import React,{ useState } from 'react'
import axios from 'axios';
import { useIntl, FormattedMessage } from "react-intl";
import { toast } from 'react-toastify';
import { Button } from 'antd';
 const CacheComponent = () => {
    const intl = useIntl();
    const [loading, setLoading] =  useState(false)
    const config = {
        headers: {
           "Content-Type": "application/json",
           Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
     };
    const clearCache = () => {
        setLoading(true)
        axios.delete(`/api/clearcache`, config).then((result) => {
            console.log(`API result`)
            console.log(result)
            if (result.data === true) {
               toast.success(intl.formatMessage({ id: "CacheCleared", defaultMessage: "CacheCleared" }), {
                  position: toast.POSITION.BOTTOM_CENTER
               })
               setTimeout(() => {
                setLoading(false)
               },3000)
            }
         })
    }
  return (
    <div className="container-fluid mt-4 mb-5 d-flex justify-content-end">
        <Button type="primary" loading={loading} onClick={clearCache}>
        <FormattedMessage
                           id="Clear Cache"
                           defaultMessage="Clear Cache"
                        />
        </Button>
         </div>
  )
}
export default CacheComponent;
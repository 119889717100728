import { useState } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { message } from "antd";

export function useUpload() {
  const [fileList, setFileList] = useState([]);

  const intl = useIntl();

  const handleChange = async (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(
        intl.formatMessage({
          id: "FileUploadSuccess",
          defaultMessage: `${info.file.name} file uploaded successfully`,
        })
      );
    } else if (info.file.status === "error") {
      message.error(
        intl.formatMessage({
          id: "FileUploadFail",
          defaultMessage: `${info.file.name} file upload failed.`,
        })
      );
    }
  };

  const handleBeforeUpload = (file) => {
    setFileList([...fileList, file]);
    return false;
  };

  const handleAfterUpload = (error, data) => {
    if (error) {
      console.log(error);
      toast.error(
        intl.formatMessage({
          id: "FileUploadFailed",
          defaultMessage: "Files handling failed.",
        }),
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    } else {
      setFileList([]);

      toast.success(
        intl.formatMessage({
          id: "FileUploadedSuccessfully",
          defaultMessage: "Files handled successfully!",
        }),
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    }
  };

  const handleRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  return {
    handleChange,
    handleBeforeUpload,
    handleAfterUpload,
    handleRemove,
    fileList,
  };
}

import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

function KPIFileUpload(props) {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file.originFileObj);
    });
    setUploading(true);
    try {
      const response = await axios.post("/api/kpi/v2/fileUpload/" + props.projectID, formData, config,  {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      });

      if(response.status === 200 && response.data.result === "Success"){
        message.success(response.data.message);
      }else{
        message.error(response.data.message);
      }

    } catch (error) {
      message.error("Something Went Wrong! Please check the file data & standards");
    }
    setUploading(false);
  };

  const propsUpload = {
    fileList,
    onChange: handleFileChange,
    multiple: false,
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
      showDownloadIcon: false,
    },
  };

  return (
    <div>
      <Upload {...propsUpload} accept=".xlsx" maxCount={1}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0 || uploading}
        loading={uploading}
        style={{ marginTop: 16 }}
      >
        {uploading ? "Uploading..." : "Start Upload"}
      </Button>
    </div>
  );
}

export default KPIFileUpload;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useIntl, FormattedMessage } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../footer'
import Spinner from '../spinner';
import { ToastContainer, toast } from 'react-toastify';
import ChangeProjectPopup from './popups/changeprojects'


const Changeprojects = (props) => {
  const intl = useIntl();
  const [Loading, setLoading] = useState(true);
  const [usersArr, setUsers] = useState([])
  const [allusers, setAllUsers] = useState([])
  const [filterVal, setFilterVal] = useState("")
  const [nvusersArr,setNvUsersArr] = useState([])
  const [showPopup,setShowPopup] = useState(false)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    }
  };
  const closehandlePopUp = () => {
    setShowPopup(false)
    document.body.classList.remove('overflow-hidden');
    window.location.reload(false);
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const changeprojectUsers = axios.get("/api/private/rights/changeprojects", config);
        const nonchangeprojectUsers = axios.get("/api/private/rights/nochangeprojects",config);
        const [vUsers,nvUsers] = await Promise.all([changeprojectUsers,nonchangeprojectUsers])
        setUsers(vUsers.data)
        setNvUsersArr(nvUsers.data)
        setAllUsers(vUsers.data)
        setLoading(false)
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    fetch().then(
      setLoading(false)
    );
  },[Loading]);

  useEffect(() => {
    filterNewUsers();
  },[filterVal]);
  const onChangeTableFilter = (val) => {
    setFilterVal(val); 
    filterNewUsers();
  }

  const filterNewUsers = () => {
    if (filterVal.length > 0) {
      let filterarr = []
      usersArr.map((arrItem, Index) => {
        if (arrItem.username.toString().toLowerCase() === filterVal.toString().toLowerCase() || arrItem.email.toString().toLowerCase() === filterVal.toString().toLowerCase() || arrItem.email.includes(filterVal.toString().toLowerCase()) || arrItem.username.includes(filterVal.toString().toLowerCase())) {
          filterarr.push(arrItem)
        }
        setUsers(filterarr)
      })
    } else {
      setUsers(allusers)
    }
  }

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      filterNewUsers();
    }
  }

  const removeVersioningRight = (ID) => {
    setLoading(true)
    axios.post('/api/rights/revokechangeprojects', { userid: ID }, config).then(result => {
      toast.success(intl.formatMessage({ id: "Successfully!ChangeProjectsRevoked", defaultMessage: "Successfully! Change Projects Revoked" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      setLoading(false)
    }).catch(err => {
    });
  }

  if (!Loading){
    return (
      <>
        <ToastContainer toastClassName="top-zIndex" autoClose={3000} />
        <ChangeProjectPopup show={showPopup} onHide={closehandlePopUp} heading={intl.formatMessage({ id: "Addrights", defaultMessage: "Add Rights" })} users={nvusersArr}/>
        <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 project-page">
          <div className="main-body bg-custom-white pt-0 pd-20">
            <div className="content-wrapper">
              <section className="content ">
                <div className="content-wrapper">
                  <div className="row mg-t-20 ">
                    <div className="col-lg-12 plr-0">
                      <div className="row d-flex align-items-center">
                        <div className="col-md-6">
                          <h1 className="main-heading"><FormattedMessage id="ChangeProjectsRights" defaultMessage="Change Projects Rights" /></h1>
                          <p className="mb-3"><FormattedMessage id="Itcontainsalltheusershavingchangeprojectsrights" defaultMessage="It contains all the users having change projects rights" /></p>
                        </div>
                        <div className="col-md-6 text-right">
                          <button type="button" className="btn cta-btn" onClick={() => setShowPopup(true)}><FormattedMessage id="AddMultipleUserRights" defaultMessage="Add Multiple User Rights" /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 plr-0">
                      <div className="table-user-wrapper">
                        <div className="d-flex input-project-wrapper mb-3">
                          <input className="input-search-field" onChange={(e) => onChangeTableFilter(e.target.value)} placeholder={intl.formatMessage({ id: "SearchforUser", defaultMessage: "Search for user..." })} value={filterVal} onKeyPress={enterPressed.bind(this)}/>
                          <div className="search-icon" onClick={filterNewUsers}>
                            <img src="/images/icons/search-input-icon.svg" />
                          </div>
                        </div>
                        <table id="user-table" className="table table-bordered">
                          <thead>
                            <tr>
                              <td>Username</td>
                              <td>Email</td>
                              <td className='text-center'>Actions</td>
                            </tr>
                          </thead>
                          <tbody>
                            {usersArr.map((arrItem, Index) => {
                              return (
                                <tr role="row" key={arrItem + Index}>
                                  <td className='table-username'>{arrItem.username}</td>
                                  <td>{arrItem.email}</td>
                                  <td className='text-center'>
                                      <button className='btn cta-btn  btn-red mr-2' onClick={() => removeVersioningRight(arrItem._id)}>
                                        <i className="fas fa-trash"></i> <FormattedMessage id="RemoveRights" defaultMessage="Remove Rights" />
                                      </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </section>
              <div className="col-md-12 mg-t-10">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Spinner />;
  }
}
export default Changeprojects;
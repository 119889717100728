export const LANGUAGE_MENU = [
  // {
  //   id: 'ar',
  //   defaultMessage: 'Arabic',
  //   icon: 'ARFlag',
  // },
  {
    id: 'bg',
    defaultMessage: 'Bulgaria',
    icon: 'BGFlag',
  },
  {
    id: 'de',
    defaultMessage: 'Germany',
    icon: 'DEFlag',
  },
  {
    id: 'ru',
    defaultMessage: 'Russia',
    icon: 'RUFlag',
  },
  {
    id: 'en',
    defaultMessage: 'English',
    icon: 'USFlag',
  },  
  {
    id: 'es',
    defaultMessage: 'Spain',
    icon: 'ESFlag',
  },
  {
    id: 'fr',
    defaultMessage: 'France',
    icon: 'FRFlag',
  },
  // {
  //   id: 'hr',
  //   defaultMessage: 'Croatia',
  //   icon: 'HRFlag',
  // },
  // {
  //   id: 'hu',
  //   defaultMessage: 'Hungary',
  //   icon: 'HUFlag',
  // },
  {
    id: 'it',
    defaultMessage: 'Italy',
    icon: 'ITFlag',
  },
  {
    id: 'nl',
    defaultMessage: 'Netherlands',
    icon: 'NLFlag',
  },
    {
    id: 'bl',
    defaultMessage: 'Belgium',
    icon: 'NLFlag',
  },
  {
    id: 'pl',
    defaultMessage: 'Poland',
    icon: 'PLFlag',
  },
  {
    id: 'ro',
    defaultMessage: 'Romania',
    icon: 'ROFlag',
  },
  // {
  //   id: 'sk',
  //   defaultMessage: 'Slovakia',
  //   icon: 'SKFlag',
  // },
  // {
  //   id: 'sr',
  //   defaultMessage: 'Suriname',
  //   icon: 'SRFlag',
  // },
  {
    id: 'tr',
    defaultMessage: 'Turkey',
    icon: 'TRFlag',
  },
  {
    id: 'hu',
    defaultMessage: 'Hungarian',
    icon: 'HUFlag'
  },
  {
    id: 'zh',
    defaultMessage: 'Chinese',
    icon: 'ZHFlag'
  },
  {
    id: 'jp',
    defaultMessage: 'Japanese',
    icon: 'JPFlag'
  }
];

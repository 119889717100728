import { React, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const Popup = (props) => {
  const intl = useIntl();

  const [fileList, setFileList] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(props.imgurl)

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };


  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', fileList);
    formData.append('old_file', props.imgurl)
    setUploading(true);
    console.log(fileList);
    axios.post(`/api/private/queue/uploadimgproject/${props.idd}`, formData, config)
      .then((response) => {
        setFileList("");

        message.success(intl.formatMessage({id:"ImageUploadedSuccessfully",defaultMessage:"Image Uploaded Successfully!"}));
        setUploadedImage(response.data.picture)
        setUploading(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000)
      }, (error) => {
        console.log(error)
        message.error(intl.formatMessage({id:"ImageUploadedSuccessfully",defaultMessage:"Image Uploaded Successfully!"}));
        setUploading(false);
      })
  };

  const fileprops = {
    onRemove: (file) => {
      setFileList("");
    },
    beforeUpload: (file) => {
      setFileList(file);
      return false;
    }
  };
  return (
    <div>
      {true && (
        <div className={`fq-modal fq-modal-small  ${props.show ? "d-block" : "d-none"}`}>
          <div className="fq-modal-content">
            <div style={{ background: "#194A96" }}
              className="fq-modal-header">
              <span onClick={props.onHide} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
              <h1><FormattedMessage id={props.heading.replace(/\s/g, '')} defaultMessage={props.heading} /> </h1>

            </div>
            <div className="fq-modal-content">
              <div className="text-center">
                <img className="ProjectImage mb-4" alt="project-img" src={props.imgurl} />
                <br />
                <Upload {...fileprops} listType="picture" maxCount={1} multiple>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>

                {fileList !== "" ?
                  <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList === ""}
                    loading={uploading}
                    style={{
                      marginTop: 16,
                    }}
                  >
                    {uploading ? 'Uploading' : 'Change Project Picture'}
                  </Button> : ""
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup
import { Progress } from 'antd';

const KPIGauge = ({ title, targetValue, value, onClick, sign , currencyValue, targetCurrencyValue}) => {

  const getValue = (value) => {
    if (title === "Key to Key Days" || title === "Key To Key") {
      return (value * 100) / 30;
    } else if(title === "Total Sales" || title === "Parts Sales" || title === "Average Jobs") {
      return (value * 100) / targetValue;
    }else if(title === "Parts Profit %" || title === "Average Paint Cost % per Job"){
      return value > 100 ? 100 : value
    }else{
      return value > 100 ? 100 : value
    }
  }
  const getStrokeColor = (value) => {
    let percentage = parseInt(value);
    if (title === "Key to Key Days" || title === "Key To Key") {
      if (percentage <= parseInt(targetValue)) {
        return "#1CC435"
      } else {
        return "#BE0412"
      }
    } else {
      if (percentage >= parseInt(targetValue)) {
        return "#1CC435"
      } else {
        return "#BE0412"
      }
    }
  }

  const displayValue = () => {
    if(sign === "%"){
      return value + sign;
    }else if(sign === "currency"){
      return currencyValue;
    }else{
      return value;
    }
  }

  const handleonClick = () => {
    if (value !== 0) { // Check if the value is not zero
      onClick();
    }
  };

  return (
    <div className={`kpi-card kpi-card-guage cursor-pointer rounded-2 pd-2 border-c-right`} onClick={handleonClick}>
      <div className="d-flex flex-column">
        <h2 className="card-c-heading-2">{title}</h2>
        <p className='card-text mb-4'>Target Value: {sign === "currency" ? targetCurrencyValue : sign === "%" ? targetValue + sign : targetValue}</p>
        <Progress
          type="dashboard"
          className="kpi-guage kpi-center"
          width={"80%"}
          percent={getValue(value)}
          format={displayValue} // to display the value inside the gauge
          showInfo={true}
          strokeColor={getStrokeColor(value)}
        />
      </div>
    </div>

  );
};

export default KPIGauge;
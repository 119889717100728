import { useEffect, useState } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { Badge, Button, Dropdown, Segmented } from "antd";
import { 
  BellOutlined, 
  CheckCircleOutlined, 
  CloseOutlined, 
  MoreOutlined 
} from "@ant-design/icons";

import Notification from "./Notification";

function Notifications() {
  console.log("Notifications => Starting");
  const [activities, setActivities] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOrderedByUser, setIsOrderedByUser] = useState(true);
  const [isOrderedByDate, setIsOrderedByDate] = useState(false);
  const [isOrderedByProject, setIsOrderedByProject] = useState(false);
  const [segmented, setSegmented] = useState("Unread");
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);

  const authToken = localStorage.getItem("authToken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${authToken}`,
    },
  };

  const dropdownItems = [
    {
      label: "User",
      key: '1',
    },
    {
      label: "Project",
      key: '2',
    },
    {
      label: "Date",
      key: '3',
    },
  ];

  useEffect(() => {
    console.log("Notifications => useEffect => activities:", activities);
    console.log("Notifications => useEffect => currPage:", currPage);
    console.log("Notifications => useEffect => prevPage:", prevPage);
    console.log("Notifications => useEffect => lastList:", lastList);
    console.log("Notifications => useEffect => totalCount:", totalCount);
    const fetchData = async () => {
      const response = await axios.get(
        `/api/activities?query=${segmented}&sortBy=${isOrderedByUser ? "user" : 
          isOrderedByDate ? "date" :
          "project"}&page=${currPage}`,
        config
      );
      console.log("response.data:", response.data);
      if (!totalCount) setTotalCount(response.data.totalCount);
      if (!unreadCount) setUnreadCount(response.data.unreadCount);
      if (!response.data.activities.length) {
        setLastList(true);
        return;
      }
      if (response.data.activities.length < 30) // 30 is the number of activities fetched per page
        setLastList(true);
      setPrevPage(currPage);
      const reshapedActivities = addGroupsToFetchedActivities(response.data.activities);
      setActivities([...activities, ...reshapedActivities]);
    };
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [
    currPage, 
    lastList, 
    segmented,
    isOrderedByDate,
    isOrderedByProject,
    isOrderedByUser,
  ]);

  const getDateWithSlashes = (date) => {
    const activityDate = new Date(date);
    const day = activityDate.getDate();
    const month = activityDate.getMonth() + 1;
    const year = activityDate.getFullYear();
    return [day, month, year].join('/');
  }

  const addGroupsToFetchedActivities = (fetchedActivities) => {
    const reshapedList = [];
    
    for (let i = 0; i < fetchedActivities.length; i++) {
      const currentActivity = fetchedActivities[i];
      const username = currentActivity.user.username;
      const date = isOrderedByDate && getDateWithSlashes(new Date(currentActivity.createdAt));
      const projectName = currentActivity.project.name;
       
      if (i !== 0) {
        if ((isOrderedByUser && username !== fetchedActivities[i - 1].user.username) ||
          (isOrderedByProject && projectName !== fetchedActivities[i - 1].project.name) ||
          (isOrderedByDate && 
            date !== getDateWithSlashes(new Date(fetchedActivities[i - 1].createdAt)))) {
            reshapedList.push(
              { group: isOrderedByUser ? username : 
                  isOrderedByProject ? projectName : 
                  date }
            );
            reshapedList.push(currentActivity); 
        } else {
          reshapedList.push(currentActivity);
        }
      } else {
        if (activities.length) {
          if ((isOrderedByUser && username !== activities[activities.length - 1].user.username) ||
            (isOrderedByProject && projectName !== activities[activities.length - 1].project.name) ||
            (isOrderedByDate && 
              date !== getDateWithSlashes(new Date(activities[activities.length - 1].createdAt)))) {
              reshapedList.push(
                { group: isOrderedByUser ? username : 
                    isOrderedByProject ? projectName : 
                    date }
              );
              reshapedList.push(currentActivity);
            } else {
              reshapedList.push(currentActivity);
            }
        } else {
          reshapedList.push(
            { group: isOrderedByUser ? username : 
                isOrderedByProject ? projectName : 
                date },
          );
          reshapedList.push(currentActivity);
        }
      }
    }
    
    return reshapedList;
  };

  const onDropdownItemClick = ({ key }) => {
    console.log("onDropdownItemClick => key:", key);
    if (key === "1") {
      setIsOrderedByProject(false);
      setIsOrderedByDate(false);
      setIsOrderedByUser(true);
    } else if (key === "2") {
      setIsOrderedByUser(false);
      setIsOrderedByDate(false);
      setIsOrderedByProject(true);
    } else {
      setIsOrderedByProject(false);
      setIsOrderedByUser(false);
      setIsOrderedByDate(true);
    }
    setActivities([]);
    setCurrPage(1);
    setPrevPage(0);
    setLastList(false);
  };

  const handleBellClick = () => {
    setIsOpen(true);
  }

  const handleSegmentedChange = (value) => {
    console.log("handleSegmentedChange => value:", value);
    setSegmented(value);
    setActivities([]);
    setCurrPage(1);
    setPrevPage(0);
    setLastList(false);
  }

  const markAllAsRead = async () => {
    try {
      const body = {
        all: true,
        orderedBy: isOrderedByUser ? "user" : 
          isOrderedByProject ? "project" : 
          "date",
      };
  
      await axios
        .patch(`/api/activities/mark-as-read`, body, config)
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
  
      setActivities([]);
      setUnreadCount(0);
      // handleSegmentedChange("Read");
    } catch (error) {
      console.log(error);
    }
  }

  const handleMarkedAsRead = (markedAsReadActivity) => {
    const updatedActivities = activities.filter(
      (activity) => activity._id !== markedAsReadActivity._id
    );

    setActivities(updatedActivities);
    setUnreadCount(unreadCount - 1);
  }

  const renderGroup = (activity) => {
    return (
      <div key={`${activity._id}`} className='row mt-2 d-flex flex-column'>
        <div className='mb-3' style={{fontSize: "1.3em"}}>
          <b>
            <FormattedMessage 
              id="SortingCriteria" 
              defaultMessage={`${activity.group}`} 
            />
          </b>
        </div>
      </div>
    )
  }

  const renderNotification = (activity) => {
    return (
      <li key={activity._id}>
        <Notification 
          activity={activity} 
          authToken={authToken}
          onMarkedAsRead={handleMarkedAsRead}
        />
      </li>
    )
  }

  const close = () => setIsOpen(false);

  if (!isOpen) return (
    <>
      <Button
        style={{border: "none", marginRight: "8px"}}
        className="notification-bell-button bg-transparent" 
        onClick={handleBellClick}
      >
        <Badge count={unreadCount} color="red">
          <BellOutlined style={{fontSize: "1.3rem", color: "white"}} />
        </Badge>
      </Button>
    </>
  );

  return (
    <>
      <div id="popup-1" className="d-block fq-modal">
        <div 
          id="notifications" 
          className={`fq-modal-content ${isOpen ? "slide-in" : "slide-out"}`}
        >
          <div className="fq-modal-header">
            <h1>
              <FormattedMessage id="Notifications" defaultMessage="Notifications" />
            </h1>
            <div className="notifications-header__subtitle">
              <FormattedMessage 
                id="NotificationsCount" 
                defaultMessage={`${unreadCount} unread | ${totalCount - unreadCount} read`} 
              />
              <Button
                className="mark-all-as-read-button" 
                icon={<CheckCircleOutlined />}
                onClick={markAllAsRead}
                style={{marginLeft: "20px"}}
                type="primary"
              >
                Mark all as read
              </Button>
              <Dropdown
                menu={{
                  items: dropdownItems,
                  selectable: true,
                  defaultSelectedKeys: ['1'],
                  onClick: onDropdownItemClick,
                }}
                trigger={['click']}
                dropdownRender={(menu) => (
                  <div className="notifications-dropdown-content">
                    <p><FormattedMessage id="SortBy" defaultMessage="Sort By" /></p>
                    {menu}
                  </div>
                )}
              >
                <Button
                  className="show-more-button" 
                  icon={<MoreOutlined />}
                  onClick={(e) => e.preventDefault()}
                  style={{marginLeft: "20px"}}
                />
              </Dropdown>
            </div>
            <Button
              className="close-button bg-transparent" 
              icon={<CloseOutlined />}
              onClick={close}
              shape="circle"
              style={{position: "absolute", top: "15px", right: "5px"}}
            />            
          </div>
          <Segmented 
            options={["Unread", "Read"]} 
            value={segmented} 
            onChange={handleSegmentedChange}
            style={{marginBottom: "20px", marginLeft: "10px"}} 
          />
          <div
            className="modal-content-wrapper" 
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "15px",
              paddingBottom: "30px", 
              overflowY: "auto"
            }}
          >
            <div
              className='row mobilePadding' 
              style={{width: "100%"}}
            >
              <div
                className='w-100'
                style={{height: "100%"}}
              >
                {!!activities.length && (
                    <>
                    {activities.map((activity) => (
                      <>
                      {activity.group ? renderGroup(activity) : renderNotification(activity)}               
                      </>
                    ))}
                    {Boolean(!lastList) && (
                      <Button
                        className="load-more-button" 
                        onClick={() => setCurrPage(currPage + 1)}
                        style={{marginLeft: "20px", border: "none"}}
                        type="primary"
                      >
                        Load more...
                      </Button>
                    )}
                    </>
                )}
                {/* {Boolean(!lastList) && Boolean(!activities.length) && "Loading..."} */}
                {lastList && !activities.length && (
                  <div className="no-notifications">
                    <FormattedMessage
                      id="NoUnreadNotifications"
                      defaultMessage={segmented === "Unread" ? "You're all up to date!" :
                      "You do not have any read notifications."}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications;

import styled from 'styled-components';

export const Box = styled.div`
  margin-right: 20px;
  @media (max-width: 990px) {
    margin-left: auto;
  }

  .popover-wrapper.right {
    .popover-content {
      padding: 5px 0;
    }
  }

  @media (max-width: 767px) {
    margin-right: 10px;
  }
`;

export const SelectedItem = styled.button`
  width: auto;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ACACAC;
  border: 1px solid #f1f1f1;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  color: #fff;

  @media (max-width: 767px) {
    display: block
  }

  span {
    display: flex;
    align-items: center;
    font-family: inherit;
    font-size: 15px;
    line-height: 17px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;

    /* @media (max-width: 990px) {
      display: none;
    } */

    @media (max-width: 767px) {
      display: block;
    }

    &:first-child {
      margin-right: auto;
    }
  }
`;

export const Flag = styled.div`
  margin-right: 7px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
  svg {
    width: 20px;
    height: auto;
  }

  @media (max-width: 767px) {
    margin-right: 0;
  }

  /* @media (max-width: 990px) {
    margin-right: 0;
  } */
`;

export const MenuItem = styled.button`
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  color: #0D1136;
  line-height: 1.2em;
  display: block;
  padding: 10px 30px;
  border-radius: 6px;
  transition: 0.15s ease-in-out;
  display: flex;
  align-items: center;
  border: 0;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 0;
  background-color: transparent;
  outline: 0;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  @media (max-width: 1400px) {
    margin-right: 10px;
    font-size: 15px;
  }

  @media only screen and (min-width: 991px) and (max-width: 1200px) {
    padding: 15px 30px;
  }

  span {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    overflow: hidden;
    margin-right: 15px;

    svg {
      display: block;
      width: 20px;
      height: auto;
    }
  }
`;

import React, { useEffect, useState } from 'react';
  
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Line } from "react-chartjs-2";

    
const ProjectElementHistory = (props) => {
  
   const idd = props.match.params.id
     const [History, setHistory] = useState({})
     const [Loading, setLoading] = useState(false)   


     useEffect(() => {
      
      const fetchPrivateDate = async () => {
     
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        };
          
        try {
          const { data } = await axios.get(`/api/private/dbprojectshistory/${idd}`, config);
         // console.log(data)
          setHistory(data)  
      } catch(e) {
         if(!props.dev) {
          localStorage.removeItem("authToken");
      window.location.href = '/logout';   
}
      }
        finally {
        
        }
       
     
      };
  
  fetchPrivateDate();
  
  fetchPrivateDate().then( () => {
    setLoading(false)
    })
  
  
      
    }, []);
  

    
    
     var options = {
      
        legend: {
            labels: {
              padding: 100 
            }
          },
         elements: {
            radius: 0,
             points: {
                 radius: 0
             }
         },

         responsive: true,
         
        scales: {
            xAxis: {
              // The axis for this scale is determined from the first letter of the id as `'x'`
              // It is recommended to specify `position` and / or `axis` explicitly.
              grid: {
                display : false,
                drawTicks : false,
              },
              ticks: {
                display: false
              }
            },
            yAxis: {
                // The axis for this scale is determined from the first letter of the id as `'x'`
                // It is recommended to specify `position` and / or `axis` explicitly.
                grid: {
                  display : false,
                }
              }
          },
          
}
     
     const data = {
       // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "Business/Capacity",
            data: [0, 10, 20, 30, 40, 50],
            fill: false,
            borderColor: "#6e6ac2",
            pointRadius: 0,
          },
          {
            label: "Facility/Equipment",
            data: [0, 5, 15, 25, 35, 45],
            fill: false,
            borderColor: "#eaa240",
            pointRadius: 0,
          },
          {
            label: "Business/Workshop",
            data: [0, 80, 90, 95, 100, 100],
            fill: false,
            borderColor: "#dc616a",
            pointRadius: 0,
          },
          {
            label: "Training",
            data: [0, 20, 40, 60, 80, 100],
            fill: false,
            borderColor: "#312e76",
            pointRadius: 0,
          },
          {
            label: "Repair Quality",
            data: [0, 50, 55, 75, 80, 85],
            fill: false,
            borderColor: "#a0d4e5",
            pointRadius: 0,
          },
          {
            label: "Total",
            data: [0, 20, 40, 60, 80, 90],
            fill: false,
            borderColor: "#742774",
            pointRadius: 0,
          }
        ]
      };

     

     
    

  if(!Loading) {
     {/* Main content */}
    return (
    <div className="content-wrapper" >
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
      <div className="col-sm-10">
      <h1 className="project"></h1>
        </div>
     
        <div className="chart-container" style={{position: 'relative', width: "60vw", maxWidth: "100%"}}>
        <Line   
        
        options={options} data={data} />
    </div>


       
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  
</div>

  
);
}
else {
return ""
}}

      export default  withRouter(ProjectElementHistory)

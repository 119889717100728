import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useTranslation } from "../../hooks/useTranslation";
import TranslationButton from "./TranslationButton";

function MilestoneTitle({ index, item, ...props }) {
  const { 
    detectedSourceLanguage, 
    targetLanguage, 
    translated, 
    translatedText, 
    translate, 
    showOriginalText 
  } = useTranslation(item.language);

  useEffect(() => {
    const fetchTranslation = async () => {
      await translate(item["Title"]);
    };
    fetchTranslation()
      .catch(console.error);
  }, [item]);

  return (
    <div style={{ marginBottom: "0" }} className="">
      <div class="d-flex">
        <h5 className="title-milestone" onClick={
      props.MilestoneResultState &&
      props.canEdit &&
      props.MilestoneResultState[index]
        ? () =>
            props.openTasklistMilestones(
              true,
              item,
              index
            )
        : props.openTasklistMilestones(false)
    }>
          {translated ? translatedText : item["Title"]}{" "}
        </h5>
        <TranslationButton
            detectedSourceLanguage={detectedSourceLanguage}
            targetLanguage={targetLanguage}
            translated={translated}
            onTranslate={showOriginalText}
            onShowOriginal={() => {
              translate(item["Title"]);
            }}
          />
      </div>
      <p style={{ marginLeft: "10px" }} className="stats-text">
        {props.MilestoneResultState[index] &&
        (props.MilestoneResultState[index]["complete"] ||
          props.MilestoneResultState[index]["complete"] == 0)
          ? props.MilestoneResultState[index]["complete"]
          : ""}
        /
        {props.MilestoneResultState[index] &&
        (props.MilestoneResultState[index]["total"] ||
          props.MilestoneResultState[index]["total"] == 0)
          ? props.MilestoneResultState[index]["total"]
          : ""}{" "}
        {props.MilestoneResultState[index]["total"] ||
        props.MilestoneResultState[index]["total"] == 0 ? (
          <FormattedMessage
            id="Taskscompleted"
            defaultMessage="Tasks completed"
          />
        ) : (
          ""
        )}
      </p>
    </div>
  );
}

export default MilestoneTitle;
export const defaultLocale = 'en' as const;

export const locales = [
  'ar',
  'bg',
  'de',
  'en',
  'es',
  'fr',
  'hr',
  'hu',
  'it',
  'nl',
  'bl',
  'pl',
  'ro',
  'ru',
  'sk',
  'sr',
  'tr',
  'zh',
  'jp'
] as const;
export const rtlLocales = ['ar', 'he', 'fa'] as const;
export const languageNames = {
  ar: "Arabic",
  bg: "BG",
  de: "German",
  en: "English",
  es: "Espanish",
  fr: "French",
  hr: "HR",
  hu: "HU",
  it: "Itly",
  nl: "Netherland",
  bl: "Belgium",
  pl: "Poland",
  ro: "RO",
  ru: "Russu",
  sk: "SK",
  sr: "SR",
  tr: "TR",
  zh: "Chinese",
  jp: "Japanese"
};

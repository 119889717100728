import React, { useEffect, useState } from 'react';
 
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PopupAdd from '../Translation/TranslationWeightPopupAdd';
import PopupDivide from '../Translation/TranslationWeightPopupDivide';
import PopupMultiply from '../Translation/TranslationWeightPopupMultiply';
import Multiply from '../Translation/TranslationWeightMultiply';
import WeightModalElements from './WeightModaleElements';
import { useIntl, FormattedMessage } from 'react-intl';

const WeightModalElement = (props) => {
  const [saved, setSaved] = useState(true)
  const [saveable, setsaveable] = useState(true);
  const [updatedTasks, setUpdatedTasks] = useState(false);
  const [BayTasks, setBayTasks] = useState({});
  /*  const [Weight, setWeight] = useState([]); */
  // console.log(props)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  useEffect(() => {
    /*  props.setWeight(props.translation["Weight"]) */
    let paintshop = props.FinalArray.PaintShopBaysCountResult && props.FinalArray.PaintShopBaysCountResult[0] + "."
    let ToolBoxPaint = props.FinalArray.ToolBoxPaint && props.FinalArray.ToolBoxPaint[0] && props.FinalArray.ToolBoxPaint[0]["FatherNumber"] + "."
    let ToolBoxBody = props.FinalArray.ToolBoxBody && props.FinalArray.ToolBoxBody[0] && props.FinalArray.ToolBoxBody[0]["FatherNumber"] + "."
    let mixingRoom = props.FinalArray.MixingBayResult && props.FinalArray.MixingBayResult[0] + "."
    let spraybooth = props.FinalArray.SprayBoothBayResult && props.FinalArray.SprayBoothBayResult[0] + "."
    let bodyshop = props.FinalArray.MechanicalAndBodyRepairWorkBaysResult && props.FinalArray.MechanicalAndBodyRepairWorkBaysResult[0] + "."
    let repair = props.FinalArray.GroupedbyDate && props.FinalArray.GroupedbyDate[0]["FatherNumber"] + "."

    let allItems = [paintshop, mixingRoom, spraybooth, bodyshop]
    let baytasks = {
      all: [], delete: [], keep: {
        paintshop: [], mixingRoom: [], spraybooth: [], repair: [], bodyshop: [], tools: [], ToolBoxBody: [], ToolBoxPaint: [],
      }, test: []
    }

    /*   function checkIfStringStartsWith(str, substrs) {
        return substrs.some(substrs => str.startsWith(substrs));
      } */
    console.log(props)
    Object.keys(props.allTasks).forEach(element => {
      if (element.startsWith(paintshop + "1.")) {
        baytasks.keep.paintshop.push(element)
        baytasks.all.push(element)
      } else if (element.startsWith(ToolBoxPaint + "1.")) {
        baytasks.keep.ToolBoxPaint.push(element)
        baytasks.all.push(element)
      } else if (element.startsWith(ToolBoxBody + "1.")) {
        baytasks.keep.ToolBoxBody.push(element)
        baytasks.all.push(element)
      }
      else if (element.startsWith(mixingRoom + "1.")) {
        baytasks.keep.mixingRoom.push(element)
        baytasks.all.push(element)
      } else if (element.startsWith(spraybooth + "1.")) {
        baytasks.keep.spraybooth.push(element)
        baytasks.all.push(element)
      } else if (element.startsWith(repair + "1.")) {
        baytasks.keep.repair.push(element)
        baytasks.all.push(element)
      } else if (element.startsWith(bodyshop + "1.")) {
        baytasks.keep.bodyshop.push(element)
        baytasks.all.push(element)
      }
      else if (element.startsWith(paintshop) ||
        element.startsWith(mixingRoom) ||
        element.startsWith(spraybooth) ||
        element.startsWith(repair) ||
        element.startsWith(bodyshop)) {
        baytasks.delete.push(element)
        baytasks.all.push(element)
      }
    })
    /*   set */

    let newObj = { ...props.allTasks }
    baytasks.all.forEach(item => {
      delete newObj[item]
    })
    setUpdatedTasks(newObj)

    let keepObj = {}
    for (const [key, value] of Object.entries(baytasks.keep)) {
      baytasks.keep[key].forEach(item => {
        //  console.log(props.allTasks[item])
        if (!keepObj[key]) {
          keepObj[key] = {}
        }
        keepObj[key][item] = props.allTasks[item]
      })
      setBayTasks(keepObj)
      console.log(newObj)
      console.log(keepObj)
    }
  }, [])




  const handleSave = (e) => {

    let Body = {
      ...props.translation,
      Weight: props.Weight
    }
    /*  let body = {
       ProjectID: props.idd,
       selectedTags: newTags
     } */
    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
        console.log(DBGet.data)
      } catch (e) {
        if(!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }


    fetch().then(item => {
      setSaved(true)
    })
  }


  if (updatedTasks && BayTasks) {
    return (
      <div style={{ display: "Block", zIndex: "1000" }} id="popup-1" className="fq-modal">
        <div id="milestones" className="fq-modal-content">
          <div className="fq-modal-header">

            <span onClick={() => props.setWeightModal(false)} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
            <h1><FormattedMessage id="SetTaskWeightforVersioning" defaultMessage="Set Task Weight for Versioning" />   </h1>

          </div>
          <div style={{
            display: "flex",
            flexDirection: "column",

            paddingTop: "40px", paddingBottom: "30px", overflowY: "auto"
          }} className="modal-content-wrapper">


            <div style={{ padding: "15px 30px" }} className="row d-flex">
              <div style={{ flexDirection: "column", width: "100%", position: "relative", marginLeft: "37px", marginRight: "20px", display: "flex" }}>

                <b>Body Shop</b>
                {BayTasks.bodyshop && Object.entries(BayTasks.bodyshop).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} someProps={0} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}

                <b>Body Shop Tool Box</b>
                {BayTasks.ToolBoxBody && Object.entries(BayTasks.ToolBoxBody).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} someProps={0} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}

                <b>Paint Shop Preparation/Masking/Polishing</b>
                {BayTasks.paintshop && Object.entries(BayTasks.paintshop).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}

                <b>Paint Shop Tool Box</b>
                {BayTasks.ToolBoxPaint && Object.entries(BayTasks.ToolBoxPaint).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}

                <b>Mixing Area</b>
                {BayTasks.mixingRoom && Object.entries(BayTasks.mixingRoom).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}
                <b>Spray Area</b>
                {BayTasks.spraybooth && Object.entries(BayTasks.spraybooth).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}
                <b>Repair</b>
                {BayTasks.repair && Object.entries(BayTasks.repair).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}
                <b>Rest</b>
                {Object.entries(updatedTasks).map(([somekey, item], index) => (
                  <WeightModalElements setsaveable={setsaveable} onload={props.Weight[somekey]} Weight={props.Weight} setWeight={props.setWeight} saved={saved} setSaved={setSaved} item={item} somekey={somekey} index={index} />
                ))}

              </div>


            </div>
            <div style={{ marginTop: "auto", marginLeft: "auto" }} className="row">



              {saved || !saveable ?
                <button
                  style={{ cursor: "initial", marginRight: "30px" }}
                  className='nohover whiteColor  btn saveButtonTasklist'
                ><FormattedMessage id="Save" defaultMessage="Save" /></button>
                :
                <button
                  style={{ marginRight: "30px" }}
                  className=' btn saveButtonTasklistSave'
                  onClick={() => handleSave()}><FormattedMessage id="Save" defaultMessage="Save" /></button>
              }



            </div>
          </div>

        </div>

      </div>



    );
  } else {
    return (
      ""
    )
  }
}

export default WeightModalElement

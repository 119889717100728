import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { useIntl, FormattedMessage } from 'react-intl';
import Select from 'react-select'
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";
import axios from 'axios';
import _ from 'lodash';
import Collapsible from './ProjectTasklistCollapsible';
import CollapsibleWithout from './ProjectTasklistCollapsibleWithout';
import CollapsibleWithoutCustom from './ProjectTasklistCollapsibleWithoutCustom';
import Owldemo5 from '../reactcarousel5'
import ProjectTasklistSecondScreen from './ProjectTasklistSecondScreen';
import ProjectTasklistSecondScreenCustom from './ProjectTasklistSecondScreenCustom';
import { projectTasklistPathPatterns } from "./tasks-routing-constants";
import { MultiSelect } from "react-multi-select-component";

const ProjectTasklist = (props) => {
  const intl = useIntl();
  //props.Result.Pictures.push("https://dummyimage.com/600.png/09f/fff")
  const [FinishCount, setFinishCount] = useState(props.TaskListComp.length);
  const [El, setEl] = useState([]);
  const [Filter, setFilter] = useState("Uncompleted");
  const [avoidItems, setAvoidItems] = useState([]);
  const [SecondScreen, setSecondScreen] = useState(false);
  const [secondScreenCustom, setSecondScreenCustom] = useState(false);
  const [necessaryItems, setNecessaryItems] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [milestone, setMilestone] = useState([]);
  const [addToMilestone, setAddToMilestone] = useState([]);
  const [SelectOne, setSelectOne] = useState([])
  const [success, setSuccess] = useState(false);
  const [customQuestionSelected,setCustomQuestionSelected] = useState([])
  const [customQuestionIndex,setCustomQuestionIndex] = useState(0)

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  let history = useHistory();
  const location = useLocation();
  const projectPath = `/project/${props.idd}`;

  useEffect(() => {
    let AlreadyIncluded = []
    let avoidItems = []
    let necessaryItems = [];
    function getChildren(item) {
      if (props.allTasks[item]) {
        if (props.allTasks[item][2]) {
          props.allTasks[item][2].map(element => {
            if (props.TaskListComp.includes(element)) {
              AlreadyIncluded.push(element)
              getChildren(element)
            }
          })
        }
      }
    }

    if (props.TaskListComp) {
      props.TaskListComp.map((item, index) => {
        if (AlreadyIncluded.includes(item)) {
          avoidItems.push(item)
        } else {
          if (props.allTasks[item]) {
            necessaryItems.push(item)
          }
          getChildren(item)
        }
      })
    }
    setAvoidItems(avoidItems)
    const match = props.MilestoneResultState.filter(item => item.Title === props.Result.Name);
    setCustomQuestions(match[0]?.Questions)
    setMilestone(match[0])
    // Update the document title using the browser API    document.title = `You clicked ${count} times`;  });
  }, [Filter])

  // Checking url pattern matches
  useEffect(() => {
    const pathMatch = matchPathPattern();

    if (pathMatch?.isExact && pathMatch?.params.taskKey) {
      const { taskKey } = pathMatch.params;
      setSecondScreen(taskKey);
    }

    if (pathMatch?.isExact && pathMatch?.params.milestoneKey && pathMatch?.params.arrKey) {
      const { arrKey } = pathMatch.params;
      setCustomQuestionSelected(customQuestions[arrKey])
      setCustomQuestionIndex(arrKey)
      setSecondScreenCustom(true);
    }
  }, [location.pathname]);

  const matchPathPattern = () => {
    let matchedPath = {};
    projectTasklistPathPatterns.forEach((pattern) => {
      const match = matchPath(location.pathname, { path: pattern, exact: true });
      if (match?.isExact) matchedPath = match;
    });
    return matchedPath;
  }

  const close = () => {
    function scroll() {
      window.scrollTo({ top: props.ScrollPos, behavior: 'smooth' })
    }
    props.passTaskListComp(0)
    if (location.pathname.includes("BodyShop")) {
      history.push(`${projectPath}/BodyShop`);
    } else if (location.pathname.includes("PaintShop")) {
      history.push(`${projectPath}/PaintShop`);
    } else if (location.pathname.includes("CompressedAir")) {
      history.push(`${projectPath}/CompressedAir`);
    } else {
      history.push(projectPath);
    }
  }

  const addTasksToSelectedMilestones = () => {
    const uniqueTasks = Array.from(new Set(addToMilestone));

    const originalMilestoneResultState = [...props.MilestoneResultState];

    const updatedMilestoneResultState = props.MilestoneResultState.map(item => {
      if (SelectOne.some(selectObj => selectObj.label === item.Title)) {
        return {
          ...item,
          Tasks: [...item.Tasks, ...uniqueTasks],
        };
      } else {
        return item;
      }
    });
  
    props.setMilestoneResultState(updatedMilestoneResultState);
    updateMultipleMilestones(originalMilestoneResultState, updatedMilestoneResultState);
  };

  const updateMultipleMilestones = async (originalMilestones, updatedMilestones) => {
    // Filter out the milestones with updated tasks
    const milestonesToUpdate = updatedMilestones.filter((updatedMilestone, index) => {
      const originalMilestone = originalMilestones[index];
      return JSON.stringify(updatedMilestone.Tasks) !== JSON.stringify(originalMilestone.Tasks);
    });
  
    if (milestonesToUpdate.length > 0) {
      try {
        const data = await axios.put(`/api/private/allmilestones`, { projectId: props.idd, milestonesData: milestonesToUpdate }, config);
  
      setSuccess(true);
      toast.success(
        intl.formatMessage({
          id: "MilestonesSuccessfullyUpdated",
          defaultMessage: "Milestones successfully updated.",
        }),
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
      } catch (e) {
        toast.error(
          intl.formatMessage({
            id: "MilestoneEditFailed",
            defaultMessage: "Milestone edition failed.",
          }),
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        setSuccess(false);
        close();
      }
    } else {

    }
  };
  

  const handleChange = (selectedOption) => {
    setSuccess(false);
    setSelectOne(selectedOption)
  };

  const filterOptions = (options) => {
    return options
      .filter((milestone) => milestone.Title) // Filter milestones with non-empty titles
      .map((milestone) => ({ label: milestone.Title, value: milestone.Title })); // Create new option objects with label and value properties set to the title
  };  


  const isSecondScreenCustom = Array.isArray(SecondScreen);

  /*   const listenForFinish = (e) => {
  
      if (e[1] == false) {
        setFinishCount(prevCheck => prevCheck - 1)
      } else {
        setFinishCount(prevCheck => prevCheck + 1)
      }
    } */

  //const [necessaryItems, setNecessaryItems] = useState([]);



  /*  const getFinalData = (state ) => {
    necessaryItems

  } */

  if (Array.isArray(avoidItems)) {
    return (
      <>
        <div style={{ display: "Block" }} id="popup-1" className="fq-modal">
          <div className="fq-modal-content">
            <div
              style={{ background: props.Result.Result < 50.01 ? "#BE0312" : props.Result.Result < 85 ? "#F99F1C" : props.Result.Result < 100 ? "#1CC435" : "#1F8E30" }}
              className="fq-modal-header">
              <span onClick={() => close()} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
              <h1><FormattedMessage id={props.Result.Name.toString().replace(/\s/g, '')} defaultMessage={props.Result.Name} /> - {props.Result.Result} %</h1>
            </div>

            {secondScreenCustom ?
              <ProjectTasklistSecondScreenCustom dev={props.dev} idd={props.idd} Milestone={milestone} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} CustomQuestions={customQuestions} setCustomQuestions={setCustomQuestions} setAllTasks={props.setAllTasks} noTeamwork={props.noTeamwork} canEdit={props.canEdit} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} allTasks={props.allTasks} selectedItem={customQuestionSelected} setSelectedItem={setCustomQuestionSelected} setSecondScreenCustom={setSecondScreenCustom} index={customQuestionIndex} setSecondScreenShow={setSecondScreenCustom} Resultpictures={props.Result.Pictures} />
              : SecondScreen ? 
              <ProjectTasklistSecondScreen dev={props.dev} idd={props.idd} setAllTasks={props.setAllTasks} noTeamwork={props.noTeamwork} canEdit={props.canEdit} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} allTasks={props.allTasks} SecondScreen={SecondScreen} setSecondScreen={setSecondScreen} section={props.Result.section} />   
              :
              <>
                {props.Result.Pictures[0] ?
                  <div style={{ padding: "5px 16px" }} className="filter-wrapper d-flex">
                    <Owldemo5 noTeamwork={props.noTeamwork} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} Pictures={props.Result.Pictures} milestones={props.Result.milestone}/>
                  </div>
                  : ""
                }
                <div className="filter-wrapper d-flex justify-content-between">
                  <div className='filter-left d-flex'>
                    <img src="/images/filter.svg" alt="fitler" className="mr-8" />
                    <div onClick={e => setFilter("Not-Audited")} className={Filter === "Not-Audited" ? "markedTasklist filter-tag cursor-pointer" : "cursor-pointer filter-tag"}> <i className={Filter === "Not-Audited" ? "fas fa-check div-done-blue" : "fas fa-check"} aria-hidden="true" />&nbsp;<FormattedMessage id="Not-Checked" defaultMessage="Not-Checked" /> </div>
                    <div onClick={e => setFilter("Audited")} className={Filter === "Audited" ? "markedTasklist filter-tag cursor-pointer" : "cursor-pointer filter-tag"}> <i className={Filter === "Audited" ? "fas fa-check div-done-blue" : "fas fa-check"} aria-hidden="true" />&nbsp;<FormattedMessage id="Checked" defaultMessage="Checked" /> </div>
                    <div onClick={e => setFilter("Completed")} className={Filter === "Completed" ? "markedTasklist cursor-pointer filter-tag" : "cursor-pointer filter-tag"}> <i className={Filter === "Completed" ? "fas fa-check div-done-blue" : "fas fa-check"} aria-hidden="true" />&nbsp;<FormattedMessage id="Completed" defaultMessage="Completed" /> </div>
                    <div onClick={e => setFilter("Uncompleted")} className={Filter === "Uncompleted" ? "markedTasklist filter-tag cursor-pointer" : " cursor-pointer filter-tag"}> <i className={Filter === "Uncompleted" ? "fas fa-check div-done-blue" : "fas fa-check"} aria-hidden="true" />&nbsp;<FormattedMessage id="Uncompleted" defaultMessage="Uncompleted" /> </div>
                  </div>
                  { addToMilestone && addToMilestone.length > 0 ?
                  <div id="dropdown" style={{ display: "flex", alignItems: "center" }} className="col-8 dropdown-milestone">
                    <MultiSelect
                      options={props.MilestoneResultState}
                      value={SelectOne}
                      hasSelectAll={false}
                      labelledBy="Add to milestone"
                      onChange={(e) => handleChange(e)}
                      filterOptions={filterOptions}
                    />
                    {
                      success ? (
                        <button 
                        onClick={() => close()}
                        className="btn doneButton"
                        >
                          Done
                        </button>
                      ) : SelectOne && SelectOne.length > 0 ? (
                        <button
                          onClick={() => addTasksToSelectedMilestones()}
                          className="btn saveButtonTasklist"
                        >
                          Add to milestone
                        </button>
                      ) : null
                    }
                  </div>
                  : "" }
                  {/*  <div className='filter-right d-flex align-items-center'>
                    <div className='milestone-select'>
                      <Select
                        placeholder={intl.formatMessage({ id: "AddtoMilestone", defaultMessage: "Add to Milestone" })}
                        options={[{ value: "Milestone 1", label: "Milestone 1" }, { value: "Milestone 2", label: "Milestone 2" }, { value: "Milestone 3", label: "Milestone 3" }, { value: "Milestone 4", label: "Milestone 4" }]}
                      />
                    </div>
                    <i class="far fa-thumbs-up"></i>
                    <i class="far fa-thumbs-down "></i>
                  </div> */}
                </div>
                <div style={{ paddingBottom: "400px" }} className="modal-content-wrapper">
                  <div
                    style={{
                      marginLeft: "52px",
                      marginTop: "10px",
                      marginBottom: "10px"
                    }}
                    className="TaskListRowParent" ><h6 style={{ paddingTop: "3px", paddingBottom: "3px", marginBottom: 0, fontWeight: "bold" }}><FormattedMessage id="Tasks" defaultMessage="Tasks" /></h6></div>

                  {Filter === "Uncompleted" ?
                    <>
                      {props.TaskListComp && props.TaskListComp.map((Item, index) => {
                        if (props.allTasks[Item]?.length >= 0) {
                          if (props.allTasks[Item][2] && !avoidItems.includes(Item) && ((!props.allTasks[Item][1] && props.allTasks[Item][10] !== 100) || (props.allTasks[Item][12].includes(58572))) && !avoidItems.includes(Item)) {
                            return (
                                <Collapsible noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} TaskListComp={props.TaskListComp} addToMilestone={addToMilestone} setAddToMilestone={setAddToMilestone} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={20} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                            )
                          } else if (!avoidItems.includes(Item) && (props.allTasks[Item][10] !== 100 && !props.allTasks[Item][1])   /* && !props.allTasks[Item][10] && !props.allTasks[Item][1] */) {
                            return (
                              <>
                                <CollapsibleWithout noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} TaskListComp={props.TaskListComp} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Milestone={milestone} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                              </>
                            )
                          }
                        }
                      })}

                      {customQuestions && customQuestions.map((Item, index) => {
                          if (!avoidItems.includes(Item) && (Item[10] !== 100 && !Item[1])   /* && !props.allTasks[Item][10] && !props.allTasks[Item][1] */) {
                            return (
                              <>
                                <CollapsibleWithoutCustom noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} CustomQuestions={customQuestions} setCustomQuestions={setCustomQuestions} Milestone={milestone} TaskListComp={props.TaskListComp} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                              </>
                            )
                          }
                      })}
                    </>

                    : Filter === "Completed" ?
                      <>
                        {
                          props.TaskListComp && props.TaskListComp.map((Item, index) => {
                            if (props.allTasks[Item]) {
                              if (props.allTasks[Item][2] && !avoidItems.includes(Item)) {
                                return (
                                  <>
                                    <Collapsible noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} TaskListComp={props.TaskListComp} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={20} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                  </>

                                )
                              } else if (!avoidItems.includes(Item) && (props.allTasks[Item][1] || props.allTasks[Item][10] == 100)  /* && !props.allTasks[Item][10] && !props.allTasks[Item][1] */) {
                                return (
                                  <>
                                    <CollapsibleWithout noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} TaskListComp={props.TaskListComp} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Milestone={milestone} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                  </>
                                )
                              }
                            }
                          })
                        }

                        {customQuestions && customQuestions.map((Item, index) => {
                          if (!avoidItems.includes(Item) && (Item[1] || Item[10] === 100)   /* && !props.allTasks[Item][10] && !props.allTasks[Item][1] */) {
                            return (
                              <>
                                <CollapsibleWithoutCustom noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} TaskListComp={props.TaskListComp} CustomQuestions={customQuestions} setCustomQuestions={setCustomQuestions} Milestone={milestone} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                              </>
                            )
                          }
                        })} 
                      </>
                      : Filter === "Audited" ?
                        <>
                          {
                            //Audited Checked
                            props.TaskListComp && props.TaskListComp.map((Item, index) => {
                              if (props.allTasks[Item]) {
                                if (props.allTasks[Item][2] && !avoidItems.includes(Item)) {
                                  return (
                                    <>
                                      <Collapsible noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} TaskListComp={props.TaskListComp} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={20} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                    </>
                                  )
                                } else if (!avoidItems.includes(Item) && (props.allTasks[Item][1] || props.allTasks[Item][10] === 20 || props.allTasks[Item][10] === 100)) {
                                  return (
                                    <>
                                      <CollapsibleWithout noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} Milestone={milestone} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} TaskListComp={props.TaskListComp} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                    </>
                                  )
                                }
                              }
                            })
                          }

                          {customQuestions && customQuestions.map((Item, index) => {
                            if (!avoidItems.includes(Item) && (Item[1] || Item[10] === 20 || Item[10] === 100)   /* && !props.allTasks[Item][10] && !props.allTasks[Item][1] */) {
                              return (
                                <>
                                  <CollapsibleWithoutCustom noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} TaskListComp={props.TaskListComp} CustomQuestions={customQuestions} setCustomQuestions={setCustomQuestions} Milestone={milestone} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                </>
                              )
                            }
                          })} 
                        </>
                        :
                        <>
                          {
                            props.TaskListComp && props.TaskListComp.map((Item, index) => {
                              if (props.allTasks[Item]) {
                                if (props.allTasks[Item][2] && !avoidItems.includes(Item)) {
                                  return (
                                    <>
                                      <Collapsible noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} TaskListComp={props.TaskListComp} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={20} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                    </>
                                  )
                                } else if (!avoidItems.includes(Item) && (!props.allTasks[Item][1] && props.allTasks[Item][10] !== 20 && props.allTasks[Item][10] !== 100)  /* && !props.allTasks[Item][10] && !props.allTasks[Item][1] */) {
                                  return (
                                    <>
                                      <CollapsibleWithout noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} TaskListComp={props.TaskListComp} Milestone={milestone} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                    </>
                                  )
                                }
                              }
                            })
                          }

                          {customQuestions && customQuestions.map((Item, index) => {
                            if  (!avoidItems.includes(Item) && (!Item[1] && Item[10] !== 20 && Item[10] !== 100)  /* && !props.allTasks[Item][10] && !props.allTasks[Item][1] */) {
                              return (
                                <>
                                  <CollapsibleWithoutCustom noTeamwork={props.noTeamwork} dev={props.dev} Tags={props.Result.Tags} idd={props.idd} FinalTags={props.FinalTags} updateMilestoneCounter={props.updateMilestoneCounter} MilestoneResultState={props.MilestoneResultState} TaskListComp={props.TaskListComp} CustomQuestions={customQuestions} setCustomQuestions={setCustomQuestions} Milestone={milestone} setModalImage={props.setModalImage} setCurrentImg={props.setCurrentImg} setSecondScreen={setSecondScreen} Filter={Filter} El={El} setEl={setEl} margin={17} setAllTasks={props.setAllTasks} allTasks={props.allTasks} key={"some" + index} canEdit={props.canEdit} item={Item} index={index} section={props.Result.section} />
                                </>
                              )
                            }
                          })} 
                        </>
                  }

                  {/* <div className="footer-table">
        <div className="footer-pagination pd-16 d-flex justify-content-end align-items-center">
          <div className="rows-wraper d-flex align-items-center">
            <h6 className="mx-8 mb-0">Rows per page</h6>
            <div className="form-group mb-0">
              <select className="form-control form-select" id="exampleFormControlSelect1">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
          <div className="pages-count mx-16">
            <span>1-10 of 678</span>
          </div>
          <div className="arrow-wrapper">
            <span><img src="images/skip_previous.svg" alt /></span><span><img src="images/expand_more.svg" alt /></span><span><img src="images/expand_more2.svg" alt /></span><span><img src="images/skip_next_black.svg" alt /></span>
          </div>
        </div>
      </div> */}
                </div>
              </>
            }
          </div>
        </div>

        {/* <div style={{ padding: "15px" }}>
        <Pagination
          total={paginationOptions.total}
          
          current={paginationOptions.currentPage}
          onChange={onChangePage}
          pageSize={paginationOptions.pageSize} />
      </div> */}
      </>
    );
  } else {
    return ("")
  }
}

export default ProjectTasklist
import axios from 'axios';
import { useEffect, useState } from 'react'
import Cookie from 'js-cookie';
import { useIntl } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import DefaultLocale from "./userLocale"
import CacheComponent from "../cache/CacheComponent"
export default function Settings() {
  const intl = useIntl();
  const [currentPassword, setcurrentPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");
  const [passwordVisibility,setPasswordVisibility] = useState(false)
  const [passwordVisibility1,setPasswordVisibility1] = useState(false)
  const [passwordVisibility2,setPasswordVisibility2] = useState(false)
  const [localeList,setLocaleList] = useState([])
  const [standardLocale, setStandardLocale] = useState("");

  const authToken = localStorage.getItem("authToken");
  const userID = Cookie.get('localeUser');
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const changePasswordRequest = () => {
    if (newPassword.length > 0 && confirmNewPassword.length > 0 && currentPassword.length > 0) {
      if (newPassword === confirmNewPassword) {
        if (authToken && userID) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          };
          axios.post("/api/user/changepassword", { userid: userID, currentPassword: currentPassword, newPassword: newPassword }, config).then(result => {
            if (result.data.success === true) {
              toast.success(intl.formatMessage({ id: "passwordupdatedsuccessfully", defaultMessage: "Password Updated Successfully" }), {
                position: toast.POSITION.BOTTOM_CENTER
              })
            } else if (result.data.success === false) {
              toast.error(intl.formatMessage({ id: "Currentpassworderror", defaultMessage: "Current Password doesnot match!" }), {
                position: toast.POSITION.BOTTOM_CENTER
              })
            }
          });
        }
      } else {
        toast.error(intl.formatMessage({ id: "passwordnotsameerror", defaultMessage: "New Password doesnot match!" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
      }
    } else if (newPassword.length < 4 || newPassword.length > 20 || confirmNewPassword.length < 4 || confirmNewPassword.length > 20) {
      toast.error(intl.formatMessage({ id: "passwordlengtherror", defaultMessage: "Password should be greater than 4 letter and less than 20 characters!" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    } else {
      toast.error(intl.formatMessage({ id: "passwordfieldsrequired", defaultMessage: "Password fields are required" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  }


  const getAllLocales = async () => {
    console.log('getAllLocales')
    await axios.get("/api/getlocales", config).then((response)=> {
      setLocaleList(response.data);
      console.log(response.data);
   }).catch(error => {
    console.error('Error fetching locales:', error);
  });
  }

  const getStandardLocale = async() => {
    return await axios.get('/api/getStandardLocale',config)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error('Error fetching standard locale:', error);
      return null;
    });
  }

  const getuserLocale =  async () =>{
    if(userID){
    return await axios.get(`/api/getUserLocale/${userID}`,config)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error('Error fetching standard locale:', error);
      return null
    });
  }
  return null
  }

  function converttooptions(inputArray) {
    let optionList = [];
    inputArray.map((item) => {
      const { _id, country, locale } = item;
      optionList.push({ label: country + " (" + locale + ")", value: _id });
    });
    return optionList;
  }

  useEffect(() => {
    getAllLocales();

    Promise.all([getStandardLocale(), getuserLocale()])
    .then(([firstResult, secondResult]) => {
      console.log("Promise.all")
      console.log(firstResult)
      console.log(secondResult)
      let setStandardVal = ""
      if(secondResult){
        setStandardVal = secondResult
      }else{
        setStandardVal = firstResult
      }
      setStandardLocale(setStandardVal)
    })
    .catch(error => console.error(error))
  },[])

  return (
    <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 change-password-page">
      <ToastContainer autoClose={6000} />
      <CacheComponent />
      <div className='changepassword-form text-center col-md-4 mx-auto mg-b-15 py-5'>
        <h1 className='main-heading'>Change Password</h1>
        <div className="input-group ">
          <input required type={passwordVisibility ? "text" : "password"} name='currentPassword' placeholder={intl.formatMessage({ id: "currentPasswordPlaceholder", defaultMessage: "Enter your Current Password*" })} onChange={(e) => setcurrentPassword(e.target.value)} value={currentPassword} className='form-control' />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className={passwordVisibility ? "fa fa-eye-slash password-toggle" : "fa fa-eye password-toggle"} onClick={() => { setPasswordVisibility(prevpasswordVisibility => !prevpasswordVisibility) }}></span>
            </div>
          </div>
        </div>

        <div className="input-group ">
        <input required type={passwordVisibility1 ? "text" : "password"} name='newPassword' placeholder={intl.formatMessage({ id: "newPasswordPlaceholder", defaultMessage: "Enter your New Password*" })} onChange={(e) => setnewPassword(e.target.value)} value={newPassword} className='form-control' />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className={passwordVisibility1 ? "fa fa-eye-slash password-toggle" : "fa fa-eye password-toggle"} onClick={() => { setPasswordVisibility1(prevpasswordVisibility1 => !prevpasswordVisibility1) }}></span>
            </div>
          </div>
        </div>

        <div className="input-group ">
        <input required type={passwordVisibility2 ? "text" : "password"} name='confirmnewPassword' placeholder={intl.formatMessage({ id: "confirmPasswordPlaceholder", defaultMessage: "Confirm your New Password*" })} onChange={(e) => setconfirmNewPassword(e.target.value)} value={confirmNewPassword} className='form-control' />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className={passwordVisibility2 ? "fa fa-eye-slash password-toggle" : "fa fa-eye password-toggle"} onClick={() => { setPasswordVisibility2(prevpasswordVisibility2 => !prevpasswordVisibility2) }}></span>
            </div>
          </div>
        </div>          
          <input type="submit" value="Change Password" className='btn cta-btn d-block w-100 mt-3 text-uppercase' onClick={changePasswordRequest} />
        </div>

      <div className='locale-settings  col-md-4 mx-auto mg-b-15 py-5'>
        <h1 className='main-heading text-center'>Locale Settings</h1>
        {(standardLocale !== "") && (localeList.length > 0) &&
          <DefaultLocale options={converttooptions(localeList)} standardLocale={standardLocale} authToken={authToken} userID={userID}/>
        }
      </div>

    </div>
  )
}


import { useIntl, FormattedMessage } from 'react-intl';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import { Radio } from 'antd';
const Step2 = ({ show, step, onHide, prevStep, nextStep, handleFormData, handleSelect, handleRadio, values }) => {
  const intl = useIntl();
  const brandList = [{ value: "Glasurit", label: "Glasurit" }, { value: "RM, PPG", label: "RM, PPG" }, { value: "Spies Hecker", label: "Spies Hecker" }]
  const submitFormData = (e) => {
    e.preventDefault();
    if (values.sprayBooths === "" || values.ventilatedPrepbays === "" || values.paintBrand === "" || values.otherPaintBrand === "" || values.commentCurrentSupplier === "") {
      toast.error("Some fields are missing", {
        position: toast.POSITION.BOTTOM_CENTER
      })
    } else {
      nextStep();
    }
  };
  return (
    <>
      <ToastContainer toastClassName="top-zIndex" autoClose={6000} />
      <div>
        {show && (
          <div id="popup-addNewProject" className="d-block fq-modal fq-modal-small fq-modal-project">
            <div className="fq-modal-content">
              <div className="fq-modal-header">
                <span onClick={onHide} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
                <h5><FormattedMessage id="AddanewassessmentStep2" defaultMessage="Add a new assessment - Step 2" /></h5>
                <p className='mt-2'><FormattedMessage id="Pleasefilloutthefollowingformtocreateanewassessment" defaultMessage="Please fill out the following form to create a new assessment" /></p>
              </div>
              <div className="fq-modal-content-2 pd-20">
                <form className="add-user-wrapper" onSubmit={submitFormData}>
                  <label className='d-block mb-2'><FormattedMessage id="HowmanySprayBooths" defaultMessage="How many Spray Booths?" /></label>
                  <input type="number" name='sprayBooths' placeholder={intl.formatMessage({ id: "noofspraybooths", defaultMessage: "#" })} onChange={handleFormData("sprayBooths")} value={values.sprayBooths} className='form-control mb-3' />
                  <label className='d-block mb-2'><FormattedMessage id="Howmanyventilatedprepbays?" defaultMessage="How many ventilated prep bays?" /></label>
                  <input type="number" name='sprayBooths' placeholder={intl.formatMessage({ id: "noofventilatedprepbays", defaultMessage: "#" })} onChange={handleFormData("ventilatedPrepbays")} value={values.ventilatedPrepbays} className='form-control mb-3' />
                  <div className="d-block radio-group-wrapper mb-3" >
                    <label className='d-block mb-2'><FormattedMessage id="IsBodyandPaintcarriedoutat1location" defaultMessage="Is Body and Paint carried out at 1 location?" /></label>
                    <Radio.Group name="shareKpiData" onChange={handleRadio("shareKpiData")} value={values.shareKpiData} >
                      <Radio value="yes" className='mr-2'><FormattedMessage id="OptionYes" defaultMessage="yes" /></Radio>
                      <Radio value="no" defaultChecked><FormattedMessage id="OptionNo" defaultMessage="no" /></Radio>
                    </Radio.Group>
                  </div>
                  <label className='d-block mb-2'><FormattedMessage id="Whatisthebrandofcurrentpaint" defaultMessage="What is the brand of current paint?" /></label>
                  <Select
                    placeholder={intl.formatMessage({ id: "selectBrand", defaultMessage: "Select Brand" })}
                    options={brandList}
                    onChange={handleSelect("paintBrand")}
                  />
                  <input type="text" name='otherPaintBrand' placeholder={intl.formatMessage({ id: "otherPaintBrand", defaultMessage: "Other" })} onChange={handleFormData("otherPaintBrand")} value={values.otherPaintBrand} className='form-control mb-3 mt-2' />
                  <label className='d-block mb-2'><FormattedMessage id="Whyaretheynothappywithcurrentsupplier" defaultMessage="Why are they not happy with current supplier?" /></label>
                  <textarea rows="3" type="number" name='commentCurrentSupplier' placeholder={intl.formatMessage({ id: "commentCurrentSupplier", defaultMessage: "Add your comment here…" })} onChange={handleFormData("commentCurrentSupplier")} value={values.commentCurrentSupplier} className='form-control mb-3' />
                  <div className='d-flex justify-content-end align-items-center'>
                    <button className='btn btn-primary bg-blue mt-3 mr-2 cursor-pointer' onClick={prevStep} ><FormattedMessage id="PreviousStep" defaultMessage="Previous Step" /></button>
                    <input type="submit" className='btn btn-primary bg-blue mt-3 cursor-pointer' value={intl.formatMessage({ id: "NextStep", defaultMessage: "Next Step" })} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Step2;
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const LeftPanel = (props) => {
  const [LeftPanel, setLeftPanel] = useState(true)
  const [Active, setActive] = useState(true)
  const [AccActive, setAccActive] = useState(false)
  const [onStart, setOnStart] = useState(false)

  const history = useHistory();

  useEffect(() => {
    if (onStart) {
      if (document.body.classList.contains('hide-left')) {
        console.log(document.body.classList)
        document.body.classList.remove("hide-left")
        console.log(document.body.classList)
        return
      } else {
        console.log("yes")
        console.log(document.body.classList)
        document.body.classList.add("hide-left")
        console.log(document.body.classList)
        return
      }
    }
    setOnStart(true)
    return () => {
      document.body.classList.add('hide-left');
    };
  }, [LeftPanel]);

  const showLeftMenufunc = () => {
    setLeftPanel(prevCheck => !prevCheck)
    setAccActive(false)
  }

  const accordionOpen = () => {
    setAccActive(prevAccActive => !prevAccActive)
  }

  const openSite = (e) => {
    function scroll() {
      console.log(e)
      window.scrollTo({ top: 0, behavior: 'smooth' });
      props.SetOpenPage(e)
      if (e === "Overview") history.push(`/project/${props.idd}`);
      if (e === "BodyShop") history.push(`/project/${props.idd}/BodyShop`);
      if (e === "PaintShop") history.push(`/project/${props.idd}/PaintShop`);
      if (e === "CompressedAir") history.push(`/project/${props.idd}/CompressedAir`);
    }
    props.SetOpenPage("")
    setTimeout(scroll, 50);
    // props.SetOpenPage("PaintShop")
  }
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const openSiteScroll = (e, a, b) => {
    e.preventDefault();
    if (a === "KPI") {
      props.SetOpenPage("KPI")
      history.push(`/project/${props.idd}/KPI`);
    }
    console.log(e, a, b)
    setActive(a);
    if (props.openPage !== b) {
      openSite(b)
      e.preventDefault();  // Stop Page Reloading
      const yourFunction = async () => {
        await delay(250);
        let hero = document.getElementById(a);
        hero && hero.scrollIntoView({ behavior: "smooth", block: "start" })
        console.log('Click 1');
      }
      yourFunction()

    } else {
      let hero = document.getElementById(a);
      e.preventDefault();  // Stop Page Reloading
      hero && hero.scrollIntoView({ behavior: "smooth", block: "start" })

    }

  }
  const mobileview = () => {
    if (props.isMobile) {
      return ""
    } else {
      return " leftpanel-desktop"
    }
  }
  return (
    <div className={"leftpanel pos-fixed-left wd-60 l-50 bg-custom-gray z-index-50 top-70px" + mobileview()} style={{ zIndex: 49 }}>
      <div className="leftpanel-menu pos-absolute wd-100p t-5 b-0">
        <div className={LeftPanel ? "secondary-menu-wrapper" : "secondary-menu-wrapper move-right"} >
          <div onClick={() => showLeftMenufunc()} id="showLeftMenu">
            <img src={LeftPanel ? "/images/icons/expand-menu.svg" : "/images/icons/expand-menu-2.svg"} alt />
          </div>
        </div>
        <nav className="flex-column">
          <a
            href="/"
            className={Active === "BodyShop Overview" ? "active" : ""}
            onClick={e => {
              openSiteScroll(e, "BodyShop Overview", "Overview")
            }}
          >

            <div className="d-flex">
              <img src="/images/icons/info_black_24dp.svg" alt="info icon" />
              <div className="pl-15 text-menu"><FormattedMessage id="BodyShopOverview" defaultMessage="BodyShop Overview" /></div>
            </div>
          </a>
          <a
            href="/"
            className={Active === "Business Analytics" ? "active" : ""}
            onClick={e => {
              openSiteScroll(e, "Business Analytics", "Overview")
            }}
          >
            <div className="d-flex">
              <img src="/images/icons/analytics_black_24dp.svg" alt="info icon" />
              <div className="pl-15 text-menu"><FormattedMessage id="BusinessAnalytics" defaultMessage="Business Analytics" /></div>
            </div>
          </a>



          <a
            href="/"
            className={Active === "Facility + Equipment" ? "active" : ""}
            onClick={e => {
              openSiteScroll(e, "Facility + Equipment", "Overview")
            }}
          >
            <div className="d-flex pos-rel">
              <img src="/images/icons/menu/facility-equipment.svg" alt="info icon" />
              <div className="pl-15 text-menu"><FormattedMessage id="FacilityEquipmentPaint" defaultMessage="Facility + Equipment - Paint.." /></div>
              <div className="accordion-arrow" onClick={() => accordionOpen()}><i className="fa fa-angle-down" aria-hidden="true"></i></div>
            </div>
          </a>

          <div id="accordion-menu" className={AccActive === true ? "show" : "collapse"} >
            <a onClick={e => {
              openSiteScroll(e, "BodyShop", "BodyShop")
            }}
              className={Active === "BodyShop" ? "active d-flex" : "d-flex"}
              style={{ cursor: "Pointer" }} /*  className="collapsed first-list" role="button" data-toggle="collapse" href="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1" */>
              <div className="d-flex">
                <img src="/images/icons/menu/body-shop.svg" alt="info icon" />
                <div className="pl-15 text-menu"><FormattedMessage id="BodyShop" defaultMessage="Body Shop" /></div>
              </div>
            </a>

            <a onClick={e => {
              openSiteScroll(e, "PaintShop", "PaintShop")
            }}
              className={Active === "PaintShop" ? "active d-flex" : "d-flex"}
              style={{ cursor: "Pointer" }} >
              <div className="d-flex">
                <img src="/images/icons/menu/paint-shop.svg" alt="info icon" />
                <div className="pl-15 text-menu"><FormattedMessage id="PaintShop" defaultMessage="Paint Shop" /></div>
              </div>
            </a>

            <a onClick={e => {
              openSiteScroll(e, "CompressedAir", "CompressedAir")
            }}
              className={Active === "CompressedAir" ? "active d-flex" : "d-flex"}
              style={{ cursor: "Pointer" }} >
              <div className="d-flex">
                <img src="/images/icons/menu/compressor.svg" alt="info icon" />
                <div className="pl-15 text-menu"><FormattedMessage id="Compressor" defaultMessage="Compressor" /></div>
              </div>
            </a>



          </div>





          <a
            href="/"
            className={Active == "Business + Workshop Processes" ? "active" : ""}
            onClick={e => {
              openSiteScroll(e, "Business + Workshop Processes", "Overview")
            }}
          >
            <div className="d-flex">
              <img src="/images/icons/menu/B_W_Processes.svg" alt="info icon" />
              <div className="pl-15 text-menu"><FormattedMessage id="BusinessWorkshopProcesses" defaultMessage="Business + Workshop Processes" /></div>
            </div>
          </a>
          <a
            href="/"
            className={Active == "Repair Quality" ? "active" : ""}
            onClick={e => {
              openSiteScroll(e, "Repair Quality", "Overview")
            }}>
            <div className="d-flex">
              <img src="/images/icons/menu/repair-quality.svg" alt="info icon" />
              <div className="pl-15 text-menu"><FormattedMessage id="RepairQuality" defaultMessage="Repair Quality" /></div>
            </div>
          </a>
          <a
            href="/"
            className={Active == "Training" ? "active" : ""}
            onClick={e => {
              openSiteScroll(e, "Training", "Overview")
            }}
          >
            <div className="d-flex">
              <img src="/images/icons/menu/training.svg" alt="info icon" />
              <div className="pl-15 text-menu"><FormattedMessage id="Training" defaultMessage="Training" /></div>
            </div>
          </a>
          <a
            href="/"
            className={Active == "KPI" ? "active" : ""}
            onClick={e => {
              openSiteScroll(e, "KPI", "Overview")
            }}
          >
            <div className="d-flex">
              <img src="/images/icons/menu/KPI-dashboard.svg" alt="info icon" />
              <div className="pl-15 text-menu"><FormattedMessage id="KPI" defaultMessage="KPI" /></div>
            </div>
          </a>
          {/*  <a 
            href="/"
            onClick={e => {
            let hero = document.getElementById("Facility + Equipment - Paint..");
            e.preventDefault();  // Stop Page Reloading
            hero && hero.scrollIntoView({ behavior: "smooth", block: "start"});
            }}
            >
              
            </a> */}

        </nav>
      </div>
    </div>
  );

}

export default LeftPanel;
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";

import axios from 'axios';


const DefineGlobalAssessments = (props) => {
    const [checked, setChecked] = useState(false)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        /*    console.log(props.newTags)
           console.log(props.item["id"]) */
        if (props.newTags.includes(parseInt(props.item["_id"]))) {
            setChecked(prevState => !prevState)
        }
        setReady(true)
       

    }, [])

const editAssessments = (e) => {

}


    if (ready) {
        return (

            <>

                <td style={{ width: "100%", display: "flex", alignItems: "center", borderLeft: "0px", borderRight: "0px", borderBottom: "1px solid #e9ecef" }} className="TableFlex sorting_1">

                    <div className='col-md-4'>{props.item["Name"]}</div>
                    <div className="col-md-3">
                     
                   {props.item["Name"] !== "Standard" ? <img onClick={()=>props.deleteIt(props.item["_id"])} src="/images/icons/new/trash_bin_black_24dp.svg" style={{ position: 'relative',cursor: 'pointer', zIndex: '999' }} /> : ""  }
                    <img onClick={()=>props.setTagsModal({Name : props.item["Name"], Tags: props.item["Tags"], _id: props.item["_id"] })} src="/images/icons/new/edit_black_24dp.svg" style={{ position: 'relative', cursor: 'pointer', zIndex: '999' }} /> 
                    
                   
                       
                    </div>
                    {/* <div className="form-check">
                        <input checked={checked}
                            onChange={(e) => handleInputChange(e)}
                            type="checkbox"
                            className="form-check-input"
                        />
                      

                    </div> */}


                </td>

            </>

        )
    } else {
        return (
            ""
        )
    }



}
export default DefineGlobalAssessments


import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import axios from 'axios';

const Owldemo4 = (props) => {
  const openModalPics = (pictures, Index) => {
    if (props.noTeamwork && pictures[Index][0].toString().length > 7) {
      let imgs
      if (pictures[Index][0]) {
        imgs = pictures[Index][0]
      } else {
        imgs = pictures[Index]
      }

      props.setCurrentImg([imgs, Index])
      props.setModalImage(pictures)
      return
    }else{
      console.log(pictures[Index][0].toString().length)
    let Img = pictures[Index][0]
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    try {
      axios.get(`/api/private/filesName/${Img}`, config).then(res => {
        const image = res.data.file["download-URL"]
        props.setCurrentImg([image, Index])
        props.setModalImage(pictures)
      }
      )
    } catch (e) {
      console.log(e)
      if (!props.dev) {
        localStorage.removeItem("authToken");
        window.location.href = '/logout';
      }
    }
  }
  }

  if (props.Pictures.length == 0) {
    return (
      <>
        <div className="item fillcontainer">
          <img src="/images/image-placholder.svg" alt="placeholder" style={{ minHeight: "140px" }}/>
        </div>
      </>
    )
  } else {
    return (
      <>
       <Swiper navigation={true} modules={[Navigation]} className="mySwiper picture-slider" breakpoints={{0: { slidesPerView: 1, spaceBetween: 0 }}}>
        {props.Pictures.map((item, Index) => {
          if((typeof item[1] !==  'undefined') && (item[1] !== null )){
          return (
            <SwiperSlide>
            <div className="item">
              <img className="imgfill" onClick={() => openModalPics(props.Pictures, Index)} src={item[1]} alt="img" />
            </div>
            </SwiperSlide>
          )
          }
        })}

      </Swiper>

      </>
    )
  }
}


export default Owldemo4
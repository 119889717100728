import React from "react";
import { FormattedMessage } from "react-intl";
import checkPercentage from '../../../sharedFunctions.js/checkpercentage'

function CardProject(props) {
  const { bgClass, translationId, translationMessage, percentage, classes, value } = props;
  return (
    <div className={`card rounded-2 pd-16 border-gray border-radius-16 ${bgClass} ${classes}`}>
      <div className="card-body d-flex flex-column">
        <h3 className="card-c-heading">
          <FormattedMessage id={translationId} defaultMessage={translationMessage} />
        </h3>
        {percentage &&
          <p className="stats mt-auto mb-0">
            {checkPercentage(percentage)}%
          </p>
        }
        {value &&
        <p className="stats mt-auto mb-0">
        {value}
      </p>
        }
      </div>
    </div>
  );
}

export default CardProject;
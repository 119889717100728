import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { MultiSelect } from "react-multi-select-component";


import axios from 'axios';

const TextModule = (props) => {
  const node = useRef();
  const nodeTwo = useRef();
  const [Editable, setEditable] = useState(true)
  const [Value, setValue] = useState("")
  const [Load, setLoad] = useState(true);
  const activePointRef = useRef(Value);

  const location = useLocation();
  
  const iOS = () => {
    return [
      'iPhone Simulator',
      'iPhone',
    ].includes(navigator.platform)
  }

  const selectInput = () => {
    nodeTwo.current.focus();
    if (!iOS()) {
      nodeTwo.current.setSelectionRange(0, 9999);
    }
  };

  useEffect(() => {
    if(Load) {
      selectInput(); 
    }
  }, [selectInput])

  useEffect(() => {
    if (props.onload) {
      setValue(props.onload)
    } else {
      setValue(props.text)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = e => {
    setLoad(false);
  }

  const handleClick = e => {
    if ((node.current && node.current.contains(e.target)) || (node.current && node.current.attributes[0].value == "display: none;")) {
      nodeTwo.current.focus();
      if (!iOS()) {
        nodeTwo.current.setSelectionRange(0, 9999);
      }
      return;
    }

    props.SetTextModuleState(false)
    if (Value == activePointRef.current) {
      return
    }
    if (nodeTwo && nodeTwo.current) {
      nodeTwo.current.blur();
    }

    const NameOfTask = props.allTasks[props.item][0]
    let progress
    progress = 0
    const PostData = {
      NameOfTask: NameOfTask,
      Textbody: activePointRef.current,
      Progress: progress,
      keyOfTask: props.item,
      activityUrl: location.pathname,
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    PostData["ProjectID"] = props.idd
    const req = axios.post(`/api/private/queue/comments/${NameOfTask}`, PostData, config)
      .then((response) => {
        console.log(response.data)

        if (props.item == "1.1.1") {
          console.log("test")
          let PutData = {
            newName: activePointRef.current
          }

          const req = axios.put(`/api/private/changeName/${props.idd}`, PutData, config)
            .then((response) => {
              console.log(response.data)
            })

        }

        let newItem = [...props.allTasks[props.item]]

        newItem[7] = activePointRef.current
        console.log(newItem)
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.item]: newItem
        }));


      }, (error) => {
        console.log(error)
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }

      })

  };

  const handleOnChangeWeight = (e) => {
    setValue(e.target.value)
    activePointRef.current = e.target.value
  }

  return (

    <>
      <div className={props.css} ref={node} style={{
        display: Editable ? 'inline-block' : 'none',
        position: "relative"
      }}>

        <input
          style={{
            cursor: "pointer",
            height: "25px",
            textAlign: "right",
            color: "black"
          }}
          
          type="text"
          inputMode={props.onlyNumber ? "decimal" : ""}
          pattern={props.onlyNumber ? "[0-9]*" : ""}
          ref={nodeTwo}
          id={`custom-text-${props.index}`}
          className="tagBay"
          value={Value.replace(',', '.')}
          minLength={1} maxLength={40}
          onChange={(e) => handleOnChangeWeight(e)}
        />

      </div>
      <div
        className={props.css}
        style={{ display: !Editable ? 'inline-block' : 'none' }}
      >
        <span
          style={{ marginRight: "4px" }}
        >{Value}</span></div>

    </>

  )

}
export default TextModule

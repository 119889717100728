import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { MultiSelect } from "react-multi-select-component";


import axios from 'axios';

const TextPlusMinusModule = (props) => {
    console.log(props)
    const node = useRef();
    // const [Editable, setEditable] = useState(false)
    const [Value, setValue] = useState(props.onload == false ? 0 : parseFloat(props.onload))
    const [Editable, setEditable] = useState(true)
    const activePointRef = useRef(!props.onload ? 0 : parseFloat(props.onload));

    const location = useLocation();
    //  console.log(activePointRef)
    /*  const [ready, setReady] = useState(true)
     const [cantBeSaved, setCantBeSaved] = useState(false)
  */

    useEffect(() => {
        console.log(props)
        if (props.onload || props.onload == 0) {
            //     console.log(props)
            //   console.log(props.someProps)
            //    console.log(props.onload == 0)
            //   console.log(parseInt(props.onload))
            //setValue()
        }
    }, [])

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);


    const handleClick = e => {

        // console.log()
        if (node.current.contains(e.target) || node.current.attributes[0].value == "display: none;") {
            // inside click
            return;
        }
        props.SetTextModuleState(false)
        if (Value == activePointRef.current) {
            return
        }
        // outside click 
        /*    console.log(Value)
          console.log("outside")  */


        // console.log(activePointRef)
        console.log(activePointRef.current)
        console.log(props.item)
        const NameOfTask = props.allTasks[props.item][0]
        let progress
        progress = 0
        const PostData = {
            NameOfTask: NameOfTask,
            Textbody: activePointRef.current,
            Progress: progress,
            keyOfTask: props.item,
            activityUrl: location.pathname,
        }


        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };
        PostData["ProjectID"] = props.idd
        const req = axios.post(`/api/private/queue/comments/${NameOfTask}`, PostData, config)
            .then((response) => {
                console.log("Comments response text")
                console.log(response)
                console.log(response.data)
                //  setTextField("")
                //  setSaved(true)
                //  setloadingSpinner(false)






                let newItem = [...props.allTasks[props.item]]

                newItem[7] = activePointRef.current
                console.log(newItem)
                props.setAllTasks((prevState) => ({
                    ...prevState,
                    [props.item]: newItem
                }));



            }, (error) => {
                console.log(error)
                if (!props.dev) {
                    localStorage.removeItem("authToken");
                    window.location.href = '/logout';
                }

            })




    };

    const handleOnChangeWeight = (e) => {

        if (Number(e.target.value) || e.target.value == "" || e.target.value == 0) {
            if (Number(e.target.value)) {
                /*  props.setWeight(prevState => ({
                     ...prevState,
                     [props.somekey]: parseFloat(e.target.value)
                 })); */
                setValue(parseFloat(e.target.value))
                activePointRef.current = parseFloat(e.target.value)
            } else {
                /*  props.setWeight(prevState => ({
                     ...prevState,
                     [props.somekey]: parseInt(0)
                 })); */
                // setWeight(parseInt(0))
                setValue(e.target.value)
                activePointRef.current = e.target.value
            }


        }

    }


    const plusOne = (e) => {
        /*    console.log("plus")
           console.log(Value) */


        setValue(prevState => {
            if (!Number(prevState)) {
                // activePointRef.current = 1
                return 0.5
            } else {
                // activePointRef.current++
                return prevState + 0.5
            }

        })
        activePointRef.current = activePointRef.current + 0.5


    }
    const minusOne = (e) => {
        /*   console.log("plus")
          console.log(Value) */
        //   console.log(activePointRef)
        if (activePointRef.current > 0) {
            activePointRef.current = activePointRef.current - 0.5
        }


        setValue(prevState => {
            if (prevState > 0) {
                //  activePointRef.current -- 
                return prevState - 0.5
            } else {

                return prevState
            }

        })

    }



    return (

        <>
            <div ref={node} style={{
                display: Editable ? 'flex' : 'none',
                position: "relative"
            }}>
                <div style={{
                    width: "30px",
                    display: "flex",
                    position: "relative",
                    marginLeft: "auto"
                }}>
                    <i onClick={minusOne} className="texticon-minus" style={{
                        right: "35px",
                        padding: "5px",
                        top: "-2px", position: "absolute", cursor: "pointer"
                    }} class="fas fa-minus"></i>

                    <input
                        style={{
                            cursor: "pointer",
                            width: "30px",
                            height: "21.5px",
                            textAlign: "right",
                            color: props.style == "red" ? "#BE0312" : "unset"
                        }}
                        className="text-analytics"
                        type="text" pattern="^[1-9]\d*(\.\d+)?$"
                        id={`custom-text-${props.index}`}
                        value={Value}
                        minLength={1} maxLength={props.maxLength ? props.maxLength : 3}
                        onChange={(e) => handleOnChangeWeight(e)}
                    />

                    <i onClick={plusOne} className="texticon-plus" style={{
                        cursor: "pointer",
                        padding: "5px",
                        right: "-24px",
                        top: "-2px",
                        position: "absolute"
                    }} class="fas fa-plus"></i>
                </div>
                <div
                    style={{ display: !Editable ? 'block' : 'none' }}
                    onClick={() => setEditable(true)}>
                    <span
                        style={{ marginRight: "4px" }}
                    >{Value}</span></div>

            </div>




        </>

    )



}
export default TextPlusMinusModule

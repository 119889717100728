import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

const ShowUserName = (props) => {
  

  if (props.tasks) {


    return (
      <>
        
        {/* left */}
        <div className="headpanel pos-fixed-top ht-70 l-xl-320 bg-primary z-index-150" style={{ left: 0 }}>
          <div className="d-flex align-items-center justify-content-between ht-100p pd-x-20">
            {/* <a id="showLeftMenu" href="" class="tx-white mg-r-20 hidden-lg-down"
    ><i class="icon ion-navicon-round tx-size-24"></i
    ></a> */}
            <div className>&nbsp;</div>
            {/* <a id="showLeftMenuMobile" href="" class="tx-white mg-r-20 hidden-xl-up"
    ><i class="icon ion-navicon-round tx-size-24"></i
    ></a> */}
            <nav className="nav nav-inline align-items-center justify-content-end flex-row">
              <div className="dropdown">
                <a href className="nav-link tx-white pd-x-0 tx-size-13" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="/images/profile-circle.png" className="rounded-circle wd-32" alt />
                </a>
                <div className="dropdown-menu dropdown-menu-right  tx-size-13 bd-0 mg-t-10 rounded-top-0 shadow-none ">
                  <div className="media dropdown-media">
                    <img src="/images/icons/avatar-black.svg" className="rounded-circle d-flex wd-48 mg-r-15" alt="Image" />
                    <div className="media-body pd-t-5">
                      <h6 className="mg-b-2 tx-inverse tx-semibold">{props.Username}</h6>
                      {/* <span className="tx-size-xs">cosimo@elecos.de</span> */}
                      <span><u>Datenschutzerklärung</u></span>
                    </div>{/* media-body */}
                  </div>
                  <a className="dropdown-item dropdown-media d-flex justify-content-start tx-gray-dark" href="#"><img src="/images/icons/settings_black_24dp.svg" className="rounded-circle d-flex wd-24 mg-r-15" alt="Image" /><FormattedMessage id="Settings" defaultMessage="Settings" /></a>
                  <a className="dropdown-item dropdown-media d-flex justify-content-start tx-gray-dark" href="/logout"><img src="/images/icons/logout_black_24dp.svg" className="rounded-circle d-flex wd-24 mg-r-15" alt="Image" /><FormattedMessage id="Logout" defaultMessage="Logout" /></a>

                  {/* <a className="" href="#">Logout</a> */}
                </div>
                {/* dropdown-menu */}
              </div>
              {/* dropdown */}
            </nav>
          </div>
        </div>

        
        </>
        );
  }
        else {
    return "";
  }
}


        export default ShowUserName;
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import Cookie from "js-cookie";
import { isLocale } from "./language.utils";

const LanguageContext = React.createContext({} as any);

export const LanguageProvider = ({ children, messages }) => {
  const [locale, setLocale] = useState(Cookie.get("locale"));
  const changeLanguage = (newLocale): void => {
    setLocale(newLocale);
    document.documentElement.lang = newLocale;
    Cookie.set("locale", newLocale);
  };

  useEffect(() => {
    const localSetting = Cookie.get("locale");
    if (localSetting === undefined) {
      Cookie.set("locale", "en");
      setLocale("en");
    }

    if (localSetting && isLocale(localSetting)) {
      document.documentElement.lang = localSetting;
      setLocale(localSetting);
    }
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLocale = () => React.useContext(LanguageContext);

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

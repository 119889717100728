import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox"
import axios from "axios";
import { useIntl, FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';

const Renumbering = (props) => {
  const intl = useIntl();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [searchUser,setSearchUser] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    }
  };

  useEffect(() => {
    if(searchUser.length === 0)
      setList(props.users);
    if(props.show === true)
      document.body.classList.add('overflow-hidden');
  });

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map(li => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const handleClick = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const saveChangeRights = () => {
    axios.post('/api/rights/addrenumbering', { userids: isCheck }, config).then(result => {
      if(result.data === true)
        toast.success(intl.formatMessage({ id: "SuccessfullyRenumberingRightsAdded", defaultMessage: "Successfully! Renumbering Rights Added" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
        props.onHide()
    }).catch(err => {
    });
  }
  const onChangeListValue = (val) => {
    setSearchUser(val);
    filterSearchUsers()
  }
  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      filterSearchUsers();
    }
  }
  const filterSearchUsers = () => {
    if(searchUser.length > 0){
      let temparr = [];
      list.map((item,index) => {
        if(item.username.toString().toLowerCase().includes(searchUser.toString().toLowerCase())){
          temparr.push(item);
        }
      })
      setList(temparr);
    }else{
      setList(props.users);
    }
  }
  return (
    <div>
    <ToastContainer toastClassName="top-zIndex" autoClose={3000} />
      {props.show && (
        <div id={"popup-" + props.heading} className="d-block fq-modal fq-modal-xs versioning-popup">
          <div className="fq-modal-content">
            <div style={{ background: "#194A96" }}
              className="fq-modal-header">
              <span onClick={props.onHide} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
              <h1><FormattedMessage id={props.heading.replace(/\s/g, '')} defaultMessage={props.heading} /> </h1>
            </div>
            <div className="content-header">
                <input className="form-control" placeholder="Search for the user..." onChange={(e) => onChangeListValue(e.target.value)} value={searchUser} onKeyPress={enterPressed.bind(this)} />
                <div className="d-flex align-items-center justify-content-between mb-0 mt-3">
                  <p><FormattedMessage id="SelectAllusers" defaultMessage="Select All users" /></p>
                  <Checkbox
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    handleClick={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </div>
                <hr />
              </div>
            <div className="inner-content pd-20">
              {
                 list.map((item, key) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between mb-2 each-single-checkbox">
                        <h4 className="text-capitalize">{item.username}</h4>
                        <Checkbox
                          key={item._id}
                          type="checkbox"
                          name={item.username}
                          id={item._id}
                          handleClick={handleClick}
                          isChecked={isCheck.includes(item._id)}
                        />
                      </div>
                    </>
                  );
                  })

              }
            </div>
            <div className="fq-modal-footer">
              <button className="btn cta-btn" onClick={saveChangeRights}><FormattedMessage id="GrantAccess" defaultMessage="Grant Access" /></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Renumbering
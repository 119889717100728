import React from "react";
import { FormattedMessage } from "react-intl";
import checkPercentage from '../../../sharedFunctions.js/checkpercentage'

function CardProject(props) {
  const { bgClass, translationId, translationMessage, percentage, classes, value } = props;
  return (
    <div className={`card h-100 rounded-2 pd-16 border-gray border-radius-16 projects-kpi-outer ${bgClass} ${classes}`}>
      <div className="card-body">
        <div className="row projects-kpi-inner-cards">
          <div className="col-lg-6 col-md-6 col-sm-12 kpi-col">
            <div className="kpi-cards-stats green">
              <h3 className="card-c-heading">
                <FormattedMessage id="PartsSales" defaultMessage="Parts Sales" />
              </h3>
              <p className="stats mb-0">0</p>
              <div className="stats-icon">
                <div className="icon-img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 96 96"><path d="M0,0H96V96H0Z" fill="none" /><path d="M58.082,6l9.173,9.093L47.706,34.471,31.683,18.588,2,48.052l5.648,5.6L31.683,29.825,47.706,45.709,72.943,20.732l9.173,9.093V6Z" transform="translate(5.942 18.175)" fill="#d3d3d3" className="fill" /></svg>
                </div>
                <span className="span-text">+0 %</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 kpi-col">
            <div className="kpi-cards-stats green">
              <h3 className="card-c-heading">
                <FormattedMessage id="PaintLiquidSales" defaultMessage="Paint Liquid Sales" />
              </h3>
              <p className="stats mb-0">0</p>
              <div className="stats-icon">
                <div className="icon-img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 96 96"><path d="M0,0H96V96H0Z" fill="none" /><path d="M58.082,6l9.173,9.093L47.706,34.471,31.683,18.588,2,48.052l5.648,5.6L31.683,29.825,47.706,45.709,72.943,20.732l9.173,9.093V6Z" transform="translate(5.942 18.175)" fill="#d3d3d3" className="fill" /></svg>
                </div>
                <span className="span-text">+0 %</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 kpi-col">
            <div className="kpi-cards-stats red">
              <h3 className="card-c-heading">
                <FormattedMessage id="Carsperweek" defaultMessage="Cars per week" />
              </h3>
              <p className="stats mb-0">0</p>
              <div className="stats-icon">
                <div className="icon-img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 96 96"><path d="M0,0H96V96H0Z" fill="none" /><path d="M58.082,6l9.173,9.093L47.706,34.471,31.683,18.588,2,48.052l5.648,5.6L31.683,29.825,47.706,45.709,72.943,20.732l9.173,9.093V6Z" transform="translate(5.942 18.175)" fill="#d3d3d3" className="fill" /></svg>
                </div>
                <span className="span-text">+0 %</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 kpi-col">
            <div className="kpi-cards-stats green">
              <h3 className="card-c-heading">
                <FormattedMessage id="Total#ofcars" defaultMessage="Total # of cars" />
              </h3>
              <p className="stats mb-0">0</p>
              <div className="stats-icon">
                <div className="icon-img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 96 96"><path d="M0,0H96V96H0Z" fill="none" /><path d="M58.082,6l9.173,9.093L47.706,34.471,31.683,18.588,2,48.052l5.648,5.6L31.683,29.825,47.706,45.709,72.943,20.732l9.173,9.093V6Z" transform="translate(5.942 18.175)" fill="#d3d3d3" className="fill" /></svg>
                </div>
                <span className="span-text">+0 %</span>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default CardProject;
import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PopupAdd from './TranslationWeightPopupAdd';
import PopupDivide from './TranslationWeightPopupDivide';
import PopupMultiply from './TranslationWeightPopupMultiply';
import Multiply from './TranslationWeightMultiply';
import { MultiSelect } from "react-multi-select-component";
import { useIntl, FormattedMessage } from 'react-intl';
import Select, { createFilter } from 'react-select';
import CustomOption from './CustomOption';




const TranslationWeightPopup = (props) => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const [Header, setHeader] = useState(props.Translation[props.TranslationModal] ? props.Translation[props.TranslationModal] : [])
  const [List, setList] = useState([])
  const [checkedState, setCheckedState] = useState([])
  /*  const [Weight, setWeight] = useState([]) */
  const [Additional, setAdditional] = useState("")
  const [AdditionalDivide, setAdditionalDivide] = useState(["", true]) //useState(["", true])
  const [AdditionalMultiply, setAdditionalMultiply] = useState(1) //useState(["", true])
  const [AdditionalDivideTwo, setAdditionalDivideTwo] = useState(["", true]) //useState(["", true])
  const [AdditionalMultiplyTwo, setAdditionalMultiplyTwo] = useState(1) //useState(["", true])
  const [Tasks, setTasks] = useState([])
  const [TasksTwo, setTasksTwo] = useState([])
  const [Searched, setSearched] = useState(false)
  const [divide, setDivide] = useState(false)
  const [divideTwo, setDivideTwo] = useState(false)
  const [multiply, setMultiply] = useState(false)
  const [multiplyTwo, setMultiplyTwo] = useState(false)
  const [Saved, setSaved] = useState(true)
  const [Radio, setRadio] = useState("")
  const [CommentCheck, setCommentCheck] = useState(9000)
  const [BayCommentParent, setBayCommentParent] = useState(false)
  const [Milestone, setMilestone] = useState(false)
  const [SelectOne, setSelectOne] = useState({})
  const [SelectTwo, setSelectTwo] = useState([])
  const [possibleTags, setPossibleTags] = useState(false);
  const [clickable, setClickable] = useState(false);

let changeNames = {
    "PaintShopBaysRadio" : "Furniture", "PaintShopBaysTools" : "Tools", "PaintShopBaysAir" : "Dust Extraction", "PaintShopBaysSteam" : "Air", "PaintShopBaysElectricity" : "Electricity", "PaintShopBaysWaste" : "Trash", "PaintShopBaysLiftOne" : "Lift One", "PaintShopBaysLiftTwo" : "Pulling Bay","PaintShopBaysLamp" : "Light" , "PaintShopBaysLiftFour" : "Fume Extraction", "PaintShopBaysLiftThree" : "Lift Two", "PaintShopBaysLiftFive" : "Masking Paper Dispenser" ,
    "BodyShopBaysRadio" : "Furniture", "BodyShopBaysTools" : "Tools", "BodyShopBaysAir" : "Dust Extraction", "BodyShopBaysSteam" : "Air", "BodyShopBaysElectricity" : "Electricity", "BodyShopBaysWaste" : "Trash", "BodyShopBaysLiftOne" : "Lift One", "BodyShopBaysLiftTwo" : "Pulling Bay", "BodyShopBaysLamp" : "Light", "BodyShopBaysLiftFour" : "Fume Extraction", "BodyShopBaysLiftThree" : "Lift Two", "BodyShopBaysLiftFive" : "Masking Paper Dispenser"

  }


  const DefaultItemRenderer = ({
    checked,
    option,
    onClick,
    /*  disabled, */
  }) => (
    <div className={`item-renderer `}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}


      />
      <span>{option.label}</span>
    </div>
  );




  // console.log(props.Translation[props.TranslationModal])
  useEffect(() => {
    console.log(props)
    if (props.TranslationModal == "ProjectedWeeklyJobsCalcSpecial") {
      console.log("test")
    }
    let FilteredTagsArr = []
    const fetch = async () => {
      const AllTagsFetch = axios.get(`/api/private/allTags/${props.idd}`, config);
      const SelectedTagsFetch = axios.get(`/api/private/selectedTags`, config);
      try {
        const [AllTags, SelectedTags] = await Promise.all([AllTagsFetch, SelectedTagsFetch])
        console.log(AllTags)
        console.log(SelectedTags)
        let FilteredTags = AllTags.data.tags.filter(item => {
          return SelectedTags.data.includes(parseInt(item["id"]))
        })

        FilteredTags.map(item => {
          FilteredTagsArr.push({ label: item["name"], value: item["id"] })
        })
        setPossibleTags(FilteredTagsArr)
        setClickable(true)
        console.log(FilteredTagsArr)
      } catch (e) {
        console.log(e)
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      } finally {




      }


    }
    fetch()
    console.log(props)
    /*   console.log("translation")
      console.log(props.Translation)
      console.log("translationModal")
      console.log(props.TranslationModal) */



    setBayCommentParent(props.TranslationAddon)
    const sendTasks = (e) => {
      // console.log(props.TranslationModal)
      let arr = []
      let arrTwo = []

      Object.entries(props.allTasks).map(item => {
        // console.log(item)
        arr.push({ label: item[1][3], value: item[0] })
        arrTwo.push({ label: item[1][3], value: item[0] })
      })
      arr.unshift(
        { label: "All", value: "all" },
        { label: "1. Business and Capacity Information", value: "1." },
        { label: "2. Facility + Equipment", value: "2." },
        { label: "3. Business + Workshop Processes ", value: "3." },
        { label: "4. Repair Quality", value: "4." },
        { label: "5. Training", value: "5." },
      )
      arrTwo.unshift(
        { label: "All", value: "all" },
        { label: "1. Business and Capacity Information", value: "1." },
        { label: "2. Facility + Equipment", value: "2." },
        { label: "3. Business + Workshop Processes ", value: "3." },
        { label: "4. Repair Quality", value: "4." },
        { label: "5. Training", value: "5." },
      )
      setTasksTwo(arrTwo)
      if (props.TranslationModal == "ProjectedWeeklyJobsCalcSpecial" ||
        props.TranslationModal == "ProjectedWeeklyJobsCalcSpecialOne" ||
        props.TranslationModal == "ProjectedWeeklyJobsCalcSpecialTwo" ||
        props.TranslationModal == "ProjectedWeeklyJobsCalcSpecialThree") {
        arr.unshift(
          { label: "Remove Refit Bay", value: "RemoveRefitBay" },
          { label: "General Repair Bay", value: "GeneralRepairBay" },
          { label: "Heavy Repair Bay", value: "HeavyRepairBay" },
          { label: "Aluminium Bay", value: "AluminiumBay" },
          { label: "Preparation Bay Standard", value: "PreparationBayStandard" },
          { label: "Preparation Bay Semi", value: "PreparationBaySemi" },
          { label: "Preparation Bay Full", value: "PreparationBayFull" },
          { label: "Masking Bay", value: "MaskingBay" },
          { label: "Spray Booth", value: "SprayBooth" },
          { label: "Polishing Bay", value: "PolishingBay" },
          { label: "Detailing Bay", value: "WashBaysResult" },
          { label: "Mechanical Bay", value: "MechanicalBay" },
          { label: "Spray Booth Separate Drying", value: "SprayBoothSeparateDrying" },
          { label: "Combi Spray Booth Standard", value: "CombiSprayBoothStandard" },
          { label: "Combi Spray Booth Infrared", value: "CombiSprayBoothInfrared" },
          { label: "Total Working Hours By 8", value: "TotalWorkingHoursBy8" }



        )
      }
      setTasks(arr)
      if (![props.Translation[props.TranslationModal]][3] || props.Translation[props.TranslationModal][3] == []) {
        console.log(FilteredTagsArr)
        setSelectTwo(FilteredTagsArr)
      }


      if (props.onlyMilestone == "true") {
        console.log("test")
        setMilestone(true)
        setRadio("Percentage")
        console.log(props)
        //  setHeader([props.TranslationModal])
        //console.log(props.TranslationModal)
        let obj = arr.find(o => o.value === props.TranslationModal[1]);
        console.log(obj)
        if (obj) {
          setSelectOne([obj])
        }
        console.log()
        let objTwo
        if (props.TranslationModal[3] && props.TranslationModal[3] !== []) {

          objTwo = props.TranslationModal[3]

          setSelectTwo(objTwo)
        }

        let objThree

        if (props.TranslationModal[2] && props.TranslationModal[2] !== []) {
          objThree = props.TranslationModal[2]
          setAdditional(objThree)
        }

        /*  let objThree
         if (props.TranslationModal[3] && props.TranslationModal[3] !== []) {
        
           objTwo = props.TranslationModal[3]
        
           setSelectTwo(objTwo)
         }  */

        /*  if (Array.isArray(props.milestoneTranslation[1])) {
           console.log("there")
           setList(props.milestoneTranslation[1])
           let checkedArr = []
           props.milestoneTranslation[1].forEach(element => {
             checkedArr.push(element[1])
           });
           setCheckedState(checkedArr)
         }
 
 
         else if (Array.isArray(props.TranslationModal[1])) {
 
 
           console.log("test")
           console.log(props.TranslationModal[1])
 
           setList(props.TranslationModal[1])
           let checkedArr = []
           props.TranslationModal[1].forEach(element => {
             checkedArr.push(element[1])
           });
           setCheckedState(checkedArr)
 
         } else {
           console.log("here")
           setHeader(2.1)
         } */
      } else {
        console.log(Header)
        console.log(props.Translation)
        console.log(props.TranslationOnlyAllow)
        if (Header[0] == "Percentage") {
          setRadio("Percentage")
          setHeader([props.TranslationModal])
          let obj = arr.find(o => o.value === props.Translation[props.TranslationModal][1]);
          if (obj) {
            console.log(obj)
            setSelectOne(obj)



            // setSelectOne(obj)
          } /*  else {
            console.log(possibleTags)
            setSelectTwo(possibleTags)
          } */


          let objTwo
          // console.log(props.Translation[props.TranslationModal][3])
          if (props.Translation[props.TranslationModal][3] && props.Translation[props.TranslationModal][3] !== []) {
            objTwo = props.Translation[props.TranslationModal][3]
            setSelectTwo(objTwo)
          }

          let objThree
          //  console.log(props.Translation[props.TranslationModal][2])
          if (props.Translation[props.TranslationModal][2] && props.Translation[props.TranslationModal][2] !== []) {
            objThree = props.Translation[props.TranslationModal][2]
            setAdditional(objThree)
          }


        }

        else if (Header[0] == "BayPercentage") {
          setRadio("BayPercentage")

          setHeader([props.TranslationModal])
          let obj = arr.find(o => o.value === props.Translation[props.TranslationModal][1]);
          console.log(obj)
          if (obj) {
            setSelectOne(obj)
          } /*  else {
            console.log(possibleTags)
            setSelectTwo(possibleTags)
          } */


          let objTwo
          console.log(props.Translation[props.TranslationModal][3])
          if (props.Translation[props.TranslationModal][3] && props.Translation[props.TranslationModal][3] !== []) {
            objTwo = props.Translation[props.TranslationModal][3]
            setSelectTwo(objTwo)
          }

          let objThree
          console.log(props.Translation[props.TranslationModal][2])
          if (props.Translation[props.TranslationModal][2] && props.Translation[props.TranslationModal][2] !== []) {
            objThree = props.Translation[props.TranslationModal][2]
            setAdditional(objThree)
          }


        }



        else if (Header[0] == "BayIfOne") {
          setRadio("BayIfOne")

          setHeader([props.TranslationModal])
          let obj = arr.find(o => o.value === props.Translation[props.TranslationModal][1]);
          console.log(obj)
          if (obj) {
            setSelectOne(obj)
          } /*  else {
            console.log(possibleTags)
            setSelectTwo(possibleTags)
          } */


          let objTwo
          console.log(props.Translation[props.TranslationModal][3])
          if (props.Translation[props.TranslationModal][3] && props.Translation[props.TranslationModal][3] !== []) {
            objTwo = props.Translation[props.TranslationModal][3]
            setSelectTwo(objTwo)
          }

          let objThree
          console.log(props.Translation[props.TranslationModal][2])
          if (props.Translation[props.TranslationModal][2] && props.Translation[props.TranslationModal][2] !== []) {
            objThree = props.Translation[props.TranslationModal][2]
            setAdditional(objThree)
          }


        }



        else if (Header[0] == "Comment") {
          setRadio("Comment")

          if (props.allTasks[props.Translation[props.TranslationModal][1]]) {
            let element = props.allTasks[props.Translation[props.TranslationModal][1]]
            if (element.length == 8) {
              element.push(null, null, null, null, null, props.Translation[props.TranslationModal][1])

            } else {
              element.push(props.Translation[props.TranslationModal][1])
            }



            console.log(element)
            setHeader(props.Translation[props.TranslationModal][1])
            setSearched(true)
            setCommentCheck(0)
            setList([element])

          } else {
            setHeader(props.Translation[props.TranslationModal][1] + " doesnt exist in this project")
          }


        }
        else if (Header[0] == "BayComment") {
          if (props.allTasks[props.Translation[props.TranslationModal][1]]) {
            let element = props.allTasks[props.Translation[props.TranslationModal][1]]
            if (element.length == 8) {
              element.push(null, null, null, null, null, props.Translation[props.TranslationModal][1])

            } else {
              element.push(props.Translation[props.TranslationModal][1])
            }



            console.log(element)
            setHeader(props.Translation[props.TranslationModal][1])
            setSearched(true)
            setCommentCheck(0)
            setList([element])
          } else {
            setHeader(props.Translation[props.TranslationModal][1] + " doesnt exist in this project")
          }


          setRadio("BayComment")
        }
        else if (Header[0] == "BayYesNo") {
          if (props.allTasks[props.Translation[props.TranslationModal][1]]) {
            let element = props.allTasks[props.Translation[props.TranslationModal][1]]
            if (element.length == 8) {
              element.push(null, null, null, null, null, props.Translation[props.TranslationModal][1])

            } else {
              element.push(props.Translation[props.TranslationModal][1])
            }



            console.log(element)
            setHeader(props.Translation[props.TranslationModal][1])
            setSearched(true)
            setCommentCheck(0)
            setList([element])
          } else {
            setHeader(props.Translation[props.TranslationModal][1] + " doesnt exist in this project")
          }


          setRadio("BayYesNo")
        }
        else if (Header[0] == "Bay") {
          console.log("test")
          if (props.allTasks[props.Translation[props.TranslationModal][1]]) {
            let element = props.allTasks[props.Translation[props.TranslationModal][1]]
            if (element.length == 8) {
              element.push(null, null, null, null, null, props.Translation[props.TranslationModal][1])

            } else {
              element.push(props.Translation[props.TranslationModal][1])
            }
            console.log(element)
            setHeader(props.Translation[props.TranslationModal][1])
            setSearched(true)
            setCommentCheck(0)
            setList([element])
          } else {
            console.log("test2")
            setHeader(props.Translation[props.TranslationModal][1] + " doesnt exist in this project")
          }

          setRadio("Bay")
        }
        else if (Header[0] == "Repair") {
          setRadio("Repair")

          setHeader([props.TranslationModal])
          let obj = arr.find(o => o.value === props.Translation[props.TranslationModal][1]);
          console.log(obj)
          if (obj) {
            setSelectOne([obj])
          } /*  else {
            console.log(possibleTags)
            setSelectTwo(possibleTags)
          } */





        }
        else if (Header[0] == "ToolBox") {
          setRadio("ToolBox")

          setHeader([props.TranslationModal])
          let obj = arr.find(o => o.value === props.Translation[props.TranslationModal][1]);
          console.log(obj)
          if (obj) {
            setSelectOne([obj])
          } /*  else {
            console.log(possibleTags)
            setSelectTwo(possibleTags)
          } */





        }
        else if (Header[0] == "File") {
          if (props.allTasks[props.Translation[props.TranslationModal][1]]) {
            let element = props.allTasks[props.Translation[props.TranslationModal][1]]
            if (element.length == 8) {
              element.push(null, null, null, null, null, props.Translation[props.TranslationModal][1])

            } else {
              element.push(props.Translation[props.TranslationModal][1])
            }



            console.log(element)
            setHeader(props.Translation[props.TranslationModal][1])
            setSearched(true)
            setCommentCheck(0)
            setList([element])
          } else {
            setHeader(props.Translation[props.TranslationModal][1] + " doesnt exist in this project")
          }
          setRadio("File")
        }
        else if (Header[0] == "BayFile") {
          if (props.allTasks[props.Translation[props.TranslationModal][1]]) {
            let element = props.allTasks[props.Translation[props.TranslationModal][1]]
            if (element.length == 8) {
              element.push(null, null, null, null, null, props.Translation[props.TranslationModal][1])

            } else {
              element.push(props.Translation[props.TranslationModal][1])
            }



            console.log(element)
            setHeader(props.Translation[props.TranslationModal][1])
            setSearched(true)
            setCommentCheck(0)
            setList([element])
          } else {
            setHeader(props.Translation[props.TranslationModal][1] + " doesnt exist in this project")
          }
          setRadio("BayFile")
        } else if (Header[0] == "Calculation") {
          console.log("test")

          let obj = arr.find(o => o.value === props.Translation[props.TranslationModal][1]);
          console.log(obj)
          if (obj) {
            setSelectOne([obj])
          }

          let objTwo
          console.log(props.Translation[props.TranslationModal][5])
          if (props.Translation[props.TranslationModal][5] && props.Translation[props.TranslationModal][5] !== []) {
            objTwo = props.Translation[props.TranslationModal][5]
            setSelectTwo(objTwo)
          }

          let objThree
          console.log(props.Translation[props.TranslationModal][4])
          if (props.Translation[props.TranslationModal][4] && props.Translation[props.TranslationModal][4] !== []) {
            objThree = props.Translation[props.TranslationModal][4]
            setAdditional(objThree)
          }


          if (Array.isArray(props.Translation[props.TranslationModal][2])) {

            console.log(props.Translation[props.TranslationModal][2])

            setAdditionalDivide(props.Translation[props.TranslationModal][2])
            setDivide(true)

          }
          if (Array.isArray(props.Translation[props.TranslationModal][7])) {


            setAdditionalDivideTwo(props.Translation[props.TranslationModal][7])
            setMultiplyTwo(true)

          }

          if (props.Translation[props.TranslationModal][3]) {
            console.log(typeof props.Translation[props.TranslationModal][3])
            if (typeof props.Translation[props.TranslationModal][3] == "string") {

              console.log(props.Translation[props.TranslationModal][3])

              setAdditionalMultiply(props.Translation[props.TranslationModal][3])
              setMultiply(true)

            } else {
              setAdditionalMultiply(1)
              setMultiply(true)
            }


          }

          if (props.Translation[props.TranslationModal][6]) {
            console.log(typeof props.Translation[props.TranslationModal][6])
            if (typeof props.Translation[props.TranslationModal][6] == "string") {

              console.log(props.Translation[props.TranslationModal][6])

              setAdditionalMultiplyTwo(props.Translation[props.TranslationModal][6])
              setDivideTwo(true)

            } else {
              setAdditionalMultiplyTwo(1)
              setDivideTwo(true)
            }


          }


          setRadio("calculation")
        }
        else if (Header[0] == "BayAverage") {
          console.log("test")
          console.log(props.TranslationModal)
          /*           let obj = arr.find(o => o.value === props.Translation[props.TranslationModal][1]);
                    console.log(obj)
                    if (obj) {
                      setSelectOne([obj])
                    } */

          let objThree
          console.log(props.Translation[props.TranslationModal][1])
          if (props.Translation[props.TranslationModal][1] && props.Translation[props.TranslationModal][1] !== []) {
            objThree = props.Translation[props.TranslationModal][1]
            setAdditional(objThree)
          }

          /*  let objTwo
           console.log(props.Translation[props.TranslationModal][5])
           if (props.Translation[props.TranslationModal][5] && props.Translation[props.TranslationModal][5] !== []) {
             objTwo = props.Translation[props.TranslationModal][5]
             setSelectTwo(objTwo)
           } */

          /*   let objThree
            console.log(props.Translation[props.TranslationModal][4])
            if (props.Translation[props.TranslationModal][4] && props.Translation[props.TranslationModal][4] !== []) {
              objThree = props.Translation[props.TranslationModal][4]
              setAdditional(objThree)
            } */


          if (Array.isArray(props.Translation[props.TranslationModal][2])) {

            console.log(props.Translation[props.TranslationModal][2])

            setAdditionalDivide(props.Translation[props.TranslationModal][2])
            setDivide(true)

          }

          if (Array.isArray(props.Translation[props.TranslationModal][3])) {


            setAdditionalDivideTwo(props.Translation[props.TranslationModal][3])
            setMultiplyTwo(true)

          }
          /* if (Array.isArray(props.Translation[props.TranslationModal][7])) {


            setAdditionalDivideTwo(props.Translation[props.TranslationModal][7])
            setMultiplyTwo(true)

          } */

          /*    if (props.Translation[props.TranslationModal][3]) {
               console.log(typeof props.Translation[props.TranslationModal][3])
               if (typeof props.Translation[props.TranslationModal][3] == "string") {
   
                 console.log(props.Translation[props.TranslationModal][3])
   
                 setAdditionalMultiply(props.Translation[props.TranslationModal][3])
                 setMultiply(true)
   
               } else {
                 setAdditionalMultiply(1)
                 setMultiply(true)
               }
   
   
             } */

          /*  if (props.Translation[props.TranslationModal][6]) {
             console.log(typeof props.Translation[props.TranslationModal][6])
             if (typeof props.Translation[props.TranslationModal][6] == "string") {
 
               console.log(props.Translation[props.TranslationModal][6])
 
               setAdditionalMultiplyTwo(props.Translation[props.TranslationModal][6])
               setDivideTwo(true)
 
             } else {
               setAdditionalMultiplyTwo(1)
               setDivideTwo(true)
             }
 
 
           } */


          setRadio("BayAverage")
        }




        else {
          setRadio("")
        }








      }

    }



    sendTasks()

  }, [])


  useEffect(() => {

    if (Header[0] == "Percentage") {
      console.log(Tasks)
      let obj = Tasks.find(o => o.value === props.Translation[props.TranslationModal][1]);
      console.log(obj)

      if (obj) {
        console.log(obj)
        setSelectOne({ label: "1. Business and Capacity Information", value: "1." })
        // setSelectOne(obj)
      }
    }
  }, [Tasks])

  const update = (e) => {
    setHeader(e.target.value)
    //  console.log()
    console.log(Header)

  }



  const saveAverage = (e) => {
    let newList

    let newList1 = []
    newList1[0] = "BayAverage"
    if (Additional !== "") {
      console.log(Additional)
      newList1[1] = [...Additional]
    }

    if (divide) {
      newList1[2] = AdditionalDivide
    }
    if (multiplyTwo) {
      newList1[3] = AdditionalDivideTwo
    }


    console.log(newList1)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList1
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }

    if (!Saved) {
      fetch().then(
        setSaved(true)
      )

    }



    //   setHeader(e.target.value)




  }




  const saveDivide = (e) => {
    let newList

    let newList1 = []
    newList1[0] = "Calculation"
    newList1[1] = SelectOne && SelectOne["value"]
    console.log(AdditionalDivide)
    if (divide) {
      newList1[2] = AdditionalDivide
    }
    if (multiply) {
      newList1[3] = AdditionalMultiply
    }
    if (divideTwo) {
      newList1[6] = AdditionalMultiplyTwo
    }
    if (multiplyTwo) {
      newList1[7] = AdditionalDivideTwo
    }
    if (Additional !== "") {
      console.log(Additional)
      newList1[4] = [...Additional]
    }
    newList1[5] = SelectTwo

    console.log(newList1)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList1
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }

    if (!Saved) {
      fetch().then(
        setSaved(true)
      )

    }



    //   setHeader(e.target.value)




  }





  const handleChange = (selectedOption) => {
    console.log(selectedOption)
    setSelectOne(selectedOption)
    //console.log(selectedOption[selectedOption.length-1])
    /*    if (selectedOption.length == 0) {
         console.log(selectedOption)
         setSelectOne([])
       } else {
         console.log(selectedOption)
         setSelectOne([selectedOption[selectedOption.length - 1]]) 
       } */
    setSaved(false)
  };

  const handleChangeTwo = (selectedOption) => {
    console.log(selectedOption)
    console.log(selectedOption.length)
    //console.log(selectedOption[selectedOption.length-1])
    if (selectedOption.length == 0) {
      console.log(selectedOption)
      setSelectTwo([])

    } else {
      console.log(selectedOption)
      setSelectTwo(selectedOption)

    }
    setSaved(false)
  };

  const filterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ value }) => value && value.match(re));
  };



  const saveMilestone = (e) => {

    /*  let newList
     if (Searched) {
       newList = List.map(item => {
         return [item[13]]
       })
     } else {
       newList = List.map(item => {
         return [item[0]]
       })
     }
   
   
   
     checkedState.map((item, index) => {
       newList[index].push(item)
     })
   
   
     let check = true
     // console.log(newList)
     Additional.forEach(item => {
       if (item[0] == "") {
         check = false
       }
     })
     newList = [...newList, ...Additional]
   
   
     let newList1 = []
     newList1[0] = "Percentage"
     newList1[1] = newList
   
   
    
     console.log(newList1)
     props.setMilestoneTranslation(newList1)
     props.setTranslationModal(newList1) */

    let newList1 = []
    newList1[0] = "Percentage"
    newList1[1] = SelectOne["value"]
    if (Additional !== "") {
      newList1[2] = [...Additional]
    }
    newList1[3] = SelectTwo
    console.log(newList1)

    props.setMilestoneTranslation(newList1)
    props.setTranslationModal(newList1)



    if (!Saved) {

      setSaved(true)


    }






    //   setHeader(e.target.value)
  }


  const save = (e) => {

    if (e !== "Calculation") {
      if (!SelectOne) { return }
    }

    console.log(SelectOne)
    // console.log(SelectOne[0]["value"])

    /*   let newList1 = []
      newList1[0] = "Percentage"
      newList1[1] = newList */

    //  console.log(Additional)
    let newList1 = []
    newList1[0] = e == "Calculation" ? "Calculation" : "Percentage"
    newList1[1] = SelectOne && SelectOne["value"]
    if (Additional !== "") {
      console.log(Additional)
      newList1[2] = [...Additional]
    }
    newList1[3] = SelectTwo
    console.log(newList1)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList1
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }

    if (!Saved) {
      fetch().then(
        setSaved(true)
      )

    }




    //   setHeader(e.target.value)
  }


  const saveBayPercentage = (e) => {
    if (e !== "Calculation") {
      if (!SelectOne) { return }
    }


    // console.log(SelectOne[0]["value"])

    /*   let newList1 = []
      newList1[0] = "Percentage"
      newList1[1] = newList */

    //  console.log(Additional)
    let newList1 = []
    newList1[0] = "BayPercentage"
    newList1[1] = SelectOne && SelectOne["value"]
    if (Additional !== "") {
      console.log(Additional)
      newList1[2] = [...Additional]
    }
    newList1[3] = SelectTwo
    newList1[4] = props.TranslationAddon
    console.log(newList1)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList1
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }

    if (!Saved) {
      fetch().then(
        setSaved(true)
      )

    }




    //   setHeader(e.target.value)
  }

  const saveBayIfOne = (e) => {
    if (e !== "Calculation") {
      if (!SelectOne) { return }
    }


    // console.log(SelectOne[0]["value"])

    /*   let newList1 = []
      newList1[0] = "Percentage"
      newList1[1] = newList */

    //  console.log(Additional)
    let newList1 = []
    newList1[0] = "BayIfOne"
    newList1[1] = SelectOne && SelectOne["value"]
    if (Additional !== "") {
      console.log(Additional)
      newList1[2] = [...Additional]
    }
    newList1[3] = SelectTwo
    newList1[4] = props.TranslationAddon
    console.log(newList1)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList1
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }

    if (!Saved) {
      fetch().then(
        setSaved(true)
      )

    }




    //   setHeader(e.target.value)
  }

  const addline = (e) => {
    setSaved(false)
    console.log(List)
    //   console.log("test")
    //   console.log(Additional)
    setAdditional(prevState => {
      return [...prevState, ""]
    })
  }

  const removeLine = (e) => {
    setSaved(false)
    //   console.log(e)
    setAdditional(prevState => {
      return prevState.filter((item, index) => {
        return index !== e
      })
    })
  }

  const apply = (e) => {
    /*   setSaved(false)
      console.log(props.allTasks)
      console.log(Header)
      setSearched(true)
      let arr = []
      let results = Object.entries(props.allTasks).map(k => {
        console.log(k[1])
        if (k[0].startsWith(Header)) {
          if (k[1].length == 8) {
            arr.push([...k[1], null, null, null, null, null, k[0]])
          } else {
            arr.push([...k[1], k[0]])
          }
   
        }
      })
      console.log(arr)
      setCheckedState(new Array(arr.length).fill(true))
      setList(arr) */

  }

  const handleOnChange = (position) => {
    setSaved(false)

    const updatedCheckedState = checkedState.map((item, index) => {

      // console.log(item)

      return index === position ? !item : item
    });
    setCheckedState(updatedCheckedState);
  }
  /*  const handleOnChangeWeight = (position, e) => {
     setSaved(false)
   
     setWeight(oldArray => (oldArray.map((item, index) => index == position ? item = e.target.value : item)))
   
   } */

  const onChangeRadio = (e) => {
    setHeader(2.1)
    setList([])
    console.log(e.target.value)
    setRadio(e.target.value)

  }



  const handleDivide = (number) => {
    if (number) {
      setDivideTwo(prevState => !prevState)
    } else {
      setDivide(prevState => !prevState)
    }
    setSaved(false)

  }

  const handleMultiply = (number) => {
    if (number) {
      setMultiplyTwo(prevState => !prevState)
    } else {
      setMultiply(prevState => !prevState)
    }
    setSaved(false)
  }




  //all Comment functions






  const applyComment = (e) => {
    //  setCommentCheck(100)
    // setSaved(false)
    console.log(props.allTasks)
    console.log(Header)
    setSearched(true)
    let arr = []
    let results = Object.entries(props.allTasks).map(k => {
      if (k[0].startsWith(Header)) {
        if (k[1].length == 8) {
          arr.push([...k[1], null, null, null, null, null, k[0]])
        } else {
          arr.push([...k[1], k[0]])
        }

      }
    })
    console.log(arr)
    setCommentCheck(9000)
    setList(arr)
    console.log(CommentCheck)
  }

  const handleOnChangeComment = (position) => {
    setSaved(false)
    console.log(position)
    setCommentCheck(position)
  }


  const saveBayComment = (e) => {
    if (CommentCheck == 9000) {
      return
    }
    //console.log(List[10])
    let newList

    newList = []
    newList[0] = "BayComment"
    newList[1] = List[CommentCheck][13]
    newList[2] = BayCommentParent


    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    console.log("TestHere")
    console.log(props.TranslationModal)
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }
    fetch().then(
      setSaved(true)
    )
  }

  const saveBayYesNo = (e) => {
    if (CommentCheck == 9000) {
      return
    }
    //console.log(List[10])
    let newList

    newList = []
    newList[0] = "BayYesNo"
    newList[1] = List[CommentCheck][13]
    newList[2] = BayCommentParent


    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    console.log("TestHere")
    console.log(props.TranslationModal)
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }
    fetch().then(
      setSaved(true)
    )
  }

  const saveComment = (e) => {
    if (CommentCheck == 9000) {
      return
    }
    //console.log(List[10])
    console.log(CommentCheck)
    let newList
    console.log(List)

    newList = []
    newList[0] = "Comment"
    newList[1] = List[CommentCheck][13]

    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }


    fetch().then(
      setSaved(true)
    )


  }


  const saveBayFile = (e) => {
    if (CommentCheck == 9000) {
      return
    }
    //console.log(List[10])
    let newList

    newList = []
    newList[0] = "BayFile"
    newList[1] = List[CommentCheck][13]
    newList[2] = BayCommentParent

    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }


    fetch().then(
      setSaved(true)
    )


  }

  const saveFile = (e) => {
    if (CommentCheck == 9000) {
      return
    }
    //console.log(List[10])
    let newList

    newList = []
    newList[0] = "File"
    newList[1] = List[CommentCheck][13]

    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }


    fetch().then(
      setSaved(true)
    )


  }



  const saveBay = (e) => {
    if (CommentCheck == 9000) {
      return
    }
    //console.log(List[10])
    let newList

    newList = []
    newList[0] = "Bay"
    newList[1] = List[CommentCheck][13]

    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }
    fetch().then(
      setSaved(true)
    )
  }



  const saveRepair = (e) => {
    console.log("test")

    //console.log(List[10])
    let newList

    newList = []
    newList[0] = "Repair"
    newList[1] = SelectOne["value"]

    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }
    fetch().then(
      setSaved(true)
    )
  }

  const saveToolBox = (e) => {
    console.log("test")

    //console.log(List[10])
    let newList

    newList = []
    newList[0] = "ToolBox"
    newList[1] = SelectOne["value"]

    // newList = List[CommentCheck][10]

    console.log(newList)

    let translationObj = props.Translation
    translationObj[props.TranslationModal] = newList
    let Body = {
      ...translationObj
    }


    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/versioningSingle/${props.VersioningNumber}`, Body, config);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

      //  console.log(DBGet.data)
    }
    fetch().then(
      setSaved(true)
    )
  }



  if (Tasks.length !== 0) {


    return (
      <div className="modal2">
        <div style={{
          width: "95%",
          margin: "auto",
          marginBottom: "auto",
          paddingTop: "1%"
        }} className="row mb-2">
          <div className="col-sm-12">
            <div className="maxwidth card">



              <div style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "95vh",
                overflowY: "scroll",
                minHeight: "150px"
              }} className="maxwidth card-body">





                <i style={{ alignSelf: "end", fontSize: "30px", cursor: "pointer", color: "grey" }} onClick={() => props.setTranslationModal(false)} class="fas fa-times"></i>


                <div style={{
                  display: "flex",
                  flexWrap: "wrap"
                  /*  flexDirection: "row", justifyContent: "end" */
                }} className="col-12">



                  <div className="enoughSpace">
                    <input type="radio" name="type1"
                      value={"Comment"}
                      disabled={props.TranslationOnlyAllow.indexOf("Comment") < 0}
                      checked={Radio == "Comment"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="Comment" defaultMessage="Comment" /></span> </div>
                  <div className="enoughSpace">
                    <input type="radio" name="typ2"
                      value={"Percentage"}
                      disabled={props.TranslationOnlyAllow.indexOf("Percentage") < 0}
                      checked={Radio == "Percentage"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="Percentage" defaultMessage="Percentage" /></span> </div>

                  <div className="enoughSpace">
                    <input type="radio" name="typ4"
                      disabled={props.TranslationOnlyAllow.indexOf("Bay") < 0}
                      value={"Bay"}
                      checked={Radio == "Bay"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="Bay" defaultMessage="Bay" /></span> </div>

                  <div className="enoughSpace">
                    <input type="radio" name="typ12"
                      disabled={props.TranslationOnlyAllow.indexOf("Repair") < 0}
                      value={"Repair"}
                      checked={Radio == "Repair"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="Repair" defaultMessage="Repair" /></span> </div>
                  <div className="enoughSpace">
                    <input type="radio" name="typ20"
                      disabled={props.TranslationOnlyAllow.indexOf("ToolBox") < 0}
                      value={"ToolBox"}
                      checked={Radio == "ToolBox"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="ToolBox" defaultMessage="ToolBox" /></span> </div>

                  <div className="enoughSpace">
                    <input type="radio" name="typ5"
                      value={"BayComment"}
                      disabled={props.TranslationOnlyAllow.indexOf("BayComment") < 0}
                      checked={Radio == "BayComment"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="BayComment" defaultMessage="Bay Comment" /></span></div>

                  <div className="enoughSpace">
                    <input type="radio" name="typ5"
                      value={"BayYesNo"}
                      disabled={props.TranslationOnlyAllow.indexOf("BayYesNo") < 0}
                      checked={Radio == "BayYesNo"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="BayYesNo" defaultMessage="Bay Yes No" /></span></div>

                  <div className="enoughSpace">
                    <input type="radio" name="typ7"
                      disabled={props.TranslationOnlyAllow.indexOf("File") < 0}
                      value={"File"}
                      checked={Radio == "File"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="Picture" defaultMessage="Picture" /></span></div>
                  <div className="enoughSpace">
                    <input type="radio" name="typ8"
                      disabled={props.TranslationOnlyAllow.indexOf("BayFile") < 0}
                      value={"BayFile"}
                      checked={Radio == "BayFile"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="BayPicture" defaultMessage="Bay Picture" /></span></div>

                  <div className="enoughSpace">
                    <input type="radio" name="typ26"
                      disabled={props.TranslationOnlyAllow.indexOf("BayPercentage") < 0}
                      value={"BayPercentage"}
                      checked={Radio == "BayPercentage"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="BayPercentage" defaultMessage="Bay Percentage" /></span></div>

                  <div className="enoughSpace">
                    <input type="radio" name="typ76"
                      disabled={props.TranslationOnlyAllow.indexOf("BayIfOne") < 0}
                      value={"BayIfOne"}
                      checked={Radio == "BayIfOne"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="BayIfOne" defaultMessage="BayIfOne" /></span></div>


                  <div className="enoughSpace">
                    <input type="radio" name="typ27"
                      disabled={props.TranslationOnlyAllow.indexOf("BayAverage") < 0}
                      value={"BayAverage"}
                      checked={Radio == "BayAverage"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="BayAverage" defaultMessage="Bay Average" /></span></div>


                  <div className="enoughSpace">

                    <input type="radio" name="type3"
                      disabled={props.TranslationOnlyAllow.indexOf("calculation") < 0}
                      value={"calculation"}
                      checked={Radio == "calculation"}
                      onChange={(e) => onChangeRadio(e)}
                    /><span className="spanVersioning"> <FormattedMessage id="Calculation" defaultMessage="Calculation" /></span> </div>
                  <span></span>

                </div>

                {Radio == "Percentage" ?
                  <>
                    <div style={{
                      display: "flex",
                      flexDirection: "row"
                    }} className="col-12">
                      <div style={{
                        display: "flex",
                        alignItems: "center"
                      }} className="col-3">
                       {props.onlyMilestone == "true" || Milestone ?
                            "Milestone"
                            :
                            changeNames[props.TranslationModal] ?  changeNames[props.TranslationModal] : props.TranslationModal

                          }
                      </div>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "row"
                    }} className="col-12">
                      <div style={{
                        display: "flex",
                        alignItems: "center"
                      }} className="col-1">
                        <FormattedMessage id="Main" defaultMessage="Main" />
                      </div>


                      <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">
                      

                        {/*   <MultiSelect
                          options={Tasks}
                          ItemRenderer={DefaultItemRenderer}
                          value={SelectOne}
                          hasSelectAll={false}
                          className={"fullwidthdropdown"}
                          onChange={(e) => handleChange(e)}
                          labelledBy="Select"
                          filterOptions={filterOptions}
                          debounceDuration="800"
                          focusSearchOnOpen="true"
                        /> */}

                        <Select
                          value={SelectOne}
                          isClearable={true}
                          className={"multiClass"}
                          onChange={(e) => handleChange(e)}
                          options={Tasks}
                          filterOption={createFilter({ ignoreAccents: false })}
                          components={{ Option: CustomOption }}
                          classNamePrefix="custom-select"
                        />



                      </div>

                    </div>
                    <br style={{ marginTop: "10px" }} />

                    <div style={{
                      display: "flex",
                      flexDirection: "row"
                    }} className="col-12">
                      <div style={{
                        display: "flex",
                        alignItems: "center"
                      }} className="col-1">
                        <FormattedMessage id="Tags" defaultMessage="Tags" />
                      </div>


                      <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">

                        <MultiSelect
                          options={possibleTags}
                          value={SelectTwo}
                          hasSelectAll={false}
                          className={"fullwidthdropdown"}
                          onChange={(e) => handleChangeTwo(e)}
                          labelledBy="Select"
                          debounceDuration="1500"
                        /* focusSearchOnOpen="true" */
                        // filterOptions={filterOptions}
                        />



                      </div>
                    </div>


                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end"
                    }} className="col-12">
                      <div className="col-3">
                        <button style={{ Right: "0", fontSize: "1.3em" }}
                          className='btn btn-primary btn-primary-blue no-print m-2'
                          onClick={(e) => addline(e)}><i className="m-1 fas fa-plus"></i> <FormattedMessage id="AddLine" defaultMessage="Add Line" /></button>
                      </div>
                    </div>




                    {Additional && Additional.map((item, index) => {
                      return (
                        <PopupAdd removeLine={removeLine} item={item} key={"add" + index} Additional={Additional} SetAdditional={setAdditional} index={index} allTasks={Tasks} />
                      )
                    })}



                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end"
                    }} className="col-12">
                      <div className="col-3">
                        {props.onlyMilestone == "true" || Milestone ?

                          <button style={{ Right: "0", fontSize: "1.3em" }}
                            className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                            onClick={(e) => saveMilestone(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>



                          :
                          <button style={{ Right: "0", fontSize: "1.3em" }}
                            className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                            onClick={(e) => save(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>



                        }
                      </div>
                    </div>




                  </>


                  :
                  Radio == "BayPercentage" ?
                    <>
                      <div style={{
                        display: "flex",
                        flexDirection: "row"
                      }} className="col-12">
                        <div style={{
                          display: "flex",
                          alignItems: "center"
                        }} className="col-3">
                          {props.onlyMilestone == "true" || Milestone ?
                            "Milestone"
                            :
                            changeNames[props.TranslationModal] ?  changeNames[props.TranslationModal] : props.TranslationModal

                          }
                        </div>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "row"
                      }} className="col-12">
                        <div style={{
                          display: "flex",
                          alignItems: "center"
                        }} className="col-1">
                          <FormattedMessage id="Main" defaultMessage="Main" />
                        </div>


                        <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">

                          {/*  <MultiSelect
                            options={Tasks}
                            value={SelectOne}
                            hasSelectAll={false}
                            className={"fullwidthdropdown"}
                            onChange={(e) => handleChange(e)}
                            labelledBy="Select"
                            filterOptions={filterOptions}
                            debounceDuration="800"
                            focusSearchOnOpen="true"
                          /> */}

                          <Select
                            className={"multiClass"}
                            isClearable={true}
                            onChange={(e) => handleChange(e)}
                            options={Tasks}
                            value={SelectOne}
                            filterOption={createFilter({ ignoreAccents: false })}
                            components={{ Option: CustomOption }}
                            classNamePrefix="custom-select"
                          />



                        </div>

                      </div>
                      <br style={{ marginTop: "10px" }} />

                      <div style={{
                        display: "flex",
                        flexDirection: "row"
                      }} className="col-12">
                        <div style={{
                          display: "flex",
                          alignItems: "center"
                        }} className="col-1">
                          <FormattedMessage id="Tags" defaultMessage="Tags" />
                        </div>


                        <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">

                          <MultiSelect
                            options={possibleTags}
                            value={SelectTwo}
                            hasSelectAll={false}
                            className={"fullwidthdropdown"}
                            onChange={(e) => handleChangeTwo(e)}
                            labelledBy="Select"
                          // filterOptions={filterOptions}
                          />



                        </div>
                      </div>


                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end"
                      }} className="col-12">
                        <div className="col-3">
                          <button style={{ Right: "0", fontSize: "1.3em" }}
                            className='btn btn-primary btn-primary-blue no-print m-2'
                            onClick={(e) => addline(e)}><i className="m-1 fas fa-plus"></i> <FormattedMessage id="AddSingleTask" defaultMessage="Add Single Task" /></button>
                        </div>
                      </div>




                      {Additional && Additional.map((item, index) => {
                        return (
                          <PopupAdd removeLine={removeLine} item={item} key={"add" + index} Additional={Additional} SetAdditional={setAdditional} index={index} allTasks={Tasks} />
                        )
                      })}



                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end"
                      }} className="col-12">
                        <div className="col-3">

                          <button style={{ Right: "0", fontSize: "1.3em" }}
                            className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                            onClick={(e) => saveBayPercentage(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>


                        </div>
                      </div>




                    </>


                    :
                    Radio == "BayIfOne" ?
                      <>
                        <div style={{
                          display: "flex",
                          flexDirection: "row"
                        }} className="col-12">
                          <div style={{
                            display: "flex",
                            alignItems: "center"
                          }} className="col-3">
                            {props.onlyMilestone == "true" || Milestone ?
                            "Milestone"
                            :
                            changeNames[props.TranslationModal] ?  changeNames[props.TranslationModal] : props.TranslationModal

                          }
                          </div>
                        </div>
                        <div style={{
                          display: "flex",
                          flexDirection: "row"
                        }} className="col-12">
                          <div style={{
                            display: "flex",
                            alignItems: "center"
                          }} className="col-1">
                            <FormattedMessage id="Main" defaultMessage="Main" />
                          </div>


                          <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">



                            <Select
                              className={"multiClass"}
                              isClearable={true}
                              onChange={(e) => handleChange(e)}
                              options={Tasks}
                              value={SelectOne}
                              filterOption={createFilter({ ignoreAccents: false })}
                              components={{ Option: CustomOption }}
                              classNamePrefix="custom-select"
                            />



                          </div>

                        </div>
                        <br style={{ marginTop: "10px" }} />

                        <div style={{
                          display: "flex",
                          flexDirection: "row"
                        }} className="col-12">
                          <div style={{
                            display: "flex",
                            alignItems: "center"
                          }} className="col-1">
                            <FormattedMessage id="Tags" defaultMessage="Tags" />
                          </div>


                          <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">
                            <MultiSelect
                              options={possibleTags}
                              value={SelectTwo}
                              hasSelectAll={false}
                              className={"fullwidthdropdown"}
                              onChange={(e) => handleChangeTwo(e)}
                              labelledBy="Select"
                            // filterOptions={filterOptions}
                            />



                          </div>
                        </div>


                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end"
                        }} className="col-12">
                          <div className="col-3">
                            <button style={{ Right: "0", fontSize: "1.3em" }}
                              className='btn btn-primary btn-primary-blue no-print m-2'
                              onClick={(e) => addline(e)}><i className="m-1 fas fa-plus"></i> <FormattedMessage id="AddLine" defaultMessage="Add Line" /></button>
                          </div>
                        </div>




                        {Additional && Additional.map((item, index) => {
                          return (
                            <PopupAdd removeLine={removeLine} item={item} key={"add" + index} Additional={Additional} SetAdditional={setAdditional} index={index} allTasks={Tasks} />
                          )
                        })}



                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end"
                        }} className="col-12">
                          <div className="col-3">

                            <button style={{ Right: "0", fontSize: "1.3em" }}
                              className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                              onClick={(e) => saveBayIfOne(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>


                          </div>
                        </div>




                      </>



                      : Radio == "Comment" ?
                        <>
                          <div style={{
                            display: "flex",
                            flexDirection: "row"
                          }} className="col-12">
                            <div style={{
                              display: "flex",
                              alignItems: "center"
                            }} className="col-3">
                              {props.TranslationModal}
                            </div>
                          </div>
                          <div style={{
                            display: "flex",
                            flexDirection: "row"
                          }} className="col-12">
                            <div style={{
                              display: "flex",
                              alignItems: "center"
                            }} className="col-3">
                              <FormattedMessage id="Main" defaultMessage="Main" />
                            </div>
                            <div className="col-2">

                              <input onChange={(e) => update(e)} style={{ border: "1px solid" }}
                                className="form-control-update"
                                maxLength="15" value={Header} />
                            </div>
                            <div className="col-4"></div>
                            <div className="col-3">

                              {/* <form onSubmit={(e)=> apply(e)}>
  {/* <input value={inputText} onChange={inputHandler} type="text"/> 
  <button type="submit"> Search </button>
</form> */}

                              <button style={{ Right: "0", fontSize: "1.3em" }}
                                className='btn btn-primary btn-primary-blue no-print m-2'
                                onClick={(e) => applyComment(e)}><i className="m-1 fas fa-search"></i> <FormattedMessage id="Search" defaultMessage="Search" /></button>

                            </div>
                          </div>

                          {Array.isArray(List) && !Searched && Tasks.length > 0 ?


                            <div style={{
                              display: "flex",
                              flexDirection: "row",
                            }} className="mb-1 mt-1"
                            >

                              <div className="col-1">

                              </div>
                              <div style={{ display: "flex", alignItems: "center" }} className="col-8">{List[1]}</div>


                            </div>




                            :

                            List.map((item, index) => {
                              return (
                                <div style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }} className="mb-1 mt-1"
                                  key={index + "." + item[9]}>

                                  <div className="col-1">
                                    <input
                                      style={{ width: "100%" }}
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      /*  name={item[9]}
                                       value={item[9]} */
                                      checked={CommentCheck == index}
                                      onChange={() => handleOnChangeComment(index)}
                                    />
                                  </div>
                                  <div style={{ display: "flex", alignItems: "center" }} className="col-8">{item[3]}</div>


                                </div>
                              )
                            })}










                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end"
                          }} className="col-12">
                            <div className="col-3">
                              <button style={{ Right: "0", fontSize: "1.3em" }}
                                className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                onClick={(e) => saveComment(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                            </div>
                          </div>




                        </>



                        : Radio == "File" ?
                          <>
                            <div style={{
                              display: "flex",
                              flexDirection: "row"
                            }} className="col-12">
                              <div style={{
                                display: "flex",
                                alignItems: "center"
                              }} className="col-3">
                                {props.TranslationModal}
                              </div>
                            </div>
                            <div style={{
                              display: "flex",
                              flexDirection: "row"
                            }} className="col-12">
                              <div style={{
                                display: "flex",
                                alignItems: "center"
                              }} className="col-3">
                                <FormattedMessage id="Main" defaultMessage="Main" />
                              </div>
                              <div className="col-2">

                                <input onChange={(e) => update(e)} style={{ border: "1px solid" }}
                                  className="form-control-update"
                                  maxLength="15" value={Header} />
                              </div>
                              <div className="col-4"></div>
                              <div className="col-3">

                                {/* <form onSubmit={(e)=> apply(e)}>
  {/* <input value={inputText} onChange={inputHandler} type="text"/> 
  <button type="submit"> Search </button>
</form> */}

                                <button style={{ Right: "0", fontSize: "1.3em" }}
                                  className='btn btn-primary btn-primary-blue no-print m-2'
                                  onClick={(e) => applyComment(e)}><i className="m-1 fas fa-search"></i> <FormattedMessage id="Search" defaultMessage="Search" /></button>

                              </div>
                            </div>



                            {List.map((item, index) => {
                              return (
                                <div style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }} className="mb-1 mt-1"
                                  key={index + "." + item[9]}>

                                  <div className="col-1">
                                    <input
                                      style={{ width: "100%" }}
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      /*  name={item[9]}
                                       value={item[9]} */
                                      checked={CommentCheck == index}
                                      onChange={() => handleOnChangeComment(index)}
                                    />
                                  </div>
                                  <div style={{ display: "flex", alignItems: "center" }} className="col-8">{item[3]}</div>


                                </div>
                              )
                            })}


                            <div style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "end"
                            }} className="col-12">
                              <div className="col-3">
                                <button style={{ Right: "0", fontSize: "1.3em" }}
                                  className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                  onClick={(e) => saveFile(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                              </div>
                            </div>




                          </>



                          : Radio == "BayFile" ?
                            <>
                              <div style={{
                                display: "flex",
                                flexDirection: "row"
                              }} className="col-12">
                                <div style={{
                                  display: "flex",
                                  alignItems: "center"
                                }} className="col-3">
                                  {props.TranslationModal}
                                </div>
                              </div>
                              <div style={{
                                display: "flex",
                                flexDirection: "row"
                              }} className="col-12">
                                <div style={{
                                  display: "flex",
                                  alignItems: "center"
                                }} className="col-3">
                                  <FormattedMessage id="Main" defaultMessage="Main" />
                                </div>
                                <div className="col-2">

                                  <input onChange={(e) => update(e)} style={{ border: "1px solid" }}
                                    className="form-control-update"
                                    maxLength="15" value={Header} />
                                </div>
                                <div className="col-4"></div>
                                <div className="col-3">

                                  {/* <form onSubmit={(e)=> apply(e)}>
  {/* <input value={inputText} onChange={inputHandler} type="text"/> 
  <button type="submit"> Search </button>
</form> */}

                                  <button style={{ Right: "0", fontSize: "1.3em" }}
                                    className='btn btn-primary btn-primary-blue no-print m-2'
                                    onClick={(e) => applyComment(e)}><i className="m-1 fas fa-search"></i> <FormattedMessage id="Search" defaultMessage="Search" /></button>

                                </div>
                              </div>



                              {List.map((item, index) => {
                                return (
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }} className="mb-1 mt-1"
                                    key={index + "." + item[9]}>

                                    <div className="col-1">
                                      <input
                                        style={{ width: "100%" }}
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        /*  name={item[9]}
                                         value={item[9]} */
                                        checked={CommentCheck == index}
                                        onChange={() => handleOnChangeComment(index)}
                                      />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }} className="col-8">{item[3]}</div>


                                  </div>
                                )
                              })}


                              <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end"
                              }} className="col-12">
                                <div className="col-3">
                                  <button style={{ Right: "0", fontSize: "1.3em" }}
                                    className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                    onClick={(e) => saveBayFile(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                                </div>
                              </div>




                            </>









                            : Radio == "Bay" ?
                              <>
                                <div style={{
                                  display: "flex",
                                  flexDirection: "row"
                                }} className="col-12">
                                  <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }} className="col-3">
                                    {props.TranslationModal}
                                  </div>
                                </div>
                                <div style={{
                                  display: "flex",
                                  flexDirection: "row"
                                }} className="col-12">
                                  <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }} className="col-3">
                                    <FormattedMessage id="Main" defaultMessage="Main" />
                                  </div>
                                  <div className="col-2">

                                    <input onChange={(e) => update(e)} style={{ border: "1px solid" }}
                                      className="form-control-update"
                                      maxLength="15" value={Header} />
                                  </div>
                                  <div className="col-4"></div>
                                  <div className="col-3">

                                    {/* <form onSubmit={(e)=> apply(e)}>
{/* <input value={inputText} onChange={inputHandler} type="text"/> 
<button type="submit"> Search </button>
</form> */}

                                    <button style={{ Right: "0", fontSize: "1.3em" }}
                                      className='btn btn-primary btn-primary-blue no-print m-2'
                                      onClick={(e) => applyComment(e)}><i className="m-1 fas fa-search"></i> Search</button>

                                  </div>
                                </div>



                                {List.map((item, index) => {
                                  return (
                                    <div style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }} className="mb-1 mt-1"
                                      key={index + "." + item[9]}>

                                      <div className="col-1">
                                        <input
                                          style={{ width: "100%" }}
                                          type="checkbox"
                                          id={`custom-checkbox-${index}`}
                                          /*  name={item[9]}
                                           value={item[9]} */
                                          checked={CommentCheck == index}
                                          onChange={() => handleOnChangeComment(index)}
                                        />
                                      </div>
                                      <div style={{ display: "flex", alignItems: "center" }} className="col-8">{item[3]}</div>


                                    </div>
                                  )
                                })}



                                <div style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "end"
                                }} className="col-12">
                                  <div className="col-3">
                                    <button style={{ Right: "0", fontSize: "1.3em" }}
                                      className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                      onClick={(e) => saveBay(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                                  </div>
                                </div>




                              </>

                              : Radio == "Repair" ?
                                <>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }} className="col-12">
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }} className="col-3">
                                      {props.onlyMilestone == "true" || Milestone ?
                                        "Milestone"
                                        :
                                        props.TranslationModal

                                      }
                                    </div>
                                  </div>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }} className="col-12">
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }} className="col-1">
                                      <FormattedMessage id="Main" defaultMessage="Main" />
                                    </div>


                                    <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">

                                      {/*   <MultiSelect
                                      options={Tasks}
                                      value={SelectOne}
                                      hasSelectAll={false}
                                      className={"fullwidthdropdown"}
                                      onChange={(e) => handleChange(e)}
                                      labelledBy="Select"
                                      filterOptions={filterOptions}
                                      debounceDuration="800"
                                      focusSearchOnOpen="true"
                                    /> */}

                                      <Select
                                        className={"multiClass"}
                                        isClearable={true}
                                        onChange={(e) => handleChange(e)}
                                        options={Tasks}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        components={{ Option: CustomOption }}
                                        classNamePrefix="custom-select"
                                      />



                                    </div>

                                  </div>
                                  <br style={{ marginTop: "10px" }} />









                                  <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "end"
                                  }} className="col-12">
                                    <div className="col-3">

                                      <button style={{ Right: "0", fontSize: "1.3em" }}
                                        className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                        onClick={(e) => saveRepair(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>



                                    </div>
                                  </div>




                                </>

                                : Radio == "ToolBox" ?
                                  <>
                                    <div style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }} className="col-12">
                                      <div style={{
                                        display: "flex",
                                        alignItems: "center"
                                      }} className="col-3">
                                        {props.onlyMilestone == "true" || Milestone ?
                                          "Milestone"
                                          :
                                          props.TranslationModal

                                        }
                                      </div>
                                    </div>
                                    <div style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }} className="col-12">
                                      <div style={{
                                        display: "flex",
                                        alignItems: "center"
                                      }} className="col-1">
                                        <FormattedMessage id="Main" defaultMessage="Main" />
                                      </div>


                                      <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">

                                        {/*   <MultiSelect
                                        options={Tasks}
                                        value={SelectOne}
                                        hasSelectAll={false}
                                        className={"fullwidthdropdown"}
                                        onChange={(e) => handleChange(e)}
                                        labelledBy="Select"
                                        filterOptions={filterOptions}
                                        debounceDuration="800"
                                        focusSearchOnOpen="true"
                                      /> */}

                                        <Select
                                          className={"multiClass"}
                                          isClearable={true}
                                          onChange={(e) => handleChange(e)}
                                          options={Tasks}
                                          filterOption={createFilter({ ignoreAccents: false })}
                                          components={{ Option: CustomOption }}
                                          classNamePrefix="custom-select"
                                        />



                                      </div>

                                    </div>
                                    <br style={{ marginTop: "10px" }} />









                                    <div style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "end"
                                    }} className="col-12">
                                      <div className="col-3">

                                        <button style={{ Right: "0", fontSize: "1.3em" }}
                                          className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                          onClick={(e) => saveToolBox(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>



                                      </div>
                                    </div>




                                  </>

                                  : Radio == "BayComment" ?
                                    <>
                                      <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                      }} className="col-12">
                                        <div style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }} className="col-3">
                                          {props.TranslationModal}
                                        </div>
                                      </div>
                                      <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                      }} className="col-12">
                                        <div style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }} className="col-3">
                                          <FormattedMessage id="Main" defaultMessage="Main" />
                                        </div>
                                        <div className="col-2">

                                          <input onChange={(e) => update(e)} style={{ border: "1px solid" }}
                                            className="form-control-update"
                                            maxLength="15" value={Header} />
                                        </div>
                                        <div className="col-4"></div>
                                        <div className="col-3">

                                          {/* <form onSubmit={(e)=> apply(e)}>
{/* <input value={inputText} onChange={inputHandler} type="text"/> 
<button type="submit"> Search </button>
</form> */}

                                          <button style={{ Right: "0", fontSize: "1.3em" }}
                                            className='btn btn-primary btn-primary-blue no-print m-2'
                                            onClick={(e) => applyComment(e)}><i className="m-1 fas fa-search"></i> <FormattedMessage id="Search" defaultMessage="Search" /></button>

                                        </div>
                                      </div>



                                      {List.map((item, index) => {
                                        return (
                                          <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }} className="mb-1 mt-1"
                                            key={index + "." + item[9]}>

                                            <div className="col-1">
                                              <input
                                                style={{ width: "100%" }}
                                                type="checkbox"
                                                id={`custom-checkbox-${index}`}
                                                /*  name={item[9]}
                                                 value={item[9]} */
                                                checked={CommentCheck == index}
                                                onChange={() => handleOnChangeComment(index)}
                                              />
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center" }} className="col-8">{item[3]}</div>


                                          </div>
                                        )
                                      })}






                                      <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "end"
                                      }} className="col-12">
                                        <div className="col-3">
                                          <button style={{ Right: "0", fontSize: "1.3em" }}
                                            className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                            onClick={(e) => saveBayComment(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                                        </div>
                                      </div>




                                    </>


                                    : Radio == "BayAverage" ?
                                      <>
                                        <div style={{
                                          display: "flex",
                                          flexDirection: "row"
                                        }} className="col-12">
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center"
                                          }} className="col-3">
                                           {props.onlyMilestone == "true" || Milestone ?
                            "Milestone"
                            :
                            changeNames[props.TranslationModal] ?  changeNames[props.TranslationModal] : props.TranslationModal

                          }
                                          </div>
                                        </div>


                                        <div style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end"
                                        }} className="col-12">
                                          <div className="col-3">
                                            <button style={{ Right: "0", fontSize: "1.3em" }}
                                              className='btn btn-primary btn-primary-blue no-print m-2'
                                              onClick={(e) => addline(e)}><i className="m-1 fas fa-plus"></i> <FormattedMessage id="AddLine" defaultMessage="Add Line" /></button>
                                          </div>
                                        </div>

                                        <div style={{
                                          display: "flex",
                                          flexDirection: "row"
                                        }} className="col-12">
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "10px"
                                          }} className="col-3">
                                            <FormattedMessage id="CommentforSum" defaultMessage="Comment for Sum " />
                                          </div>
                                        </div>


                                        {Additional && Additional.map((item, index) => {
                                          return (
                                            <PopupAdd removeLine={removeLine} item={item} key={"add" + index} Additional={Additional} SetAdditional={setAdditional} index={index} allTasks={Tasks} />
                                          )
                                        })}


                                        <br></br>

                                        <div style={{
                                          display: "flex",
                                          flexDirection: "row"
                                        }} className="col-12">
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center"
                                          }} className="col-3">
                                            <FormattedMessage id="CommentforAverage" defaultMessage="Comment for Average " />
                                          </div>
                                        </div>


                                        <div style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }} className="col-12">
                                          <div className="col-1">
                                            <input
                                              style={{ width: "100%" }}
                                              type="checkbox"
                                              id={"divided"}
                                              name={"divided"}
                                              value={"divided"}
                                              checked={divide}
                                              onChange={() => handleDivide()}
                                            />
                                          </div>
                                          <PopupDivide setSaved={setSaved} removeLine={removeLine} key={"divide1"} AdditionalDivide={AdditionalDivide} SetAdditionalDivide={setAdditionalDivide} index={0} Tasks={props.allTasks} allTasks={Tasks} />

                                          {/*  <PopupDivide removeLine={removeLine} key={"divide2"} AdditionalDivide={AdditionalDivide} SetAdditionalDivide={setAdditionalDivide} index={0} Tasks={props.allTasks} allTasks={Tasks} />
*/}
                                        </div>

                                        <br></br>

                                       {/*  <div style={{
                                          display: "flex",
                                          flexDirection: "row"
                                        }} className="col-12">
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center"
                                          }} className="col-3">
                                            <FormattedMessage id="PleaseSetBay" defaultMessage="Please Set Bay Parent" />
                                          </div>
                                        </div> */}


                                    {/*     <div style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }} className="col-12">
                                          <div className="col-1">
                                            <input
                                              style={{ width: "100%" }}
                                              type="checkbox"
                                              id={"multipliedTwo"}
                                              name={"multipliedTwo"}
                                              value={"multipliedTwo"}
                                              checked={multiplyTwo}
                                              onChange={() => handleMultiply("Two")}
                                            />
                                          </div>
                                          <PopupDivide setSaved={setSaved} removeLine={removeLine} key={"divide2"} AdditionalDivide={AdditionalDivideTwo} SetAdditionalDivide={setAdditionalDivideTwo} index={0} Tasks={props.allTasks} allTasks={Tasks} />


                                        </div> */}


                                        <div style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end"
                                        }} className="col-12">
                                          <div className="col-3">
                                            <button style={{ Right: "0", fontSize: "1.3em" }}
                                              className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                              onClick={(e) => saveAverage("")}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                                          </div>
                                        </div>




                                      </>

                                      : Radio == "BayYesNo" ?
                                        <>
                                          <div style={{
                                            display: "flex",
                                            flexDirection: "row"
                                          }} className="col-12">
                                            <div style={{
                                              display: "flex",
                                              alignItems: "center"
                                            }} className="col-3">
                                           {props.onlyMilestone == "true" || Milestone ?
                            "Milestone"
                            :
                            changeNames[props.TranslationModal] ?  changeNames[props.TranslationModal] : props.TranslationModal

                          }
                                            </div>
                                          </div>
                                          <div style={{
                                            display: "flex",
                                            flexDirection: "row"
                                          }} className="col-12">
                                            <div style={{
                                              display: "flex",
                                              alignItems: "center"
                                            }} className="col-3">
                                              <FormattedMessage id="Main" defaultMessage="Main" />
                                            </div>
                                            <div className="col-2">

                                              <input onChange={(e) => update(e)} style={{ border: "1px solid" }}
                                                className="form-control-update"
                                                maxLength="15" value={Header} />
                                            </div>
                                            <div className="col-4"></div>
                                            <div className="col-3">

                                              {/* <form onSubmit={(e)=> apply(e)}>
{/* <input value={inputText} onChange={inputHandler} type="text"/> 
<button type="submit"> Search </button>
</form> */}

                                              <button style={{ Right: "0", fontSize: "1.3em" }}
                                                className='btn btn-primary btn-primary-blue no-print m-2'
                                                onClick={(e) => applyComment(e)}><i className="m-1 fas fa-search"></i> <FormattedMessage id="Search" defaultMessage="Search" /></button>

                                            </div>
                                          </div>



                                          {List.map((item, index) => {
                                            return (
                                              <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }} className="mb-1 mt-1"
                                                key={index + "." + item[9]}>

                                                <div className="col-1">
                                                  <input
                                                    style={{ width: "100%" }}
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    /*  name={item[9]}
                                                     value={item[9]} */
                                                    checked={CommentCheck == index}
                                                    onChange={() => handleOnChangeComment(index)}
                                                  />
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }} className="col-8">{item[3]}</div>


                                              </div>
                                            )
                                          })}



                                          <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "end"
                                          }} className="col-12">
                                            <div className="col-3">
                                              <button style={{ Right: "0", fontSize: "1.3em" }}
                                                className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                                onClick={(e) => saveBayYesNo(e)}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                                            </div>
                                          </div>




                                        </>

                                        : Radio == "calculation" ?
                                          <>
                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row"
                                            }} className="col-12">
                                              <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }} className="col-3">
                                                {props.TranslationModal}
                                              </div>
                                            </div>
                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row"
                                            }} className="col-12">
                                              <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }} className="col-1">
                                                <FormattedMessage id="Sum" defaultMessage="Sum" />
                                              </div>
                                              <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">

                                                {/*  <MultiSelect
                                                options={TasksTwo}
                                                value={SelectOne}
                                                hasSelectAll={false}
                                                className={"fullwidthdropdown"}
                                                onChange={(e) => handleChange(e)}
                                                labelledBy="Select"
                                                filterOptions={filterOptions}
                                                debounceDuration="800"
                                                focusSearchOnOpen="true"
                                              /> */}

                                                <Select
                                                  className={"multiClass"} 
                                                  isClearable={true}
                                                  onChange={(e) => handleChange(e)}
                                                  options={Tasks}
                                                  filterOption={createFilter({ ignoreAccents: false })}
                                                  components={{ Option: CustomOption }}
                                                  classNamePrefix="custom-select"
                                                />



                                              </div>

                                            </div>
                                            <br style={{ marginTop: "10px" }} />

                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row"
                                            }} className="col-12">
                                              <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }} className="col-1">
                                                <FormattedMessage id="Tags" defaultMessage="Tags" />
                                              </div>


                                              <div id="dropdown" style={{
                        pointerEvents: clickable ? 'auto': 'none',
                        display: "flex", alignItems: "center" }} className="col-8">
                                                <MultiSelect
                                                  options={possibleTags}
                                                  value={SelectTwo}
                                                  hasSelectAll={false}
                                                  className={"fullwidthdropdown"}
                                                  onChange={(e) => handleChangeTwo(e)}
                                                  labelledBy="Select"
                                                // filterOptions={filterOptions}
                                                />



                                              </div>
                                            </div>





                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "end"
                                            }} className="col-12">
                                              <div className="col-3">
                                                <button style={{ Right: "0", fontSize: "1.3em" }}
                                                  className='btn btn-primary btn-primary-blue no-print m-2'
                                                  onClick={(e) => addline(e)}><i className="m-1 fas fa-plus"></i> <FormattedMessage id="AddLine" defaultMessage="Add Line" /></button>
                                              </div>
                                            </div>




                                            {Additional && Additional.map((item, index) => {
                                              return (
                                                <PopupAdd removeLine={removeLine} item={item} key={"add" + index} Additional={Additional} SetAdditional={setAdditional} index={index} allTasks={Tasks} />
                                              )
                                            })}


                                            <br></br>

                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row"
                                            }} className="col-12">
                                              <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }} className="col-3">
                                                <FormattedMessage id="Divide" defaultMessage="Divide" />
                                              </div>
                                            </div>


                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }} className="col-12">
                                              <div className="col-1">
                                                <input
                                                  style={{ width: "100%" }}
                                                  type="checkbox"
                                                  id={"divided"}
                                                  name={"divided"}
                                                  value={"divided"}
                                                  checked={divide}
                                                  onChange={() => handleDivide()}
                                                />
                                              </div>
                                              <PopupDivide setSaved={setSaved} removeLine={removeLine} key={"divide1"} AdditionalDivide={AdditionalDivide} SetAdditionalDivide={setAdditionalDivide} index={0} Tasks={props.allTasks} allTasks={Tasks} />

                                              {/*  <PopupDivide removeLine={removeLine} key={"divide2"} AdditionalDivide={AdditionalDivide} SetAdditionalDivide={setAdditionalDivide} index={0} Tasks={props.allTasks} allTasks={Tasks} />
 */}
                                            </div>

                                            <br></br>

                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row"
                                            }} className="col-12">
                                              <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }} className="col-3">
                                                <FormattedMessage id="Divide" defaultMessage="Divide" />
                                              </div>
                                            </div>


                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }} className="col-12">
                                              <div className="col-1">
                                                <input
                                                  style={{ width: "100%" }}
                                                  type="checkbox"
                                                  id={"divideTwo"}
                                                  name={"divideTwo"}
                                                  value={"divideTwo"}
                                                  checked={divideTwo}
                                                  onChange={() => handleDivide("Two")}
                                                />
                                              </div>
                                              <Multiply setSaved={setSaved} AdditionalMultiply={AdditionalMultiplyTwo} setAdditionalMultiply={setAdditionalMultiplyTwo} />

                                              {/*  <PopupDivide removeLine={removeLine} key={"divide2"} AdditionalDivide={AdditionalDivide} SetAdditionalDivide={setAdditionalDivide} index={0} Tasks={props.allTasks} allTasks={Tasks} />
 */}
                                            </div>

                                            <br></br>


                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row"
                                            }} className="col-12">
                                              <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }} className="col-3">
                                                <FormattedMessage id="Multiply" defaultMessage="Multiply" />
                                              </div>
                                            </div>


                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }} className="col-12">
                                              <div className="col-1">
                                                <input
                                                  style={{ width: "100%" }}
                                                  type="checkbox"
                                                  id={"multipliedTwo"}
                                                  name={"multipliedTwo"}
                                                  value={"multipliedTwo"}
                                                  checked={multiplyTwo}
                                                  onChange={() => handleMultiply("Two")}
                                                />
                                              </div>
                                              <PopupDivide setSaved={setSaved} removeLine={removeLine} key={"divide2"} AdditionalDivide={AdditionalDivideTwo} SetAdditionalDivide={setAdditionalDivideTwo} index={0} Tasks={props.allTasks} allTasks={Tasks} />

                                              {/*   <Multiply AdditionalMultiply={AdditionalMultiply} setAdditionalMultiply={setAdditionalMultiply} />
 */}
                                              {/*  <PopupMultiply removeLine={removeLine} key={"multiply1"} AdditionalMultiply={AdditionalMultiply} SetAdditionalMultiply={setAdditionalMultiply} index={0} Tasks={props.allTasks} allTasks={Tasks} />
 */}
                                            </div>

                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row"
                                            }} className="col-12">
                                              <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }} className="col-3">
                                                <FormattedMessage id="Multiply" defaultMessage="Multiply" />
                                              </div>
                                            </div>


                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }} className="col-12">
                                              <div className="col-1">
                                                <input
                                                  style={{ width: "100%" }}
                                                  type="checkbox"
                                                  id={"multiplied"}
                                                  name={"multiplied"}
                                                  value={"multiplied"}
                                                  checked={multiply}
                                                  onChange={() => handleMultiply()}
                                                />
                                              </div>

                                              <Multiply setSaved={setSaved} AdditionalMultiply={AdditionalMultiply} setAdditionalMultiply={setAdditionalMultiply} />

                                              {/*  <PopupMultiply removeLine={removeLine} key={"multiply1"} AdditionalMultiply={AdditionalMultiply} SetAdditionalMultiply={setAdditionalMultiply} index={0} Tasks={props.allTasks} allTasks={Tasks} />
 */}
                                            </div>


                                            <div style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "end"
                                            }} className="col-12">
                                              <div className="col-3">
                                                <button style={{ Right: "0", fontSize: "1.3em" }}
                                                  className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                                                  onClick={(e) => saveDivide("Calculation")}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                                              </div>
                                            </div>




                                          </>


                                          : ""}
              </div>
            </div>
          </div>
        </div>
      </div>



    );
  } else {
    return ("")
  }
}

export default TranslationWeightPopup

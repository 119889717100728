import React, { useEffect, useRef, useState } from 'react'
import styled from "styled-components";
import ImageCarousel, { ImageType } from "./image-carousel";
import "./styles/cssStyle.css";


const Box = styled.div`        
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99999;
`;

const Dialog = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 6px;
  text-align: center;
`;

const Camera = styled.div`
    position: relative;
`;

const ResultBox = styled.div`
    &.hasPhoto {
        left: 0;
        z-index: 99999999;
    }
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align - items: center;
    background - color: aquamarine;
    transition: 0.4s;
`;

const VideoBox = styled.video` 
    width: 100%;
    max - width: 100%;
    height: auto;
`;

const ShotButton = styled.button` 
    position: absolute;
    bottom: 20px;
    left: 20px;
    appearance: none;
    border: none;
    outline: none;
    padding: 8px 16px;
    background-color: cadetblue;
    color: #fff;
    cursor: pointer;
`;

const ButtonClose = styled.button`                
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    z-index: 99999999;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    font-size: 17pt;
    font-weight: 500;
    &:before{        
        content: 'X';        
    }    
`;

interface ICameraBurstShotUpload {
    projectid: string;
    OpenCameraStatus: () => {};
}

const CameraBurstShotUpload = (props: ICameraBurstShotUpload) => {
    const videoRef = useRef(null);
    const photoRef = useRef(null);

    const [hasPhoto, sethasPhoto] = useState(false);

    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: { width: 1920, height: 1080 }
            })
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
            }).catch(err => {
                console.log(err);
            })
    };

    const stopCamera = () => {
        let video = videoRef.current;
        const stream = video.srcObject;
        const tracks = stream.getTracks();

        for (let i = 0; i < tracks.length; i++) {
            let track = tracks[i];
            track.stop();
        }
        video.srcObject = null;
    }

    const takePhoto = () => {
        const width = 414;
        const height = width / (16 / 9);

        let video = videoRef.current;
        let photo = photoRef.current;

        photo.width = width;
        photo.height = height;

        const ctx = photo.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);

        const link = ctx;
        link.download = 'download.png';
        link.href = ctx.canvas.toDataURL();
        setImagesURLs([...imagesURLs, link.href]);
        sethasPhoto(true);
    };

    const savePhoto = () => {
        if (photoRef.current) {
            let photo = photoRef.current;
            let ctx = photo.getContext('2d');
            ctx.clearRect(0, 0, photo.width, photo.height);
        }
        sethasPhoto(false);
    };

    useEffect(() => {
        getVideo();
    }, [videoRef]);

    const [images, setImages] = useState<ImageType[]>();
    const [imagesURLs, setImagesURLs] = useState([]);

    useEffect(() => {
        if (imagesURLs.length > 0)
            setImages(
                imagesURLs.map((_url, id) => ({
                    id,
                    url: _url
                }))
            );
    }, [imagesURLs]);

    return (
        <Box>
            <Dialog>
                <span onClick={() => {
                    stopCamera();
                    if (props.OpenCameraStatus)
                        props.OpenCameraStatus();
                }} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
                <Camera className="camera">
                    <VideoBox ref={videoRef}></VideoBox>
                    <ShotButton onClick={takePhoto}>Take Photo</ShotButton>
                </Camera>
                <ResultBox className={(hasPhoto ? 'hasPhoto' : '')}>
                    {hasPhoto === false && <canvas ref={photoRef}></canvas>}
                    {hasPhoto === true && <ShotButton onClick={savePhoto}>Save</ShotButton>}
                    {hasPhoto === true && <ImageCarousel images={images} />}
                </ResultBox>
            </Dialog>
        </Box>
    );
};

export const detectDeviceType = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent)
        ? "Mobile"
        : "Desktop";
}

export default CameraBurstShotUpload;
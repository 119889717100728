import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { withRouter } from "react-router-dom";

const TemperatureCycle = (props) => {
  let dataprogram = [
    props.FinalArray["Program1"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program1"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program1"]]
      ][7],
    props.FinalArray["Program5"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program5"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program5"]]
      ][7],
    props.FinalArray["Program10"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program10"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program10"]]
      ][7],
    props.FinalArray["Program15"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program15"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program15"]]
      ][7],
    props.FinalArray["Program20"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program20"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program20"]]
      ][7],
    props.FinalArray["Program25"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program25"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program25"]]
      ][7],
    props.FinalArray["Program30"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program30"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program30"]]
      ][7],
    props.FinalArray["Program35"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program35"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Program35"]]
      ][7],
  ];
  //let dataprogram = [props.FinalArray["Program5Result"] && props.FinalArray["Program5Result"][props.Index] ? Number(props.FinalArray["Program5Result"][props.Index]) : "0", props.FinalArray["Program10Result"] && props.FinalArray["Program10Result"][props.Index] ? Number(props.FinalArray["Program10Result"][props.Index]) : "0",props.FinalArray["Program15Result"] && props.FinalArray["Program15Result"][props.Index] ? Number(props.FinalArray["Program15Result"][props.Index]) : "0",props.FinalArray["Program20Result"] && props.FinalArray["Program20Result"][props.Index] ? Number(props.FinalArray["Program20Result"][props.Index]) : "0",props.FinalArray["Program25Result"] && props.FinalArray["Program25Result"][props.Index] ? Number(props.FinalArray["Program25Result"][props.Index]) : "0",props.FinalArray["Program30Result"]&& props.FinalArray["Program30Result"][props.Index] ? Number(props.FinalArray["Program30Result"][props.Index]) : "0",props.FinalArray["Program35Result"] && props.FinalArray["Program35Result"][props.Index] ? Number(props.FinalArray["Program35Result"][props.Index]) : "0",]
  let dataDisplay = [
    props.FinalArray["Display1"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display1"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display1"]]
      ][7],
    props.FinalArray["Display5"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display5"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display5"]]
      ][7],
    props.FinalArray["Display10"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display10"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display10"]]
      ][7],
    props.FinalArray["Display15"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display15"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display15"]]
      ][7],
    props.FinalArray["Display20"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display20"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display20"]]
      ][7],
    props.FinalArray["Display25"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display25"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display25"]]
      ][7],
    props.FinalArray["Display30"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display30"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display30"]]
      ][7],
    props.FinalArray["Display35"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display35"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Display35"]]
      ][7],
  ];
  let dataBooth = [
    props.FinalArray["Booth1"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth1"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth1"]]
      ][7],
    props.FinalArray["Booth5"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth5"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth5"]]
      ][7],
    props.FinalArray["Booth10"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth10"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth10"]]
      ][7],
    props.FinalArray["Booth15"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth15"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth15"]]
      ][7],
    props.FinalArray["Booth20"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth20"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth20"]]
      ][7],
    props.FinalArray["Booth25"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth25"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth25"]]
      ][7],
    props.FinalArray["Booth30"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth30"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth30"]]
      ][7],
    props.FinalArray["Booth35"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth35"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["Booth35"]]
      ][7],
  ];
  let dataPanelHor = [
    props.FinalArray["PanelHor1"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor1"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor1"]]
      ][7],
    props.FinalArray["PanelHor5"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor5"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor5"]]
      ][7],
    props.FinalArray["PanelHor10"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor10"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor10"]]
      ][7],
    props.FinalArray["PanelHor15"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor15"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor15"]]
      ][7],
    props.FinalArray["PanelHor20"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor20"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor20"]]
      ][7],
    props.FinalArray["PanelHor25"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor25"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor25"]]
      ][7],
    props.FinalArray["PanelHor30"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor30"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor30"]]
      ][7],
    props.FinalArray["PanelHor35"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor35"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelHor35"]]
      ][7],
  ];
  let dataPanelVer = [
    props.FinalArray["PanelVer1"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer1"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer1"]]
      ][7],
    props.FinalArray["PanelVer5"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer5"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer5"]]
      ][7],
    props.FinalArray["PanelVer10"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer10"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer10"]]
      ][7],
    props.FinalArray["PanelVer15"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer15"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer15"]]
      ][7],
    props.FinalArray["PanelVer20"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer20"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer20"]]
      ][7],
    props.FinalArray["PanelVer25"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer25"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer25"]]
      ][7],
    props.FinalArray["PanelVer30"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer30"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer30"]]
      ][7],
    props.FinalArray["PanelVer35"] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer35"]]
      ] &&
      props.tasks[
        props.FinalArray["SprayBoothBayResult"][0] +
          "." +
          props.item +
          [props.FinalArray["PanelVer35"]]
      ][7],
  ];

  // console.log(dataprogram)

  var options = {
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 30,
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 4,
          label: {
            enabled: false,
            content: "Test label",
          },
        },
      ],
    },

    plugins: {
      legend: {
        display: true,
        position: "bottom",
        color: "#6A7284",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          borderWidth: "10",
          color: "#6A7284",
          fontColor: "#6A7284",
        },
      },
    },

    pointBorderWidth: 2,
    responsive: true,
    scales: {
      xAxis: {
        grid: {
          display: false,
          drawBorder: false,
        },

        ticks: {
          padding: 0,
          maxRotation: 0,
          minRotation: 0,
          color: "#6A7284",
          fontColor: "#6A7284",
        },
        color: "#6A7284",
        fontColor: "#6A7284",
      },
      yAxis: {
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          stepSize: 10,
          color: "#6A7284",
          fontColor: "#6A7284",

        },
        max: 70,
        padding: 20,
        min: 0,
      },
    },
  };

  const data = {
    labels: [
      "0 Min",
      "5 Min",
      "10 Min",
      "15 Min",
      "20 Min",
      "25 Min",
      "30 Min",
      "35 Min",
    ],
    datasets: [
      {
        label: "Program T",
        data: dataprogram,
        fill: false,
        borderColor: "#F99F1B",
        pointRadius: 0,
      },
      {
        label: "Display T",
        data: dataDisplay,
        fill: false,
        borderColor: "#322D7B",
        pointRadius: 0,
      },
      {
        label: "Booth T",
        data: dataBooth,
        fill: false,
        borderColor: "#0DD391",
        pointRadius: 0,
      },
      {
        label: "Panel Hor. T",
        data: dataPanelHor,
        fill: false,
        borderColor: "#dc616a",
        pointRadius: 0,
      },
      {
        label: "Panel Vert. T",
        data: dataPanelVer,
        fill: false,
        borderColor: "#6e6ac2",
        pointRadius: 0,
      },
      {
        label: "Default",
        data: [60, 60, 60, 60, 60, 60, 60, 60],
        fill: false,
        borderColor: "grey",
        pointRadius: 0,
        borderDash: [10, 5],
      },
    ],
  };

  return (
    <div>
      <div
        className="chart-container pos-relative"
      >
        <Line height={null} width={null} options={options} data={data} />
      </div>
    </div>
  );
};

export default TemperatureCycle;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
});
const updateWidthAndHeight = () => {
  setWidth(window.innerWidth);
};

  return (
    <div className="row footer mt-5 pl-0" style={ {"width" : width - 30} }>
        <img src="/images/emprouer-logo.png"  alt="emprouer" className="infinity-logo-1"/>
        <img src="/images/logo_refinity.png"  alt="refinity" className="infinity-logo"/>
        <div className="col-md-12 text-center">
          <ul>
            <li>
              <Link to="">Privacy Policy (English, United States)</Link>
            </li>            
            <li>
              <Link to="">Credits (English, United States)</Link>
            </li>
            <li>
              <span className="coptytights">Copyrights &copy; 2022 BASF</span>
            </li>
          </ul>
        </div>
    </div>
  );
};

export default Footer;
import React, { useState, useRef, useEffect } from 'react';
function Tooltip(props) {
  const node = useRef();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);  // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    console.log(props.content)
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }  // outside click 
    props.setTooltipState(false)
  };

/*   let picture = props.content
  
  
  if (picture.includes("jpeg") || picture.includes("jpg") || picture.includes("png") || picture.includes("webp")) {
  }
  console.log(picture)
  console.log(props) */
  /*       // split between parenthesis */

  return (
    <>
      {/*  <div className="tooltiptextOne"> */}
<div className="popup-card  border-gray border-radius-16 pd-20" ref={node}> 
    <div className="close-icon"><img src="/images/close-btn.svg" alt="close btn" onClick={() =>  props.setTooltipState(false)}/></div>
    {props.content.includes("jpeg") || props.content.includes("jpg") || props.content.includes("png") || props.content.includes("webp") ?
     <div class="img-wrapper">
      <img alt="task img" src={props.content.replace(/^[^(]*\(/, "") // trim everything before first parenthesis
        .replace(/\)[^(]*$/, "") // trim everything after last parenthesis
        .split(/\)[^(]*\(/)}></img>    
      </div> 
      :  <div>{props.content}</div>}
  
</div>
    </>
  );
}

export default Tooltip
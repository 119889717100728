import { useState } from "react";
import axios from "axios";
import { useIntl, FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import LanguageSwitcher from '../language-switcher/language-switcher';


const ForgotPasswordScreen = ({ history, match, location }) => {
  const intl = useIntl();
  const [emailState, setEmailState] = useState("");
  const forgotPasswordHandler = async (e) => {
    e.preventDefault();
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    const email = emailState.toLowerCase();
    try {
      const { data } = await axios.post(
        "/api/auth/forgotpassword",
        { email },
        config
      );
      console.log(data);
      toast.success(intl.formatMessage({ id: "IfyouremailbelongtooursystemthenPleasecheckyouremailtogetthepasswordresetlink", defaultMessage: "If your email belong to our system then Please check your email to get the password reset link" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      setTimeout(() => {
        history.push("/login");
      }, 4000);

    } catch (error) {
      toast.error(intl.formatMessage({ id: "IfyouremailbelongtooursystemthenPleasecheckyouremailtogetthepasswordresetlink", defaultMessage: "If your email belong to our system then Please check your email to get the password reset link" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  };

  return (
    <>
      <ToastContainer toastClassName="top-zIndex" autoClose={5000} />
      <div id="take_screenshot_now" className="hold-transition login-page">
        <div className="fix-lanuage-switcher">
          <LanguageSwitcher side="left" />
        </div>
        <div className="login-box">
          <div className="login-logo">
            <b><FormattedMessage id="ForgotPassword" defaultMessage="Forgot Password" /></b>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p><FormattedMessage id="PleaseentertheemailaddressyouregisteryouraccountwithWewillsendyouresetpasswordconfirmationtothisemail" defaultMessage="Please enter the email address you register your account with. We
            will send you reset password confirmation to this email" /></p>
              <form>
                <div className="input-group mt-3 mb-3">
                  <input className="form-control"
                    type="email"
                    required
                    id="email"
                    placeholder="Email address"
                    value={emailState}
                    onChange={(e) => setEmailState(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button id="submit" type="submit" className="btn cta-btn btn-login" onClick={(e) => forgotPasswordHandler(e)}>
                    <FormattedMessage id="Send" defaultMessage="Send" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordScreen;

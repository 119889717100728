import { BrowserRouter as Router, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
// Routing
import PrivateRoute from "./components/routing/PrivateRoute";
// Screens
import Navbar from "./components/screens/navbar.component";
import Menu from "./components/screens/menu";
import MobileMenu from "./components/screens/mobileMenu";
import "react-toastify/dist/ReactToastify.min.css";

import ProjectElementHistory from "./components/screens/ProjectElementHistory";
import Versioning from "./components/screens/Versioning/Project-Versioning";
import VersioningsOverview from "./components/screens/Versioning/Project-Versionings-Overview";
import UserManagement from "./components/screens/User/UserOverview";
import SingleUser from "./components/screens/User/UserSingle";
import GroupManagement from "./components/screens/Groups/GroupOverview";
import SingleGroup from "./components/screens/Groups/GroupSingle";
import ForgotPasswordScreen from "./components/screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import ShowSingleProject from "./components/screens/showSingleProject";
import GlobalTags from "./components/screens/Tags/setGlobalTags";
import Logout from "./components/screens/logout.js";
import Projects from "./components/screens/projects/projects";
import LoginScreen from "./components/screens/LoginScreen";
import { createBrowserHistory } from "history";
import CreateProjects from "./components/screens/createProjects";
import { messages } from "./language/site-translation/messages";
import { LanguageProvider } from "./language/language.provider";
import Settings from "./components/screens/userPreferences/settings";
import VersioningRights from "./components/screens/rights/versioning";
import UserAdministationRights from "./components/screens/rights/useradministration";
import RenumberingRights from "./components/screens/rights/renumbering";
import ChangeProjectsRights from "./components/screens/rights/changeprojects";
import NotesRights from "./components/screens/rights/notes";
import CreateProjectsRights from "./components/screens/rights/createprojects";
import "antd/dist/antd.css";
import "./index.css";


const history = createBrowserHistory();
const jwt = () => {
  let jwttoken = localStorage.getItem("authToken");
};
jwt();

const App = (props) => {
  const [openPage, SetOpenPage] = useState("");

  return (
    <LanguageProvider messages={messages}>
      <Router {...{ history }}>
        <Route exact path="/login" component={LoginScreen} history={history} />
        <Route
          history={history}
          exact
          path="/forgotpassword"
          component={ForgotPasswordScreen}
        />
        <Route
          history={history}
          exact
          path="/changepassword/:resetToken"
          component={ResetPasswordScreen}
        />
        <Route
          history={history}
          exact
          path="/finishregistration/:resetToken/:userid"
          component={ResetPasswordScreen}
        />
        <PrivateRoute exact path="/">
          <div className="no-mobile-top-menu">
            <Menu />
            <Navbar />
            <Projects dev={props.dev} SetOpenPage={SetOpenPage} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/projects">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <Projects dev={props.dev} SetOpenPage={SetOpenPage} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/projects-deleted">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <Projects
              deleted={true}
              dev={props.dev}
              SetOpenPage={SetOpenPage}
            />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/user-management">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <UserManagement dev={props.dev} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/tag-management">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <GlobalTags dev={props.dev} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/user/:id">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <SingleUser dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/rights/versioning">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <VersioningRights dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/rights/useradministration">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <UserAdministationRights dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/rights/renumbering">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <RenumberingRights dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/rights/changeprojects">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <ChangeProjectsRights dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/rights/notes">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <NotesRights dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/rights/createprojects">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <CreateProjectsRights dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/group/:id">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <SingleGroup dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/versioning/:id">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <Versioning dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/versioning">
          <div className="no-mobile-top-menu">
            <Navbar />
            <Menu />
            <VersioningsOverview dev={props.dev} history={history} />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/project-history/:id">
          <Navbar />
          <Menu />
          <ProjectElementHistory dev={props.dev} history={history} />
        </PrivateRoute>

        <PrivateRoute exact path="/create-projects/">
          <Navbar />
          <Menu />
          <CreateProjects dev={props.dev} history={history} />
        </PrivateRoute>

        {/* <PrivateRoute path="/project/:id/Overview">
          <Navbar openPage={openPage} SetOpenPage={SetOpenPage} />
          <Menu />
          <ShowSingleProject
            dev={props.dev}
            openPage={openPage}
            SetOpenPage={SetOpenPage}
            page={"Overview"}
            history={history}
          />
        </PrivateRoute> */}

        <PrivateRoute path="/project/:projectId">
          <Navbar openPage={openPage} SetOpenPage={SetOpenPage} />
          <Menu />
          <ShowSingleProject
            dev={props.dev}
            openPage={openPage}
            SetOpenPage={SetOpenPage}
            page={"Overview"}
            history={history}
          />
        </PrivateRoute>

        {/* <PrivateRoute path="/project/:id/BodyShop">
          <Navbar openPage={openPage} SetOpenPage={SetOpenPage} />
          <Menu />
          <ShowSingleProject
            dev={props.dev}
            openPage={openPage}
            SetOpenPage={SetOpenPage}
            page={"BodyShop"}
            history={history}
          />
        </PrivateRoute>

        <PrivateRoute path="/project/:id/KPI">
          <Navbar openPage={openPage} SetOpenPage={SetOpenPage} />
          <Menu />
          <ShowSingleProject
            dev={props.dev}
            openPage={openPage}
            SetOpenPage={SetOpenPage}
            page={"KPI"}
            history={history}
          />
        </PrivateRoute>

        <PrivateRoute path="/project/:id/KPIDashboard">
          <Navbar openPage={openPage} SetOpenPage={SetOpenPage} />
          <Menu />
          <ShowSingleProject
            dev={props.dev}
            openPage={openPage}
            SetOpenPage={SetOpenPage}
            page={"KPIDashboard"}
            history={history}
          />
        </PrivateRoute>

        <PrivateRoute path="/project/:id/PaintShop">
          <Navbar openPage={openPage} SetOpenPage={SetOpenPage} />
          <Menu />
          <ShowSingleProject
            dev={props.dev}
            openPage={openPage}
            SetOpenPage={SetOpenPage}
            page={"PaintShop"}
            history={history}
          />
        </PrivateRoute>

        <PrivateRoute path="/project/:id/Compressor">
          <Navbar openPage={openPage} SetOpenPage={SetOpenPage} />
          <Menu />
          <ShowSingleProject
            dev={props.dev}
            openPage={openPage}
            SetOpenPage={SetOpenPage}
            page={"CompressedAir"}
            history={history}
          />
        </PrivateRoute> */}

        <PrivateRoute exact path="/setting">
          <div className="no-mobile-top-menu">
            <Menu />
            <Navbar />
            <Settings />
          </div>
        </PrivateRoute>
        <Route exact path="/logout" component={Logout} />
      </Router>
    </LanguageProvider>
  );
};

export default App;

import React, { useEffect , useState } from "react";
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { Collapse } from 'antd';
const { Panel } = Collapse;
const Milestone = (props) => {
  console.log("Milestone Props")
  console.log(props)
  const [filterMilestone,setFilterMilestone] = useState([])

  const toTimestamp = (dateString) => {
    const year = +dateString.substring(0, 4);
    const month = +dateString.substring(4, 6);
    const day = +dateString.substring(6, 8);

    return day + '/' + month + '/' + year;
  }

  useEffect(() => {
    var resArr = [];
    props.milestoneData.length > 0  && props.milestoneData.filter(function(item){
  var i = resArr.findIndex(x => (x.projectID === item.projectID));
  if(i <= -1){
        resArr.push(item);
  }
  return null;
 
});
console.log('resARR')
console.log(resArr)
setFilterMilestone(resArr)
console.log('resARR')
console.log(props.projects)
  },[props.milestoneData])
  return (
    <div>
      {props.show && (
        <div style={{ display: "block" }} id={"popup-" + props.heading} className="fq-modal">
          <div className="fq-modal-content milestone-content">
            <div style={{ background: "#194A96" }}
              className="fq-modal-header">
              <span onClick={props.onHide} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
              <h1><FormattedMessage id={props.heading.replace(/\s/g, '')} defaultMessage={props.heading} /> <sup className="small-text">({props.milestoneCount})</sup></h1>
            </div>
            <div className="fq-modal-content-inner pd-20">
             { filterMilestone.map((itemparent,indexparent) => {
               return (
                 <>
                { props.projects.map((itemsubparent,indexsubparent) => {
                  if(itemparent.projectID === itemsubparent.projectID){
                    return <Link to={`/project/${itemparent.projectID}`} className="project-name">{itemsubparent.projectName}</Link>
                  }
                 })
                }
               
                <ul>
               {props.milestoneData.map((item, index) => {
                 if(itemparent.projectID === item.projectID){
                return <li className="d-flex align-items-center"><span className="mr-2">{item.Title}</span> (<div className="d-flex flex-column mr-2"><span className="date-text">Start Date</span><span>{toTimestamp(item.startDate + "")}</span></div> - <div className="d-flex flex-column ml-2"><span className="date-text">Due Date</span><span>{toTimestamp(item.dueDate + "")}</span></div>)</li>
                  
               }
                })
             } 
             </ul>
             </> 
             )
            })
          }
          
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Milestone
import React, { useEffect, useState } from "react";

const ProjectTasklistCheckbox =
  ({ item, allTasks, checked, onCheckboxChange }) => {
    const [localChecked, setLocalChecked] = useState(false);

    useEffect(() => {
      setLocalChecked(checked.includes(item));
    }, [checked, item]);

    const handleParentCheckboxChange = (itemId, isChecked) => {
      onCheckboxChange(itemId, isChecked);
    };

    return (
      <>
      <input
        type="checkbox"
        className="form-check-input"
        id={"Task" + item}
        checked={localChecked}
        onChange={(e) => {
          handleParentCheckboxChange(item, e.target.checked);
        }}
      />
      </>
    );
  }

export default ProjectTasklistCheckbox;

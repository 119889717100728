import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default class Menu extends Component {
  //localStorage.getItem("authToken"))


  state = {

    rights: localStorage.getItem("rights") ? JSON.parse(atob(localStorage.getItem("rights")), console.log("test")) : (localStorage.clear(), console.log("test"), {})


  }

  /*   componentDidMount() {
        this.setState({ rights : localStorage.getItem(atob(JSON.parse(data.rights))) });
    } */

  render() {
    console.log(this.state)

    const showLeftMenu = (el) => {

      document.body.classList.add("hide-left")
    }

    return (
      <div className="left">
        <div className=" leftbar pos-fixed-left top-70px bg-custom-gray border-right-light-abs" style={{ zIndex: 999 }}>
          <nav className>
            <NavLink exact to="/Projects" className="leftbar-menu ">
              <div className="icon-text">
                <img src="/images/icons/icon-dots.svg" alt="icon" className="icon" />
                <span className="ml-4"><FormattedMessage id="Projects" defaultMessage="Projects" /></span>
              </div>
            </NavLink >


            {this.state.rights.Versioning &&
              <NavLink to="/versioning" className="leftbar-menu ">

                <div className="icon-text">
                  <img src="/images/icons/icon-list.svg" alt="icon" className="icon" />
                  <span className="ml-4"><FormattedMessage id="Versioning" defaultMessage="Versioning" /></span>
                </div>

              </NavLink >
            }
            {this.state.rights.UserAdministration &&
              <NavLink to="/user-management" className="leftbar-menu ">

                <div className="icon-text" >
                  {/*  <i className="fa fa-user" className="icon"></i> */}
                  <i style={{ fontSize: "22px" }} className="fa fa-users" ></i>

                  {/*  <img src="/images/icons/icon-list.svg" alt="icon"  /> */}
                  <span className="ml-4"><FormattedMessage id="Users" defaultMessage="Users" /></span>
                </div>

              </NavLink >
            }
            {this.state.rights.Versioning &&
              <NavLink to="/tag-management" className="leftbar-menu ">

                <div className="icon-text" >
                  {/*  <i className="fa fa-user" className="icon"></i> */}
                  <i style={{ fontSize: "22px" }} className="fa fa-tags" ></i>

                  {/*  <img src="/images/icons/icon-list.svg" alt="icon"  /> */}
                  <span className="ml-4"><FormattedMessage id="Tags" defaultMessage="Tags" /></span>
                </div>

              </NavLink >
            }
            {this.state.rights.Admin &&
              <NavLink to="/projects-deleted" className="leftbar-menu ">

                <div className="icon-text" >
                  {/*  <i className="fa fa-user" className="icon"></i> */}
                  <i style={{ fontSize: "22px" }} className="fa fa-repeat" ></i>

                  {/*  <img src="/images/icons/icon-list.svg" alt="icon"  /> */}
                  <span className="ml-4"><FormattedMessage id="Deleted" defaultMessage="Deleted" /></span>
                </div>

              </NavLink >
            }
          </nav>
        </div>
        {/* leftbar */}

      </div>




    );


  }
}
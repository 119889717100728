import React, { useEffect, useState } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const MapComponent = (props) => {
  console.log("Map Props");
  const [activeMarker, setactiveMarker] = useState({})
  const [selectedPlace, setselectedPlace] = useState({})
  const [showingInfoWindow, setshowingInfoWindow] =  useState(false)
  const [bounds, setbounds] = useState(null)
  const onMarkerClick = (props, marker) => {
    setactiveMarker(marker)
    setselectedPlace(props)
    setshowingInfoWindow(true)
  }
  const onInfoWindowClose = () => {
    setactiveMarker(null)
    setshowingInfoWindow(false)
  }
  const getpinClass = (percentage) => {
    if (percentage < 50) {
      return "red"
    } else if (percentage < 85 && percentage > 0) {
      return "yellow"
    } else if (percentage < 100 && percentage > 85) {
      return "green"
    } else if (percentage == 100) {
      return "dark-green"
    }else{
      return "red"
    }
  }
  const getLabel = (percentage) => {  
    if(percentage === null){
      return '0'
    }
      return '' + Math.round(percentage)
  }
  const getInitalpoints = (obj) => {
    return obj.position
  }
  const makeBounds = () => {
  var bounds = new props.google.maps.LatLngBounds();
    props.markers.map((item) => {
      bounds.extend(item.position)
    })
    setbounds(bounds)
  }
  const onReady = () => {
   makeBounds()
  }

  useEffect(() => {
   makeBounds()
   console.log('Markers: ')
   console.log(props.markers)
  },[props.markers])
    const style = {
      width: '100%',
      height: '100%',
    }
    return (
      <Map google={props.google}  onReady={onReady} bounds={bounds} style={style} >
      {props.markers.map(({ percentage, position, projectID, projectName}) => (

         <Marker
          projectId={projectID}
          key={projectID}
          name={projectName}
          position={position}
          onClick={onMarkerClick}
          label={ {text: getLabel(percentage.TotalAuditScore), color: "#fff"}}
          icon= {{
            url: `/images/icons/new/${getpinClass(percentage.TotalAuditScore)}-marker-pin.svg`
          }}
       />
        ))}

         <InfoWindow
          marker={activeMarker}
          onClose={onInfoWindowClose}
          visible={showingInfoWindow}>
          <div>
            <p>{selectedPlace.name}</p>

            <a href={`/project/${selectedPlace.projectId}`}>View Project</a>

          </div>
        </InfoWindow>
      </Map>
    );

}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyC1HdsZ1rBMKZ_jHRjYMtO0rX51rBDZ93Y")
})(MapComponent)

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { MultiSelect } from "react-multi-select-component";

import axios from 'axios';
    
const Multiply = (props) => {
    const [SelectOne, setSelectOne] = useState([])
    const [firstChoice, setFirstChoice] = useState(false)
  


const handleInputChange = (e) => {

    if (!Number(e.target.value)) {
        return;
    }

    props.setAdditionalMultiply(e.target.value)
}
if (props.AdditionalMultiply) {
    return (

        <>
        <div   className="col-8"> 
        <input id="borderCalc"
        style={{paddingLeft : "4px", borderRadius: "4px", height: "38px", border :  "#ccc 1px solid"}}
                name="numberOfGuests"            type="number"
                value={props.AdditionalMultiply}
                onChange={(e)=>handleInputChange(e)} />
         </div>   
        </>
    
       )
} else {
    return <></>
}
  
    
    
        }
    

      export default Multiply

import React from 'react';
 
import { Link, Redirect, withRouter } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import { useIntl, FormattedMessage } from 'react-intl';
import Footer from '../footer'

const Versioning = (props) => {

  const idd = props.match.params.id
  const [error, setError] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Versions, SetVersions] = useState("");
  const [ItemID, SetItemID] = useState("");
  const [Saved, SetSaved] = useState(true);
  const [AdditionalFields, SetAdditionalFields] = useState("");
  const [TagsModal, SetTagsModal] = useState(false)
  const [VersioningNumber, setVersioningNumber] = useState(false)
  /* const [Tags, setTags] = useState(false) */


  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };


  useEffect(() => {

    const fetch = async () => {
      const DBGet = axios.get(`/api/private/versioning/${idd}`, config);
      const DBMain = axios.get("/api/private/versioning/main", config);
    /*   const DBTags = axios.get("/api/private/selectedTags", config); */
      

      try {
        const [dbprojects, main ] = await Promise.all([DBGet, DBMain])

        SetItemID(dbprojects.data._id)
        SetVersions(dbprojects.data.tasks)
        setVersioningNumber(dbprojects.data.projectType)
    /*   setTags(dbprojects.data.tasks.AllTags) */
        console.log(dbprojects.data)
        console.log(idd)
       
        /* console.log(main.data) */
        let b = main.data.tasks
        let a = dbprojects.data.tasks
        const c = Object.entries(b).reduce((c, [k, v]) => Object.assign(c, a[k] ? {} : { [k]: v }), {});
        SetAdditionalFields(c)
        console.log(c);
      } catch (e) {
        if(!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }

      }
    }

    fetch().then(
      setLoading(false)
    );

  }, [TagsModal]);





  const update = (e, key) => {


    if (Saved) {
      SetSaved(false)
    }

    if (e.target.value === "") {
      SetVersions((prevState) => ({
        ...prevState,
        [key]: " "
      }));
    } else {
      SetVersions((prevState) => ({
        ...prevState,
        [key]: e.target.value
      }));
    }



    console.log(Versions)
    /*  AllTasks.key = e.target.value
     console.log(AllTasks) */
  };

  const updateTwo = (e, key) => {

    if (Saved) {
      SetSaved(false)
    }

    SetAdditionalFields((prevState) => ({
      ...prevState,
      [key]: e.target.value
    }));
    /*  AllTasks.key = e.target.value
     console.log(AllTasks) */
  };



  const save = () => {
    let summarized = {
      ...Versions,
      ...AdditionalFields
    };
    SetVersions((prevState) => ({
      ...prevState, ...AdditionalFields
    }));

    SetAdditionalFields((prevState) => ({

    }));

    const Body = {
      _id: ItemID,
      projectType: idd,
      tasks: summarized
    }
    const fetch = async () => {
      try {
        const DBGet = await axios.post(`/api/private/versioning/${idd}`, Body, config);
        console.log(DBGet.data)
      } catch (e) {
        if(!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    fetch().then(
      SetSaved(true)
    )
  };



  const AddRow = () => {
    if (Saved) {
      SetSaved(false)
    }


    do {
      var person = prompt("What is the name of the new field?")

      if (person == "" || person == null) {
        break; //break out of the function early
      }

    } while (person.length < 0 && person.length > 15);



    SetVersions((prevState) => ({
      ...prevState,
      [person]: "empty"
    }));

    console.log(Versions)

  };





  if (!Loading) {
   
      return (
        <>

          <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100">
            {/* Content Header (Page header) */}

            <section className="content-header">
              <div id="container" className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    {/* <button onClick={() => writeDatabase()}>Update</button> */}
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item"><a href="#"><FormattedMessage id="Home" defaultMessage="Home" /></a></li>
                      <li className="breadcrumb-item active"><FormattedMessage id="Projects" defaultMessage="Projects" /></li>
                    </ol>
                  </div>
                </div>
              </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">

                      {/* /.card-header */}
                      <div >

                        <table style={{ marginBottom: "0px" }} id="example2" className="table table-bordered table-hover">
                          <thead>

                            <tr>
                              <th><FormattedMessage id="FieldinDashboard" defaultMessage="Field in Dashboard" /></th>
                              <th><FormattedMessage id="NameinTeamwork" defaultMessage="Name in Teamwork" /></th>

                            </tr>
                          </thead>
                          <tbody>



                            {Object.entries(Versions).map(([key, value], i) => {

                              return (
                                <tr role="row">
                                  <td className="sorting_1">{key}
                                  </td>
                                  {/* <VersioningUpdate key={key+value} value={value} /> */}
                                  <td style={{ padding: 0 }} /*  className="sorting_1" */>
                                    <input onChange={(e) => update(e, key)} type="email" className="form-control-update" id="exampleInputEmail1" maxLength="10" aria-describedby="emailHelp" defaultValue={value} />
                                  </td>

                                </tr>

                              )

                            })}
                            {Object.entries(AdditionalFields).map(([key, value], i) => {
                              return (
                                <tr role="row">
                                  <td style={{ color: "Red", fontWeight: "Bold" }} className="sorting_1">{key}
                                  </td>
                                  {/* <VersioningUpdate key={key+value} value={value} /> */}
                                  <td style={{ padding: 0 }} /*  className="sorting_1" */>
                                    <input onChange={(e) => updateTwo(e, key)} type="email" className="form-control-update" id="exampleInputEmail1" maxLength="10" aria-describedby="emailHelp" defaultValue={""} />
                                  </td>

                                </tr>

                              )

                            })}



                          </tbody>
                        </table>

                      </div>


                      {/* /.card-body */}
                    </div>

                    {/*   {props.Versioning & Editing ?
          <button style={{ borderColor: "white", borderRadius: "8px" }} type="button" className="btn btn-primary btn-primary-blue m-2 TagButton" onClick={() => props.setTagsModal(true)}  > <FormattedMessage id="SetTags" defaultMessage="Set Tags" />
          </button>
          : ""}
*/}
                    <div style={{ paddingRight: "7.5px", paddingLeft: "7.5px", justifyContent: "flex-end" }} className="row mb-2">
                     {/*  <button style={{ Right: "0", fontSize: "1.3em" }}
                        className={'btn btn-primary btn-primary-blue no-print m-2'}
                        onClick={() => SetTagsModal(true)}> <FormattedMessage id="SetTags" defaultMessage="Set Tags" /></button>

 */}
                      <button style={{ Right: "0", fontSize: "1.3em" }}
                        className={'btn btn-primary btn-primary-blue no-print m-2'}
                        onClick={() => AddRow()}><i className="m-1 fas fa-plus"></i> <FormattedMessage id="AddField" defaultMessage="Add Field" /></button>
                      <button style={{ Right: "0", fontSize: "1.3em" }}
                        className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
                        onClick={() => save()}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                    </div>

                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>

        </>
      )
    }


  
  else {
    return (

      <section style={{ minHeight: "100vh" }} className="content-header">
        <div className="SpinnerCon container-fluid">
          <div className="spinner-border" role="status">
            <span className="sr-only"><FormattedMessage id="Loading..." defaultMessage="Loading..." /></span>
          </div>
        </div>
      </section>

    )

  }
}
export default withRouter(Versioning)
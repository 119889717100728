import React from 'react';
import _ from 'lodash';
import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import { useIntl, FormattedMessage } from 'react-intl';
import Footer from '../footer'
import AddUserPopup from './partials/addUserPopup'
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';

import { fi } from 'date-fns/locale';
import { Modal } from 'antd';
import 'antd/dist/antd.css';

const UserManagement = (props) => {
  const intl = useIntl();
  const [Loading, setLoading] = useState(true);

  const [isSaving, setIsSaving] = useState(false);
  const [seeUsers, setSeeUsers] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [emails, setEmails] = useState([]);
  const [Rights, setRights] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [filterval, setFilterVal] = useState("")
  const [usersData, setUsersData] = useState("")
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [itemToDelete, SetItemToDelete] = useState("")

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const users = axios.get("/api/private/users", config);
        const [usersDB] = await Promise.all([users])
        console.log(usersDB.data)
        setSeeUsers(usersDB.data)
        setUsersData(usersDB.data)
        let usernamearr = []
        usersDB.data.forEach(item => {
          usernamearr.push(item.username)
        })
        let emailarr = []
        usersDB.data.forEach(item => {
          emailarr.push(item.email)
        })
        if (usersDB.data.length > 1) {
          setRights(true)
        }
        setUsernames(usernamearr)
        setEmails(emailarr)
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    fetch().then(
      setLoading(false)
    );
  }, [setIsSaving, isSaving, showModal]);

  const deleteIt = () => {
    const e = itemToDelete;
    console.log('e: ' + e);
    const fetch = async () => {
      try {
        const DBGet = await axios.delete(`/api/private/user/${e}`, config);
        setIsDeleteModalVisible(false)
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    fetch().then(
      setIsSaving(prevCheck => !prevCheck)

    )

  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setIsDeleteModalVisible(false);
    SetItemToDelete("");
  };


  const addNewUser = () => {
    if (newUsername === "" || newUsername == null || newEmail === "" || newEmail == null) {
      toast.error(intl.formatMessage({ id: "SomefieldsMissing", defaultMessage: "Some fields Missing" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return;
    }

    if (newUsername.length < 4 || newUsername.length > 40) {
      toast.error(intl.formatMessage({ id: "Usernameshouldbebetween4and20Characters", defaultMessage: "Username should be between 4 and 40 Characters" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return;
    }

    if (newEmail.length < 4 || newEmail.length > 80) {
      toast.error(intl.formatMessage({ id: "Emailshouldbebetween4and20Characters", defaultMessage: "Email should be between 4 and 80 Characters" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return;
    }

    if (!validator.isEmail(newEmail)) {
      toast.error(intl.formatMessage({ id: "PleasEnterValidEmailAddress", defaultMessage: "Please enter valid email address!" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return;
    }


    console.log("true")
    const Body = {
      username: newUsername.toString().trim().toLowerCase(),
      email: newEmail.toString().trim().toLowerCase(),
    }
    const fetch = async () => {
      try {
        const DBGet = await axios.post(`/api/private/user`, Body, config);
        console.log(DBGet)
        toast.success(intl.formatMessage({ id: "UserAddedSuccessfully", defaultMessage: "User Added Successfully!" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
        setshowModal(false);
      } catch (e) {
        toast.error(intl.formatMessage({ id: "UsernameorEmailAlreadyExists", defaultMessage: "Username or Email already exists" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    fetch().then(
      setSeeUsers((prevState) => ([
        ...prevState, Body
      ])),
    )
  };

  const popupHide = () => {
    setshowModal(false);
  }

  const deletePopup = (e) => {
    SetItemToDelete(e);
    setIsDeleteModalVisible(true);
  }

  // useEffect(() => {
  //   //setSeeUsers(usersData)

  // },[filterval])

  const onChangeTableFilter = (val) => {

    if (val.toString() !== "") {
      //setSeeUsers(usersData)
      let filterarr = []
      seeUsers.map((arrItem, Index) => {
        if (_.includes(arrItem.username.toString().toLowerCase(), val.toString().toLowerCase()) || _.includes(arrItem.email.toString().toLowerCase(), val.toString().toLowerCase())) {
          filterarr.push(arrItem)
        }
      })
      if (filterarr.length > 0) {
        setSeeUsers(filterarr)
      }
    } else {
      setSeeUsers(usersData)
    }
  }


  if (!Loading && seeUsers) {
    return (
      <>
        <Modal title="Confirmation" visible={isDeleteModalVisible} onCancel={handleCancel} okText={intl.formatMessage({ id: "Delete", defaultMessage: "Delete" })} centered onOk={deleteIt} okType='danger'>
          <p><FormattedMessage id="Doyoureallywanttodeleteit?" defaultMessage="Do you really want to delete it?" /></p>
        </Modal>
        <ToastContainer toastClassName="top-zIndex" autoClose={6000} />
        <AddUserPopup
          show={showModal}
          onHide={popupHide}
          setNewUsername={setNewUsername}
          setNewEmail={setNewEmail}
          newUsername={newUsername}
          newEmail={newEmail}
          adduser={addNewUser}
        />
        <div className="users-page main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 l-20">
          <div className="main-body bg-custom-white pt-0 pd-x-20 pr-0">
            {Rights ?
              <div className="add-circle-wrapper floating-btn" onClick={() => { setshowModal(true) }}>
                <div className="content"><i class="fas fa-plus" ></i><span className="label-text"><FormattedMessage id="AddNew" defaultMessage="Add New User" /></span></div>
              </div> : ""}
            <div className="content-wrapper">
              <section className="content ">
                <div className="content-wrapper">
                  <div className="row mg-t-20 ">
                    <div className="col-lg-12 d-flex align-items-center justify-content-between plr-0">
                      <h1 className="main-heading"><FormattedMessage id="AllUsers" defaultMessage="All Users" /></h1>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 plr-0">
                      <div className="table-user-wrapper">
                        <div className="d-flex input-project-wrapper mb-3">
                          <input className="input-search-field" onChange={(e) => onChangeTableFilter(e.target.value)} placeholder={intl.formatMessage({ id: "SearchforUser", defaultMessage: "Search for user..." })} />
                          <div className="search-icon" onClick={onChangeTableFilter}>
                            <img src="/images/icons/search-input-icon.svg" />
                          </div>
                        </div>

                        <table id="user-table" className="table table-bordered">
                          <thead>
                            <tr>
                              <td>Username</td>
                              <td>Email</td>
                              <td className='text-center'>Actions</td>
                            </tr>
                          </thead>
                          <tbody>
                            {seeUsers.map((arrItem, Index) => {
                              if (arrItem.username == "Marcel" || arrItem.username == "Lennart") {
                                return (
                                  <tr role="row" key={arrItem + Index}>
                                    <td className='table-username'>{arrItem.username}</td>
                                    <td>{arrItem.email}</td>
                                  </tr>
                                )
                              } else {
                                return (
                                  <tr role="row" key={arrItem + Index}>
                                    <td className='table-username'>{arrItem.username}</td>
                                    <td>{arrItem.email}</td>
                                    <td className='text-center'>
                                      <Link to={`/user/${arrItem._id}`}>
                                        <button className='btn cta-btn mr-2'>
                                          <i className="fas fa-pen"></i> <FormattedMessage id="Edit" defaultMessage="Edit" />
                                        </button>
                                      </Link>
                                      {Rights ?
                                        <button className='btn cta-btn' onClick={() => deletePopup(arrItem.username)}>
                                          <i className="fas fa-trash"></i> <FormattedMessage id="Delete" defaultMessage="Delete" />
                                        </button>
                                        : ""}
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </section>
              <div className="col-md-12 mg-t-10">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </>
    );

  }
  else {
    return (
      <section style={{ minHeight: "100vh" }} className="content-header">
        <div className="SpinnerCon container-fluid">
          <div className="spinner-border" role="status">
            <span className="sr-only"><FormattedMessage id="Loading" defaultMessage="Loading..." /></span>
          </div>
        </div>
      </section>
    )
  }
}
export default UserManagement;

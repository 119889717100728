import React from 'react';
import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Progress } from 'antd';


const ProjectPill = (props) => {

  const getPillClass = (score) => {
    if (score === 0) {
      return "text-full-dark";
    } else if (score < 50) {
      return "bg-pill-red";
    } else if (score < 50) {
      return "bg-pill-red";
    } else if (score < 85) {
      return "bg-pill-orange";
    } else if (score < 100) {
      return "bg-pill-green";
    } else if (score === 100) {
      return "bg-pill-dark-green";
    }
  };

  const getTextClass = (score) => {
    const viewportWidth = window.innerWidth;
    const dynamicScoreThreshold = viewportWidth < 768 ? 64 : 26;
    if(score === 0){
      return "text-full-dark"
    }else if(score < dynamicScoreThreshold){
      return "text-dark";
    }else{
      return "text-white";
    }
  }

  return (
    <div className={`pos-rel ${getPillClass(props.score)} ${getTextClass(props.score)}`}>
      <div className="wrapper-top">
        <span className="heading">
          <FormattedMessage
            id={props.translation.id}
            defaultMessage={props.translation.message}
          />
        </span>
        <p className="small-text mg-b-20">
          {props.completedTasks}/
          {props.totalTasks}{" "}
          <FormattedMessage
            id="Taskscompleted"
            defaultMessage="Tasks completed"
          />
        </p>
      </div>
      <Progress percent={props.score} size={[300, 200]} />
    </div>

  )
}


export default ProjectPill;
// Map of section keys in FinalArrayState to section names that are shown in the UI
export const sectionNames = {
  "GeneralAreaBody": "General Area Body ",
  "BodyToolsEquipment": "Body Tools Equipment ",
  "BodyPlasticRepair": "Body Plastic Repair ",
  "EVToolsEquipment": "EV Tools Equipment ",
  "BodyHealthSafety": "Body Health Safety ",
  "BodyConsumablesRoom": "Body Consumables Room ",
  "CompressorRoomEquipment": "Compressor Room Equipment ",
  "CoolerDryer": "Cooler Dryer ",
  "CompressedAirPiping": "Compressed Air Piping ",
  "Filtration": "Filtration ",
  "GeneralAreaOne": "General Area One ",
  "HealthSafety": "Health Safety ",
  "ConsumablesRoom": "Consumables Room ",
  "IREquipment": "IR Equipment ",
  "UVEquipment": "UV Equipment ",
  "FacilityEnviromentalSustainability": "Facility + Equipment - Environmental Sustainability ",
  "FacilityParkingExterior": "Facility + Equipment - Parking + Exterior ",
  "FacilityReceptionOffice": "Facility + Equipment - Reception + Office ",
  "FacilityPartsArea": "Facility + Equipment - Parts Area ",
  "FacilityBodyShop": "Facility + Equipment - Body Shop ",
  "FacilityPaintShop": "Facility + Equipment - Paint Shop ",
  "FacilityDetailingBay": "Facility + Equipment - Detailing Bay ",
  "FacilityWasteRecycling": "Facility + Equipment - Waste + Recycling ",
  "FacilityCompressedAir": "Facility + Equipment - Compressed Air ",
  "MechanicalBay": "Mechanical Work Bays",
  "RemoveRefitBay": "Remove + Refit Area",
  "GeneralRepairBay": "Light Body Repair Bays",
  "HeavyRepairBay": "Heavy Body Repair Bays",
  "AluminiumBay": "Aluminium Body Repair Bays",
  "PreparationBay": "Preparation Bays",
  "MaskingBay": "Masking Bays",
  "PolishingBay": "Polishing Bays",
  "MixingRoom": "Mixing Room",
  "SprayBooth": "Spray Booth",
  "ToolBoxPaint": "Repair Check",
  "Business Information": "Business Information",
  "HealthandSafety": "Health and Safety",
  "ManagementSnake": "Management",
  "Parts Receiving DepartmentSnake": "Parts Receiving Department",
  "ColorCheckingMatchingSnake": "ColorCheckingMatching",
  "Disassembly + Repair IdentificationSnake": "Disassembly + Repair Identification",
  "Light Dent RepairSnake": "Light Dent Repair",
  "Heavy Body Repair + Welded PanelsSnake": "Heavy Body Repair + Welded Panels",
  "Body Filler Aplication + SandingSnake": "Body Filler Aplication + Sanding",
  "Preparation for PrimerSnake": "Preparation for Primer",
  "Applying Primer FillerSnake": "Applying Primer Filler",
  "Curing Drying Frimer FillerSnake": "Curing Drying Frimer Filler",
  "Sanding Primer FillerSnake": "Sanding Primer Filler",
  "Masking for TopcatSnake": "Masking for Topcoat ",
  "Topcat AplicationSnake": "Topcoat Application",
  "Curing Drying TopcatsSnake": "Curing Drying Topcoats",
  "Denibbing + PolishingSnake": "Denibbing + Polishing",
  "ReassemblySnake": "Re-Assembly",
  "Detailing": "Detailing",
  "Vehicle HandoverSnake": "Vehicle Handover",
  "Invoicing+CustomerFollowUp": "Invoicing + Customer Follow Up",
  "TrainingFour": "Training Four",
  "TrainingTwo": "Body Repair",
  "TrainingThree": "Paint",
  "Repairs": "Repair Check",
}

// Paths used in showSingleProject.js for url matching
export const pathPatterns = [
  "/project/:projectId/milestones/:milestoneId",
  "/project/:projectId/notes/:noteId",
  "/project/:projectId/:section",
  "/project/:projectId/:section/tasks",
  "/project/:projectId/:section/tasks/:taskKey",
  "/project/:projectId/:section/:sectionKey/tasks",
  "/project/:projectId/:section/:sectionKey/tasks/:taskKey",
  "/project/:projectId/:section/:sectionKey/:subSection/:subSectionKey/tasks",
  "/project/:projectId/:section/:sectionKey/:subSection/:subSectionKey/tasks/:taskKey",
  "/project/:projectId/BodyShop/:section",
  "/project/:projectId/PaintShop/:section",
  "/project/:projectId/CompressedAir/:section",
  "/project/:projectId/:page/:section/tasks",
  "/project/:projectId/BodyShop/:section/tasks/:taskKey",
  "/project/:projectId/PaintShop/:section/tasks/:taskKey",
  "/project/:projectId/CompressedAir/:section/tasks/:taskKey",
  "/project/:projectId/:page/:section/:sectionKey/tasks",
  "/project/:projectId/:page/:section/:sectionKey/tasks/:taskKey",
];

// Paths used in projectTasklist.js for url matching
export const projectTasklistPathPatterns = [
  "/project/:projectId/:section/tasks/:taskKey",
  "/project/:projectId/:section/:sectionKey/tasks/:taskKey",
  "/project/:projectId/:section/:sectionKey/tasks/:milestoneKey/:arrKey",
  "/project/:projectId/:section/:sectionKey/:subSection/:subSectionKey/tasks/:taskKey",
  "/project/:projectId/:page/:section/tasks/:taskKey",
  "/project/:projectId/:page/:section/:sectionKey/tasks/:taskKey",
];

// Map of bay section keys to the keys of the arrays in which they are stored in FinalArrayState.
export const baySections = {
  "MechanicalBay": "MechanicalAndBodyRepairWorkBays",
  "RemoveRefitBay": "MechanicalAndBodyRepairWorkBays",
  "GeneralRepairBay": "MechanicalAndBodyRepairWorkBays",
  "HeavyRepairBay": "MechanicalAndBodyRepairWorkBays",
  "AluminiumBay": "MechanicalAndBodyRepairWorkBays",
  "PreparationBay": "PaintShopBaysCount",
  "MaskingBay": "PaintShopBaysCount",
  "PolishingBay": "PaintShopBaysCount",
  "MixingRoom": "MixingBay",
  "SprayBooth": "SprayBoothBay",
};

// Path segments that have to be excluded from the url task sections matching in showSingleProject.js
export const noTaskPathSegments = [
  "BodyShop",
  "PaintShop",
  "CompressedAir",
  "KPI",
  "milestones",
  "notes",
];
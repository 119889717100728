import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import LanguageSwitcher from '../language-switcher/language-switcher';
import Cookie from 'js-cookie';
import { getInitialLocale } from "../../language/language.utils"
import { useIntl, FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';
import { useLocation } from "react-router-dom";

const LoginScreen = ({ history, match, location }, props) => {
  const parameterString = history.location.search;
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [queryParameter, setQueryParameter] = useState(parameterString)
  useEffect(() => {
    if (location.state && location.state.data) {
      setMessage(location.state.data)
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
    if (localStorage.getItem("authToken")) {
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    const localSetting = Cookie.get('locale');
    if (!localSetting) {
      const lang = getInitialLocale();
      Cookie.set('locale', lang);
    }
  }, []);

  const loginHandler = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    if (email === "" && password === "") {
      toast.error(intl.formatMessage({ id: "SomefieldsMissing", defaultMessage: "Some fields Missing" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    } else if (validator.isEmail(email)) {
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      try {
        const { data } = await axios.post(
          "/api/auth/login",
          { email, password },
          config
        );
        localStorage.setItem("authToken", data.token);
        Cookie.set('localeUser', data.userid);
        localStorage.setItem("username", data.username);
        localStorage.setItem("rights", btoa(JSON.stringify(data.rights)));
        localStorage.setItem("role", btoa(JSON.stringify(data.role)));
        toast.success(intl.formatMessage({ id: "SuccessfulRedirecting", defaultMessage: "Successful! Redirecting" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
        setTimeout(() => {
          if (queryParameter !== "") {
            const path_new = queryParameter.substring(parameterString.indexOf('=') + 1)
            history.push(`${path_new}`);
          } else {
            if(data.rights.SeeProjects.length === 1 && data.rights.SeeProjects[data.rights.SeeProjects.length -1 ] !== 'all'){
              history.push(`/project/${data.rights.SeeProjects[data.rights.SeeProjects.length -1 ]}`)
            }else{
            history.push("/");
          }
          }
        }, 1500);

      } catch (error) {
        toast.error(intl.formatMessage({ id: "InvalidCredentials", defaultMessage: "Invalid Credentials" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
      }
    } else {
      toast.error(intl.formatMessage({ id: "PleasEnterValidEmailAddress", defaultMessage: "Please enter valid email address!" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
    setTimeout(() => {
      setDisableButton(false);
    }, 4100);
  };

  return (
    <>
      <ToastContainer toastClassName="top-zIndex" autoClose={3000} />
      <div id="take_screenshot_now" className="hold-transition login-page">
        <div className="fix-lanuage-switcher">
          <LanguageSwitcher side="left" />
        </div>
        <div class="brand-logo"><img src="/images/basf-logo.svg" alt="basf logo" class="img-fluid"/></div>


          <div className="text-pos-ab-center">
            <p className="text-white">powered by <b>Locke Consulting</b></p>
          </div>

        <div className="login-box">

        <div className="login-logo">
        <img src="/images/BOOST_Logo.png" className="img-fluid boost-logo" alt="gun"/>

          </div>
          <div className="card">
          <div className="dot dot-1"></div>
          <div className="dot dot-2"></div>
          <div className="dot dot-3"></div>
            <div className="card-body login-card-body">
              <form onSubmit={loginHandler}>
                <div className="input-group mb-3">
                  <input className="form-control" type="text" id="email"
                    placeholder={intl.formatMessage({ id: "Emailaddress", defaultMessage: "Email address" })}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    tabIndex={1}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group ">
                  <input className="form-control" type={passwordVisibility ? "text" : "password"} id="password"
                    placeholder={intl.formatMessage({ id: "Enterpassword", defaultMessage: "Enter password" })}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    tabIndex={2}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                      <span className={passwordVisibility ? "fa fa-eye-slash password-toggle" : "fa fa-eye password-toggle"} onClick={() => { setPasswordVisibility(prevpasswordVisibility => !prevpasswordVisibility) }}></span>
                    </div>
                  </div>
                </div>
                <div className="forget-link">
                  <Link to="/forgotpassword">
                    <FormattedMessage id="ForgotPassword?" defaultMessage="Forgot Password?" />
                  </Link>
                </div>
                <button id="submit" type="submit" className="btn cta-btn btn-login" disabled={disableButton}>
                  <FormattedMessage id="SignIn" defaultMessage="Sign In" />
                </button>
              </form>
            </div>
          </div>
          {message &&
            <div class="alert alert-success" role="alert">
              {message}
            </div>}
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
import React from "react";
import { useIntl, FormattedMessage } from 'react-intl';

const AddUserPopup = (props) => {
  const intl = useIntl();
  return (
    <div>
      {props.show && (
        <div id="popup-addnewUser" className="d-block fq-modal fq-modal-small">
          <div className="fq-modal-content">
            <div className="bg-blue fq-modal-header">
              <span onClick={props.onHide} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
              <h1><FormattedMessage id="Add New User" defaultMessage="Add New User" /></h1>
            </div>
            <div className="fq-modal-content-2 pd-20">
              <div className="add-user-wrapper col-md-6 mx-auto">
                <input required type="text" name='username' placeholder={intl.formatMessage({ id: "newuserusername", defaultMessage: "Enter Name*" })} onChange={(e) => props.setNewUsername(e.target.value)} value={props.newUsername} className='form-control' />
                <input required type="email" name='email' placeholder={intl.formatMessage({ id: "newuserpassword", defaultMessage: "Enter Email Address*" })} onChange={(e) => props.setNewEmail(e.target.value)} value={props.newEmail} className='form-control mg-t-20' />
                <input type="submit" value="Add User" className='btn btn-primary bg-blue d-block w-100 mt-3' onClick={props.adduser} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddUserPopup
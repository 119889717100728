import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const AppUpload = ({ onRemove, beforeUpload, fileList }) => {
  const uploadProps = {
    onRemove: onRemove,
    beforeUpload: beforeUpload,
    fileList: fileList,
    // defaultFileList: [...fileList],
  };

  return (
    <>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Add a file</Button>
      </Upload>
    </>
  );
};

export default AppUpload;

function Spinner() {

    return (
        <section style={{ minHeight: "100vh" }} className="content-header">
            <div style={{ height: "100vh", display: "flex" }} className="container-fluid">
                <div style={{ margin: "auto" }} className="loader"></div>
            </div>
        </section>
    );
}

export default Spinner
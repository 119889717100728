import Progressbar from './progressbar'
import Owldemo4 from './reactcarousel4'
import openTaskList from '../sharedFunctions.js/openTaskList';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from "react-router-dom";
import checkPercentage from '../sharedFunctions.js/checkpercentage'

const ShowTasksCardPicture = (props) => {
    let history = useHistory();
    const location = useLocation();

    if (props.FinalArray.total !== 0) {
        return (
            <div className="min-h img-text-block d-flex">
                <Owldemo4 noTeamwork={props.noTeamwork} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} Pictures={props.FinalArray.pictures} />
                <Progressbar width={props.FinalArrayResult} />
                <div className="content">
                    <div class="sameheighttitle">
                        {props.FormattedMessage}
                    </div>
                    <p className="mg-b-0 small-text">{props.FinalArray.complete}/{props.FinalArray.total} <FormattedMessage id="Taskscompleted" defaultMessage="Tasks completed" /></p>
                    <div className="stats">{checkPercentage(props.FinalArrayResult)}%</div>
                    <div className="d-flex align-items-center mg-t-20">
                        {props.openSiteName ?
                        <a style={{ cursor: "pointer", color: "white" }} onClick={() => props.openSite(props.openSiteName)} className="cta-button mg-r-10">Details</a>
                        :
                        <div 
                            style={{ padding: "8px 0px", margin: "0" }} 
                            onClick={
                                () => {
                                    openTaskList(props.FinalArray["Tasks"], props.setScrollPos, props.passTaskListComp, props.passResult, props.FinalArrayResult, props.FinalArray, props.Name, props.section);
                                    history.push(`${location.pathname}/${props.section}/tasks`, { from: "collapsible" });
                                }
                            } 
                            className="cta-link fq-popup" 
                            data-popup-id="popup-1"
                        >
                            <FormattedMessage id="ShowTasks" defaultMessage="Show Tasks" />
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{position: "relative"}} className="min-h img-text-block d-flex">
            <div>
                <div className='bg-not-available'>
                    <div class="centered-text-overlay-bay"><FormattedMessage id="NotAvailable" defaultMessage="Not Available" /></div>
                </div>

                <div style={{pointerEvents: "none"}}>
                    <Owldemo4 noTeamwork={props.noTeamwork}  setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} Pictures={props.FinalArray.pictures} />
                </div>
                <Progressbar width={props.FinalArrayResult} />
                <div className="content">
                    <div class="sameheighttitle">
                        {props.FormattedMessage}
                    </div>
                    <p className="mg-b-0 small-text">{props.FinalArray.complete}/{props.FinalArray.total} <FormattedMessage id="Taskscompleted" defaultMessage="Tasks completed" /></p>
                    <div className="stats">{checkPercentage(props.FinalArrayResult)}%</div>
                    <div className="d-flex mg-t-20">
                        <div style={{ padding: "8px 0px", marginBottom: "20px" }} class="" data-popup-id="popup-1"><FormattedMessage id="ShowTasks" defaultMessage="Show Tasks" /></div>
                    </div>
                </div>
            </div>
            </div>
          
        )
    }

}
export default ShowTasksCardPicture

import React from 'react';
 
import { Link, Redirect, withRouter } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import Projects from '../projects/projects';
import { FormattedMessage } from 'react-intl';

const SingleGroup = (props) => {
    const idd = props.match.params.id
    const [onload, setonload] = useState(false)
    const [error, setError] = useState("");
    const [Loading, setLoading] = useState(true);
const [groupName, setGroupName] = useState(false);
    const [SeeProjects, setSeeProjects] = useState([]);
     const [EditProjects, setEditProjects] = useState([]);
    const [Saved, SetSaved] = useState(true);
   const [projects, setProjects] = useState([]);
   const [Final, SetFinal] = useState(false);
   const [Rights, SetRights] = useState(false);

   useEffect(() => {
    
    if (Final && Saved) {
      SetSaved(prevCheck => !prevCheck)
     }
  }, [EditProjects]);
   
  

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

 


    useEffect(() => {
        const fetch = async () => {

          try {
            const users =  axios.get(`/api/private/groups/${idd}`, config);
            const projects =  axios.get(`/api/private/dbprojects`, config);
          const  [usersDB,projectsDB] = await Promise.all([users,projects])
          console.log(usersDB.data.elements)
          console.log(projectsDB.data)

         
          setProjects(projectsDB.data.database)

          if(usersDB.data.rights) {
            setGroupName(usersDB.data.elements.groupName)
            SetRights(true)
          let seeProjectsarr = []
          let editProjectsarr = []
                    usersDB.data.elements.SeeProjects.forEach(item=> {
                      let obj = {}
                      obj.value = item
                      if(item == "all") {
                        obj.label = "All Projects"
                        seeProjectsarr.push(obj)
                      } else {
                        obj.label = projectsDB.data.database.find(o=> o.projectID == item).projectName
                        seeProjectsarr.push(obj)
                      }
          
          
                     
                    })
          
                    usersDB.data.elements.EditProjects.forEach(item=> {
                      let obj = {}
                      obj.value = item
                     
                      if(item == "all") {
                        obj.label = "All Projects"
                        editProjectsarr.push(obj)
                      } else {
                      obj.label = projectsDB.data.database.find(o=> o.projectID == item).projectName
                      editProjectsarr.push(obj)
                      }
                    })
          
                    setSeeProjects(seeProjectsarr)
                    setEditProjects(editProjectsarr)
          
          
          
          
                   
          
          let ProjectsArr = []
          ProjectsArr.push({label: "All Projects", value : "all"})
                    projectsDB.data.database.forEach(ele => {
          
          let obj = {}
          obj.value = ele.projectID
          obj.label = ele.projectName
          ProjectsArr.push(obj)
                    });
          
                    setProjects(ProjectsArr)

          } 
         
         
          

          




        
          }  catch(e) {
         
             if(!props.dev) {
              localStorage.removeItem("authToken");
          window.location.href = '/logout';   
            
      }}
     
      }

 fetch().then(
  setTimeout(() => {
    setonload(true)
  }, 1000),
  //SetSaved(true),
  setLoading(false)
 );
 
    }, []);

   

    useEffect(() => {
      if (Saved && onload) {
        SetSaved(false)
       }
    }, [SeeProjects,EditProjects])




      const save = () => {


        let obj = []
          SeeProjects.forEach(item=>{
            obj.push(item.value)
          })

          SetFinal(obj)
          let obj2 = []
          EditProjects.forEach(item=> {
            obj2.push(item.value)
          })

        const Body = {
          data : {
      username : groupName,
          SeeProjects: obj,
            EditProjects: obj2,
          }
        }

   
        const fetch = async () => {
          try {

          
          const DBGet =  await axios.put(`/api/private/groups/${idd}`,Body, config);
          console.log(DBGet.data)
        } catch(e) {
           if(!props.dev) {
            localStorage.removeItem("authToken");
        window.location.href = '/logout';   }
        }
        }
        fetch().then(
          SetSaved(true)
        ) 
        };

        

         

    if(!Loading  && Rights) {
        return (

          

<div className="content-wrapper">

{/* Content Header (Page header) */}
<section className="content-header">
<div id="container" className="container-fluid">
  <div className="row mb-2">
    <div className="col-sm-6">
{/* <button onClick={() => writeDatabase()}>Update</button> */}  
    </div>
    <div className="col-sm-6">
      <ol className="breadcrumb float-sm-right">
        <li className="breadcrumb-item"><a href="#"><FormattedMessage id="Home" defaultMessage="Home" /></a></li>
        <li className="breadcrumb-item active"><FormattedMessage id="Group" defaultMessage="Group" /></li>
      </ol>
    </div>
  </div>
</div>{/* /.container-fluid */}
</section>
{/* Main content */}
<section className="content">
<div className="container-fluid">
<div style={{paddingRight: "7.5px", paddingLeft: "7.5px", justifyContent: "flex-end"}} className="row mb-2">
{/* <button style={{Right: "0",  fontSize: "1.3em"}} className='mb-2 btn btn-primary btn-primary-blue no-print'
        onClick={()=> addNew()}><i  style={{paddingRight:"10px"}} className="fas fa-file"></i> Add new</button> */}
</div>

  
  <div className="row">
    <div className="col-12">

    <div className="card">
      <div style={{display:"flex"}} className="card-body table-responsive pad">
  <div style={{display:"flex"}} className="col-2">       
    <h3  style={{alignSelf:"center"}} className="card-title">{groupName}</h3>
    </div>
    
              
        </div>
      </div>

     

      {
      <>
      
      

    

      <div className="card">
      <div style={{display:"flex", overflowX: "unset"}} className="card-body table-responsive pad">
  <div style={{display:"flex"}} className="col-2">       
    <h3  style={{alignSelf:"center"}} className="card-title"><FormattedMessage id="SeeProjects" defaultMessage="See Projects" /></h3>
    
    </div>
    <div className="col-7">
  
     
      <MultiSelect
     
      
        options={projects}
        value={SeeProjects}
        hasSelectAll={false}
        className={"MultiSelectClass"}
        onChange={setSeeProjects}
        labelledBy="Select"

      />
    </div>

</div>           
    
      </div>


   
    
      <div className="card">
      <div style={{display:"flex", overflowX: "unset"}} className="card-body table-responsive pad">
  <div style={{display:"flex"}} className="col-2">       
    <h3  style={{alignSelf:"center"}} className="card-title"><FormattedMessage id="EditProjects" defaultMessage="Edit Projects" /></h3>
    
    </div>
    <div className="col-7">
  
     
      <MultiSelect
     
      
        options={projects}
        value={EditProjects}
        hasSelectAll={false}
        className={"MultiSelectClass"}
        onChange={setEditProjects}
        labelledBy="Select"
      />
    </div>

</div>           
    
      </div>
      





      <div style={{paddingRight: "7.5px", paddingLeft: "7.5px", justifyContent: "flex-end"}} className="row mb-2">
         
            <button style={{Right: "0",  fontSize: "1.3em"}} 
            className={Saved ? 'btn btn-secondary no-print m-2' : 'btn btn-primary btn-primary-blue no-print m-2'}
            onClick={()=> save()}><i  className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
</div>

      
      </>
      }
        
        


     
    </div>
    {/* /.col */}
  </div>
  {/* /.row */}
</div>
{/* /.container-fluid */}
</section>
</div>

);
}
else {
return (
 
  <section style={{minHeight: "100vh"}} className="content-header">
    <div className="SpinnerCon container-fluid">
<div className="spinner-border" role="status">
<span className="sr-only"><FormattedMessage id="Loading" defaultMessage="Loading..." /></span>
</div>
</div>
</section>

)
    
  }}
  export default withRouter(SingleGroup)
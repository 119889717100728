import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DefaultCurrency from './DefaultCurrency'
import { useIntl, FormattedMessage } from "react-intl";
import { Table, Button, Modal, Input } from 'antd';
import { toast } from "react-toastify";
import AddCurrencyPopup from "./AddCurrencyPopup"
const { Column } = Table;

const Currencies = (props) => {
   const intl = useIntl();
   const [editingCurrency, setEditingCurrency] = useState(null);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [editedCurrencyName, setEditedCurrencyName] = useState('');
   const [editedCurrencyCode, setEditedCurrencyCode] = useState('');
   const [editedCurrencySymbol, setEditedCurrencySymbol] = useState('');
   const localesList = [
      { code: "AED", name: "United Arab Emirates Dirham" },
      { code: "AFN", name: "Afghan Afghani" },
      { code: "ALL", name: "Albanian Lek" },
      { code: "AMD", name: "Armenian Dram" },
      { code: "ANG", name: "Netherlands Antillean Guilder" },
      { code: "AOA", name: "Angolan Kwanza" },
      { code: "ARS", name: "Argentine Peso" },
      { code: "AUD", name: "Australian Dollar" },
      { code: "AWG", name: "Aruban Florin" },
      { code: "AZN", name: "Azerbaijani Manat" },
      { code: "BAM", name: "Bosnia-Herzegovina Convertible Mark" },
      { code: "BBD", name: "Barbadian Dollar" },
      { code: "BDT", name: "Bangladeshi Taka" },
      { code: "BGN", name: "Bulgarian Lev" },
      { code: "BHD", name: "Bahraini Dinar" },
      { code: "BIF", name: "Burundian Franc" },
      { code: "BMD", name: "Bermudan Dollar" },
      { code: "BND", name: "Brunei Dollar" },
      { code: "BOB", name: "Bolivian Boliviano" },
      { code: "BRL", name: "Brazilian Real" },
      { code: "BSD", name: "Bahamian Dollar" },
      { code: "BTC", name: "Bitcoin" },
      { code: "BTN", name: "Bhutanese Ngultrum" },
      { code: "BWP", name: "Botswanan Pula" },
      { code: "BYN", name: "New Belarusian Ruble" },
      { code: "BYR", name: "Belarusian Ruble" },
      { code: "BZD", name: "Belize Dollar" },
      { code: "CAD", name: "Canadian Dollar" },
      { code: "CDF", name: "Congolese Franc" },
      { code: "CHF", name: "Swiss Franc" },
      { code: "CLF", name: "Chilean Unit of Account (UF)" },
      { code: "CLP", name: "Chilean Peso" },
      { code: "CNY", name: "Chinese Yuan" },
      { code: "COP", name: "Colombian Peso" },
      { code: "CRC", name: "Costa Rican Col\u00f3n" },
      { code: "CUC", name: "Cuban Convertible Peso" },
      { code: "CUP", name: "Cuban Peso" },
      { code: "CVE", name: "Cape Verdean Escudo" },
      { code: "CZK", name: "Czech Republic Koruna" },
      { code: "DJF", name: "Djiboutian Franc" },
      { code: "DKK", name: "Danish Krone" },
      { code: "DOP", name: "Dominican Peso" },
      { code: "DZD", name: "Algerian Dinar" },
      { code: "EGP", name: "Egyptian Pound" },
      { code: "ERN", name: "Eritrean Nakfa" },
      { code: "ETB", name: "Ethiopian Birr" },
      { code: "EUR", name: "Euro" },
      { code: "FJD", name: "Fijian Dollar" },
      { code: "FKP", name: "Falkland Islands Pound" },
      { code: "GBP", name: "British Pound Sterling" },
      { code: "GEL", name: "Georgian Lari" },
      { code: "GGP", name: "Guernsey Pound" },
      { code: "GHS", name: "Ghanaian Cedi" },
      { code: "GIP", name: "Gibraltar Pound" },
      { code: "GMD", name: "Gambian Dalasi" },
      { code: "GNF", name: "Guinean Franc" },
      { code: "GTQ", name: "Guatemalan Quetzal" },
      { code: "GYD", name: "Guyanaese Dollar" },
      { code: "HKD", name: "Hong Kong Dollar" },
      { code: "HNL", name: "Honduran Lempira" },
      { code: "HRK", name: "Croatian Kuna" },
      { code: "HTG", name: "Haitian Gourde" },
      { code: "HUF", name: "Hungarian Forint" },
      { code: "IDR", name: "Indonesian Rupiah" },
      { code: "ILS", name: "Israeli New Sheqel" },
      { code: "IMP", name: "Manx pound" },
      { code: "INR", name: "Indian Rupee" },
      { code: "IQD", name: "Iraqi Dinar" },
      { code: "IRR", name: "Iranian Rial" },
      { code: "ISK", name: "Icelandic Kr\u00f3na" },
      { code: "JEP", name: "Jersey Pound" },
      { code: "JMD", name: "Jamaican Dollar" },
      { code: "JOD", name: "Jordanian Dinar" },
      { code: "JPY", name: "Japanese Yen" },
      { code: "KES", name: "Kenyan Shilling" },
      { code: "KGS", name: "Kyrgystani Som" },
      { code: "KHR", name: "Cambodian Riel" },
      { code: "KMF", name: "Comorian Franc" },
      { code: "KPW", name: "North Korean Won" },
      { code: "KRW", name: "South Korean Won" },
      { code: "KWD", name: "Kuwaiti Dinar" },
      { code: "KYD", name: "Cayman Islands Dollar" },
      { code: "KZT", name: "Kazakhstani Tenge" },
      { code: "LAK", name: "Laotian Kip" },
      { code: "LBP", name: "Lebanese Pound" },
      { code: "LKR", name: "Sri Lankan Rupee" },
      { code: "LRD", name: "Liberian Dollar" },
      { code: "LSL", name: "Lesotho Loti" },
      { code: "LTL", name: "Lithuanian Litas" },
      { code: "LVL", name: "Latvian Lats" },
      { code: "LYD", name: "Libyan Dinar" },
      { code: "MAD", name: "Moroccan Dirham" },
      { code: "MDL", name: "Moldovan Leu" },
      { code: "MGA", name: "Malagasy Ariary" },
      { code: "MKD", name: "Macedonian Denar" },
      { code: "MMK", name: "Myanma Kyat" },
      { code: "MNT", name: "Mongolian Tugrik" },
      { code: "MOP", name: "Macanese Pataca" },
      { code: "MRO", name: "Mauritanian Ouguiya" },
      { code: "MUR", name: "Mauritian Rupee" },
      { code: "MVR", name: "Maldivian Rufiyaa" },
      { code: "MWK", name: "Malawian Kwacha" },
      { code: "MXN", name: "Mexican Peso" },
      { code: "MYR", name: "Malaysian Ringgit" },
      { code: "MZN", name: "Mozambican Metical" },
      { code: "NAD", name: "Namibian Dollar" },
      { code: "NGN", name: "Nigerian Naira" },
      { code: "NIO", name: "Nicaraguan C\u00f3rdoba" },
      { code: "NOK", name: "Norwegian Krone" },
      { code: "NPR", name: "Nepalese Rupee" },
      { code: "NZD", name: "New Zealand Dollar" },
      { code: "OMR", name: "Omani Rial" },
      { code: "PAB", name: "Panamanian Balboa" },
      { code: "PEN", name: "Peruvian Nuevo Sol" },
      { code: "PGK", name: "Papua New Guinean Kina" },
      { code: "PHP", name: "Philippine Peso" },
      { code: "PKR", name: "Pakistani Rupee" },
      { code: "PLN", name: "Polish Zloty" },
      { code: "PYG", name: "Paraguayan Guarani" },
      { code: "QAR", name: "Qatari Rial" },
      { code: "RON", name: "Romanian Leu" },
      { code: "RSD", name: "Serbian Dinar" },
      { code: "RUB", name: "Russian Ruble" },
      { code: "RWF", name: "Rwandan Franc" },
      { code: "SAR", name: "Saudi Riyal" },
      { code: "SBD", name: "Solomon Islands Dollar" },
      { code: "SCR", name: "Seychellois Rupee" },
      { code: "SDG", name: "Sudanese Pound" },
      { code: "SEK", name: "Swedish Krona" },
      { code: "SGD", name: "Singapore Dollar" },
      { code: "SHP", name: "Saint Helena Pound" },
      { code: "SLE", name: "Sierra Leonean Leone" },
      { code: "SLL", name: "Sierra Leonean Leone" },
      { code: "SOS", name: "Somali Shilling" },
      { code: "SRD", name: "Surinamese Dollar" },
      { code: "STD", name: "S\u00e3o Tom\u00e9 and Pr\u00edncipe Dobra" },
      { code: "SVC", name: "Salvadoran Col\u00f3n" },
      { code: "SYP", name: "Syrian Pound" },
      { code: "SZL", name: "Swazi Lilangeni" },
      { code: "THB", name: "Thai Baht" },
      { code: "TJS", name: "Tajikistani Somoni" },
      { code: "TMT", name: "Turkmenistani Manat" },
      { code: "TND", name: "Tunisian Dinar" },
      { code: "TOP", name: "Tongan Pa\u02bbanga" },
      { code: "TRY", name: "Turkish Lira" },
      { code: "TTD", name: "Trinidad and Tobago Dollar" },
      { code: "TWD", name: "New Taiwan Dollar" },
      { code: "TZS", name: "Tanzanian Shilling" },
      { code: "UAH", name: "Ukrainian Hryvnia" },
      { code: "UGX", name: "Ugandan Shilling" },
      { code: "USD", name: "United States Dollar" },
      { code: "UYU", name: "Uruguayan Peso" },
      { code: "UZS", name: "Uzbekistan Som" },
      { code: "VEF", name: "Venezuelan Bol\u00edvar Fuerte" },
      { code: "VES", name: "Sovereign Bolivar" },
      { code: "VND", name: "Vietnamese Dong" },
      { code: "VUV", name: "Vanuatu Vatu" },
      { code: "WST", name: "Samoan Tala" },
      { code: "XAF", name: "CFA Franc BEAC" },
      { code: "XAG", name: "Silver (troy ounce)" },
      { code: "XAU", name: "Gold (troy ounce)" },
      { code: "XCD", name: "East Caribbean Dollar" },
      { code: "XDR", name: "Special Drawing Rights" },
      { code: "XOF", name: "CFA Franc BCEAO" },
      { code: "XPF", name: "CFP Franc" },
      { code: "YER", name: "Yemeni Rial" },
      { code: "ZAR", name: "South African Rand" },
      { code: "ZMK", name: "Zambian Kwacha (pre-2013)" },
      { code: "ZMW", name: "Zambian Kwacha" },
      { code: "ZWL", name: "Zimbabwean Dollar" }
   ];

   const currencyList = [
      {
         "symbol": "AED",
         "name_plural": "UAE dirhams",
         "code": "AED",
         "symbol_native": "د.إ.\u200F",
         "decimal_digits": 2,
         "name": "United Arab Emirates Dirham",
         "rounding": 0.0
      },
      {
         "symbol": "AFN",
         "name_plural": "Afghan Afghanis",
         "code": "AFN",
         "symbol_native": "؋",
         "decimal_digits": 0,
         "name": "Afghan Afghani",
         "rounding": 0.0
      },
      {
         "symbol": "ALL",
         "name_plural": "Albanian lekë",
         "code": "ALL",
         "symbol_native": "Lekë",
         "decimal_digits": 0,
         "name": "Albanian Lek",
         "rounding": 0.0
      },
      {
         "symbol": "AMD",
         "name_plural": "Armenian drams",
         "code": "AMD",
         "symbol_native": "֏",
         "decimal_digits": 0,
         "name": "Armenian Dram",
         "rounding": 0.0
      },
      {
         "symbol": "ANG",
         "name_plural": "Netherlands Antillean guilders",
         "code": "ANG",
         "symbol_native": "NAf.",
         "decimal_digits": 2,
         "name": "Netherlands Antillean Guilder",
         "rounding": 0.0
      },
      {
         "symbol": "AOA",
         "name_plural": "Angolan kwanzas",
         "code": "AOA",
         "symbol_native": "Kz",
         "decimal_digits": 2,
         "name": "Angolan Kwanza",
         "rounding": 0.0
      },
      {
         "symbol": "ARS",
         "name_plural": "Argentine pesos",
         "code": "ARS",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Argentine Peso",
         "rounding": 0.0
      },
      {
         "symbol": "A$",
         "name_plural": "Australian dollars",
         "code": "AUD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Australian Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "AWG",
         "name_plural": "Aruban florin",
         "code": "AWG",
         "symbol_native": "Afl.",
         "decimal_digits": 2,
         "name": "Aruban Florin",
         "rounding": 0.0
      },
      {
         "symbol": "AZN",
         "name_plural": "Azerbaijani manats",
         "code": "AZN",
         "symbol_native": "\u20BC",
         "decimal_digits": 2,
         "name": "Azerbaijani Manat",
         "rounding": 0.0
      },
      {
         "symbol": "BAM",
         "name_plural": "Bosnia-Herzegovina convertible marks",
         "code": "BAM",
         "symbol_native": "КМ",
         "decimal_digits": 2,
         "name": "Bosnia-Herzegovina Convertible Mark",
         "rounding": 0.0
      },
      {
         "symbol": "BBD",
         "name_plural": "Barbadian dollars",
         "code": "BBD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Barbadian Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "BDT",
         "name_plural": "Bangladeshi takas",
         "code": "BDT",
         "symbol_native": "৳",
         "decimal_digits": 2,
         "name": "Bangladeshi Taka",
         "rounding": 0.0
      },
      {
         "symbol": "BGN",
         "name_plural": "Bulgarian leva",
         "code": "BGN",
         "symbol_native": "лв.",
         "decimal_digits": 2,
         "name": "Bulgarian Lev",
         "rounding": 0.0
      },
      {
         "symbol": "BHD",
         "name_plural": "Bahraini dinars",
         "code": "BHD",
         "symbol_native": "د.ب.\u200F",
         "decimal_digits": 3,
         "name": "Bahraini Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "BIF",
         "name_plural": "Burundian francs",
         "code": "BIF",
         "symbol_native": "FBu",
         "decimal_digits": 0,
         "name": "Burundian Franc",
         "rounding": 0.0
      },
      {
         "symbol": "BMD",
         "name_plural": "Bermudan dollars",
         "code": "BMD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Bermudan Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "BND",
         "name_plural": "Brunei dollars",
         "code": "BND",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Brunei Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "BOB",
         "name_plural": "Bolivian bolivianos",
         "code": "BOB",
         "symbol_native": "Bs",
         "decimal_digits": 2,
         "name": "Bolivian Boliviano",
         "rounding": 0.0
      },
      {
         "symbol": "BOV",
         "name_plural": "Bolivian mvdols",
         "code": "BOV",
         "symbol_native": "BOV",
         "decimal_digits": 2,
         "name": "Bolivian Mvdol",
         "rounding": 0.0
      },
      {
         "symbol": "R$",
         "name_plural": "Brazilian reals",
         "code": "BRL",
         "symbol_native": "R$",
         "decimal_digits": 2,
         "name": "Brazilian Real",
         "rounding": 0.0
      },
      {
         "symbol": "BSD",
         "name_plural": "Bahamian dollars",
         "code": "BSD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Bahamian Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "BTN",
         "name_plural": "Bhutanese ngultrums",
         "code": "BTN",
         "symbol_native": "Nu.",
         "decimal_digits": 2,
         "name": "Bhutanese Ngultrum",
         "rounding": 0.0
      },
      {
         "symbol": "BWP",
         "name_plural": "Botswanan pulas",
         "code": "BWP",
         "symbol_native": "P",
         "decimal_digits": 2,
         "name": "Botswanan Pula",
         "rounding": 0.0
      },
      {
         "symbol": "BYN",
         "name_plural": "Belarusian rubles",
         "code": "BYN",
         "symbol_native": "Br",
         "decimal_digits": 2,
         "name": "Belarusian Ruble",
         "rounding": 0.0
      },
      {
         "symbol": "BZD",
         "name_plural": "Belize dollars",
         "code": "BZD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Belize Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "CA$",
         "name_plural": "Canadian dollars",
         "code": "CAD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Canadian Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "CDF",
         "name_plural": "Congolese francs",
         "code": "CDF",
         "symbol_native": "FC",
         "decimal_digits": 2,
         "name": "Congolese Franc",
         "rounding": 0.0
      },
      {
         "symbol": "CHE",
         "name_plural": "WIR euros",
         "code": "CHE",
         "symbol_native": "CHE",
         "decimal_digits": 2,
         "name": "WIR Euro",
         "rounding": 0.0
      },
      {
         "symbol": "CHF",
         "name_plural": "Swiss francs",
         "code": "CHF",
         "symbol_native": "CHF",
         "decimal_digits": 2,
         "name": "Swiss Franc",
         "rounding": 0.0
      },
      {
         "symbol": "CHW",
         "name_plural": "WIR francs",
         "code": "CHW",
         "symbol_native": "CHW",
         "decimal_digits": 2,
         "name": "WIR Franc",
         "rounding": 0.0
      },
      {
         "symbol": "CLF",
         "name_plural": "Chilean units of account (UF)",
         "code": "CLF",
         "symbol_native": "CLF",
         "decimal_digits": 4,
         "name": "Chilean Unit of Account (UF)",
         "rounding": 0.0
      },
      {
         "symbol": "CLP",
         "name_plural": "Chilean pesos",
         "code": "CLP",
         "symbol_native": "$",
         "decimal_digits": 0,
         "name": "Chilean Peso",
         "rounding": 0.0
      },
      {
         "symbol": "CNH",
         "name_plural": "Chinese yuan (offshore)",
         "code": "CNH",
         "symbol_native": "CNH",
         "decimal_digits": 2,
         "name": "Chinese Yuan (offshore)",
         "rounding": 0.0
      },
      {
         "symbol": "CN¥",
         "name_plural": "Chinese yuan",
         "code": "CNY",
         "symbol_native": "¥",
         "decimal_digits": 2,
         "name": "Chinese Yuan",
         "rounding": 0.0
      },
      {
         "symbol": "COP",
         "name_plural": "Colombian pesos",
         "code": "COP",
         "symbol_native": "$",
         "decimal_digits": 0,
         "name": "Colombian Peso",
         "rounding": 0.0
      },
      {
         "symbol": "COU",
         "name_plural": "Colombian real value units",
         "code": "COU",
         "symbol_native": "COU",
         "decimal_digits": 2,
         "name": "Colombian Real Value Unit",
         "rounding": 0.0
      },
      {
         "symbol": "CRC",
         "name_plural": "Costa Rican colóns",
         "code": "CRC",
         "symbol_native": "\u20A1",
         "decimal_digits": 2,
         "name": "Costa Rican Colón",
         "rounding": 0.0
      },
      {
         "symbol": "CUC",
         "name_plural": "Cuban convertible pesos",
         "code": "CUC",
         "symbol_native": "CUC",
         "decimal_digits": 2,
         "name": "Cuban Convertible Peso",
         "rounding": 0.0
      },
      {
         "symbol": "CUP",
         "name_plural": "Cuban pesos",
         "code": "CUP",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Cuban Peso",
         "rounding": 0.0
      },
      {
         "symbol": "CVE",
         "name_plural": "Cape Verdean escudos",
         "code": "CVE",
         "symbol_native": "\u200B",
         "decimal_digits": 2,
         "name": "Cape Verdean Escudo",
         "rounding": 0.0
      },
      {
         "symbol": "CZK",
         "name_plural": "Czech korunas",
         "code": "CZK",
         "symbol_native": "Kč",
         "decimal_digits": 2,
         "name": "Czech Koruna",
         "rounding": 0.0
      },
      {
         "symbol": "DJF",
         "name_plural": "Djiboutian francs",
         "code": "DJF",
         "symbol_native": "Fdj",
         "decimal_digits": 0,
         "name": "Djiboutian Franc",
         "rounding": 0.0
      },
      {
         "symbol": "DKK",
         "name_plural": "Danish kroner",
         "code": "DKK",
         "symbol_native": "kr.",
         "decimal_digits": 2,
         "name": "Danish Krone",
         "rounding": 0.0
      },
      {
         "symbol": "DOP",
         "name_plural": "Dominican pesos",
         "code": "DOP",
         "symbol_native": "RD$",
         "decimal_digits": 2,
         "name": "Dominican Peso",
         "rounding": 0.0
      },
      {
         "symbol": "DZD",
         "name_plural": "Algerian dinars",
         "code": "DZD",
         "symbol_native": "د.ج.\u200F",
         "decimal_digits": 2,
         "name": "Algerian Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "EGP",
         "name_plural": "Egyptian pounds",
         "code": "EGP",
         "symbol_native": "ج.م.\u200F",
         "decimal_digits": 2,
         "name": "Egyptian Pound",
         "rounding": 0.0
      },
      {
         "symbol": "ERN",
         "name_plural": "Eritrean nakfas",
         "code": "ERN",
         "symbol_native": "Nfk",
         "decimal_digits": 2,
         "name": "Eritrean Nakfa",
         "rounding": 0.0
      },
      {
         "symbol": "ETB",
         "name_plural": "Ethiopian birrs",
         "code": "ETB",
         "symbol_native": "ብር",
         "decimal_digits": 2,
         "name": "Ethiopian Birr",
         "rounding": 0.0
      },
      {
         "symbol": "\u20AC",
         "name_plural": "euros",
         "code": "EUR",
         "symbol_native": "\u20AC",
         "decimal_digits": 2,
         "name": "Euro",
         "rounding": 0.0
      },
      {
         "symbol": "FJD",
         "name_plural": "Fijian dollars",
         "code": "FJD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Fijian Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "FKP",
         "name_plural": "Falkland Islands pounds",
         "code": "FKP",
         "symbol_native": "£",
         "decimal_digits": 2,
         "name": "Falkland Islands Pound",
         "rounding": 0.0
      },
      {
         "symbol": "£",
         "name_plural": "British pounds",
         "code": "GBP",
         "symbol_native": "£",
         "decimal_digits": 2,
         "name": "British Pound",
         "rounding": 0.0
      },
      {
         "symbol": "GEL",
         "name_plural": "Georgian laris",
         "code": "GEL",
         "symbol_native": "\u20BE",
         "decimal_digits": 2,
         "name": "Georgian Lari",
         "rounding": 0.0
      },
      {
         "symbol": "GHS",
         "name_plural": "Ghanaian cedis",
         "code": "GHS",
         "symbol_native": "GH\u20B5",
         "decimal_digits": 2,
         "name": "Ghanaian Cedi",
         "rounding": 0.0
      },
      {
         "symbol": "GIP",
         "name_plural": "Gibraltar pounds",
         "code": "GIP",
         "symbol_native": "£",
         "decimal_digits": 2,
         "name": "Gibraltar Pound",
         "rounding": 0.0
      },
      {
         "symbol": "GMD",
         "name_plural": "Gambian dalasis",
         "code": "GMD",
         "symbol_native": "D",
         "decimal_digits": 2,
         "name": "Gambian Dalasi",
         "rounding": 0.0
      },
      {
         "symbol": "GNF",
         "name_plural": "Guinean francs",
         "code": "GNF",
         "symbol_native": "FG",
         "decimal_digits": 0,
         "name": "Guinean Franc",
         "rounding": 0.0
      },
      {
         "symbol": "GTQ",
         "name_plural": "Guatemalan quetzals",
         "code": "GTQ",
         "symbol_native": "Q",
         "decimal_digits": 2,
         "name": "Guatemalan Quetzal",
         "rounding": 0.0
      },
      {
         "symbol": "GYD",
         "name_plural": "Guyanaese dollars",
         "code": "GYD",
         "symbol_native": "$",
         "decimal_digits": 0,
         "name": "Guyanaese Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "HK$",
         "name_plural": "Hong Kong dollars",
         "code": "HKD",
         "symbol_native": "HK$",
         "decimal_digits": 2,
         "name": "Hong Kong Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "HNL",
         "name_plural": "Honduran lempiras",
         "code": "HNL",
         "symbol_native": "L",
         "decimal_digits": 2,
         "name": "Honduran Lempira",
         "rounding": 0.0
      },
      {
         "symbol": "HRK",
         "name_plural": "Croatian kunas",
         "code": "HRK",
         "symbol_native": "HRK",
         "decimal_digits": 2,
         "name": "Croatian Kuna",
         "rounding": 0.0
      },
      {
         "symbol": "HTG",
         "name_plural": "Haitian gourdes",
         "code": "HTG",
         "symbol_native": "G",
         "decimal_digits": 2,
         "name": "Haitian Gourde",
         "rounding": 0.0
      },
      {
         "symbol": "HUF",
         "name_plural": "Hungarian forints",
         "code": "HUF",
         "symbol_native": "Ft",
         "decimal_digits": 2,
         "name": "Hungarian Forint",
         "rounding": 0.0
      },
      {
         "symbol": "IDR",
         "name_plural": "Indonesian rupiahs",
         "code": "IDR",
         "symbol_native": "Rp",
         "decimal_digits": 0,
         "name": "Indonesian Rupiah",
         "rounding": 0.0
      },
      {
         "symbol": "\u20AA",
         "name_plural": "Israeli new shekels",
         "code": "ILS",
         "symbol_native": "\u20AA",
         "decimal_digits": 2,
         "name": "Israeli New Shekel",
         "rounding": 0.0
      },
      {
         "symbol": "\u20B9",
         "name_plural": "Indian rupees",
         "code": "INR",
         "symbol_native": "\u20B9",
         "decimal_digits": 2,
         "name": "Indian Rupee",
         "rounding": 0.0
      },
      {
         "symbol": "IQD",
         "name_plural": "Iraqi dinars",
         "code": "IQD",
         "symbol_native": "د.ع.\u200F",
         "decimal_digits": 0,
         "name": "Iraqi Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "IRR",
         "name_plural": "Iranian rials",
         "code": "IRR",
         "symbol_native": "IRR",
         "decimal_digits": 0,
         "name": "Iranian Rial",
         "rounding": 0.0
      },
      {
         "symbol": "ISK",
         "name_plural": "Icelandic krónur",
         "code": "ISK",
         "symbol_native": "ISK",
         "decimal_digits": 0,
         "name": "Icelandic Króna",
         "rounding": 0.0
      },
      {
         "symbol": "JMD",
         "name_plural": "Jamaican dollars",
         "code": "JMD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Jamaican Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "JOD",
         "name_plural": "Jordanian dinars",
         "code": "JOD",
         "symbol_native": "د.أ.\u200F",
         "decimal_digits": 3,
         "name": "Jordanian Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "¥",
         "name_plural": "Japanese yen",
         "code": "JPY",
         "symbol_native": "￥",
         "decimal_digits": 0,
         "name": "Japanese Yen",
         "rounding": 0.0
      },
      {
         "symbol": "KES",
         "name_plural": "Kenyan shillings",
         "code": "KES",
         "symbol_native": "Ksh",
         "decimal_digits": 2,
         "name": "Kenyan Shilling",
         "rounding": 0.0
      },
      {
         "symbol": "KGS",
         "name_plural": "Kyrgystani soms",
         "code": "KGS",
         "symbol_native": "сом",
         "decimal_digits": 2,
         "name": "Kyrgystani Som",
         "rounding": 0.0
      },
      {
         "symbol": "KHR",
         "name_plural": "Cambodian riels",
         "code": "KHR",
         "symbol_native": "៛",
         "decimal_digits": 2,
         "name": "Cambodian Riel",
         "rounding": 0.0
      },
      {
         "symbol": "KMF",
         "name_plural": "Comorian francs",
         "code": "KMF",
         "symbol_native": "CF",
         "decimal_digits": 0,
         "name": "Comorian Franc",
         "rounding": 0.0
      },
      {
         "symbol": "KPW",
         "name_plural": "North Korean won",
         "code": "KPW",
         "symbol_native": "KPW",
         "decimal_digits": 0,
         "name": "North Korean Won",
         "rounding": 0.0
      },
      {
         "symbol": "\u20A9",
         "name_plural": "South Korean won",
         "code": "KRW",
         "symbol_native": "\u20A9",
         "decimal_digits": 0,
         "name": "South Korean Won",
         "rounding": 0.0
      },
      {
         "symbol": "KWD",
         "name_plural": "Kuwaiti dinars",
         "code": "KWD",
         "symbol_native": "د.ك.\u200F",
         "decimal_digits": 3,
         "name": "Kuwaiti Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "KYD",
         "name_plural": "Cayman Islands dollars",
         "code": "KYD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Cayman Islands Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "KZT",
         "name_plural": "Kazakhstani tenges",
         "code": "KZT",
         "symbol_native": "\u20B8",
         "decimal_digits": 2,
         "name": "Kazakhstani Tenge",
         "rounding": 0.0
      },
      {
         "symbol": "LAK",
         "name_plural": "Laotian kips",
         "code": "LAK",
         "symbol_native": "\u20AD",
         "decimal_digits": 0,
         "name": "Laotian Kip",
         "rounding": 0.0
      },
      {
         "symbol": "LBP",
         "name_plural": "Lebanese pounds",
         "code": "LBP",
         "symbol_native": "ل.ل.\u200F",
         "decimal_digits": 0,
         "name": "Lebanese Pound",
         "rounding": 0.0
      },
      {
         "symbol": "LKR",
         "name_plural": "Sri Lankan rupees",
         "code": "LKR",
         "symbol_native": "රු.",
         "decimal_digits": 2,
         "name": "Sri Lankan Rupee",
         "rounding": 0.0
      },
      {
         "symbol": "LRD",
         "name_plural": "Liberian dollars",
         "code": "LRD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Liberian Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "LSL",
         "name_plural": "Lesotho lotis",
         "code": "LSL",
         "symbol_native": "LSL",
         "decimal_digits": 2,
         "name": "Lesotho Loti",
         "rounding": 0.0
      },
      {
         "symbol": "LYD",
         "name_plural": "Libyan dinars",
         "code": "LYD",
         "symbol_native": "د.ل.\u200F",
         "decimal_digits": 3,
         "name": "Libyan Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "MAD",
         "name_plural": "Moroccan dirhams",
         "code": "MAD",
         "symbol_native": "د.م.\u200F",
         "decimal_digits": 2,
         "name": "Moroccan Dirham",
         "rounding": 0.0
      },
      {
         "symbol": "MDL",
         "name_plural": "Moldovan lei",
         "code": "MDL",
         "symbol_native": "L",
         "decimal_digits": 2,
         "name": "Moldovan Leu",
         "rounding": 0.0
      },
      {
         "symbol": "MGA",
         "name_plural": "Malagasy ariaries",
         "code": "MGA",
         "symbol_native": "Ar",
         "decimal_digits": 0,
         "name": "Malagasy Ariary",
         "rounding": 0.0
      },
      {
         "symbol": "MKD",
         "name_plural": "Macedonian denari",
         "code": "MKD",
         "symbol_native": "ден",
         "decimal_digits": 2,
         "name": "Macedonian Denar",
         "rounding": 0.0
      },
      {
         "symbol": "MMK",
         "name_plural": "Myanmar kyats",
         "code": "MMK",
         "symbol_native": "K",
         "decimal_digits": 0,
         "name": "Myanmar Kyat",
         "rounding": 0.0
      },
      {
         "symbol": "MNT",
         "name_plural": "Mongolian tugriks",
         "code": "MNT",
         "symbol_native": "\u20AE",
         "decimal_digits": 0,
         "name": "Mongolian Tugrik",
         "rounding": 0.0
      },
      {
         "symbol": "MOP",
         "name_plural": "Macanese patacas",
         "code": "MOP",
         "symbol_native": "MOP$",
         "decimal_digits": 2,
         "name": "Macanese Pataca",
         "rounding": 0.0
      },
      {
         "symbol": "MRO",
         "name_plural": "Mauritanian ouguiyas",
         "code": "MRO",
         "symbol_native": "أ.م.\u200F",
         "decimal_digits": 0,
         "name": "Mauritanian Ouguiya",
         "rounding": 0.0
      },
      {
         "symbol": "MUR",
         "name_plural": "Mauritian rupees",
         "code": "MUR",
         "symbol_native": "Rs",
         "decimal_digits": 0,
         "name": "Mauritian Rupee",
         "rounding": 0.0
      },
      {
         "symbol": "MWK",
         "name_plural": "Malawian kwachas",
         "code": "MWK",
         "symbol_native": "MK",
         "decimal_digits": 2,
         "name": "Malawian Kwacha",
         "rounding": 0.0
      },
      {
         "symbol": "MX$",
         "name_plural": "Mexican pesos",
         "code": "MXN",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Mexican Peso",
         "rounding": 0.0
      },
      {
         "symbol": "MXV",
         "name_plural": "Mexican investment units",
         "code": "MXV",
         "symbol_native": "MXV",
         "decimal_digits": 2,
         "name": "Mexican Investment Unit",
         "rounding": 0.0
      },
      {
         "symbol": "MYR",
         "name_plural": "Malaysian ringgits",
         "code": "MYR",
         "symbol_native": "RM",
         "decimal_digits": 2,
         "name": "Malaysian Ringgit",
         "rounding": 0.0
      },
      {
         "symbol": "MZN",
         "name_plural": "Mozambican meticals",
         "code": "MZN",
         "symbol_native": "MTn",
         "decimal_digits": 2,
         "name": "Mozambican Metical",
         "rounding": 0.0
      },
      {
         "symbol": "NAD",
         "name_plural": "Namibian dollars",
         "code": "NAD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Namibian Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "NGN",
         "name_plural": "Nigerian nairas",
         "code": "NGN",
         "symbol_native": "\u20A6",
         "decimal_digits": 2,
         "name": "Nigerian Naira",
         "rounding": 0.0
      },
      {
         "symbol": "NIO",
         "name_plural": "Nicaraguan córdobas",
         "code": "NIO",
         "symbol_native": "C$",
         "decimal_digits": 2,
         "name": "Nicaraguan Córdoba",
         "rounding": 0.0
      },
      {
         "symbol": "NOK",
         "name_plural": "Norwegian kroner",
         "code": "NOK",
         "symbol_native": "kr",
         "decimal_digits": 2,
         "name": "Norwegian Krone",
         "rounding": 0.0
      },
      {
         "symbol": "NPR",
         "name_plural": "Nepalese rupees",
         "code": "NPR",
         "symbol_native": "नेरू",
         "decimal_digits": 2,
         "name": "Nepalese Rupee",
         "rounding": 0.0
      },
      {
         "symbol": "NZ$",
         "name_plural": "New Zealand dollars",
         "code": "NZD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "New Zealand Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "OMR",
         "name_plural": "Omani rials",
         "code": "OMR",
         "symbol_native": "ر.ع.\u200F",
         "decimal_digits": 3,
         "name": "Omani Rial",
         "rounding": 0.0
      },
      {
         "symbol": "PAB",
         "name_plural": "Panamanian balboas",
         "code": "PAB",
         "symbol_native": "B\/.",
         "decimal_digits": 2,
         "name": "Panamanian Balboa",
         "rounding": 0.0
      },
      {
         "symbol": "PEN",
         "name_plural": "Peruvian soles",
         "code": "PEN",
         "symbol_native": "S\/",
         "decimal_digits": 2,
         "name": "Peruvian Sol",
         "rounding": 0.0
      },
      {
         "symbol": "PGK",
         "name_plural": "Papua New Guinean kina",
         "code": "PGK",
         "symbol_native": "K",
         "decimal_digits": 2,
         "name": "Papua New Guinean Kina",
         "rounding": 0.0
      },
      {
         "symbol": "PHP",
         "name_plural": "Philippine pisos",
         "code": "PHP",
         "symbol_native": "\u20B1",
         "decimal_digits": 2,
         "name": "Philippine Piso",
         "rounding": 0.0
      },
      {
         "symbol": "PKR",
         "name_plural": "Pakistani rupees",
         "code": "PKR",
         "symbol_native": "Rs",
         "decimal_digits": 0,
         "name": "Pakistani Rupee",
         "rounding": 0.0
      },
      {
         "symbol": "PLN",
         "name_plural": "Polish zlotys",
         "code": "PLN",
         "symbol_native": "zł",
         "decimal_digits": 2,
         "name": "Polish Zloty",
         "rounding": 0.0
      },
      {
         "symbol": "PYG",
         "name_plural": "Paraguayan guaranis",
         "code": "PYG",
         "symbol_native": "Gs.",
         "decimal_digits": 0,
         "name": "Paraguayan Guarani",
         "rounding": 0.0
      },
      {
         "symbol": "QAR",
         "name_plural": "Qatari rials",
         "code": "QAR",
         "symbol_native": "ر.ق.\u200F",
         "decimal_digits": 2,
         "name": "Qatari Rial",
         "rounding": 0.0
      },
      {
         "symbol": "RON",
         "name_plural": "Romanian lei",
         "code": "RON",
         "symbol_native": "RON",
         "decimal_digits": 2,
         "name": "Romanian Leu",
         "rounding": 0.0
      },
      {
         "symbol": "RSD",
         "name_plural": "Serbian dinars",
         "code": "RSD",
         "symbol_native": "RSD",
         "decimal_digits": 0,
         "name": "Serbian Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "RUB",
         "name_plural": "Russian rubles",
         "code": "RUB",
         "symbol_native": "\u20BD",
         "decimal_digits": 2,
         "name": "Russian Ruble",
         "rounding": 0.0
      },
      {
         "symbol": "RWF",
         "name_plural": "Rwandan francs",
         "code": "RWF",
         "symbol_native": "RF",
         "decimal_digits": 0,
         "name": "Rwandan Franc",
         "rounding": 0.0
      },
      {
         "symbol": "SAR",
         "name_plural": "Saudi riyals",
         "code": "SAR",
         "symbol_native": "ر.س.\u200F",
         "decimal_digits": 2,
         "name": "Saudi Riyal",
         "rounding": 0.0
      },
      {
         "symbol": "SBD",
         "name_plural": "Solomon Islands dollars",
         "code": "SBD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Solomon Islands Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "SCR",
         "name_plural": "Seychellois rupees",
         "code": "SCR",
         "symbol_native": "SR",
         "decimal_digits": 2,
         "name": "Seychellois Rupee",
         "rounding": 0.0
      },
      {
         "symbol": "SDG",
         "name_plural": "Sudanese pounds",
         "code": "SDG",
         "symbol_native": "ج.س.",
         "decimal_digits": 2,
         "name": "Sudanese Pound",
         "rounding": 0.0
      },
      {
         "symbol": "SEK",
         "name_plural": "Swedish kronor",
         "code": "SEK",
         "symbol_native": "kr",
         "decimal_digits": 2,
         "name": "Swedish Krona",
         "rounding": 0.0
      },
      {
         "symbol": "SGD",
         "name_plural": "Singapore dollars",
         "code": "SGD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Singapore Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "SHP",
         "name_plural": "St. Helena pounds",
         "code": "SHP",
         "symbol_native": "£",
         "decimal_digits": 2,
         "name": "St. Helena Pound",
         "rounding": 0.0
      },
      {
         "symbol": "SLL",
         "name_plural": "Sierra Leonean leones",
         "code": "SLL",
         "symbol_native": "Le",
         "decimal_digits": 0,
         "name": "Sierra Leonean Leone",
         "rounding": 0.0
      },
      {
         "symbol": "SOS",
         "name_plural": "Somali shillings",
         "code": "SOS",
         "symbol_native": "S",
         "decimal_digits": 0,
         "name": "Somali Shilling",
         "rounding": 0.0
      },
      {
         "symbol": "SRD",
         "name_plural": "Surinamese dollars",
         "code": "SRD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Surinamese Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "SSP",
         "name_plural": "South Sudanese pounds",
         "code": "SSP",
         "symbol_native": "£",
         "decimal_digits": 2,
         "name": "South Sudanese Pound",
         "rounding": 0.0
      },
      {
         "symbol": "STN",
         "name_plural": "São Tomé & Príncipe dobras (2018)",
         "code": "STN",
         "symbol_native": "STN",
         "decimal_digits": 2,
         "name": "São Tomé & Príncipe Dobra (2018)",
         "rounding": 0.0
      },
      {
         "symbol": "SYP",
         "name_plural": "Syrian pounds",
         "code": "SYP",
         "symbol_native": "ل.س.\u200F",
         "decimal_digits": 0,
         "name": "Syrian Pound",
         "rounding": 0.0
      },
      {
         "symbol": "SZL",
         "name_plural": "Swazi emalangeni",
         "code": "SZL",
         "symbol_native": "E",
         "decimal_digits": 2,
         "name": "Swazi Lilangeni",
         "rounding": 0.0
      },
      {
         "symbol": "THB",
         "name_plural": "Thai baht",
         "code": "THB",
         "symbol_native": "THB",
         "decimal_digits": 2,
         "name": "Thai Baht",
         "rounding": 0.0
      },
      {
         "symbol": "TJS",
         "name_plural": "Tajikistani somonis",
         "code": "TJS",
         "symbol_native": "сом.",
         "decimal_digits": 2,
         "name": "Tajikistani Somoni",
         "rounding": 0.0
      },
      {
         "symbol": "TND",
         "name_plural": "Tunisian dinars",
         "code": "TND",
         "symbol_native": "د.ت.\u200F",
         "decimal_digits": 3,
         "name": "Tunisian Dinar",
         "rounding": 0.0
      },
      {
         "symbol": "TOP",
         "name_plural": "Tongan paʻanga",
         "code": "TOP",
         "symbol_native": "T$",
         "decimal_digits": 2,
         "name": "Tongan Paʻanga",
         "rounding": 0.0
      },
      {
         "symbol": "TRY",
         "name_plural": "Turkish Lira",
         "code": "TRY",
         "symbol_native": "\u20BA",
         "decimal_digits": 2,
         "name": "Turkish Lira",
         "rounding": 0.0
      },
      {
         "symbol": "TTD",
         "name_plural": "Trinidad & Tobago dollars",
         "code": "TTD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Trinidad & Tobago Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "NT$",
         "name_plural": "New Taiwan dollars",
         "code": "TWD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "New Taiwan Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "TZS",
         "name_plural": "Tanzanian shillings",
         "code": "TZS",
         "symbol_native": "TSh",
         "decimal_digits": 0,
         "name": "Tanzanian Shilling",
         "rounding": 0.0
      },
      {
         "symbol": "UAH",
         "name_plural": "Ukrainian hryvnias",
         "code": "UAH",
         "symbol_native": "\u20B4",
         "decimal_digits": 2,
         "name": "Ukrainian Hryvnia",
         "rounding": 0.0
      },
      {
         "symbol": "UGX",
         "name_plural": "Ugandan shillings",
         "code": "UGX",
         "symbol_native": "USh",
         "decimal_digits": 0,
         "name": "Ugandan Shilling",
         "rounding": 0.0
      },
      {
         "symbol": "$",
         "name_plural": "US dollars",
         "code": "USD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "US Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "USN",
         "name_plural": "US dollars (next day)",
         "code": "USN",
         "symbol_native": "USN",
         "decimal_digits": 2,
         "name": "US Dollar (Next day)",
         "rounding": 0.0
      },
      {
         "symbol": "UYI",
         "name_plural": "Uruguayan pesos (indexed units)",
         "code": "UYI",
         "symbol_native": "UYI",
         "decimal_digits": 0,
         "name": "Uruguayan Peso (Indexed Units)",
         "rounding": 0.0
      },
      {
         "symbol": "UYU",
         "name_plural": "Uruguayan pesos",
         "code": "UYU",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "Uruguayan Peso",
         "rounding": 0.0
      },
      {
         "symbol": "UZS",
         "name_plural": "Uzbekistani som",
         "code": "UZS",
         "symbol_native": "сўм",
         "decimal_digits": 0,
         "name": "Uzbekistani Som",
         "rounding": 0.0
      },
      {
         "symbol": "VEF",
         "name_plural": "Venezuelan bolívars",
         "code": "VEF",
         "symbol_native": "Bs.",
         "decimal_digits": 2,
         "name": "Venezuelan Bolívar",
         "rounding": 0.0
      },
      {
         "symbol": "\u20AB",
         "name_plural": "Vietnamese dong",
         "code": "VND",
         "symbol_native": "\u20AB",
         "decimal_digits": 0,
         "name": "Vietnamese Dong",
         "rounding": 0.0
      },
      {
         "symbol": "VUV",
         "name_plural": "Vanuatu vatus",
         "code": "VUV",
         "symbol_native": "VT",
         "decimal_digits": 0,
         "name": "Vanuatu Vatu",
         "rounding": 0.0
      },
      {
         "symbol": "WST",
         "name_plural": "Samoan tala",
         "code": "WST",
         "symbol_native": "WS$",
         "decimal_digits": 2,
         "name": "Samoan Tala",
         "rounding": 0.0
      },
      {
         "symbol": "FCFA",
         "name_plural": "Central African CFA francs",
         "code": "XAF",
         "symbol_native": "FCFA",
         "decimal_digits": 0,
         "name": "Central African CFA Franc",
         "rounding": 0.0
      },
      {
         "symbol": "EC$",
         "name_plural": "East Caribbean dollars",
         "code": "XCD",
         "symbol_native": "$",
         "decimal_digits": 2,
         "name": "East Caribbean Dollar",
         "rounding": 0.0
      },
      {
         "symbol": "CFA",
         "name_plural": "West African CFA francs",
         "code": "XOF",
         "symbol_native": "CFA",
         "decimal_digits": 0,
         "name": "West African CFA Franc",
         "rounding": 0.0
      },
      {
         "symbol": "CFPF",
         "name_plural": "CFP francs",
         "code": "XPF",
         "symbol_native": "FCFP",
         "decimal_digits": 0,
         "name": "CFP Franc",
         "rounding": 0.0
      },
      {
         "symbol": "YER",
         "name_plural": "Yemeni rials",
         "code": "YER",
         "symbol_native": "ر.ي.\u200F",
         "decimal_digits": 0,
         "name": "Yemeni Rial",
         "rounding": 0.0
      },
      {
         "symbol": "ZAR",
         "name_plural": "South African rand",
         "code": "ZAR",
         "symbol_native": "R",
         "decimal_digits": 2,
         "name": "South African Rand",
         "rounding": 0.0
      },
      {
         "symbol": "ZMW",
         "name_plural": "Zambian kwachas",
         "code": "ZMW",
         "symbol_native": "K",
         "decimal_digits": 2,
         "name": "Zambian Kwacha",
         "rounding": 0.0
      }
   ];
   const config = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
   };

   const saveAllcurrenciestoDB = async () => {
      currencyList.map(async item => {
         let body = {
            currencyObj: {
               name: item.name,
               code: item.code,
               symbol_native: item.symbol_native
            }
         };
         await axios.post("/api/addcurrency", body, config).then(response => {
            console.log("API CURRENCY RESPONSE")
            console.log(response)
         })
      })
   }

   function converttooptions(inputArray) {
      let optionList = [];
      inputArray.map((item) => {
         const { _id, name, symbol_native, code } = item;
         optionList.push({ label: name + " - " + code + " (" + symbol_native + ")", value: _id });
      });
      return optionList;
   }

   const getstandardCurrencyVal = (val, arr) => {
      const option = arr.find((item) => item._id === val);
      if (option && typeof option === "object") {
         const { name, code, symbol_native, _id } = option;
         const label = `${name} - ${code} (${symbol_native})`;
         const value = _id;
         return { label, value };
      }
      return { label: "", value: "" };
   }

   const handleEdit = currency => {
      setEditingCurrency(currency);
      setEditedCurrencyName(currency.name);
      setEditedCurrencyCode(currency.code);
      setEditedCurrencySymbol(currency.symbol_native);
      setEditModalVisible(true);
   };

   const handleSave = () => {
      const updatedCurrencies = props.currenciesList.map(currency =>
         currency._id === editingCurrency._id
            ? { ...currency, name: editedCurrencyName, code: editedCurrencyCode, symbol_native: editedCurrencySymbol }
            : currency
      );
      let body = {
         name: editedCurrencyName , code: editedCurrencyCode, symbol: editedCurrencySymbol
      }
      axios.post(`/api/updateCurrency/${editingCurrency._id}`, { body },config)
         .then(response => {
            console.log("Update Currency response")
            console.log(response)
            if(response.data === "success"){
            props.setCurrenciesList(updatedCurrencies);
            setEditModalVisible(false);
         }else{
            toast.error(intl.formatMessage({ id: "PleaseTryAgain", defaultMessage: "Please Try Again!" }), {
               position: toast.POSITION.BOTTOM_CENTER
            })
         }
         })
         .catch(error => console.log(error));
   };

   return (
      <>
         {props.currenciesList.length > 0 && (
            <DefaultCurrency standardCurrency={props.standardCurrency} optionList={props.currenciesList} options={converttooptions(props.currenciesList)} standardCurrencyObj={getstandardCurrencyVal(props.standardCurrency, props.currenciesList)} setStandardCurrency={props.setStandardCurrency} />
         )}
         <AddCurrencyPopup />
         <div class="row">
            <div class="col-12">
               <div className='card'>
                  <Table dataSource={props.currenciesList}>
                     <Column title="Code" dataIndex="code" key="code" />
                     <Column title="Name" dataIndex="name" key="name" />
                     <Column title="Symbol" dataIndex="symbol_native" key="symbol_native" />
                     <Column
                        title="Actions"
                        key="edit"
                        render={(_, record) => (
                           <Button onClick={() => handleEdit(record)}>Edit</Button>
                        )}
                     />
                  </Table>
               </div>
            </div>
         </div>
         <Modal
            title="Edit Currency"
            visible={editModalVisible}
            onOk={handleSave}
            onCancel={() => setEditModalVisible(false)}
         >
            <Input value={editedCurrencyName} onChange={e => setEditedCurrencyName(e.target.value)} className="mb-2" />
            <Input value={editedCurrencyCode} onChange={e => setEditedCurrencyCode(e.target.value)} className="mb-2" />
            <Input value={editedCurrencySymbol} onChange={e => setEditedCurrencySymbol(e.target.value)} className="mb-2" />
         </Modal>
      </>
   );
}

export default Currencies;





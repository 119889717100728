import { useState } from "react";
import { Drawer, Button, Form, Input, message, Space } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import axios from "axios";
import { PlusOutlined } from '@ant-design/icons';
const AddCurrencyPopup = () => {
    const [visible, setVisible] = useState(false);
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };
    const onFinish = async (values) => {
        let body = {
            currencyObj: {
               name: values.name,
               code: values.code,
               symbol_native: values.symbol_native
            }
         };
        console.log(values)
        try {
            //API route yet not created
            const response = await axios.post("/api/addcurrency", body, config);
            console.log(response.data);
            message.success("Data saved successfully!");
            window.location.reload(false);
            setVisible(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Space wrap className="mb-3 d-flex justify-content-start">
                <Button type="primary" onClick={() => setVisible(true)} icon={<PlusOutlined />}>
                <FormattedMessage id="AddNewCurrency" defaultMessage="AddNewCurrency" />
                </Button>
            </Space>
            <Drawer
                title="Add New Currency"
                visible={visible}
                onClose={() => setVisible(false)}
                size='large'
                extra={
                    <Space>
                        <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
                        <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button form="new-currency-form" type="primary" htmlType="submit">
                        <FormattedMessage id="Save" defaultMessage="Save" />
                        </Button>
                    </Space>
                }
            >
                <Form id="new-currency-form" onFinish={onFinish} layout="vertical">
                    <Form.Item
                        label="Currency Name"
                        name="name"
                        rules={[{ required: true, message: "Please input the currency name!" }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Currency Code"
                        name="code"
                        rules={[{ required: true, message: "Please input the currency code!" }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Currency Symbol"
                        name="symbol_native"
                        rules={[{ required: true, message: "Please input the currency symbol native!" }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default AddCurrencyPopup;
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { FormattedMessage } from 'react-intl';

const ChangeBayModal = (props) => {
  const [selectedBay, setSelectedBay] = useState(props.changeModal[1][1])
  console.log(props)
  console.log(props.changeModal[1][2])
  console.log(props.changeModal[0]["Name"])
  const handleClose = () => {
    props.SetChangeModal(false)
  }

  const handleChange = (name) => {
    console.log(name)
    setSelectedBay(name)
  }

  const handleSave = (name, ArrayNumber) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    /* 
    console.log(["MechanicalAndBodyRepairWorkBaysResult"][0]) */
    let currentTaskID = props.tasks[props.changeModal[0]["Name"]][0];
    let newBayType = selectedBay;
    let newBayName = props.changeModal[0]["Name"];


    let PostData = {
      newBayType: newBayType,
      newBayName: newBayName,
      currentTaskID: currentTaskID,
      projectID: props.idd,
      keyOfTask: props.changeModal[0]["Name"]
    }

    console.log(PostData)
    async function send() {
      try {
        const data = await axios.put(`/api/private/queue/baychange/${currentTaskID}`, PostData, config);
        console.log(data.data)
        /*   SetWaitingForResponse(false) */
        props.setTasks((prevState) => {
          let newData = { ...prevState }
          console.log(newData)
          console.log(newBayName)
          newData[newBayName][7] = newBayType
          return newData;
        })


        return data
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    send().then(newObj => {
      handleClose()

    })
  }

  return (
    <div>
      <div className="modal fade show" id="exampleModalLive" tabIndex={-1} aria-labelledby="exampleModalLiveLabel" style={{ backgroundColor: "rgba(112, 112, 112, 0.5)", display: 'flex', paddingRight: 13 }} aria-modal="true" role="dialog">
        <div style={{ margin: "auto" }} className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLiveLabel"><FormattedMessage id="ChangeBay" defaultMessage="Change Bay" /></h5>
              <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/* {props} */}
              {props.BodyArrs.map((item, index) => {
                return (
                  <>
                    <button onClick={() => handleChange(item[1])} style={{ marginBottom: "10px" }} type="button" className={selectedBay == item[1] ? "btnselected btn btn-primary" : "btn btn-primary"}>{item[2]} </button>
                    <br />
                  </>
                )

              })}

            </div>
            <div className="modal-footer">
              <button onClick={handleClose} type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="Close" defaultMessage="Close" /></button>
              <button onClick={handleSave} type="button" className="btn btn-primary"><FormattedMessage id="Savechanges" defaultMessage="Save changes" /></button>
            </div>
          </div>
        </div>
      </div>


      {/*  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        TEST
        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div> */}
    </div>
  )
}


export default ChangeBayModal

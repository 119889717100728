import React, { memo } from 'react';
import Maps from '../MapComponent';

const MapWrapper = memo(({ markers, initialCenterMarker }) => (
  <>
      {markers.length > 0 ? (
        <Maps
          markers={markers}
          initialCenterMarker={initialCenterMarker}
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC1HdsZ1rBMKZ_jHRjYMtO0rX51rBDZ93Y&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      ) : (
        ''
      )}
   </>
));

export default memo(MapWrapper);

import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Tooltip } from 'antd';

const ProjectPieKPI = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: "circle"
        }
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label;
            const value = context.formattedValue;
            return `${label}: ${value}%`;
          },
        },
      },
    }
  }

  const generateColors = (totalCountGenerate) => {
    const retuenColors = [];
    for (let index = 0; index < totalCountGenerate; index++) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      retuenColors.push("#" + randomColor);
    }
    return retuenColors;
  }

  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.workTypes && props.workTypes != '' && props.workTypes != '0') {
      const listTypes = props.workTypes.map(x => { return x.WorkType });
      const listTypesCount = props.workTypes.map(x => { return x.WorkTypeCount });
      const colors = generateColors(props.workTypes.length);
      const _data = {
        labels: listTypes,//["Audi", "VW", "Stammkunden"],
        datasets: [
          {
            label: listTypes[0],
            data: listTypesCount,
            backgroundColor: colors,
          },
        ]
      };
      setData(_data);
    } else {
      const _data = {
        labels: ["Audi", "VW", "Stammkunden"],
        datasets: [
          {
            label: 'Audi',
            data: [50, 30, 20],
            backgroundColor: ["#1CC435", "#194A96", "#F17329"],
          },
        ]
      };
      setData(_data);
    }
  }, [props.workTypes]);

  return (
    <>
      <div className="card rounded-2 border-gray border-radius-16 pd-16 bg-white card-height col-md-2">
        <h3 className="card-c-heading-2">{props.title}</h3>
        <div className="chart-container" style={{ width: "100%", position: 'relative', maxWidth: "100%", }}>
          <Pie
            options={options}
            height={null} width={null}
            data={data} />
        </div>
      </div>
    </>
  );
};


export default ProjectPieKPI;
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useIntl, FormattedMessage } from 'react-intl';
import LanguageSwitcher from '../language-switcher/language-switcher';
import { ToastContainer, toast } from 'react-toastify';

const ResetPasswordScreen = ({ history, match }) => {
  console.log(history, match)
  const intl = useIntl();
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [username, setUsername] = useState("")
  let { userid } = useParams();
  const config = {
    header: {
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    axios.get(`/api/getusername/${userid}`, config).then(result => {
      setUsername(result.data.username)
    });

    if (match.path == "/changepassword/:resetToken") {
      setType("Change")
    } else {
      setType("Create")
    }
  })

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    if (password === "") {
      toast.error(intl.formatMessage({ id: "PleaseEnterPassword", defaultMessage: "Please Enter Password" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return;
    }

    try {
      const { data } = await axios.put(`/api/auth/passwordreset/${match.params.resetToken}`,{password},config);
      if (type === "change") {
        toast.success(intl.formatMessage({ id: "PasswordSuccessfullyChanged.RedirectingtoLogin...", defaultMessage: "Password Successfully Changed.Redirecting to Login..." }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
        setTimeout(() => {
          history.push("/login");
        }, 1500);
      } else {
        toast.success(intl.formatMessage({ id: "PasswordSuccessfullyCreated.RedirectingtoLogin...", defaultMessage: "Password Successfully Created. Redirecting to Login..." }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
        setTimeout(() => {
          history.push("/login");
        }, 1500);
      }
    } catch (error) {
      if (error.response.data.error === "Invalid Token") {
        toast.error(intl.formatMessage({ id: "InvalidToken?", defaultMessage: "The link has expired - Please get in contact with us" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
      }
    }
  };

  return (
    <>
      <ToastContainer toastClassName="top-zIndex" autoClose={3000} />
      <div className="headpanel pos-fixed-top ht-70 l-xl-320 bg-primary z-index-150 forget-password" style={{ left: 0 }}>
        <div className="brand-logo-left">
          <img src="/images/body-shop-boost-logo.svg" alt='body shop boost logo' className="img-fluid" />
        </div>
        <div className="brand-logo">
          <img src="/images/basf-logo.svg" alt='basf logo' className="img-fluid" />
        </div>
        <div className="fix-lanuage-switcher">
          <LanguageSwitcher side="left" />
        </div>
      </div>
      <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 project-page">
        <div className="main-body bg-custom-white pt-0">
          <div className="hold-transition login-page reset-page">
            <div className="login-boxs col-md-6 col-lg-6 col-12">
              <div className="login-logo">
                {type == "Change" ?
                  <h1 className="main-heading"><FormattedMessage id="ChangePassword" defaultMessage="Change Password" /></h1>
                  :
                  <>
                    <h1 className="main-heading"><FormattedMessage id="RegisterYourAccount" defaultMessage="Register Your Account" /></h1>
                    <p className="mb-2 small-text">Welcome <b>{username}</b>,</p>
                    <p className="mb-2 small-text">Please choose a password to finish the registration of your Body Shop <b>BOOST</b> account. <br />After choosing the password, you'll  be able to <b>BOOST</b> your Body Shop </p>
                  </>
                }
              </div>
              <form onSubmit={resetPasswordHandler}>
                <div className="input-group mb-3 mt-3">
                  {type == "Change" ?
                    <div className="input-group ">
                      <input className="form-control" type={passwordVisibility ? "text" : "password"} id="password"
                        placeholder={intl.formatMessage({ id: "Enternewpassword", defaultMessage: "Enter new password" })}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className={passwordVisibility ? "fa fa-eye-slash password-toggle" : "fa fa-eye password-toggle"} onClick={() => { setPasswordVisibility(prevpasswordVisibility => !prevpasswordVisibility) }}></span>
                        </div>
                      </div>
                    </div>
                    :
                    <>
                      <div className="input-group ">
                        <input className="form-control" type={passwordVisibility ? "text" : "password"} id="password"
                          placeholder={intl.formatMessage({ id: "Setpassword", defaultMessage: "Set password" })}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className={passwordVisibility ? "fa fa-eye-slash password-toggle" : "fa fa-eye password-toggle"} onClick={() => { setPasswordVisibility(prevpasswordVisibility => !prevpasswordVisibility) }}></span>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
                {type == "Change" ?
                  <button id="submit" type="submit" className="btn cta-btn text-uppercase"><FormattedMessage id="ChangePassword" defaultMessage="Change Password" /></button>
                  : <button id="submit" type="submit" className="btn cta-btn text-uppercase"><FormattedMessage id="RegisterAccount" defaultMessage="Register Account" /></button>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordScreen;

import { Box, SelectedItem, Flag, MenuItem } from './language-switcher.style';
import Popover from './popover/popover';
import { FormattedMessage } from 'react-intl';
import * as flagIcons from './flags';
import { useLocale } from '../../language/language.provider';
import { LANGUAGE_MENU } from './languages';
import axios from 'axios';
import Cookie from 'js-cookie';
import { useCallback, useEffect } from 'react';

const FlagIcon = ({ name }) => {
  const TagName = flagIcons[name];
  return !!TagName ? <TagName /> : <p>Invalid icon {name}</p>;
};

const listLanguages = LANGUAGE_MENU.sort((a, b) => a.id.localeCompare(b.id));

const LanguageMenu = ({ onClick }) => {
  return (
    <>
      {listLanguages.map((item) => (
        <MenuItem onClick={onClick} key={item.id} value={item.id}>
          {/* <span>
            <FlagIcon name={item.icon} />
          </span> */}
          <FormattedMessage id={item.id} defaultMessage={item.id.toUpperCase()} />
        </MenuItem>
      ))}
    </>
  );
};

const LanguageSwitcher = ({ side }) => {
  const { locale, changeLanguage } = useLocale();
  if (!locale) changeLanguage("en");
  const selectedLanguage = LANGUAGE_MENU.find((x) => x.id === (locale ? locale : 'en'));
  const languageChangeHandler = (e) => {
    changeLanguage(e.target.value);
    const authToken = localStorage.getItem("authToken");
    const userID = Cookie.get('localeUser');
    if (authToken && userID) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios.post("/api/user/setuserlanguage", { userid: userID, selectedLang: e.target.value }, config).then(res => {
        // Do nothing just continue
      }).catch(err => {
        console.log(err);
      });
    }
  };


  useEffect(() => {
    getUserLanguagePreferences();
  }, []);

  const getUserLanguagePreferences = useCallback(() => {
    const authToken = localStorage.getItem("authToken");
    const userID = Cookie.get('localeUser');
    if (authToken && userID) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios.get(`/api/user/getuserlanguage/${userID}`, config)
        .then(res => {
          changeLanguage(res.data);
        }).catch(err => {
          console.log(err);
          changeLanguage("en");
        });
    }
  });


  return (
    <Box>
      <Popover
        className={side}
        handler={
          <SelectedItem>
            {/* <Flag>
              <FlagIcon name={selectedLanguage?.icon} />
            </Flag> */}
            <span>
              <FormattedMessage
                id={selectedLanguage?.id}
                defaultMessage={selectedLanguage?.id.toUpperCase()}
              />
            </span>
          </SelectedItem>
        }
        content={<LanguageMenu onClick={languageChangeHandler} />}
      />
    </Box>
  );
};

export default LanguageSwitcher;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';


const PopupAdd = (props) => {



  const [SelectOne, setSelectOne] = useState([])
  /*   const [Weight, setWeight] = useState(props.item[2]) */
  //console.log(props.allTasks)
  const removeEl = () => {

    props.removeLine(props.index)
    //props.key

  }
console.log(SelectOne)
  useEffect(() => {
   console.log(props.item)
      if (props.item.label) {
      setSelectOne([props.item])
    }  
  }, [])

  /*   const handleChangeWeight = (e) => {
       // props.Additional()
        setWeight(+e.target.value)
       props.SetAdditional(prevState => {
           return prevState.map((item,index) => {
          return     index == props.index  ?  [item[0],item[1],+e.target.value] : item
        
     })})

    } */


  const handleChange = (selectedOption) => {
    console.log(selectedOption)
    console.log(selectedOption.length)
    //console.log(selectedOption[selectedOption.length-1])
    if (selectedOption.length == 0) {
      console.log(selectedOption)
      setSelectOne([])
      props.SetAdditional(prevState => {
        return prevState.map((item, index) => {
          return index == props.index ? "" : item
        })
      })
    } else {
      console.log(selectedOption)
      setSelectOne([selectedOption[selectedOption.length - 1]])
      props.SetAdditional(prevState => {
        return prevState.map((item, index) => {
          console.log(item)
          return index == props.index ? selectedOption[selectedOption.length - 1] : item
        })
      })

    }


  };

  const filterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ value }) => value && value.match(re));
  };

  return (

    <div style={{
      display: "flex",
      flexDirection: "row",
    }} className=" col-12 mb-1 mt-1"
      key={props.index + "." + "Add"}>
      <div className="col-1"></div>

      <div id="dropdown" style={{ display: "flex", alignItems: "center" }} className="col-8">
   
     
        <MultiSelect
          options={props.allTasks}
          value={SelectOne}
          hasSelectAll={false}
          className={"fullwidthdropdown"}
          onChange={(e) => handleChange(e)}
          labelledBy="Select"
          filterOptions={filterOptions}
        />

   
      </div>


    

      <div style={{ display: "flex", alignItems: "center" }} className="col-1">
        <i style={{ cursor: "pointer" }} onClick={() => removeEl()} className="m-1 fas fa-minus"></i>

      </div>

    </div>
  )
}


export default PopupAdd

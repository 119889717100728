import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";

import axios from 'axios';


const PopupDivide = (props) => {
  const [SelectOne, setSelectOne] = useState([])
  const [firstChoice, setFirstChoice] = useState(false)

  useEffect(() => {
    console.log(props.AdditionalDivide)
    if (props.AdditionalDivide[0] !== "" && props.Tasks) {
      console.log(props.allTasks)
      console.log(props.Tasks)
      let obj = props.allTasks.find(o => o.value === props.AdditionalDivide[0]);
      setFirstChoice([{ "label": obj.label, "value": props.AdditionalDivide[0] }])
    }
  }, [])


  const handleChange = (selectedOption) => {
    console.log(selectedOption)
    console.log(selectedOption.length)
    //console.log(selectedOption[selectedOption.length-1])
    if (selectedOption.length == 0) {
      setFirstChoice(false)
      console.log(selectedOption)
      setSelectOne([])
      props.SetAdditionalDivide(prevState => {
        return prevState.map((item, index) => {
          return index == props.index ? ["", item[0], item[2]] : item
        })
      })
    } else {
      console.log(selectedOption)
      setFirstChoice(false)
      setSelectOne([selectedOption[selectedOption.length - 1]])
      console.log([selectedOption[selectedOption.length - 1]])
      props.SetAdditionalDivide(prevState => {
        console.log(prevState)
        return prevState.map((item, index) => {
          console.log("test")
          console.log(item)
          return index == props.index ? selectedOption[selectedOption.length - 1]["value"] : item
        })
      })

    }
    props.setSaved(false)
    console.log(props.AdditionalDivide)

  };

  const filterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ value }) => value && value.match(re));
  };

  return (



    <div id="dropdown" style={{ display: "flex", alignItems: "center" }} className="col-8">
      {/* 
<SelectSearch
options={props.allTasks}
search
filterOptions={fuzzySearch}
placeholder="Select Task"

/> */}

      <MultiSelect
        options={props.allTasks}
        value={firstChoice ? firstChoice : SelectOne}
        hasSelectAll={false}
        className={"fullwidthdropdown"}
        onChange={(e) => handleChange(e)}
        labelledBy="Select"
        filterOptions={filterOptions}
      />

      {/* <Select
 style={{width: "100%"}}
        value={SelectOne}
        onChange={handleChange}
        options={props.allTasks} />   */}

    </div>






  )
}


export default PopupDivide

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useState, useEffect } from "react";



const PieChart = (props) => {
  

  const [ChartData, setChartData] = useState({})
  const [Loading, setLoading] = useState(true)

  useEffect(() => {  
      // Update the document title using the browser API    document.title = `You clicked ${count} times`; 
      var ctx = document.getElementById('canvas').getContext("2d")
      var gradient = ctx.createLinearGradient(0, 0, 0, 400)
      gradient.addColorStop(0, 'rgba(234, 162, 64)')
      gradient.addColorStop(0.5, 'rgba(234, 162, 64, 0.3) ')
      gradient.addColorStop(1, 'rgba(255, 255, 255, 1) ')
      
      let PropsPer = props.percentage 
      
      if (PropsPer == 0) {
        PropsPer = 1
      }
      let PropsData = [PropsPer, (100-PropsPer)]
     
     

      const data  = {
        
        
         
        datasets: [
            
          {
            responsive: true,
            label: '# of Votes',
            
            data: PropsData,
            //data: [0, 99],
            cutout: "73%",
            backgroundColor: [
              getDoughnutBg(),
              'white',
              
              
            ],
            borderColor: [
              'rgba(255, 255, 255, 0)',
              'rgb(234, 162, 64)'
              
            ],
            borderWidth: 0,
          },
        ],
      };
     
      setChartData(data)
      
    }, [props]);
    

    const rounded = () => {
     let number =  props.percentage
     let rounded = Math.round(number * 10) / 10
     var fixed = rounded.toFixed(1)
     return fixed

    };

      const getDoughnutBg = () => {
        let percentage = parseInt(props.percentage);
        if (percentage < 50) {
          return "#BE0412"
        } else if (percentage < 85 && percentage > 0) {
          return "#F99F1C"
        } else if (percentage < 100 && percentage > 85) {
          return "#1CC435"
        } else if (percentage == 100) {
          return "#1C822C"
        }else{
          return "#BE0412"
        }
      }
    

    const PropsPercentage = () => {
     if (props.percentage == 0) {
      return "0 " + "%"
     } else if (props.percentage > 0) {
      return rounded() + "%"
     } 
     
     };

    var options = {
      events: []
    };
  /*
  const plugins = [{
      beforeDraw: function(chart) {
       var width = chart.width,
           height = chart.height,
           ctx = chart.ctx;
           ctx.restore();
           var fontSize = (height / 160).toFixed(2);
           ctx.font = fontSize + "em sans-serif";
           ctx.textBaseline = "top";
           var text = '68 %',
           textX = Math.round((width - ctx.measureText(text).width) / 2),
           textY = height / 2;
           ctx.fillText(text, textX, textY);
           ctx.save();
      } 
    }]
*/
  
     return (

   
      <div className="chart-container" style={{position: 'relative', width: "100vw", maxWidth: "100%"}}>
         <>
        <Doughnut  style={{margin:"auto"}} id='canvas' data={ChartData} options={options}/>

          <div style={{position: "absolute",
top: "45%",
left: "50%",
transform: "translatex(-50%)",
color: "#6A7284",
fontSize:" 32px",
fontWeight: "lighter",
}}>{PropsPercentage()} </div>
         {/*  <p style={{position: "absolute",
    top: "53%",
    left: "50%",
    transform: "translatex(-50%)",
    color: "#312e76",
    fontSize:" 1.5rem",
    fontWeight: "lighter",
    textAlign: "center"}}>Total Audit Score</p> */}
        
      </>


    
</div>
      
    )
     }
 

export default PieChart;
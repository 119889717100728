import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import SpinnerTags from "./spinnerTags";
import axios from "axios";
import { FormattedMessage , intl, useIntl} from "react-intl";
import { message } from "antd";
import { toast } from "react-toastify";

const DefineStatus = (props) => {
  const intl = useIntl();
  const [saved, setSaved] = useState(true);
  const [saveable, setsaveable] = useState(true);
  const [Assessments, setAssessments] = useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  useEffect(
    () => {
      const fetch = async () => {
        try {
          const users = axios.get("/api/private/assessments", config);
          const [usersDB] = await Promise.all([users]);
          console.log(usersDB.data);
          setAssessments(usersDB.data);
          /* let groupnamearr = []
                usersDB.data.elements.forEach(item => {
                  groupnamearr.push(item.groupName)
                })
                setGroupnames(groupnamearr)
     */
        } catch (e) {
          console.log(e.message);
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = "/logout";
          }
        }
      };
      fetch()
        .then
        /*   setLoading(false) */
        ();
    },
    [
      /* setIsSaving, isSaving */
    ]
  );
  const handleSave = (e) => {
    let body = {
      ProjectID: props.idd,
      Status: props.Status,
    };

    console.log(body);

    const sendToServer = async () => {
      console.log(body);
      try {
        const data = await axios.put(
          `/api/private/status/${props.idd}`,
          body,
          config
        );
        console.log(data);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    };
    sendToServer().then((item) => {
      setSaved(true);
      toast.success(intl.formatMessage({id:"ProjectStatusUpdated",defaultMessage:"Project Status Updated!"}), {
        position: toast.POSITION.BOTTOM_CENTER
      })
      setTimeout(() => {
          props.setStatusModal(false);
          window.location.reload(false);
      },3000)

      //    props.setTags(newTags)
    });
  };

  const onChangeValue = (event) => {
    setSaved(false);
    props.setStatus(event.target.value);
    console.log(event.target.value);


  };

  if (props.Status && Assessments) {
    return (
      <div
        style={{ display: "Block", zIndex: "1000" }}
        id="popup-1"
        className="fq-modal"
      >
        <div
          style={{ maxWidth: "500px" }}
          id="milestones"
          className="fq-modal-content"
        >
          <div className="fq-modal-header">
            <span onClick={() => props.setStatusModal(false)} className="close">
              <img src="/images/close-btn.svg" alt="btn close" />
            </span>
            <h1>
              <FormattedMessage
                id="SetStatusforProject"
                defaultMessage="Set Status for Project  "
              />
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "40px",
              paddingBottom: "30px",
              overflowY: "auto",
              height: "60vh",
            }}
            className="modal-content-wrapper"
          >
            <div style={{ padding: "15px 30px" }} className="row d-flex">
              <div
                style={{
                  flexDirection: "column",
                  width: "100%",
                  position: "relative",
                  marginLeft: "37px",
                  marginRight: "20px",
                  display: "flex",
                }}
              >
                {Assessments.map((item, index) => {
                  return (
                    <div
                      className="StatusRow"
                      onChange={(event) => onChangeValue(event)}
                    >
                      <input
                        checked={props.Status === item._id}
                        value={item._id}
                        type="radio"
                        name="gender"
                      />
                      <span style={{ paddingLeft: "10px", fontSize: "20px" }}>
                        {" "}
                        {item.Name}
                      </span>
                    </div>
                  );
                })}

                <div>

                  {saved || !saveable ? (
                    <button
                      style={{ display: "block" }}
                      className="btn cta-btn btn-block" disabled="true"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                    style={{ display: "block" }}
                      className=" btn cta-btn btn-block"
                      onClick={() => handleSave()}
                    >
                      <FormattedMessage id="Save" defaultMessage="Save" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{ display: "Block", zIndex: "1000" }}
        id="popup-1"
        className="fq-modal"
      >
        <SpinnerTags display={"center"} />
      </div>
    );
  }
};

export default DefineStatus;

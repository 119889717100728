import { useEffect, useState } from 'react';
import { Drawer, Space, Button, Select } from 'antd';
import SpinnerTags from './../spinnerTags';
import axios from 'axios';
import { useIntl,FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

const Currency = (props) => {
    const intl = useIntl();
    const [DBcurrency, setDBcurrency] = useState(false);
    const [optionList, setOptionList] = useState([])
    const [butttonStatus, setButtonStatus] = useState(true)
    const [loadingState, setLoadingState] = useState(false)

    function converttooptions(inputArray) {
        let optionList = [];
        inputArray.map((item) => {
            const { _id, name, symbol_native, code } = item;
            optionList.push({ label: name + " - " + code + " (" + symbol_native + ")", value: _id });
        });
        return optionList;
    }

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const users = axios.get("/api/private/currencies", config);
                const [usersDB] = await Promise.all([users])
                console.log("currenciesLISTS")
                console.log(usersDB.data)

                setDBcurrency(usersDB.data)
                if (usersDB.data.length > 0) {
                    let optlist = converttooptions(usersDB.data)
                    setOptionList(optlist)
                }
            } catch (e) {
                console.log(e.message)
                if (!props.dev) {
                    localStorage.removeItem("authToken");
                    window.location.href = '/logout';
                }
            }
        }
        fetch().then(
        );
    }, []);

    const onChangeSelect = (value) => {
        setButtonStatus(false);
        props.setCurrency(value)
    };

    let body = {
        currency: props.currency
    }

    const saveCurrencies = () => {
        setLoadingState(true)
        axios.put(`/api/private/currencies/${props.idd}`, body, config).then((result) => {
            if (result.data === "success") {
                toast.success(intl.formatMessage({ id: "ProjectCurrencyUpdated", defaultMessage: "Project Currency Updated!" }), {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                window.location.reload(false);
            }
            props.setCurrencyModal(false)
            setButtonStatus(false);
        })
    }

    if (DBcurrency) {
        return (
            <Drawer
                title="Update Currency"
                visible={props.currencyModal}
                onClose={() => props.setCurrencyModal(false)}
                size='300px'
                extra={
                    <Space>
                        <Button onClick={() => props.setCurrencyModal(false)} style={{ marginRight: 8 }}>
                            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button type="primary" onClick={saveCurrencies} disabled={butttonStatus} loading={loadingState} >
                        <FormattedMessage id="Save" defaultMessage="Save" />
                        </Button>
                    </Space>
                }
            >
                <Select
                    defaultValue={props.currency}
                    placeholder={intl.formatMessage({ id: "SelectCurrency", defaultMessage: "Select Currency" })}
                    optionFilterProp="children"
                    onChange={onChangeSelect}
                    style={{ width: '100%' }}
                    options={optionList}
                />
            </Drawer>
        )
    } else {
        return (
            <div className="fq-modal d-block z-1000">
                <SpinnerTags display={"center"} />
            </div>
        )
    }
}

export default Currency

import React, { useState } from 'react';
function SpinnerTags() {

    return (
        <section  className="content-header">
            <div style={{height:"80vh",display:"flex"}} className="container-fluid">
                <div style={{margin: "auto"}} class="loader"></div>
            </div>
        </section>
    );
}

export default SpinnerTags
import React, { useState,useEffect } from 'react';
import { Drawer } from 'antd';
import { Input, Button } from 'antd';
import axios from 'axios';

const KPIDrawerSettings = (props) => {
  const [limits, setLimits] = useState({
    WeekdayCustomerDropoff: '',
    KeyToKeyDays: '',
    LeadTime: '',
    IdleTime: '',
    ProductivityPaint: '',
    ProductivityPanel: '',
    ProductivityTotal: ''
   });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const handleSave = async () => {
    const updatedLimits = {};
      updatedLimits.WeekdayCustomerDropoff = limits.WeekdayCustomerDropoff;
      updatedLimits.KeyToKeyDays = limits.KeyToKeyDays;
      updatedLimits.LeadTime = limits.LeadTime;
      updatedLimits.IdleTime = limits.IdleTime;
      updatedLimits.ProductivityPaint = limits.ProductivityPaint;
      updatedLimits.ProductivityPanel = limits.ProductivityPanel;
      updatedLimits.ProductivityTotal = limits.ProductivityTotal;
    try {
      const response = await axios.post(`/api/kpi/saveLimits/${props.projectID}`, updatedLimits, config);
      props.setVisible(false)
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimitChange = (key, value) => {
    setLimits(prevLimits => ({ ...prevLimits, [key]: value }));
  }

  const getKpiLimits = () => {
   axios.get(`/api/getLimits/${props.projectID}`,config).then((kpiresponse) => {
    if(kpiresponse.data){
      setLimits(prevState => Object.assign({}, prevState, {
      WeekdayCustomerDropoff: kpiresponse.data.WeekdayCustomerDropoff,
      KeyToKeyDays: kpiresponse.data.KeyToKeyDays,
      LeadTime: kpiresponse.data.LeadTime,
      IdleTime: kpiresponse.data.IdleTime,
      ProductivityPaint: kpiresponse.data.ProductivityPaint,
      ProductivityPanel: kpiresponse.data.ProductivityPanel,
      ProductivityTotal: kpiresponse.data.ProductivityTotal
    }))
  }
})
  }

  useEffect(() => {
    getKpiLimits();
  },[])

  return (
    <Drawer
      title="Settings"
      placement="right"
      onClose={() => props.setVisible(false)}
      visible={props.visible}
    >

      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Keys to Key Days</label>
        <Input type="number" min={0} max={30} value={limits.KeyToKeyDays} onChange={e => handleLimitChange('KeyToKeyDays', e.target.value)} placeholder="Set Key to Key Days Limit" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Lead Time</label>
        <Input type="number" min={0} max={100} value={limits.LeadTime} onChange={e => handleLimitChange('LeadTime', e.target.value)} placeholder="Set Lead Time Limit" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Idle Time</label>
        <Input type="number" min={0} max={100}  value={limits.IdleTime} onChange={e => handleLimitChange('IdleTime', e.target.value)} placeholder="Set Idle Time Limit" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Productivity Paint</label>
        <Input type="number" min={1} max={10}  value={limits.ProductivityPaint} onChange={e => handleLimitChange('ProductivityPaint', e.target.value)} placeholder="Set limit for Productivity Paint" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Productivity Panel</label>
        <Input type="number" min={1} max={10}  value={limits.ProductivityPanel} onChange={e => handleLimitChange('ProductivityPanel', e.target.value)} placeholder="Set limit for Productivity Panel" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Productivity Total</label>
        <Input type="number" min={1} max={10}  value={limits.ProductivityTotal} onChange={e => handleLimitChange('ProductivityTotal', e.target.value)} placeholder="Set limit for Productivity Total" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>WeekDay Customer Dropoff</label>
        <Input type="number" min={1} max={10}  value={limits.WeekdayCustomerDropoff} onChange={e => handleLimitChange('WeekdayCustomerDropoff', e.target.value)} placeholder="Set limit for WeekDay Customer Dropoff" />
      </div>

      <Button onClick={handleSave} className="btn-primary">Set Limits</Button>
    </Drawer>
  );
};

export default KPIDrawerSettings;
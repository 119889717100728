import { useState, useEffect } from 'react'
import { Select } from 'antd'
import { useIntl, FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import axios from 'axios';
const clm = require('country-locale-map');


const UserLocale = (props) => {
    console.log("User Locale Props")
    console.log(props)
    const [loading, setLoading] = useState(false)
    const intl = useIntl();


    const saveAllLocale = async () => {
        setLoading(false);
        const localesList = clm.getAllCountries();
        localesList.map(async item => {
            let body = {
                localeObj: {
                    country: item.name,
                    locale: item.default_locale,
                }
            };
            await axios.post("/api/addlocale", body, config).then(response => {
                console.log("Locale API RESPONSE")
                console.log(response)
            })
        })
    }



    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };
    const handleChange = (value) => {
        let body = {
            userid: props.userID,
            locale: value
        }
        setLoading(true);
        axios.post(`/api/setUserLocale`, body, config).then((result) => {
            if (result.data === "success") {
                toast.success(intl.formatMessage({ id: "LocaleUpdated", defaultMessage: "Locale Updated!" }), {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                window.location.reload(false);
            }
        })
        setLoading(false);
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };


    return (
        <>
            <Select
                showSearch
                defaultValue={props.standardLocale}
                placeholder={intl.formatMessage({ id: "SelectLocale", defaultMessage: "Select Locale" })}
                onChange={handleChange}
                style={{ width: '100%' }}
                optionFilterProp="children"
                options={props.options}
                loading={loading}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </>
    )
}

export default UserLocale
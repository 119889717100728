import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper";
import { Spin } from 'antd';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import axios from 'axios';

const ShowModalPictures = (props) => {
  console.log("ShowModalPictures")
  console.log(props)
  const [bigImgPreview, setBigImgPreview] = useState(props.CurrentImg[0].props?.src ? props.CurrentImg[0].props?.src : props.CurrentImg[0])
  const [imgLoading, setImgLoading] = useState(false)
  /* close function */
  const close = () => {
    props.setModalImage(false)
  }
  /* This function is called when a thumbnail is clicked */
  const onchange = (imageID, value) => {

    setImgLoading(true)
    if (value > (props.Pictures.length - 1)) {
      return
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    if (props.noTeamwork && imageID.toString().length > 7) {
      setBigImgPreview(imageID)
      setTimeout(() => {
        setImgLoading(false)
      }, 500)
      return
    }else{
    try {
      axios.get(`/api/private/files/${imageID}`, config).then(res => {
        setTimeout(() => {
          setImgLoading(false)
        }, 500)
        setBigImgPreview(res.data.file["download-URL"])

      })
    } catch (e) {
      if (!props.dev) {
        localStorage.removeItem("authToken");
        window.location.href = '/logout';
      }
    }
  }
  };


  return (
    <>
      <div id="picture-models" className="fq-modal-1 d-flex flex-column">
        <div className="fq-modal-header-pics">
          <span onClick={() => close()} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
        </div>
        <div className={`img-bigger-size ${imgLoading ? "d-flex justify-content-center align-items-center" : ""}`}>
          {imgLoading ? <div className='img-loader'><Spin tip="Loading" size="large"  /> </div>:
            <img src={bigImgPreview ? bigImgPreview : '/images/img-placeholder.svg'} alt="img" />
          }
        </div>
        <Swiper
          spaceBetween={20}
          slidesPerView={8}
          navigation={true}
          modules={[Navigation, FreeMode]}
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 8,
              spaceBetween: 20,
            },
            1920: {
              slidesPerView: 12,
              spaceBetween: 20,
            }
          }}
          className="mySwiper popup-thumbnail-slider">
          {props.Pictures.map((item, Index) => {
            if(item && item.length ===  2 && item[1] !==  null){
              return (
                <SwiperSlide id={Date.now() + Index}>
                  <img className="img-thumbnail cursor-pointer if-p" src={item[1]} alt="img" onClick={() => onchange(item[0], Index)} picid={item} />
                </SwiperSlide>
              )
            }
            else if(item[1] !==  null){
            return (
              <SwiperSlide id={Date.now() + Index}>
                <img className="img-thumbnail cursor-pointer else-p" src={item[0]} alt="img" onClick={() => onchange(item[0], Index)} picid={item[0]} />
              </SwiperSlide>
            )
            }
          })}
        </Swiper>
      </div>
    </>
  );
}


export default ShowModalPictures;
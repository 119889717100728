import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import round from "../sharedFunctions.js/round";
import Progressbar from "./progressbar";
import Owldemo4 from "./reactcarousel4";
import openTaskList from "../sharedFunctions.js/openTaskList";
import axios from "axios";
import SpinnerMini from "./spinnermini";
import "chartjs-adapter-date-fns";
import { Spin } from 'antd';
import { FormattedMessage } from "react-intl";
import checkPercentage from '../sharedFunctions.js/checkpercentage'

const ShowToolBox = (props) => {
  const [WaitingForResponse, SetWaitingForResponse] = useState(false);
  const [AddableRepair, SetAddableRepair] = useState(true);
  const [Deleteable, SetDeleteable] = useState(true);

  let history = useHistory();
  const location = useLocation();

  const openModalPics = (pictures, Index) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    if (props.noTeamwork) {
      let fullImg = (
        <img
          style={{ maxWidth: "90vw", maxHeight: "60vh" }}
          className="ImgModalPic"
          src={pictures[Index][0]}
        />
      );
      props.setCurrentImg([fullImg, Index]);
      props.setModalImage(pictures);
      return;
    }
    let Img = pictures[Index][0];

    try {
      axios.get(`/api/private/files/${Img}`, config).then((res) => {
        const image = res.data.file["download-URL"];
        let fullImg = <img className="ImgModalPic" src={image} />;

        props.setCurrentImg([fullImg, Index]);
        props.setModalImage(pictures);
      });
    } catch (e) {
      if (!props.dev) {
        localStorage.removeItem("authToken");
        window.location.href = "/logout";
      }
    }
  };

  const handleClick = (name, whichArray) => {
    if (!props.noTeamwork) {
      SetDeleteable(false);
    }

    if (props.noTeamwork) {
      SetAddableRepair(false);
      SetDeleteable(false);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    console.log(props.FinalArray[props.ToolBox][0]["FatherNumber"]);
    let lastBayIndex =
      props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]][2]
        .length - 1;
    let lastBayName =
      props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]][2][
      lastBayIndex
      ];
    let NewBayNumber = lastBayName;
    NewBayNumber = NewBayNumber.split(".");
    console.log(props);
    console.log(
      props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]]
    );
    let PostData = {
      baytype: name,
      parentTaskID:
        props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]][0],
      currentTaskID:
        props.tasks[
        props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]][2][0]
        ][0],
      parentTaskKey: props.FinalArray[props.ToolBox][0]["FatherNumber"],
      keyOfTask:
        props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]][2][0],
      projectID: props.idd,
      tasklistNumber: props.TasklistNumber,
    };
    console.log(PostData);
    async function send() {
      try {
        const data = await axios.post(
          `/api/private/queue/baycreate`,
          PostData,
          config
        );
        console.log(data.data);

        if (data.data.tasklist) {
          if (props.noTeamwork) {
            SetAddableRepair(true);
            SetDeleteable(true);
          }
          console.log(data.data);
          props.setTasks((prevState) => data.data.tasklist);
        } else {
          props.setTasks((prevState) => ({
            ...prevState,
            [props.FinalArray[props.ToolBox][0]["FatherNumber"]]: newArr,
            [NewBayNumber]: [
              false,
              false,
              [NewBayNumber + ".1"],
              NewBayNumber,
              false,
              false,
              0,
              name,
              null,
              null,
              null,
              null,
            ],
            [NewBayNumber + ".1"]: [
              false,
              false,
              false,
              NewBayNumber + ".1",
              false,
              false,
              0,
              "Loading",
              null,
              null,
              null,
              null,
            ],

          }));
        }
        SetWaitingForResponse(false);
        return data;
      } catch (e) {
        console.log(e);
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    }

    let NewIndex = NewBayNumber[NewBayNumber.length - 1];
    NewIndex = parseInt(NewIndex) + 1;
    NewBayNumber[NewBayNumber.length - 1] = NewIndex;
    NewBayNumber = NewBayNumber.join(".");

    let newArr =
      props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]];
    newArr[2] = [
      ...props.tasks[props.FinalArray[props.ToolBox][0]["FatherNumber"]][2],
      NewBayNumber,
    ];

    send().then((item) => { });
  };

  const handleDeleteRepair = (name, ArrayNumber) => {
    if (!props.noTeamwork) {
      SetAddableRepair(false);

    }
    if (props.noTeamwork) {
      SetAddableRepair(false);
      SetDeleteable(false);
    }
    console.log(name);
    console.log(name["ArrayNumber"]);
    let isExecuted = window.confirm("Are you sure you want to delete?");
    if (!isExecuted) {
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    let currentTaskID = props.tasks[name["ArrayNumber"]][0];
    console.log(currentTaskID);
    let PostData = {
      currentTaskID: props.tasks[name["ArrayNumber"]][0],
      keyOfTask: {
        ArrayNumber: name["ArrayNumber"],
      },
      projectID: props.idd,
      // TaskID :
    };
    //    console.log(PostData)
    async function send() {
      try {
        const data = await axios.put(
          `/api/private/queue/repairdelete/${currentTaskID}`,
          PostData,
          config
        );
        //          console.log(data.data)
        SetWaitingForResponse(false);
        if (data.data.tasklist) {
          if (props.noTeamwork) {
            SetAddableRepair(true);
            SetDeleteable(true);
          }
          console.log(data.data);
          props.setTasks((prevState) => data.data.tasklist);
        } else {
          let Arr = props.tasks[name["FatherNumber"]];
          console.log(Arr);
          console.log(name["ArrayNumber"]);
          const index = Arr[2].indexOf(name["ArrayNumber"]);
          if (index > -1) {
            Arr[2].splice(index, 1);
          }
          console.log(Arr);
          const filtered = Object.keys(props.tasks);
          let AllChildren = filtered.filter((e) => {
            return e.startsWith(name["ArrayNumber"]);
          });
          console.log(AllChildren);

          props.setTasks((prevState) => {
            let newData = { ...prevState };
            newData[name["ArrayNumber"]] = Arr;
            AllChildren.map((item) => {
              delete newData[item];
            });
            return newData;
          });
        }
        return data;
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    }

    send().then((newObj) => { });
  };

  if (props.FinalArray[props.ToolBox]) {
    return (
      <>

          {(props.FinalArray[props.ToolBox].slice(1).length >= (props.ToolBox === "ToolBoxBody" ? (props.FinalArray[props.ToolBox].slice(1).length + 1) : 0)) ?
            <>
            <div id="technical-tool-box">
              <div className="row mg-t-20 mg-b-30 mg-t-50">
                <div className="col-lg-12 pl-0">
                  <h1 className="main-heading d-flex align-items-center">
                    <FormattedMessage
                      id="TechnicanToolBoxes"
                      defaultMessage="Technican Tool Boxes"
                    />
                    {props.canEdit && !WaitingForResponse && AddableRepair ? (
                      <div>
                        <i
                          onClick={() =>
                            handleClick(
                              props.ToolBox,
                              props.FinalArray["GroupedbyDate"][0][props.ToolBox]
                            )
                          }
                          style={{
                            fontSize: "30px",
                            marginLeft: "20px",
                            cursor: "pointer",
                          }}
                          className="fas fa-plus-circle"
                        ></i>
                      </div>
                    ) : (
                      <div>
                        <SpinnerMini />
                      </div>
                    )}
                  </h1>
                </div>
              </div>

              <div className="container-fluid mb-t-20 ">
                <div className="row mobile-scroll-y">
                  {(props.FinalArray[props.ToolBox].slice(1).length >= 1) ? (props.FinalArray[props.ToolBox].slice(1).map((Item, Index) => {
                    let check = false;

                    if (Item.Tasks.length === 0 || Item.Tasks[Item.Tasks.length - 1].startsWith(Item.ArrayNumber)) {
                      check = true;
                    }

                    if (Item.total === 0) {
                      check = true;
                    }
                    return (
                      <>
                        {check ? (
                          <>
                            <div
                              style={{
                                flexDirection: "column",
                                position: "relative",
                              }}
                              className="min-h img-text-block d-flex"
                            >
                              <Owldemo4
                                noTeamwork={props.noTeamwork}
                                setCurrentImg={props.setCurrentImg}
                                setModalImage={props.setModalImage}
                                Pictures={Item.pictures}
                              />
                              <Progressbar width={Item["Result"]} />
                              {props.canEdit &&
                                !WaitingForResponse &&
                                Deleteable ? (
                                <div
                                  className="delete-icon-wrapper-toolbox"
                                  onClick={() => handleDeleteRepair(Item)}
                                >
                                  <i className="fas fa-trash"></i>
                                </div>
                              ) : <div className="delete-icon-wrapper-toolbox">
                                <Spin />
                              </div>}

                              <div className="content">
                                <h5>Tool Box {Index + 1}</h5>
                                <p className="mg-b-0 small-text">
                                  {Item.complete}/{Item.total} Tasks completed
                                </p>
                                <div className="stats">{checkPercentage(Item["Result"])}%</div>
                                <div className="d-flex mg-t-20">
                                  <div
                                    style={{ padding: "8px 0px", margin: "0" }}
                                    onClick={() => {
                                      openTaskList(
                                        Item["Tasks"],
                                        props.setScrollPos,
                                        props.passTaskListComp,
                                        props.passResult,
                                        round(
                                          (Item["WeightComplete"] /
                                            Item["TotalWeight"]) *
                                          100
                                        ),
                                        Item,
                                        "Repair Check",
                                        props.ToolBox,
                                      )
                                      history.push(`${location.pathname}/${props.ToolBox}/${Index + 1}/tasks`, { from: "collapsible" });
                                    }}
                                    className="cta-link fq-popup"
                                    data-popup-id="popup-1"
                                  >
                                    <FormattedMessage
                                      id="ShowTasks"
                                      defaultMessage="Show Tasks"
                                    />
                                  </div>
                                </div>{" "}
                              </div>
                              <div
                                style={{ marginTop: "auto", marginLeft: "1rem" }}
                                className="d-flex mg-t-20"
                              ></div>
                            </div>
                          </>

                        ) : (

                          <div
                            style={{
                              position: "relative",
                              flexDirection: "column",
                            }}
                            className="min-h img-text-block d-flex"
                          >

                            {props.canEdit &&
                              !WaitingForResponse && Deleteable ? (
                              <div
                                className="delete-icon-wrapper-toolbox"
                                onClick={() => handleDeleteRepair(Item)}
                              >
                                <i className="fas fa-trash"></i>
                              </div>
                            ) : <div
                              className="delete-icon-wrapper-toolbox"><Spin /></div>}
                            <div className="bg-not-available"> </div>
                            <div className="centered-text-overlay-bay">
                              <FormattedMessage
                                id="NotAvailable"
                                defaultMessage="Not Available"
                              />
                            </div>
                            <Owldemo4
                              noTeamwork={props.noTeamwork}
                              setCurrentImg={props.setCurrentImg}
                              setModalImage={props.setModalImage}
                              Pictures={Item.pictures}
                            />
                            <div className="content">
                              <h5>Tool Box {Index + 1}</h5>
                              <p className="mg-b-0 small-text"> </p>
                              <div className="stats">{checkPercentage(Item["Result"])} %</div>
                              <div className="d-flex mg-t-20">
                                <div
                                  style={{ padding: "8px 0px", margin: "0" }}
                                  onClick={() => {
                                    openTaskList(
                                      Item["Tasks"],
                                      props.setScrollPos,
                                      props.passTaskListComp,
                                      props.passResult,
                                      round(
                                        (Item["WeightComplete"] /
                                          Item["TotalWeight"]) *
                                        100
                                      ),
                                      Item,
                                      "Repair Check",
                                      props.ToolBox,
                                    )
                                    history.push(`${location.pathname}/${props.Toolbox}/${Index + 1}/tasks`, { from: "collapsible" });
                                  }}
                                  className="cta-link fq-popup"
                                  data-popup-id="popup-1"
                                >
                                  <FormattedMessage
                                    id="ShowTasks"
                                    defaultMessage="Show Tasks"
                                  />
                                </div>
                              </div>{" "}
                            </div>

                          </div>
                        )}
                      </>
                    );

                  })) : (
                    <>
                      <div className="container-fluid pl-0 empty-container" >
                        <FormattedMessage id="NoTechnicanToolBoxes" defaultMessage="No Technican Tool Boxes" />

                      </div>
                    </>
                  )}
                </div>
              </div>
              </div>
            </>

            : ""}

      </>
    );
  } else {
    return <></>;
  }
};

export default ShowToolBox;

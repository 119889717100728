import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import { useIntl, FormattedMessage } from 'react-intl';

import axios from 'axios';

const WeightModalElements = (props) => {
    const [Weight, setWeight] = useState(1)
    const [ready, setReady] = useState(true)
    const [cantBeSaved, setCantBeSaved] = useState(false)

    useEffect(() => {
        if (props.onload || props.onload == 0) {
            //   console.log(props.someProps)
            console.log(props.onload == 0)
            console.log(parseInt(props.onload))
            setWeight(props.onload)
        }
    }, [])

    const GetCodeRename = (name) => {
        name = name.split(' ')
        name = name[0];
        return name
      }
    
    
     const Rename = (name) => {
        name = name.split(' ')
        name.shift()
        name = name.join(' ')
        return name
      }


    const handleOnChangeWeight = (e) => {
        console.log(e.target.value)
        console.log(e.target.value === "")
        console.log(parseInt(e.target.value) === 0)
        console.log(parseInt(e.target.value) == 0)
        //console.log(parseInt(e.target.value))


        if (Number(e.target.value) || e.target.value == "" || e.target.value == 0) {
            props.setSaved(false)

            console.log(props)
            console.log(e.target.value)
            props.setWeight(prevState => ({
                ...prevState,
                [props.somekey]: parseFloat(e.target.value)
            }));
            setWeight(e.target.value)
            console.log(props.Weight)

            if (Number(e.target.value)) {
                props.setWeight(prevState => ({
                    ...prevState,
                    [props.somekey]: parseFloat(e.target.value)
                }));
                setWeight(e.target.value)
            } else {
                props.setWeight(prevState => ({
                    ...prevState,
                    [props.somekey]: parseInt(0)
                }));
                // setWeight(parseInt(0))
                setWeight(e.target.value)
            }


        } /* else if (e.target.value == "") {
            props.setSaved(false)
            props.setWeight(prevState => ({
                ...prevState,
                [props.somekey]: parseFloat(e.target.value)
            }));
            setWeight(e.target.value)
            console.log(props.Weight)
        }
       */

    }
    /* console.log(props) */

    if (ready && !props.item[2]) {
        return (

            <>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                }} className="mb-1 mt-1">


                    <div style={{ display: "flex", alignItems: "center" }} className="col-8">
                    {GetCodeRename(props.item[3])}&nbsp;<FormattedMessage id={Rename(props.item[3])} defaultMessage={Rename(props.item[3])} />
                        </div>

                    <div style={{ display: "flex", alignItems: "center" }} className="col-1">
                        <input
                            style={{ width: "100%" }}
                            type="text" pattern="[0-9]*"

                            id={`custom-text-${props.index}`}

                            value={Weight}
                            minLength={1} maxLength={3}
                            onChange={(e) => handleOnChangeWeight(e)}
                        />
                    </div>


                </div>

            </>

        )
    } else {
        return (
            ""
        )
    }



}
export default WeightModalElements

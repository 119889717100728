import { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import round from "../sharedFunctions.js/round";
import roundTwo from "../sharedFunctions.js/roundTwo";
import { Button, message, Upload } from "antd";

// Components Import
import ShowRepairQuality from "./showSingleProjectRepairQuality";
import ShowTasksCardPicture from "./ShowTasksCardPicture";
import ShowTopCardUnavailable from "./ShowTopCardUnavailable";
import CheckExistComment from "../sharedFunctions.js/existsComment";
import PieChart from "./ProjectElementPie";
import PieChart2 from "./ProjectElementPie2";
import HistoryChart from "./history";
import openTaskList from "../sharedFunctions.js/openTaskList";
import Spinner from "./spinner";
import Progressbar from "./progressbar";
import ProgressPill from "./ProjectPill";
import axios from "axios";
import TextPlusMinusModule from "./TextPlusMinusModule";
import TextModule from "./TextModule";
import AddPictureModule from "./AddPictureModule";
import { FormattedMessage, useIntl } from "react-intl";
import Footer from "./footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import { CompassOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Input, List, InputRef, Modal } from "antd";
import { SaveTwoTone } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import checkPercentage from "../sharedFunctions.js/checkpercentage";
import MilestoneTitle from "./MilestoneTitle";
import KPIDashboard from "./KPIDashboard/v2/KPIDashboard-Project";
import { Progress } from 'antd';

import "antd/dist/antd.css";

const ShowSingleProjectSlides = (props) => {
  console.log("ShowSingleProjectSlides Props")
  console.log(props)
  const intl = useIntl();
  const [Editing, SetEditing] = useState(false);
  const [tabName, SetTabName] = useState("TotalAuditScore");
  const [TextModuleState, SetTextModuleState] = useState(false);
  //State for Collapsible Card
  const [cardCollapseFlag, setcardCollapseFlag] = useState(false);
  const [cardCollapseFlaggraph, setcardCollapseFlaggraph] = useState(false);
  const [cardCollapseNotes, setcardCollapseNotes] = useState(false);
  const [cardCollapseAdministration, setcardCollapseAdministration] = useState(false);
  const [cardCollapseFacilityCap, setcardCollapseFacilityCap] = useState(false);
  const [convertedText, setConvertedText] = useState("Some default content");
  const [valueAddress, setValueAddress] = useState(
    props.projectAddress.address.Street
  );
  const [projectAddress, setProjectAddress] = useState(false);
  const [valueProjectName, setProjectName] = useState(
    props.projectAddress.projectName
  );
  const [projectNameCheck, setProjectNameCheck] = useState(false);
  const [checkLoading, setCheckLoading] = useState(true);
  const [projectImageURL, SetProjectImageURL] = useState("");
  const [isProjectPictureModalOpen, setIsProjectPictureModalOpen] =
    useState(false);
  const [checkEditProject, setCheckEditProject] = useState(false);
  const [userNames, setUserNames] = useState("");
  const SetTabFunction = (name) => {
    SetTabName(name);
  };

  let history = useHistory();
  const location = useLocation();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyC1HdsZ1rBMKZ_jHRjYMtO0rX51rBDZ93Y",
    options: { types: ["geocode", "establishment"] },
  });

  const getProjectPicture = () => {
    axios.get(`/api/getprojectpicture/${props.idd}`, config).then((result) => {
      if (result) {
        SetProjectImageURL(result.data[0].picture);
        console.log("Project Picture");
        console.log(result);
      }
    });
  };

  const saveNewAddress = () => {
    console.log(valueAddress);
    if (props.projectAddress.address.Street !== valueAddress) {
      axios
        .post(
          `/api/savenewaddress/${props.idd}`,
          { addressupdated: valueAddress },
          config
        )
        .then((result) => {
          if (result)
            toast.success("Address Successfully updated", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          setTimeout(() => {
            window.location.reload(false);
          }, 6000);
        });
    } else {
      toast.error("Change Address to update", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  useEffect(() => {
    let rights = JSON.parse(atob(localStorage.getItem("rights")));
    console.log("rights:");
    console.log(rights);
    if (
      rights.EditProjects.includes("all") ||
      rights.EditProjects.includes(parseInt(props.idd))
    ) {
      setCheckEditProject(true);
    }
    console.log("checkEdirProjectRights: " + checkEditProject);

    getProjectPicture();
    if (checkLoading === true) {
      updateScores();
    }

    const req = axios.get(`/api/private/userNames`, config).then((response) => {
      let usernamesarr = [];
      response.data.forEach((item) => {
        const editRights =
          item.rights.EditProjects.find((item) => item == props.idd) ||
          item.rights.EditProjects == "all"
            ? true
            : false;
        const seeRights =
          item.rights.SeeProjects.find((item) => item == props.idd) ||
          item.rights.SeeProjects == "all"
            ? true
            : false;
        if (editRights && seeRights) {
          let obj = {};
          obj.value = item.username;
          obj.label = item.username;
          obj.id = item._id;
          usernamesarr.push(obj);
        }
      });

      setUserNames(usernamesarr);
    });

  }, []);

  const updateScores = () => {
    setCheckLoading(false);
    axios
      .get(`/api/updateProjectScores/${props.idd}`, config)
      .then((result) => {
        console.log("UpdateProjectScore Response");
        console.log(result.data);
      });
  };

  const saveNewProjectName = () => {
    console.log(valueProjectName);
    if (props.projectAddress.projectName !== valueProjectName) {
      axios
        .post(
          `/api/savenewprojectname/${props.idd}`,
          { projectnameupdated: valueProjectName },
          config
        )
        .then((result) => {
          if (result)
            toast.success("Project Name Successfully updated", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          setTimeout(() => {
            window.location.reload(false);
          }, 6000);
        });
    } else {
      toast.error("Change Address to update", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  let CompanyPicture = CheckExistComment(
    props.tasks[props.translation["CompanyPicture"]],
    5
  );
  const changeEditing = () => {
    SetEditing((prevState) => !prevState);
  };
  const changeAddressStatus = () => {
    console.log("clciked");
    setProjectAddress((prevState) => !prevState);
  };
  const changeProjectNameStatus = () => {
    console.log("clciked");
    setProjectNameCheck((prevState) => !prevState);
  };

  const ModalProjectPictureOpen = () => {
    setIsProjectPictureModalOpen(true);
  };

  const handleProjectPictureCancel = () => {
    setIsProjectPictureModalOpen(false);
  };
  const openModal = (e, b, allowed) => {
    props.setOnlyMilestone("false");
    props.setMilestoneTranslation([]);
    props.setTranslationModal(e);
    if (allowed) {
      props.SetTranslationOnlyAllow(allowed);
    }
  };

  const date = (e) => {
    if (e) {
      let s = e.replace(/(\d{4})(\d{2})(\d{2})/g, "$3/$2/$1");
      return s;
    }
  };

  const currencyNumberFormat = (value) => {
    if(value === undefined || value === null){
      value = 0
    }

    console.log(`currencyNumberFormat: ${props.currencyLocale}`);
  return new Intl.NumberFormat(props.userLocale, {
    style: 'currency',
    currency: props.currencyName,
    currencyDisplay: "symbol"
  }).format(value);
}
  const openSite = (e) => {
    function scroll() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      props.SetOpenPage(e);
    }
    props.SetOpenPage("");
    setTimeout(scroll, 50);
    history.push(`${location.pathname}/${e}`);
  };
  let list = [];
  const filtered = Object.keys(props.tasks)
    .filter((key) => /\./.test(key))
    .reduce((acc, key) => {
      if (!props.tasks[key][1]) {
        list.push([
          props.tasks[key][3],
          props.tasks[key][2],
          props.tasks[key][0],
          props.tasks[key][5],
          props.tasks[key][7],
          props.tasks[key][1],
          props.tasks[key][8],
          props.tasks[key][9],
        ]);
      }
      return acc;
    }, {});
  let bodyShopTotal =
    parseFloat(
      props.FinalArray["BodyShopForemanResult"]
        ? props.FinalArray["BodyShopForemanResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["RemoveRefitTechnicianResult"]
        ? props.FinalArray["RemoveRefitTechnicianResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["BodyShopTechnicianResult"]
        ? props.FinalArray["BodyShopTechnicianResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["MechanicTechniciansResult"]
        ? props.FinalArray["MechanicTechniciansResult"]
        : 0
    ) +
    parseFloat(
      (props.FinalArray["BodyShopApprenticeResult"]
        ? props.FinalArray["BodyShopApprenticeResult"]
        : 0) / 2
    );
  let PaintShopTotal =
    parseFloat(
      props.FinalArray["PaintShopForemanResult"]
        ? props.FinalArray["PaintShopForemanResult"]
        : 0
    ) /
      2 +
    parseFloat(
      props.FinalArray["PaintPreparationTechniciansResult"]
        ? props.FinalArray["PaintPreparationTechniciansResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["PaintShopTechnicianApplicationResult"]
        ? props.FinalArray["PaintShopTechnicianApplicationResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["PaintShopTechnicianColourMatchResult"]
        ? props.FinalArray["PaintShopTechnicianColourMatchResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["DetailersResult"]
        ? props.FinalArray["DetailersResult"]
        : 0
    ) +
    parseFloat(
      (props.FinalArray["PaintShopApprenticeResult"]
        ? props.FinalArray["PaintShopApprenticeResult"]
        : 0) / 2
    );
  let AdministrationStaff =
    parseFloat(
      props.FinalArray["ManagerResult"] ? props.FinalArray["ManagerResult"] : 0
    ) +
    parseFloat(
      props.FinalArray["OfficeManagerResult"]
        ? props.FinalArray["OfficeManagerResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["ReceptionResult"]
        ? props.FinalArray["ReceptionResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["EstimatorResult"]
        ? props.FinalArray["EstimatorResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["ProductionManagerResult"]
        ? props.FinalArray["ProductionManagerResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["Parts ManagerResult"]
        ? props.FinalArray["Parts ManagerResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["DetailerResult"]
        ? props.FinalArray["DetailerResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["TotalAdministrationStaff"]
        ? props.FinalArray["TotalAdministrationStaff"]
        : 0
    );
  let ProductiveStaff =
    parseFloat(
      props.FinalArray["BodyShopForemanResult"]
        ? props.FinalArray["BodyShopForemanResult"]
        : 0
    ) /
      2 +
    parseFloat(
      props.FinalArray["RemoveRefitTechnicianResult"]
        ? props.FinalArray["RemoveRefitTechnicianResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["BodyShopTechnicianResult"]
        ? props.FinalArray["BodyShopTechnicianResult"]
        : 0
    ) +
    parseFloat(
      props.FinalArray["MechanicTechniciansResult"]
        ? props.FinalArray["MechanicTechniciansResult"]
        : 0
    ) +
    parseFloat(
      (props.FinalArray["BodyShopApprenticeResult"]
        ? props.FinalArray["BodyShopApprenticeResult"]
        : 0) / 2
    ) +
    (parseFloat(
      props.FinalArray["PaintShopForemanResult"]
        ? props.FinalArray["PaintShopForemanResult"]
        : 0
    ) /
      2 +
      parseFloat(
        props.FinalArray["PaintPreparationTechniciansResult"]
          ? props.FinalArray["PaintPreparationTechniciansResult"]
          : 0
      ) +
      parseFloat(
        props.FinalArray["PaintShopTechnicianApplicationResult"]
          ? props.FinalArray["PaintShopTechnicianApplicationResult"]
          : 0
      ) +
      parseFloat(
        props.FinalArray["PaintShopTechnicianColourMatchResult"]
          ? props.FinalArray["PaintShopTechnicianColourMatchResult"]
          : 0
      ) +
      parseFloat(
        props.FinalArray["PolisherResult"]
          ? props.FinalArray["PolisherResult"]
          : 0
      ) +
      parseFloat(
        (props.FinalArray["PaintShopApprenticeResult"]
          ? props.FinalArray["PaintShopApprenticeResult"]
          : 0) / 2
      ));

  const getRatioClass = (a, b, value) => {
    let result = 0.0;
    if (a > b) {
      result = a / b;
      if (value === "productive" && parseFloat(result).toFixed(1) > 2.2) {
        return false;
      } else if (value === "bodyshop" && parseFloat(result).toFixed(1) > 2.6) {
        return false;
      }
    } else {
      result = b / a;
      if (value === "productive" && parseFloat(result).toFixed(1) > 2.2) {
        return true;
      } else if (value === "bodyshop" && parseFloat(result).toFixed(1) > 2.6) {
        return true;
      }
    }
  };
  const getRatio = (a, b) => {
    let bigger;
    let smaller;
    if (a > b) {
      if (a == b) {
        return 1;
      }
      bigger = a;
      smaller = b;
      let result = bigger / smaller;
      if (!Number.isFinite(result)) {
        return 0;
      } else {
        return round(result) + ":1";
      }
    } else {
      bigger = b;
      smaller = a;
      let result = bigger / smaller;
      if (!Number.isFinite(result)) {
        return 0;
      } else {
        return "1:" + round(result);
      }
    }
  };
  let businessAnalyticsarr = [
    "Manager",
    "Office Manager",
    "Reception",
    "Estimator",
    "Production Manager",
    "Parts Manager",
    "Detailer",
  ];
  let productionStaffArr = [
    "Body Shop Foreman (0.5)",
    "Remove + Refit Technician",
    "Body Shop Technician",
    "Body Shop Apprentice (0.5)",
    "Mechanic Technicians",
    "Paint Shop Foreman (0.5)",
    "Paint Preparation Technicians",
    "Paint Shop Technician – Application",
    "Paint Shop Technician – Colour Match",
    "Paint Shop Apprentice (0.5)",
    "Polisher",
  ];
  let ratioTwo = parseFloat(AdministrationStaff / ProductiveStaff).toFixed(1);
  console.log("Ration Two");
  console.log(ratioTwo);
  let ratioThree = parseFloat(bodyShopTotal / PaintShopTotal).toFixed(1);
  console.log(ratioThree);
  let totaratio = getRatio(AdministrationStaff, ProductiveStaff);
  let ratioOne = getRatio(bodyShopTotal, PaintShopTotal);
  let score =
    props.FinalArray["TotalAuditScore"] &&
    props.FinalArray["TotalAuditScore"].WeightComplete &&
    props.FinalArray["TotalAuditScore"].TotalWeight
      ? round(
          (props.FinalArray["TotalAuditScore"].WeightComplete /
            props.FinalArray["TotalAuditScore"].TotalWeight) *
            100
        )
      : 0;
  var currentTime = new Date().getTime() / 1000;
  const late = (e) => {
    if (!e) {
      return;
    }
    var b = [e.slice(0, 4), "-", e.slice(4, 6), "-", e.slice(6, 8)].join("");
    let datefromMilestone = new Date(b).getTime() / 1000;
    if (currentTime > datefromMilestone) {
      return true;
    } else {
      return false;
    }
  };

  const getClassName = (e) => {
    if (!props.FinalArray[e] || props.FinalArray[e].total == 0) {
      return "circle-each bg-grey";
    }
    if (props.FinalArray[e] == 100) {
      return "circle-each bg-green";
    } else if (props.FinalArray[e] > 85) {
      return "circle-each bg-green";
    } else if (props.FinalArray[e] > 50) {
      return "circle-each bg-yellow";
    } else {
      return "circle-each bg-red";
    }
  };
  const editMilestone = (item) => {
    props.setMilestonesComp(item);
    props.setEditMilestone("true");
  };

  const viewNote = (item) => {
    props.setNotesComp(item);
    props.setViewNote(true);
  };

  const seeNoteRights = (item) => {
    return item.Private.find((item) => item.value === props.Username) || userNames.length > 5 && userNames.length === (item.Private.length + 1 || item.Private.length)
  };

  const openTasklistMilestones = (execute, item, index) => {
    if (!execute) {
      return;
    }
    openTaskList(
      props.MilestoneResultState[index]["Tasks"],
      props.setScrollPos,
      props.passTaskListComp,
      props.passResult,
      props.MilestoneResultState[index]["Result"],
      props.MilestoneResultState[index],
      item["Title"],
      "MilestoneTasklist",
      "Milestone"
    );
    history.push(`${location.pathname}/MilestoneTasklist/${index + 1}/tasks`, { from: "collapsible" });
  };

  const cutString = (item, maxChar) => {
    return item.length > maxChar ? item.substring(0, maxChar) + "..." : item;
  };

  const findUsername = (userID) => {
    let user = "";
    if (userNames) {
      user = userNames.find((username) => username.id === userID);
    }
    return user ? user.value : "";
  };

  let allUncompletedMilestoneTasks = 0;
  let allCompletedMilestoneTasks = 0;
  if (props.MilestoneResultState) {
    for (let Task of props.MilestoneResultState) {
      allUncompletedMilestoneTasks += Task.complete;
      allCompletedMilestoneTasks += Task.total;
    }
  }

  const NewestRepairQualityArray =
    props.FinalArray["GroupedbyDate"][
      props.FinalArray["GroupedbyDate"].length - 1
    ];
  let NewestRepairQualityTasks = props.FinalArray["GroupedbyDate"][
    props.FinalArray["GroupedbyDate"].length - 1
  ].Repairs.map((a) => a.Tasks);
  let MergedRepairQualityTasks = [];
  for (let i = 0; i < NewestRepairQualityTasks.length; i++) {
    MergedRepairQualityTasks.push(...NewestRepairQualityTasks[i]);
  }

  let ArrayOfPeople = [
    "BodyShopForeman",
    "RemoveRefitTechnician",
    "BodyShopTechnician",
    "BodyShopApprentice",
    "MechanicTechnicians",
    "PaintShopForeman",
    "PaintPreparationTechnicians",
    "PaintShopTechnicianApplication",
    "PaintShopTechnicianColourMatch",
    "PaintShopApprentice",
    "Polisher",
  ];
  let ArrayOfPeopleLeft = [
    "Manager",
    "OfficeManager",
    "Reception",
    "Estimator",
    "ProductionManager",
    "Parts Manager",
    "Detailer",
  ];
  let DaysOfWeekArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let DaysofWeekArrayAlias = ["M", "T", "W", "Th", "F", "S", "S"];
  console.log(props);

  props.FinalArray["ProjectedWeeklyJobsCalcSpecialOneResult"] &&
    console.log(props.FinalArray["ProjectedWeeklyJobsCalcSpecialOneResult"]);
  props.FinalArray["ProjectedWeeklyJobsCalcSpecialTwoResult"] &&
    console.log(props.FinalArray["ProjectedWeeklyJobsCalcSpecialTwoResult"]);
  props.FinalArray["ProjectedWeeklyJobsCalcSpecialThreeResult"] &&
    console.log(props.FinalArray["ProjectedWeeklyJobsCalcSpecialThreeResult"]);
  console.log(props.FinalArray["CombiSprayBoothStandard"]);

  if (props.tasks && props.FinalArray) {
    return (
      <>
        <ToastContainer toastClassName="top-zIndex" autoClose={6000} />
        <div
          id="BodyShop Overview"
          className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100"
        >
          <div className="main-header pd-x-20 ht-70 d-flex align-items-center justify-content-between pl--0">
            <h1 className=" mg-b-0 main-heading mg-t-16 d-flex align-items-center">
              {props.Versioning && (
                <label className="switch mr-3">
                  <input onChange={() => changeEditing()} type="checkbox" />
                  <span className="slider round" />
                </label>
              )}
              {props.Versioning & Editing ? (
                <button
                  type="button"
                  className="btn btn-primary btn-primary-blue m-2 TagButton"
                  onClick={() => props.setStatusModal(true)}
                >
                  {" "}
                  <FormattedMessage
                    id="SetStatus"
                    defaultMessage="Set Status"
                  />
                </button>
              ) : (
                ""
              )}
              {props.Versioning & Editing ? (
                <button
                  type="button"
                  className="btn btn-primary btn-primary-blue m-2 TagButton"
                  onClick={() => props.setWeightModal(true)}
                >
                  {" "}
                  <FormattedMessage
                    id="SetWeight"
                    defaultMessage="Set Weight"
                  />
                </button>
              ) : (
                ""
              )}
              {props.Versioning & Editing ? (
                <button
                  style={{ borderColor: "white", borderRadius: "8px" }}
                  type="button"
                  className="btn btn-primary btn-primary-blue m-2 TagButton"
                  onClick={() => props.setCurrencyModal(true)}
                >
                  {" "}
                  <FormattedMessage
                    id="SetCurrency"
                    defaultMessage="Set Currency"
                  />
                </button>
              ) : (
                ""
              )}
              {props.Versioning & Editing ? (
                <button
                  style={{ borderColor: "white", borderRadius: "8px" }}
                  type="button"
                  className="btn btn-primary btn-primary-blue m-2 TagButton"
                  onClick={() => props.setDateFormatModal(true)}
                >
                  {" "}
                  <FormattedMessage
                    id="SetDateFormat"
                    defaultMessage="Set Date Format"
                  />
                </button>
              ) : (
                ""
              )}

              {props.Versioning & Editing ? (
                <button
                  style={{ borderColor: "white", borderRadius: "8px" }}
                  type="button"
                  className="btn btn-primary btn-primary-blue m-2 TagButton"
                  onClick={() => props.setKpiFormatModal(true)}
                >
                  {" "}
                  <FormattedMessage
                    id="SetDateFormat"
                    defaultMessage="Set KPI version"
                  />
                </button>
              ) : (
                ""
              )}
            </h1>
            <div className="right-options d-flex"></div>
          </div>
          {/* main-header */}
          <div className="main-body bg-custom-white pt-0 pd-20">
            <div className="row flex-column mg-t-20 first-row bodyshop-overview">
              <div className="col-lg-12 col-md-12 col-sm-12 min-h plr-0">
                <div className="card-no-shadow border-radius-16 overflow-hidden">
                  <div className="profile-flex-wrapper d-flex justify-content-start align-items-start">
                    {props.Versioning & Editing ? (
                      <i
                        onClick={() =>
                          openModal("CompanyPicture", "", ["File"])
                        }
                        className="fas fa-wrench"
                      ></i>
                    ) : (
                      ""
                    )}

                    {projectImageURL !== "" && projectImageURL !== "false" ? (
                      <>
                        <div class="d-flex align-items-center">
                          <div className="project-image-wrapper">
                            <img
                              alt="project-img"
                              src={projectImageURL}
                              onClick={ModalProjectPictureOpen}
                              title="Change Picture"
                              style={{ cursor: "pointer" }}
                            />
                          </div>

                          <AddPictureModule
                            heading="Change Profile Picture"
                            onHide={handleProjectPictureCancel}
                            show={isProjectPictureModalOpen}
                            dev={props.dev}
                            idd={props.idd}
                            imgurl={projectImageURL}
                          />



                        </div>

                      </>
                    ) : (
                      <>
                        <div className="project-image-wrapper">
                          <img
                            src="/images/img-avatar.svg"
                            alt="project-img"
                            onClick={
                              checkEditProject
                                ? ModalProjectPictureOpen
                                : "disabled"
                            }
                            title="Change Picture"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <AddPictureModule
                          heading="Change Profile Picture"
                          onHide={handleProjectPictureCancel}
                          show={isProjectPictureModalOpen}
                          dev={props.dev}
                          idd={props.idd}
                          imgurl="/images/img-avatar.svg"
                        />
                      </>
                    )}
                    <div className="project-head-col-2">
                    {!projectNameCheck ? (
                      <p
                        className="mb-1 project-heading-xl ml-2"
                        onClick={
                          checkEditProject
                            ? () => changeProjectNameStatus()
                            : "disabled"
                        }
                      >
                        {props.projectAddress.projectName}
                      </p>
                    ) : (
                      <Input.Group compact>
                        <Input
                          style={{
                            width: "calc(100% - 60px)",
                          }}
                          value={valueProjectName}
                          placeholder={intl.formatMessage({
                            id: "ProjectName",
                            defaultMessage: "Project Name",
                          })}
                          onChange={(evt) => {
                            setProjectName(evt.target.value);
                          }}
                        />
                        <Button
                          icon={<SaveTwoTone />}
                          onClick={() => saveNewProjectName()}
                        />
                      </Input.Group>
                    )}

                    {/* {props.canEdit && TextModuleState == "CompanyPicture" && Array.isArray(props.translation["CompanyPicture"]) && props.translation["CompanyPicture"][1] !== "empty" && props.translation["CompanyPicture"][1] !== " " ?
                      <AddPictureModule dev={props.dev} idd={props.idd} SetTextModuleState={SetTextModuleState} setAllTasks={props.setTasks} allTasks={props.tasks} item={props.translation["CompanyPicture"][1]} onload={props.FinalArray["CompanyPictureResult"]} text={"Name"} />
                      : props.FinalArray["CompanyPictureResult"] ?
                        <div onClick={() => SetTextModuleState("CompanyPicture")}><img className="ProjectImage" src={props.FinalArray["CompanyPictureResult"][0]}></img></div> :
                        <div onClick={() => SetTextModuleState("CompanyPicture")} className="avatar-round"><i className="fas fa-image"></i></div>} */}
                    <div
                      style={{ color: "black" }}
                      className="profile-content pd-10 pt-0 max-width-70 "
                    >

                      {!projectAddress && (
                        <>
                          {props.projectAddress.address.Street ? (
                            <p
                              onClick={
                                checkEditProject
                                  ? () => changeAddressStatus()
                                  : "disabled"
                              }
                            >
                              {" "}
                              {props.projectAddress.address.Street}
                            </p>
                          ) : (
                            <p onClick={() => changeAddressStatus()}>
                              Add Address
                            </p>
                          )}
                        </>
                      )}
                      {projectAddress && (
                        <>
                          <p className="mb-1 project-heading">
                            {props.projectAddress.projectName}
                          </p>
                          <div className="w-100">
                            <Input.Group compact>
                              <Input
                                style={{
                                  width: "calc(100% - 60px)",
                                }}
                                value={valueAddress}
                                placeholder={intl.formatMessage({
                                  id: "AddressGoogleMapsAPI",
                                  defaultMessage: "Address (Google Maps API)*",
                                })}
                                onChange={(evt) => {
                                  getPlacePredictions({
                                    input: evt.target.value,
                                  });
                                  setValueAddress(evt.target.value);
                                }}
                                onFocus={(e) => {
                                  e.target.select();
                                }}
                              />
                              <Button
                                icon={<SaveTwoTone />}
                                onClick={() => saveNewAddress()}
                                loading={isPlacePredictionsLoading}
                              />
                            </Input.Group>
                            <div className="predicition-list">
                              {!isPlacePredictionsLoading && (
                                <List
                                  dataSource={placePredictions}
                                  renderItem={(item) => (
                                    <List.Item
                                      onClick={() =>
                                        setValueAddress(item.description)
                                      }
                                    >
                                      <List.Item.Meta
                                        title={item.description}
                                      />
                                    </List.Item>
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      )}
</div>
                      <br />
                      {props.FinalArray["ApprovedRepairerOne"] && (
                        <span className="mg-t-20 ">
                          <FormattedMessage
                            id="ApprovedRepairerfor"
                            defaultMessage="Approved Repairer for"
                          />
                          :
                        </span>
                      )}
                      <div className="d-flex flex-row justify-content-between">
                        {props.Versioning & Editing ? (
                          <i
                            onClick={() =>
                              openModal("ApprovedRepairerOne", "", ["File"])
                            }
                            className="fas fa-wrench"
                          ></i>
                        ) : (
                          ""
                        )}
                        {props.FinalArray["ApprovedRepairerOne"] && (
                          <img
                            className="img-fluid d-block mt-2 carbrandpicture"
                            src={props.FinalArray["ApprovedRepairerOne"][0]}
                          ></img>
                        )}
                        {props.Versioning & Editing ? (
                          <i
                            onClick={() =>
                              openModal("ApprovedRepairerTwo", "", ["File"])
                            }
                            className="fas fa-wrench"
                          ></i>
                        ) : (
                          ""
                        )}
                        {props.FinalArray["ApprovedRepairerTwo"] && (
                          <img
                            className="img-fluid d-block mt-2 carbrandpicture"
                            src={props.FinalArray["ApprovedRepairerTwo"][0]}
                          ></img>
                        )}
                        {props.Versioning & Editing ? (
                          <i
                            onClick={() =>
                              openModal("ApprovedRepairerThree", "", ["File"])
                            }
                            className="fas fa-wrench"
                          ></i>
                        ) : (
                          ""
                        )}
                        {props.FinalArray["ApprovedRepairerThree"] && (
                          <img
                            className="img-fluid d-block mt-2 carbrandpicture"
                            src={props.FinalArray["ApprovedRepairerThree"][0]}
                          ></img>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="pill-container audit-score-pill">
              {props.Versioning & Editing ? (
                      <i
                        onClick={() =>
                          openModal("TotalAuditScore", "", ["Percentage"])
                        }
                        className="fas fa-wrench"
                      ></i>
                    ) : (
                      ""
                    )}
                    {props.FinalArray["TotalAuditScoreResult"] ||
                    props.FinalArray["TotalAuditScoreResult"] == 0 ? (
                      <ProgressPill score={score} translation={{id: "TotalAuditScore",message: "Total Audit Score"}} completedTasks={props.FinalArray["TotalAuditScore"].complete} totalTasks={props.FinalArray["TotalAuditScore"].total} />
                    ) : (
                      ""
                    )}
              </div>
              <div className="row-kpi">
              <KPIDashboard userLocale={props.userLocale} currencyName={props.currencyName} currencyLocale={props.currencyLocale} projectID={props.idd}/>
              </div>
              <div className="row mg-t-20">
                <div className="row card-custom w-100 gap-c-16 mobile-scroll-y">
                  {props.Versioning & Editing ? (
                    <i
                      onClick={() =>
                        openModal("Sustainability", "", ["Percentage"])
                      }
                      className="fas fa-wrench"
                    ></i>
                  ) : (
                    ""
                  )}
                  {props.FinalArray["SustainabilityResult"] ||
                  props.FinalArray["SustainabilityResult"] == 0 ? (
                    props.FinalArray["Sustainability"].total !== 0 ? (
                      <div className="col rounded-2 pd-16 border-gray border-radius-16  bg-green-c">
                        <div className="card-body d-flex flex-column h-100">
                          <h3 className="card-c-heading">
                            <FormattedMessage
                              id="Sustainability"
                              defaultMessage="Sustainability"
                            />
                          </h3>
                          <p className="small-text">
                            {props.FinalArray["Sustainability"].complete}/
                            {props.FinalArray["Sustainability"].total}{" "}
                            <FormattedMessage
                              id="Taskscompleted"
                              defaultMessage="Tasks completed"
                            />
                          </p>
                          <p className="stats mg-t-auto">
                            {" "}
                            {props.FinalArray["SustainabilityResult"] &&
                              checkPercentage(
                                props.FinalArray["SustainabilityResult"]
                              )}
                            %
                          </p>
                        </div>
                      </div>
                    ) : (
                      <ShowTopCardUnavailable
                        dev={props.dev}
                        message={
                          <FormattedMessage
                            id="Sustainability"
                            defaultMessage="Sustainability"
                          />
                        }
                        color={"bg-green-c"}
                      />
                    )
                  ) : (
                    ""
                  )}
                  {props.Versioning & Editing ? (
                    <i
                      onClick={() =>
                        openModal("Facility + Equipment", "", ["Percentage"])
                      }
                      className="fas fa-wrench"
                    ></i>
                  ) : (
                    ""
                  )}
                  {props.FinalArray["Facility + EquipmentResult"] ||
                  props.FinalArray["Facility + EquipmentResult"] == 0 ? (
                    props.FinalArray["Facility + Equipment"].total !== 0 ? (
                      <div className="col rounded-2 pd-16 border-gray border-radius-16 bg-gray-c">
                        <div className="card-body d-flex flex-column h-100">
                          <h3 className="card-c-heading">
                            <FormattedMessage
                              id="Facility+Equipment"
                              defaultMessage="Facility + Equipment"
                            />
                          </h3>
                          <p className="small-text">
                            {props.FinalArray["Facility + Equipment"].complete}/
                            {props.FinalArray["Facility + Equipment"].total}{" "}
                            <FormattedMessage
                              id="Taskscompleted"
                              defaultMessage="Tasks completed"
                            />
                          </p>
                          <p className="stats  mg-t-auto">
                            {" "}
                            {props.FinalArray["Facility + EquipmentResult"] &&
                              checkPercentage(
                                props.FinalArray["Facility + EquipmentResult"]
                              )}
                            %
                          </p>
                        </div>
                      </div>
                    ) : (
                      <ShowTopCardUnavailable
                        message={
                          <FormattedMessage
                            id="Facility+Equipment"
                            defaultMessage="Facility + Equipment"
                          />
                        }
                        color={"bg-gray-c"}
                      />
                    )
                  ) : (
                    ""
                  )}
                  {props.Versioning & Editing ? (
                    <i
                      onClick={() =>
                        openModal("Business + Workshop Process", "", [
                          "Percentage",
                        ])
                      }
                      className="fas fa-wrench"
                    ></i>
                  ) : (
                    ""
                  )}

                  {props.FinalArray["Business + Workshop ProcessResult"] ||
                  props.FinalArray["Business + Workshop ProcessResult"] == 0 ? (
                    props.FinalArray["Business + Workshop Process"].total !==
                    0 ? (
                      <div className="col rounded-2 pd-16 border-gray border-radius-16 bg-turquiose-c">
                        <div className="card-body d-flex flex-column h-100">
                          <h3 className="card-c-heading">
                            <FormattedMessage
                              id="Business+WorkshopProcesses"
                              defaultMessage="Business + Workshop Processes"
                            />
                          </h3>
                          <p className="small-text">
                            {
                              props.FinalArray["Business + Workshop Process"]
                                .complete
                            }
                            /
                            {
                              props.FinalArray["Business + Workshop Process"]
                                .total
                            }{" "}
                            Tasks completed
                          </p>
                          <p className="stats mg-t-auto">
                            {" "}
                            {props.FinalArray[
                              "Business + Workshop ProcessResult"
                            ] &&
                              checkPercentage(
                                props.FinalArray[
                                  "Business + Workshop ProcessResult"
                                ]
                              )}
                            %
                          </p>
                        </div>
                      </div>
                    ) : (
                      <ShowTopCardUnavailable
                        message={
                          <FormattedMessage
                            id="Business+WorkshopProcesses"
                            defaultMessage="Business + Workshop Processes"
                          />
                        }
                        color={"bg-turquiose-c"}
                      />
                    )
                  ) : (
                    ""
                  )}

                  {props.Versioning & Editing ? (
                    <i
                      onClick={() =>
                        openModal("RepairQuality", "", ["Percentage"])
                      }
                      className="fas fa-wrench"
                    ></i>
                  ) : (
                    ""
                  )}
                  {props.FinalArray["RepairQualityResult"] ||
                  props.FinalArray["RepairQualityResult"] === 0 ||
                  props.FinalArray["RepairQualityResult"] === "0.0" ? (
                    props.FinalArray["RepairQuality"].total !== 0 ? (
                      <div className="col rounded-2 pd-16 border-gray border-radius-16 bg-orange-c">
                        <div className="card-body d-flex flex-column h-100">
                          <h3 className="card-c-heading">
                            <FormattedMessage
                              id="RepairQuality"
                              defaultMessage="Repair Quality"
                            />
                          </h3>
                          <p className="small-text">
                            {NewestRepairQualityArray.complete}/
                            {NewestRepairQualityArray.total}{" "}
                            <FormattedMessage
                              id="Taskscompleted"
                              defaultMessage="Tasks completed"
                            />
                          </p>
                          <p className="stats mg-t-auto">
                            {" "}
                            {checkPercentage(NewestRepairQualityArray.Result)}%
                          </p>
                        </div>
                      </div>
                    ) : (
                      <ShowTopCardUnavailable
                        message={
                          <FormattedMessage
                            id="RepairQuality"
                            defaultMessage="RepairQuality"
                          />
                        }
                        color={"bg-orange-c"}
                      />
                    )
                  ) : (
                    ""
                  )}
                  {props.Versioning & Editing ? (
                    <i
                      onClick={() => openModal("Training", "", ["Percentage"])}
                      className="fas fa-wrench"
                    ></i>
                  ) : (
                    ""
                  )}
                  {props.FinalArray["TrainingResult"] ||
                  props.FinalArray["TrainingResult"] === 0 ? (
                    props.FinalArray["Training"].total !== 0 ? (
                      <div className="col rounded-2 pd-16 border-gray border-radius-16 bg-dark-blue-c">
                        <div className="card-body d-flex flex-column h-100">
                          <h3 className="card-c-heading">
                            <FormattedMessage
                              id="Training"
                              defaultMessage="Training"
                            />
                          </h3>
                          <p className="small-text">
                            {props.FinalArray["Training"].complete}/
                            {props.FinalArray["Training"].total}{" "}
                            <FormattedMessage
                              id="Taskscompleted"
                              defaultMessage="Tasks completed"
                            />
                          </p>
                          <p className="stats mg-t-auto">
                            {props.FinalArray["TrainingResult"] &&
                              checkPercentage(
                                props.FinalArray["TrainingResult"]
                              )}
                            %
                          </p>
                        </div>
                      </div>
                    ) : (
                      <ShowTopCardUnavailable
                        message={
                          <FormattedMessage
                            id="Training"
                            defaultMessage="Training"
                          />
                        }
                        color={"bg-dark-blue-c"}
                      />
                    )
                  ) : (
                    ""
                  )}
                  {props.Versioning & Editing ? (
                    <i
                      onClick={() =>
                        openModal("Business Information", "", ["Percentage"])
                      }
                      className="fas fa-wrench"
                    ></i>
                  ) : (
                    ""
                  )}
                  {props.FinalArray["Business InformationResult"] ||
                  props.FinalArray["Business InformationResult"] == 0 ? (
                    props.FinalArray["Business Information"].total !== 0 ? (
                      <div className="col last rounded-2 pd-16 border-gray border-radius-16 bg-white">
                        <div className="card-body d-flex flex-column h-100">
                          <h3 className="card-c-heading">
                            Business Information
                          </h3>
                          <p className="small-text">
                            {props.FinalArray["Business Information"].complete}/
                            {props.FinalArray["Business Information"].total}{" "}
                            <FormattedMessage
                              id="Taskscompleted"
                              defaultMessage="Tasks completed"
                            />
                          </p>
                          <p className="stats mg-t-auto">
                            {" "}
                            {props.FinalArray["Business InformationResult"] &&
                              checkPercentage(
                                props.FinalArray["Business InformationResult"]
                              )}
                            %
                          </p>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Business Information"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["Business InformationResult"],
                                props.FinalArray["Business Information"],
                                "Business Information",
                                "Business Information"
                              )
                              history.push(`${location.pathname}/Business Information/tasks`, { from: "collapsible" });
                            }}
                            className="cta-link fq-popup cta-link-blue m-0 p-0"
                          >
                            <FormattedMessage
                              id="ShowTasks"
                              defaultMessage="Show Tasks"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <ShowTopCardUnavailable
                        message={
                          <FormattedMessage
                            id="BusinessInformation"
                            defaultMessage="Business Information"
                          />
                        }
                        color={"bg-white"}
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              {/* row */}

            </div>
            {/* row */}
            <div className="row mg-t-20 milestone-row ml--15 mr--15">
              <div className="mt-3 col plr-0 milestone-row-inner">
                <div className="card rounded-2 border-gray border-radius-16 bg-white card-collapse">
                  <div className="top-card-header">
                    <h3 className="card-c-heading mg-b-0">
                      <FormattedMessage
                        id={
                          cardCollapseFlag
                            ? "Milestones"
                            : "Milestones/Recommendations"
                        }
                        defaultMessage={
                          cardCollapseFlag
                            ? "Milestones"
                            : "Milestones / Recommendations"
                        }
                      />
                    </h3>
                    <div>
                      {allUncompletedMilestoneTasks}/
                      {allCompletedMilestoneTasks} Tasks completed
                    </div>
                    <div className="collapse-arrow">
                      <i
                        className={
                          cardCollapseFlag
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                        onClick={() =>
                          setcardCollapseFlag(
                            (prevcardCollapseFlag) => !prevcardCollapseFlag
                          )
                        }
                      ></i>
                    </div>
                  </div>
                  <div
                    className={
                      cardCollapseFlag ? "card-body show" : "card-body"
                    }
                  >
                    <br />
                    <div className="milestone-list">
                      {props.Milestones &&
                        props.MilestoneResultState &&
                        props.MilestoneResultState.map((item, index) => {
                          return props.MilestoneResultState[index] ? (
                            <div
                              style={{
                                marginBottom: "10px",
                                cursor: "pointer",
                              }}
                              className={
                                props.MilestoneResultState[index]["Result"] ==
                                100
                                  ? "text-number-block-row milestone-item green-svg"
                                  : props.MilestoneResultState[index][
                                      "Result"
                                    ] !== 100 && late(item["dueDate"])
                                  ? "text-number-block-row milestone-item red-svg"
                                  : props.MilestoneResultState[index][
                                      "Result"
                                    ] !== 100 && !late(item["dueDate"])
                                  ? "text-number-block-row milestone-item grey-svg"
                                  : "text-number-block-row milestone-item yellow-svg"
                              }
                            >
                              <div className="cols-1 cols-small d-flex align-items-center">
                                <div
                                  onClick={
                                    props.MilestoneResultState &&
                                    props.canEdit &&
                                    props.MilestoneResultState[index]
                                      ? () =>
                                          openTasklistMilestones(
                                            true,
                                            item,
                                            index
                                          )
                                      : openTasklistMilestones(false)
                                  }
                                  style={{ cursor: "pointer", height: "64px" }}
                                  className="svg-img"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={64}
                                    height={64}
                                    viewBox="0 0 64 64"
                                  >
                                    <g transform="translate(-156 -3225)">
                                      <g transform="translate(156 3225)">
                                        <g transform="translate(8 8)">
                                          <path d="M0,0H48V48H0Z" fill="none" />
                                          <path
                                            d="M40.6,26.156A16.89,16.89,0,0,0,40.755,24a16.891,16.891,0,0,0-.156-2.156l4.713-3.63a1.1,1.1,0,0,0,.268-1.408L41.113,9.194a1.119,1.119,0,0,0-.983-.55,1.058,1.058,0,0,0-.38.066l-5.562,2.2a16.381,16.381,0,0,0-3.775-2.156l-.849-5.83A1.084,1.084,0,0,0,28.47,2H19.535a1.084,1.084,0,0,0-1.094.924l-.849,5.83a17.226,17.226,0,0,0-3.775,2.156L8.255,8.71a1.282,1.282,0,0,0-.4-.066,1.109,1.109,0,0,0-.96.55L2.426,16.806a1.074,1.074,0,0,0,.268,1.408l4.713,3.63A17.189,17.189,0,0,0,7.25,24a17.189,17.189,0,0,0,.156,2.156l-4.713,3.63a1.1,1.1,0,0,0-.268,1.408l4.467,7.612a1.119,1.119,0,0,0,.983.55,1.058,1.058,0,0,0,.38-.066l5.562-2.2a16.381,16.381,0,0,0,3.775,2.156l.849,5.83A1.084,1.084,0,0,0,19.535,46H28.47a1.084,1.084,0,0,0,1.094-.924l.849-5.83a17.226,17.226,0,0,0,3.775-2.156l5.562,2.2a1.282,1.282,0,0,0,.4.066,1.109,1.109,0,0,0,.96-.55l4.467-7.612a1.1,1.1,0,0,0-.268-1.408Zm-4.423-3.762A11.58,11.58,0,0,1,36.288,24c0,.462-.045.946-.112,1.606l-.313,2.486,1.988,1.54,2.412,1.848L38.7,34.142,35.863,33.02,33.54,32.1l-2.01,1.5A13.131,13.131,0,0,1,28.738,35.2l-2.368.946-.357,2.486-.447,2.97H22.439l-.424-2.97-.357-2.486L19.29,35.2a12.723,12.723,0,0,1-2.747-1.562L14.51,32.1l-2.368.946L9.305,34.164,7.742,31.5l2.412-1.848,1.988-1.54-.313-2.486c-.067-.682-.112-1.188-.112-1.628s.045-.946.112-1.606l.313-2.486-1.988-1.54L7.742,16.52l1.564-2.662,2.837,1.122,2.323.924,2.01-1.5A13.131,13.131,0,0,1,19.267,12.8l2.368-.946.357-2.486.447-2.97h3.1l.424,2.97.357,2.486,2.368.946a12.723,12.723,0,0,1,2.747,1.562l2.033,1.54,2.368-.946,2.837-1.122L40.241,16.5l-2.39,1.87-1.988,1.54.313,2.486ZM24,15.2A8.8,8.8,0,1,0,32.937,24,8.866,8.866,0,0,0,24,15.2Zm0,13.2A4.4,4.4,0,1,1,28.47,24,4.447,4.447,0,0,1,24,28.4Z"
                                            transform="translate(0 0)"
                                            className="fill"
                                            fill="#1cc435"
                                          />
                                        </g>
                                        <g
                                          fill="none"
                                          stroke="#1cc435"
                                          className="stroke"
                                          strokeWidth={4}
                                        >
                                          <rect
                                            width={64}
                                            height={64}
                                            rx={32}
                                            stroke="none"
                                          />
                                          <rect
                                            x={2}
                                            y={2}
                                            width={60}
                                            height={60}
                                            rx={30}
                                            fill="none"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                                <MilestoneTitle
                                  canEdit={props.canEdit}
                                  index={index}
                                  item={item}
                                  MilestoneResultState={
                                    props.MilestoneResultState
                                  }
                                  openTasklistMilestones={openTasklistMilestones}
                                />
                              </div>
                              <div className="col-2">
                                {item["startDate"] &&
                                item["startDate"] !== "false" ? (
                                  <>
                                    <FormattedMessage
                                      id="StartDate"
                                      defaultMessage="Start Date"
                                    />
                                    :<br />
                                    {date(item["startDate"])}
                                  </>
                                ) : (
                                  <>
                                    <span>No Start Date</span>
                                  </>
                                )}
                              </div>
                              <div className="col-2">
                                {item["dueDate"] &&
                                item["dueDate"] !== "false" ? (
                                  <>
                                    <FormattedMessage
                                      id="DueDate"
                                      defaultMessage="Due Date"
                                    />
                                    :<br />
                                    {date(item["dueDate"])}
                                  </>
                                ) : (
                                  <>
                                    <span>No Due Date</span>
                                  </>
                                )}
                              </div>
                              <div className="d-flex flex-column col-2 assigned-person">
                                {item["Persons"].length > 0 ? (
                                  <>
                                    Assigned to: <br />
                                    {item["Persons"].map((item, index) => {
                                      if (index == 0) {
                                        return <span className="d-flex">{item["label"]}</span>;
                                      } else {
                                        return (
                                          <span className="d-flex">{", " + item["label"]}</span>
                                        );
                                      }
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <span className="d-flex">Not assigned</span>
                                  </>
                                )}
                              </div>
                              <div
                                className="pos-absolute"
                                style={{ right: "15px" }}
                              >
                                {props.canEdit ? (
                                  <>
                                    {" "}
                                    <i
                                      onClick={() => editMilestone(item)}
                                      className="fa fa-pencil"
                                    ></i>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          );
                        })}
                    </div>
                    {props.canEdit ? (
                      <div
                        onClick={() => props.setMilestonesComp(true)}
                        className="mt-4 pointer d-flex flex-column text-center"
                      >
                        <i className="p-2 fas fa-plus"></i>Add milestone
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {props.History && (
                <div className="col plr-0 pd-16-screen mt-3 milestone-row-inner">
                  <div
                    style={{ flexGrow: "unset" }}
                    className=" card rounded-2  border-gray border-radius-16 bg-white card-collapse"
                  >
                    <div className="top-card-header">
                      <h3 className="card-c-heading mg-b-0">
                        <FormattedMessage
                          id="BusinessGraphs"
                          defaultMessage="Business Graph"
                        />
                      </h3>
                      <div className="collapse-arrow">
                        <i
                          className="fa fa-angle-down"
                          aria-hidden="true"
                          onClick={() =>
                            setcardCollapseFlaggraph(
                              (prevcardCollapseFlaggraph) =>
                                !prevcardCollapseFlaggraph
                            )
                          }
                        ></i>
                      </div>
                    </div>
                    <div
                      className={
                        cardCollapseFlaggraph ? "card-body show" : "card-body"
                      }
                      style={{ paddingBottom: "0px" }}
                    >
                      <div
                        style={{ borderBottom: "0px" }}
                        className="card-header tab-card-header border-radius-16"
                      >
                        <ul
                          className="nav c-nav-tabs card-header-tabs mobile-scroll-desk-y  business-graph-list"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="c-nav-item">
                            <a
                              onClick={() =>
                                SetTabFunction(
                                  "TotalAuditScore",
                                  props["FinalArray"]["TotalAuditScore"]
                                )
                              }
                              className="c-nav-link active"
                              id="one-tab"
                              data-toggle="tab"
                              href="#one"
                              role="tab"
                              aria-controls="One"
                              aria-selected="true"
                            >
                              <FormattedMessage
                                id="TotalAuditScore"
                                defaultMessage="Total Audit Score"
                              />{" "}
                              <br />{" "}
                              <span className="num-stats">
                                {" "}
                                {props["FinalArray"]["TotalAuditScoreResult"] &&
                                  checkPercentage(
                                    props["FinalArray"]["TotalAuditScoreResult"]
                                  )}
                                %{" "}
                              </span>
                            </a>
                          </li>

                          <li className="c-nav-item">
                            <a
                              onClick={() =>
                                SetTabFunction(
                                  "BusinessInformation",
                                  props["FinalArray"][
                                    "Business InformationResult"
                                  ]
                                )
                              }
                              className="c-nav-link"
                              id="one-tab"
                              data-toggle="tab"
                              href="#one"
                              role="tab"
                              aria-controls="One"
                              aria-selected="true"
                            >
                              <FormattedMessage
                                id="BusinessInformation"
                                defaultMessage="Business Information"
                              />{" "}
                              <br />{" "}
                              <span className="num-stats">
                                {" "}
                                {props["FinalArray"][
                                  "Business InformationResult"
                                ] &&
                                  checkPercentage(
                                    props["FinalArray"][
                                      "Business InformationResult"
                                    ]
                                  )}
                                %{" "}
                              </span>
                            </a>
                          </li>
                          <li className="c-nav-item">
                            <a
                              onClick={() =>
                                SetTabFunction("FacilityEquipment")
                              }
                              className="c-nav-link"
                              id="three-tab"
                              data-toggle="tab"
                              href="#three"
                              role="tab"
                              aria-controls="Three"
                              aria-selected="false"
                            >
                              <FormattedMessage
                                id="Facility+Equipment"
                                defaultMessage="Facility + Equipment"
                              />{" "}
                              <br />{" "}
                              <span className="num-stats">
                                {props["FinalArray"][
                                  "Facility + EquipmentResult"
                                ] &&
                                  checkPercentage(
                                    props["FinalArray"][
                                      "Facility + EquipmentResult"
                                    ]
                                  )}
                                %
                              </span>
                            </a>
                          </li>
                          <li className="c-nav-item">
                            <a
                              onClick={() =>
                                SetTabFunction("BusinessWorkshopProcess")
                              }
                              className="c-nav-link"
                              id="three-tab"
                              data-toggle="tab"
                              href="#four"
                              role="tab"
                              aria-controls="Three"
                              aria-selected="false"
                            >
                              <FormattedMessage
                                id="Business+WorkshopProcesses"
                                defaultMessage="Business + WorkShop Processes"
                              />{" "}
                              <br />{" "}
                              <span className="num-stats">
                                {props["FinalArray"][
                                  "Business + Workshop ProcessResult"
                                ] &&
                                  checkPercentage(
                                    props["FinalArray"][
                                      "Business + Workshop ProcessResult"
                                    ]
                                  )}
                                %
                              </span>
                            </a>
                          </li>
                          <li className="c-nav-item">
                            <a
                              onClick={() => SetTabFunction("Training")}
                              className="c-nav-link"
                              id="three-tab"
                              data-toggle="tab"
                              href="#five"
                              role="tab"
                              aria-controls="Three"
                              aria-selected="false"
                            >
                              <FormattedMessage
                                id="Training"
                                defaultMessage="Training"
                              />
                              <br />{" "}
                              <span className="num-stats">
                                {props["FinalArray"]["TrainingResult"] &&
                                  checkPercentage(
                                    props["FinalArray"]["TrainingResult"]
                                  )}
                                %
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div style={{ height: 200 }}>
                          <HistoryChart
                            name={tabName}
                            history={props.History}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {props.notesRights && (
              <div className="row mt-3 milestone-row ml--15 mr--15">
                {props.Notes && props.notesRights ? (
                  <div className="mielstone-row-inner2 col plr-0">
                    <div className="card rounded-2 border-gray border-radius-16 bg-white card-collapse">
                      <div className="top-card-header">
                        <h3 className="card-c-heading mg-b-0">
                          <FormattedMessage id="Notes" defaultMessage="Notes" />
                        </h3>
                        <div className="collapse-arrow">
                          <i
                            className={
                              cardCollapseNotes
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                            onClick={() =>
                              setcardCollapseNotes(
                                (prevcardCollapseFlag) => !prevcardCollapseFlag
                              )
                            }
                          ></i>
                        </div>
                      </div>
                      <div
                        className={
                          cardCollapseNotes ? "card-body show" : "card-body"
                        }
                      >
                        <div className="text-number-block milestone-list">
                          {props.Notes.map((item, index) => {
                            return (
                              <>
                                {seeNoteRights(item) ? (
                                  <div
                                    className="text-number-block-row"
                                    onClick={() => viewNote(item)}
                                    style={{
                                      paddingTop: "20px",
                                      paddingBottom: "20px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="col-6 d-flex align-items-center">
                                      <div className="svg-img">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="36"
                                          height="36"
                                          viewBox="0 0 36 36"
                                        >
                                          <path
                                            id="Pfad_547"
                                            data-name="Pfad 547"
                                            d="M35,7V25H25V35H7V7H35m0-4H7A4.012,4.012,0,0,0,3,7V35a4.012,4.012,0,0,0,4,4H27L39,27V7A4.012,4.012,0,0,0,35,3ZM21,25H11V21H21Zm10-8H11V13H31Z"
                                            transform="translate(-3 -3)"
                                            fill="#6a7284"
                                          />
                                        </svg>
                                      </div>
                                      <div
                                        style={{ marginBottom: "0" }}
                                        className=""
                                      >
                                        <h5 className="title-milestone">
                                          {item["Title"]}
                                          {item.Private.length === 1 ? (
                                            <span
                                              style={{
                                                fontSize: "8px",
                                                fontWeight: "normal",
                                                verticalAlign: "text-top",
                                              }}
                                            >
                                              private
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </h5>

                                        <div
                                          className="short-note-desc"
                                          style={{ marginLeft: "10px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              cutString(item["Content"], 15)
                                            ),
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-3 d-flex flex-column">
                                      Last edit:
                                      <span>{item["Edited"]}</span>
                                    </div>

                                    <div className="col-3 d-flex flex-column">
                                      Editor:
                                      <span>
                                        {findUsername(item["Creator"])}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </div>
                        {props.canEdit ? (
                          <div
                            onClick={() => props.setNotesComp(true)}
                            className="mt-4 pointer d-flex flex-column text-center"
                          >
                            <i className="p-2 fas fa-plus"></i>Add Note
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

<div id="Business Analytics" className="row heading-block">
              <div className="col-lg-12 pl-0">
                <h1 className="main-heading">
                  <FormattedMessage
                    id="BusinessAnalytics"
                    defaultMessage="Business Analytics"
                  />
                </h1>
              </div>
            </div>

            {/* Business Analytics Section  */}
            <div className="row business-analytics-section">
              <div className="col-lg-6 min-h plr-0 business-analytics-wrapper">
                <div
                  style={{ paddingBottom: "0px" }}
                  className="card rounded-2 border-gray bg-white ba-card"
                >
                  <div className="columns-wrapper d-flex align-items-start flex-column">
                    <div className="columns-wrapper-1">
                      <h5 className="sub-heading-card">
                        <FormattedMessage
                          id="Administration/ProductiveStaffRatio"
                          defaultMessage="Administration / Productive Staff Ratio"
                        />
                      </h5>
                      <div className="row">
                        <div className="col-lg-6 plr-0 column-view">
                          <h6 className="list-heading">
                            <FormattedMessage
                              id="AdministrationStaff"
                              defaultMessage="Administration Staff"
                            />
                          </h6>
                          <div className="text-number-block d-flex w-100 h-100 flex-column">
                            <div className="mb-auto">
                              {ArrayOfPeopleLeft.map((item, index) => {
                                return (
                                  <>
                                    <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                                      <div className="text-value">
                                        <FormattedMessage
                                          id={businessAnalyticsarr[index].replace(/[^a-zA-Z]/g, "")}
                                          defaultMessage={businessAnalyticsarr[index]}
                                        />
                                      </div>
                                      {props.canEdit &&
                                      TextModuleState == item &&
                                      Array.isArray(props.translation[item]) &&
                                      props.translation[item][1] !== "empty" &&
                                      props.translation[item][1] !== " " ? (
                                        <TextPlusMinusModule
                                          dev={props.dev}
                                          idd={props.idd}
                                          SetTextModuleState={
                                            SetTextModuleState
                                          }
                                          setAllTasks={props.setTasks}
                                          allTasks={props.tasks}
                                          item={props.translation[item][1]}
                                          onload={
                                            props.FinalArray[item + "Result"]
                                          }
                                        />
                                      ) : props.FinalArray[item + "Result"] ? (
                                        <span
                                          onClick={() =>
                                            SetTextModuleState(item)
                                          }
                                        >
                                          {props.FinalArray[item + "Result"]}
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() =>
                                            SetTextModuleState(item)
                                          }
                                        >
                                          0
                                        </span>
                                      )}
                                      {props.Versioning & Editing ? (
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                          onClick={() =>
                                            openModal(item, "", ["Comment"])
                                          }
                                          className="fas fa-wrench"
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                            <div className="text-number-block-row final-total justify-content-between d-flex w-100 pr-2 mt-auto">
                              <div className="text-value">
                                <FormattedMessage
                                  id="TotalAdministrationStaff"
                                  defaultMessage="Total Administration Staff"
                                />
                              </div>
                              <span>{AdministrationStaff}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 plr-0 column-view">
                          <h6 className="list-heading">
                            <FormattedMessage
                              id="ProductionStaff"
                              defaultMessage="Production Staff"
                            />
                          </h6>
                          <div className="text-number-block d-flex w-100 h-100 flex-column">
                            <div className="mb-auto">
                              {ArrayOfPeople.map((item, index) => {
                                return (
                                  <>
                                    <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                                      <div className="text-value">
                                        <FormattedMessage
                                          id={productionStaffArr[index].replace(
                                            /[^a-zA-Z]/g,
                                            ""
                                          )}
                                          defaultMessage={
                                            productionStaffArr[index]
                                          }
                                        />
                                      </div>
                                      {props.canEdit &&
                                      TextModuleState == item &&
                                      Array.isArray(props.translation[item]) &&
                                      props.translation[item][1] !== "empty" &&
                                      props.translation[item][1] !== " " ? (
                                        <TextPlusMinusModule
                                          dev={props.dev}
                                          idd={props.idd}
                                          SetTextModuleState={
                                            SetTextModuleState
                                          }
                                          setAllTasks={props.setTasks}
                                          allTasks={props.tasks}
                                          item={props.translation[item][1]}
                                          onload={
                                            props.FinalArray[item + "Result"]
                                          }
                                        />
                                      ) : props.FinalArray[item + "Result"] ? (
                                        <span
                                          onClick={() =>
                                            SetTextModuleState(item)
                                          }
                                        >
                                          {props.FinalArray[item + "Result"]}
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() =>
                                            SetTextModuleState(item)
                                          }
                                        >
                                          0
                                        </span>
                                      )}
                                      {props.Versioning & Editing ? (
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                          onClick={() =>
                                            openModal(item, "", ["Comment"])
                                          }
                                          className="fas fa-wrench"
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                            <div className="text-number-block-row final-total justify-content-between d-flex w-100 pr-2 mt-auto">
                              <div className="text-value">
                                <FormattedMessage
                                  id="TotalProductiveStaff"
                                  defaultMessage="Total Productive Staff"
                                />
                              </div>
                              <span>{ProductiveStaff}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="working-hours-wrapper">
                        <h4>
                          <b>Working Hours</b>
                        </h4>
                        <div className="week-days d-flex justify-content-between mt-3">
                          {DaysOfWeekArray.map((item, index) => {
                            return (
                              <>
                                <div classname="per-day text-center mb-2">
                                  <p className="font-bold mb-1 text-center">
                                    {DaysofWeekArrayAlias[index]}
                                  </p>
                                  <br />
                                  {props.canEdit &&
                                  TextModuleState == item &&
                                  Array.isArray(props.translation[item]) &&
                                  props.translation[item][1] !== "empty" &&
                                  props.translation[item][1] !== " " ? (
                                    <TextPlusMinusModule
                                      dev={props.dev}
                                      idd={props.idd}
                                      SetTextModuleState={SetTextModuleState}
                                      setAllTasks={props.setTasks}
                                      allTasks={props.tasks}
                                      item={props.translation[item][1]}
                                      onload={props.FinalArray[item + "Result"]}
                                    />
                                  ) : props.FinalArray[item + "Result"] ? (
                                    <span
                                      onClick={() => SetTextModuleState(item)}
                                    >
                                      {props.FinalArray[item + "Result"]}
                                    </span>
                                  ) : (
                                    <span
                                      onClick={() => SetTextModuleState(item)}
                                    >
                                      0
                                    </span>
                                  )}
                                  {props.Versioning & Editing ? (
                                    <i
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "12px",
                                      }}
                                      onClick={() =>
                                        openModal(item, "", ["Comment"])
                                      }
                                      className="fas fa-wrench"
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="column-wrapper-2 mt-auto">
                      <div className="col-lg-12 plr-0">
                        <div style={{ marginTop: "auto" }} className="d-flex">
                          <div
                            className={
                              getRatioClass(
                                AdministrationStaff,
                                ProductiveStaff,
                                "productive"
                              )
                                ? "ba-green-bg w-50 text-center pd-16 text-white bd-b-left"
                                : "ba-red-bg w-50 text-center pd-16 text-white bd-b-left"
                            }
                          >
                            <p>
                              <FormattedMessage
                                id="Administration/ProductiveStaffRatio"
                                defaultMessage="Administration / Productive Staff Ratio"
                              />{" "}
                            </p>
                            <p className="stats-text">{totaratio}</p>
                          </div>
                          <div
                            className={
                              getRatioClass(
                                bodyShopTotal,
                                PaintShopTotal,
                                "bodyshop"
                              )
                                ? "ba-green-bg w-50 text-center pd-16 text-white bd-b-right"
                                : "ba-red-bg w-50 text-center pd-16 text-white bd-b-right"
                            }
                          >
                            <p>
                              <FormattedMessage
                                id="BodyShop/PaintShopTechniciansRatio"
                                defaultMessage="Body Shop / Paint Shop Technicians Ratio"
                              />
                            </p>
                            <p className="stats-text">{ratioOne}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 min-h plr-0 business-analytics-wrapper">
                <div className="card rounded-2 border-gray bg-white ba-card pd-b-0">
                  <h5 className="sub-heading-card">
                    {" "}
                    <FormattedMessage
                      id="FacilityCapacity"
                      defaultMessage="Facility Capacity"
                    />
                  </h5>
                  <h6 className="list-heading">&nbsp;</h6>
                  <div style={{ flexGrow: 1 }} className="row">
                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="col-lg-12 plr-0"
                    >
                      <div className="text-number-block">
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="EstimatingBays"
                              defaultMessage="Estimating Bays"
                            />{" "}
                            {props.Versioning & Editing ? (
                              <i
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() =>
                                  openModal("EstimatingBaysOne", "", [
                                    "Comment",
                                    "calculation",
                                  ])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="number-value">
                            {props.canEdit &&
                            TextModuleState == "EstimatingBaysOne" &&
                            Array.isArray(
                              props.translation["EstimatingBaysOne"]
                            ) &&
                            props.translation["EstimatingBaysOne"][1] !==
                              "empty" &&
                            props.translation["EstimatingBaysOne"][1] !==
                              " " ? (
                              <TextPlusMinusModule
                                idd={props.idd}
                                SetTextModuleState={SetTextModuleState}
                                setAllTasks={props.setTasks}
                                allTasks={props.tasks}
                                item={props.translation["EstimatingBaysOne"][1]}
                                onload={
                                  props.FinalArray[
                                    "EstimatingBaysOne" + "Result"
                                  ]
                                }
                              />
                            ) : props.FinalArray[
                                "EstimatingBaysOne" + "Result"
                              ] ? (
                              <span
                                onClick={() =>
                                  SetTextModuleState("EstimatingBaysOne")
                                }
                              >
                                {
                                  props.FinalArray[
                                    "EstimatingBaysOne" + "Result"
                                  ]
                                }
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  SetTextModuleState("EstimatingBaysOne")
                                }
                              >
                                0
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="MechanicalBays"
                              defaultMessage="Mechanical Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["MechanicalAndBodyRepairWorkBays"]
                              ? props.FinalArray[
                                  "MechanicalAndBodyRepairWorkBays"
                                ]["MechanicalBay"].length
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="DedicatedRemove+RefitBays"
                              defaultMessage="Dedicated Remove + Refit Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["MechanicalAndBodyRepairWorkBays"]
                              ? props.FinalArray[
                                  "MechanicalAndBodyRepairWorkBays"
                                ]["RemoveRefitBay"].length
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="LightRepairBays"
                              defaultMessage="Dedicated Remove + Refit Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["MechanicalAndBodyRepairWorkBays"]
                              ? props.FinalArray[
                                  "MechanicalAndBodyRepairWorkBays"
                                ]["GeneralRepairBay"].length
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="HeavyRepairBays"
                              defaultMessage="Heavy Repair Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["MechanicalAndBodyRepairWorkBays"]
                              ? props.FinalArray[
                                  "MechanicalAndBodyRepairWorkBays"
                                ]["HeavyRepairBay"].length
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="DedicatedAluminiumBays"
                              defaultMessage="Dedicated Aluminium Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["MechanicalAndBodyRepairWorkBays"]
                              ? props.FinalArray[
                                  "MechanicalAndBodyRepairWorkBays"
                                ]["AluminiumBay"].length
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="StandardPreparationBays"
                              defaultMessage="Standard Preparation Bays"
                            />{" "}
                            {props.Versioning & Editing ? (
                              <i
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() =>
                                  openModal("StandardSemiDown", "", ["Comment"])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="number-value">
                            {props.FinalArray["PreparationBayStandard"] &&
                              props.FinalArray["PreparationBayStandard"]}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="Semi-DowndraftPreparationBays"
                              defaultMessage="Semi-Downdraft Preparation Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["PreparationBaySemi"] &&
                              props.FinalArray["PreparationBaySemi"]}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="Full-DowndraftPreparationBays"
                              defaultMessage="Full-Downdraft Preparation Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["PreparationBayFull"] &&
                              props.FinalArray["PreparationBayFull"]}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="MaskingBays"
                              defaultMessage="Masking Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["PaintShopBaysCount"]
                              ? props.FinalArray["PaintShopBaysCount"][
                                  "MaskingBay"
                                ].length
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="CombiSprayBoothStandard"
                              defaultMessage="Combi Spray Booth Standard"
                            />{" "}
                            {props.Versioning & Editing ? (
                              <i
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() =>
                                  openModal("CombiSpray", "", ["Comment"])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="number-value">
                            {props.FinalArray["CombiSprayBoothStandard"]
                              ? props.FinalArray["CombiSprayBoothStandard"]
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="CombiSprayBoothInfrared"
                              defaultMessage="Combi Spray Booth Infrared"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["CombiSprayBoothInfrared"]
                              ? props.FinalArray["CombiSprayBoothInfrared"]
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="SprayBoothSeparateDrying"
                              defaultMessage="Spray Booth Separate Drying"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["SprayBoothSeparateDrying"]
                              ? props.FinalArray["SprayBoothSeparateDrying"]
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="PolishingBays"
                              defaultMessage="Polishing Bays"
                            />
                          </div>
                          <div className="number-value">
                            {props.FinalArray["PaintShopBaysCount"]
                              ? props.FinalArray["PaintShopBaysCount"][
                                  "PolishingBay"
                                ].length
                              : 0}
                          </div>
                        </div>
                        <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                          <div className="text-value">
                            <FormattedMessage
                              id="WashBays"
                              defaultMessage="Detailing Bays"
                            />{" "}
                            {props.Versioning & Editing ? (
                              <i
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() =>
                                  openModal("WashBays", "", ["Percentage"])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="number-value">
                            {props.FinalArray["WashBaysResult"] &&
                            parseInt(props.FinalArray["WashBaysResult"]) > 0
                              ? 1
                              : 0}
                          </div>
                        </div>
                        {props.Versioning & Editing ? (
                          <>
                            <div className="text-number-block-row justify-content-between d-flex w-100 pr-2">
                              <FormattedMessage
                                id="TotalWorkingHoursBy8"
                                defaultMessage="TotalWorkingHoursBy8"
                              />
                              <i
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() =>
                                  openModal("TotalWorkingHoursBy8", "", [
                                    "calculation",
                                  ])
                                }
                                className="fas fa-wrench"
                              ></i>
                              {"\u00A0"}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div
                          style={{ fontSize: "16px" }}
                          className="text-number-block-row justify-content-between d-flex w-100 pr-2"
                        >
                          <div className="text-value">
                            <span style={{ fontWeight: "bold" }}>
                              <FormattedMessage
                                id="CurrentAverageJobValue"
                                defaultMessage="Current Average Job Value"
                              />
                            </span>{" "}
                            {props.Versioning & Editing ? (
                              <i
                                onClick={() =>
                                  openModal("CurrentAverageJobValue", "", [
                                    "Comment",
                                    "calculation",
                                  ])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            style={{ fontWeight: "bold" }}
                            className="number-value"
                          >
                            {props.canEdit &&
                            TextModuleState == "CurrentAverageJobValue" &&
                            Array.isArray(
                              props.translation["CurrentAverageJobValue"]
                            ) &&
                            props.translation["CurrentAverageJobValue"][1] !==
                              "empty" &&
                            props.translation["CurrentAverageJobValue"][1] !==
                              " " ? (
                              <TextPlusMinusModule
                                idd={props.idd}
                                maxLength={10}
                                SetTextModuleState={SetTextModuleState}
                                setAllTasks={props.setTasks}
                                allTasks={props.tasks}
                                item={
                                  props.translation["CurrentAverageJobValue"][1]
                                }
                                onload={
                                  props.FinalArray[
                                    "CurrentAverageJobValue" + "Result"
                                  ]
                                }
                              />
                            ) : props.FinalArray[
                                "CurrentAverageJobValue" + "Result"
                              ] ? (
                              <span
                                onClick={() =>
                                  SetTextModuleState("CurrentAverageJobValue")
                                }
                              >
{currencyNumberFormat(props.FinalArray["CurrentAverageJobValue" + "Result"])}
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  SetTextModuleState("CurrentAverageJobValue")
                                }
                              >
                                {currencyNumberFormat(0)}



                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: "auto" }} className="d-flex">
                        <div
                          style={{
                            borderBottomLeftRadius: "0px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                          }}
                          className={
                            parseInt(
                              props.FinalArray[
                                "CurrentAverageWeeklyJobs" + "Result"
                              ]
                            ) >=
                              (props.FinalArray[
                                "ProjectedWeeklyJobsCalcSpecialOneResult"
                              ]
                                ? props.FinalArray[
                                    "ProjectedWeeklyJobsCalcSpecialOneResult"
                                  ]
                                : 0) +
                                (props.FinalArray[
                                  "ProjectedWeeklyJobsCalcSpecialTwoResult"
                                ]
                                  ? props.FinalArray[
                                      "ProjectedWeeklyJobsCalcSpecialTwoResult"
                                    ]
                                  : 0) +
                                (props.FinalArray[
                                  "ProjectedWeeklyJobsCalcSpecialThreeResult"
                                ]
                                  ? props.FinalArray[
                                      "ProjectedWeeklyJobsCalcSpecialThreeResult"
                                    ]
                                  : 0) ||
                            props.FinalArray[
                              "ProjectedWeeklyJobsCalcSpecialResult"
                            ] === undefined
                              ? "ba-green-bg w-50 text-center pd-16 text-white bd-b-left"
                              : "ba-red-bg w-50 text-center pd-16 text-white bd-b-left"
                          }
                        >
                          <p>
                            Current Average Weekly Jobs{" "}
                            {props.Versioning & Editing ? (
                              <i
                                onClick={() =>
                                  openModal("CurrentAverageWeeklyJobs", "", [
                                    "Comment",
                                    "calculation",
                                  ])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            {props.canEdit &&
                            TextModuleState == "CurrentAverageWeeklyJobs" &&
                            Array.isArray(
                              props.translation["CurrentAverageWeeklyJobs"]
                            ) &&
                            props.translation["CurrentAverageWeeklyJobs"][1] !==
                              "empty" &&
                            props.translation["CurrentAverageWeeklyJobs"][1] !==
                              " " ? (
                              <TextPlusMinusModule
                                dev={props.dev}
                                idd={props.idd}
                                style={"red"}
                                maxLength={4}
                                SetTextModuleState={SetTextModuleState}
                                setAllTasks={props.setTasks}
                                allTasks={props.tasks}
                                item={
                                  props.translation[
                                    "CurrentAverageWeeklyJobs"
                                  ][1]
                                }
                                onload={
                                  props.FinalArray[
                                    "CurrentAverageWeeklyJobs" + "Result"
                                  ]
                                }
                              />
                            ) : props.FinalArray[
                                "CurrentAverageWeeklyJobs" + "Result"
                              ] ? (
                              <span
                                className="stats-text"
                                onClick={() =>
                                  SetTextModuleState("CurrentAverageWeeklyJobs")
                                }
                              >
                                {
                                  props.FinalArray[
                                    "CurrentAverageWeeklyJobs" + "Result"
                                  ]
                                }
                              </span>
                            ) : (
                              <span
                                className="stats-text"
                                onClick={() =>
                                  SetTextModuleState("CurrentAverageWeeklyJobs")
                                }
                              >
                                0
                              </span>
                            )}
                          </div>

                          {/*  <p className="stats-text">{props.FinalArray["CurrentAverageWeeklyJobsResult"]}</p> */}
                        </div>
                        <div
                          style={{
                            borderBottomRightRadius: "0px",
                            background: "#1CC435",
                          }}
                          className="ba-green-bg w-50 text-center pd-16 text-white bd-b-right"
                        >
                          <p>
                            <FormattedMessage
                              id="ProjectedWeeklyJobs"
                              defaultMessage="Projected Weekly Jobs"
                            />{" "}
                          </p>
                          {/* <p className="stats-text">{props.FinalArray["ProjectedWeeklyJobsCalcSpecialResult"]}</p> */}
                          <p className="stats-text">
                            {parseInt(
                              (props.FinalArray[
                                "ProjectedWeeklyJobsCalcSpecialOneResult"
                              ]
                                ? props.FinalArray[
                                    "ProjectedWeeklyJobsCalcSpecialOneResult"
                                  ]
                                : 0) +
                                (props.FinalArray[
                                  "ProjectedWeeklyJobsCalcSpecialTwoResult"
                                ]
                                  ? props.FinalArray[
                                      "ProjectedWeeklyJobsCalcSpecialTwoResult"
                                    ]
                                  : 0) +
                                (props.FinalArray[
                                  "ProjectedWeeklyJobsCalcSpecialThreeResult"
                                ]
                                  ? props.FinalArray[
                                      "ProjectedWeeklyJobsCalcSpecialThreeResult"
                                    ]
                                  : 0)
                            ).toFixed(0)}
                          </p>

                          {props.Versioning & Editing ? (
                            <i
                              onClick={() =>
                                openModal(
                                  "ProjectedWeeklyJobsCalcSpecialOne",
                                  "",
                                  ["Comment", "calculation"]
                                )
                              }
                              className="fas fa-wrench"
                            ></i>
                          ) : (
                            ""
                          )}
                          {props.Versioning & Editing ? (
                            <i
                              onClick={() =>
                                openModal(
                                  "ProjectedWeeklyJobsCalcSpecialTwo",
                                  "",
                                  ["Comment", "calculation"]
                                )
                              }
                              className="fas fa-wrench"
                            ></i>
                          ) : (
                            ""
                          )}
                          {props.Versioning & Editing ? (
                            <i
                              onClick={() =>
                                openModal(
                                  "ProjectedWeeklyJobsCalcSpecialThree",
                                  "",
                                  ["Comment", "calculation"]
                                )
                              }
                              className="fas fa-wrench"
                            ></i>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottomRightRadius: "16px",
                          borderBottomLeftRadius: "16px",
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        <p style={{ textAlign: "center" }}>
                          <FormattedMessage
                            id="WeeklyRevenueGrowthOpportunity"
                            defaultMessage="Weekly Revenue Growth Opportunity"
                          />{" "}
                        </p>
                        <p
                          style={{ textAlign: "center" }}
                          className="ba-card stats-text"
                        >

                            {
                          currencyNumberFormat(
                            (parseFloat(
                              (props.FinalArray[
                                "ProjectedWeeklyJobsCalcSpecialOneResult"
                              ]
                                ? props.FinalArray[
                                    "ProjectedWeeklyJobsCalcSpecialOneResult"
                                  ]
                                : 0) +
                                (props.FinalArray[
                                  "ProjectedWeeklyJobsCalcSpecialTwoResult"
                                ]
                                  ? props.FinalArray[
                                      "ProjectedWeeklyJobsCalcSpecialTwoResult"
                                    ]
                                  : 0) +
                                (props.FinalArray[
                                  "ProjectedWeeklyJobsCalcSpecialThreeResult"
                                ]
                                  ? props.FinalArray[
                                      "ProjectedWeeklyJobsCalcSpecialThreeResult"
                                    ]
                                  : 0)
                            ) -
                              parseFloat(
                                props.FinalArray[
                                  "CurrentAverageWeeklyJobsResult"
                                ]
                              )) *
                              parseFloat(
                                props.FinalArray["CurrentAverageJobValueResult"]
                              )

                          )
                              }


                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div id="Facility + Equipment" className="row heading-block ">
              <div className="col-lg-12 pl-0">
                <h1 className="main-heading">
                  <FormattedMessage
                    id="Facility+Equipment"
                    defaultMessage="Facility + Equipment"
                  />{" "}
                </h1>
              </div>
            </div>
            <div className="container-fluid mb-t-20 facility-equipment-wrapper plr-0">
              <div className="row carousel-wrapper mobile-scroll-y">
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("FacilityEnviromentalSustainability", "", [
                        "Percentage",
                      ])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityEnviromentalSustainability"] && (
                  <ShowTasksCardPicture
                    section="FacilityEnviromentalSustainability"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray[
                        "FacilityEnviromentalSustainabilityResult"
                      ]
                    }
                    FinalArray={
                      props.FinalArray["FacilityEnviromentalSustainability"]
                    }
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={
                      "Facility + Equipment - Environmental Sustainability "
                    }
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="EnviromentalSustainability"
                          defaultMessage="Enviromental Sustainability"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("FacilityParkingExterior", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityParkingExterior"] && (
                  <ShowTasksCardPicture
                    section="FacilityParkingExterior"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityParkingExteriorResult"]
                    }
                    FinalArray={props.FinalArray["FacilityParkingExterior"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Parking + Exterior "}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="Parking+Exterior"
                          defaultMessage="Parking + Exterior"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("FacilityReceptionOffice", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityReceptionOffice"] && (
                  <ShowTasksCardPicture
                    section="FacilityReceptionOffice"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityReceptionOfficeResult"]
                    }
                    FinalArray={props.FinalArray["FacilityReceptionOffice"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Reception + Office "}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="Reception+Office"
                          defaultMessage="Reception + Office"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("FacilityPartsArea", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityPartsArea"] && (
                  <ShowTasksCardPicture
                    section="FacilityPartsArea"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityPartsAreaResult"]
                    }
                    FinalArray={props.FinalArray["FacilityPartsArea"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Parts Area "}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="PartsArea"
                          defaultMessage="Parts Area"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("FacilityBodyShop", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityBodyShop"] && (
                  <ShowTasksCardPicture
                    section="FacilityBodyShop"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityBodyShopResult"]
                    }
                    FinalArray={props.FinalArray["FacilityBodyShop"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Body Shop "}
                    openSiteName={"BodyShop"}
                    openSite={openSite}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="BodyShop"
                          defaultMessage="Body Shop"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("FacilityPaintShop", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityPaintShop"] && (
                  <ShowTasksCardPicture
                    section="FacilityPaintShop"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityPaintShopResult"]
                    }
                    FinalArray={props.FinalArray["FacilityPaintShop"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Paint Shop "}
                    openSiteName={"PaintShop"}
                    openSite={openSite}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="PaintShop"
                          defaultMessage="Paint Shop"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    style={{ cursor: "pointer", fontSize: "15px" }}
                    onClick={() =>
                      openModal("FacilityDetailingBay", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityDetailingBay"] && (
                  <ShowTasksCardPicture
                    section="FacilityDetailingBay"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityDetailingBayResult"]
                    }
                    FinalArray={props.FinalArray["FacilityDetailingBay"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Detailing Bay "}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="DetailingBay"
                          defaultMessage="Detailing Bay"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    style={{ cursor: "pointer", fontSize: "15px" }}
                    onClick={() =>
                      openModal("FacilityWasteRecycling", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityWasteRecycling"] && (
                  <ShowTasksCardPicture
                    section="FacilityWasteRecycling"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityWasteRecyclingResult"]
                    }
                    FinalArray={props.FinalArray["FacilityWasteRecycling"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Waste + Recycling "}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="Waste+Recycling"
                          defaultMessage="Waste + Recycling"
                        />
                      </h5>
                    }
                  />
                )}
                {props.Versioning & Editing ? (
                  <i
                    style={{ cursor: "pointer", fontSize: "15px" }}
                    onClick={() =>
                      openModal("FacilityCompressedAir", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["FacilityCompressedAir"] && (
                  <ShowTasksCardPicture
                    section="FacilityCompressedAir"
                    noTeamwork={props.noTeamwork}
                    FinalArrayResult={
                      props.FinalArray["FacilityCompressedAirResult"]
                    }
                    FinalArray={props.FinalArray["FacilityCompressedAir"]}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    setScrollPos={props.setScrollPos}
                    passTaskListComp={props.passTaskListComp}
                    passResult={props.passResult}
                    Name={"Facility + Equipment - Compressed Air "}
                    openSiteName={"CompressedAir"}
                    openSite={openSite}
                    FormattedMessage={
                      <h5>
                        <FormattedMessage
                          id="CompressedAir"
                          defaultMessage="Compressed Air"
                        />
                      </h5>
                    }
                  />
                )}
              </div>
            </div>
            <div
              id="Business + Workshop Processes"
              className="row heading-block"
            >
              <div className="col-lg-12 pl-0">
                <h1 className="main-heading">
                  <FormattedMessage
                    id="Business+WorkshopProcesses"
                    defaultMessage="Business + Workshop Processes"
                  />{" "}
                </h1>
              </div>
            </div>

            <div className="container-fluid mb-t-20">
              <div className="row block-center">
                <div
                  style={{ position: "relative" }}
                  className="snake-wrapper desktop-version"
                >
                  <div className="snake-timeline row-1">
                    <div className="circle-each bg-blue">
                      <img src="/images/vpn_key_black_24dp@1X.png" alt="" />
                    </div>

                    <div className={getClassName("HealthandSafetyResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("HealthandSafety", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        <div style={{ display: "none" }}></div>
                      )}
                      {props.FinalArray["HealthandSafety"] &&
                      props.FinalArray["HealthandSafety"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["HealthandSafety"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["HealthandSafetyResult"],
                                props.FinalArray["HealthandSafety"],
                                "Health and Safety",
                                "HealthandSafety",
                              )
                              history.push(`${location.pathname}/HealthandSafety/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["HealthandSafetyResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="HealthandSafety"
                                defaultMessage="Health and Safety"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="HealthandSafety"
                                defaultMessage="Health and Safety"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={getClassName("ManagementSnakeResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("ManagementSnake", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        <div style={{ display: "none" }}></div>
                      )}
                      {props.FinalArray["ManagementSnake"] &&
                      props.FinalArray["ManagementSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["ManagementSnake"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["ManagementSnakeResult"],
                                props.FinalArray["ManagementSnake"],
                                "Management",
                                "ManagementSnake",
                              )
                              history.push(`${location.pathname}/ManagementSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["ManagementSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="ManagementProcess"
                                defaultMessage="Management Process"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="ManagementProcess"
                                defaultMessage="Management Process"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      className={getClassName(
                        "Parts Receiving DepartmentSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Parts Receiving DepartmentSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Parts Receiving DepartmentSnake"] &&
                      props.FinalArray["Parts Receiving DepartmentSnake"]
                        .total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnakeResult"
                                ],
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnake"
                                ],
                                "Parts Receiving Department",
                                "Parts Receiving DepartmentSnake",
                              )
                              history.push(`${location.pathname}/Parts Receiving DepartmentSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="PartsReceivingDepartment"
                                defaultMessage="Parts Receiving Department"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="PartsReceivingDepartment"
                                defaultMessage="Parts Receiving Department"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName(
                        "ColorCheckingMatchingSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("ColorCheckingMatchingSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["ColorCheckingMatchingSnake"] &&
                      props.FinalArray["ColorCheckingMatchingSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["ColorCheckingMatchingSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "ColorCheckingMatchingSnakeResult"
                                ],
                                props.FinalArray["ColorCheckingMatchingSnake"],
                                "ColorCheckingMatching",
                                "ColorCheckingMatchingSnake",
                              )
                              history.push(`${location.pathname}/ColorCheckingMatchingSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "ColorCheckingMatchingSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="ColourChecking+Matching"
                                defaultMessage="Colour Checking and Matching"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="ColourChecking+Matching"
                                defaultMessage="Colour Checking and Matching"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName(
                          "Disassembly + Repair IdentificationSnakeResult"
                        ) + " show-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal(
                              "Disassembly + Repair IdentificationSnake",
                              "",
                              ["Percentage"]
                            )
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray[
                        "Disassembly + Repair IdentificationSnake"
                      ] &&
                      props.FinalArray[
                        "Disassembly + Repair IdentificationSnake"
                      ] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnakeResult"
                                ],
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnake"
                                ],
                                "Disassembly + Repair Identification",
                                "Disassembly + Repair IdentificationSnake",
                              )
                              history.push(`${location.pathname}/Disassembly + Repair IdentificationSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Disassembly+RepairIdentification"
                                defaultMessage="Disassembly + Repair Identification"
                              />{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className="value-ratio">N.A.</div>
                          <div className="label">
                            <FormattedMessage
                              id="Disassembly+RepairIdentification"
                              defaultMessage="Disassembly + Repair Identification"
                            />{" "}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Light Dent RepairSnakeResult") +
                        " show-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Light Dent RepairSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Light Dent RepairSnake"] &&
                      props.FinalArray["Light Dent RepairSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Light Dent RepairSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Light Dent RepairSnakeResult"
                                ],
                                props.FinalArray["Light Dent RepairSnake"],
                                "Light Dent Repair",
                                "Light Dent RepairSnake",
                              )
                              history.push(`${location.pathname}/Light Dent RepairSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Light Dent RepairSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="LightDentRepair"
                                defaultMessage="Light Dent Repair"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A.</div>
                            <div className="label">
                              <FormattedMessage
                                id="LightDentRepair"
                                defaultMessage="Light Dent Repair"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="snake-timeline reverse-right">
                    <div
                      className={
                        getClassName(
                          "Disassembly + Repair IdentificationSnakeResult"
                        ) + " hide-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal(
                              "Disassembly + Repair IdentificationSnake",
                              "",
                              ["Percentage"]
                            )
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray[
                        "Disassembly + Repair IdentificationSnake"
                      ] &&
                      props.FinalArray[
                        "Disassembly + Repair IdentificationSnake"
                      ].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnakeResult"
                                ],
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnake"
                                ],
                                "Disassembly + Repair Identification",
                                "Disassembly + Repair IdentificationSnake",
                              )
                              history.push(`${location.pathname}/Disassembly + Repair IdentificationSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Disassembly+RepairIdentification"
                                defaultMessage="Disassembly + Repair Identification"
                              />{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Disassembly+RepairIdentification"
                                defaultMessage="Disassembly + Repair Identification"
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Light Dent RepairSnakeResult") +
                        " hide-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Light Dent RepairSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Light Dent RepairSnake"] &&
                      props.FinalArray["Light Dent RepairSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Light Dent RepairSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Light Dent RepairSnakeResult"
                                ],
                                props.FinalArray["Light Dent RepairSnake"],
                                "Light Dent Repair",
                                "Light Dent RepairSnake",
                              )
                              history.push(`${location.pathname}/Light Dent RepairSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Light Dent RepairSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="LightDentRepair"
                                defaultMessage="Light Dent Repair"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A.</div>
                            <div className="label">
                              <FormattedMessage
                                id="LightDentRepair"
                                defaultMessage="Light Dent Repair"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName(
                        "Heavy Body Repair + Welded PanelsSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal(
                              "Heavy Body Repair + Welded PanelsSnake",
                              "",
                              ["Percentage"]
                            )
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray[
                        "Heavy Body Repair + Welded PanelsSnake"
                      ] &&
                      props.FinalArray["Heavy Body Repair + Welded PanelsSnake"]
                        .total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnakeResult"
                                ],
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnake"
                                ],
                                "Heavy Body Repair + Welded Panels",
                                "Heavy Body Repair + Welded PanelsSnake",
                              )
                              history.push(`${location.pathname}/Heavy Body Repair + Welded PanelsSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="HeavyBodyRepair+WeldedPanels"
                                defaultMessage="Heavy Body Repair - R + R Welded Panels"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="HeavyBodyRepair+WeldedPanels"
                                defaultMessage="Heavy Body Repair - R + R Welded Panels"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName(
                        "Body Filler Aplication + SandingSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal(
                              "Body Filler Aplication + SandingSnake",
                              "",
                              ["Percentage"]
                            )
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray[
                        "Body Filler Aplication + SandingSnake"
                      ] &&
                      props.FinalArray["Body Filler Aplication + SandingSnake"]
                        .total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnakeResult"
                                ],
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnake"
                                ],
                                "Body Filler Application + Sanding",
                                "Body Filler Aplication + SandingSnake",
                              )
                              history.push(`${location.pathname}/Body Filler Aplication + SandingSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {" "}
                              {checkPercentage(
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="BodyFillerApplication+Sanding"
                                defaultMessage="Applying Body Filling + Sanding"
                              />{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="BodyFillerApplication+Sanding"
                                defaultMessage="Applying Body Filling + Sanding"
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Preparation for PrimerSnakeResult") + " "
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Preparation for PrimerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Preparation for PrimerSnake"] &&
                      props.FinalArray["Preparation for PrimerSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Preparation for PrimerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Preparation for PrimerSnakeResult"
                                ],
                                props.FinalArray["Preparation for PrimerSnake"],
                                "Preparation for Primer",
                                "Preparation for PrimerSnake",
                              )
                              history.push(`${location.pathname}/Preparation for PrimerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Preparation for PrimerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Preparation for Primer"
                                defaultMessage="Preparation for Primer"
                              />{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Preparation for Primer"
                                defaultMessage="Preparation for Primer"
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Applying Primer FillerSnakeResult") +
                        " show-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Applying Primer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Applying Primer FillerSnake"] &&
                      props.FinalArray["Applying Primer FillerSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Applying Primer FillerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Applying Primer FillerSnakeResult"
                                ],
                                props.FinalArray["Applying Primer FillerSnake"],
                                "Applying Primer Filler",
                                "Applying Primer FillerSnake",
                              )
                              history.push(`${location.pathname}/Applying Primer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Applying Primer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="ApplyingPrimerFiller"
                                defaultMessage="Primer Filler Application"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="ApplyingPrimerFiller"
                                defaultMessage="Primer Filler Application"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Curing Drying Frimer FillerSnakeResult") +
                        " show-circle-xl "
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Curing Drying Frimer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Curing Drying Frimer FillerSnake"] &&
                      props.FinalArray["Curing Drying Frimer FillerSnake"] !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnakeResult"
                                ],
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnake"
                                ],
                                "Curing Drying Primer Filler",
                                "Curing Drying Frimer FillerSnake",
                              )
                              history.push(`${location.pathname}/Curing Drying Frimer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingPrimerFiller"
                                defaultMessage="Curing or Drying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingPrimerFiller"
                                defaultMessage="Curing or Drying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Sanding Primer FillerSnakeResult", 16) +
                        " show-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Sanding Primer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Sanding Primer FillerSnake"] &&
                      props.FinalArray["Sanding Primer FillerSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Sanding Primer FillerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Sanding Primer FillerSnakeResult"
                                ],
                                props.FinalArray["Sanding Primer FillerSnake"],
                                "Sanding Primer Filler",
                                "Sanding Primer FillerSnake",
                              )
                              history.push(`${location.pathname}/Sanding Primer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Sanding Primer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="SandingPrimerFiller"
                                defaultMessage="Sanding Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="SandingPrimerFiller"
                                defaultMessage="Sanding Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Masking for TopcatSnakeResult") +
                        " show-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Masking for TopcatSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Masking for TopcatSnake"] &&
                      props.FinalArray["Masking for TopcatSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Masking for TopcatSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Masking for TopcatSnakeResult"
                                ],
                                props.FinalArray["Masking for TopcatSnake"],
                                "Masking for Topcoat ",
                                "Masking for TopcatSnake",
                              )
                              history.push(`${location.pathname}/Masking for TopcatSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Masking for TopcatSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="MaskingforTopcoat"
                                defaultMessage="Masking for Topcoat"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="MaskingforTopcoat"
                                defaultMessage="Masking for Topcoat"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Topcat AplicationSnakeResult") +
                        " show-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Topcat AplicationSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Topcat AplicationSnake"] &&
                      props.FinalArray["Topcat AplicationSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Topcat AplicationSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Topcat AplicationSnakeResult"
                                ],
                                props.FinalArray["Topcat AplicationSnake"],
                                "Topcoat Application",
                                "Topcat AplicationSnake",
                              )
                              history.push(`${location.pathname}/Topcat AplicationSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Topcat AplicationSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="TopcoatApplication"
                                defaultMessage="Topcoat Application"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="TopcoatApplication"
                                defaultMessage="Topcoat Application"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="snake-timeline">
                    <div
                      className={
                        getClassName("Applying Primer FillerSnakeResult") +
                        " hide-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Applying Primer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Applying Primer FillerSnake"] &&
                      props.FinalArray["Applying Primer FillerSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Applying Primer FillerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Applying Primer FillerSnakeResult"
                                ],
                                props.FinalArray["Applying Primer FillerSnake"],
                                "Applying Primer Filler",
                                "Applying Primer FillerSnake",
                              )
                              history.push(`${location.pathname}/Applying Primer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Applying Primer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="ApplyingPrimerFiller"
                                defaultMessage="Primer Filler Application"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="ApplyingPrimerFiller"
                                defaultMessage="Primer Filler Application"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Curing Drying Frimer FillerSnakeResult") +
                        " hide-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Curing Drying Frimer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Curing Drying Frimer FillerSnake"] &&
                      props.FinalArray["Curing Drying Frimer FillerSnake"] !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnakeResult"
                                ],
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnake"
                                ],
                                "Curing Drying Primer Filler",
                                "Curing Drying Frimer FillerSnake",
                              )
                              history.push(`${location.pathname}/Curing Drying Primer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingPrimerFiller"
                                defaultMessage="Curing or Drying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingPrimerFiller"
                                defaultMessage="Curing or Drying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Sanding Primer FillerSnakeResult", 16) +
                        " hide-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Sanding Primer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Sanding Primer FillerSnake"] &&
                      props.FinalArray["Sanding Primer FillerSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Sanding Primer FillerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Sanding Primer FillerSnakeResult"
                                ],
                                props.FinalArray["Sanding Primer FillerSnake"],
                                "Sanding Primer Filler",
                                "Sanding Primer FillerSnake",
                              )
                              history.push(`${location.pathname}/Sanding Primer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Sanding Primer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="SandingPrimerFiller"
                                defaultMessage="Sanding Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="SandingPrimerFiller"
                                defaultMessage="Sanding Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Masking for TopcatSnakeResult") +
                        " hide-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Masking for TopcatSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Masking for TopcatSnake"] &&
                      props.FinalArray["Masking for TopcatSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Masking for TopcatSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Masking for TopcatSnakeResult"
                                ],
                                props.FinalArray["Masking for TopcatSnake"],
                                "Masking for Topcoat ",
                                "Masking for TopcatSnake",
                              )
                              history.push(`${location.pathname}/Masking for TopcatSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Masking for TopcatSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="MaskingforTopcoat"
                                defaultMessage="Masking for Topcoat"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="MaskingforTopcoat"
                                defaultMessage="Masking for Topcoat"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Topcat AplicationSnakeResult") +
                        " hide-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Topcat AplicationSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Topcat AplicationSnake"] &&
                      props.FinalArray["Topcat AplicationSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Topcat AplicationSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Topcat AplicationSnakeResult"
                                ],
                                props.FinalArray["Topcat AplicationSnake"],
                                "Topcoat Application",
                                "Topcat AplicationSnake",
                              )
                              history.push(`${location.pathname}/Topcat AplicationSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Topcat AplicationSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="TopcoatApplication"
                                defaultMessage="Topcoat Application"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">
                              {props.FinalArray["Topcat AplicationSnakeResult"]}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="TopcoatApplication"
                                defaultMessage="Topcoat Application"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="snake-timeline reverse-right no-reverse-xl">
                    <div
                      className={getClassName(
                        "Curing Drying TopcatsSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Curing Drying TopcatsSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Curing Drying TopcatsSnake"] &&
                      props.FinalArray["Curing Drying TopcatsSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Curing Drying TopcatsSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Curing Drying TopcatsSnakeResult"
                                ],
                                props.FinalArray["Curing Drying TopcatsSnake"],
                                "Curing Drying Topcoats",
                                "Curing Drying TopcatsSnake",
                              )
                              history.push(`${location.pathname}/Curing Drying TopcatsSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Curing Drying TopcatsSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingTopcoats"
                                defaultMessage="Curing/Drying Topcoats"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingTopcoats"
                                defaultMessage="Curing/Drying Topcoats"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName(
                        "Denibbing + PolishingSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Denibbing + PolishingSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Denibbing + PolishingSnake"] &&
                      props.FinalArray["Denibbing + PolishingSnake"] !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Denibbing + PolishingSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Denibbing + PolishingSnakeResult"
                                ],
                                props.FinalArray["Denibbing + PolishingSnake"],
                                "Denibbing + Polishing",
                                "Denibbing + PolishingSnake",
                              )
                              history.push(`${location.pathname}/Denibbing + PolishingSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Denibbing + PolishingSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Denibbing+Polishing"
                                defaultMessage="Denibbing and Polishing"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">
                              {
                                props.FinalArray[
                                  "Denibbing + PolishingSnakeResult"
                                ]
                              }
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Denibbing+Polishing"
                                defaultMessage="Denibbing and Polishing"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={getClassName("ReassemblySnakeResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("ReassemblySnake", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["ReassemblySnake"] &&
                      props.FinalArray["ReassemblySnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["ReassemblySnake"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["ReassemblySnakeResult"],
                                props.FinalArray["ReassemblySnake"],
                                "Re-Assembly",
                                "ReassemblySnake",
                              )
                              history.push(`${location.pathname}/ReassemblySnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["ReassemblySnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Re-Assembly"
                                defaultMessage="Reassembly"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Re-Assembly"
                                defaultMessage="Reassembly"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={getClassName("DetailingResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Detailing", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Detailing"] &&
                      props.FinalArray["Detailing"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Detailing"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["DetailingResult"],
                                props.FinalArray["Detailing"],
                                "Detailing",
                                "Detailing",
                              )
                              history.push(`${location.pathname}/Detailing/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["DetailingResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Detailing"
                                defaultMessage="Detailing"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Detailing"
                                defaultMessage="Detailing"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName("Vehicle HandoverSnakeResult")}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Vehicle HandoverSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Vehicle HandoverSnake"] &&
                      props.FinalArray["Vehicle HandoverSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Vehicle HandoverSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["Vehicle HandoverSnakeResult"],
                                props.FinalArray["Vehicle HandoverSnake"],
                                "Vehicle Handover",
                                "Vehicle HandoverSnake",
                              )
                              history.push(`${location.pathname}/Vehicle HandoverSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Vehicle HandoverSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="VehicleHandover"
                                defaultMessage="Vehicle Handover"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="VehicleHandover"
                                defaultMessage="Vehicle Handover"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        getClassName("Invoicing+CustomerFollowUpResult") +
                        " show-circle-xl"
                      }
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Invoicing+CustomerFollowUp", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Invoicing+CustomerFollowUp"] &&
                      props.FinalArray["Invoicing+CustomerFollowUp"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Invoicing+CustomerFollowUp"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Invoicing+CustomerFollowUpResult"
                                ],
                                props.FinalArray["Invoicing+CustomerFollowUp"],
                                "Invoicing + Customer Follow Up",
                                "Invoicing+CustomerFollowUp",
                              )
                              history.push(`${location.pathname}/Invoicing+CustomerFollowUp/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Invoicing+CustomerFollowUpResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Invoicing+CustomerFollowUp"
                                defaultMessage="Invoicing + Customer Follow Up"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Invoicing+CustomerFollowUp"
                                defaultMessage="Invoicing + Customer Follow Up"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="circle-each bg-blue nothing show-circle-xl">
                      <img src="/images/vpn_key_black_24dp@1X.png" alt="" />
                    </div>
                  </div>
                  <div className="snake-timeline  justify-content-start last-row">
                    <div
                      className={getClassName(
                        "Invoicing+CustomerFollowUpResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Invoicing+CustomerFollowUp", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Invoicing+CustomerFollowUp"] &&
                      props.FinalArray["Invoicing+CustomerFollowUp"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Invoicing+CustomerFollowUp"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Invoicing+CustomerFollowUpResult"
                                ],
                                props.FinalArray["Invoicing+CustomerFollowUp"],
                                "Invoicing + Customer Follow Up",
                                "Invoicing+CustomerFollowUp",
                              )
                              history.push(`${location.pathname}/Invoicing+CustomerFollowUp/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Invoicing+CustomerFollowUpResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Invoicing+CustomerFollowUp"
                                defaultMessage="Invoicing + Customer Follow Up"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Invoicing+CustomerFollowUp"
                                defaultMessage="Invoicing + Customer Follow Up"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="circle-each bg-blue nothing">
                      <img src="/images/vpn_key_black_24dp@1X.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ position: "relative" }}
                className="snake-wrapper mobile-version"
              >
                <div className="mobile-snake-timeline">
                  <div className="col">
                    <div className="circle-each bg-blue">
                      <img src="/images/vpn_key_black_24dp@1X.png" alt="" />
                    </div>
                    <div className={getClassName("HealthandSafetyResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("HealthandSafety", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["HealthandSafety"] &&
                      props.FinalArray["HealthandSafety"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["HealthandSafety"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["HealthandSafetyResult"],
                                props.FinalArray["HealthandSafety"],
                                "Health and Safety",
                                "HealthandSafety",
                              )
                              history.push(`${location.pathname}/HealthandSafety/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["HealthandSafetyResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="HealthandSafety"
                                defaultMessage="Health and Safety"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="HealthandSafety"
                                defaultMessage="Health and Safety"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col right">
                    <div
                      className={getClassName(
                        "Parts Receiving DepartmentSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Parts Receiving DepartmentSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Parts Receiving DepartmentSnake"] &&
                      props.FinalArray["Parts Receiving DepartmentSnake"]
                        .total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnakeResult"
                                ],
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnake"
                                ],
                                "Parts Receiving Department",
                                "Parts Receiving DepartmentSnake",
                              )
                              history.push(`${location.pathname}/Parts Receiving DepartmentSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Parts Receiving DepartmentSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="PartsReceivingDepartment"
                                defaultMessage="Parts Receiving Department"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="PartsReceivingDepartment"
                                defaultMessage="Parts Receiving Department"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={getClassName("ManagementSnakeResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("ManagementSnake", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        <div style={{ display: "none" }}></div>
                      )}
                      {props.FinalArray["ManagementSnake"] &&
                      props.FinalArray["ManagementSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["ManagementSnake"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["ManagementSnakeResult"],
                                props.FinalArray["ManagementSnake"],
                                "Management",
                                "ManagementSnake",
                              )
                              history.push(`${location.pathname}/ManagementSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["ManagementSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="ManagementProcess"
                                defaultMessage="Management Process"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="ManagementProcess"
                                defaultMessage="Management Process"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className={getClassName(
                        "ColorCheckingMatchingSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("ColorCheckingMatchingSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["ColorCheckingMatchingSnake"] &&
                      props.FinalArray["ColorCheckingMatchingSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["ColorCheckingMatchingSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "ColorCheckingMatchingSnakeResult"
                                ],
                                props.FinalArray["ColorCheckingMatchingSnake"],
                                "ColorCheckingMatching",
                                "ColorCheckingMatchingSnake",
                              )
                              history.push(`${location.pathname}/ColorCheckingMatchingSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "ColorCheckingMatchingSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="ColourChecking+Matching"
                                defaultMessage="Colour Checking + Matching"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="ColourChecking+Matching"
                                defaultMessage="Colour Checking + Matching"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName(
                        "Disassembly + Repair IdentificationSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal(
                              "Disassembly + Repair IdentificationSnake",
                              "",
                              ["Percentage"]
                            )
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray[
                        "Disassembly + Repair IdentificationSnake"
                      ] &&
                      props.FinalArray[
                        "Disassembly + Repair IdentificationSnake"
                      ].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnakeResult"
                                ],
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnake"
                                ],
                                "Disassembly + Repair Identification",
                                "Disassembly + Repair IdentificationSnake",
                              )
                              history.push(`${location.pathname}/Disassembly + Repair IdentificationSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Disassembly + Repair IdentificationSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Disassembly+RepairIdentification"
                                defaultMessage="Disassembly + Repair Identification"
                              />{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Disassembly+RepairIdentification"
                                defaultMessage="Disassembly + Repair Identification"
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col right">
                    <div
                      className={getClassName(
                        "Heavy Body Repair + Welded PanelsSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal(
                              "Heavy Body Repair + Welded PanelsSnake",
                              "",
                              ["Percentage"]
                            )
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray[
                        "Heavy Body Repair + Welded PanelsSnake"
                      ] &&
                      props.FinalArray["Heavy Body Repair + Welded PanelsSnake"]
                        .total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnakeResult"
                                ],
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnake"
                                ],
                                "Heavy Body Repair + Welded Panels",
                                "Heavy Body Repair + Welded PanelsSnake",
                              )
                              history.push(`${location.pathname}/Heavy Body Repair + Welded PanelsSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Heavy Body Repair + Welded PanelsSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="HeavyBodyRepair+WeldedPanels"
                                defaultMessage="Heavy Body Repair + Welded Panels"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="HeavyBodyRepair+WeldedPanels"
                                defaultMessage="Heavy Body Repair + Welded Panels"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName("Light Dent RepairSnakeResult")}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Light Dent RepairSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Light Dent RepairSnake"] &&
                      props.FinalArray["Light Dent RepairSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Light Dent RepairSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Light Dent RepairSnakeResult"
                                ],
                                props.FinalArray["Light Dent RepairSnake"],
                                "Light Dent Repair",
                                "Light Dent RepairSnake",
                              )
                              history.push(`${location.pathname}/Light Dent RepairSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Light Dent RepairSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="LightDentRepair"
                                defaultMessage="Light Dent Repair"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="LightDentRepair"
                                defaultMessage="Light Dent Repair"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className={getClassName(
                        "Body Filler Aplication + SandingSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal(
                              "Body Filler Aplication + SandingSnake",
                              "",
                              ["Percentage"]
                            )
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray[
                        "Body Filler Aplication + SandingSnake"
                      ] &&
                      props.FinalArray["Body Filler Aplication + SandingSnake"]
                        .total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnakeResult"
                                ],
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnake"
                                ],
                                "Body Filler Application + Sanding",
                                "Body Filler Aplication + SandingSnake",
                              )
                              history.push(`${location.pathname}/Body Filler Aplication + SandingSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {" "}
                              {checkPercentage(
                                props.FinalArray[
                                  "Body Filler Aplication + SandingSnakeResult"
                                ]
                              )}
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="BodyFillerApplication+Sanding"
                                defaultMessage="Body Filler Application + Sanding"
                              />{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="BodyFillerApplication+Sanding"
                                defaultMessage="Body Filler Application + Sanding"
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName("Preparation for PrimerSnake")}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Preparation for PrimerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Preparation for PrimerSnake"] &&
                      props.FinalArray["Preparation for PrimerSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Preparation for PrimerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Preparation for PrimerSnakeResult"
                                ],
                                props.FinalArray["Preparation for PrimerSnake"],
                                "Preparation for Primer",
                                "Preparation for PrimerSnake",
                              )
                              history.push(`${location.pathname}/Preparation for PrimerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Preparation for PrimerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="PreparationforPrimer"
                                defaultMessage="Preparation for Primer"
                              />{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="PreparationforPrimer"
                                defaultMessage="Preparation for Primer"
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col right">
                    <div
                      className={getClassName(
                        "Curing Drying Frimer FillerSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Curing Drying Frimer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Curing Drying Frimer FillerSnake"] &&
                      props.FinalArray["Curing Drying Frimer FillerSnake"]
                        .total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnake"
                                ]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnakeResult"
                                ],
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnake"
                                ],
                                "Curing Drying Primer Filler",
                                "Curing Drying Frimer FillerSnake",
                              )
                              history.push(`${location.pathname}/Curing Drying Frimer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Curing Drying Frimer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingPrimerFiller"
                                defaultMessage="Curing Drying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingPrimerFiller"
                                defaultMessage="Curing Drying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName(
                        "Applying Primer FillerSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Applying Primer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Applying Primer FillerSnake"] &&
                      props.FinalArray["Applying Primer FillerSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Applying Primer FillerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Applying Primer FillerSnakeResult"
                                ],
                                props.FinalArray["Applying Primer FillerSnake"],
                                "Applying Primer Filler",
                                "Applying Primer FillerSnake",
                              )
                              history.push(`${location.pathname}/Applying Primer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Applying Primer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="ApplyingPrimerFiller"
                                defaultMessage="Applying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="ApplyingPrimerFiller"
                                defaultMessage="Applying Primer Filler"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className={getClassName(
                        "Sanding Primer FillerSnakeResult",
                        16
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Sanding Primer FillerSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Sanding Primer FillerSnake"] &&
                      props.FinalArray["Sanding Primer FillerSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Sanding Primer FillerSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Sanding Primer FillerSnakeResult"
                                ],
                                props.FinalArray["Sanding Primer FillerSnake"],
                                "Sanding Primer Filler",
                                "Sanding Primer FillerSnake",
                              )
                              history.push(`${location.pathname}/Sanding Primer FillerSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Sanding Primer FillerSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">Sanding Primer Filler</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">Sanding Primer Filler</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName("Masking for TopcatSnakeResult")}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Masking for TopcatSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Masking for TopcatSnake"] &&
                      props.FinalArray["Masking for TopcatSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Masking for TopcatSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Masking for TopcatSnakeResult"
                                ],
                                props.FinalArray["Masking for TopcatSnake"],
                                "Masking for Topcoat ",
                                "Masking for TopcatSnake",
                              )
                              history.push(`${location.pathname}/Masking for TopcatSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Masking for TopcatSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="MaskingforTopcoat"
                                defaultMessage="Masking for Topcoat"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="MaskingforTopcoat"
                                defaultMessage="Masking for Topcoat"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col right">
                    <div
                      className={getClassName(
                        "Curing Drying TopcatsSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Curing Drying TopcatsSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Curing Drying TopcatsSnake"] &&
                      props.FinalArray["Curing Drying TopcatsSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Curing Drying TopcatsSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Curing Drying TopcatsSnakeResult"
                                ],
                                props.FinalArray["Curing Drying TopcatsSnake"],
                                "Curing Drying Topcats",
                                "Curing Drying TopcatsSnake",
                              )
                              history.push(`${location.pathname}/Curing Drying TopcatsSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Curing Drying TopcatsSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingTopcoats"
                                defaultMessage="Curing Drying Topcoats"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="CuringDryingTopcoats"
                                defaultMessage="Curing Drying Topcoats"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={getClassName("Topcat AplicationSnakeResult")}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Topcat AplicationSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Topcat AplicationSnake"] &&
                      props.FinalArray["Topcat AplicationSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Topcat AplicationSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Topcat AplicationSnakeResult"
                                ],
                                props.FinalArray["Topcat AplicationSnake"],
                                "Topcoat Application",
                                "Topcat AplicationSnake",
                              )
                              history.push(`${location.pathname}/Topcat AplicationSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Topcat AplicationSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="TopcoatApplication"
                                defaultMessage="Topcoat Application"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="TopcoatApplication"
                                defaultMessage="Topcoat Application"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className={getClassName(
                        "Denibbing + PolishingSnakeResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Denibbing + PolishingSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Denibbing + PolishingSnake"] &&
                      props.FinalArray["Denibbing + PolishingSnake"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Denibbing + PolishingSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Denibbing + PolishingSnakeResult"
                                ],
                                props.FinalArray["Denibbing + PolishingSnake"],
                                "Denibbing + Polishing",
                                "Denibbing + PolishingSnake",
                              )
                              history.push(`${location.pathname}/Denibbing + PolishingSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Denibbing + PolishingSnakeResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Denibbing+Polishing"
                                defaultMessage="Denibbing + Polishing"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Denibbing+Polishing"
                                defaultMessage="Denibbing + Polishing"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={getClassName("ReassemblySnakeResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("ReassemblySnake", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["ReassemblySnake"] &&
                      props.FinalArray["ReassemblySnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["ReassemblySnake"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["ReassemblySnakeResult"],
                                props.FinalArray["ReassemblySnake"],
                                "Re-Assembly",
                                "ReassemblySnake",
                              )
                              history.push(`${location.pathname}/ReassemblySnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["ReassemblySnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Re-Assembly"
                                defaultMessage="Re-Assembly"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Re-Assembly"
                                defaultMessage="Re-Assembly"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col right">
                    <div
                      className={getClassName("Vehicle HandoverSnakeResult")}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Vehicle HandoverSnake", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Vehicle HandoverSnake"] &&
                      props.FinalArray["Vehicle HandoverSnake"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Vehicle HandoverSnake"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["Vehicle HandoverSnakeResult"],
                                props.FinalArray["Vehicle HandoverSnake"],
                                "Vehicle Handover",
                                "Vehicle HandoverSnake",
                              )
                              history.push(`${location.pathname}/Vehicle HandoverSnake/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["Vehicle HandoverSnakeResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="VehicleHandover"
                                defaultMessage="Vehicle Handover"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="VehicleHandover"
                                defaultMessage="Vehicle Handover"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={getClassName("DetailingResult")}>
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Detailing", "", ["Percentage"])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Detailing"] &&
                      props.FinalArray["Detailing"].total !== 0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Detailing"]["Tasks"],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray["DetailingResult"],
                                props.FinalArray["Detailing"],
                                "Detailing",
                                "Detailing",
                              )
                              history.push(`${location.pathname}/Detailing/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray["DetailingResult"]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Detailing"
                                defaultMessage="Detailing"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Detailing"
                                defaultMessage="Detailing"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col no-left-curve">
                    <div
                      className={getClassName(
                        "Invoicing+CustomerFollowUpResult"
                      )}
                    >
                      {props.Versioning & Editing ? (
                        <i
                          onClick={() =>
                            openModal("Invoicing+CustomerFollowUp", "", [
                              "Percentage",
                            ])
                          }
                          className="admin-icon fas fa-wrench"
                        ></i>
                      ) : (
                        ""
                      )}
                      {props.FinalArray["Invoicing+CustomerFollowUp"] &&
                      props.FinalArray["Invoicing+CustomerFollowUp"].total !==
                        0 ? (
                        <>
                          <div
                            onClick={() => {
                              openTaskList(
                                props.FinalArray["Invoicing+CustomerFollowUp"][
                                  "Tasks"
                                ],
                                props.setScrollPos,
                                props.passTaskListComp,
                                props.passResult,
                                props.FinalArray[
                                  "Invoicing+CustomerFollowUpResult"
                                ],
                                props.FinalArray["Invoicing+CustomerFollowUp"],
                                "Invoicing + Customer Follow Up",
                                "Invoicing+CustomerFollowUp",
                              )
                              history.push(`${location.pathname}/Invoicing+CustomerFollowUp/tasks`, { from: "collapsible" });
                            }}
                          >
                            <div className="value-ratio">
                              {checkPercentage(
                                props.FinalArray[
                                  "Invoicing+CustomerFollowUpResult"
                                ]
                              )}
                              %
                            </div>
                            <div className="label">
                              <FormattedMessage
                                id="Invoicing+CustomerFollowUp"
                                defaultMessage="Invoicing + Customer Follow Up"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="value-ratio">N.A</div>
                            <div className="label">
                              <FormattedMessage
                                id="Invoicing+CustomerFollowUp"
                                defaultMessage="Invoicing + Customer Follow Up"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="circle-each bg-blue">
                      <img src="/images/vpn_key_black_24dp@1X.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.Versioning & Editing ? (
              <i
                style={{
                  marginBottom: "40px",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                onClick={() => openModal("GroupedbyDate", "", ["Repair"])}
                className="fas fa-wrench"
              ></i>
            ) : (
              ""
            )}
            <ShowRepairQuality
              canEdit={props.canEdit}
              setModalImage={props.setModalImage}
              setCurrentImg={props.setCurrentImg}
              noTeamwork={props.noTeamwork}
              dev={props.dev}
              Tags={props.Tags}
              Editing={props.Editing}
              setTasks={props.setTasks}
              SetTranslationOnlyAllow={props.SetTranslationOnlyAllow}
              SetTranslationAddon={props.SetTranslationAddon}
              setTranslationModal={props.setTranslationModal}
              setMilestoneTranslation={props.setMilestoneTranslation}
              setOnlyMilestone={props.setOnlyMilestone}
              idd={props.idd}
              TasklistNumber={props.TasklistNumber}
              passResult={props.passResult}
              passTaskListComp={props.passTaskListComp}
              openModal={props.openModal}
              /* Editing={Editing} */ Versioning={props.Versioning}
              tasks={props.tasks}
              FinalArray={props.FinalArray}
            />
            <div id="Training" className="row heading-block">
              <div className="col-lg-12 pl-0">
                <h1 className="main-heading">
                  <FormattedMessage id="Training" defaultMessage="Training" />
                </h1>
              </div>
            </div>
            <div className="container-fluid mb-t-20 plr-0">
              <div className="row mobile-scroll-y">
                {props.Versioning & Editing ? (
                  <i
                    onClick={() => openModal("TrainingOne", "", ["Percentage"])}
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["TrainingOne"] ? (
                  <div
                    style={{ position: "relative" }}
                    className="min-h img-text-block d-flex"
                  >
                    {props.FinalArray["TrainingOne"].total == 0 && (
                      <>
                        <div
                          style={{
                            borderRadius: "16px",
                            backgroundColor: "#808080b4",
                            zIndex: 10,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            position: "absolute",
                          }}
                        >
                          <div
                            style={{ left: "30%" }}
                            className="centered-text-overlay-bay"
                          >
                            <FormattedMessage
                              id="NotAvailable"
                              defaultMessage="Not Available"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="content">
                      <h5>
                        <b>
                          <FormattedMessage
                            id="Training"
                            defaultMessage="Training"
                          />
                        </b>
                      </h5>
                      <p className="mg-b-0 small-text">
                        {props.FinalArray["TrainingOne"].complete}/
                        {props.FinalArray["TrainingOne"].total}{" "}
                        <FormattedMessage
                          id="Taskscompleted"
                          defaultMessage="Tasks completed"
                        />
                      </p>
                      <div
                        style={{
                          width: "80%",
                          margin: "auto",
                          marginTop: "2rem",
                        }}
                      >
                        {" "}
                        <PieChart
                          className="printPie"
                          percentage={props.FinalArray["TrainingOneResult"]}
                        />{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  "\u00A0"
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("TrainingFour", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["TrainingFour"] ? (
                  <div
                    style={{ position: "relative" }}
                    className="min-h img-text-block d-flex"
                  >
                    {props.FinalArray["TrainingFour"].total == 0 && (
                      <>
                        <div
                          style={{
                            borderRadius: "16px",
                            backgroundColor: "#808080b4",
                            zIndex: 10,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            position: "absolute",
                          }}
                        >
                          <div
                            style={{ left: "30%" }}
                            className="centered-text-overlay-bay"
                          >
                            <FormattedMessage
                              id="NotAvailable"
                              defaultMessage="Not Available"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      style={{ marginTop: "2rem", paddingBottom: "0" }}
                      className="content"
                    >
                      <div className="d-flex justify-content-between text-number-block height-115">
                        <div style={{ width: "60%" }} className="col-first">
                          <img
                            src="/images/people-icon.svg"
                            alt="car svg"
                            className="img-car"
                            style={{ maxHeight: "80px", maxWidth: "115px" }}
                          />
                        </div>
                        <div
                          style={{ width: "40%" }}
                          className=" flexTraining col-second "
                        >
                          <span className="fontTrainingNumber">
                            {props.Versioning & Editing ? (
                              <i
                                onClick={() =>
                                  openModal("TraningFive", "", [
                                    "Comment",
                                    "calculation",
                                  ])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                            {props.FinalArray["TraningFiveResult"] &&
                              props.FinalArray["TraningFiveResult"]}{" "}
                          </span>
                          <br />
                          <span className="fontTrainingPeople">
                            <FormattedMessage
                              id="people"
                              defaultMessage="people"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <Progressbar
                      width={props.FinalArray["TrainingFourResult"]}
                    />
                    <div style={{ marginTop: "0" }} className="img-content">
                      <h5>
                        <FormattedMessage
                          id="Administration"
                          defaultMessage="Administration"
                        />
                      </h5>
                      <p className="mg-b-0 small-text">
                        {props.FinalArray["TrainingFour"].complete}/
                        {props.FinalArray["TrainingFour"].total}{" "}
                        <FormattedMessage
                          id="Taskscompleted"
                          defaultMessage="Tasks completed"
                        />
                      </p>
                      <div className="stats">
                        {checkPercentage(
                          props.FinalArray["TrainingFourResult"]
                        )}
                        %
                      </div>
                      <div
                        className="TrainingModules"
                        onClick={() => {
                          openTaskList(
                            props.FinalArray["TrainingFour"]["Tasks"],
                            props.setScrollPos,
                            props.passTaskListComp,
                            props.passResult,
                            props.FinalArray["TrainingFourResult"],
                            props.FinalArray["TrainingFour"],
                            "Training Four",
                            "TrainingFour",
                            "Administration",
                          )
                          history.push(`${location.pathname}/TrainingFour/tasks`, { from: "collapsible" });
                        }}
                        data-popup-id="popup-1"
                      >
                        <FormattedMessage
                          id="ShowTrainingModulesLower"
                          defaultMessage="Show Training Modules"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  "\u00A0"
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() => openModal("TrainingTwo", "", ["Percentage"])}
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["TrainingTwo"] ? (
                  <div
                    style={{ position: "relative" }}
                    className="min-h img-text-block d-flex"
                  >
                    {props.FinalArray["TrainingTwo"].total == 0 && (
                      <>
                        <div
                          style={{
                            borderRadius: "16px",
                            backgroundColor: "#808080b4",
                            zIndex: 10,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            position: "absolute",
                          }}
                        >
                          <div
                            style={{ left: "30%" }}
                            className="centered-text-overlay-bay"
                          >
                            <FormattedMessage
                              id="NotAvailable"
                              defaultMessage="Not Available"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      style={{ marginTop: "2rem", paddingBottom: "0" }}
                      className="content"
                    >
                      <div className="d-flex justify-content-between text-number-block height-115">
                        <div style={{ width: "60%" }} className="col-first">
                          <img
                            src="/images/repair-icon.svg"
                            alt="car svg"
                            className="img-car"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div
                          style={{ width: "40%" }}
                          className="flexTraining col-second"
                        >
                          <span className="fontTrainingNumber">
                            {props.Versioning & Editing ? (
                              <i
                                onClick={() =>
                                  openModal("TrainingSix", "", [
                                    "Comment",
                                    "calculation",
                                  ])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                            {props.FinalArray["TrainingSixResult"] &&
                              props.FinalArray["TrainingSixResult"]}
                          </span>
                          <br />
                          <span className="fontTrainingPeople">
                            <FormattedMessage
                              id="people"
                              defaultMessage="people"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <Progressbar
                      width={props.FinalArray["TrainingTwoResult"]}
                    />
                    <div style={{ marginTop: "0" }} className="img-content">
                      <h5>
                        <FormattedMessage
                          id="BodyRepair"
                          defaultMessage="Body Repair"
                        />
                      </h5>
                      <p className="mg-b-0 small-text">
                        {props.FinalArray["TrainingTwo"].complete}/
                        {props.FinalArray["TrainingTwo"].total}{" "}
                        <FormattedMessage
                          id="Taskscompleted"
                          defaultMessage="Tasks completed"
                        />
                      </p>
                      <div className="stats">
                        {checkPercentage(props.FinalArray["TrainingTwoResult"])}
                        %
                      </div>
                      <div
                        className="TrainingModules"
                        onClick={() => {
                          openTaskList(
                            props.FinalArray["TrainingTwo"]["Tasks"],
                            props.setScrollPos,
                            props.passTaskListComp,
                            props.passResult,
                            props.FinalArray["TrainingTwoResult"],
                            props.FinalArray["TrainingTwo"],
                            "Body Repair",
                            "Training Two",
                          )
                          history.push(`${location.pathname}/TrainingTwo/tasks`, { from: "collapsible" });
                        }}
                        data-popup-id="popup-1"
                      >
                        <FormattedMessage
                          id="ShowTrainingModules"
                          defaultMessage="Show Training Modules"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  "\u00A0"
                )}
                {props.Versioning & Editing ? (
                  <i
                    onClick={() =>
                      openModal("TrainingThree", "", ["Percentage"])
                    }
                    className="fas fa-wrench"
                  ></i>
                ) : (
                  ""
                )}
                {props.FinalArray["TrainingThree"] ? (
                  <div
                    style={{ position: "relative" }}
                    className="min-h img-text-block d-flex"
                  >
                    {props.FinalArray["TrainingThree"].total == 0 && (
                      <>
                        <div
                          style={{
                            borderRadius: "16px",
                            backgroundColor: "#808080b4",
                            zIndex: 10,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            position: "absolute",
                          }}
                        >
                          <div
                            style={{ left: "30%" }}
                            className="centered-text-overlay-bay"
                          >
                            <FormattedMessage
                              id="NotAvailable"
                              defaultMessage="Not Available"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      style={{ marginTop: "2rem", paddingBottom: "0" }}
                      className="content"
                    >
                      <div className="d-flex justify-content-between text-number-block height-115">
                        <div style={{ width: "60%" }} className="col-first">
                          <img
                            src="/images/paint-spraygun-icon.svg"
                            alt="car svg"
                            className="img-car"
                            style={{ maxHeight: "80px" }}
                          />
                        </div>
                        <div
                          style={{ width: "40%" }}
                          className=" flexTraining col-second"
                        >
                          <span className="fontTrainingNumber">
                            {props.Versioning & Editing ? (
                              <i
                                onClick={() =>
                                  openModal("TrainingSeven", "", [
                                    "Comment",
                                    "calculation",
                                  ])
                                }
                                className="fas fa-wrench"
                              ></i>
                            ) : (
                              ""
                            )}
                            {props.FinalArray["TrainingSevenResult"] &&
                              props.FinalArray["TrainingSevenResult"]}
                          </span>
                          <br />
                          <span className="fontTrainingPeople">
                            <FormattedMessage
                              id="people"
                              defaultMessage="people"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <Progressbar
                      width={props.FinalArray["TrainingThreeResult"]}
                    />
                    <div style={{ marginTop: "0" }} className="img-content">
                      <h5>
                        <FormattedMessage id="Paint" defaultMessage="Paint" />
                      </h5>
                      <p className="mg-b-0 small-text">
                        {props.FinalArray["TrainingThree"].complete}/
                        {props.FinalArray["TrainingThree"].total} Tasks
                        completed
                      </p>
                      <div className="stats">
                        {checkPercentage(
                          props.FinalArray["TrainingThreeResult"]
                        )}
                        %
                      </div>
                      <div
                        className="TrainingModules"
                        onClick={() => {
                          openTaskList(
                            props.FinalArray["TrainingThree"]["Tasks"],
                            props.setScrollPos,
                            props.passTaskListComp,
                            props.passResult,
                            props.FinalArray["TrainingThreeResult"],
                            props.FinalArray["TrainingThree"],
                            "Paint",
                            "TrainingThree",
                          )
                          history.push(`${location.pathname}/TrainingThree/tasks`, { from: "collapsible" });
                        }}
                      >
                        <FormattedMessage
                          id="ShowTrainingModules"
                          defaultMessage="Show Training Modules"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  "\u00A0"
                )}
              </div>
            </div>
            {/* pd-25 */}
            <Footer />
          </div>
          {/* main */}
          {/* Popup Code */}
        </div>
      </>
    );
  } else {
    return <Spinner />;
  }
};

export default ShowSingleProjectSlides;

import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import { intlFormat } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select"
import { format, parse, addDays } from 'date-fns';
import { Spin } from 'antd';


const ProjectTasklistSecondScreen = (props) => {
  let history = useHistory();
  const intl = useIntl();
  const [imgloader, setImageLoader] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [TextField, setTextField] = useState("");
  const [FilesField, setFilesField] = useState(false);
  const [ThumbsUp, setThumbsUp] = useState(false);
  const [Display, setDisplay] = useState(true);
  const [newComment, setNewComment] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [saved, setSaved] = useState(true);
  const [pictures, setPictures] = useState([]);
  const [picturesTwo, setPicturesTwo] = useState(false);
  const [userNames, setUserNames] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(false);
  const [deleted, setDeleted] = useState([]);
  const [currentItem, setCurrentItem] =  useState(props.selectedItem)

  const firstUpdate = useRef(true);

  const location = useLocation();

  useEffect(() => {

    if (firstUpdate.current) {
      const req = axios.get(`/api/private/userNames`, config)
        .then((response) => {
          let usernamesarr = []
          response.data.forEach(item => {
            let obj = {}
            obj.value = item.username
            obj.label = item.username
            usernamesarr.push(obj)
          })
          setUserNames(usernamesarr)

        })
      if (parseInt(props.selectedItem[10]) === 20 ) {
        setThumbsUp("Down")
      }if (parseInt(props.selectedItem[10]) === 100 ) {
        setThumbsUp("Up")
      }
      if (props.selectedItem[8]) {
        setStartDate(parse(props.selectedItem[8], 'yyyyMMdd', new Date()))

      }
      if (props.selectedItem[9]) {
        setSelectedPerson({
          value: props.selectedItem[9],
          label: props.selectedItem[9]
        })
      }
      firstUpdate.current = false;
    }

    // if (props.selectedItem[20]) {
    //   setPicturesTwo(props.selectedItem[20])
    // }
    if (currentItem[4] && currentItem[5]) {
      setPictures(currentItem[5])
    }

  })

  useEffect(() => {
    if (currentItem[4] && currentItem[5]) {
      setPictures(currentItem[5])
    }
  },[currentItem, pictures])


  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setSaved(false);
  };

  const handlePersonChange = (selectedOption) => {
    setSelectedPerson(selectedOption);
    setSaved(false);
  };

  const openModalPics = (pictures, Index) => {
    let Img = pictures[0]

    if (props.noTeamwork) {
      let fullImg = (<img style={{ maxWidth: "90vw", maxHeight: "60vh" }} className="ImgModalPic" src={pictures[0]} />)
      props.setCurrentImg([fullImg, Index])
      props.setModalImage(pictures)
      return
    }

    try {
      axios.get(`/api/private/files/${Img}`, config).then(res => {
        const image = res.data.file["download-URL"]
        let fullImg = (<img style={{ maxWidth: "90vw", maxHeight: "60vh" }} className="ImgModalPic" src={image} />)
        props.setCurrentImg([fullImg, Index])
        props.setModalImage([pictures])
      }
      )
    } catch (e) {
      if (!props.dev) {
        localStorage.removeItem("authToken");
        window.location.href = '/logout';
      }
    }
  }

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const handleTextChange = (e) => {
    setSaved(false)
    setTextField(e.target.value)
  }

  // const currentMilestoneId = props.Milestone._id;
  // const milestoneIndex = props.MilestoneResultState.findIndex(milestone => milestone._id === currentMilestoneId);
  const Milestone = JSON.parse(JSON.stringify(props.Milestone));
  let arr = [];
  Object.entries(props.allTasks).map((entry) => {
    const [key, value] = entry;
    if (value[3] && Milestone.Tasks?.includes(key)) {
      arr.push({
        label: value[3],
        value: key,
      });
    }
  });

  const handleFileChange = (e) => {
    //setSaved(false)

    const handleFileSave = () => {
      setImageLoader(true);
      const data = new FormData()

      data.append("keyOfTask", currentItem[3])
      data.append("ProjectID", props.idd)
      data.append("milestone", JSON.stringify(Milestone))
      data.append("Tasks", JSON.stringify(arr))
      data.append("activityUrl", location.pathname)
      if (TextField && TextField !== "") {
        data.append('text', TextField)
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      data.append('file', e.target.files[0])
      if(e.target.files[0].name !== ""){
      axios.post(`/api/private/uploadimg/${props.idd}`, data, config)
        .then((response) => {
          //setFilesField("")
          //setSaved(true)
          if(currentItem[4] === false){
            currentItem[4] = []
          }
          if(currentItem[5] === false){
            currentItem[5] = []
          }
          currentItem[4].push(response.data[0])
          currentItem[5].push(response.data[1])
          props.Resultpictures.push(response.data[0],response.data[1])
          setImageLoader(false);
          //setPictures(currentItem[5].push(response.data[1]))
          //props.selectedItem[5]=  props.selectedItem[5].push(response.data[1])
          // for (let i = 0; i < props.CustomQuestions.length; i++) {
          //   if (props.CustomQuestions[i][3] === props.selectedItem[3]) {
          //     let customQ = props.CustomQuestions
    
          //     customQ[i][4] = props.selectedItem[4].push(response.data[0])
          //     customQ[i][5] = props.selectedItem[5].push(response.data[1])
          //     if (TextField && TextField !== "") {
          //       customQ[7] = TextField
          //     }
    
          //     props.setCustomQuestions(customQ)
          //     break;
          //   }
          // }
          
          // let newItem = props.selectedItem
          // newItem[4] = props.selectedItem[4].push(response.data[0])
          // newItem[5] = props.selectedItem[5].push(response.data[1])
          // if (TextField && TextField !== "") {
          //   newItem[7] = TextField
          // }

          // props.setSelectedItem(newItem);
          //return response.data
          //props.setSelectedItem(newItem);
          //setPictures(newItem[4] && newItem[5])
          return;
        }, (error) => {
          console.log(error)
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        })
      }
    }
    handleFileSave()

    /*
        setFilesField(e.target.files[0]) */
  }


  const deletePic = (e) => {
    if (e == "File") {
      setFilesField(false)
    } else {



      const handleDeleteTask = () => {
        //setSaved(false)
        const NameOfTask = props.selectedItem[3]
        const PostData = {
          NameOfTask: NameOfTask,
          keyOfTask: currentItem[3],
          delete: [currentItem[4][e], currentItem[5][e]],
          milestone: {
            ...Milestone,
            Tasks: arr
          },
          activityUrl: location.pathname,
        }

        PostData["ProjectID"] = props.idd
        const filteredItemsFour = currentItem[4].filter(item => item !== currentItem[4][e])
        const filteredItemsFive = currentItem[5].filter(item => item !== currentItem[5][e])
        const sendToServer = async () => {
          try {
            const config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            };
            const data = await axios.put(`/api/private/deleteIMG/${props.idd}`, PostData, config);
            currentItem[4] = filteredItemsFour
            currentItem[5] = filteredItemsFive
            //setPictures(filteredItemsFive)
            return;
          } catch (e) {
            if (!props.dev) {
              localStorage.removeItem("authToken");
              window.location.href = '/logout';
            }
          } finally {
          }
        }
        sendToServer().then(item => {
          setSaved(true)
        })
      }
      if (window.confirm("Do you really want to delete it?")) {
        handleDeleteTask()
      }
      /* setDeleted(prevState=>{
        prevState.push()
      }) */
      //console.log(props.selectedItem[4][e])
      //console.log(e)
      /* setDeleted() */
      /* index */
    }
    setSaved(false)

  }

  const Rename = (name) => {
    name = name.split(' ')
    name.shift()
    name = name.join(' ')
    return name
  }
  const GetCodeRename = (name) => {
    name = name.split(' ')
    name = name[0];
    return name
  }
  const handleSave = () => {
    const handleFinishTask = (e) => {
      const NameOfTask = props.selectedItem[0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.selectedItem[3],
        milestone: {
          ...Milestone,
          Tasks: arr
        }
      }
      if (startDate) {
        PostData.startDate = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        PostData.selectedPerson = selectedPerson.value
      }
      /* if () */

      PostData["ProjectID"] = props.idd

      for (let i = 0; i < props.CustomQuestions.length; i++) {
        if (props.CustomQuestions[i][3] === props.selectedItem[3]) {
          let customQ = props.CustomQuestions
          customQ[i][1] = true
          customQ[i][10] = 100
          if (startDate) {
            customQ[i][8] = format(startDate, "yyyyMMdd")
          }
          if (selectedPerson) {
            customQ[i][9] = selectedPerson
          }
          if (TextField && TextField !== "") {
            customQ[i][7] = TextField
          }
          if (FilesField) {
            //customQ[i][20] = "reload to see new pictures"
          }
          props.setCustomQuestions(customQ)
          break;
        }
      }

      let newItem = props.selectedItem
      newItem[1] = true
      newItem[10] = 100
      if (startDate) {
        newItem[8] = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        newItem[9] = selectedPerson.value
      }
      if (TextField && TextField !== "") {
        newItem[7] = TextField
      }
      if (FilesField) {
        newItem[20] = "reload to see new pictures"
      }
      props.setSelectedItem(newItem);


      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/finishMilestone/${props.idd}`, PostData, config);
          return data
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        } finally {
        }
      }
      sendToServer().then(item => {
        setSaved(true)
      })
    }

    const handleProgressTask = (e) => {
      const NameOfTask = props.selectedItem[0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.selectedItem,
        milestone: {
          ...Milestone,
          Tasks: arr
        }
      }
      if (startDate) {
        PostData.startDate = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        PostData.selectedPerson = selectedPerson.value
      }
      PostData["ProjectID"] = props.idd

      
      for (let i = 0; i < props.CustomQuestions.length; i++) {
        if (props.CustomQuestions[i][3] === props.selectedItem[3]) {
          let customQ = props.CustomQuestions
          customQ[i][10] = 20
          if (startDate) {
            customQ[i][8] = format(startDate, "yyyyMMdd")
          }
          if (selectedPerson) {
            customQ[i][9] = selectedPerson
          }
          props.setCustomQuestions(customQ)
          break;
        }
      }

      let newItem = props.selectedItem
      if (startDate) {
        newItem[8] = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        newItem[9] = selectedPerson.value
      }
      newItem[10] = 20
      props.setSelectedItem(newItem);

      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/progressMilestone/${props.idd}`, PostData, config);
          return data
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        } finally {
        }
      }
      sendToServer().then(item => {
        setSaved(true)

      })
    }



    const handleSaveComment = (e) => {
      const NameOfTask = props.selectedItem[0]
      let progress
      if (ThumbsUp === "Up") {
        progress = 100
      } else {
        progress = 20
      }
      const PostData = {
        NameOfTask: NameOfTask,
        Textbody: TextField,
        Progress: progress,
        keyOfTask: props.selectedItem[3],
        milestone: {
          ...Milestone,
          Tasks: arr
        },
        activityUrl: location.pathname,
      }
      if (startDate) {
        PostData.startDate = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        PostData.selectedPerson = selectedPerson.value
      }

      PostData["ProjectID"] = props.idd
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      for (let i = 0; i < props.CustomQuestions.length; i++) {
        if (props.CustomQuestions[i][3] === props.selectedItem[3]) {
          let customQ = props.CustomQuestions
          if (ThumbsUp == "Down" && !FilesField) {
            customQ[i][1] = false
            customQ[i][10] = 20
            customQ[i][7] = TextField
          } else if ((ThumbsUp === "Up" && !FilesField)) {
            customQ[i][1] = true
            customQ[i][10] = 100
            customQ[i][7] = TextField
          }
          if (startDate) {
            customQ[i][8] = format(startDate, "yyyyMMdd")
          }
          if (selectedPerson) {
            customQ[i][9] = selectedPerson
          }
          props.setCustomQuestions(customQ)
          break;
        }
      }

      if (ThumbsUp === "Down" && !FilesField) {
        let newItem = props.selectedItem
        newItem[1] = false
        newItem[10] = 20
        if (startDate) {
          newItem[8] = format(startDate, "yyyyMMdd")
        }
        if (selectedPerson) {
          newItem[9] = selectedPerson.value
        }
        newItem[7] = TextField
        props.setSelectedItem(newItem);
        setCurrentItem(newItem)

      } else if ((ThumbsUp === "Up" && !FilesField)) {
        let newItem = props.selectedItem
        newItem[1] = true
        if (startDate) {
          newItem[8] = format(startDate, "yyyyMMdd")
        }
        if (selectedPerson) {
          newItem[9] = selectedPerson.value
        }
        newItem[10] = 100
        newItem[7] = TextField
        props.setSelectedItem(newItem);
        setCurrentItem(newItem)
      }

      const req = axios.post(`/api/private/comments/${props.idd}`, PostData, config)
        .then((response) => {
          currentItem[7] = response.data.comment

          setTextField("")
          setSaved(true)
          return response
        }, (error) => {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        })
    }



   if (TextField && TextField !== "") {
      handleSaveComment()
    } else {
      if (ThumbsUp === "Up") {
        handleFinishTask()
      } else if (ThumbsUp === "Down") {
        handleProgressTask()
      }
    }

  }






  return (
    <>
      <div className="d-flex flex-column pd-20 overflow-y-auto modal-content-wrapper">
        <div className="row mb-2 px-3">
          <h5 className='font-bold'><FormattedMessage id={props.selectedItem && props.selectedItem[3]} /></h5>
        </div>
        <div className="row inner-popup align-items-center">
          <div className="col-md-3 mb-2 d-flex flex-row align-items-center">
            <h6 className='mr-10 mb-0'><FormattedMessage id="DueDate" defaultMessage="Due Date" /></h6>
            {/* {props.selectedItem[8] ? <h6>{props.selectedItem[8]}</h6> : ""} */}
            <DatePicker selected={startDate} onChange={(date) => handleDateChange(date)} />
          </div>
          <div className="col-md-3 mb-2 d-flex flex-row align-items-center">
            <h6 className='mr-10 mb-0'><FormattedMessage id="Assignedto" defaultMessage="Assigned to" /></h6>
            {/*  {props.selectedItem[9] ? <h6>{props.selectedItem[9]}</h6> : ""} */}
            <div class="select-wrapper">
              <Select

                onChange={(selectedOption) => handlePersonChange(selectedOption)}
                value={selectedPerson}
                placeholder={intl.formatMessage({ id: "SelectPerson", defaultMessage: "Select Person" })}
                options={userNames}
              />
            </div>
          </div>
          <div class="col-md-3  mb-2">
            {/*  <Select
              placeholder={intl.formatMessage({ id: "AddtoMilestone", defaultMessage: "Add to Milestone" })}
              options={[{ value: "Milestone 1", label: "Milestone 1" }, { value: "Milestone 2", label: "Milestone 2" }, { value: "Milestone 3", label: "Milestone 3" }, { value: "Milestone 4", label: "Milestone 4" }]}
            /> */}
          </div>
          <div className='col-md-3 mb-2  d-flex justify-content-md-end'>


            <i className={ThumbsUp === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} onClick={() => { setThumbsUp("Up"); setSaved(false); }} class="far fa-thumbs-up task-thumb-up"></i>
            <i className={ThumbsUp === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} onClick={() => setThumbsUp("Down")} class="far fa-thumbs-down task-thumb-down"></i>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-2">
            <h6>Comments </h6>
            {props.canEdit ?
              <>
                <textarea className="textarea-styling"
                  placeholder={intl.formatMessage({ id: "Addyourcommenthere", defaultMessage: "Add your comment here..." })}
                  value={TextField}
                  onChange={e => handleTextChange(e)}
                />
              </> : ""}
          </div>
          <div className="col-md-6  mb-2 ">
            <div className='last-comments-wrapper'>
              <div class="single-comment">

                {props.selectedItem[7] ? <p>{props.selectedItem[7]}</p>
                  : <p>no comments yet</p>}
              </div>

            </div>
          </div>
        </div>
        {props.canEdit ?
          <>
            <h6 className='px-3 mt-2'><FormattedMessage id="PleaseSubmitaphoto" defaultMessage="Please Submit a photo" /></h6>
            <div className="row-photos d-flex mt-2">
              {pictures && pictures.length > 0 ?
                pictures.map((item, index) => {
                  return (
                    <div className="fixHeight item fillcontainer imgtasklistcontainer  pos-rel fillcontainer-dim">
                      <div onClick={() => deletePic(index)} className="delete-icon-wrapper">
                        <i class="fas fa-trash cursor-pointer"></i>
                      </div>
                      <img className="imgfill" onClick={() => openModalPics(pictures, index)} src={item} alt="img" />
                    </div>
                  )
                })
                : ""
              }
              <><label style={{ marginBottom: "0" }} >
                <input accept="image/png,image/jpg,image/jpeg" capture="user" type="file" name="file"  onChange={e => handleFileChange(e)} disabled={imgloader ? true : false}/>
                <div style={{
                  height: "140px",
                  border: "1px solid black",
                  width: "140px",
                  borderRadius: "20px",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer"
                }} className="item fillcontainer imgtasklistcontainer">
                  {imgloader ? <Spin /> : <i style={{ margin: "auto", fontSize: "70px" }} class="fas fa-image"></i>}
                </div>
              </label>
              </>
            </div>
          </>
          :
          <>
            {FilesField ?
              <div className="fixHeight item fillcontainer fillcontainer-dim">

                <img className="imgfill imgtasklist" onClick={() => openModalPics(pictures, 0)} src={URL.createObjectURL(FilesField)} alt="img" />
              </div>

              :
              picturesTwo ?
                <div className="item fillcontainer fillcontainer-dim">
                  <img style={{ cursor: "inital" }} className="imgfill imgtasklist" src={picturesTwo} alt="img" />
                </div>
                : pictures.length > 0 ?
                  <div className="item fillcontainer fillcontainer-dim">
                    <img className="imgfill imgtasklist" onClick={() => openModalPics(pictures, 0)} src={pictures[1]} alt="img" />
                  </div>
                  : ""
            }
          </>
        }

       <div className="d-flex justify-content-end align-items-center">
          <button className='cta-btn cta-outline mr-10'
          onClick={() => { props.setSecondScreenShow(false);
            history.push(location.pathname.split('/').slice(0,-2).join('/'));
          }}><FormattedMessage id="Cancel" defaultMessage="Cancel" /></button>
          {props.canEdit && !saved ? 
            <button className={!saved && (ThumbsUp === "Up" || ThumbsUp === "Down") ? 'cta-btn' : ' cta-btn notclick'}
              onClick={
                !saved  ? () => handleSave() : () => false
              }><FormattedMessage id="Save" defaultMessage="Save" /></button>
            :
            <button className={saved && (ThumbsUp === "Up" || ThumbsUp === "Down") ? 'cta-btn' : ' cta-btn notclick'}
            onClick={() => { props.setSecondScreenShow(false);
              history.push(location.pathname.split('/').slice(0,-2).join('/'));
            }}><FormattedMessage id="Done" defaultMessage="Done" /></button>
            }
        </div>
      </div>
    </>
  )
}

export default ProjectTasklistSecondScreen
import { Tooltip } from 'antd';

function TranslationButton({ 
  detectedSourceLanguage, 
  targetLanguage,
  translated, 
  onTranslate, 
  onShowOriginal 
}) {
  const renderButtons = () => {
    if (translated)
      return (
        <>
          <Tooltip title={`${detectedSourceLanguage} > ${targetLanguage}`}>
            <button
              style={{border: "none", background: "transparent"}}
              className="translation-button" 
              onClick={() => onTranslate()}>
              <svg id="translate_black_24dp" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                <path id="Path_612" data-name="Path 612" d="M0,0H24V24H0Z" fill="none"/>
                <g id="Group_368" data-name="Group 368">
                  <path id="Path_613" data-name="Path 613" d="M20,5H10.9L10,2H4A2.006,2.006,0,0,0,2,4V17a2.006,2.006,0,0,0,2,2h7l1,3h8a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM9.5,14.6l-.8-2.1H5.5l-.8,2.1H3.3L6.4,6.4H7.8l3.1,8.2Zm3.2-3.5h4a6.612,6.612,0,0,1-1.6,2.7A6.9,6.9,0,0,1,14,12.1H13Zm1.7,3.5-.5.5-.6-2.2A4.517,4.517,0,0,0,14.4,14.6ZM21,20a1.075,1.075,0,0,1-1,1H13l2-2-.8-2.8.9-.9L17.8,18l.7-.7-2.7-2.7a8.011,8.011,0,0,0,1.9-3.5H19v-1H15.4V9h-1v1h-2L11.2,6H20a1.075,1.075,0,0,1,1,1Z" fill="#6a7284"/>
                  <path id="Path_614" data-name="Path 614" d="M6,11.2H8.2l-1.1-3Z" fill="#6a7284"/>
                </g>
              </svg>
            </button>
          </Tooltip>
        </>
      )
    else
      return (
        <>
          <button
            style={{border: "none", background: "transparent"}}
            className="translation-button fas fa-light fa-arrow-left"
            onClick={() => onShowOriginal()}
          ></button>
        </>
      )
  };

  return <>{renderButtons()}</>;
}

export default TranslationButton;

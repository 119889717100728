import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import DefineTagsElements from "./defineTagsElements";
import SpinnerTags from "../spinnerTags";
import axios from "axios";
import { FormattedMessage } from "react-intl";

const DefineTags = (props) => {
  const [possibleTags, setPossibleTags] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [saved, setSaved] = useState(true);
  const [saveable, setsaveable] = useState(true);
  const [assessmentName,setAssessmentName] =  useState(props.Name)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  function handleChange(event) {
    setAssessmentName(event.target.value)
    setSaved(false)


  }

  useEffect(() => {
    console.log(props);
    const fetch = async () => {
      const AllTagsFetch = axios.get(
        `/api/private/allTags/${props.idd}`,
        config
      );
      const SelectedTagsFetch = axios.get(`/api/private/selectedTags`, config);
      try {
        const [AllTags, SelectedTags] = await Promise.all([
          AllTagsFetch,
          SelectedTagsFetch,
        ]);
        console.log(AllTags.data.tags);
        console.log(SelectedTags.data);
        let FilteredTags = AllTags.data.tags.filter((item) => {
          return SelectedTags.data.includes(parseInt(item["id"]));
        });
        console.log(FilteredTags);
        setPossibleTags(FilteredTags);
        setNewTags(props.Tags);

        console.log(FilteredTags);
      } catch (e) {
        console.log(e);
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    };

    fetch();
  }, []);

  if (possibleTags) {
    return (
      <div
        style={{ display: "Block", zIndex: "1000" }}
        id="popup-1"
        className="fq-modal"
      >
        <div
          style={{ maxWidth: "500px" }}
          id="define-tags"
          className="fq-modal-content"
        >
          <div className="fq-modal-header">
            <span onClick={() => props.SetTagsModal(false)} className="close">
              <img src="/images/close-btn.svg" alt="btn close" />
            </span>
            <h3 className="main-heading text-white">
              <FormattedMessage
                id="SetTagsforAssessment"
                defaultMessage="Set Tags for Assessment"
              />
            </h3>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "60vh",
              paddingTop: "40px",
              paddingBottom: "30px",
              overflowY: "auto",
            }}
            className="modal-content-wrapper"
          >
            <div style={{ padding: "15px 30px" }} className="row d-flex">
              <div
                style={{
                  flexDirection: "column",
                  width: "100%",
                  position: "relative",
                  marginLeft: "37px",
                  marginRight: "20px",
                  display: "flex",
                }}
              >

                  <input className="form-control mb-3" id="input-assessmenttype" value={assessmentName} onChange={handleChange}/>


                {possibleTags.map((item, index) => {
                  return (
                    <DefineTagsElements
                      Tags={props.Tags}
                      newTags={newTags}
                      setNewTags={setNewTags}
                      possibleTags={possibleTags}
                      setSaved={setSaved}
                      item={item}
                      index={index}
                    />
                  );
                })}
              </div>
              {saved || !saveable ? (
                <button className="btn cta-btn btn-block mt-3 mb-2" disabled>
                  <FormattedMessage id="Save" defaultMessage="Save" />
                </button>
              ) : (
                <button
                  className=" btn cta-btn btn-block mt-3 mb-2"
                  onClick={() =>
                    props.save(
                      { Name: assessmentName, Tags: newTags, id: props._id },
                      setSaved
                    )
                  }
                >
                  <FormattedMessage id="Save" defaultMessage="Save" />
                </button>
              )}

            </div>

          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{ display: "Block", zIndex: "1000" }}
        id="popup-1"
        className="fq-modal"
      ></div>
    );
  }
};

export default DefineTags;

import React, { useState,useEffect } from 'react';
import { Drawer } from 'antd';
import { Input, Button } from 'antd';
import axios from 'axios';

const KPIDrawerSettings = (props) => {
  const [limits, setLimits] = useState({
    KeyToKeyDays: '',
    TotalSales: '',
    Throughput: '',
    PartsSales: '',
    PartsProfit: '',
    PaintCost: '',
   });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const handleSave = async () => {
    const updatedLimits = {};
      updatedLimits.KeyToKeyDays = limits.KeyToKeyDays;
      updatedLimits.TotalSales = limits.TotalSales;
      updatedLimits.Throughput = limits.Throughput;
      updatedLimits.PartsSales = limits.PartsSales;
      updatedLimits.PartsProfit = limits.PartsProfit;
      updatedLimits.PaintCost = limits.PaintCost;
    try {
      const response = await axios.post(`/api/kpi/v2/saveLimits/${props.projectID}`, updatedLimits, config);
      props.setVisible(false)
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimitChange = (key, value) => {
    setLimits(prevLimits => ({ ...prevLimits, [key]: value }));
  }

  const getKpiLimits = () => {
   axios.get(`/api/getLimits/${props.projectID}`,config).then((kpiresponse) => {
    if(kpiresponse.data){
      setLimits(prevState => Object.assign({}, prevState, {
      KeyToKeyDays: kpiresponse.data.KeyToKeyDays,
      TotalSales: kpiresponse.data.TotalSales,
      Throughput: kpiresponse.data.Throughput,
      PartsSales: kpiresponse.data.PartsSales,
      PartsProfit: kpiresponse.data.PartsProfit,
      PaintCost: kpiresponse.data.PaintCost
    }))
  }
})
  }

  useEffect(() => {
    getKpiLimits();
  },[])

  return (
    <Drawer
      title="Settings"
      placement="right"
      onClose={() => props.setVisible(false)}
      visible={props.visible}
    >

      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Keys to Key Days</label>
        <Input type="number" min={0} max={30} value={limits.KeyToKeyDays} onChange={e => handleLimitChange('KeyToKeyDays', e.target.value)} placeholder="Set Key to Key Days Limit" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Total Sales</label>
        <Input type="number" min={0} value={limits.TotalSales} onChange={e => handleLimitChange('TotalSales', e.target.value)} placeholder="Set Total Sales Limit" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Throughput</label>
        <Input type="number" min={0}  value={limits.Throughput} onChange={e => handleLimitChange('Throughput', e.target.value)} placeholder="Set Throughput Limit" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Parts Sales</label>
        <Input type="number" min={0}  value={limits.PartsSales} onChange={e => handleLimitChange('PartsSales', e.target.value)} placeholder="Set Limit for Parts Sales" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Parts Profit (%)</label>
        <Input type="number" min={0} max={100} value={limits.PartsProfit} onChange={e => handleLimitChange('PartsProfit', e.target.value)} placeholder="Set Limit for Parts Profit" />
      </div>
      <div className='input-wrapper mb-3'>
        <label className='mb-1'>Paint Cost (%)</label>
        <Input type="number" min={0} max={100} value={limits.PaintCost} onChange={e => handleLimitChange('PaintCost', e.target.value)} placeholder="Set Limit for Paint Cost" />
      </div>

      <Button onClick={handleSave} className="btn-primary">Save Limits</Button>
    </Drawer>
  );
};

export default KPIDrawerSettings;
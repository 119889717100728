import React, { memo }from "react";
import { FormattedMessage } from "react-intl";
import checkPercentage from '../../../sharedFunctions.js/checkpercentage'
import PieChart2 from '../../ProjectElementPie2';

const CardProjectDonut = memo((props)  => {
  const { bgClass, translationId, translationMessage, percentage, classes } = props;
  return (
    <div className={`card rounded-2 pd-16 border-gray border-radius-16 ${classes} ${bgClass}`}>
      <div className="card-body d-flex flex-column">
        <h3 className="card-c-heading">
          <FormattedMessage id={translationId} defaultMessage={translationMessage} />
        </h3>
        <div className="col-sm-12s mg-sm-t-0 d-flex align-items-center justify-content-center pos-relative w-70 m-auto">
          <PieChart2 className="printPie" percentage={checkPercentage(percentage)} />
        </div>
      </div>
    </div>
  );
});

export default CardProjectDonut;
import { Link, withRouter } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';
import { useIntl, FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import Cookie from 'js-cookie';

import Spinner from '../spinner';

const SingleUser = (props) => {
  console.log("SingleUser:")
  console.log(props)
  const intl = useIntl();
  const idd = props.match.params.id
  const [onload, setonload] = useState(false)
  const [error, setError] = useState("");
  const [Loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [username, setUsername] = useState(false);
  const [email, setEmail] = useState(false);
  const [Versioning, setVersioning] = useState(false);
  const [Notes, setNotes] = useState(false);
  const [UserAdmin, setUserAdmin] = useState(false);
  const [Renumbering, setRenumbering] = useState(false);
  const [SeeProjects, setSeeProjects] = useState([{ "value": 373762, "label": "Review 1 Version - Advanced Business Solutions Shop Assessment - BASF Body and Paint Audit 2021" }]);
  const [EditProjects, setEditProjects] = useState([]);
  const [changeProjects, setChangeProjects] = useState(false);
  const [Saved, SetSaved] = useState(false);
  const [projects, setProjects] = useState([]);
  const [Final, SetFinal] = useState(false);
  const [Rights, SetRights] = useState(false);
  const [GroupsDB, SetGroupsDB] = useState(false);
  const [createProjects, setCreateProjects] = useState(false);
  const [Groups, setGroups] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [role, setRole] = useState([]);
  const [Reset, setReset] = useState(false);
  const [newPassword,setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isSuperadmin, setIsSuperadmin] = useState(false);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  const userId = Cookie.get("localeUser");
  const roles = [
    { label: "User", value: "user" },
    { label: "Admin", value: "admin" },
    { label: "Superadmin", value: "superadmin" },
  ];

  useEffect(() => {
    if (Saved && onload) {
      SetSaved(false)
    }
  }, [SeeProjects, EditProjects, GroupsDB, role])

  // useEffect to fetch the user that is using the app, whose id we got in the line above, not the user whose information is on the screen. The purpose is to see if the user is superadmin to allow him enabling notifications to other users or not.
  useEffect(() => {
    async function fetchUser() {
      const response = await axios.get(`/api/private/users/${userId}`, config);
      const user = response.data;
      console.log("user:", user);
      const _isSuperadmin = user.role && (user.role === "superadmin");
      setIsSuperadmin(_isSuperadmin);
    }
    fetchUser()
      .catch(console.error);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const users = axios.get(`/api/private/users/${idd}`, config);
        const projects = axios.get(`/api/private/userdbprojects`, config);
        const groups = axios.get(`/api/private/groups`, config);
        const [usersDB, projectsDB, groupsDB] = await Promise.all([users, projects, groups])
        //console.log(usersDB.data.rights.Groups)
       // console.log(projectsDB.data)
        console.log("fetched")
        console.log(usersDB)
        console.log(projectsDB)

        setProjects(projectsDB.data.database)
        setUsername(usersDB.data.username)
        const _role = usersDB.data.role || "user";

        console.log("role",_role)
        const roleObj = {
          label: capitalize(_role),
          value: _role
        }
        setRole([roleObj]);
        console.log('***usersDB.data.rights***');
        console.log(usersDB.data.rights);
        console.log('***usersDB.data.rights****');
        if (usersDB.data.rights) {
          console.log('***usersDB.data.rights***');
          console.log(usersDB.data.rights);
          console.log('***usersDB.data.rights****');
          setEmail(usersDB.data.email)
          SetRights(true)
          setCreateProjects(usersDB.data.rights.createProjects)
          setVersioning(usersDB.data.rights.Versioning)
          setUserAdmin(usersDB.data.rights.UserAdministration)
          setRenumbering(usersDB.data.rights.Renumbering)
          setChangeProjects(usersDB.data.rights.ChangeProjects)
          setNotes(usersDB.data.rights.Notes)
          setNotifications(usersDB.data.rights.notifications)
          usersDB.data.resetPasswordExpire && setReset(true)

          let seeProjectsarr = []
          let editProjectsarr = []
          let GroupsDBarr = []
          usersDB.data.rights.SeeProjects.forEach(item => {
            let obj = {}
            obj.value = item
            if (item == "all") {
              obj.label = "All Projects"
              seeProjectsarr.push(obj)
            } else {
              // obj.label = projectsDB.data.database.find(o => o.projectID == item).projectName
              let test = projectsDB.data.database.find(o => o.projectID == item)
              if (test) {
                obj.label = test.projectName
                seeProjectsarr.push(obj)
              }
              else {
                seeProjectsarr.push({ label: item, value: item })
              }
              // console.log(test)
            }
          })

          usersDB.data.rights.EditProjects.forEach(item => {
            let obj = {}
            obj.value = item

            if (item == "all") {
              obj.label = "All Projects"
              editProjectsarr.push(obj)
            } else {
              let test = projectsDB.data.database.find(o => o.projectID == item)
              if (test) {
                obj.label = test.projectName
                editProjectsarr.push(obj)
              } else {
                editProjectsarr.push({ label: item, value: item })
              }
              //console.log(test)
              //   obj.label = projectsDB.data.database.find(o => o.projectID == item).projectName

            }
          })

          for (const property in usersDB.data.rights.Groups) {
            let obj = {}
            obj.value = property
            obj.label = property
            GroupsDBarr.push(obj)
            //data.rights.Groups[property] = tempobj[property]
          }

          /*  usersDB.data.rights.Groups.forEach(item=> {
             let obj = {}
             obj.value = item
             obj.label = item
             GroupsDBarr.push(obj)
             }) */

          SetGroupsDB(GroupsDBarr)
          console.log(seeProjectsarr)
          setSeeProjects(seeProjectsarr)
          setEditProjects(editProjectsarr)

          let ProjectsArr = []
          ProjectsArr.push({ label: "All Projects", value: "all" })
          projectsDB.data.database.forEach(ele => {
            let obj = {}
            obj.value = ele.projectID
            obj.label = ele.projectName
            ProjectsArr.push(obj)
          });

          setProjects(ProjectsArr)

          let GroupsArr = []
          groupsDB.data.elements.forEach(ele => {
            let obj = {}
            obj.value = ele.groupName
            obj.label = ele.groupName
            GroupsArr.push(obj)
          });

          setGroups(GroupsArr)
        } else {
          setUsername(usersDB.data[0].username)
          setEmail(usersDB.data[0].email)
        }
      } catch (e) {
        console.log(`Error from user fetch: ` + e)
        if(!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }

    fetch().then(
      console.log("test"),
      console.log("works"),
      console.log(Loading),
      console.log(username),
      console.log(projects),
      SetSaved(true),
      setLoading(false),
      setTimeout(() => {
        setonload(true)
      }, 1000),
    );
  }, [setIsSaving, isSaving]);

  function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  const unsave = () => {
    if (Saved) {
      SetSaved(false)
    }
  }

  const changeText = (e, toDo) => {
    toDo(e.target.value)
    if (Saved) {
      SetSaved(false)
    }
  }

  const checkRadio = (e, toDo) => {
    console.log(e)
    console.log(toDo)
    console.log(Saved)
    toDo(prevCheck => !prevCheck)
    if (Saved) {
      console.log("test")
      console.log(Saved)
      SetSaved(false)
    }
  }

  const save = () => {
    let obj = []
    SeeProjects.forEach(item => {
      obj.push(item.value)
    })

    SetFinal(obj)
    let obj2 = []
    EditProjects.forEach(item => {
      obj2.push(item.value)
    })

    let obj3 = []
    GroupsDB.forEach(item => {
      obj3.push(item.value)
    })

    const Body = {
      data: {
        use: {
          username: username,
          rights: {
            Versioning: Versioning,
            UserAdministration: UserAdmin,
            Renumbering: Renumbering,
            SeeProjects: obj,
            Notes: Notes,
            EditProjects: obj2,
            createProjects: createProjects,
            ChangeProjects: changeProjects,
            notifications: notifications,
          },
          role: role.value,
        },
        dontuse: {
          GroupNames: obj3
        }
      },
    }
console.log(Body)

    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/users/${idd}`, Body, config);
        console.log(DBGet.data)
        if (DBGet) {
          toast.success("User data saved Successfully", {
            position: toast.POSITION.BOTTOM_CENTER
          })
        }
      } catch (e) {
        if(!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    fetch().then(
      SetSaved(true)
    )
  };

  const changePass = (e) => {
    if (newPassword === "" || confirmNewPassword === null || confirmNewPassword === "" || confirmNewPassword === null ) {
      toast.error(intl.formatMessage({ id: "passwordfieldsrequired", defaultMessage: "Password fields are required" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }else
    if ( newPassword.length < 4 || newPassword.length > 20 || confirmNewPassword.length < 4 || confirmNewPassword.length > 20  ) {
      toast.error(intl.formatMessage({ id: "passwordlengtherror", defaultMessage: "Password should be greater than 4 letter and less than 20 characters!" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }else
    if(newPassword !== confirmNewPassword) {
      toast.error(intl.formatMessage({ id: "passwordnotsameerror", defaultMessage: "New Password doesnot match!" }), {
        position: toast.POSITION.BOTTOM_CENTER
      })
    } else {    
      console.log("true")
      const Body = {
        username: username,
        password: newPassword,
      }

      const fetch = async () => {
        try {
          const DBGet = await axios.put(`/api/private/changepassword/${idd}`, Body, config);
          console.log('success: '+ DBGet.data.success );
          if(DBGet.data.success === true){
            toast.success(intl.formatMessage({ id: "passwordupdatedsuccessfully", defaultMessage: "Password Updated Successfully" }), {
              position: toast.POSITION.BOTTOM_CENTER
            })
          }
        } catch (e) {
          if(!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        }
      }

      fetch().then(
        setIsSaving(true)
      )
    }
  };

  const Resend = async () => {
    let Body = {}
    console.log(idd)
    try {
      const DBGet = await axios.put(`/api/private/ResendInvitation/${idd}`, Body, config);
      console.log(DBGet.data)
      if (DBGet) {
        toast.success(intl.formatMessage({ id: "ResendInvitationSuccessfully", defaultMessage: "Resend Invitation Successfully!" }), {
          position: toast.POSITION.BOTTOM_CENTER
        })
      }
    } catch (e) {
      if(!props.dev) {
        localStorage.removeItem("authToken");
        window.location.href = '/logout';
      }
    }
  }

  if (!Loading && username) {
    return (
      <div className="users-page main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 l-20">
        <ToastContainer autoClose={6000} />
        <div className="content-wrapper">
          {/* Main content */}
          <section className="content-inner">
            <div className="wrapper">
              <h1 className='main-heading text-capitalize'>{username} <span className='text-mute'>({email})</span></h1>
              <div className="card">
                <div className='card-body'>
                  <div className="option-wrapper">
                    <div className="card-body ">
                      <div className='row d-flex align-items-center'>
                        <div className='col-md-3'>
                          <FormattedMessage id="ResendInvitation" defaultMessage="Resend Invitation" />
                        </div>
                        <div className='col-md-9'>
                          {Reset &&                           
                            <button onClick={(e) => Resend()} className="btn cta-btn">
                              <FormattedMessage id="ResendInvitation" defaultMessage="Resend Invitation" />
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {Rights ?
                    <div className="option-wrapper">
                      <div className='card-body'>
                        <div className='row d-flex align-items-center'>
                          <div className='col-md-3'>
                            <h3 className="carduser"><FormattedMessage id="Password" defaultMessage="Password" /></h3>
                          </div>
                          <div className="col-md-7">
                            <div className='d-flex align-items-center'>
                              <input type="password" placeholder="Enter the new password" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} className="form-control mr-2" />
                              <input type="password" placeholder="Confirm your new password" onChange={(e) => setConfirmNewPassword(e.target.value)} value={confirmNewPassword} className="form-control mr-2" />
                              <input type="submit" className="btn cta-btn" value="Change Password" onClick={changePass} />
                            </div>     </div>
                        </div>
                      </div>
                    </div>
                    : ""}
                  {Rights ?
                    <>
                      <div className="option-wrapper">
                        <div className='card-body'>
                          <div className='row d-flex align-items-center'>
                            <div className='col-md-3'>
                            <Link to={`/rights/useradministration`}><h3 className="carduser"><FormattedMessage id="UserAdministration" defaultMessage="User Administration" /></h3></Link>
                            </div>
                            <div className="col-md-7">
                              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label onClick={(e) => checkRadio(e, setUserAdmin)} className={UserAdmin ? "btn cta-btn-radio active" : "btn cta-btn-radio"}>
                                  <input type="radio" checked={UserAdmin} name="options" id="option_a1" /> <FormattedMessage id="Enabled" defaultMessage="Enabled" />
                                </label>
                                <label onClick={(e) => checkRadio(e, setUserAdmin)} className={UserAdmin ? "btn cta-btn-radio" : "btn cta-btn-radio active"} >
                                  <input type="radio" checked={!UserAdmin} name="options" id="option_a1" /> <FormattedMessage id="Disabled" defaultMessage="Disabled" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="option-wrapper">
                        <div className='card-body'>
                          <div className='row d-flex align-items-center'>
                            <div className='col-md-3'>
                            <Link to={`/rights/notes`}><h3 className="carduser"><FormattedMessage id="Notes" defaultMessage="Notes" /></h3></Link>
                            </div>
                            <div className="col-md-7">
                              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label onClick={(e) => checkRadio(e, setNotes)} className={Notes ? "btn cta-btn-radio active" : "btn cta-btn-radio"}>
                                  <input type="radio" checked={Notes} name="options" id="option_a1" /> <FormattedMessage id="Enabled" defaultMessage="Enabled" />
                                </label>
                                <label onClick={(e) => checkRadio(e, setNotes)} className={Notes ? "btn cta-btn-radio" : "btn cta-btn-radio active"} >
                                  <input type="radio" checked={!Notes} name="options" id="option_a1" /> <FormattedMessage id="Disabled" defaultMessage="Disabled" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="option-wrapper">
                        <div className='card-body'>
                          <div className='row d-flex align-items-center'>
                            <div className='col-md-3'>
                            <Link to={`/rights/createprojects`}><h3 className="carduser"><FormattedMessage id="CreateProjects" defaultMessage="Create Projects" /></h3></Link>
                            </div>
                            <div className="col-md-7">
                              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label onClick={(e) => checkRadio(e, setCreateProjects)} className={createProjects ? "btn cta-btn-radio active" : "btn cta-btn-radio"}>
                                  <input type="radio" checked={createProjects} name="options" id="option_a1" /> <FormattedMessage id="Enabled" defaultMessage="Enabled" />
                                </label>
                                <label onClick={(e) => checkRadio(e, setCreateProjects)} className={createProjects ? "btn cta-btn-radio" : "btn cta-btn-radio active"} >
                                  <input type="radio" checked={!createProjects} name="options" id="option_a1" /> <FormattedMessage id="Disabled" defaultMessage="Disabled" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isSuperadmin && <>
                        <div className="option-wrapper">
                          <div className='card-body'>
                            <div className='row d-flex align-items-center'>
                              <div className='col-md-3'>
                              <Link to={`/rights/changeprojects`}><h3 className="carduser"><FormattedMessage id="ChangeProjects" defaultMessage="Change Projects" /></h3></Link>
                              </div>
                              <div className="col-md-7">
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                  <label onClick={(e) => checkRadio(e, setChangeProjects)} className={changeProjects ? "btn cta-btn-radio active" : "btn cta-btn-radio"}>
                                    <input type="radio" checked={changeProjects} name="options" id="option_a1" /> <FormattedMessage id="Enabled" defaultMessage="Enabled" />
                                  </label>
                                  <label onClick={(e) => checkRadio(e, setChangeProjects)} className={changeProjects ? "btn cta-btn-radio" : "btn cta-btn-radio active"} >
                                    <input type="radio" checked={!changeProjects} name="options" id="option_a1" /> <FormattedMessage id="Disabled" defaultMessage="Disabled" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="option-wrapper">
                          <div className='card-body'>
                            <div className='row d-flex align-items-center'>
                              <div className='col-md-3'>
                              <Link to={`/rights/versioning`}><h3 className="carduser"><FormattedMessage id="Versioning" defaultMessage="Versioning" /></h3></Link>
                              </div>
                              <div className="col-md-7">
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                  <label onClick={(e) => checkRadio(e, setVersioning)} className={Versioning ? "btn cta-btn-radio active" : "btn cta-btn-radio"}>
                                    <input type="radio" checked={Versioning} name="options" id="option_a1" /> <FormattedMessage id="Enabled" defaultMessage="Enabled" />
                                  </label>
                                  <label onClick={(e) => checkRadio(e, setVersioning)} className={Versioning ? "btn cta-btn-radio" : "btn cta-btn-radio active"} >
                                    <input type="radio" checked={!Versioning} name="options" id="option_a1" /> <FormattedMessage id="Disabled" defaultMessage="Disabled" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="option-wrapper">
                          <div className='card-body'>
                            <div className='row d-flex align-items-center'>
                              <div className='col-md-3'>
                              <Link to={`/rights/renumbering`}><h3 style={{ alignSelf: "center" }} className="carduser"><FormattedMessage id="Renumbering" defaultMessage="Renumbering" /></h3></Link>
                              </div>
                              <div className="col-md-7">
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                  <label onClick={(e) => checkRadio(e, setRenumbering)} className={Renumbering ? "btn cta-btn-radio active" : "btn cta-btn-radio"}>
                                    <input type="radio" checked={Renumbering} name="options" id="option_a1" /> <FormattedMessage id="Enabled" defaultMessage="Enabled" />
                                  </label>
                                  <label onClick={(e) => checkRadio(e, setRenumbering)} className={Renumbering ? "btn cta-btn-radio" : "btn cta-btn-radio active"} >
                                    <input type="radio" checked={!Renumbering} name="options" id="option_a1" /> <FormattedMessage id="Disabled" defaultMessage="Disabled" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="option-wrapper">
                          <div className='card-body'>
                            <div className='row d-flex align-items-center'>
                              <div className='col-md-3'>
                              <Link to={`/rights/notifications`}><h3 className="carduser"><FormattedMessage id="Notifications" defaultMessage="Notifications" /></h3></Link>
                              </div>
                              <div className="col-md-7">
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                  <label onClick={(e) => checkRadio(e, setNotifications)} className={notifications ? "btn cta-btn-radio active" : "btn cta-btn-radio"}>
                                    <input type="radio" checked={notifications} name="options" id="option_a1" /> <FormattedMessage id="Enabled" defaultMessage="Enabled" />
                                  </label>
                                  <label onClick={(e) => checkRadio(e, setNotifications)} className={notifications ? "btn cta-btn-radio" : "btn cta-btn-radio active"} >
                                    <input type="radio" checked={!notifications} name="options" id="option_a1" /> <FormattedMessage id="Disabled" defaultMessage="Disabled" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="option-wrapper">
                          <div className='card-body'>
                            <div className='row d-flex align-items-center'>
                              <div className='col-md-3'>
                                <h3 className="carduser"><FormattedMessage id="Role" defaultMessage="Role" /></h3>
                              </div>
                              <div className="col-md-7">
                                <Select
                                  options={roles}
                                  value={role}
                                  className={"MultiSelectClass"}
                                  onChange={setRole}
                                  placeholder={intl.formatMessage({
                                    id: "Role",
                                    defaultMessage: "Select Role",
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>  
                      </>
                      }
                      <div className="option-wrapper">
                        <div className='card-body'>
                          <div className='row d-flex align-items-center'>
                            <div className='col-md-3'>
                              <h3 className="carduser"><FormattedMessage id="SeeProjects" defaultMessage="See Projects" /></h3>
                            </div>
                            <div className="col-md-7">
                              <MultiSelect
                                options={projects}
                                value={SeeProjects}
                                hasSelectAll={false}
                                className={"MultiSelectClass"}
                                onChange={setSeeProjects}
                                labelledBy="Select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="noborder">
                        <div className='card-body'>
                          <div className='row d-flex align-items-center'>
                            <div className='col-md-3'>
                              <h3 className="carduser font-bold"><FormattedMessage id="EditProjects" defaultMessage="Edit Projects" /></h3>
                            </div>
                            <div className="col-md-7">
                              <MultiSelect
                                options={projects}
                                value={EditProjects}
                                hasSelectAll={false}
                                className={"MultiSelectClass"}
                                onChange={setEditProjects}
                                labelledBy="Select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button className='btn cta-btn no-print m-2' disabled={Saved}
                          onClick={() => save()}><i className="m-1 fas fa-save"></i> <FormattedMessage id="Save" defaultMessage="Save" /></button>
                      </div>

                    </>
                    : ""}
                </div>
                {/* /.row */}
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
    );
  }
  else {
    return <Spinner />;
  }
}
export default withRouter(SingleUser)
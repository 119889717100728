/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { FormattedMessage } from "react-intl";
import moment from 'moment';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import KPICards from "../common/ProjectKPIcards";
import { message } from 'antd'
import { DatePicker } from 'antd';
import _ from "lodash";
import  KPIChart  from '../common/KPIChart'
import  KPIChartSales  from '../common/KPIChartSales'
import KPIPie from "./ProjectPieKPI";
import 'react-nice-dates/build/style.css'
import { Spin } from 'antd';
import { Divider } from 'antd';
import Gauge from "../common/KPIGauge"
import DrawerSettings from "./KPIDrawerSettings"
import { Button } from 'antd';
import KPICardsWithGraph from './KPICardWithChart'
import KPIButton from '../v2/KPIFileUpload'

var XLSX = require("xlsx");

const { RangePicker } = DatePicker;


const KPIDashboard2 = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const _projectID = params['id'];
  const [chartTitle, setChartTitle] = useState("")
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingstatus, setLoadingStatus] = useState(false)
  const [loadingchange, setLoadingChange] = useState(false)
  const [exisitingRecords, setExistingRecords] = useState(0)
  const [totalRecords, setTotalRecords] = useState(0)
  const [LeftRecords, setLeftRecords] = useState(0)
  const [projectDateFormat,setProjectDateFormat] = useState("")
  const [loadingTime,SetLoadingTime] = useState(0)
  const [loadingSpinner,setLoadingSpinner] = useState(false)
  const [ChartData, setChartData] = useState({});
  const [ChartsData, setChartsData] = useState({
    daysData: [], // 7 days
    weeksData: [], // 4 week
    monthData: [], // 30 days
  });
  const [dateRange, setDateRange] = useState([]);
  const [kpisCalculations,setKpisCalculations] = useState({
    CycleTime: "0 Hour(s)",
    IdleTime: 0,
    KeyToKeyDays: 0,
    LaborSalesAllowed: 0,
    LeadTime: 0,
    PaintLiquidSales: 0,
    PaintRev: 0,
    PartSales: 0,
    TotalValueSales: 0,
    TotalAverageJob: 0,
    TotalAveragePaintJob: 0,
    TotalAveragePartJob: 0,
    ProductivityPaint: 0,
    ProductivityPanel: 0,
    ProductivityTotal: 0,
    RepairvsReplaceRatio: 0,
    Throughput: 0,
    TotalLossEst: 0,
    WeekdayWithHighestCustomerDropoff: [],
    WorkProviderSplit: [],
    WorkType: [],
    VehicleAverageAge: "0 year(s)"
  })
  const [graphLabels, setGraphLabels] = useState({
    Date: {
      alias: 'Date',
    },
    Count: {
      alias: 'Count',
    }
  })
  const [drawerSettingsvisible, setDrawerSettingsvisible] = useState(false);
  const [databaseloading,setDatabaseLoading] = useState(false)
  const [kpiDataLimits,setKpiDataLimits] = useState({
    WeekdayCustomerDropoff: 0,
    KeyToKeyDays: 0,
    LeadTime: 0,
    IdleTime: 0,
    ProductivityPaint: 0,
    ProductivityPanel: 0,
    ProductivityTotal: 0
  })

const handleClickLimits = () => {
  setDrawerSettingsvisible(true);
};

  const currencyNumberFormat = (value) => {
    if(value === undefined || value === null){
      value = 0
    }
  return new Intl.NumberFormat(props.userLocale, {
    style: 'currency',
    currency: props.currencyName,
    currencyDisplay: "symbol"
  }).format(value);
}
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };


  const getProjectDateFormat = () => {
    axios.get(`/api/getprojectdateformat/${_projectID}`, config).then((result) => {
      if (result) {
        setProjectDateFormat(result.data)
      }
    })
  }

  function handleFile(e) {
    setData([])
    getValidatedData([])
    setLoadingChange(false)
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      const wb = XLSX.read(data, { type: 'array' });
      const wsname = wb.SheetNames[1];
      const ws = wb.Sheets[wsname];
      const dataOut = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, blankrows: false, defval: null, skipHeader:true });
      if(moment(dataOut[1][1], projectDateFormat, true).isValid()){
        message.success("Date Format Found");
        setData(dataOut);
        getValidatedData(dataOut)
      }else{
        message.error("Date Format Issue");
        setLoadingChange(false)
        setLoadingStatus(false)
      }
    };
    reader.readAsArrayBuffer(file);
  }

  useEffect(() => {
    getProjectDateFormat();
  },[])



  const getKpiLimits = () => {
   axios.get(`/api/getLimits/${_projectID}`,config).then((kpiresponse) => {
    if(kpiresponse.data){
    setKpiDataLimits(prevState => Object.assign({}, prevState, {
      WeekdayCustomerDropoff: kpiresponse.data.WeekdayCustomerDropoff,
      KeyToKeyDays: kpiresponse.data.KeyToKeyDays,
      LeadTime: kpiresponse.data.LeadTime,
      IdleTime: kpiresponse.data.IdleTime,
      ProductivityPaint: kpiresponse.data.ProductivityPaint,
      ProductivityPanel: kpiresponse.data.ProductivityPanel,
      ProductivityTotal: kpiresponse.data.ProductivityTotal
    }))
  }
})
  }

  const getValidatedData = (dataSet) => {
    const _now = new Date();

// Use lodash to generate a random string
const randomString = _.random(0, 1000000000).toString(36) +
                     _.random(0, 1000000000).toString(36) +
                     _now.getTime().toString(36);
    const _kpiID = randomString.slice(0, 32);;
    let readyToImport = [];
    let jobIDarr = [];
    setLoading(true);
    setLoadingStatus(true)
    if (dataSet.length > 0) {
      dataSet.map((row) => {
        if (row[0] === "Job No") {
          return;
        }
        if (row[0] !== null) {
          if (row[1] !== null && moment(row[1], projectDateFormat, true).isValid()){
            row[1] = moment(row[1].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          if (row[4] !== null && moment(row[4], projectDateFormat, true).isValid()){
            row[4] = row[4].trim();
          }
          if (row[17] !== null && moment(row[17], projectDateFormat, true).isValid()){
            row[17] = moment(row[17].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          if (row[18] !== null && moment(row[18], projectDateFormat, true).isValid()){
            row[18] = moment(row[18].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          if (row[19] !== null && moment(row[19], projectDateFormat, true).isValid()){
            row[19] = moment(row[19].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          if (row[20] !== null && moment(row[20], projectDateFormat, true).isValid()){
            row[20] = moment(row[20].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          if (row[21] !== null && moment(row[21], "YYYY-MM-DD HH:mm:ss", true).isValid()){
            row[21] = row[21].trim();
          }
          if (row[22] !== null && moment(row[22], "YYYY-MM-DD HH:mm:ss", true).isValid()){
            row[22] = row[22].trim();
          }
          if (row[23] !== null && moment(row[23], projectDateFormat, true).isValid()){
            row[23] = moment(row[23].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          if (row[24] !== null && moment(row[24], projectDateFormat, true).isValid()){
            row[24] = moment(row[24].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          if (row[25] !== null && moment(row[25], projectDateFormat, true).isValid()){
            row[25] = moment(row[25].trim()).format("YYYY-MM-DD HH:mm:ss");
          }
          row[26] = _projectID;
          row[27] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          row[28] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          row[29] = _kpiID;
          if (row[3] !== null){
            row[3] = row[3].trim();
          }
          if (row[5] !== null){
            row[5] = row[5].trim();
          }
          if (row[6] !== null){
            row[6] = row[6].trim().replace(/,/g, '');
          }
          if (row[7] !== null){
            row[7] = row[7].trim().replace(/,/g, '');
          }
          if (row[8] !== null){
            row[8] = row[8].trim().replace(/,/g, '');
          }
          if (row[9] !== null){
            row[9] = row[9].trim().replace(/,/g, '');
          }
          if (row[10] !== null){
            row[10] = row[10].trim().replace(/,/g, '');
          }
          if (row[11] !== null){
            row[11] = row[11].trim().replace(/,/g, '');
          }
          if (row[12] !== null){
            row[12] = row[12].trim().replace(/,/g, '');
          }
          if (row[13] !== null){
            row[13] = row[13].trim().replace(/,/g, '');
          }
          if (row[14] !== null){
            row[14] = row[14].trim().replace(/,/g, '');
          }
          if (row[15] !== null){
            row[15] = row[15].trim().replace(/,/g, '');
          }
          if (row[16] !== null){
            row[16] = row[16].trim().replace(/,/g, '');
          }
        jobIDarr.push(row[0]);
      }
        readyToImport.push(row);
      })
if(readyToImport.length > 0) {
      const myObjStr = generateJson(readyToImport);
      setData(readyToImport)
      setLoading(false);
      setTotalRecords(myObjStr.length)
      setThroughPut(myObjStr.length,_kpiID);
      setLeftRecords(myObjStr.length - myObjStr.filter(x => x.JobNo !== "").length)
      getDataStatus(jobIDarr)
      saveDataIntoDatabase(myObjStr.filter(x => x.JobNo !== ""));

    }else{
      message.error("Date Format Error")
    }

    }
  }


  function sumCountByWeekRanges(weekRangeArray, originalData) {
    const result = [];

    // Loop through each week range
    for (let i = 0; i < weekRangeArray.length; i++) {
      const weekRange = weekRangeArray[i];
      let countSum = 0;
      let countDataRows = 0;
      // Loop through each data point to check if it falls within the week range
      for (let j = 0; j < originalData.length; j++) {
        const dataPoint = originalData[j];
        const date = new Date(dataPoint.Date);

        // If the data point's date falls within the week range, add its count to the sum
        if (date >= new Date(weekRange.startDate) && date <= new Date(weekRange.endDate)) {
          countSum += dataPoint.Count;
          countDataRows++;
        }
      }

      // Add the week range and its count sum to the result array
      if(countSum > 0){
      result.push({
        Date: weekRange.Date,
        Count: Number(Number(countSum/countDataRows).toFixed(1))
      });
    }
    }

    return result;
  }




  function getWeekRanges(startDate, endDate) {
    const weekRanges = [];
    const start = moment(startDate).startOf('week');
    const end = moment(endDate).endOf('week');

    let current = moment(start);
    while (current.isBefore(end)) {
      const weekStart = current.clone().startOf('week').format('YYYY-MM-DD');
      const weekEnd = current.clone().endOf('week').format('YYYY-MM-DD');
      weekRanges.push({ startDate: weekStart, endDate: weekEnd , Date: `${weekStart} - ${weekEnd}`});
      current.add(1, 'week');
    }

    return weekRanges;
  }


  useEffect(() => {
    if (dateRange.length >= 1) {
      getKpiLimits();
      setLoadingSpinner(true);
      kpicalculationsbyID(moment(dateRange[0]).format(), moment(dateRange[1]).format())
    }
  }, [dateRange])


  const setThroughPut = (totalJobs, kpiID) => {
    axios.post("/kpi/addThroughput/" + _projectID, {
      throughPut: totalJobs,
      kpiID: kpiID,
    }, config).then(response => {
    })
  }
  const kpicalculationsbyID = (dateRange1, dateRange2) => {
    axios.post("/list/filter/car/" + _projectID, {
      DateRange1: [moment(dateRange1),moment(dateRange2)],
      DateRange2: [],
      CompareWithDateTwo: false
    }, config).then(data => {
      if(data.data) {
        setKpisCalculations(prevState => Object.assign({}, prevState, {
          CycleTime: data.data.CycleTime || "0 hour",
          IdleTime: data.data.IdleTime || 0,
          KeyToKeyDays: data.data.KeyToKeyDays || 0,
          LaborSalesAllowed: parseInt(data.data.LaborSalesAllowed) || 0,
          LeadTime: parseInt(data.data.LeadTime) || 0,
          PaintLiquidSales: parseInt(data.data.PaintLiquidSales) || 0,
          PaintRev: parseFloat(data.data.PaintRev).toFixed(2) || 0.0,
          PartSales: parseInt(data.data.PartSales) || 0,
          ProductivityPaint: data.data.ProductivityPaint || 0,
          ProductivityPanel: data.data.ProductivityPanel || 0,
          ProductivityTotal: data.data.ProductivityTotal || 0,
          RepairvsReplaceRatio: data.data.RepairvsReplaceRatio || "",
          Throughput: data.data.Throughput || 0,
          TotalLossEst: data.data.TotalLossEst || "",
          WeekdayWithHighestCustomerDropoff: data.data.WeekdayWithHighestCustomerDropoff || [],
          WorkProviderSplit: data.data.WorkProviderSplit || [],
          WorkType: data.data.WorkType || [],
          VehicleAverageAge: data.data.VehicleAverageAge || "0 years",
          TotalValueSales: data.data.TotalValueSales ||  0,
          TotalAverageJob: data.data.TotalAverageJob ||  0,
          TotalAveragePaintJob:  data.data.TotalAveragePaintJob || 0,
          TotalAveragePartJob:  data.data.TotalAveragePartJob || 0,
        }))
        setLoadingSpinner(false);
      }
    })
  }


  const getDataStatus = (arrData) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios.post('/checkDataStatus', {
        projectids: _projectID,
        jobnoarr: arrData
      }, config).then((response) => {
        setExistingRecords(response.data.length)
        setLoadingStatus(false)
        setLoadingChange(true)
      }).catch(err => {
        console.log(err);
      });
    }
  }

  const generateJson = (arrData) => {
    const labels = ["JobNo", "DateCompleted", "Customer", "CustomerType", "VehicleYear", "Brand", "Model", "LabourValue", "PartsValue", "PaintValue", "OtherValue", "PanelHoursAllowed", "PaintHoursAllowed", "OtherHoursAllowed", "PanelHoursWorked", "PaintHoursWorked", "OtherHoursWorked", "DateCreated", "DateEstimated", "DateIn", "DateAuthorised", "DateFirstClock", "DateLastClock", "TargetOut", "DateReady", "DateOut", "Projectid", "DateUpload", "DateUpdate","KpiID"];
    let obj, arrResult = [], i, j, key, value;
    for (i = 0; i < arrData.length; i++) {
      obj = {};
      for (j = 0; j < 30; j++) {
        key = labels[j];
        value = arrData[i][j];
        if (value === null) {
          obj[key] = "";
        } else {
          obj[key] = value;
        }

      }
      arrResult.push(obj);
    }
    return arrResult;
  }


  const  CallDataForChart = async (selectedKPICard) => {

    if(dateRange.length >= 1){

      setLoadingSpinner(true)
    setChartTitle(selectedKPICard);
    const dateRange1 = dateRange[0];
    const dateRange2 = dateRange[1];
    if(dateRange.length >= 1){
    await axios.post("/kpi/chart/" + _projectID, {
      DateRange1: [moment(dateRange1).format(),moment(dateRange2).format()],
      DateRange2: [],
      CompareWithDateTwo: false,
      SelectedKPICard: selectedKPICard
    }, config).then(data => {
        setChartData(data.data)
        setLoadingSpinner(false)
    })
  }

}

  }


  const saveDataIntoDatabase = (_data) => {
    setDatabaseLoading(true)
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      let body = {
        projectid: _projectID,
        KPIDashboardModels: _data
      }
      axios.post('/create/fromfile', body , config).then((response) => {
        if (response.data === true) {
          setDatabaseLoading(false)
          message.success("Data saved successfully")
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  function handleDateChange(dates,dateStrings) {
    setDateRange(dateStrings);
  }



  return (
    <>
    {drawerSettingsvisible && <DrawerSettings setVisible={setDrawerSettingsvisible} visible={drawerSettingsvisible} projectID={_projectID}/> }
    {(loadingSpinner || databaseloading) ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          {loadingSpinner ? <Spin size="large" tip={`Fetching Values ${'.'.repeat(6)}`} /> : <Spin size="large" tip={`Saving to Database ${'.'.repeat(6)}`} /> }
        </div>
      ) : ""}
      <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 kpi-dashboard">
        <div className="main-header mobile-flex-start pd-x-20 ht-70 d-flex align-items-center justify-content-between pl-0">
          <h1 className=" mg-b-0 main-heading mg-t-16">
            <FormattedMessage id="KPIDashboard" defaultMessage="KPI Dashboard" />
          </h1>
          <div>
          <span><span>Allowed Date Format:</span> <b>{projectDateFormat}</b></span>
          <Button className="ml-2" onClick={handleClickLimits}>
  Set Limits
</Button>
</div>

        </div>
        <div className='d-flex justify-content-end mg-t-16 mb-3 align-items-center mobile-flex-start'>

        <div className="responsive-range-picker my-2 ml-3">
        <RangePicker
        bordered={true}
        onChange={handleDateChange}
        value={dateRange.length === 0 ? null : [moment(dateRange[0]), moment(dateRange[1])]}
        clearIcon={false}/>
        </div>
        <form>
          <div className="kpi-upload-btn my-2">
            <label class="label-kpi" for="kpi-upload"><i class="fas fa-upload"></i> Upload KPI File</label>
            <input type="file" className="form-control-file" id="kpiupload" onChange={handleFile} name="excelfile" accept=".xls, .xlsx" />
          </div>
        </form>
        </div>

        <div className="container-fluid plr-0">
          <div className="card rounded-2 border-gray border-radius-16  kpi-progress-cards">
            <div className='col-md-12'>
            { (chartTitle !== "" && (chartTitle === "Parts Sales" || chartTitle === "Paint Liquid Sales" || chartTitle === "Labor Sales" || chartTitle === "Total Sales" || chartTitle === "Total Sales Average Job" || chartTitle === "Paint Average Per Job" || chartTitle === "Parts Average Per Job")) ?
                <KPIChartSales ChartData={ChartData || null} label={ChartData.meta || {Date:"Date",Count: "Count"} } title={chartTitle !== "" ? chartTitle + " -" : ""} currency={props.currencyName}/>

          :
          <KPIChart ChartData={ChartData || null} label={ChartData.meta || {Date:"Date",Count: "Count"} } title={chartTitle !== "" ? chartTitle + " -" : ""} currency={props.currencyName}/>

          }
            </div>
            <Divider />
            <div className='row'>
            <div className="col-md-3">
                  <Gauge
                    title="Key to Key Days"
                    targetValue={kpiDataLimits.KeyToKeyDays}
                    onClick={() => {
                      CallDataForChart("Key to Key Days");
                    }}
                    value={kpisCalculations.KeyToKeyDays}
                  />
                </div>
                <div className="col-md-3">
                  <Gauge
                    title="Lead Time"
                    targetValue={kpiDataLimits.LeadTime}
                    onClick={() => {
                      CallDataForChart("Lead Time");
                    }}
                    value={kpisCalculations.LeadTime}
                    //value={35}
                  />
                </div>
                <div className="col-md-3">
                  <Gauge
                    title="Idle Time"
                    targetValue={kpiDataLimits.IdleTime}
                    onClick={() => {
                      CallDataForChart("Idle Time");
                    }}
                    value={kpisCalculations.IdleTime}
                  />
                </div>
                <div className="col-md-3">
                  <Gauge
                    title="Productivity Paint"
                    targetValue={kpiDataLimits.ProductivityPaint}
                    onClick={() => {
                      CallDataForChart("Productivity Paint");
                    }}
                    sign="%"
                    value={kpisCalculations.ProductivityPaint}
                  />
                </div>
                </div>
                <div className='row mt-4'>
                  <div className="col-md-3">
                    <Gauge
                      title="Productivity Panel"
                      targetValue={kpiDataLimits.ProductivityPanel}
                      onClick={() => {
                        CallDataForChart("Productivity Panel");
                      }}
                      sign="%"
                      value={kpisCalculations.ProductivityPanel}
                    />
                  </div>
                  <div className="col-md-3">
                    <Gauge
                      title="Productivity Total"
                      targetValue={kpiDataLimits.ProductivityTotal}
                      onClick={() => {
                        CallDataForChart("Productivity Total");
                      }}
                      sign="%"
                      value={kpisCalculations.ProductivityTotal}
                    />
                  </div>
                </div>
                <Divider />
                <div className='row'>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card active"
                    cardname="Parts Sales"
                    activeClassName="active"
                    cardpercentage=""
                    onClick={() =>
                      CallDataForChart("Parts Sales")
                    }
                    cardvalue={currencyNumberFormat(kpisCalculations.PartSales)}
                  />
                </div>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Paint Liquid Sales"
                    cardpercentage=""
                    onClick={() =>
                      CallDataForChart("Paint Liquid Sales")
                    }
                    cardvalue={currencyNumberFormat(kpisCalculations.PaintLiquidSales)}
                  />
                </div>

                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Labour Sales Allowed"
                    cardpercentage=""
                    onClick={() => {
                      CallDataForChart("Labor Sales");
                    }}
                    cardvalue={currencyNumberFormat(kpisCalculations.LaborSalesAllowed)}
                  />
                </div>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Total Sales"
                    cardpercentage=""
                    onClick={() => {
                      CallDataForChart("Total Sales");
                    }}
                    cardvalue={currencyNumberFormat(kpisCalculations.TotalValueSales)}
                  />
                </div>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Total Sales Average Job"
                    cardpercentage=""
                    onClick={() => {
                      CallDataForChart("Total Sales Average Job");
                    }}
                    cardvalue={currencyNumberFormat(kpisCalculations.TotalAverageJob)}
                  />
                </div>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Throughput"
                    cardpercentage=""
                    onClick={() => {
                      CallDataForChart("Throughput");
                    }}
                    cardvalue={kpisCalculations.Throughput}
                  />
                </div>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Vehicle Average Age"
                    cardpercentage=""
                    onClick={() => {
                      CallDataForChart("Vehicle Average Age");
                    }}
                    cardvalue={kpisCalculations.VehicleAverageAge}
                  />
                </div>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Parts Average Per Job"
                    cardpercentage=""
                    onClick={() => {
                      CallDataForChart("Parts Average Per Job");
                    }}
                    cardvalue={currencyNumberFormat(kpisCalculations.TotalAveragePartJob)}
                  />
                </div>
                <div className="col-md-3">
                  <KPICards
                    cardclass="gray-card"
                    activeClassName="active"
                    cardname="Paint Average Per Job"
                    cardpercentage=""
                    onClick={() => {
                      CallDataForChart("Paint Average Per Job");
                    }}
                    cardvalue={currencyNumberFormat(kpisCalculations.TotalAveragePaintJob)}
                  />
                </div>
                </div>
              </div>
        </div>
        <div className='container-fluid plr-0 mg-t-30'>
          <div className="row">
            <KPIPie title={"Vehicle Brand Split"} workTypes={kpisCalculations.WorkProviderSplit} />
            <div className="col-md-4">
                  <KPICardsWithGraph
                    cardname="Vehicle Dropoff"
                    ChartData={kpisCalculations.WeekdayWithHighestCustomerDropoff}
                  />
                </div>
          </div>
        </div>
        {/* <div className='container-fluid plr-0 mt-3'>
          <div className="row">
          <KPIPie title={"Work Provider"} workTypes={kpisCalculations.WorkType} />
          </div>
        </div> */}



        {data !== "" ?
          <>
            {loadingstatus ? <p><LoadingOutlined fontSize="24" /> Analyzing data</p> : ""}
            {loadingchange ?
              <>
                <div className='alert alert-primary'>
                  <p>{exisitingRecords} existing rows updated</p>
                </div>
                <div className='alert alert-danger'>
                  <p>{LeftRecords} rows left out of {totalRecords}</p>
                </div>
                <div className='alert alert-success'>
                  <p>{totalRecords - (exisitingRecords + LeftRecords)} rows will be added</p>
                </div>
              </> : ""
            }

            <div className="table-wrapper" style={{ "overflowX": "scroll" }}>
              <table className="table table-responsive mb-5" style={{ "overflowX": "scroll" }}>
                <thead>
                </thead>
                <tbody>
                  { data.length > 0  ? (data.map((item) => {
                      if (item[0] === null) {
                        return (
                          <tr style={{ "background": "#FFFCC9", "color": "#000" }}>
                            {item.length > 0 && item.map((values) => {
                              return (<td>{values}</td>)
                            })

                            }
                          </tr>
                        )
                      } else {
                        return (
                          <tr style={{ "background": "#fff" }}>
                            {item.length > 0 && item.map((values) => {
                              return (<td>{values}</td>)
                            })
                            }
                          </tr>
                        )
                      }
                    })) : ""}
                </tbody>
              </table>
            </div>
          </>
          : ""}
      </div>
    </>
  )
}

export default KPIDashboard2
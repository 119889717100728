import { Redirect, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const { pathname } = useLocation();
  console.log("Children: ")
  console.log(children)
  return (
    <Route
      {...rest} render={(props) =>
        localStorage.getItem("authToken") ?
          children
          : <Redirect to={`/login?redirectTo=${pathname}`} />
      }
    />
  );
};

export default PrivateRoute;

import React, { useRef, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { Line } from '@ant-design/plots';


const KPICardWithChart = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.ChartData.length > 0)
            setData(props.ChartData);
    }, [props.ChartData])

    const maxCount = Math.max(...data.map((d) => d.Count));

    const config = {
        data: data,
        xField: 'Day',
        yField: 'Count',
        color: "#264595",
        meta: {
          Count: {
            alias: 'Number of Jobs'
           }
        },
        smooth: true
      };
    return (
        <div className={`card rounded-2 border-gray border-radius-16 pd-16 bg-white card-height ` + props.cardclass} /* onClick={() => props.onClick()} */>
            <h3 className="card-c-heading-2">{props.cardname}</h3>
            <div className="chart-container" style={{ width: "100%", position: 'relative', height: "200px", maxWidth: "100%", padding: "10px 0" }}>
            <Line {...config} />
            </div>
        </div>
    );
};

export default KPICardWithChart;
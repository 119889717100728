
import { useIntl, FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import { Radio } from 'antd';
const Step3 = ({ show, step, onHide, prevStep, nextStep, handleFormData, handleSelect, handleRadio, values , save}) => {
  const intl = useIntl();
  const submitFormData = (e) => {
    e.preventDefault();
    if (values.sprayBooths === "" || values.ventilatedPrepbays === "" || values.paintBrand === "" || values.otherPaintBrand === "" || values.commentCurrentSupplier === "") {
      toast.error("Some fields are missing", {
        position: toast.POSITION.BOTTOM_CENTER
      })
    } else {
      console.log("test")
       save(); 
    }
  };

  return (
    <>
      <ToastContainer toastClassName="top-zIndex" autoClose={6000} />
      <div>
        {show && (
          <div id="popup-addNewProject" className="d-block fq-modal fq-modal-small fq-modal-project">
            <div className="fq-modal-content">
              <div className="fq-modal-header">
                <span onClick={onHide} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
                <h5><FormattedMessage id="AddanewassessmentStep3" defaultMessage="Add a new assessment - Step 3" /></h5>
                <p className='mt-2'><FormattedMessage id="Pleasefilloutthefollowingformtocreateanewassessment" defaultMessage="Please fill out the following form to create a new assessment" /></p>
              </div>
              <div className="fq-modal-content-2 pd-20">
                <div className="add-user-wrapper">
                  <form className="add-user-wrapper" onSubmit={submitFormData}>
                    <label className='d-block mb-2'><FormattedMessage id="Whatdotheylikeabouttheoldbrand" defaultMessage="What do they like about the old brand?" /></label>
                    <textarea rows="3" type="number" name='likeOldBrand' placeholder={intl.formatMessage({ id: "AddyourCommentHere", defaultMessage: "Add your comment here…" })} onChange={handleFormData("likeOldBrand")} value={values.likeOldBrand} className='form-control mb-3' />
                    <label className='d-block mb-2'><FormattedMessage id="WhyaretheyinterestedinchangingtoBASF" defaultMessage="Why are they interested in changing to BASF?" /></label>
                    <textarea rows="3" type="number" name='changingBasf' placeholder={intl.formatMessage({ id: "AddyourCommentHere", defaultMessage: "Add your comment here…" })} onChange={handleFormData("changingBasf")} value={values.changingBasf} className='form-control mb-3' />
                    <label className='d-block mb-2'><FormattedMessage id="IfforcustomerretentionAretheyhappywithBASFandwhyorwhynot" defaultMessage="Why are they not happy with current supplier?" /></label>
                    <textarea rows="3" type="number" name='customerRentention' placeholder={intl.formatMessage({ id: "AddyourCommentHere", defaultMessage: "Add your comment here…" })} onChange={handleFormData("customerRentention")} value={values.customerRentention} className='form-control mb-3' />
                    <label className='d-block mb-2'><FormattedMessage id="IsanyoneintheshopprocurrentsupplieroragainstBASFbeingthesupplierWhoandwhy" defaultMessage="Is anyone in the shop pro current supplier or against BASF being the supplier? Who and why?" /></label>
                    <textarea rows="3" type="number" name='commentSupplier' placeholder={intl.formatMessage({ id: "AddyourCommentHere", defaultMessage: "Add your comment here…" })} onChange={handleFormData("commentSupplier")} value={values.commentSupplier} className='form-control mb-3' />
                    <div className="d-block radio-group-wrapper mb-3" >
                      <label className='d-block mb-2'><FormattedMessage id="IsBodyandPaintcarriedoutat1location" defaultMessage="Is Body and Paint carried out at 1 location?" /></label>
                      <Radio.Group name="bodyPaintLocation" onChange={handleRadio("bodyPaintLocation")} value={values.bodyPaintLocation} >
                      <Radio value="yes" className='mr-2'><FormattedMessage id="OptionYes" defaultMessage="yes" /></Radio>
                      <Radio value="no" defaultChecked><FormattedMessage id="OptionNo" defaultMessage="no" /></Radio>
                      </Radio.Group>
                    </div>
                    <div className='d-flex justify-content-end align-items-center'>
                      <button className='btn btn-primary bg-blue mt-3 mr-2 cursor-pointer' onClick={prevStep} ><FormattedMessage id="PreviousStep" defaultMessage="Previous Step" /></button>
                      <input type="submit" className='btn btn-primary bg-blue mt-3 cursor-pointer' value={intl.formatMessage({ id: "SubmitForm", defaultMessage: "Submit Form" })} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Step3;
import localAR from './lang/ar.json';
import localBG from './lang/bg.json';
import localDE from './lang/de.json';
import localEN from './lang/en.json';
import localES from './lang/es.json';
import localFR from './lang/fr.json';
import localHR from './lang/hr.json';
import localHU from './lang/hu.json';
import localIT from './lang/it.json';
import localNL from './lang/nl.json';
import localBL from './lang/bl.json';
import localPL from './lang/pl.json';
import localRO from './lang/ro.json';
import localRU from './lang/ru.json';
import localSK from './lang/sk.json';
import localSR from './lang/sr.json';
import localTR from './lang/tr.json';
import localZH from './lang/zh.json'
import localJP from './lang/jp.json'
export const messages = {
  ar: localAR,
  bg: localBG,
  de: localDE,
  en: localEN,
  es: localES,
  fr: localFR,
  hr: localHR,
  hu: localHU,
  it: localIT,
  nl: localNL,
  bl: localBL,
  pl: localPL,
  ro: localRO,
  ru: localRU,
  sk: localSK,
  sr: localSR,
  tr: localTR,
  zh: localZH,
  jp: localJP
};

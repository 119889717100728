import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import locale from 'antd/lib/locale/de_DE';
const KPIChart = (props) => {
  console.log("KPI Chart Props");
  console.log(props)
  const cardTitleProps = props.title;
  const [ChartData, setChartData] = useState([]);
  const [ChartDataDays, setChartDataDays] = useState([]);
  const [ChartDataWeeks, setChartDataWeeks] = useState([]);
  const [ChartDataMonths, setChartDataMonths] = useState([]);
  const [ChartDataYears, setChartDataYears] = useState([]);
  const [cardTitle, setcardTitle] = useState("")
  const [chartTabCounter, setchartTabCounter] = useState(1)
  const [meta, setMeta] = useState({})
  const datafilterhandler = (text) => {
    if (text === 'day') {
      setcardTitle(`${cardTitleProps} All Days`);
      setchartTabCounter(0);

      setChartData(ChartDataDays);
      console.log('day clicked');
    }
    if (text === 'week') {
      setcardTitle(`${cardTitleProps} Weeks`);
      setchartTabCounter(1);
      setChartData(ChartDataWeeks);
      console.log('week clicked');
    }
    if (text === 'month') {
      setcardTitle(`${cardTitleProps} Months`);
      setchartTabCounter(2);
      setChartData(ChartDataMonths);
      console.log('month clicked');
    }
    if (text === 'years') {
      setcardTitle(`${cardTitleProps} Years`);
      setchartTabCounter(3);
      setChartData(ChartDataYears);
      console.log('years clicked');
    }
  }

  useEffect(() => {

    if (Object.keys(props.ChartData).length !== 0  && props.label !== "") {
      setMeta({
        Date: {
          alias: props.label.Date,
        },
        Count: {
          alias: props.label.Count,
        },
      });

      const dataList = props.ChartData;
      setChartDataDays(dataList.daysDataset);
      setChartDataWeeks(dataList.weeksDataset);
      setChartDataMonths(dataList.monthsDataset);
      setChartDataYears(dataList.yearsDataset);
      setChartData(dataList.daysDataset);

      if(dataList.daysDataset.length > 0){
      datafilterhandler('day');
    }else
      if(dataList.weeksDataset.length > 0){
        datafilterhandler('week');
      }else  if(dataList.monthsDataset.length > 0){
        datafilterhandler('month');
      }else if(dataList.yearsDataset.length > 0){
        datafilterhandler('years');
      }
    }




  }, [props.ChartData, props.graphLabels, props.chartTitle])

  useEffect(() => {
    if (chartTabCounter === 0) {
      setChartData(ChartDataDays);
    }
    if (chartTabCounter === 1) {
      setChartData(ChartDataWeeks);
    }
    if (chartTabCounter === 2) {
      setChartData(ChartDataMonths);
    }
    if (chartTabCounter === 3) {
      setChartData(ChartDataYears);
    }
  }, [ChartDataDays,ChartDataWeeks,ChartDataWeeks,ChartDataMonths]);

  const currencyNumberFormat = (value) => {
    if(value === undefined || value === null){
      value = 0
    }
  return new Intl.NumberFormat(props.userLocale, {
    style: 'currency',
    currency: props.currency,
    currencyDisplay: "symbol"
  }).format(value);
}


  const config = {
    data: ChartData,
    xField: "Date",
    yField: "Count",
    yAxis: {
      min: 0,
    },
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    slider: {start: 0, end: 1, height: 16},
    meta: meta,
    color: "#F17329",
  };



  return (
    <div className="card-chart pd-16 bg-white card-height" style={{ "position": "relative" }}>
      <div className='d-flex justify-content-between mb-4'>
      <h3 className="card-c-heading-2" style={{ 'position': 'relative' }}>{cardTitle}</h3>
<div className='d-flex card-filter-wrappers'>
          {props.ChartData.daysDataset && props.ChartData.daysDataset.length > 0 ? <div className={`filter-text ${chartTabCounter === 0 ? "active" : ""}`}
            onClick={() => datafilterhandler('day')}>Days</div> : ""
          }
        {props.ChartData.weeksDataset && props.ChartData.weeksDataset.length > 0 ?
        <div className={`filter-text ${chartTabCounter === 1 ? "active" : ""}`}
          onClick={() => datafilterhandler('week')}>Weeks</div> : "" }
           {props.ChartData.monthsDataset && props.ChartData.monthsDataset.length > 0 ?
        <div className={`filter-text ${chartTabCounter === 2 ? "active" : ""}`}
          onClick={() => datafilterhandler('month')}>Months</div> : "" }
          {props.ChartData.yearsDataset && props.ChartData.yearsDataset.length > 0 ?
        <div className={`filter-text ${chartTabCounter === 3 ? "active" : ""}`}
          onClick={() => datafilterhandler('years')}>Years</div> : "" }
      </div>
      </div>
      <div className="chart-container" style={{ width: "100%", position: 'relative', height: "400px", maxWidth: "100%", }}>
        <Column {...config} dataKey="Count" />
      </div>
    </div>
  );
};
export default KPIChart;
import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import axios from "axios";
import Cookie from "js-cookie";

import MobileMenu from './mobileMenu';
import LanguageSwitcher from '../language-switcher/language-switcher';
import Notifications from "./Notifications";

const Navbar = (props) => {
  const [hasNotificationsRights, setHasNotificationsRights] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [username, setUsername] = useState("")

  // const rights = JSON.parse(atob(localStorage.getItem("rights")));
  // const userId = Cookie.get("localeUser");
  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //   },
  // };
  useEffect(() => {
    const localStorageRights = localStorage.getItem("rights") && JSON.parse(atob(localStorage.getItem("rights")));
    console.log("localStorageRights:", localStorageRights);
    const _hasNotificationsRights = localStorageRights ? localStorageRights?.notifications :
      false;
    setHasNotificationsRights(_hasNotificationsRights);
  }, []);

  // useEffect(() => {
  //   async function fetchUser() {
  //     const response = await axios.get(`/api/private/users/${userId}`, config);
  //     const user = response.data;
  //     console.log("user:", user);
  //     const localStorageRights = localStorage.getItem("rights") && JSON.parse(atob(localStorage.getItem("rights")));
  //     const _hasNotificationsRights = user.rights ? user?.rights?.notifications : 
  //       localStorageRights ? localStorageRights?.notifications :
  //       false;
  //     setHasNotificationsRights(_hasNotificationsRights);
  //   }
  //   fetchUser()
  //     .catch(console.error);
  // }, []);
  

  const handleMobileMenuStatus = () => {
    setMobileMenuOpen(false)
  }




  /*
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    console.log("test")
    document.getElementById("slider").classList.add('None')
    document.body.classList.add('modal-open');
    document.body.classList.toggle('modal-open', isOpen);
  },[isOpen])
*/



  return (
    <>
      <MobileMenu openPage={props.openPage} SetOpenPage={props.SetOpenPage} isMenuOpen={isMobileMenuOpen} closeHandle={handleMobileMenuStatus} />
      <div className="headpanel pos-fixed-top ht-70 l-xl-320 bg-primary z-index-150" style={{ left: 0 }}>
        <div className="brand-logo-left">
          <img src="/images/body-shop-boost-logo.svg" alt='body shop boost logo' className="img-fluid" />
        </div>
        <div className="brand-logo">
          <img src="/images/basf-logo.svg" alt='basf logo' className="img-fluid" />
        </div>
        <div className="d-flex align-items-center justify-content-end ht-100p pd-x-20 w-100 flex-dir">
          {/* <a id="showLeftMenu" href="" className="tx-white mg-r-20 hidden-lg-down"
    ><i className="icon ion-navicon-round tx-size-24"></i
    ></a> */}
          <div className="mobile-hamburger" onClick={() => { setMobileMenuOpen(true); }}>
            <img src="/images/menu_black_24dp.svg" alt="print-icon" />
          </div>
          {/* <a id="showLeftMenuMobile" href="" className="tx-white mg-r-20 hidden-xl-up"
    ><i className="icon ion-navicon-round tx-size-24"></i
    ></a> */}
          <nav className="nav nav-inline align-items-center justify-content-end flex-row">
            {hasNotificationsRights && (
              <Notifications />
            )}

            <LanguageSwitcher side="right" />

            <div className="dropdown">
              <a href className="nav-link tx-white pd-x-0 tx-size-13 cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="/images/profile-circle.png" className="rounded-circle wd-32" alt />
              </a>
              <div className="dropdown-menu dropdown-menu-right  tx-size-13 mg-t-10 pd-0">
                <div className="username-wrapper d-flex align-items-center">
                  <img src="/images/icons/avatar-black.svg" className="rounded-circle d-flex wd-48 mg-r-15" alt="Image" />
                  <div className="media-body pd-t-5">
                    <h6 className="mg-b-2 tx-inverse tx-semibold text-capitalize">{localStorage.getItem("username")}</h6>
                  </div>{/* media-body */}
                </div>
                <Link className="dropdown-item align-items-center d-flex justify-content-start tx-gray-dark" to="/setting"><img src="/images/icons/settings_black_24dp.svg" className="rounded-circle d-flex wd-24 mg-r-15" alt="Image" />
                  <FormattedMessage id="Settings" defaultMessage="Settings" />
                </Link>
                {/* <Link className="dropdown-item align-items-center d-flex justify-content-start tx-gray-dark" to="/api-setting"><img src="/images/icons/key.svg" className="rounded-circle d-flex wd-24 mg-r-15" alt="Image" />
                  <FormattedMessage id="API" defaultMessage="API" />
                </Link> */}
                <Link className="dropdown-item align-items-center d-flex justify-content-start tx-gray-dark" to="/logout" ><img src="/images/icons/logout_black_24dp.svg" className="rounded-circle d-flex wd-24 mg-r-15" alt="Image" />
                  <FormattedMessage id="Logout" defaultMessage="Logout" />
                </Link>
              </div>
              {/* dropdown-menu */}
            </div>

            {/* dropdown */}
          </nav>
        </div>
      </div>
    </>


  );
}


export default Navbar
import round from './round';

import { format, parse, addDays } from 'date-fns';


const MilestoneProgress = (milestones, tasks, MileStoneResult, Tags, Weight) => {
  try {
    let newMilestones = []
    //   console.log(milestones)
    //   console.log(MileStoneResult)
    //  console.log(Tags)
    // console.log(Weight)
    milestones.map(element => {
      //    console.log(element)
      // let elementTasksValues = [];

      let DateSort
      if (element["dueDate"] && element["dueDate"] !== "false") {
        DateSort = parse(element["dueDate"], 'yyyyMMdd', new Date())
        DateSort = DateSort.valueOf()
      } else {
        DateSort = 9999999999999
      }

      //   console.log(DateSort)
      let result = {}
      result["_id"] = element["_id"]
      result["DateSort"] = DateSort
      result["Persons"] = element["Persons"]
      result["Title"] = element["Title"]
      result["dueDate"] = element["dueDate"]
      result["startDate"] = element["startDate"]
      result["startDate"] = element["startDate"]
      result["TotalWeight"] = 0
      result["WeightComplete"] = 0
      result["complete"] = 0
      result["total"] = 0
      result["Tasks"] = []
      result["Questions"] = []
      result["uncompleted"] = 0
      result["pictures"] = []
      /*    console.log(element)
         console.log(element["Tasks"]) */

      let allChildren = []

      if (element.Tasks?.length > 0) {
        element.Tasks.forEach(task => {
            // Object.keys(tasks).forEach(el => {
            //   if (task && task[1] && el.startsWith(task[1])) {
    
            //     allChildren.push(el)
            //   }
            // })
            // if (task && task[2]) {
    
            //   task[2].map(Additem => {
            //     if (!allChildren[Additem]) {
            //       allChildren.push(Additem)
            //     }
            //   })
    
    
            // }
            let item;
            if (task && task.label && task.value) {
              item = task.value;
            } else {
              item = item;
            }

            allChildren.push(item)
          })
          //   console.log(allChildren.length)
    
    
          // let TheseTags = []
          // if (task[3]) {
          //   task[3].map(tagEl => {
    
          //     TheseTags.push(parseInt(tagEl["value"]))
          //   })
          // }
      }

      //   console.log(Tags)
      allChildren.map(item => {
        if (tasks[item] && tasks[item][1] && tasks[item][2] && tasks[item][3]) {
          result["searchTasks"] = ["Percentage", tasks[item][1], tasks[item][2], tasks[item][3]]
        }
        /*  const even = (element) => element % 2 === 0; */
        if (tasks[item] && tasks[item][12] && tasks[item][12].some(el => Tags.includes(el))) {

          function addToList() {

            if (tasks[item][4]) {
              result["pictures"].push([tasks[item][4][0], tasks[item][5][0]])

            } 

            //yesno
            if (!tasks[item][2] || tasks[item][12].includes(58572)) {

              /*   if (tasks[item][12].includes(58604)) {

                } */
              result["total"]++
              let additionalWeight = 1
              if (tasks[item][12].includes(58604)) {
                function checkFather(FatherName, ChildBay) {
                  //    console.log(FatherName)
                  let ElementName = FatherName.split('.')
                  if (ChildBay == 0) {
                    ChildBay = ElementName.pop()
                  } else {
                    let newChildBay = ElementName.pop()
                    ChildBay = newChildBay + "." + ChildBay
                  }
                  //  console.log(ChildBay)
                  ElementName = ElementName.join('.')
                  // console.log(FatherName)
                  //console.log(ChildBay)
                  if (tasks[FatherName] && !tasks[FatherName][12].includes(58561)) {
                    //  console.log("didnt")
                    //   console.log(ElementName)
                    return checkFather(ElementName, ChildBay)
                  } else {
                    //  console.log("final")
                    ChildBay = ChildBay.split(".")
                    ChildBay.shift()
                    ChildBay = ChildBay.join(".")
                    //    console.log(ChildBay)
                    let finalName = FatherName.split('.')
                    finalName.pop()
                    finalName = finalName.join('.')
                    finalName = finalName + ".1." + ChildBay
                    return finalName

                    /*  console.log(ElementName)
                     console.log(ChildBay) */
                    // console.log("final")
                  }

                }

                //2.6.2.44.15.6
                let result = checkFather(item, 0)
                // console.log(result)

                if (Weight[result]) {
                  //   console.log(Weight[result])
                  additionalWeight = Weight[result]
                } else if (Weight[item]) {
                  additionalWeight = Weight[item]
                } else {
                  additionalWeight = 1
                }
                // console.log(checkFather(item))

              }
              else if (tasks[item][12].includes(58511)) {
                //  console.log(item)
                let itemcheck = item.split(".")
                let CheckThisItem = itemcheck.pop()
                //  console.log(CheckThisItem)
                // console.log(4 + ".1" + ".1" + ".1." + CheckThisItem)
                //  console.log(Weight[4 + ".1" + ".1" + ".1." + CheckThisItem])
                if (Weight[4 + ".1" + ".1" + ".1." + CheckThisItem]) {
                  // console.log(Weight[4 + ".1" + ".1" + ".1." + CheckThisItem])
                  additionalWeight = Weight[4 + ".1" + ".1" + ".1." + CheckThisItem]
                } else if (Weight[item]) {
                  additionalWeight = Weight[item]
                } else {
                  additionalWeight = 1
                }
                // console.log(checkFather(item))

              }

              else if (Weight && Weight.hasOwnProperty(item)) {
                /*      console.log(Weight[item[0]]) */
                additionalWeight = Weight[item]
              }

              result["TotalWeight"] += additionalWeight  //+= item[2]
              if (tasks[item][1] /* || tasks[item[0]][10] == 20 */ || tasks[item][1] == 100) {
                result["complete"]++
                result["WeightComplete"] += additionalWeight // += item[2]
              } else {
                result["uncompleted"]++
              }
            }

            result["Tasks"].push(item)


          }



          function checkIfFirstChild(FatherName) {
            //  console.log(FatherName)
            let ElementName = FatherName.split('.')
            let ChildBay = ElementName.pop()
            ElementName = ElementName.join('.')
            if (FatherName.length == 1) {
              return true
            }

            if (tasks[FatherName] && !tasks[FatherName][12].includes(58561)) {

              return checkIfFirstChild(ElementName)
            } else {
              if (ChildBay == "1") {
                return true
              } else {
                if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Mechanical Work Bays" && !tasks[item][12].includes(58481))) {

                  return true
                }
                if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Remove + Refit Area" && !tasks[item][12].includes(58184))) {

                  return true
                }
                if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Light Body Repair Bays" && !tasks[item][12].includes(58480))) {
                  return true
                }
                if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Heavy Body Repair Bays" && !tasks[item][12].includes(58479))) {
                  return true
                }
                if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Aluminium Body Repair Bays" && !tasks[item][12].includes(58485))) {
                  return true
                }
                if (tasks[FatherName] && tasks[FatherName][7] == "Preparation Bays" && !tasks[item][12].includes(58610)) {
                  //   console.log(item)
                  return true
                }
                if (tasks[FatherName] && tasks[FatherName][7] == "Masking Bays" && !tasks[item][12].includes(58609)) {
                  // console.log(item)
                  return true
                }
                if (tasks[FatherName] && tasks[FatherName][7] == "Polishing Bays" && !tasks[item][12].includes(58611)) {
                  return true
                }
                if (tasks[FatherName] && tasks[FatherName][12].includes(58885)) {
                  return true
                }
                return false
              }
            }
          }

          // bayquestion
          if (tasks[item][12].includes(58604)) {
            //    console.log(item)
            if (checkIfFirstChild(item)) {
              //    console.log("test")
              return
            }
          }
          function checkIfFirstChildTwo(FatherName) {
            //  console.log(FatherName)
            let ElementName = FatherName.split('.')
            let ChildBay = ElementName.pop()
            ElementName = ElementName.join('.')
            if (FatherName.length == 1) {
              return true
            }

            if (tasks[FatherName] && (!tasks[FatherName][12].includes(58885))) {
              return checkIfFirstChildTwo(ElementName)
            } else {
              if (ChildBay == "1") {
                return true
              } else {
                return false
              }
            }
          }

          if (tasks[item][12].includes(58862)) {
            if (checkIfFirstChildTwo(item)) {
              return
            }
          }
          function getfather(ElementName) {
            if (!tasks[ElementName][12].includes(58572)) {
              ElementName = ElementName.split('.')
              ElementName.pop()
              ElementName = ElementName.join('.')
              return getfather(ElementName)
            } else {
              if (tasks[ElementName][7] == "yes") {
                return true
              } else {
                return false
              }
            }
          }

          //repairquality
          if (tasks[item][12].includes(58511)) {
            //   console.log(item)
            let name = item.split(".")
            /*    console.log(name) */
            name.pop()
            name.pop()
            /*    console.log(name) */
            let parentItem = name.join(".")
            //   console.log(parentItem)
            name.pop()
            let GrandParentItem = name.join(".")
            //  console.log(GrandParentItem)
            if (tasks[GrandParentItem][2][tasks[GrandParentItem][2].length - 1] == parentItem && parentItem !== "4.1.1") {

            } else {

              return
            }

          }

          //conditional ?
          if (tasks[item][12].includes(58557)) {
            //      console.log(item)
            if (getfather(item)) {
              addToList()
            } else {
              result["Tasks"].push(item)
            }
          } else {
            addToList()
          }
        }
      })

      element.Questions.map(item => {

        /*  const even = (element) => element % 2 === 0; */
        if (item && item[3] != "") {
          if (item[4] && item[5]) {
            console.log("item[4].length",item[4].length)
            console.log(item)
            for(let i=0; i < item[4].length; i++){
              result["pictures"].push([item[4][i],item[5][i]])
            }
            //result["pictures"].push(item[4][0], item[5][0])
          } 

          result["total"]++

          // let additionalWeight = 1
          // result["TotalWeight"] += additionalWeight  //+= item[2]
          if (item[1] || item[1] == 100) {
            result["complete"]++
            // result["WeightComplete"] += additionalWeight // += item[2]
          } else {
            result["uncompleted"]++
          }
        }

        result["Questions"].push(item)
      })

      result["Result"] = round(100 * result["WeightComplete"] / result["TotalWeight"])

      newMilestones.push(result)
    })


    newMilestones.sort((a, b) => (a.DateSort > b.DateSort) ? 1 : (a.DateSort === b.DateSort) ? ((a.Title > b.Title) ? 1 : -1) : -1)
    MileStoneResult[0] = newMilestones
    //   console.log(newMilestones)
  } catch (error) {
    console.error(error);
  }

}

export default MilestoneProgress
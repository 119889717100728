import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import { intlFormat } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select"
import { format, parse, addDays } from 'date-fns';


const ProjectTasklistSecondScreen = (props) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [TextField, setTextField] = useState("");
  const [FilesField, setFilesField] = useState(false);
  const [ThumbsUp, setThumbsUp] = useState(false);
  const [Display, setDisplay] = useState(true);
  const [newComment, setNewComment] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [saved, setSaved] = useState(true);
  const [pictures, setPictures] = useState([]);
  const [picturesTwo, setPicturesTwo] = useState(false);
  const [userNames, setUserNames] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(false);
  const [deleted, setDeleted] = useState([]);

  const firstUpdate = useRef(true);

  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (firstUpdate.current) {
      const req = axios.get(`/api/private/userNames`, config)
        .then((response) => {
          let usernamesarr = []
          response.data.forEach(item => {
            let obj = {}
            obj.value = item.username
            obj.label = item.username
            usernamesarr.push(obj)
          })

          setUserNames(usernamesarr)
        })
      if (parseInt(props.allTasks[props.SecondScreen][10]) === 20 ) {
        setThumbsUp("Down")
        firstUpdate.current = false;
      }
      if (parseInt(props.allTasks[props.SecondScreen][10]) === 100 ) {
        setThumbsUp("Up")
        firstUpdate.current = false;
      }
      if (props.allTasks[props.SecondScreen][8]) {
        setStartDate(parse(props.allTasks[props.SecondScreen][8], 'yyyyMMdd', new Date()))
      }
      if (props.allTasks[props.SecondScreen][9]) {
        setSelectedPerson({
          value: props.allTasks[props.SecondScreen][9],
          label: props.allTasks[props.SecondScreen][9]
        })
      }
    }

    if (props.allTasks[props.SecondScreen][20]) {
      setPicturesTwo(props.allTasks[props.SecondScreen][20])
    }
    if (props.allTasks[props.SecondScreen][4] && props.allTasks[props.SecondScreen][5]) {
      setPictures(props.allTasks[props.SecondScreen][4] && props.allTasks[props.SecondScreen][5])
      /*    pictures.push(props.allTasks[props.SecondScreen][4][0], props.allTasks[props.SecondScreen][5][0]) */
    }
  }, [])

  const config = {
    headers: {
      "Content-Type": "application/jso n",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const openModalPics = (pictures, Index) => {
    let Img = pictures[0]

    if (props.noTeamwork) {
      let fullImg = (<img style={{ maxWidth: "90vw", maxHeight: "60vh" }} className="ImgModalPic" src={pictures[0]} />)
      props.setCurrentImg([fullImg, Index])
      props.setModalImage(pictures)
      return
    }

    try {
      axios.get(`/api/private/files/${Img}`, config).then(res => {
        const image = res.data.file["download-URL"]
        let fullImg = (<img style={{ maxWidth: "90vw", maxHeight: "60vh" }} className="ImgModalPic" src={image} />)
        props.setCurrentImg([fullImg, Index])
        props.setModalImage([pictures])
      })
    } catch (e) {
      if (!props.dev) {
        localStorage.removeItem("authToken");
        window.location.href = '/logout';
      }
    }
  }

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const handleTextChange = (e) => {
    setSaved(false)
    setTextField(e.target.value)
  }

  const handleFileChange = (e) => {
    setSaved(false)

    const handleFileSave = () => {
      const data = new FormData()

      data.append("keyOfTask", props.SecondScreen)
      data.append("ProjectID", props.idd)
      data.append("activityUrl", location.pathname)
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      data.append('file', e.target.files[0])
      const NameOfTask = props.allTasks[props.SecondScreen][0]
      axios.post(`/api/private/queue/uploadimg/${NameOfTask}`, data, config)
        .then((response) => {
          setFilesField("")
          setSaved(true)

          let newItem = [...props.allTasks[props.SecondScreen]]
          if (newItem[4] === null || newItem[4] === false) {
            newItem[4] = [response.data[0]]
          } else {
            newItem[4].push(response.data[0])
          }

          if (newItem[5] === null || newItem[5] === false) {
            newItem[5] = [response.data[1]]
          } else {
            newItem[5].push(response.data[1])
          }

          pictures.push(response.data[0])

          /*  console.log(URL.createObjectURL(e.target.files[0]))
           newItem[20] = URL.createObjectURL(e.target.files[0]) */

          props.setAllTasks((prevState) => ({
            ...prevState,
            [props.SecondScreen]: newItem
          }));

          return response.data
        }, (error) => {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        })
    }
    handleFileSave()
    /*
        setFilesField(e.target.files[0]) */
  }

  const deletePic = (e) => {
    if (e === "File") {
      setFilesField(false)
    } else {
      const handleDeleteTask = () => {
        const NameOfTask = props.allTasks[props.SecondScreen][0]
        const PostData = {
          NameOfTask: NameOfTask,
          keyOfTask: props.SecondScreen,
          delete: [props.allTasks[props.SecondScreen][4][e], props.allTasks[props.SecondScreen][5][e]],
          activityUrl: location.pathname,
        }

        PostData["ProjectID"] = props.idd
        const sendToServer = async () => {
          try {
            const config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            };
            const data = await axios.put(`/api/private/queue/deleteIMG/${NameOfTask}`, PostData, config);
            return data
          } catch (e) {
            if (!props.dev) {
              localStorage.removeItem("authToken");
              window.location.href = '/logout';
            }
          } finally {
          }
        }
        sendToServer().then(item => {
          let newItem = [...props.allTasks[props.SecondScreen]]
          const filteredItemsFour = props.allTasks[props.SecondScreen][4].filter(item => item !== props.allTasks[props.SecondScreen][4][e])
          const filteredItemsFive = props.allTasks[props.SecondScreen][5].filter(item => item !== props.allTasks[props.SecondScreen][5][e])
          newItem[4] = filteredItemsFour
          newItem[5] = filteredItemsFive

          newItem[10] = 20
          props.setAllTasks((prevState) => ({
            ...prevState,
            [props.SecondScreen]: newItem
          }));
          setSaved(true)
        })
      }
      if (window.confirm("Do you really want to delete it?")) {
        handleDeleteTask()
      }
      /* setDeleted(prevState=>{
        prevState.push()
      }) */
      /* setDeleted() */
      /* index */
    }
    setSaved(false)
  }

  const Rename = (name) => {
    name = name.split(' ')
    name.shift()
    name = name.join(' ')
    return name
  }

  const GetCodeRename = (name) => {
    name = name.split(' ')
    name = name[0];
    return name
  }

  const handleSave = () => {
    const handleFinishTask = (e) => {
      const NameOfTask = props.allTasks[props.SecondScreen][0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.SecondScreen,
        activityUrl: location.pathname,
      }
      if (startDate) {
        PostData.startDate = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        PostData.selectedPerson = selectedPerson.value
      }
      /* if () */

      PostData["ProjectID"] = props.idd
      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/queue/finishtask/${NameOfTask}`, PostData, config);
          return data
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        } finally {
        }
      }
      sendToServer().then(item => {
        let newItem = [...props.allTasks[props.SecondScreen]]
        newItem[1] = true
        newItem[10] = 100
        if (startDate) {
          newItem[8] = format(startDate, "yyyyMMdd")
        }
        if (selectedPerson) {
          newItem[9] = selectedPerson.value
        }
        if (TextField && TextField !== "") {
          newItem[7] = TextField
        }
        if (FilesField) {
          newItem[20] = "reload to see new pictures"
        }
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.SecondScreen]: newItem
        }));
        setSaved(true)
      })
    }

    const handleProgressTask = (e) => {
      const NameOfTask = props.allTasks[props.SecondScreen][0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.SecondScreen,
        activityUrl: location.pathname,
      }
      if (startDate) {
        PostData.startDate = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        PostData.selectedPerson = selectedPerson.value
      }
      PostData["ProjectID"] = props.idd
      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/queue/progressTask/${NameOfTask}`, PostData, config);
          return data
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        } finally {
        }
      }
      sendToServer().then(item => {
        let newItem = [...props.allTasks[props.SecondScreen]]
        if (startDate) {
          newItem[8] = format(startDate, "yyyyMMdd")
        }
        if (selectedPerson) {
          newItem[9] = selectedPerson.value
        }
        newItem[10] = 20
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.SecondScreen]: newItem
        }));
        setSaved(true)
      })
    }

    const handleSaveComment = (e) => {
      const NameOfTask = props.allTasks[props.SecondScreen][0]
      let progress
      if (ThumbsUp === "Up") {
        progress = 100
      } else {
        progress = 20
      }
      const PostData = {
        NameOfTask: NameOfTask,
        Textbody: TextField,
        Progress: progress,
        keyOfTask: props.SecondScreen,
        activityUrl: location.pathname,
      }
      if (startDate) {
        PostData.startDate = format(startDate, "yyyyMMdd")
      }
      if (selectedPerson) {
        PostData.selectedPerson = selectedPerson.value
      }

      PostData["ProjectID"] = props.idd
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      const req = axios.post(`/api/private/queue/comments/${NameOfTask}`, PostData, config)
        .then((response) => {
          setTextField("")
          setSaved(true)

          if (ThumbsUp === "Down" && !FilesField) {
            let newItem = [...props.allTasks[props.SecondScreen]]
            newItem[10] = 20
            if (startDate) {
              newItem[8] = format(startDate, "yyyyMMdd")
            }
            if (selectedPerson) {
              newItem[9] = selectedPerson.value
            }
            newItem[7] = TextField

            props.setAllTasks((prevState) => ({
              ...prevState,
              [props.SecondScreen]: newItem
            }));
          } else if ((ThumbsUp === "Up" && !FilesField)) {
            let newItem = [...props.allTasks[props.SecondScreen]]
            newItem[1] = true
            if (startDate) {
              newItem[8] = format(startDate, "yyyyMMdd")
            }
            if (selectedPerson) {
              newItem[9] = selectedPerson.value
            }
            newItem[10] = 100
            newItem[7] = TextField
            props.setAllTasks((prevState) => ({
              ...prevState,
              [props.SecondScreenSecondScreen]: newItem
            }));
          }
          return response
        }, (error) => {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        })
    }

  /*   if (FilesField && FilesField !== "") {
      handleFileSave()
    } else */ if (TextField && TextField !== "") {
      handleSaveComment()
    } else {
      if (ThumbsUp === "Up") {
        handleFinishTask()
      } else if (ThumbsUp === "Down") {
        handleProgressTask()
      }
    }
  }

  return (
    <>
      <div className="d-flex flex-column pd-20 overflow-y-auto modal-content-wrapper">
        <div className="row mb-2 ml-0">
          <h5 className='font-bold'>{GetCodeRename(props.allTasks[props.SecondScreen][3])}&nbsp;<FormattedMessage id={Rename(props.allTasks[props.SecondScreen][3])} defaultMessage={Rename(props.allTasks[props.SecondScreen][3])} /></h5>
        </div>
        <div className="row inner-popup align-items-center">
          <div className="col-md-3 mb-2 d-flex flex-row align-items-center">
            <h6 className='mr-10'><FormattedMessage id="DueDate" defaultMessage="Due Date" /></h6>
            {/* {props.allTasks[props.SecondScreen][8] ? <h6>{props.allTasks[props.SecondScreen][8]}</h6> : ""} */}
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
          </div>
          <div className="col-md-3 mb-2 d-flex flex-row align-items-center">
            <h6 className='mr-10'><FormattedMessage id="Assignedto" defaultMessage="Assigned to" /></h6>
            {/*  {props.allTasks[props.SecondScreen][9] ? <h6>{props.allTasks[props.SecondScreen][9]}</h6> : ""} */}
            <div className="select-wrapper">
              <Select
                onChange={setSelectedPerson}
                value={selectedPerson}
                placeholder={intl.formatMessage({ id: "SelectPerson", defaultMessage: "Select Person" })}
                options={userNames}
              />
            </div>
          </div>
          <div className="col-md-3  mb-2">
            {/*  <Select
              placeholder={intl.formatMessage({ id: "AddtoMilestone", defaultMessage: "Add to Milestone" })}
              options={[{ value: "Milestone 1", label: "Milestone 1" }, { value: "Milestone 2", label: "Milestone 2" }, { value: "Milestone 3", label: "Milestone 3" }, { value: "Milestone 4", label: "Milestone 4" }]}
            /> */}
          </div>
          <div className='col-md-3 mb-2  d-flex justify-content-md-end'>
            <i className={ThumbsUp === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} onClick={() => setThumbsUp("Up")}></i>
            <i className={ThumbsUp === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} onClick={() => setThumbsUp("Down")}></i>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-2">
            <h6>Comments </h6>
            {props.canEdit ?
              <>
                <textarea className="textarea-styling"
                  placeholder={intl.formatMessage({ id: "Addyourcommenthere", defaultMessage: "Add your comment here..." })}
                  value={TextField}
                  onChange={e => handleTextChange(e)}
                />
              </> : ""}
          </div>
          <div className="col-md-6  mb-2 ">
            <div className='last-comments-wrapper'>
              <div className="single-comment">
                {props.allTasks[props.SecondScreen][7] ? <p>{props.allTasks[props.SecondScreen][7]}</p>
                  : <p>no comments yet</p>}
              </div>
            </div>
          </div>
        </div>

        {props.canEdit ?
          <>
            <h6><FormattedMessage id="PleaseSubmitaphoto" defaultMessage="Please Submit a photo" /></h6>
            <div className="row-photos d-flex mt-2">
              {console.log(pictures)}
              {pictures && pictures.length > 0 ?
                pictures.map((item, index) => {
                  return (
                    item !== null && (
                    <div className="fixHeight item fillcontainer imgtasklistcontainer  pos-rel fillcontainer-dim">
                      <div onClick={() => deletePic(index)} className="delete-icon-wrapper">
                        <i class="fas fa-trash cursor-pointer"></i>
                      </div>
                      <img className="imgfill" onClick={() => openModalPics(pictures, index)} src={item} alt="img" />
                      {/*    <img className="imgfill" onClick={() => openModalPics(props.Pictures, Index)} src={item[1]} alt="img" /> */}
                    </div>
                    )
                  )
                })
                : ""
              }

              {/*  {picturesTwo &&
                <div className="fixHeight item fillcontainer imgtasklistcontainer  pos-rel fillcontainer-dim">
                  <img style={{ cursor: "inital" }} className="imgfill" src={picturesTwo} alt="img" />
                </div>
              } */}


              {/*     {FilesField &&

                <div className="fixHeight item fillcontainer imgtasklistcontainer  pos-rel fillcontainer-dim">

                  <div onClick={() => deletePic("File")} className="delete-icon-wrapper">
                    <i class="fas fa-trash cursor-pointer"></i>
                  </div>
                  <img className="imgfill" onClick={() => openModalPics(pictures, 0)} src={URL.createObjectURL(FilesField)} alt="img" />
                </div>} */}

              <><label style={{ marginBottom: "0" }} >
                <input accept="image/*;capture=camera" type="file" name="file" onChange={e => handleFileChange(e)} />
                <div style={{
                  height: "140px",
                  border: "1px solid black",
                  width: "140px",
                  borderRadius: "20px",
                  marginLeft: "10px",
                  display: "flex",
                  height: "140px"
                }} className="item fillcontainer imgtasklistcontainer">
                  <i style={{ margin: "auto", fontSize: "70px" }} className="fas fa-image"></i>
                </div>
              </label>
              </>
            </div>
          </>
          :
          //props cant edit
          <>
            {FilesField ?
              <div className="fixHeight item fillcontainer fillcontainer-dim">
                <img className="imgfill imgtasklist" onClick={() => openModalPics(pictures, 0)} src={URL.createObjectURL(FilesField)} alt="img" />
              </div>
            : picturesTwo ?
              <div className="item fillcontainer fillcontainer-dim">
                <img style={{ cursor: "inital" }} className="imgfill imgtasklist" src={picturesTwo} alt="img" />
              </div>
            : pictures.length > 0 ?
                <div className="item fillcontainer fillcontainer-dim">
                  <img className="imgfill imgtasklist" onClick={() => openModalPics(pictures, 0)} src={pictures[1]} alt="img" />
                </div>
            : ""
            }
          </>
        }

        <div className="d-flex justify-content-end align-items-center">
          <button 
            className='cta-btn cta-outline mr-10' 
            onClick={() => {
              props.setSecondScreen(false);
              history.push(location.pathname.split('/').slice(0,-1).join('/'));
            }}
          >
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </button>
          {props.canEdit ?
            <button className={!saved && (ThumbsUp === "Up" || ThumbsUp === "Down") ? 'cta-btn' : ' cta-btn notclick'}
              onClick={
                !saved && (ThumbsUp === "Up" || ThumbsUp === "Down") ? () => handleSave() : () => false
              }><FormattedMessage id="Save" defaultMessage="Save" /></button>
            : ""}
        </div>
      </div>
    </>
  )
}

export default ProjectTasklistSecondScreen
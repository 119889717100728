import React, { useEffect, useState } from "react";
import { Radio, Space } from "antd";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import { message } from "antd";
const ProjectKpiFormatModal = (props) => {
  const intl = useIntl();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  console.log("props.projectKpiFormat");
  console.log(props.projectKpiFormat);
  const [value, setValue] = useState(props.projectKpiFormat);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
    props.setKpiFormatModal(e.target.value);
  };

  const handleSave = () => {
    let body = {
      kpiFormat: value,
    };

    console.log(body);

    const sendToServer = async () => {
      console.log(body);
      try {
        const data = await axios.put(
          `/api/private/changkpiformat/${props.idd}`,
          body,
          config
        );
        console.log(data);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    };
    sendToServer().then((item) => {
      message.success(
        intl.formatMessage({
          id: "ProjectKpiFormatUpdated",
          defaultMessage: "Project Kpi Format Updated!",
        })
      );
      setTimeout(() => {
        window.location.reload(false);
      }, 1500);
    });
  };
  const allKpiFormats = ["v2", "v1"];
  return (
    <div>
      {
        <div
          className={`fq-modal fq-modal-small  ${
            props.show ? "d-block" : "d-none"
          }`}
        >
          <div className="fq-modal-content">
            <div style={{ background: "#BE0312" }} className="fq-modal-header">
              <span
                onClick={() => props.setKpiFormatModal(false)}
                className="close"
              >
                <img src="/images/close-btn.svg" alt="btn close" />
              </span>
              <h1>
                <FormattedMessage
                  id="ChangeKPIVeersion"
                  defaultMessage="Change KPI Version"
                />{" "}
              </h1>
            </div>
            <div className="fq-modal-content" style={{ minHeight: "40vh" }}>
              {allKpiFormats.map((item) => {
                return (
                  <>
                    <Radio.Group onChange={onChange} value={value}>
                      <Space direction="vertical">
                        <Radio value={item}>{item}</Radio><br/>
                      </Space>
                    </Radio.Group>
                  </>
                );
              })}
              <br/>
              <button
                className=" btn cta-btn d-inline-block"
                onClick={() => handleSave()}
              >
                <FormattedMessage id="Save" defaultMessage="Save" />
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default ProjectKpiFormatModal;

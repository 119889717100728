import { useIntl, FormattedMessage } from "react-intl";
import { useState, useEffect, useLayoutEffect, useRef, ref, memo } from "react";
import axios from "axios";
import Select from "react-select";
import { Radio } from "antd";
import { ToastContainer, toast } from "react-toastify";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Input, List } from "antd";
import lookup from "country-code-lookup";
import { getAllInfoByISO } from "iso-country-currency";

const Step1 = ({
  show,
  onHide,
  nextStep,
  handleFormData,
  handleSelect,
  handleCurrencyChange,
  handleRadio,
  values,
  save,
  projectData,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState("");

  const [assessmentType, setAssessmentType] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [currency, setCurrency] = useState({});
  /* const assessmentType = [{ value: "Facility + Equipment Assessment", label: "Facility + Equipment Assessment" }, { value: "Paint Shop Assessment", label: "Paint Shop Assessment" }]
   */
  const ref = useRef(null);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyC1HdsZ1rBMKZ_jHRjYMtO0rX51rBDZ93Y",
    options: { types: ["geocode", "establishment"] },
  });

  useEffect(() => {
    console.log("render assessments");
    const fetch = async () => {
      const users = axios.get("/api/private/assessments", config);
      const [usersDB] = await Promise.all([users]);
      console.log(usersDB.data);
      console.log(projectData);
      let assessments = [];
      usersDB.data.map((item) => {
        assessments.push({ label: item["Name"], value: item["Name"] });
      });
      setAssessmentType(assessments);
    };
    fetch();
  }, []);

  // useEffect to set currency, currencies and currenciesOptions
  useEffect(() => {
    console.log("(step 1 - useEffect) Fetching currencies...");
    const fetch = async () => {
      try {
        const res = await axios.get("/api/getAllcurrencies", config);
        console.log("(/getAllcurrencies) res.data", res.data);

        const _currencies = res.data;
        console.log("_currencies:", _currencies);
        setCurrencies(_currencies);

        const defaultCurrency = _currencies.find(item => item.code === "EUR");
        const defaultCurrencyOption = convertToOptions([defaultCurrency]);
        console.log("defaultCurrencyOption:", defaultCurrencyOption);
        onCurrencyChange(...defaultCurrencyOption);
        // const _currencies = res.data.map((item) => {
        //   return { label: `${item["name"]} (${item["code"]})`, value: `${item["name"]} (${item["code"]})` };
        // });
        const _currenciesOptions = convertToOptions(_currencies);
        console.log("_currenciesOptions:", _currenciesOptions);
        setCurrenciesOptions(_currenciesOptions);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    values.projectAddress = value;
  }, [value]);

  const convertToOptions = (currencies) => {
    const options = currencies.map((item) => {
       const { _id, name, symbol_native, code } = item;
       return { label: name + " - " + code + " (" + symbol_native + ")", value: _id };
    });

    return options;
 }

  const setCurrencyByAddress = (address) => {
    const country = getCountryFromAddress(address);
    if (country) {
      const countryCode = country.iso2;
      console.log("countryCode:", countryCode);
      const currencyCode = getAllInfoByISO(countryCode).currency;
      console.log("currencyCode:", currencyCode);
      console.log("currencies:", currencies);
      const index = currencies.findIndex(item => item.code === currencyCode);
      if (index >= 0) {
        const _currency = convertToOptions([currencies[index]])
  
        onCurrencyChange(..._currency);
      }
    }
  };

  const getCountryFromAddress = (address) => {
    console.log("address:", address);
    let indexStart = address.lastIndexOf(",") + 1;
    if (indexStart === 0)
      indexStart = address.lastIndexOf("-") + 1;
    const expectedCountry = address.slice(indexStart).trim();
    console.log("expectedCountry:", expectedCountry);
    const country = lookupCountry(expectedCountry);
    console.log("country:", country);
    return country;
  };

  const lookupCountry = (expectedCountry) => {
    if (expectedCountry === "Türkiye")
      expectedCountry = "Turkey";

    try {
      const byCountry = lookup.byCountry(expectedCountry);
      if (byCountry) 
        return byCountry;
      
      const byInternet = lookup.byInternet(expectedCountry);
      if (byInternet)
        return byInternet;
  
      const byIso = lookup.byIso(expectedCountry);
      if (byIso)
        return byIso;
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const onCurrencyChange = (option) => {
    console.log("handleCurrencyChange option:", option);
    setCurrency(option);
    handleCurrencyChange("assessmentCurrency", option);
  }

  // const onCurrencySelect = (e) => {
  //   console.log("ocCurrencySelect option:", e.value);
  //   const option = e.value;
  //   setCurrency(option);
  //   handleCurrencyChange("assessmentCurrency", option);
  // }

  const submitFormData = (e) => {
    e.preventDefault();
    if (
      values.projectName === "" ||
      values.projectAddress ===
        "" /* || values.purposeAssessment === "" || values.boPosition === "" */
    ) {
      toast.error("Some fields are missing", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      if(values.assesstmentType === ""){
        values.assesstmentType = "Standard"
      }
      save();
      /*  nextStep(); */
    }
  };

  console.log("currency:", currency);

  return (
    <>
      <ToastContainer toastClassName="top-zIndex" autoClose={6000} />
      <div>
        {show && (
          <div
            id="popup-addNewProject"
            className="d-block fq-modal fq-modal-small fq-modal-project"
          >
            <div className="fq-modal-content" 
              ref={el => {
                if (el) {
                  el.style.setProperty('overflow', 'visible', 'important');
                }
              }}
            >
              <div className="fq-modal-header">
                <span onClick={onHide} className="close">
                  <img src="/images/close-btn.svg" alt="btn close" />
                </span>
                <h5>
                  <FormattedMessage
                    id="AddanewassessmentStep1"
                    defaultMessage="Add a new assessment - Step 1"
                  />
                </h5>
                <p className="mt-2">
                  <FormattedMessage
                    id="Pleasefilloutthefollowingformtocreateanewassessment"
                    defaultMessage="Please fill out the following form to create a new assessment"
                  />
                </p>
              </div>
              <div className="fq-modal-content-2 pd-20">
                <form className="add-user-wrapper" onSubmit={submitFormData}>
                  <label className="d-block mb-2">
                    <FormattedMessage
                      id="Pleaseenteranameforthisproject"
                      defaultMessage="Please enter a name for this project"
                    />
                  </label>
                  <input
                    type="text"
                    name="projectName"
                    placeholder={intl.formatMessage({
                      id: "Nameofthenewproject",
                      defaultMessage: "Name of the new project*",
                    })}
                    onChange={handleFormData("projectName")}
                    value={values.projectName}
                    className="form-control mb-2"
                  />
                  <div className="w-100">
                    <span>Address</span>
                    <Input.Search
                      value={value}
                      placeholder={intl.formatMessage({
                        id: "AddressGoogleMapsAPI",
                        defaultMessage: "Address (Google Maps API)*",
                      })}
                      onChange={(evt) => {
                        getPlacePredictions({ input: evt.target.value });
                        setValue(evt.target.value);
                        console.log(evt.target.value);
                        console.log(placePredictions);
                        values.projectAddress = value;
                      }}
                      loading={isPlacePredictionsLoading}
                    />
                    <div className="predicition-list">
                      {!isPlacePredictionsLoading && (
                        <List
                          dataSource={placePredictions}
                          renderItem={(item) => (
                            <List.Item
                              onClick={() => {
                                setValue(item.description);
                                setCurrencyByAddress(item.description);
                              }}
                            >
                              <List.Item.Meta title={item.description} />
                            </List.Item>
                          )}
                        />
                      )}
                    </div>
                  </div>
                  {/* <input type="text" name='projectAddress' placeholder={intl.formatMessage({ id: "AddressGoogleMapsAPI", defaultMessage: "Address (Google Maps API)*" })} onChange={handleFormData("projectAddress")} value={values.projectAddress} className='form-control mb-3' /> */}
                  {/*     <label className='d-block mb-2'><FormattedMessage id="Whatisthepurposeoftheassessment" defaultMessage="What is the purpose of the assessment?" /></label>
                  <textarea rows="3" className='form-control mb-3' onChange={handleFormData("purposeAssessment")} value={values.purposeAssessment} />
            */}{" "}
                  <label className="d-block mb-2">
                    <FormattedMessage
                      id="AssessmentType"
                      defaultMessage="Assessment Type?"
                    />
                  </label>
                  <div className="form-group mb-3">
                    <Select
                      placeholder={intl.formatMessage({
                        id: "SelectAssessment",
                        defaultMessage: "Select Assessment",
                      })}
                      options={assessmentType}
                      onChange={handleSelect("assesstmentType")}
                    />
                  </div>
                  <label className="d-block mb-2">
                    <FormattedMessage
                      id="AssessmentCurrency"
                      defaultMessage="Assessment Currency?"
                    />
                  </label>
                  <div className="form-group mb-3">
                    <Select
                      showSearch
                      placeholder={intl.formatMessage({
                        id: "SelectAssessmentCurrency",
                        defaultMessage: "Select Assessment Currency",
                      })}
                      options={currenciesOptions}
                      value={currency}
                      onChange={onCurrencyChange}
                      onSearch={onSearch}
                    />
                  </div>
                  {/*      <div className="d-block radio-group-wrapper mb-3" >
                    <label className='d-block mb-2'><FormattedMessage id="IstheBusinessOwnerorFinancialdecisionmakerawareoftheassessment" defaultMessage="Is the Business Owner or Financial decision maker aware of the assessment?" /></label>
                    <Radio.Group name="boAware" onChange={handleRadio("boAware")} value={values.boAware} >
                      <Radio value="yes" className='mr-2'><FormattedMessage id="OptionYes" defaultMessage="yes" /></Radio>
                      <Radio value="no" defaultChecked><FormattedMessage id="OptionNo" defaultMessage="no" /></Radio>
                    </Radio.Group>
                  </div>
                  <input type="text" name='assessmentPosition' placeholder={intl.formatMessage({ id: "assessmentPosition", defaultMessage: "Position" })} onChange={handleFormData("boPosition")} value={values.boPosition} className='form-control mb-3' />
     */}{" "}
                  {/*     <div className="d-block radio-group-wrapper mb-3" >
                    <label className='d-block mb-2'><FormattedMessage id="Istheshoppayingfortheassessment" defaultMessage="Is the shop paying for the assessment?" /></label>
                    <Radio.Group name="spAssessment" onChange={handleRadio("spAssessment")} value={values.spAssessment} >
                      <Radio value="yes" className='mr-2'><FormattedMessage id="OptionYes" defaultMessage="yes" /></Radio>
                      <Radio value="no" defaultChecked><FormattedMessage id="OptionNo" defaultMessage="no" /></Radio>
                    </Radio.Group>
                  </div>
         */}{" "}
                  {/*    <div className="d-block radio-group-wrapper mb-3">
                    <label className='d-block mb-2'><FormattedMessage id="Isthebusinesswillingandabletomakechangesinthebusinessbasedonrecommendations" defaultMessage="Is the business willing and able to make changes in the business based on recommendations?" /></label>
                    <Radio.Group name="businessRecommendation" onChange={handleRadio("businessRecommendation")} value={values.businessRecommendation} >
                      <Radio value="yes" className='mr-2'><FormattedMessage id="OptionYes" defaultMessage="yes" /></Radio>
                      <Radio value="no" defaultChecked><FormattedMessage id="OptionNo" defaultMessage="no" /></Radio>
                    </Radio.Group>
                  </div>
           */}{" "}
                  <div className="d-flex justify-content-end align-items-center">
                    <input
                      type="submit"
                      className="btn btn-primary bg-blue mt-3 cursor-pointer"
                      value={intl.formatMessage({
                        id: "Save",
                        defaultMessage: "Save",
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default memo(Step1);

import React from "react";

import { Link, Redirect } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import ProjectTBODY from "../projectTBODY";
import DefineGlobalAssessments from "./setGlobalAssessments";
import DefineGlobalCurrencies from "./setGlobalCurrencies";
import DefineGlobalTagsElements from "./setGlobalTagsElements";
import { useIntl, FormattedMessage } from "react-intl";
import DefineTags from "./defineTags";
import DefineCurrencies from "./defineCurrencies";
import { ToastContainer, toast } from "react-toastify";
import Select, { createFilter } from "react-select";
import { message } from "antd";
import CurrenciesM from "../Currency/Currencies"
import Locale from "../Locales/defaultLocale"
const GlobalTags = (props) => {
  const intl = useIntl();
  const [newTags, setNewTags] = useState([]);
  const [loadedTags, setLoadedTags] = useState([]);
  const [saved, setSaved] = useState(true);
  const [saveable, setsaveable] = useState(true);
  const [Assessments, setAssessments] = useState([]);
  const [AssessmentDrop, setAssessmentDrop] = useState([]);
  const [AssessmentDropValue, setAssessmentDropValue] = useState([]);
  const [CurrenciesDrop, setCurrenciesDrop] = useState([]);
  const [CurrenciesDropValue, setCurrenciesDropValue] = useState([]);
  const [Currencies, setCurrencies] = useState([]);
  const [TagsModal, setTagsModal] = useState(false);
  const [CurrenciesModal, setCurrenciesModal] = useState(false);
  const [standardCurrency, setStandardCurrency] = useState("");
  const [localeList,setLocaleList] = useState([])
  const [standardLocale, setStandardLocale] = useState("");
  const [standardDateFormat,setStandardDateFormat] = useState({value: "", label: ""});
  const allDateFormats = [
    {value: "MM-DD-YYYY",label: "MM-DD-YYYY"},
    {value: "DD-MM-YYYY", label: "DD-MM-YYYY"},
    {label: "YYYY-MM-DD",value: "YYYY-MM-DD"}
  ];
  const [currenciesList, setCurrenciesList] = useState([]);


  useEffect(() => {
    const fetch = async () => {
      console.log(props);
      axios.get(`/api/private/allTags/${props.idd}`, config).then((item) => {
        console.log(item.data.tags);
        setLoadedTags(item.data.tags);
      });

      axios.get(`/api/private/selectedTags`, config).then((item) => {
        console.log(item.data);
        setNewTags(item.data);
      });

      axios.get(`/api/private/getStandardDateFormat`, config).then((item) => {
        setStandardDateFormat({value: item.data, label: item.data})
      })

      axios.get("/api/getAllcurrencies", config).then((response)=> {
        setCurrenciesList(response.data);
        console.log(response.data);
        console.log(currenciesList);
     });

     axios.get('/api/getStandardCurrency',config)
      .then(response => {
        setStandardCurrency(response.data);
      })
      .catch(error => {
        console.error('Error fetching standard currency:', error);
      });

      axios.get("/api/getlocales", config).then((response)=> {
        setLocaleList(response.data);
     })

     axios.get('/api/getStandardLocale',config).then(response => {
      setStandardLocale(response.data)
    })
    };
    fetch();
  }, []);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const converttooptions = (inputArray) => {
    let optionList = [];
    inputArray.map((item) => {
      const { _id, country, locale } = item;
      optionList.push({ label: country + " (" + locale + ")", value: _id });
    });
    return optionList;
  }

  const handleDateFormatChange = (e) => {
    console.log(e)
    setStandardDateFormat(e)
    console.log(e.value)
    let body = {
      dateformat: e.value
    }
    console.log(body)
    axios.post('/api/setStandardDateFormat', body, config).then((data) => {
      console.log("data response dateformat")
      console.log(data.data);
      if(data.data === true){
        message.success(
          intl.formatMessage({
            id: "SavedSuccessfully",
            defaultMessage: "Saved Successfully!",
          })
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      }
    });


  }

  const handleSave = (e) => {
    let Body = {
      Tags: newTags,
    };
    console.log(Body);
    const fetch = async () => {
      try {
        const DBGet = await axios.put(
          `/api/private/selectedTags/`,
          Body,
          config
        );
        console.log(DBGet.data);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }

      //  console.log(DBGet.data)
    };

    fetch().then((item) => {
      setSaved(true);
    });
  };



  const handleChangeDrop = (item, e, type) => {
    console.log(item);
    console.log(e, type);
    if (type == "Assessments") {
      /*       console.log(e) */
      const Body = {
        data: {
          Standard: e,
          Tags: false,
        },
      };

      const fetch = async () => {
        try {
          const DBGet = await axios.put(
            `/api/private/assessments/${item._id}`,
            Body,
            config
          );
          console.log(DBGet.data);
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = "/logout";
          }
        }
      };
      fetch().then(setAssessmentDropValue(e));
    }

    if (type == "Currencies") {
      const Body = {
        data: {
          Standard: e,
          Symbol: false,
        },
      };
      const fetch = async () => {
        try {
          const DBGet = await axios.put(
            `/api/private/currenciesTwo/${item._id}`,
            Body,
            config
          );
          console.log(DBGet.data);
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = "/logout";
          }
        }
      };
      fetch().then(setCurrenciesDropValue(e));

      /*    Assessments == "test" */
    }
  };

  useEffect(
    () => {
      const fetch = async () => {
        try {
          const users = axios.get("/api/private/assessments", config);
          const [usersDB] = await Promise.all([users]);
          console.log(usersDB.data);
          setAssessments(usersDB.data);
          let filtered = usersDB.data.filter(
            (item) => item["Name"] !== "Standard"
          );
          console.log(filtered);
          let Newfiltered = [];
          filtered.map((item) => {
            Newfiltered.push({ label: item["Name"], value: item["_id"] });
          });
          console.log(Newfiltered);
          setAssessmentDrop(Newfiltered);
          /*  setAssessmentDropValue(usersDB.data.) */
          let dropvalue = usersDB.data.find(
            (x) => x.Name === "Standard"
          ).Standard;
          console.log(dropvalue);
          setAssessmentDropValue(dropvalue);
          /* let groupnamearr = []
                usersDB.data.elements.forEach(item => {
                  groupnamearr.push(item.groupName)
                })
                setGroupnames(groupnamearr)
     */
        } catch (e) {
          console.log(e.message);
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = "/logout";
          }
        }
      };
      fetch()
        .then
        /*   setLoading(false) */
        ();
    },
    [
      /* setIsSaving, isSaving */
    ]
  );

  const deleteIt = (e) => {
    console.log(e);
    if (window.confirm("Do you really want to delete it?")) {
      console.log(e);
      const fetch = async () => {
        try {
          const DBGet = await axios.delete(
            `/api/private/assessments/${e}`,
            config
          );
        } catch (e) {
          console.log(e.message);
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = "/logout";
          }
        }
      };
      fetch().then(
        /*  setIsSaving(prevCheck => !prevCheck) */
        setAssessments((oldArray) => oldArray.filter((item) => item._id !== e))
      );
    } else {
      return;
    }
  };

  const addNew = (e) => {
    function containsSpecialChars(str) {
      const specialChars = /[`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(str);
    }

    let groupname = prompt("Please enter Assessment name:");
    console.log(Assessments);

    if (
      containsSpecialChars(groupname) ||
      groupname == "" ||
      groupname == null ||
      groupname.length < 0 ||
      groupname.length > 100 /* ||   groupname.indexOf(Assessments) >= 0 */
    ) {
      console.log(groupname);
      alert("invalid Assessment name");

      return; //break out of the function early
    }
    console.log("true");
    const Body = {
      Name: groupname,
      Tags: [],
    };
    const fetch = async () => {
      try {
        const DBGet = await axios.post(
          `/api/private/assessments`,
          Body,
          config
        );
        console.log(DBGet.data);
        setAssessments((prevState) => [...prevState, DBGet.data.data]);
      } catch (e) {
        console.log(e.message);
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    };
    fetch(); /* .then(
            setAssessments((prevState) => ([
                ...prevState, Body
            ])) */
    /*   setSeeUsers((prevState) => ([
            ...prevState, Body
          ])), */
  };

  const save = (e, setsavedcb) => {
    console.log(e);

    const Body = {
      data: {
        Tags: e.Tags,
        Name: e.Name,
      },
    };

    const fetch = async () => {
      try {
        const DBGet = await axios.put(
          `/api/private/assessments/${e.id}`,
          Body,
          config
        );
        console.log(DBGet.data);
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    };
    console.log(Assessments);
    setTagsModal(false);
    message.success(
      intl.formatMessage({
        id: "SavedSuccessfully",
        defaultMessage: "Saved Successfully!",
      })
    );
    fetch().then(
      setAssessments((prevState) => {
        let index = prevState.findIndex((element) => element.Name == e.Name);
        let newState = [...prevState];
        newState[index] = e;
        return newState;
      }),
      setsavedcb(true)

      /*  SetSaved(true) */
    );
  };

  const saveCurrencies = (e, setsavedcb) => {
    console.log(e);
    const Body = {
      data: {
        Symbol: e.Symbol,
        Position: e.Position,
      },
    };

    const fetch = async () => {
      try {
        const DBGet = await axios.put(
          `/api/private/currenciesTwo/${e.id}`,
          Body,
          config
        );
        console.log(DBGet.data);
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    };

    console.log(Assessments);
    fetch().then(
      setCurrencies((prevState) => {
        let index = prevState.findIndex((element) => element.Name == e.Name);
        let newState = [...prevState];
        newState[index] = e;
        return newState;
      }),
      setsavedcb(true),
      setCurrenciesModal(false)

      /*  SetSaved(true) */
    );
  };

  /*  handleSave */

  if (true) {
    return (
      <>
        <ToastContainer toastClassName="top-zIndex" autoClose={6000} />

        {TagsModal ? (
          <DefineTags
            save={save}
            _id={TagsModal._id}
            Name={TagsModal.Name}
            Tags={TagsModal.Tags}
            SetTagsModal={
              setTagsModal
            } /* SetTagsModal={SetTagsModal}  translation={Versions}  VersioningNumber={VersioningNumber}  idd={idd} Tags={Tags}  setTags={setTags} */
          />
        ) : (
          ""
        )}


        {CurrenciesModal ? (
          <DefineCurrencies
            dev={props.dev}
            saveCurrencies={saveCurrencies}
            setCurrenciesModal={setCurrenciesModal}
            CurrenciesModal={CurrenciesModal}
            SetTagsModal={setTagsModal}
          />
        ) : (
          ""
        )}
        <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100">
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
              <div id="container" className="container-fluid">
                <div className="mb-5 row mb-2">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6">
                    {/*  <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#"><FormattedMessage id="Home" defaultMessage="Home" /></a></li>
                                            <li className="breadcrumb-item active"><FormattedMessage id="TagManagement" defaultMessage="Tag Management" /></li>
                                        </ol>
                                   */}{" "}
                  </div>
                </div>
              </div>
              {/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
              <div className="container-fluid">

                <div
                  style={{
                    paddingRight: "7.5px",
                    paddingLeft: "7.5px",
                    justifyContent: "flex-end",
                  }}
                  className="row mb-2"
                ></div>

                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {/* /.card-header */}
                      <div>
                        {" "}
                        <table
                          style={{ border: 0, marginBottom: "0px" }}
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <th
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                borderLeft: "0px",
                                borderRight: "0px",
                                borderBottom: "1px solid #e9ecef",
                              }}
                            >
                              <div className="col-md-4">
                                <FormattedMessage
                                  id="AllAssessments"
                                  defaultMessage="All Assessments"
                                />
                              </div>
                              <div className="col-md-3">
                                <i
                                  onClick={() => addNew()}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    verticalAlign: "sub",
                                    marginLeft: "6px",
                                  }}
                                  class="fas fa-plus-circle"
                                ></i>
                              </div>
                            </th>
                          </thead>

                          <tbody>

                          {Assessments.map((item, index) => {
                            if (item.Name !== "Standard") {
                              return (
                                <>
                                  <tr role="row">
                                    {/* <td className="TableFlex sorting_1">{arrItem.username} <span>&nbsp;&nbsp;&nbsp;</span> {arrItem.email} */}
                                    <DefineGlobalAssessments
                                      dev={props.dev}
                                      _id={item._id}
                                      Tags={item.Tags}
                                      key={item.Name}
                                      setTagsModal={setTagsModal}
                                      deleteIt={deleteIt}
                                      setSaved={setSaved}
                                      newTags={newTags}
                                      setNewTags={setNewTags}
                                      item={item}
                                      index={index}
                                    />
                                    {/*  </td> */}
                                  </tr>
                                </>
                              );
                            }
                          })}

                          {Assessments.map((item, index) => {
                            if (item.Name == "Standard") {
                              return (
                                <>
                                  <tr
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      borderLeft: "0px",
                                      borderRight: "0px",
                                      borderBottom: "1px solid #e9ecef",
                                    }}
                                    className="TableFlex sorting_1"
                                  >
                                    <div className="col-md-4">
                                      {item["Name"]}
                                    </div>
                                    <div className="col-md-3">
                                      <Select
                                        className={"multiClass"}
                                        value={AssessmentDropValue}
                                        onChange={(e) =>
                                          handleChangeDrop(
                                            item,
                                            e,
                                            "Assessments"
                                          )
                                        }
                                        options={AssessmentDrop}
                                        filterOption={createFilter({
                                          ignoreAccents: false,
                                        })}
                                        classNamePrefix="custom-select"
                                      />
                                    </div>
                                  </tr>
                                </>
                              );
                            }
                          })}
                          </tbody>
                        </table>
                      </div>

                      {/* /.card-body */}
                    </div>
                  </div>
                </div>

                {/* /.col */}
                {/* /.row */}
              </div>

              <div className="container-fluid mt-4">
                <div className="col-12">
                    <div className="row">


                    <div className="card">
                        <div className="card-header">
                                <FormattedMessage
                                  id="StandardDateFormat"
                                  defaultMessage="Standard Date Format"
                                />
                                </div>

                                <div className="card-body row align-items-center">
                                    <div className="col-4">
                                    <FormattedMessage
                                  id="ChooseDateFormat"
                                  defaultMessage="Choose Date Format"
                                />
                                    </div>
                                    <div className="col-8">
                                    <Select
                                        className="basic-single-pix"
                                        value={standardDateFormat}
                                        options={allDateFormats}
                                        classNamePrefix="select"
                                        onChange={(e) => handleDateFormatChange(e)}
                                      />
                                      {console.log("default Date Format")}
                                      {console.log(standardDateFormat)}
                                    </div>
                                </div>
                    </div>
                    </div>
                </div>
              </div>
              <div style={{ marginTop: "50px" }} className="container-fluid">
                {(standardLocale !== "") && (localeList.length > 0) &&
                  <Locale options={converttooptions(localeList)} standardLocale={standardLocale} />
                }
              </div>
              <div style={{ marginTop: "50px" }} className="container-fluid">
                <CurrenciesM setCurrenciesList={setCurrenciesList} currenciesList={currenciesList} standardCurrency={standardCurrency} setStandardCurrency={setStandardCurrency}/>
              </div>

              <div className="container-fluid" style={{ marginTop: "50px" }}>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {/* /.card-header */}
                      <div>
                        <table
                          style={{ border: 0, marginBottom: "0px" }}
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  borderLeft: "0px",
                                  borderRight: "0px",
                                  borderBottom: "1px solid #e9ecef",
                                }}
                              >
                                <div className="col-2">
                                  <FormattedMessage
                                    id="AllTags"
                                    defaultMessage="All Tags"
                                  />
                                </div>
                              </th>
                            </tr>

                            {loadedTags.map((item, index) => {
                              return (
                                <>
                                  <tr role="row" key={index}>
                                    {/* <td className="TableFlex sorting_1">{arrItem.username} <span>&nbsp;&nbsp;&nbsp;</span> {arrItem.email} */}
                                    <DefineGlobalTagsElements
                                      dev={props.dev}
                                      setSaved={setSaved}
                                      newTags={newTags}
                                      setNewTags={setNewTags}
                                      item={item}
                                      index={index}
                                    />
                                    {/*  </td> */}
                                  </tr>
                                </>
                              );
                            })}
                          </thead>
                        </table>
                      </div>

                      {/* /.card-body */}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    paddingRight: "7.5px",
                    paddingLeft: "7.5px",
                    justifyContent: "flex-end",
                  }}
                  className="row mb-2"
                >
                  {saved || !saveable ? (
                    <button
                      style={{ Right: "0", fontSize: "1.3em" }}
                      className="btn btn-secondary no-print m-2"
                    >
                      <i className="m-1 fas fa-save"></i>{" "}
                      <FormattedMessage id="Save" defaultMessage="Save" />
                    </button>
                  ) : (
                    <button
                      style={{ Right: "0", fontSize: "1.3em" }}
                      className="btn btn-primary btn-primary-blue no-print m-2"
                      onClick={() => handleSave()}
                    >
                      <i className="m-1 fas fa-save"></i>{" "}
                      <FormattedMessage id="Save" defaultMessage="Save" />
                    </button>
                  )}
                </div>
                {/* /.col */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <section style={{ minHeight: "100vh" }} className="content-header">
        <div className="SpinnerCon container-fluid">
          <div className="spinner-border" role="status">
            <span className="sr-only">
              <FormattedMessage id="Loading" defaultMessage="Loading..." />
            </span>
          </div>
        </div>
      </section>
    );
  }
};
export default GlobalTags;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { MultiSelect } from "react-multi-select-component";

import axios from "axios";

const DefineGlobalTagsElements = (props) => {
  const [checked, setChecked] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.newTags.includes(parseInt(props.item["id"]))) {
      setChecked((prevState) => !prevState);
    }
    setReady(true);
  }, []);

  const handleInputChange = (e) => {
    props.setSaved(false);
    if (!checked) {
      props.setNewTags((prevState) => {
        return [...prevState, parseInt(props.item["id"])];
      });
    } else {
      props.setNewTags((prevState) => {
        return prevState.filter(function (item) {
          return item !== parseInt(props.item["id"]);
        });
      });
    }
    setChecked((prevState) => !prevState);
  };

  if (ready) {
    return (
      <>
        <td
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            borderLeft: "0px",
            borderRight: "0px",
            borderBottom: "1px solid #e9ecef",
          }}
          className="TableFlex sorting_1"
        >
          <div className="col-2">{props.item["name"]}</div>
          <div className="col-3">
            <label style={{ marginBottom: "0" }} className="switch">
              <input
                checked={checked}
                onChange={(e) => handleInputChange(e)}
                type="checkbox"
              />
              <span className="slider round" />
            </label>
          </div>
        </td>
      </>
    );
  } else {
    return "";
  }
};
export default DefineGlobalTagsElements;

import { useEffect, useState } from "react";
import { Button, Divider, Typography, Tooltip } from "antd";
import { CheckCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import axios from "axios";
const { Link } = Typography;

function Notification({ 
  activity, 
  authToken, 
  onMarkedAsRead,
}) {
  const [isRead, setIsRead] = useState(activity.isRead);

  const activityTime = new Date(activity.createdAt).toLocaleString();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  }

  const handleMarkAsReadClick = async (activityId) => {
    try {
      if (!isRead) {
        const body = {
          activityId
        };
    
        const response = await axios
          .patch(`/api/activities/mark-as-read`, body, config)
          .catch(function (error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
        const markedAsReadActivity = response.data;
    
        setIsRead(true);
        onMarkedAsRead(markedAsReadActivity);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <>
      <div 
        className="notification"
        onClick={() => {
          handleMarkAsReadClick(activity._id);
        }}
        style={{position: "relative", maxWidth: "500px"}}
      >
        <div style={{marginRight: "12px"}}>
          <Divider style={{marginTop: "12px"}} />
          <Link 
            href={activity.url} 
            style={{
              textDecoration: "none", 
              color: "rgb(106, 114, 132)", 
            }}
            disabled={(activity.project.status !== "active" &&
              !activity.project.isActive) ? true : false}
          >
            <FormattedMessage 
              id={`NotificationForActivity${activity._id}`} 
              defaultMessage={`${activity.user.username} ${activity.action} "${activity.project.name}"`} 
            />
          </Link>
        </div>
        <div 
          className="d-flex" 
          style={{
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <span>{activityTime}</span>
          {isRead ?
            <Button
              style={{border: "none", background: "transparent"}}
              className="marked-as-read-button"
            >
              <CheckCircleFilled
                style={{fontSize: "1.2rem"}} 
              />
            </Button>
          :
            <Tooltip title="Mark as read">
              <Button
                style={{border: "none", background: "transparent"}}
                className="mark-as-read-button"
                onClick={(event) => {
                  event.stopPropagation();
                  handleMarkAsReadClick(activity._id)
                }}
              >
                <CheckCircleOutlined
                  style={{fontSize: "1.2rem"}} 
                />
              </Button>
            </Tooltip>
          }
        </div>
      </div>
    </>
  )
};

export default Notification;

import Progressbar from './progressbar'
import Owldemo4 from './reactcarousel4'
import openTaskList from '../sharedFunctions.js/openTaskList';
import { FormattedMessage } from 'react-intl';

const ShowTopCardUnavailable = (props) => {
    return (
    <div className={`col rounded-2 pd-16 border-gray border-radius-16 ${props.color}`}>
        <div

            style={{
                borderRadius: "16px",
                backgroundColor: "#808080b4",
                zIndex: 10,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                position: "absolute"
            }}>
            <div style={{left : "30%"}} class="centered-text-overlay-bay"><FormattedMessage id="NotAvailable" defaultMessage="Not Available" /></div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }} className="card-body">
                <h3 className="card-c-heading">{props.message}</h3>
                <p className="small-text">0/0  <FormattedMessage id="Taskscompleted" defaultMessage="Tasks completed" /></p>
                <p style={{ marginTop: "auto" }} className="stats"> 0 %</p>
                <div style={{ padding: "0", margin: "0" }} ><FormattedMessage id="ShowTasks" defaultMessage="Show Tasks" /></div>
            </div>
        
    </div>
    )

}
export default ShowTopCardUnavailable

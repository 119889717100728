import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';


const DefineTagsElements = (props) => {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
     /*    console.log(props) */
        if (props.Tags.includes(parseInt(props.item["id"]))) {
            setChecked(prevState => !prevState)
        }
    }, [])


    const handleInputChange = (e) => {
        console.log(props)
        props.setSaved(false)


        if (!checked) {

            props.setNewTags(prevState => {
                return [...prevState, parseInt(props.item["id"])]  
            })
        } else {


            props.setNewTags(prevState => {
                return prevState.filter(function (item) {
                        return item !== parseInt(props.item["id"])
                })
            })
        

        }
        setChecked(prevState => !prevState)



    }


    return (

        <><div style={{
            display: "flex",
            flexDirection: "row",
        }} className="mb-1 mt-1"
            key={props.item["id"]}>

            <div className="col-md-12">
                <div className="form-check">
                    <input checked={checked}
                        onChange={(e) => handleInputChange(e)}
                        type="checkbox"
                        className="form-check-input"
                        style={{marginTop: "0"}}
                    />
                    <span style={{ marginLeft: "10px" }}>{props.item["name"]}</span>

                </div>


            </div>
        </div>

        </>

    )

}
export default DefineTagsElements

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SpinnerMini from '../spinnermini';
import Tooltip from './Tooltip';
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import CameraBurstShotUpload, { detectDeviceType } from '../../burstCameraUpload/cameraBurstShotUpload';


const CollapsibleWithout = (props) => {
  console.log("CollapsibleWithout")
  console.log(props)
  const intl = useIntl();
  const [isOpenCamera, setOpenCamera] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [Thumbs, setThumbs] = useState("none");
  const [TextField, setTextField] = useState("");
  const [FilesField, setFilesField] = useState(false);
  const [ThumbsUp, setThumbsUp] = useState(false);
  const [Display, setDisplay] = useState(true);
  const [lockedForEditing, setlockedForEditing] = useState(false);
  const [TooltipState, setTooltipState] = useState(false);
  const [saved, setSaved] = useState(true);
  const [loadingSpinner, setloadingSpinner] = useState(false);

  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (props.allTasks[props.item][1] || parseInt(props.allTasks[props.item][10]) === 100) {
      setThumbs("Up")
      setThumbsUp(true)
      setlockedForEditing(true)
    }
    if (parseInt(props.allTasks[props.item][10]) === 20) {
      setThumbs("Down")
    }
    if(props.allTasks[props.item][10] === null || parseInt(props.allTasks[props.item][10]) === 0){
      console.log('Found null');
    }
  },
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  const hiddenFileInput = React.useRef(null);
  const handleClickHidden = event => {
    hiddenFileInput.current.click();
  };

  const handleOpenCamera = event => {
    setOpenCamera(true);
  };

  const handleChangePicture = event => {
    setFilesField(event.target.files[0])
    setSaved(false)
  };

  const handleTextChange = (e) => {
    setSaved(false)
    setTextField(e.target.value)
  }

  const handleUnSafe = (item) => {
    const currentMilestoneId = props.Milestone && props.Milestone._id;
    const milestoneIndex = props.MilestoneResultState.findIndex(milestone => milestone._id === currentMilestoneId);
    console.log("handleUnsafe WC")
    setloadingSpinner(true)
    let val = true
    const NameOfTask = props.allTasks[props.item][0]
    const PostData = {
      NameOfTask: NameOfTask,
      keyOfTask: props.item,
      activityUrl: location.pathname,
    }
    console.log(props.allTasks[props.item][10])
    if (props.allTasks[props.item][10]) {
      PostData["progress"] = props.allTasks[props.item][10]
    } else {
      PostData["finish"] = props.allTasks[props.item][1]
    }
    PostData["ProjectID"] = props.idd
    const sendToServer = async () => {
      try {
        const data = await axios.put(`/api/private/queue/unfinishtask/${NameOfTask}`, PostData, config);
        return data
      }
      catch (e) {
        // if (!props.dev) {
        //   localStorage.removeItem("authToken");
        //   window.location.href = '/logout';
        // }
        console.log('error:' + e);
      } finally {
      }
    }
    sendToServer().then(item => {
      setSaved(true)
      setloadingSpinner(false)
      setlockedForEditing(true)
      if(props.Milestone){
      props.updateMilestoneCounter(milestoneIndex, -1, 1);
      }
      let newItem = [...props.allTasks[props.item]]
      newItem[1] = false
      if (newItem[10]) {
        newItem[10] = 0
      }
      console.log(newItem)
      props.setAllTasks((prevState) => ({
        ...prevState,
        [props.item]: newItem
      }));
    })
  }

  const handleSave = (status) => {
    const currentMilestoneId =props.Milestone && props.Milestone._id;
    const milestoneIndex = props.MilestoneResultState.findIndex(milestone => milestone._id === currentMilestoneId);
    console.log("handleSave WC")
    if (status === "Up") {
      setThumbs("Up")
    } else if (status === "Down") {
      setThumbs("Down")
    }
    setloadingSpinner(true)
    const handleFinishTask = (e) => {
      const NameOfTask = props.allTasks[props.item][0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.item,
        activityUrl: location.pathname,
      }
      console.log("PostData.taskSection:", PostData.taskSection);
      PostData["ProjectID"] = props.idd
      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/queue/finishtask/${NameOfTask}`, PostData, config);
          console.log("Returned Data")
          console.log(data)
          return data
        } catch (e) {
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
          console.log('error:' + e);
        } finally {
        }
      }
      sendToServer().then(item => {
        console.log(props, milestoneIndex, 'CHECKSAVE')
        if(props.Milestone){
        props.updateMilestoneCounter(milestoneIndex, 1, -1);
        }
        let newItem = [...props.allTasks[props.item]]
        newItem[1] = true
        newItem[10] = 100
        if (TextField && TextField !== "") {
          newItem[7] = TextField
        }
        if (FilesField) {
          newItem[20] = "reload to see new pictures"
        }
        console.log(newItem)
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.item]: newItem
        }));
        setSaved(true)
        setloadingSpinner(false)
      })
    }

    const handleProgressTask = (e) => {
      console.log("handleProgressTask WC")
      const NameOfTask = props.allTasks[props.item][0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.item,
        activityUrl: location.pathname,
      }
      PostData["ProjectID"] = props.idd
      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/queue/progressTask/${NameOfTask}`, PostData, config);
          return data
        } catch (e) {
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
          console.log('error:' + e);
        } finally {

        }
      }
      sendToServer().then(item => {
        let newItem = [...props.allTasks[props.item]]
        newItem[10] = 20
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.item]: newItem
        }));
        setSaved(true)
        setloadingSpinner(false)
      })
    }

    const handleFileSave = (e) => {
      console.log("handleFileSave WC")
      const data = new FormData()
      if (TextField && TextField !== "") {
        data.append('text', TextField)
      }
      if (status === "Up") {
        data.append('progress', "100")
      } else {
        data.append('progress', "20")
      }
      data.append("ProjectID", props.idd)
      data.append("keyOfTask", props.item)
      data.append("activityUrl", location.pathname)
      // PostData["ProjectID"] = props.idd

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      data.append('file', FilesField)
      data.append("searchKey", props.item)
      console.log(data)
      const NameOfTask = props.allTasks[props.item][0]
      axios.post(`/api/private/queue/uploadimg/${NameOfTask}`, data, config)
        .then((response) => {
          setFilesField("")
          setSaved(true)
          setloadingSpinner(false)
          if (status === "Down") {
            let newItem = [...props.allTasks[props.item]]
            newItem[10] = 20
            newItem[20] = URL.createObjectURL(FilesField)
            if (TextField && TextField !== "") {
              newItem[7] = TextField
            }
            console.log(newItem)
            props.setAllTasks((prevState) => ({
              ...prevState,
              [props.item]: newItem
            }));
          } else {
            let newItem = [...props.allTasks[props.item]]
            newItem[1] = true
            newItem[10] = 100
            newItem[20] = URL.createObjectURL(FilesField)
            if (TextField && TextField !== "") {
              newItem[7] = TextField
            }
            props.setAllTasks((prevState) => ({
              ...prevState,
              [props.item]: newItem
            }));
          }
          return response.data
        }, (error) => {
          console.log('error:' + e);
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
        })
    }

    const handleSaveComment = (e) => {
      console.log("handleSaveComment WC")
      const NameOfTask = props.allTasks[props.item][0]
      console.log("Name of Task: " + NameOfTask)
      let progress
      if (status === "Up") {
        progress = 100
      } else {
        progress = 20
      }
      const PostData = {
        NameOfTask: NameOfTask,
        Textbody: TextField,
        Progress: progress,
        keyOfTask: props.item,
        activityUrl: location.pathname,
      }
      console.log("POST DATA")
      console.log(PostData)
      PostData["ProjectID"] = props.idd
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      console.log("Without")
      const req = axios.post(`/api/private/queue/comments/${NameOfTask}`, PostData, config)
        .then((response) => {
          console.log("Comments response")
          console.log(response)
          setTextField("")
          setSaved(true)
          setloadingSpinner(false)
          if (status == "Down" && !FilesField) {
            let newItem = [...props.allTasks[props.item]]
            newItem[10] = 20
            newItem[7] = TextField
            props.setAllTasks((prevState) => ({
              ...prevState,
              [props.item]: newItem
            }));
          } else if ((status === "Up" && !FilesField)) {
            let newItem = [...props.allTasks[props.item]]
            newItem[1] = true
            newItem[10] = 100
            newItem[7] = TextField
            props.setAllTasks((prevState) => ({
              ...prevState,
              [props.item]: newItem
            }));
          }
          return response
        }, (error) => {
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
          console.log('error:' + error);
        })
    }
    if (FilesField && FilesField !== "") {
      handleFileSave()
    } else if (TextField && TextField !== "") {
      handleSaveComment()
    } else {
      if (status == "Up") {
        console.log("test")
        handleFinishTask()
      } else if (status == "Down") {
        handleProgressTask()
      }
    }
  }

  const handleThumbsUp = (item) => {
    setThumbsUp(prev => !prev)
    setSaved(false)
  }

  const Rename = (name) => {
    name = name.split(' ')
    name.shift()
    name = name.join(' ')
    return name
  }

  if (props.Filter === "Uncompleted") {
    return (
      <>
        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent" style={{ paddingLeft: props.margin + "px" }}>
          {/*   <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}
          <div className="tasklist-inner">
            <div 
              className="tasklist-col-1" 
              onClick={props.canEdit ? () => {
                history.push(`${location.pathname}/${props.item}`);
                // props.setSecondScreen(props.item);
              } 
              : false
              } >
              <FormattedMessage id={Rename(props.allTasks[props.item][3])} defaultMessage={Rename(props.allTasks[props.item][3])} />
            </div>
            <div className="tasklist-col-2">
              {/*  <div className="counter-wrapper">
                <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div>
              <div className="counter-wrapper">
                <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div> */}
              {props.allTasks[props.item][11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.allTasks[props.item][11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              loadingSpinner ?
              <div className='loading-tasklist-icon'>
                <SpinnerMini />
              </div>
              : ""
            }
            {props.canEdit &&
              <>
                <div className='d-flex margin-left-auto'>
                  <input className='tasklist-input-1' placeholder="Add your comment here..." value={TextField} onChange={e => handleTextChange(e)} />
                  {!FilesField ?
                    <>
                    </>
                    :
                    <i onClick={() => setFilesField(false)} className="far fa-trash-alt tasklist-delete-1" />
                  }
                  <i onClick={Thumbs === "Down" ? undefined : () => handleSave("Down")} className={Thumbs === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} />
                  <i onClick={() => handleSave("Up")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                </div>
              </>
            }
          </div>
        </div>
        {(isOpenCamera === true && detectDeviceType() === "Desktop") &&
          <CameraBurstShotUpload OpenCameraStatus={() => setOpenCamera(false)} />}
      </>
    )
  }

  else if (props.Filter === "Completed") {
    return (
      <>
        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent tasklistrow-top" style={{ paddingLeft: props.margin + "px" }}>
          {/*  <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}
          <div className="tasklist-inner">
            <div 
              className="tasklist-col-1" 
              onClick={props.canEdit ? () => {
                // props.setSecondScreen(props.item)
                history.push(`${location.pathname}/${props.item}`);
              } 
              : undefined} 
            >
              <FormattedMessage id={Rename(props.allTasks[props.item][3])} defaultMessage={Rename(props.allTasks[props.item][3])} />
            </div>
            <div className='tasklist-col-2'>
              {/*  <div className="counter-wrapper">
                <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div>
              <div className="counter-wrapper">
                <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div> */}
              {props.allTasks[props.item][11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.allTasks[props.item][11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              <>
                {lockedForEditing &&
                  loadingSpinner ?
                  <div className='loading-tasklist-icon'>
                    <SpinnerMini />
                  </div>
                  :
                  <>
                    <div className='ml-auto'>
                      <i onClick={() => handleUnSafe()} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                    </div>
                  </>
                }
              </>
            }
          </div>
        </div>
      </>
    )
  }
  else if (props.Filter === "Audited") {
    return (
      <>
        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent tasklistrow-top" style={{ paddingLeft: props.margin + "px" }}>
          {/* <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}
          <div className='tasklist-inner'>
            <div 
              className='tasklist-col-1' 
              onClick={props.canEdit ? () => {
                // props.setSecondScreen(props.item)
                history.push(`${location.pathname}/${props.item}`);
              } 
              : undefined} >
              <FormattedMessage id={Rename(props.allTasks[props.item][3])} defaultMessage={Rename(props.allTasks[props.item][3])} />
            </div>
            <div className='tasklist-col-2'>
              {/* <div className="counter-wrapper">
              <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div>
            <div className="counter-wrapper">
              <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div> */}
              {props.allTasks[props.item][11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.allTasks[props.item][11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              <>
                {!lockedForEditing ?
                  loadingSpinner ?
                    <div className="loading-tasklist-icon">
                      <SpinnerMini />
                    </div>
                    :
                    <>
                      <div className='ml-auto'>
                        <input className='tasklist-input-1' placeholder="Add your comment here..." value={TextField} onChange={e => handleTextChange(e)} />
                        {!FilesField ?
                          <>
                            <input
                              className="ZeroWidth w-0"
                              key="uploadPic"
                              ref={hiddenFileInput}
                              accept="image/*"
                              multiple
                              capture
                              type="file" name="file" onChange={handleChangePicture} />
                            <i onClick={handleClickHidden} className="far fa-image fa-icon-img" />
                          </>
                          :
                          <i onClick={() => setFilesField(false)} className="far fa-trash-alt fa-img-delete" />
                        }
                        <i onClick={Thumbs === "Down" ? undefined : () => handleSave("Down")} className={Thumbs === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} />
                        <i onClick={() => handleSave("Up")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                      </div>
                    </>
                  :
                  loadingSpinner ?
                    <div className='loading-tasklist-icon'>
                      <SpinnerMini />
                    </div>
                    :
                    <>
                      <div className='ml-auto'>
                        <i onClick={() => handleUnSafe()} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                      </div>
                    </>
                }
              </>
            }
          </div>
        </div>
      </>
    )
  }


  else if (props.Filter === "Not-Audited") {
    if(props.allTasks[props.item][10] === null || parseInt(props.allTasks[props.item][10]) === 0){
    return (
      <>

        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent tasklistrow-top" style={{ paddingLeft: props.margin + "px" }}>
          {/*   <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}

          <div className='tasklist-inner'>
            <div 
              className='tasklist-col-1' 
              onClick={props.canEdit ? () => {
                // props.setSecondScreen(props.item)
                history.push(`${location.pathname}/${props.item}`);
              } 
              : undefined} 
            >
              <FormattedMessage id={Rename(props.allTasks[props.item][3])} defaultMessage={Rename(props.allTasks[props.item][3])} />
            </div>
            <div className='tasklist-col-2'>
              {/*  <div className="counter-wrapper">
              <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div>
            <div className="counter-wrapper">
              <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div> */}
              {props.allTasks[props.item][11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.allTasks[props.item][11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              loadingSpinner ?
              <div className='loading-tasklist-icon'>
                <SpinnerMini />
              </div>
              :
              <>
                <div style={{ marginLeft: "auto" }}>
                  <input className='tasklist-input-1' placeholder="Add your comment here..." value={TextField} onChange={e => handleTextChange(e)} />
                  {!FilesField ?
                    <>
                      <input
                        className="ZeroWidth w-0"
                        key="uploadPic"
                        ref={hiddenFileInput}
                        accept="image/*"
                        multiple
                        capture
                        type="file" name="file" onChange={handleChangePicture} />
                      <i onClick={handleClickHidden} className="far fa-image fa-icon-img" />
                    </>
                    :
                    <i onClick={() => setFilesField(false)} className="far fa-trash-alt fa-img-delete" />
                  }
                  <i onClick={Thumbs === "Down" ? undefined : () => handleSave("Down")} className={Thumbs === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} />
                  <i onClick={() => handleSave("Up")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                </div>
              </>
            }
          </div>
        </div>
      </>
    )
          }else{
            return (
              <></>
            )
          }
  }
  else {
    return (
      <></>
    )
  }
}

export default CollapsibleWithout
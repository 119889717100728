import { useEffect, useState, useRef } from 'react';
import { 
  withRouter, 
  Route, 
  useHistory, 
  matchPath, 
  useLocation 
} from 'react-router-dom';

import MainFunction from '../sharedFunctions.js/MainFunction';
import round from "../sharedFunctions.js/round";
import ShowSingleProjectSlides from './showSingleProjectSlides';
import CompressedAir from './ShowSingleCompressedAir';
import PaintShop from './ShowSinglePaintShop';

import KPIDashboardPage from './KPIDashboard/v1/KPIDashboard';
import KPIDashboardV2 from './KPIDashboard/v2/KPIDashboard'
import ShowUserName from './ShowUserName';
import LeftPanel from './LeftPanel';
import BodyShop from './ShowSingleBodyShop';
import TranslationWeightPopup from './Translation/TranslationWeightPopup';
import Milestone from './Milestones';
import Note from './Note';

import MilestoneProgress from '../sharedFunctions.js/getMilestoneProgress';
import WeightModalElement from './Weight/WeightModal'
import DefineStatus from './defineStatus'
import Currency from './Currency/Currency.js'
import DateFormatModal from './Settings/projectDateFormatModal'
import KpiFormatModal from './Settings/projectKpiFormatModal'
import ProjectTasklist from './Tasklist/ProjectTasklists';
import { 
  sectionNames, 
  pathPatterns, 
  baySections, 
  noTaskPathSegments} from "./Tasklist/tasks-routing-constants";
import ShowModalPictures from './showModalPictures';
import Spinner from './spinner';
import axios from 'axios';
import openTaskList from '../sharedFunctions.js/openTaskList';
const clm = require('country-locale-map');

const ShowSingleProject = (props) => {
  console.log("ShowSingleProject Props")
  console.log(props)
  const idd = props.match.params.projectId
  const [TaskLists, setTaskLists] = useState(false)
  const [notOnLoad, setNotOnLoad] = useState(false)
  const [allTasks, setAllTasks] = useState(false)
  const [Loading, setLoading] = useState(true)
  const [TaskListComp, setTaskListComp] = useState(false)
  const [MilestonesComp, setMilestonesComp] = useState(false)
  const [NotesComp, setNotesComp] = useState(false)
  const [TranslationModal, setTranslationModal] = useState(false)
  const [ScrollPos, setScrollPos] = useState(false)
  const [ModalImage, setModalImage] = useState(false);
  const [CurrentImg, setCurrentImg] = useState([]);
  const [translation, setTranslation] = useState("");
  const [canEdit, setCanEdit] = useState(false);
  const [Versioning, setVersioning] = useState(false);
  const [VersioningNumber, setVersioningNumber] = useState(false);
  const [Milestones, setMilestones] = useState(false);
  const [Notes, setNotes] = useState(false);
  const [notesRights, setNotesRights] = useState(false);
  const [passResult, setPassResult] = useState(0);
  const [TranslationAddon, SetTranslationAddon] = useState("");
  const [TranslationOnlyAllow, SetTranslationOnlyAllow] = useState(["Comment", "calculation", "BayFile", "File", "BayPercentage", "BayComment", "Bay", "Percentage"]);
  const [Username, SetUsername] = useState("");
  const [FinalArrayState, SetFinalArrayState] = useState(false);
  const [MilestoneResultState, setMilestoneResultState] = useState(false);
  const [History, setHistory] = useState(false);
  const [Tags, setTags] = useState([]);
  const [TagsModal, setTagsModal] = useState(false);
  const [WeightModal, setWeightModal] = useState(false);
  const [Weight, setWeight] = useState({});
  const [StatusModal, setStatusModal] = useState(false);
  const [Status, setStatus] = useState({});
  const [TasklistNumber, setTasklistNumber] = useState(false);
  const [onlyMilestone, setOnlyMilestone] = useState("false");
  const [milestoneTranslation, setMilestoneTranslation] = useState([]);
  const [milestoneTasks, setMilestoneTasks] = useState([]);
  const [editMilestone, setEditMilestone] = useState("false");
  const [editNote, setEditNote] = useState(false);
  const [viewNote, setViewNote] = useState(false);
  const [currency, setCurrency] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [CurrencyModal, setCurrencyModal] = useState(false);
  const [dateFormatModal, setDateFormatModal] = useState(false)
  const [kpiFormatModal, setKpiFormatModal] = useState(false)
  const [noTeamworkState, setNoTeamwork] = useState(false);
  const [currencyPosition, setCurrencyPosition] = useState("")
  const [projectAddressInfo, setProjectAddressInfo] = useState([]);
  const [currencyLocale, setCurrencyLocale] = useState("")
  const [projectDateFormat, setProjectDateFormat] = useState([])
  const [projectDateSetting, setProjectDateSetting] = useState("")
  const [projectKpiSetting, setProjectKpiSetting] = useState("")
  const firstUpdate = useRef(true);
  const [userLocale,setUserLocale] = useState("");

  let history = useHistory();
  const location = useLocation();

  let answer = []
  let TasklistDatabase = {}
  let lastSaved   //20210702123559
  let lastSavedOne  //20210629123559
  let RenaimingList = []

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const updateMilestoneCounter = (index, completedChange, uncompletedChange) => {
    setMilestoneResultState(prevState => prevState.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          complete: item.complete + completedChange,
          uncompleted: item.uncompleted + uncompletedChange,
        };
      }
      return item;
    }));
  };
  
  useEffect(() => {
    let FinalArray = {}
    Object.entries(translation).forEach(([key, val]) => {
      MainFunction(val, allTasks, key, FinalArray, Tags, Weight, translation)
    });
    setMilestoneTasks(FinalArray?.TotalAuditScore?.Tasks)
  }, []);

  useEffect(() => {
    if (!firstUpdate.current) {
      console.log("MileStoneResult updated")
      let MileStoneResult = []
      MilestoneProgress(Milestones, allTasks, MileStoneResult, Tags, Weight)
      setMilestoneResultState(MileStoneResult[0])
    }
  }, [TagsModal, WeightModal, allTasks, Milestones]);

  useEffect(() => {
    // console.log("main")
    if (!firstUpdate.current) {
      console.log("FinalArray Called")
      let FinalArray = {}
      Object.entries(translation).forEach(([key, val]) => {
        MainFunction(val, allTasks, key, FinalArray, Tags, Weight, translation)
      });
      SetFinalArrayState(FinalArray)
    }
  }, [TagsModal, WeightModal, //TaskListComp, 
    allTasks]);

  // [0 = finished, 1 = predecessors length, 2= name of task, 3= array of files ids 4= array of thumbnails 5 array of children(predecessors) 6 = comment]
  useEffect(() => {
    let answer = []
    console.log("userid: " + idd)

    const projectAddress = axios.get(`/api/getprojectAddress/${idd}`, config)
      .then((result) => {
        console.log("projectAddress");
        console.log(result)
        setProjectAddressInfo(result.data[0]);
        let locale = clm.getLocaleByName(result.data[0].address.Country);
        if(locale === undefined){
          locale = "en-EN"
        }

        setCurrencyLocale(locale.replace('_', '-'));
      })
      .catch(console.error);

    const req = axios.get(`/api/private/project-overview/${idd}`, config)
      .then((response) => {
        console.log("PROJECT OW")
        console.log(response.data)

        SetUsername(response.data.Username)
        if (response.data.Milestones) {
          setMilestones(response.data.Milestones)
        }

        if (response.data.Notes && response.data.NotesRights) {
          console.log(response.data.Notes)
          setNotes(response.data.Notes)
        } else {
          setNotes([])
        }

        if (response.data.noTeamwork) {
          setNoTeamwork(true)
        }

        TasklistDatabase = response.data
        if (response.data.summary) {
          setTaskLists(response.data.summary)
        }

        if (response.data.History) {
          setHistory(response.data.History[0])
        }

        if (response.data.currency) {
          setCurrency(response.data.currency)
        }

        if (response.data.currencyName) {
          setCurrencyName(response.data.currencyName)
        }

        if(currencyName === ""){

        }

        if (response.data.currencyPosition) {
          setCurrencyPosition(response.data.currencyPosition)
        }

        if (response.data.dateSetting) {
          setProjectDateSetting(response.data.dateSetting)
        }
        console.log("response.data.kpiSetting")
        console.log(response.data.kpiSetting)
        if (response.data.kpiSetting) {
          setProjectKpiSetting(response.data.kpiSetting)
        }

        setAllTasks(response.data.tasklist)
        //   console.log(response.data.version)
        console.log("Translation:")
        console.log(response.data.version)
        setTranslation(response.data.version)
        if (response.data.rights) {
          setCanEdit(true)
        }

        if (response.data.NotesRights) {
          setNotesRights(true)
        }

        if(response.data.userlocale){
          setUserLocale(response.data.userlocale)
        }

        if (response.data.Versioning) {
          setVersioning(true)
        }
        setVersioningNumber(response.data.VersionNumber)

        let FinalArray = {}
        setStatus(response.data.Status)

        console.log("Assessment Type: ")
        console.log(Status)
        let TagsOfSt
        if (response.data.TagsOfStatus) { 
          TagsOfSt = response.data.TagsOfStatus 
        } else {
          TagsOfSt = []
        }
        setTags(TagsOfSt)

        Object.entries(response.data.version).forEach(([key, val]) => {
          MainFunction(val, response.data.tasklist, key, FinalArray, TagsOfSt, response.data["Weight"], response.data.version)
        });

        SetFinalArrayState(FinalArray)

        if (response.data["Weight"]) {
          setWeight(response.data["Weight"])
        }
        setTasklistNumber(response.data.TasklistNumber)
        let MileStoneResult = []

        MilestoneProgress(response.data.Milestones, response.data.tasklist, MileStoneResult, TagsOfSt, response.data["Weight"])

        setMilestoneResultState(MileStoneResult[0])
        setNotOnLoad(true)
        setLoading(false)

        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }
      }, (error) => {
        console.log(error)
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      });
  }, []);
  console.log("FinalArrayState:", FinalArrayState);
  console.log("MilestoneResultState:", MilestoneResultState);
  console.log("Milestones:", Milestones);

  // Mapping variables to routes
  useEffect(() => {
    if (MilestonesComp) {
      if (editMilestone && MilestonesComp["_id"]) {
        history.push(`${props.match.url}/milestones/${MilestonesComp["_id"]}`);
      } else {
        history.push(`${props.match.url}/milestones`);
      }
    } 
    
    if (NotesComp) {
      if ((editNote || viewNote) && NotesComp["_id"]) {
        history.push(`${props.match.url}/notes/${NotesComp["_id"]}`);
      } else {
        history.push(`${props.match.url}/notes`);
      }
    }
  }, [
    MilestonesComp, 
    NotesComp, 
    props.match.url,
    editMilestone,
    editNote,
    viewNote,
    passResult,
  ]);

  // Resetting location state on page reload
  useEffect(() => {
    if (history.location.state?.from === "collapsible") {
      history.replace({
        ...history.location,
        state: undefined,
      });
    }
  }, []);
  
  // Checking pattern matches to render components by url recognition
  useEffect(() => {
    const pathMatch = matchPathPattern();
    const { isExact, path } = pathMatch;
    const section = pathMatch?.params?.section;
    const page = pathMatch?.params?.page;
    console.log("pathMatch:", pathMatch);
    console.log("history.location:", history.location);
    console.log("section:", section);
    console.log("history.location.state.from:", history.location.state?.from)

    if (isExact && path === "/project/:projectId/milestones/:milestoneId" && 
      MilestoneResultState) {
      const { milestoneId } = pathMatch.params;
      const milestone = MilestoneResultState.find((milestone) => milestone["_id"] === milestoneId);
      
      if (milestone) {
        setMilestonesComp(milestone);
        setEditMilestone("true");
      }
    }

    if (isExact && path === "/project/:projectId/notes/:noteId" && Notes) {
      const { noteId } = pathMatch.params;
      const note = Notes.find((note) => note["_id"] === noteId);
      
      if (note) {
        setNotesComp(note);
        !editNote && setViewNote(true);
      }
    }

    if (isExact && 
      (path === "/project/:projectId/:section" || path === "/project/:projectId/:page/:section") &&
      !noTaskPathSegments.includes(section) && 
      (page ? noTaskPathSegments.includes(page) : true)) { 
        history.replace(`${location.pathname.split('/').slice(0,-1).join('/')}`);
    }

    if (isExact && !noTaskPathSegments.includes(section) && FinalArrayState &&
      (section === "MilestoneTasklist" ? MilestoneResultState : true) &&
      (!TaskListComp || !passResult) && (history.location.state?.from !== "collapsible") &&
      path !== "/project/:projectId/milestones/:milestoneId" &&
      path !== "/project/:projectId/notes/:noteId") {
      const sectionKey = pathMatch?.params?.sectionKey;
      const subSection = pathMatch?.params?.subSection;
      const subSectionKey = pathMatch?.params?.subSectionKey;
      const subSectionIndex = subSectionKey - 1;
      const sectionIndex = sectionKey - 1;
      const baySection = baySections[section];
      console.log("sectionKey:", sectionKey);
      console.log("subSection:", subSection);
      console.log("baySection:", baySection);
      const tasklist = baySection ? FinalArrayState[baySection][section][sectionIndex]["Tasks"] :
        section === "ToolBoxPaint" ? FinalArrayState[section][sectionIndex]["Tasks"] :
        section === "MilestoneTasklist" ? MilestoneResultState[sectionIndex]["Tasks"] :
        subSection === "Repairs" ? 
          FinalArrayState["GroupedbyDate"][sectionKey]["Repairs"][subSectionIndex]["Tasks"] :
        FinalArrayState[section]["Tasks"];
      console.log("tasklist:", tasklist);
      const result = baySection ? round(
        (FinalArrayState[baySection][section][sectionIndex]["WeightComplete"] / 
        FinalArrayState[baySection][section][sectionIndex]["TotalWeight"]) * 100) :
        section === "ToolBoxPaint" ? round(
          (FinalArrayState[section][sectionKey]["WeightComplete"] / 
          FinalArrayState[section][sectionKey]["TotalWeight"]) * 100) :
        section === "MilestoneTasklist" ? MilestoneResultState[sectionIndex]["Result"] :
        subSection === "Repairs" ? 
          FinalArrayState["GroupedbyDate"][sectionKey]["Repairs"][subSectionIndex]["Result"] : 
        FinalArrayState[`${section}Result`];
      console.log("result:", result);
      const finalArrayStateSection = baySection ? FinalArrayState[baySection][section][sectionIndex] :
        section === "ToolBoxPaint" ? FinalArrayState[section][sectionIndex] :
        section === "MilestoneTasklist" ? MilestoneResultState[sectionIndex] :
        subSection === "Repairs" ? 
          FinalArrayState["GroupedbyDate"][sectionKey]["Repairs"][subSectionIndex] :
        FinalArrayState[section];
      const sectionUIName = section === "MilestoneTasklist" ? MilestoneResultState[sectionIndex]["Title"] :
        subSection === "Repairs" ? sectionNames[subSection] :
        sectionNames[section];
      const milestoneReference = (section === "MilestoneTasklist") ? "Milestone" :
        baySection ? "newBay" :
        "";
      
      openTaskList(
        tasklist, 
        setScrollPos, 
        setTaskListComp, 
        setPassResult,
        result,
        finalArrayStateSection,
        sectionUIName,
        section,
        milestoneReference,
      )
    }
  }, [
    location.pathname,
    history, 
    MilestoneResultState, 
    Notes, 
    FinalArrayState, 
    allTasks, 
    editNote
  ]);

  const matchPathPattern = () => {
    let matchedPath = {};
    pathPatterns.forEach((pattern) => {
      const match = matchPath(location.pathname, { path: pattern, exact: true });
      if (match?.isExact) matchedPath = match;
    });
    return matchedPath;
  }

  function closeMenu(e) {
    if (e.target && e.target.className === "imgmodal") {
      return
    }
    //  console.log(e.target)
    setModalImage("")
    document.removeEventListener("click", closeMenu);
    // , () => {document.removeEventListener('click', closeMenu);}; //
  };

  function clickX(e) {
    setModalImage("")
    document.removeEventListener("click", closeMenu);
  }

  useEffect(() => {
    if (props.openPage === "") props.SetOpenPage(props.page);
    // if (props.openPage === "Overview") history.push(`/project/${idd}`);
    // if (props.openPage === "BodyShop") history.push(`${props.match.url}/BodyShop`);
    // if (props.openPage === "PaintShop") history.push(`${props.match.url}/PaintShop`);
    // if (props.openPage === "CompressedAir") history.push(`${props.match.url}/CompressedAir`);
    // if (props.openPage === "KPI") history.push(`${props.match.url}/KPI`);
    // else if (props.openPage === "Overview") {
    //   console.log("useEffect replaceState...")
    //   window.history.replaceState(null, null, `/project/${idd}/${props.openPage}`)
    // }
    //props.history.replace({ pathname: `/project/${idd}/${openPage}`})
  }, [props.openPage]);

  if (!Loading && FinalArrayState) {
    {/* Main content */ }
    return (
      <>
        {/* <h1 style={{
              color: "red",
              marginTop: "10rem",
              zIndex: "1000",
              position: "relative"
            }}>{openPage}</h1> */}
        <LeftPanel idd={idd} openPage={props.openPage} SetOpenPage={props.SetOpenPage} />
        <ShowUserName Username={Username} />
        {ModalImage && <ShowModalPictures noTeamwork={noTeamworkState} dev={props.dev} setCurrentImg={setCurrentImg} CurrentImg={CurrentImg} Pictures={ModalImage} setModalImage={setModalImage} Result={passResult} canEdit={canEdit} allTasks={allTasks} passTaskListComp={setTaskListComp} TaskListComp={TaskListComp} />}
        
        <Route path={[
          `${props.match.path}/:section/tasks`,  
          `${props.match.path}/:page/:section/tasks`,
          `${props.match.path}/:page/:section/:sectionKey/tasks`,
          `${props.match.path}/:section/:sectionKey/:subSection/:subSectionKey/tasks`,
        ]}
        >
          {Boolean(passResult) && ( 
            <ProjectTasklist noTeamwork={noTeamworkState} dev={props.dev} idd={idd} FinalTags={Tags} setModalImage={setModalImage} setCurrentImg={setCurrentImg} Result={passResult} canEdit={canEdit} updateMilestoneCounter={updateMilestoneCounter} MilestoneResultState={MilestoneResultState} setAllTasks={setAllTasks} allTasks={allTasks} ScrollPos={ScrollPos} passTaskListComp={setTaskListComp} TaskListComp={TaskListComp} />
          )}
        </Route>
        
        <Route path={[
          `${props.match.path}/milestones/:milestoneId`,
          `${props.match.path}/milestones`
        ]}
        >
          <Milestone userLocale={userLocale.replace("_","-")} noTeamwork={noTeamworkState} dev={props.dev} setEditMilestone={setEditMilestone} editMilestone={editMilestone} setMilestones={setMilestones} Milestones={Milestones} MilestoneResultState={MilestoneResultState} setMilestoneResultState={setMilestoneResultState} setMilestoneTranslation={setMilestoneTranslation} milestoneTranslation={milestoneTranslation} idd={idd} onlyMilestone={onlyMilestone} setOnlyMilestone={setOnlyMilestone} SetTranslationOnlyAllow={SetTranslationOnlyAllow} TranslationOnlyAllow={TranslationOnlyAllow} TranslationAddon={TranslationAddon} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} milestoneTasks={FinalArrayState["TotalAuditScore"]} MilestonesComp={MilestonesComp} setMilestonesComp={setMilestonesComp} canEdit={canEdit} Translation={translation} setTranslation={setTranslation} setModalImage={setModalImage} setCurrentImg={setCurrentImg} Result={passResult} /* canEdit={canEdit} */ setAllTasks={setAllTasks} allTasks={allTasks} ScrollPos={ScrollPos} passTaskListComp={setTaskListComp} TaskListComp={TaskListComp} />
        </Route>
        
        <Route path={[
          `${props.match.path}/notes/:noteId`,
          `${props.match.path}/notes`
        ]}
        >
          <Note companyName={FinalArrayState["CompanyNameResult"]} setEditNote={setEditNote} editNote={editNote} setViewNote={setViewNote} viewNote={viewNote} username={Username} edited={new Date()} dev={props.dev} setNotes={setNotes} Notes={Notes} idd={idd} NotesComp={NotesComp} setNotesComp={setNotesComp} canEdit={canEdit} ScrollPos={ScrollPos} />
        </Route>

        {!TranslationModal ? "" :
          TranslationModal && (Versioning || canEdit) ?
            <TranslationWeightPopup dev={props.dev} FinalArray={FinalArrayState} FinalTags={Tags} setMilestoneTranslation={setMilestoneTranslation} milestoneTranslation={milestoneTranslation} onlyMilestone={onlyMilestone} /* setMilestoneTranslation={setMilestoneTranslation} */ setOnlyMilestone={setOnlyMilestone} TranslationOnlyAllow={TranslationOnlyAllow} TranslationAddon={TranslationAddon} VersioningNumber={VersioningNumber} allTasks={allTasks} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} canEdit={canEdit} Translation={translation} setTranslation={setTranslation} />
            : ""}

        {!WeightModal ? "" :
          WeightModal && Versioning ?
            <WeightModalElement dev={props.dev} FinalArray={FinalArrayState} idd={idd} Weight={Weight} setWeight={setWeight} translation={translation} VersioningNumber={VersioningNumber} setWeightModal={setWeightModal} /* VersioningNumber={VersioningNumber} */ allTasks={allTasks} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} canEdit={canEdit} Translation={translation} setTranslation={setTranslation} />
            : ""}

        {!StatusModal ? "" :
          StatusModal && Versioning ?
            <DefineStatus dev={props.dev} idd={idd} Status={Status} setStatusModal={setStatusModal} setStatus={setStatus} translation={translation} VersioningNumber={VersioningNumber} setWeightModal={setWeightModal} /* VersioningNumber={VersioningNumber} */ allTasks={allTasks} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} canEdit={canEdit} Translation={translation} setTranslation={setTranslation} />
            : ""}

        {!CurrencyModal ? "" :
          CurrencyModal && Versioning ?
            <Currency currencyName={currencyName} dev={props.dev} idd={idd} currency={currency} setCurrency={setCurrency} setCurrencyName={setCurrencyName} currencyModal={CurrencyModal} setCurrencyModal={setCurrencyModal} translation={translation} VersioningNumber={VersioningNumber} allTasks={allTasks} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} canEdit={canEdit} Translation={translation} setTranslation={setTranslation} />
            : ""}

        {!dateFormatModal ? "" :
          dateFormatModal && Versioning ?
            <DateFormatModal projectDateFormat={projectDateSetting} show={dateFormatModal} dev={props.dev} idd={idd} setDateFormatModal={setDateFormatModal} canEdit={canEdit} />
            : ""}

        {!kpiFormatModal ? "" :
          kpiFormatModal && Versioning ?
            <KpiFormatModal projectKpiFormat={projectKpiSetting} show={kpiFormatModal} dev={props.dev} idd={idd} setKpiFormatModal={setKpiFormatModal} canEdit={canEdit} />
            : ""}

        <Route exact path={`${props.match.path}`}>
          <ShowSingleProjectSlides userLocale={userLocale.replace("_","-")} projectAddress={projectAddressInfo} currencyName={currencyName} currencyPosition={currencyPosition} noTeamwork={noTeamworkState} currencyLocale={currencyLocale}  dev={props.dev} setCurrencyModal={setCurrencyModal} setDateFormatModal={setDateFormatModal} setKpiFormatModal={setKpiFormatModal} setCurrency={setCurrency} currency={currency} canEdit={canEdit} SetTranslationAddon={SetTranslationAddon} setTasks={setAllTasks} TasklistNumber={TasklistNumber} setStatusModal={setStatusModal} setWeightModal={setWeightModal} WeightModal={WeightModal} setTagsModal={setTagsModal} TagsModal={TagsModal} setTags={setTags} Tags={Tags} History={History} MilestoneResultState={MilestoneResultState} setMilestoneResultState={setMilestoneResultState} setOnlyMilestone={setOnlyMilestone} setEditMilestone={setEditMilestone} editMilestone={editMilestone} setEditNote={setEditNote} editNote={editNote} setViewNote={setViewNote} viewNote={viewNote}
          setMilestoneTranslation={setMilestoneTranslation} setNotesComp={setNotesComp} idd={idd}
          notesRights={notesRights} Notes={Notes} setNotes={setNotes} setMilestonesComp={setMilestonesComp}
          FinalArray={FinalArrayState} SetTranslationOnlyAllow={SetTranslationOnlyAllow} Username={Username} SetOpenPage={props.SetOpenPage} setCurrentImg={setCurrentImg} setModalImage={setModalImage} passResult={setPassResult} setMilestones={setMilestones} Milestones={Milestones} VersioningNumber={VersioningNumber} Versioning={Versioning} translation={translation}   //Street={Comments["1.1.3"]} PLZ={Comments["1.1.5"]} City={Comments["1.1.4"]}          img={allTasks["1.1.2"][4]} approved={allTasks["1.1.6"][4]}
          tasks={allTasks} setScrollPos={setScrollPos} passTaskListComp={setTaskListComp} TaskLists={TaskLists} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} />
        </Route>

        <Route exact path={`${props.match.path}/PaintShop`}>
          <PaintShop canEdit={canEdit} noTeamwork={noTeamworkState} dev={props.dev} Tags={Tags} idd={idd} setTasks={setAllTasks} TasklistNumber={TasklistNumber} setOnlyMilestone={setOnlyMilestone} setMilestoneTranslation={setMilestoneTranslation} FinalArray={FinalArrayState} SetTranslationOnlyAllow={SetTranslationOnlyAllow} Username={Username} SetTranslationAddon={SetTranslationAddon} SetOpenPage={props.SetOpenPage} setCurrentImg={setCurrentImg} setModalImage={setModalImage} passResult={setPassResult} Milestones={Milestones} VersioningNumber={VersioningNumber} Versioning={Versioning} translation={translation}   //Street={Comments["1.1.3"]} PLZ={Comments["1.1.5"]} City={Comments["1.1.4"]}          img={allTasks["1.1.2"][4]} approved={allTasks["1.1.6"][4]}
          tasks={allTasks} setScrollPos={setScrollPos} passTaskListComp={setTaskListComp} TaskLists={TaskLists} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} />
        </Route>

        <Route exact path={`${props.match.path}/BodyShop`}>
          <BodyShop canEdit={canEdit} noTeamwork={noTeamworkState} dev={props.dev} Tags={Tags} TasklistNumber={TasklistNumber} idd={idd} setOnlyMilestone={setOnlyMilestone} setMilestoneTranslation={setMilestoneTranslation} FinalArray={FinalArrayState} SetTranslationOnlyAllow={SetTranslationOnlyAllow} Username={Username} SetTranslationAddon={SetTranslationAddon} SetOpenPage={props.SetOpenPage} setCurrentImg={setCurrentImg} setModalImage={setModalImage} passResult={setPassResult} Milestones={Milestones} VersioningNumber={VersioningNumber} Versioning={Versioning} translation={translation}   //Street={Comments["1.1.3"]} PLZ={Comments["1.1.5"]} City={Comments["1.1.4"]}          img={allTasks["1.1.2"][4]} approved={allTasks["1.1.6"][4]}
          tasks={allTasks} setTasks={setAllTasks} setScrollPos={setScrollPos} passTaskListComp={setTaskListComp} TaskLists={TaskLists} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} />
        </Route>

        <Route path={`${props.match.path}/CompressedAir`}>
          <CompressedAir canEdit={canEdit} noTeamwork={noTeamworkState} dev={props.dev} setOnlyMilestone={setOnlyMilestone} setMilestoneTranslation={setMilestoneTranslation} FinalArray={FinalArrayState} SetTranslationOnlyAllow={SetTranslationOnlyAllow} Username={Username} SetTranslationAddon={SetTranslationAddon} SetOpenPage={props.SetOpenPage} setCurrentImg={setCurrentImg} setModalImage={setModalImage} passResult={setPassResult} Milestones={Milestones} VersioningNumber={VersioningNumber} Versioning={Versioning} translation={translation}   //Street={Comments["1.1.3"]} PLZ={Comments["1.1.5"]} City={Comments["1.1.4"]}          img={allTasks["1.1.2"][4]} approved={allTasks["1.1.6"][4]}
          tasks={allTasks} setScrollPos={setScrollPos} passTaskListComp={setTaskListComp} TaskLists={TaskLists} setTranslationModal={setTranslationModal} TranslationModal={TranslationModal} />
        </Route>

        <Route path={`${props.match.path}/KPI/`}>
          {projectKpiSetting === "v1" ?
          <KPIDashboardPage  userLocale={userLocale.replace("_","-")} currencyName={currencyName} currencyLocale={currencyLocale}/>
          :
          <KPIDashboardV2  userLocale={userLocale.replace("_","-")} currencyName={currencyName} currencyLocale={currencyLocale} projectID={idd}/>
          }
          </Route>
      </>
    )
  } else {
    return (
      <Spinner />
    )
  }
}

export default withRouter(ShowSingleProject)

import axios from 'axios';
import { useState } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import { Select } from 'antd';
import { toast } from "react-toastify";

const DefaultCurrency = (props) => {
   const [loading, setLoading] = useState(false)
   const intl = useIntl();

   const config = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
   };

   const handleChange = (value) => {
      let body = {
         currency: value
      }
      setLoading(true);
      axios.post(`/api/setStandardCurrency`, body, config).then((result) => {
         if (result.data === "success") {
            toast.success(intl.formatMessage({ id: "ProjectCurrencyUpdated", defaultMessage: "Project Currency Updated!" }), {
               position: toast.POSITION.BOTTOM_CENTER
            })
            window.location.reload(false);
         }
      })
      setLoading(false);
   };

   const onSearch = (value) => {
      console.log('search:', value);
   };

   return (
      <>
         <div className="container-fluid mt-4 mb-5">
            <div className="col-inner">
               <div className="row">
                  <div className="card">
                     <div className="card-header">
                        <FormattedMessage
                           id="DefaultCurrency"
                           defaultMessage="Default Currency"
                        />
                     </div>
                     <div className="card-body row align-items-center">
                        <div className="col-4">
                           <FormattedMessage
                              id="ChooseCurrency"
                              defaultMessage="Choose Currency"
                           />
                        </div>
                        <div className="col-8">
                           <Select
                              showSearch
                              defaultValue={props.standardCurrency}
                              placeholder={intl.formatMessage({ id: "SelectCurrency", defaultMessage: "Select Currency" })}
                              onChange={handleChange}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              options={props.options}
                              loading={loading}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                 (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                           />

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default DefaultCurrency;





import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SpinnerMini from '../spinnermini';
import Tooltip from './Tooltip';
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import CameraBurstShotUpload, { detectDeviceType } from '../../burstCameraUpload/cameraBurstShotUpload';


const CollapsibleWithoutCustom = (props) => {
  const intl = useIntl();
  const [isOpenCamera, setOpenCamera] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [Thumbs, setThumbs] = useState("none");
  const [TextField, setTextField] = useState("");
  const [FilesField, setFilesField] = useState(false);
  const [ThumbsUp, setThumbsUp] = useState(false);
  const [Display, setDisplay] = useState(true);
  const [lockedForEditing, setlockedForEditing] = useState(false);
  const [TooltipState, setTooltipState] = useState(false);
  const [saved, setSaved] = useState(true);
  const [loadingSpinner, setloadingSpinner] = useState(false);

  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (props.item[1] || parseInt(props.item[10]) === 100) {
      setThumbs("Up")
      setThumbsUp(true)
      setlockedForEditing(true)
    }
    if (parseInt(props.item[10]) === 20) {
      setThumbs("Down")
    }
    if(props.item[10] === null || parseInt(props.item[10]) === 0){
      console.log('Found null');
    }
  },
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  const hiddenFileInput = React.useRef(null);
  const handleClickHidden = event => {
    hiddenFileInput.current.click();
  };

  const handleOpenCamera = event => {
    setOpenCamera(true);
  };

  const handleChangePicture = event => {
    setFilesField(event.target.files[0])
    setSaved(false)
  };

  const handleTextChange = (e) => {
    setSaved(false)
    setTextField(e.target.value)
  }

  const handleUnSafe = (status) => {
    const currentMilestoneId = props.Milestone._id;
    const milestoneIndex = props.MilestoneResultState.findIndex(milestone => milestone._id === currentMilestoneId);

    setloadingSpinner(true)
    let val = true
    const NameOfTask = props.item[0]
    const Milestone = JSON.parse(JSON.stringify(props.Milestone));

    if (status === "Up") {
      setThumbs("Up")
    } else if (status === "Down") {
      setThumbs("Down")
    }
    const arr = [];
    Object.entries(props.allTasks).map((entry) => {
      const [key, value] = entry;
      if (value[3] && Milestone.Tasks?.includes(key)) {
        arr.push({
          label: value[3],
          value: key,
        });
      }
    });

    const PostData = {
      NameOfTask: NameOfTask,
      keyOfTask: props.item,       
      milestone: {
        ...Milestone,
        Tasks: arr
      }
    }
    for (let i = 0; i < props.CustomQuestions.length; i++) {
      if (props.CustomQuestions[i][3] === props.item[3]) {
        let customQ = props.CustomQuestions
        customQ[i][1] = false
        customQ[i][10] = 0
        props.setCustomQuestions(customQ)
        break;
      }
    }

    if (props.item[10]) {
      PostData["progress"] = props.item[10]
    } else {
      PostData["finish"] = props.item[1]
    }
    PostData["ProjectID"] = props.idd
    const sendToServer = async () => {
      try {
        const data = await axios.put(`/api/private/unfinishMilestone/${props.idd}`, PostData, config);
        return data
      }
      catch (e) {
        // if (!props.dev) {
        //   localStorage.removeItem("authToken");
        //   window.location.href = '/logout';
        // }
      } finally {
      }
    }
    sendToServer().then(item => {
      setSaved(true)
      setloadingSpinner(false)
      setlockedForEditing(true)
      props.updateMilestoneCounter(milestoneIndex, -1, 1);
    })
  }

  const handleSave = (status) => {
    const currentMilestoneId = props.Milestone._id;
    const milestoneIndex = props.MilestoneResultState.findIndex(milestone => milestone._id === currentMilestoneId);
    const Milestone = JSON.parse(JSON.stringify(props.Milestone));
    if (status === "Up") {
      setThumbs("Up")
    } else if (status === "Down") {
      setThumbs("Down")
    }
    setloadingSpinner(true)
    const arr = [];
    Object.entries(props.allTasks).map((entry) => {
      const [key, value] = entry;
      if (value[3] && Milestone.Tasks?.includes(key)) {
        arr.push({
          label: value[3],
          value: key,
        });
      }
    });

    const handleFinishTask = (e) => {
      const NameOfTask = props.item[0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.item,
        milestone: {
          ...Milestone,
          Tasks: arr
        }
      }
      for (let i = 0; i < props.CustomQuestions.length; i++) {
        if (props.CustomQuestions[i][3] === props.item[3]) {
          let customQ = props.CustomQuestions
          customQ[i][1] = true
          customQ[i][10] = 100
          if (TextField && TextField !== "") {
            customQ[i][7] = TextField
          }
          if (FilesField) {
            customQ[i][20] = "reload to see new pictures"
          }
          props.setCustomQuestions(customQ)
          break;
        }
      }
      PostData["ProjectID"] = props.idd

      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/finishMilestone/${props.idd}`, PostData, config);
          return data
        } catch (e) {
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
          console.log('error:' + e);
        } finally {
        }
      }
      sendToServer().then(item => {
        setSaved(true)
        setloadingSpinner(false)
        props.updateMilestoneCounter(milestoneIndex, 1, -1);
      })
    }

    const handleProgressTask = (e) => {
      const NameOfTask = props.item[0]
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.item,
        milestone: Milestone
      }
      for (let i = 0; i < props.CustomQuestions.length; i++) {
        if (props.CustomQuestions[i][3] === props.item[3]) {
          let customQ = props.CustomQuestions
          customQ[i][10] = 20
          props.setCustomQuestions(customQ)
          break;
        }
      }

      PostData["ProjectID"] = props.idd

      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(`/api/private/progressMilestone/${props.idd}`, PostData, config);
          return data
        } catch (e) {
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
          console.log('error:' + e);
        } finally {

        }
      }
      sendToServer().then(item => {
        setSaved(true)
        setloadingSpinner(false)
      })
    }

    const handleFileSave = (e) => {
      const data = new FormData()
      if (TextField && TextField !== "") {
        data.append('text', TextField)
      }
      if (status === "Up") {
        data.append('progress', "100")
      } else {
        data.append('progress', "20")
      }
      let arr = [];
  Object.entries(props.allTasks).map((entry) => {
    const [key, value] = entry;
    if (value[3] && Milestone.Tasks?.includes(key)) {
      arr.push({
        label: value[3],
        value: key,
      });
    }
  });
      data.append("milestone", JSON.stringify(Milestone))
      data.append("ProjectID", props.idd)
      data.append("keyOfTask", props.item[3])
      data.append("Tasks", JSON.stringify(arr))
      data.append("activityUrl", location.pathname)
      // PostData["ProjectID"] = props.idd

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      data.append('file', FilesField)
      data.append("searchKey", props.item)

      axios.post(`/api/private/uploadimg/${props.idd}`, data, config)
        .then((response) => {
          setFilesField("")
          for (let i = 0; i < props.CustomQuestions.length; i++) {
            if (props.CustomQuestions[i][3] === props.item[3]) {
              let customQ = props.CustomQuestions
              if (status === "Down") {
                customQ[i][10] = 20
                customQ[i][20] = URL.createObjectURL(FilesField)
                if (TextField && TextField !== "") {
                  customQ[i][7] = TextField
                }
              } else {
                customQ[i][1] = true
                customQ[i][10] = 100
                customQ[i][20] = URL.createObjectURL(FilesField)
                if (TextField && TextField !== "") {
                  customQ[i][7] = TextField
                }
              }
              props.setCustomQuestions(customQ)
              break;
            }
          }
          setSaved(true)
          setloadingSpinner(false)
          return response.data
        }, (error) => {
          console.log('error:' + e);
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
        })
    }

    const handleSaveComment = (e) => {
      const NameOfTask = props.item[0]
      let progress
      if (status === "Up") {
        progress = 100
      } else {
        progress = 20
      }
      const PostData = {
        NameOfTask: NameOfTask,
        Textbody: TextField,
        Progress: progress,
        keyOfTask: props.item,
        milestone: Milestone,
        activityUrl: location.pathname,
      }
      for (let i = 0; i < props.CustomQuestions.length; i++) {
        if (props.CustomQuestions[i][3] === props.item[3]) {
          let customQ = props.CustomQuestions
          if (status == "Down" && !FilesField) {
            customQ[i][10] = 20
            customQ[i][7] = TextField
          } else if ((status === "Up" && !FilesField)) {
            customQ[i][1] = true
            customQ[i][10] = 100
            customQ[i][7] = TextField
          }
          props.setCustomQuestions(customQ)
          break;
        }
      }

      PostData["ProjectID"] = props.idd
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      const req = axios.post(`/api/private/comments/${props.idd}`, PostData, config)
        .then((response) => {
          setTextField("")
          setSaved(true)
          setloadingSpinner(false)
          return response
        }, (error) => {
        })
    }
    if (FilesField && FilesField !== "") {
      handleFileSave()
    } else if (TextField && TextField !== "") {
      handleSaveComment()
    } else {
      if (status == "Up") {
        handleFinishTask()
      } else if (status == "Down") {
        handleProgressTask()
      }
    }
  }

  const handleThumbsUp = (item) => {
    setThumbsUp(prev => !prev)
    setSaved(false)
  }

  const Rename = (name) => {
    name = name.split(' ')
    name.shift()
    name = name.join(' ')
    return name
  }

  if (props.Filter === "Uncompleted") {
    return (
      <>

        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent" style={{ paddingLeft: props.margin + "px" }}>
          {/*   <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}
          {props.item && props.item[3] ?  
          <div className="tasklist-inner">
            <div 
              className="tasklist-col-1" 
              onClick={props.canEdit ? () => {
                history.push(`${location.pathname}/${props.Milestone._id}/${props.index}`);
              } 
              : false} 
            >
              <FormattedMessage id={props.item[3]} defaultMessage={props.item[3]} />
            </div>
            <div className="tasklist-col-2">
              {/*  <div className="counter-wrapper">
                <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div>
              <div className="counter-wrapper">
                <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div> */}
              {props.item[11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.item[11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              loadingSpinner ?
              <div className='loading-tasklist-icon'>
                <SpinnerMini />
              </div>
              : ""
            }
            {props.canEdit &&
              <>
                <div className='d-flex margin-left-auto'>
                  <input className='tasklist-input-1' placeholder="Add your comment here..." value={TextField} onChange={e => handleTextChange(e)} />
                  {!FilesField ?
                    <>
                    </>
                    :
                    <i onClick={() => setFilesField(false)} className="far fa-trash-alt tasklist-delete-1" />
                  }
                  <i onClick={Thumbs === "Down" ? undefined : () => handleSave("Down")} className={Thumbs === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} />
                  <i onClick={() => handleSave("Up")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                </div>
              </>
            }
          </div> : "" }
        </div>
        {(isOpenCamera === true && detectDeviceType() === "Desktop") &&
          <CameraBurstShotUpload OpenCameraStatus={() => setOpenCamera(false)} />}
      </>
    )
  }

  else if (props.Filter === "Completed") {
    return (
      <>
        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent tasklistrow-top" style={{ paddingLeft: props.margin + "px" }}>
          {/*  <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}
          {props.item && props.item[3] ?
          <div className="tasklist-inner">
            <div 
              className="tasklist-col-1" 
              onClick={props.canEdit ? () => {
                history.push(`${location.pathname}/${props.Milestone._id}/${props.index}`);
              } 
              : undefined} 
            >
              <FormattedMessage id={props.item[3]} defaultMessage={props.item[3]} />
            </div>
            <div className='tasklist-col-2'>
              {/*  <div className="counter-wrapper">
                <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div>
              <div className="counter-wrapper">
                <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                <div className="counter">0</div>
              </div> */}
              {props.item[11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.item[11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              <>
                {lockedForEditing &&
                  loadingSpinner ?
                  <div className='loading-tasklist-icon'>
                    <SpinnerMini />
                  </div>
                  :
                  <>
                    <div className='ml-auto'>
                      <i onClick={() => handleUnSafe("Down")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                    </div>
                  </>
                }
              </>
            }
          </div> : "" }
        </div>
      </>
    )
  }
  else if (props.Filter === "Audited") {
    return (
      <>
        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent tasklistrow-top" style={{ paddingLeft: props.margin + "px" }}>
          {/* <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}
          {props.item && props.item[3] ?
          <div className='tasklist-inner'>
            <div 
              className='tasklist-col-1' 
              onClick={props.canEdit ? () => {
                history.push(`${location.pathname}/${props.Milestone._id}/${props.index}`);
              } 
              : undefined} 
            >
              <FormattedMessage id={props.item[3]} defaultMessage={props.item[3]} />
            </div>
            <div className='tasklist-col-2'>
              {/* <div className="counter-wrapper">
              <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div>
            <div className="counter-wrapper">
              <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div> */}
              {props.item[11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.item[11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              <>
                {!lockedForEditing ?
                  loadingSpinner ?
                    <div className="loading-tasklist-icon">
                      <SpinnerMini />
                    </div>
                    :
                    <>
                      <div className='ml-auto'>
                        <input className='tasklist-input-1' placeholder="Add your comment here..." value={TextField} onChange={e => handleTextChange(e)} />
                        {!FilesField ?
                          <>
                            <input
                              className="ZeroWidth w-0"
                              key="uploadPic"
                              ref={hiddenFileInput}
                              accept="image/*"
                              multiple
                              capture
                              type="file" name="file" onChange={handleChangePicture} />
                            <i onClick={handleClickHidden} className="far fa-image fa-icon-img" />
                          </>
                          :
                          <i onClick={() => setFilesField(false)} className="far fa-trash-alt fa-img-delete" />
                        }
                        <i onClick={Thumbs === "Down" ? undefined : () => handleSave("Down")} className={Thumbs === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} />
                        <i onClick={() => handleSave("Up")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                      </div>
                    </>
                  :
                  loadingSpinner ?
                    <div className='loading-tasklist-icon'>
                      <SpinnerMini />
                    </div>
                    :
                    <>
                      <div className='ml-auto'>
                        <i onClick={() => handleUnSafe("Down")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                      </div>
                    </>
                }
              </>
            }
          </div> : "" }
        </div>
      </>
    )
  }


  else if (props.Filter === "Not-Audited") {
    if(props.item[10] === null || parseInt(props.item[10]) === 0){
    return (
      <>

        <div className="borderSecure"></div>
        <div className="TaskListRow TaskListRowParent tasklistrow-top milestone-custom-task" style={{ paddingLeft: props.margin + "px" }}>
          {/*   <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" />
          </div> */}

          {props.item && props.item[3] ?
          <div className='tasklist-inner'>
            <div 
              className='tasklist-col-1' 
              onClick={props.canEdit ? () => {
                history.push(`${location.pathname}/${props.Milestone._id}/${props.index}`);
              } 
              : undefined} 
            >
              <FormattedMessage id={props.item[3]} defaultMessage={props.item[3]} />
            </div>
            <div className='tasklist-col-2'>
              {/*  <div className="counter-wrapper">
              <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div>
            <div className="counter-wrapper">
              <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
              <div className="counter">0</div>
            </div> */}
              {props.item[11] &&
                <>
                  <i onClick={() => setTooltipState(true)} className="fas fa-info-circle tooltipOne tasklist-icon-info"></i>
                  {TooltipState ? <Tooltip setTooltipState={setTooltipState} content={props.item[11]} /> : ""}
                </>
              }
            </div>
            {props.canEdit &&
              loadingSpinner ?
              <div className='loading-tasklist-icon'>
                <SpinnerMini />
              </div>
              :
              <>
                <div style={{ marginLeft: "auto" }}>
                  <input className='tasklist-input-1' placeholder="Add your comment here..." value={TextField} onChange={e => handleTextChange(e)} />
                  {!FilesField ?
                    <>
                      <input
                        className="ZeroWidth w-0"
                        key="uploadPic"
                        ref={hiddenFileInput}
                        accept="image/*"
                        multiple
                        capture
                        type="file" name="file" onChange={handleChangePicture} />
                      <i onClick={handleClickHidden} className="far fa-image fa-icon-img" />
                    </>
                    :
                    <i onClick={() => setFilesField(false)} className="far fa-trash-alt fa-img-delete" />
                  }
                  <i onClick={Thumbs === "Down" ? undefined : () => handleSave("Down")} className={Thumbs === "Down" ? "far fa-thumbs-down RedIMG task-thumb-down" : "far fa-thumbs-down task-thumb-down"} />
                  <i onClick={() => handleSave("Up")} className={Thumbs === "Up" ? "far fa-thumbs-up greenIMG task-thumb-up" : "far fa-thumbs-up task-thumb-up"} />
                </div>
              </>
            }
          </div> : "" }
        </div>
      </>
    )
          }else{
            return (
              <></>
            )
          }
  }
  else {
    return (
      <></>
    )
  }
}

export default CollapsibleWithoutCustom
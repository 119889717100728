import React, { useRef, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';


const ProjectKPIcards = (props) => {

  return (
      <div className={`kpi-card-panel card-height my-3 w-100 ` + props.cardclass} onClick={() => props.onClick() }>
        <div style={{ display: "flex", flexDirection: "column" }} className="card-body">
          <h3 className="card-c-heading-2">{props.cardname}</h3>
          <div className='stats-outer-wrapper'>
            <div className='col-12 plr-0'>
              <p className="stats text-gray mg-b-0">{props.cardvalue}</p>
            </div>
            {/* {props.cardpercentage &&
            <div className='text-stats'>
              <div className='d-flex align-items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0,0H24V24H0Z" fill="none" /><path d="M16,6l2.29,2.29-4.88,4.88-4-4L2,16.59,3.41,18l6-6,4,4,6.3-6.29L22,12V6Z" className='fill' /></svg>
                <span className='small-stats'>{props.cardpercentage}%</span>
              </div>
            </div>
            } */}
          </div>
        </div>
      </div>
  );
};

export default ProjectKPIcards;
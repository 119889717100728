import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import axios from 'axios';


const Owldemo5 = (props) => {
  const removeThumbnailDuplicates = (arr) => {
    const seen = new Set();
    const output = [];
  
    arr.forEach((item) => {
      const numberMatch = item.match(/\d+/);
      if (numberMatch) {
        const number = numberMatch[0];
  
        if (!seen.has(number)) {
          seen.add(number);
  
          if (!item.includes('thumbnail')) {
            output.push(item);
          }
        }
      }
    });
  
    return output;
  }

  let reducedPics = []
  if (props.Pictures && props.Pictures.length > 0 ) {
    const filteredPictures = props.Pictures.filter((item) => {
      if (Array.isArray(item)) {
        return item[0] !== undefined;
      }
      return true;
    });
    console.log("filteredPictures");
    console.log(filteredPictures);
    // Check if none of the items are arrays
    const noArrays = filteredPictures.every((item) => !Array.isArray(item));
    // Call removeThumbnailDuplicates only if noArrays is true
  //   if (props.milestones) {
  //     //reducedPics = removeThumbnailDuplicates(filteredPictures);
  //     //reducedPics = filteredPictures;

  //     for (let i = 0; i < filteredPictures.length; i += 2) {
  //       if (i + 1 < filteredPictures.length) {
  //         if (Array.isArray(filteredPictures[i]) && filteredPictures[i][0] !== undefined && filteredPictures[i][1] !== undefined){
  //           reducedPics.push([filteredPictures[i][0].split(",")[0]]);
  //         }else{
  //           reducedPics.push([filteredPictures[i]]);
  //         }

  //       } else {
  //         reducedPics.push([filteredPictures[i]]);
  //       }
  //     }

  // console.log("reducedPics")
  // console.log(reducedPics)
  //    }
  }


  const openModalPics = (pictures, Index) => {
    if (props.noTeamwork && pictures[Index][0].toString().length > 7) {
      let imgs
      if (pictures[Index][0]) {
        imgs = pictures[Index][0]
      } else {
        imgs = pictures[Index]
      }
      props.setCurrentImg([imgs, Index])
      props.setModalImage(pictures)
      return
    }else{
    let Img = pictures[Index][0]
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    try {
      axios.get(`/api/private/filesName/${Img}`, config).then(res => {
        const image = res.data.file["download-URL"]
        props.setCurrentImg([image, Index])
        props.setModalImage(pictures)
      }
      )
    } catch (e) {
      if (!props.dev) {
        localStorage.removeItem("authToken");
        window.location.href = '/logout';
      }
    }
  }
  }



  if (props.Pictures.length == 0) {
    return (
      <>
        <div className="item fillcontainer">
        <img src="/images/image-placholder.svg" alt="placeholder" style={{ minHeight: "140px" }}/>
        </div>
      </>
    )
  } else {
    return (
      <>
        <Swiper navigation={true} modules={[Navigation]} className="tasklist-picture-slider"
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            1440:{
              slidesPerView: 5.5,
              spaceBetween: 16,
            },
            1660:{
              slidesPerView: 4.5,
              spaceBetween: 16,
            },
            1920: {
              slidesPerView: 5.5,
              spaceBetween: 16,
            },
            2000: {
              slidesPerView: 7.5,
              spaceBetween: 16,
            }
          }}>
          {
            reducedPics && reducedPics.length > 0 ? (
              reducedPics.map((item, index) => {
                if (item.toString().length > 7 && typeof item !== "undefined" && item !== null) {
                  return (
                    <SwiperSlide>
                      <div className="item pos-rel 22">
                        <img
                          className="imgfill imgtasklist-if"
                          onClick={() => openModalPics(reducedPics, index)}
                          src={item}
                          alt="img"
                        />
                      </div>
                    </SwiperSlide>
                  );
                }
              })
            ) : (
              props.Pictures.map((item, index) => {
                if (
                  item[1] &&
                  item[1].toString().length > 7 &&
                  typeof item[1] !== "undefined" &&
                  item[1] !== null
                ) {
                  return (
                    <SwiperSlide>
                      <div className="item pos-rel 11">
                        <img
                          className="imgfill imgtasklist-else"
                          onClick={() => openModalPics(props.Pictures, index)}
                          src={item[1]}
                          alt="img"
                        />
                      </div>
                    </SwiperSlide>
                  );
                }
              })
            )
          }

        </Swiper>
      </>
    )
  }
}


export default Owldemo5
export default function openTaskList(taskList, setScrollPos, passTaskListComp, passResult, Result, pictures, name, section, milestone) {
  console.log("openTaskList.js: openTaskList() taskList:", taskList);
  console.log("openTaskList.js: openTaskList() Result:", Result);
  console.log("openTaskList.js: openTaskList() pictures:", pictures.pictures);
  console.log("openTaskList.js: openTaskList() name:", name);
  console.log("openTaskList.js: openTaskList() section:", section);
  console.log("openTaskList.js: openTaskList() milestone:", milestone);

  function scroll() {
    //  window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // if (milestone === "Milestone") {
  //   setTimeout(scroll, 50);
  //   // setScrollPos(window.pageYOffset)
  //   passTaskListComp(taskList)
  //   passResult({
  //     "Result": taskList.Result,
  //     "Pictures": pictures.pictures,
  //     "Name": name,
  //     "Tags": pictures.Tags,
  //     "section": section,
  //   })
  //   return
  // }
  /* else if (milestone == "newBay") {
    console.log("test")
    setTimeout(scroll, 50);
    // setScrollPos(window.pageYOffset)

    passTaskListComp(e)
    passResult({
      "Result": Result,
      "Pictures": pictures.pictures,
      "Name": name
    })
    return
  } */

  setTimeout(scroll, 50);
  // setScrollPos(window.pageYOffset)

  passTaskListComp(taskList)
  passResult({
    "Result": Result,
    "Pictures": pictures.pictures,
    "Name": name,
    "Tags": pictures.Tags,
    "section": section,
    "milestone": milestone === "Milestone" ? true :  false
  })
  /* passResultEverything() */
};

//FinalArray.LightRepair["uncompleted"], props.setScrollPos, props.setTaskListComp
import { useState } from 'react';
import PieChart from './ProjectElementPie';
import axios from 'axios';
import Spinner from './spinner';
import { FormattedMessage } from 'react-intl';
import Footer from './footer'
import ShowTasksCardPicture from './ShowTasksCardPicture'
import checkPercentage from '../sharedFunctions.js/checkpercentage'

const CompressedAir = (props) => {
    /*  console.log(props) */

    const [Editing, SetEditing] = useState(false)

    /*  let props.FinalArray = {}
 
     Object.entries(props.translation).forEach(([key, val]) => {
         MainFunction(val, props, key, props.FinalArray)
     }); */
    //MainFunction(props.translation["CompanyName"], props, "CompanyName", props.FinalArray)

    //console.log(props.FinalArray)

    const changeEditing = () => {
        SetEditing(prevState => !prevState)
    }

    const openModal = (e, b, allowed) => {
        props.setOnlyMilestone("false")
        props.setMilestoneTranslation([])
        props.setTranslationModal(e)

        if (allowed) {
            props.SetTranslationOnlyAllow(allowed)
        }

        /*  console.log(e) */
    }

  

    const date = (e) => {
        let s = e.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3');
        return s

    }



    let list = []
    const filtered = Object.keys(props.tasks).filter(key => /\./.test(key)).reduce((acc, key) => {
        if (!props.tasks[key][1]) {
            list.push([props.tasks[key][3], props.tasks[key][2], props.tasks[key][0], props.tasks[key][5], props.tasks[key][7], props.tasks[key][1], props.tasks[key][8], props.tasks[key][9]])
        }

        //acc[key] = props.tasks[key]; 

        return acc;
    }, {});

    const openModalPics = (pictures, Index) => {
        if (props.noTeamwork && pictures[Index][0].toString().length > 7) {
          let imgs
          if (pictures[Index][0]) {
            imgs = pictures[Index][0]
          } else {
            imgs = pictures[Index]
          }
          props.setCurrentImg([imgs, Index])
          props.setModalImage(pictures)
          return
        }else{
          console.log(pictures[Index][0].toString().length)
        let Img = pictures[Index][0]
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        };

        try {
          axios.get(`/api/private/filesName/${Img}`, config).then(res => {
            const image = res.data.file["download-URL"]
            props.setCurrentImg([image, Index])
            props.setModalImage(pictures)
          }
          )
        } catch (e) {
          console.log(e)
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        }
      }
      }

    const openSite = () => {
        function scroll() {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            props.SetOpenPage("Overview")
        }
        props.SetOpenPage("")
        setTimeout(scroll, 50);

        // props.SetOpenPage("PaintShop")

    }

    /*   console.log(props.FinalArray) */
    if (props.tasks) {


        return (
            <>
                <div className="left">

                </div>
                {/* left */}



                <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100">
                    <div className="main-header pd-x-20 ht-70 d-flex align-items-center justify-content-between pl--0">
                        <h3 className=" mg-b-0 text-dashboard-heading">Compressor    {"\u00A0"}
                            {props.Versioning &&

                                <label className="switch">
                                    <input onChange={() => changeEditing()} type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            }


                            {/* <button onClick={() => openSite("PaintShop")} class="buttonPrev">❮ Previous</button>
*/}


                        </h3> <div className="right-options d-flex">
{/* 
                            <img src="/images/icons/print_black_24dp.svg" alt="print-icon" />
                            <img src="/images/icons/forward_to_inbox_black_24dp.svg" alt="email icon" className="ml-3" />
                         */}
                        </div>
                    </div>
                    {/* main-header */}
                    <div className="main-body bg-custom-white pd-20 pt-0">

                        <div  className="row ">
                            <div className="col-lg-12 pl-0">
                                <h1 className="main-heading"><FormattedMessage id="Compressor" defaultMessage="Compressor" /></h1>
                            </div>
                        </div>
                        <div className="container-fluid plr-0">
                            <div className="row mobile-scroll-y">
                                {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("BodyShopScoreTwo", "", ["Percentage"])} className="fas fa-wrench"></i> : ""}
                                {props.FinalArray["BodyShopScoreTwo"] ?
                                <>
                                    <div >
                                        <div className="min-h img-text-block d-flex">
                                            {/*   <Owldemo2  Pictures={props.FinalArray["Parking Area"].pictures}/>  */}

                                            <div className="content">
                                                <h5><b>Body Shop Score</b></h5>
                                                <p className="mg-b-0 small-text">{props.FinalArray["BodyShopScoreTwo"].complete}/{props.FinalArray["BodyShopScoreTwo"].total} Tasks completed</p>
                                                <div style={{ width: "80%", margin: "auto", marginTop: "2rem" }}> <PieChart className="printPie" percentage={props.FinalArray["BodyShopScoreTwoResult"]} />   </div>
                                            </div>
                                        </div>
                                    </div>
                                    




                                {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("CompressorRoomEquipment", "", ["Percentage"])} className="fas fa-wrench"></i> : ""}
                                {props.FinalArray["CompressorRoomEquipment"] ?
                                 <>
                                        <div>
                                        <ShowTasksCardPicture FinalArrayResult={props.FinalArray["CompressorRoomEquipmentResult"]} FinalArray={props.FinalArray["CompressorRoomEquipment"]} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} setScrollPos={props.setScrollPos} passTaskListComp={props.passTaskListComp} passResult={props.passResult} Name={"Compressor Room Equipment "} FormattedMessage={<h5><FormattedMessage id="CompressorRoom&Equipment" defaultMessage="Compressor Room & Equipment" /></h5>} section="CompressorRoomEquipment" />
                               

                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQualityPicture", "", ["File"])} className="fas fa-wrench"></i> : ""}
                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQualityComment", "", ["Comment"])} className="fas fa-wrench"></i> : ""}
                                            {props.FinalArray["AirQualityPictureResult"] || props.FinalArray["AirQualityCommentResult"] ?

                                                <div className="min-h img-text-block d-flex">
                                                    {props.FinalArray["AirQualityPictureResult"] &&
                                                        <div className="item fillcontainer noPics">

                                                            <img className="imgfill" src={props.FinalArray["AirQualityPictureResult"][0]} alt="img" />

                                                        </div>
                                                    }
                                                    <div className="content">
                                                        <h5 style={{ fontWeight: "bold" }}><FormattedMessage id="AirQuality" defaultMessage="Air Quality" /></h5>
                                                        <p className="mg-b-0 small-text"><FormattedMessage id="Oil" defaultMessage="Oil" /></p>


                                                        <span className="fontTrainingNumber"></span>

                                                        <div className="stats">{props.FinalArray["AirQualityCommentResult"] && checkPercentage(props.FinalArray["AirQualityCommentResult"])} </div>
                                                        <div className="d-flex mg-t-20">

                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}

                                        </div>

                                    </>
                                    : "\u00A0"}

                                {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("CoolerDryer", "", ["Percentage"])} className="fas fa-wrench"></i> : ""}
                                {props.FinalArray["CoolerDryer"] ?
                                    <>
                                        <div>
                                        <ShowTasksCardPicture FinalArrayResult={props.FinalArray["CoolerDryerResult"]} FinalArray={props.FinalArray["CoolerDryer"]} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} setScrollPos={props.setScrollPos} passTaskListComp={props.passTaskListComp} passResult={props.passResult} Name={"Cooler Dryer "} FormattedMessage={<h5><FormattedMessage id="Cooler/Dryer" defaultMessage="Cooler / Dryer" /></h5>} section="CoolerDryer" />
                               
                                            
                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQualityCOPicture", "", ["File"])} className="fas fa-wrench"></i> : ""}
                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQualityCOComment", "", ["Comment"])} className="fas fa-wrench"></i> : ""}
                                            {props.FinalArray["AirQualityCOPictureResult"] || props.FinalArray["AirQualityCOCommentResult"] ?
                                                <div className="min-h img-text-block d-flex">
                                                    {props.FinalArray["AirQualityCOPictureResult"] &&
                                                        <div className="item fillcontainer noPics">

                                                            <img className="imgfill" src={props.FinalArray["AirQualityCOPictureResult"][0]} alt="img" />

                                                        </div>
                                                    }
                                                    <div className="content">
                                                        <h5 style={{ fontWeight: "bold" }}>Air Quality</h5>
                                                        <p className="mg-b-0 small-text">CO</p>


                                                        <span className="fontTrainingNumber"></span>

                                                        <div className="stats">{props.FinalArray["AirQualityCOCommentResult"] && checkPercentage(props.FinalArray["AirQualityCOCommentResult"])} </div>
                                                        <div className="d-flex mg-t-20">

                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                    </>
                                    : "\u00A0"}

                                {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("CompressedAirPiping", "", ["Percentage"])} className="fas fa-wrench"></i> : ""}
                                {props.FinalArray["CompressedAirPiping"] ?
                                    <>
                                        <div>
                                        <ShowTasksCardPicture FinalArrayResult={props.FinalArray["CompressedAirPipingResult"]} FinalArray={props.FinalArray["CompressedAirPiping"]} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} setScrollPos={props.setScrollPos} passTaskListComp={props.passTaskListComp} passResult={props.passResult} Name={"Compressed Air Piping "} FormattedMessage={<h5><FormattedMessage id="CompressedAirPiping" defaultMessage="Compressed Air Piping" /></h5>} section="CompressedAirPiping" />
                               
                                            
                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQualityH2OPicture", "", ["File"])} className="fas fa-wrench"></i> : ""}
                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQualityH2OComment", "", ["Comment"])} className="fas fa-wrench"></i> : ""}
                                            {props.FinalArray["AirQualityH2OPictureResult"] || props.FinalArray["AirQualityH2OCommentResult"] ?
                                                <div className="min-h img-text-block d-flex">
                                                    {props.FinalArray["AirQualityH2OPictureResult"] &&
                                                        <div className="item fillcontainer noPics">

                                                            <img className="imgfill" src={props.FinalArray["AirQualityH2OPictureResult"][0]} alt="img" />

                                                        </div>

                                                    }
                                                    <div className="content">
                                                        <h5 style={{ fontWeight: "bold" }}>Air Quality</h5>
                                                        <p className="mg-b-0 small-text">H2O</p>


                                                        <span className="fontTrainingNumber"></span>

                                                        <div className="stats">{props.FinalArray["AirQualityH2OCommentResult"] && checkPercentage(props.FinalArray["AirQualityH2OCommentResult"])} </div>
                                                        <div className="d-flex mg-t-20">

                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}

                                        </div>
                                    </>
                                    : "\u00A0"}


                                {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("Filtration", "", ["Percentage"])} className="fas fa-wrench"></i> : ""}
                                {props.FinalArray["Filtration"] ?
                                    <>
                                        <div>
                                             <ShowTasksCardPicture FinalArrayResult={props.FinalArray["FiltrationResult"]} FinalArray={props.FinalArray["Filtration"]} setCurrentImg={props.setCurrentImg} setModalImage={props.setModalImage} setScrollPos={props.setScrollPos} passTaskListComp={props.passTaskListComp} passResult={props.passResult} Name={"Filtration "} FormattedMessage={<h5><FormattedMessage id="Filtration" defaultMessage="Filtration" /></h5>} section="Filtration" />
                               
                                            
                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQuality4Picture", "", ["File"])} className="fas fa-wrench"></i> : ""}
                                            {props.Versioning & Editing ? <i style={{ cursor: "pointer", fontSize: "15px" }} onClick={() => openModal("AirQuality4Comment", "", ["Comment"])} className="fas fa-wrench"></i> : ""}

                                            {props.FinalArray["AirQuality4PictureResult"] || props.FinalArray["AirQuality4CommentResult"] ?
                                                <div className="min-h img-text-block d-flex">
                                                    {props.FinalArray["AirQuality4PictureResult"] &&
                                                        <div className="item fillcontainer noPics">

                                                            <img className="imgfill" src={props.FinalArray["AirQuality4PictureResult"][0]} alt="img" />

                                                        </div>
                                                    }

                                                    <div className="content">
                                                        <h5 style={{ fontWeight: "bold" }}>Air Quality</h5>
                                                        <p className="mg-b-0 small-text">H2O</p>

                                                </div>
                                                <div style={{ marginTop: "auto", marginLeft: "1rem" }} className="d-flex mg-t-20">

                                                        <span className="fontTrainingNumber"></span>

                                                        <div className="stats">{props.FinalArray["AirQuality4CommentResult"] && checkPercentage(props.FinalArray["AirQuality4CommentResult"])} </div>
                                                        <div className="d-flex mg-t-20">

                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}

                                        </div>
                                    </>
                                    : "\u00A0"}

</>  : "\u00A0"}
                            </div>
                        </div>
                    <Footer />                                            
                    </div>
                </div>
            </>
        );
    }
    else {
        return <Spinner />;
    }
}


export default CompressedAir;
import React from 'react';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
  
import axios from 'axios';


const CreateProjects = (props) => {
    const [projects, setProjects] = useState(false)
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)


    useEffect(() => {
        let rights = JSON.parse(atob(localStorage.getItem("rights")))
        if (rights.Versioning && rights.Renumbering ) {
            const fetch = async () => {
                //props.SetOpenPage("")
                const TWGet = axios.get("/api/private/projects", config);
                try {
                    const [projects] = await Promise.all([TWGet])
                    setProjects(projects.data.projects);
                    console.log(projects.data.projects)

                } catch (e) {
                    if (!props.dev) {
                        localStorage.removeItem("authToken");
                        window.location.href = '/logout';
                    }
                }
            }
            fetch().then(
                setLoading(false)
            );
        }



    }, []);


    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };



    function writeDatabase2(idd, oldname) {
        do {
            var person = prompt("How many times copy?")
            if (person == "" || person == null) {
                return //break out of the function early
            }

        } while (person.length < 0 && person.length > 51);
        person = parseInt((person), 10);


        const sendToServer = async () => {

            const PostData = {
                oldName: oldname,
                projectID: idd,
                times: person
            }
            try {
                const data = await axios.post(`/api/private/copyprojecttemp`, PostData, config);
                console.log(data.data)
                if (data.data) {
                    setSuccess(true)
                }
            } catch (e) {
                if (!props.dev) {
                    console.log(e)
                    /*   localStorage.removeItem("authToken");
                      window.location.href = '/logout';  */
                }
            } finally {

            }

        }
        sendToServer().then(async () => {




        })



    }




    function renumber(idd) {
        let isExecuted = window.confirm("Are you sure to execute this action?");

        if (isExecuted) {
            let isExecuted2 = window.confirm("Are you absolutely sure to execute this action?");
            if (isExecuted2) {
                const sendToServer = async () => {
                    const PostData = {
                        projectID: idd,
                    }
                    try {
                        const data = await axios.post(`/api/private/rename/${idd}`, PostData, config);
                        console.log(data.data)
                        if (data.data) {
                            setSuccess(true)
                        }
                    } catch (e) {
                        if (!props.dev) {
                            console.log(e)
                            /*   localStorage.removeItem("authToken");
                              window.location.href = '/logout';  */
                        }
                    } finally {
                    }
                }
                sendToServer().then(async () => {
                })
            }
        }






    }

    if (!loading && projects) {

        return (
            <>

                <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100">
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <div id="container" className="container-fluid">
                                <div className="mb-5 row mb-2">
                                    <div className="col-sm-12">
                                        <h1>Copy Projects</h1>
                                        {projects.map((items, index) => {
                                            return (
                                                <button onClick={() => writeDatabase2(items.id, items.name)}>{items.name}</button>
                                            )
                                        })
                                        }
                                        {success &&
                                            <div> in Process now</div>}
                                    </div>
                                </div>
                                <br></br>
                                <div className="mb-5 row mb-2">
                                    <div className="col-sm-12">
                                    <h1>Copy Projects</h1>
                                        {projects.map((items, index) => {
                                            return (
                                                <button onClick={() => renumber(items.id)}>{items.name}</button>
                                            )
                                        })
                                        }
                                        {success &&
                                            <div> in Process now</div>}
                                    </div>
                                </div>
                            </div>{/* /.container-fluid */}
                        </section>
                        {/* Main content */}
                        <section className="content">

                            <div className="container-fluid">
                                <div style={{ paddingRight: "7.5px", paddingLeft: "7.5px", justifyContent: "flex-end" }} className="row mb-2">
                                </div>



                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            {/* /.card-header */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.col */}
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                    </div >
                </div >

            </>
        );

    }
    else {
        return (

            <section style={{ minHeight: "100vh" }} className="content-header">
                <div className="SpinnerCon container-fluid">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"><FormattedMessage id="Loading" defaultMessage="Loading..." /></span>
                    </div>
                </div>
            </section>

        )

    }
}
export default CreateProjects;
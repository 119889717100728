import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Spinner from '../spinner';
import PieChart3 from '../ProjectElementPie3';
import ProjectTextIcon from '../ProjectTextIcon'
import MapContainer from '../Maps/MapWrapper';
import { useIntl, FormattedMessage } from 'react-intl';
import Footer from "../footer"
import Select from 'react-select';
import ProjectStartDate from './partials/ProjectStartDate'
import { Empty, Pagination , Button } from "antd"
import checkPercentage from '../../sharedFunctions.js/checkpercentage'
import { Input, Skeleton } from 'antd';
import CardProject from "../UI/cards/CardProject"
import CardProjectKpi from "../UI/cards/CardProjectKpiStats"
import CardProjectDonut from "../UI/cards/CardProjectDonut"
import NewProjectPopup from './newproject/index'
import MilestonePopup from './popup/milestone'

const Projects = (props) => {
  const _userRole = JSON.parse(atob(localStorage.getItem("role")));
  console.log("Projects Props")
  console.log(props);
  console.log(props.deleted)
  const intl = useIntl();
  const PAGE_LIMIT = 40;
  const [Loading, setLoading] = useState(true);
  const [EmptyProjects, setEmptyProjects] = useState(false);
  const searchValue = useRef(null)
  const [selectedItem, setSelectedItem] = useState('');

  const [defaultProjects, setDefaultProjects] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showProjectPopup, setShowProjectPopup] = useState(false);
  const [showMilestonesPopup,setShowMilestonesPopup] = useState(false)
  const [assessmentTypeObj,setAssessmentTypeObj] = useState([])
  const [fetchedData, setFetchedData] = useState({
    kpiIndicator: 0,
    countlateMilestones: 0,
    lateMilestones: [],
    countupcomingMilestones: 0,
    upcomingMilestones: [],
    countstartedMilestones: 0,
    startedMilestones: [],
    counttodayMilestones: 0,
    todayMilestones: [],
    countNoDateMilestones: 0,
    noDateMilestones: [],
    totalMilestones: 0,
    totalProjects: 0,
    projects: [],
    totalAvgSubstainbility: 0.0,
    totalAvgFacility: 0.0,
    totalAvgBusinessWorkshopProcess: 0,
    totalAvgRepairQuality: 0,
    totalAvgTraining: 0,
    totalAvgAuditScore: 0,
    totalAvgBusinessInformation: 0,
    Renumbered: false,
    versioning: false,
    changeProjects: false,
    createProjects: false
  })
  const [milestonePopup,setMilestonePopup] = useState({
    milestoneTitle: "",
    milestoneArr: [],
    milestoneCount: 0
  })

  const { klaro, klaroConfig } = window;
  const ConsentManager = klaro?.getManager(klaroConfig);
  const consents = ConsentManager?.consents;
  const filterOptions = [
    { label: "Select All", value: 0 },
    { label: "0% difference", value: 1 },
    { label: "Older than 1 year", value: 2 },
    { label: "2022", value: 4 },
    { label: "2023", value: 5 },
    { label: "Possess KPI", value: 6 },
  ];

  const sortOptions = [
    { label: "Default sorting", value: 0 },
    { label: "Start Date", value: 1 },
    { label: "Total Assesment Score", value: 2 },
    { label: "+30 days +/-", value: 3 }
  ]

  const closeProjectPopUp = () => {
    setShowProjectPopup(false)
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };


  useEffect(() => {
    const fetch = async () => {
      let DBGet = axios.get("/api/private/dbprojects", config);
      let _assessmentList = axios.get("/api/private/public/assessments", config);
      try {
        const [projectsData,assessmentList] = await Promise.all([DBGet,_assessmentList])
        if(assessmentList.data){
          console.log("assessmentList")
          console.log(assessmentList)
          const assessmentDropdownObj = assessmentList.data.map((item) => {
            if (item.Name !== "Standard") {
              return { label: item.Name, value: item.Name };
            }
            return null;
          }).filter((item) => item !== null);
          console.log("assessment obj2: ")
          console.log(assessmentDropdownObj)
          setAssessmentTypeObj([{label: "Select Assessment", value: '' },...assessmentDropdownObj])
          console.log("assessment obj3: ")
          console.log([{label: "Select Assessment", value: '' },...assessmentDropdownObj])
        }
        if (projectsData.data.projects.length === 0) {
          setEmptyProjects(true)
        }
        if (projectsData.data) {
          console.log("projectsData.data.projects ")
          console.log(projectsData.data.projects)
          setFetchedData(prevData => ({
            ...prevData,
            kpiIndicator: projectsData.data.kpiIndicator || 0,
            countlateMilestones: projectsData.data.milestones.countlateMilestones || 0,
            lateMilestones: projectsData.data.milestones.lateMilestones || [],
            countupcomingMilestones: projectsData.data.milestones.countupcomingMilestones || 0,
            upcomingMilestones: projectsData.data.milestones.upcomingMilestones || [],
            countstartedMilestones: projectsData.data.milestones.countstartedMilestones || 0,
            startedMilestones: projectsData.data.milestones.startedMilestones || [],
            counttodayMilestones: projectsData.data.milestones.counttodayMilestones || 0,
            todayMilestones: projectsData.data.todayMilestones || [],
            countNoDateMilestones: projectsData.data.milestones.countNoDateMilestones || 0,
            noDateMilestones: projectsData.data.milestones.noDateMilestones || [],
            totalMilestones: projectsData.data.totalMilestones || 0,
            totalProjects: projectsData.data.totalProjects || 0,
            projects: projectsData.data.projects || [],
            totalAvgSubstainbility: projectsData.data.totalAvgSubstainbility || 0.0,
            totalAvgFacility: projectsData.data.totalAvgFacility || 0.0,
            totalAvgBusinessWorkshopProcess: projectsData.data.totalAvgBusinessWorkshopProcess || 0,
            totalAvgRepairQuality: projectsData.data.totalAvgRepairQuality || 0,
            totalAvgTraining: projectsData.data.totalAvgTraining || 0,
            totalAvgAuditScore: projectsData.data.totalAvgAuditScore || 0,
            totalAvgBusinessInformation: projectsData.data.totalAvgBusinessInformation || 0,
            Renumbered: projectsData.data.Renumbered || false,
            versioning: projectsData.data.versioning || false,
            changeProjects: projectsData.data.ChangeProjects || false,
            createProjects: projectsData.data.CreateProjects || false,
          }));
          setDefaultProjects(projectsData.data.projects)
          setLoading(false);
        }
      } catch (e) {
        console.log(e)
        console.log("logout")
        if (!props.dev) {
          console.log(e)
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    fetch().then(
      console.log("finish"),
      props.SetOpenPage(""),
    );
  }, []);


  const DeleteDB = (e) => {
    const fetch = async () => {
      //SetRenumbering(true)
      try {
        const DBGet = await axios.delete(`/api/private/project/${e}`, config);
      } catch (e) {
        console.log(e)
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    let isExecuted = window.confirm(intl.formatMessage({
      id: "Areyousureyouwanttodelete",
      defaultMessage: "Are you sure you want to delete?"
    }))
    if (!isExecuted) {
      return
    }
    fetch().then(result => {
    })
  };

  const Recover = (e) => {
    const fetch = async () => {
      try {
        const DBGet = await axios.put(`/api/private/project-recover/${e}`, { empty: "empty" }, config);
      } catch (e) {
        console.log(e)
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }
    }
    let isExecuted = window.confirm(intl.formatMessage({
      id: "Areyousureyouwanttorecover",
      defaultMessage: "Are you sure you want to recover?"
    }))
    if (!isExecuted) {
      return
    }
    fetch().then(result => { })
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchValue = (event) => {
    setIsLoading(true);
    setFetchedData(prevState => Object.assign({}, prevState, { projects: [] }));
    const filterValue = searchValue.current?.input?.value
    console.log(`filter: ${filterValue}`)
    console.log(searchValue.current)
      axios.get(`/api/private/dbprojects?search=${encodeURIComponent(filterValue)}`, config).then(projectsData => {
        setFetchedData(prevState => Object.assign({}, prevState, {
          kpiIndicator: projectsData.data.kpiIndicator || 0,
          countlateMilestones: projectsData.data.milestones.countlateMilestones || 0,
          lateMilestones: projectsData.data.milestones.lateMilestones || [],
          countupcomingMilestones: projectsData.data.milestones.countupcomingMilestones || 0,
          upcomingMilestones: projectsData.data.milestones.upcomingMilestones || [],
          countstartedMilestones: projectsData.data.milestones.countstartedMilestones || 0,
          startedMilestones: projectsData.data.milestones.startedMilestones || [],
          counttodayMilestones: projectsData.data.milestones.counttodayMilestones || 0,
          todayMilestones: projectsData.data.todayMilestones || [],
          countNoDateMilestones: projectsData.data.milestones.countNoDateMilestones || 0,
          noDateMilestones: projectsData.data.milestones.noDateMilestones || [],
          totalMilestones: projectsData.data.totalMilestones || 0,
          totalProjects: projectsData.data.totalProjects || 0,
          projects: projectsData.data.projects || [],
          totalAvgSubstainbility: projectsData.data.totalAvgSubstainbility || 0.0,
          totalAvgFacility: projectsData.data.totalAvgFacility || 0.0,
          totalAvgBusinessWorkshopProcess: projectsData.data.totalAvgBusinessWorkshopProcess || 0,
          totalAvgRepairQuality: projectsData.data.totalAvgRepairQuality || 0,
          totalAvgTraining: projectsData.data.totalAvgTraining || 0,
          totalAvgAuditScore: projectsData.data.totalAvgAuditScore || 0,
          totalAvgBusinessInformation: projectsData.data.totalAvgBusinessInformation || 0,
          Renumbered: projectsData.data.Renumbered || false,
          versioning: projectsData.data.versioning || false,
          changeProjects: projectsData.data.ChangeProjects || false,
          createProjects: projectsData.data.CreateProjects || false,
        }));
        setDefaultProjects(projectsData.data.projects)
        console.log("projects::")
        console.log(projectsData.data.projects)
      })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        })

  }

  const sortNewMarkersBySelect = (sorting) => {
    setFetchedData(prevState => Object.assign({}, prevState, { projects: defaultProjects }));
    let sortedProjects = []
    if (sorting.value === 0) {
      setFetchedData(prevState => Object.assign({}, prevState, { projects: defaultProjects }));
      return;
    }
    if (sorting.value === 1) {
      sortedProjects = defaultProjects.filter(project => project !== null && project.startDate !== "").sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    } else if (sorting.value === 2) {
      sortedProjects = defaultProjects.sort((a, b) => b.percentage.TotalAuditScore - a.percentage.TotalAuditScore);
    } else if (sorting.value === 3) {
      sortedProjects = defaultProjects.sort((a, b) => parseFloat(b.dailyAuditScore) - parseFloat(a.dailyAuditScore));
    }else if(sorting.value === "Holistic Body & Paint"){
      sortedProjects = defaultProjects.filter(project => project !== null && project.assessmentType === "Holistic Body & Paint")
    }else if(sorting.value === "Paint Shop"){
      sortedProjects = defaultProjects.filter(project => project !== null && project.assessmentType === "Paint Shop")
    }else if(sorting.value === "AMA"){
      sortedProjects = defaultProjects.filter(project => project !== null && project.assessmentType === "AMA")
    }else if(sorting.value === "Test CV only"){
      sortedProjects = defaultProjects.filter(project => project !== null && project.assessmentType === "Test CV only")
    }else if(sorting.value === "Commercial Vehicle"){
      sortedProjects = defaultProjects.filter(project => project !== null && project.assessmentType === "Commercial Vehicle")
    }else if(sorting.value === "RCC"){
      sortedProjects = defaultProjects.filter(project => project !== null && project.assessmentType === "RCC")
    }
    setFetchedData(prevState => Object.assign({}, prevState, { projects: sortedProjects }));
    setCurrentPage(1)
  }

  const filterNewMarkersBySelect = (sorting, dropdownType) => {
    console.log("dropdownType",dropdownType)
    let assessmentVal = "";
    let selectedItemVal = selectedItem;
    if(dropdownType === "filter"){
      selectedItemVal = sorting.value;
      setSelectedItem(selectedItemVal);
    }else{
      assessmentVal = sorting.value
    }
    const filterValue = searchValue.current?.input?.value || "";
    setIsLoading(true);
    setFetchedData(prevState => Object.assign({}, prevState, { projects: [] }));
    console.log(`filter value: ${sorting.value}`)
    axios.get(`/api/private/dbprojects?search=${encodeURIComponent(filterValue)}&filter=${selectedItemVal}&assessment=${encodeURIComponent(assessmentVal)}`, config).then(projectsData => {

      setFetchedData(prevState => Object.assign({}, prevState, {
        kpiIndicator: projectsData.data.kpiIndicator || 0,
        countlateMilestones: projectsData.data.milestones.countlateMilestones || 0,
        lateMilestones: projectsData.data.milestones.lateMilestones || [],
        countupcomingMilestones: projectsData.data.milestones.countupcomingMilestones || 0,
        upcomingMilestones: projectsData.data.milestones.upcomingMilestones || [],
        countstartedMilestones: projectsData.data.milestones.countstartedMilestones || 0,
        startedMilestones: projectsData.data.milestones.startedMilestones || [],
        counttodayMilestones: projectsData.data.milestones.counttodayMilestones || 0,
        todayMilestones: projectsData.data.todayMilestones || [],
        countNoDateMilestones: projectsData.data.milestones.countNoDateMilestones || 0,
        noDateMilestones: projectsData.data.milestones.noDateMilestones || [],
        totalMilestones: projectsData.data.totalMilestones || 0,
        totalProjects: projectsData.data.totalProjects || 0,
        projects: projectsData.data.projects || [],
        totalAvgSubstainbility: projectsData.data.totalAvgSubstainbility || 0.0,
        totalAvgFacility: projectsData.data.totalAvgFacility || 0.0,
        totalAvgBusinessWorkshopProcess: projectsData.data.totalAvgBusinessWorkshopProcess || 0,
        totalAvgRepairQuality: projectsData.data.totalAvgRepairQuality || 0,
        totalAvgTraining: projectsData.data.totalAvgTraining || 0,
        totalAvgAuditScore: projectsData.data.totalAvgAuditScore || 0,
        totalAvgBusinessInformation: projectsData.data.totalAvgBusinessInformation || 0,
        Renumbered: projectsData.data.Renumbered || false,
        versioning: projectsData.data.versioning || false,
        changeProjects: projectsData.data.ChangeProjects || false,
        createProjects: projectsData.data.CreateProjects || false,
      }));
      setDefaultProjects(projectsData.data.projects)
      console.log("projects::")
      console.log(projectsData.data.projects)
    })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      })
    setCurrentPage(1)
  }

  const openLateMilestonePopup = (title) => {
    let newState = {};

    if (title === "Late Milestones") {
      newState = {
        milestoneTitle: "Late MileStone",
        milestoneArr: fetchedData.lateMilestones,
        milestoneCount: fetchedData.countlateMilestones,
      };
    } else if (title === "Started Milestones") {
      newState = {
        milestoneTitle: "Started MileStones",
        milestoneArr: fetchedData.startedMilestones,
        milestoneCount: fetchedData.countstartedMilestones,
      };
    } else if (title === "Today Milestones") {
      newState = {
        milestoneTitle: "Today",
        milestoneArr: fetchedData.todayMilestones,
        milestoneCount: fetchedData.counttodayMilestones,
      };
    } else if (title === "No Date Milestones") {
      newState = {
        milestoneTitle: "No Date Milestones",
        milestoneArr: fetchedData.noDateMilestones,
        milestoneCount: fetchedData.countNoDateMilestones,
      };
    } else if (title === "Upcoming Milestones") {
      newState = {
        milestoneTitle: "Upcoming Milestones",
        milestoneArr: fetchedData.upcomingMilestones,
        milestoneCount: fetchedData.countupcomingMilestones,
      };
    }

    setMilestonePopup(prevState => Object.assign({}, prevState, newState));
    setShowMilestonesPopup(true);
  }

  function handleCloseMilestonesPopup() {
    setShowMilestonesPopup(false);
  }

  const startProjectIndex = (currentPage - 1) * PAGE_LIMIT;
  const endProjectIndex = startProjectIndex + PAGE_LIMIT;




  if (!Loading) {
    return (
      <>
        {EmptyProjects ?
          <>
            {fetchedData.createProjects === true &&
              <NewProjectPopup setFetchedData={setFetchedData} projectData={fetchedData.projects} show={showProjectPopup} onHide={closeProjectPopUp}  />
            }
            <MilestonePopup heading={milestonePopup.milestoneTitle} show={showMilestonesPopup} onHide={handleCloseMilestonesPopup} milestoneData={milestonePopup.milestoneArr} projects={fetchedData.projects} milestoneCount={milestonePopup.milestoneCount} />
            <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 project-page d-flex align-items-center justify-content-center">
              <div className="main-body bg-custom-white pt-0 pd-20">
                {fetchedData.createProjects === true &&
                  <div className="add-circle-wrapper floating-btn floating-project-btn" onClick={() => setShowProjectPopup(true)}>
                    <div className="content"><i className="fas fa-plus" ></i><span className="label-text"><FormattedMessage id="AddNewProject" defaultMessage="Add New Project" /></span></div>
                  </div>
                }
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<div><span className='label' style={{ "fontSize": "32px", "fontWeight": "bold", "color": "#000" }}><FormattedMessage id="projectNotFound" defaultMessage="Project(s) Not Found" /></span><br /><span className='label-desc' style={{ "fontSize": "18px", "fontWeight": "normal", "color": "#666" }}><FormattedMessage id="BaysNotFoundText" defaultMessage="Please contact the administrator so you can edit/see some projects" /></span></div>} />
              </div>
            </div>
          </>
          :
          <>
          {fetchedData.createProjects === true && showProjectPopup &&
              <NewProjectPopup setFetchedData={setFetchedData} projectData={fetchedData.projects} show={showProjectPopup} onHide={closeProjectPopUp} />
          }
          <MilestonePopup heading={milestonePopup.milestoneTitle} show={showMilestonesPopup} onHide={handleCloseMilestonesPopup} milestoneData={milestonePopup.milestoneArr} projects={fetchedData.projects} milestoneCount={milestonePopup.milestoneCount} />
            <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 project-page">
              <div className="main-body bg-custom-white pt-0 pd-20">
                {console.log("fetchedData.createProjects",fetchedData.createProjects)}
                {fetchedData.createProjects === true &&
                  <div className="add-circle-wrapper floating-btn floating-project-btn" onClick={() => setShowProjectPopup(true)}>
                    <div className="content"><i className="fas fa-plus" ></i><span className="label-text"><FormattedMessage id="AddNewProject" defaultMessage="Add New Project" /></span></div>
                  </div>
                }
                <div className="row mg-t-20">
                  <div className="col-lg-12 pl-0">
                    <h1 className="main-heading"><FormattedMessage id="Projects" defaultMessage="Projects" /></h1>
                  </div>
                </div>
                <div className="row project-row-wrapper">
                  <div className="col-c col-lg-2 min-h  col-md-2 col-sm-12 pl-0 project-col-1">
                    <CardProjectDonut bgClass="bg-white" translationId="AvgTotalAssessmentScore" translationMessage="Avg. Assessment Score" percentage={fetchedData.totalAvgAuditScore.toString()} classes="mg-l-0" />
                  </div>
                  <div className="col-c col-lg-8 min-h  col-md-8 col-sm-12  project-col-2">
                    <div className="row projects-row card-custom">
                      <CardProject bgClass="bg-green-c" translationId="Sustainability" translationMessage="Sustainability" percentage={fetchedData.totalAvgSubstainbility.toString()} />
                      <CardProject bgClass="bg-gray-c" translationId="Facility+Equipment" translationMessage="Facility + Equipment" percentage={fetchedData.totalAvgFacility.toString()} />
                      <CardProject bgClass="bg-turquiose-c" translationId="Business+Workshop" translationMessage="Business + Workshop Process" percentage={fetchedData.totalAvgBusinessWorkshopProcess.toString()} />
                      <CardProject bgClass="bg-orange-c" translationId="RepairQuality" translationMessage="Repair Quality" percentage={fetchedData.totalAvgRepairQuality.toString()} />
                      <CardProject bgClass="bg-dark-blue-c" translationId="Training" translationMessage="Training" percentage={fetchedData.totalAvgTraining.toString()} />
                      <CardProject bgClass="bg-white" classes="last" translationId="BusinessInformation" translationMessage="Business Information" percentage={fetchedData.totalAvgBusinessInformation.toString()} />
                    </div>
                  </div>
                  <div className="col-c col-lg-2 min-h  col-md-2 col-sm-12 project-col-3 card-milestone-wrapper">
                    <div className="card card-milestone rounded-2 border-gray border-radius-16  bg-white">
                      <div className="card-body">
                        <h3 className="card-c-heading"><FormattedMessage id="OpenMilestones" defaultMessage="Open Milestones" /></h3>
                        <div className="d-flex justify-content-between text-number-block">
                          <div className="col-first">
                            <ul className="highlighted-row">
                              <li><FormattedMessage id="AllMilestone" defaultMessage="All Milestone" /></li>
                              <li><FormattedMessage id="Late" defaultMessage="Late" /></li>
                              <li><FormattedMessage id="Started" defaultMessage="Started" /></li>
                              <li><FormattedMessage id="Today" defaultMessage="Today" /></li>
                              <li><FormattedMessage id="Upcoming" defaultMessage="Upcoming" /></li>
                              <li><FormattedMessage id="NoDate" defaultMessage="No Date" /></li>
                            </ul>

                          </div>
                          <div className="col-second">
                            <ul className="highlighted-row">
                              <li>{fetchedData.totalMilestones}</li>
                              <li className="text-underline" onClick={() => openLateMilestonePopup('Late Milestones')}>{fetchedData.countlateMilestones}</li>
                              <li className="text-underline" onClick={() => openLateMilestonePopup('Started Milestones')}>{fetchedData.countstartedMilestones}</li>
                              <li className='text-underline' onClick={() => openLateMilestonePopup('Today Milestones')}>{fetchedData.counttodayMilestones}</li>
                              <li className='text-underline' onClick={() => openLateMilestonePopup('Upcoming Milestones')}>{fetchedData.countupcomingMilestones}</li>
                              <li className='text-underline' onClick={() => openLateMilestonePopup('No Date Milestones')}>{fetchedData.countNoDateMilestones}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  mg-t-16 two-column-height-wrapper">
                  <div className="col-c col-md-2 col-lg-2 pl-0 project-col-1">
                    <div className='row gap-16 flex-column ml-0 mr-0'>
                    <CardProject bgClass="bg-white" classes="card-height" translationId="NumberofShops" translationMessage="Number of Shops" value={fetchedData.projects.length.toString()} />
                    <CardProject bgClass="bg-white" classes="card-height" translationId="ProvidingKPIData" translationMessage="Providing KPI Data" value={fetchedData.kpiIndicator.toString()} />

                    </div>
                     </div>
                  <div className="col-c col-md-4 col-lg-4 pl-0 project-col-2">
                    <CardProjectKpi bgClass="bg-white" classes="card-heights" translationId="PartsSales" translationMessage="Parts Sales" value={fetchedData.projects.length.toString()} />
                 </div>
                  <div className="col-c col-md-6 col-lg-6 col-sm-12 min-h project-col-map">
                  {consents && consents["google-maps"] ?
                    <div data-name="google-maps" className="card rounded-2  border-gray border-radius-16 bg-white overflow-hidden map-wrapper">
                      {fetchedData.projects && fetchedData.projects.length > 0 ?
                        <MapContainer
                          markers={fetchedData.projects}
                          initialCenterMarker={fetchedData.projects[0].position}
                        />
                        : ""}
                    </div>
                    :  <>
                    <h3 className="card-c-heading"><FormattedMessage id="MapsConsentRequest" defaultMessage="If you want to load external content supplied by Google Maps, please give your consent." /></h3>
                    <Button
                      type="primary"
                      style={{
                        marginRight: "30px",
                        textTransform: "uppercase",
                        width: "50%"
                        // minWidth: "fit-content",
                      }}
                      className="btn saveButtonTasklist"
                      onClick={() => {
                        window.klaro.show(undefined, true);
                      }}
                    >
                      <FormattedMessage id="Consent" defaultMessage="Show Consent Manager" />
                    </Button>
                  </>
                }
                  </div>
                </div>
                <div className="row mg-t-16 align-items-wrapper search-bar-wrapper">
                  <div className={(_userRole === 'admin' || _userRole === 'superadmin') ? 'col-lg-6 col-md-6 col-sm-6 plr-0 min-h-48 search-bar' : "col-lg-8 col-md-8 col-sm-8 plr-0 min-h-48 search-bar"}>
                    <div className="d-flex input-project-wrapper mg-b-16">
                      <Input.Search className="input-search-field" ref={searchValue} loading={isLoading}
                        onSearch={handleSearchValue} placeholder={intl.formatMessage({
                          id: "SearchforKeywordBodyShopName",
                          defaultMessage: "Search for Keyword, Body Shop Name, …"
                        })} />
                      {/* <div className="search-icon" onClick={handleSearchValue}>
                    <img src="/images/icons/search-input-icon.svg" />
                  </div> */}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 plr-2 min-h-48 search-bar">
                    <div className="d-flex input-project-wrapper mg-b-16">
                      <Select className="input-search-field react-select-container" classNamePrefix="react-select"
                        placeholder={intl.formatMessage({
                          id: "FilterBodyShopName",
                          defaultMessage: "Select filter"
                        })}
                        onChange={e => filterNewMarkersBySelect(e,"filter")}
                        options={filterOptions}
                      >
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 plr-2 min-h-48 search-bar">
                    <div className="d-flex input-project-wrapper mg-b-16">
                      <Select className="input-search-field react-select-container" classNamePrefix="react-select"
                        placeholder={intl.formatMessage({ id: "SortingBodyShopName", defaultMessage: "Select sorting" })}
                        onChange={sortNewMarkersBySelect}
                        options={sortOptions}
                      >
                      </Select>
                    </div>
                  </div>
                  {(_userRole === 'admin' || _userRole === 'superadmin') &&
                  <div className="col-lg-2 col-md-2 col-sm-2 plr-2 min-h-48 search-bar">
                    <div className="d-flex input-project-wrapper mg-b-16">
                      <Select className="input-search-field react-select-container" classNamePrefix="react-select"
                        placeholder={intl.formatMessage({ id: "SortingAssessmentBodyShopName", defaultMessage: "Select Assessment" })}
                        onChange={e => filterNewMarkersBySelect(e,"assessment")}
                        options={assessmentTypeObj}
                      >
                      </Select>
                    </div>
                  </div>
                  }

                </div>
                <div className='projects-wrapper'>
                <Skeleton active loading={isLoading}>
                  {fetchedData.projects.slice(startProjectIndex, endProjectIndex).map(arrItem => (
                    <div className="row mg-b-16 single-project-wrapper" key={arrItem.projectID}>
                      <div className="col-md-12 col-lg-12 col-sm-12 plr-0">
                        <div className="card rounded-2  border-gray border-radius-16 bg-white pd-16 pos-rel">
                          {fetchedData.changeProjects ?
                            <>
                              {props.deleted ?
                                <div className="projectEditRow project-recover-icon" onClick={() => Recover(arrItem.projectID)}>
                                  <div className="icon-text" >
                                    <i className="fa fa-repeat" ></i>
                                  </div>
                                </div>
                                :
                                <div className="projectEditRow project-del-icon" onClick={() => DeleteDB(arrItem.projectID)}>  <img src="/images/icons/new/trash_bin_black_24dp.svg" />  </div>
                              }
                            </> : ""}
                          <div className="row mlr-0">
                            <div className="col-md-4 col-lg-4 col-sm-8 project-col-3">
                              <div className="d-flex profile-flex-wrapper">
                                <div className="img-profile">
                                  <img className="ProjectImage-info rounded-img" src={arrItem.picture} alt="pic"/>
                                </div>
                                <div className="profile-content pl-20 color-black">
                                  <span className="project-link"><Link to={`/project/${arrItem.projectID}`}  >{arrItem.projectName}</Link></span> {arrItem.completeAddress}
                                  {(arrItem.startDate && (arrItem.startDate !== "" ||  arrItem.startDate !== null)) ? <div><ProjectStartDate startDate={arrItem.startDate} /></div> : ""}
                                </div>
                              </div>

                            </div>
                            <div className="col-md-8 col-lg-8 col-sm-4 project-col-3 project-single-right">
                              <div className="d-flex justify-content-end">
                                <ProjectTextIcon percentage={arrItem.dailyAuditScore} iconName="trending_up_black_24dp.svg" text="Total Audit Score last 30 days" />
                                <div className="piechart-wrapper">
                                  <PieChart3 className="printPie" percentage={arrItem.percentage ? arrItem.percentage.TotalAuditScore : 0} textPie="Total Audit Score" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Skeleton>
                </div>
                {fetchedData.projects.length > PAGE_LIMIT ?
                  <div className="d-flex justify-content-center pt-4">
                  <Pagination
                    current={currentPage}
                    total={fetchedData.projects.length}
                    pageSize={PAGE_LIMIT}
                    onChange={handlePageChange}
                  />
                  </div>
                  : ""}
                <Footer />
              </div>
            </div>
          </>
        }
      </>

    );
  } else {
    return <Spinner />;
  }

}

export default Projects;
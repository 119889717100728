import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import DefineTagsElements from './defineTagsElements';
import SpinnerTags from '../spinnerTags';
import axios from 'axios';
import Select from 'react-select'

import { useIntl, FormattedMessage } from 'react-intl';

const DefineCurrencies = (props) => {
  const intl = useIntl()
  const [possibleTags, setPossibleTags] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [saved, setSaved] = useState(true);
  const [saveable, setsaveable] = useState(true);
  const [TextInput, setTextInput] = useState(props.CurrenciesModal.Symbol);
  const [TextInputPosition, setTextInputPosition] = useState(props.CurrenciesModal.Position)
  const positionType = [{ value: "left", label: "Left" }, { value: "right", label: "Right" }]


  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };


  useEffect(() => {
    console.log(props)


  }, [])


  const onChangeSign = (e) => {
    setTextInput(e.target.value)
    if (e.target.value !== "") {
      setSaved(false)
    } else {
      setSaved(true)
    }

  }

  const onChangePosition = (e) => {
    console.log(e.value)
    setTextInputPosition(e.value)
    if (e.value !== "") {
      setSaved(false)
    } else {
      setSaved(true)
    }

  }



  if (true) {
    return (

      <div style={{ display: "Block", zIndex: "1000" }} id="popup-1" className="fq-modal">
        <div style={{ maxWidth: "500px", minHeight: "60vh" }} id="definetags-modal" className="fq-modal-content">
          <div className="fq-modal-header">

            <span onClick={() => props.setCurrenciesModal(false)} className="close"><img src="/images/close-btn.svg" alt="btn close" /></span>
            <h1><FormattedMessage id="SetCurrencies" defaultMessage="Set Currencies" /></h1>

          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "unset",
            padding: "15px 30px", paddingBottom: "60px", overflowY: "auto"
          }} className="modal-content-wrapper">


            <div className="row d-flex">

              <div className="col-12">
                <h2 style={{ "fontSize": "32px", "fontWeight": "bold", paddingBottom: "0px" }}>{props.CurrenciesModal.Name}</h2></div>
              <div className="col-12">
                <div className='input-wrapper mb-2'>
                  <label for='Sign' className='mb-2'><FormattedMessage id="CurrencySign" defaultMessage="Currency Sign" /></label>
                  <input value={TextInput} onChange={(e) => onChangeSign(e)} type="text" name="name" maxLength="3" className='form-control' />
                </div>
                <div className='input-wrapper mb-2'>
                  <label for='position' className='mb-2'><FormattedMessage id="CurrencyPosition" defaultMessage="Currency Position" /></label>
                  <Select
                    defaultValue={positionType.filter(x => x.value === TextInputPosition)}
                    placeholder={intl.formatMessage({ id: "SelectPosition", defaultMessage: "Select Position" })}
                    options={positionType}
                    onChange={(e) => onChangePosition(e)}
                  />

                </div>
              </div>






            </div>
            <div className='row mt-3'>



              {saved || !saveable ?
                <button
                  className='btn cta-btn btn-login w-100' disabled="true"
                ><FormattedMessage id="Save" defaultMessage="Save" /></button>
                :
                <button
                  className=' btn cta-btn btn-login w-100'
                  onClick={() => props.saveCurrencies({ Name: props.CurrenciesModal.Name, Symbol: TextInput, Position: TextInputPosition, id: props.CurrenciesModal._id }, setSaved)}><FormattedMessage id="Save" defaultMessage="Save" /></button>
              }





            </div>
          </div>
        </div>
      </div>



    );
  } else {
    return (

      <div style={{ display: "Block", zIndex: "1000" }} id="popup-1" className="fq-modal">

      </div>
    )

  }
}

export default DefineCurrencies

import React from 'react'

const ProjectStartDate = (props) => {
    const setEndDate = (_date) => {
        let d = new Date(_date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear() + 1;

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        return [year, month, day].join('-');
      }
    return (
        <div className='d-flex align-items-center' >
            <div className='start-date d-flex align-items-center mr-2'>
                <img src="/images/start-date-icon.svg" alt="print-icon" /><span>{props.startDate}</span>
            </div>
            <div className='start-date d-flex align-items-center'>
                <img src="/images/end-date.svg" alt="print-icon" /><span>{setEndDate(props.startDate)}</span>
            </div>
        </div>
    )
}

export default ProjectStartDate;
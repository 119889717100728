import { useState } from "react";
import Cookie from "js-cookie";
import { toast } from "react-toastify";
import axios from "axios";

export function useTranslation(sourceLanguage) {
  const [translated, setTranslated] = useState(false);
  const [translatedText, setTranslatedText] = useState("");
  const [detectedSourceLanguage, setDetectedSourceLanguage] = useState();
  const [targetLanguage, setTargetLanguage] = useState();

  const translate = async (text) => {
    const locale = Cookie.get("locale");
    setTargetLanguage(locale.toUpperCase());

    if (locale && locale !== sourceLanguage) {
      try {
        const translation = await translateText(text, locale);
        // const apiTranslatedText = translation.translatedText;
        const apiTranslatedText = translation.text;
        const apiDetectedSourceLanguage = translation.detected_source_language;

        if (apiTranslatedText) {
          setTranslatedText(apiTranslatedText);
          setTranslated(true);
          setDetectedSourceLanguage(apiDetectedSourceLanguage);
        }
      } catch (error) {
        console.log(error);
        toast.error("Translation failed.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      setTranslated(false);
      setDetectedSourceLanguage(sourceLanguage);
      return;
    }
  };

  const showOriginalText = () => setTranslated(false);

  return { 
    detectedSourceLanguage, 
    targetLanguage, 
    translated, 
    translatedText, 
    translate, 
    showOriginalText 
  };
}

function translateText(text, target) {
  return (async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      const body = {
        text,
        target,
      };

      const response = await axios.post("/api/translate-text", body, config);
      console.log("response.translations:", response.data);
      // const translation = response.data.data.translations[0];
      const translation = response.data.translations[0];
      console.log("(useTranslation - translateText) translation:", translation);

      return translation;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error:", error.message);
      }
      console.log(error.config);
    }
  })();
}

import React, {memo} from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useState, useEffect } from "react";
import {FormattedMessage } from 'react-intl';

const ProjectTextIcon = memo((props) => {

  return (
    <div className="text-icon-wrapper" style={{
     position: 'relative' , zIndex: "1", width: "96px", height: "96px", maxWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"
    }}>
      <img class="icon" src={"/images/icons/" + props.iconName } style={{position: "absolute",zIndex: "0" }}/>
      <p class="chart-percentage" style={{
color: "#3C3C3C",
fontSize:" 26px",
lineHeight: "26px",
fontWeight: "normal",
width: "96px",
height: "96px",
textAlign: 'center',
marginBottom: "0px", zIndex: "1",
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
flexDirection: 'column',
marginTop: '20px'
}}>{props.percentage}%<br/> <span class="textPie" style={{
color: "#6A7284",
fontSize:" 12px",
lineHeight: "14px",
display: 'block',
margin: '0 auto',
fontWeight: "normal",
width: "70px",
textAlign: "center", zIndex: "1",
}}><FormattedMessage id="TotalAuditScorelast30days" defaultMessage={props.text} /></span> </p>
    </div>

  )
    

});

export default ProjectTextIcon;
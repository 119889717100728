import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import ProjectTBODY from '../projectTBODY';
import { useIntl, FormattedMessage } from 'react-intl';
import Footer from '../footer'
import Select, { createFilter } from 'react-select';
import CustomOption from './CustomOption';

const VersioningsOverview = (history, props) => {

  const [error, setError] = useState("");
  const [Loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [Numbers, SetNumbers] = useState(false);
  const [canSee, setCanSee] = useState(false);
  const [selectVersioning, setSelectVersioning] = useState(false);
  const [selectProject, setSelectProject] = useState(false);
  const [projects, setProjects] = useState([]);
  const [versionings, setVersionings] = useState([]);
  const firstUpdate = useRef(true);



  const ProjectIds = []

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };


  useEffect(() => {
    const fetch = async () => {

      try {
        const Vers = axios.get("/api/private/versioning", config);
        const TWGet = axios.get("/api/private/projects", config);
        const defaults = axios.get("/api/private/defaults", config);
        const [dbversioning, resultProjects, defaultElements] = await Promise.all([Vers, TWGet, defaults])
        console.log(resultProjects.data.projects)
        console.log(defaultElements.data.data)
        setSelectVersioning(defaultElements.data.data.Versioning)
        setSelectProject(defaultElements.data.data.Project)
        setCanSee(dbversioning.data.versioning)

        let allNumbers = []
        dbversioning.data.results.map((arrItem, Index) => {
          allNumbers.push(arrItem.projectType)
        })

        console.log(allNumbers)
        SetNumbers(allNumbers)

        let versionings = []
        allNumbers.map(item => {
          versionings.push({ value: item, label: item })
        })
        let allProjects = []
        resultProjects.data.projects.map(item => {
          allProjects.push({ value: item.id, label: item.name })
        })
        setProjects(allProjects)
        setVersionings(versionings)
        return true
      } catch (e) {


        /*  if (!props.dev) {
           localStorage.removeItem("authToken");
           window.location.href = '/logout';
         }
  */


        /*   console.log(e.message)
      localStorage.removeItem("authToken");
      history.push("/"); */
        // window.location.href = '/logout';   

      }

    }

    fetch().then(item=>{
      setLoading(false)
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return
      }
      
       
      
      
    });

  }, [setIsSaving, isSaving]);



  useEffect(() => {
    if (firstUpdate.current) {
      console.log(selectVersioning)
      console.log(selectProject)
      return
     }
     let body ={
       Project: selectProject,
       Versioning: selectVersioning
     }
     console.log(body)
      try {
        const Vers = axios.put(`/api/private/defaults`, body, config).then(item=>{
          console.log(item.data)
        })
        
      } catch (e) {
         if (!props.dev) {
           localStorage.removeItem("authToken");
           window.location.href = '/logout';
         }
     }
  }, [selectProject, selectVersioning]);

  /* 
  useEffect(() => {
    if (!firstUpdate.current) {
      console.log(selectVersioning)
      console.log(selectProject)
      return
     }
     let body ={
       default : {
         selectProject
       }
     }
      try {
        const Vers = axios.put("/api/private/default-versioning/${}", config);
      } catch (e) {
         if (!props.dev) {
           localStorage.removeItem("authToken");
           window.location.href = '/logout';
         }
     }
  }, [selectVersioning]); */



  const addNew = () => {

    do {
      var person = prompt("Please enter new version number:")

      if (person == "" || person == null) {
        return //break out of the function early
      }
      person = parseInt((person), 10);
    } while (isNaN(person) || Numbers.indexOf(person) >= 0);

    console.log("true")
    const Body = {
      projectType: person,
    }
    const fetch = async () => {
      try {


        const DBGet = await axios.post(`/api/private/versioning/`, Body, config);
        console.log(DBGet.data)
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        }
      }

    }
    fetch().then(
      /* setIsSaving(prev => (!prev)) */
      SetNumbers(oldArray => [...oldArray, person])

      /*  SetNumbers((prevState) => ({
         ...prevState,person
         
       })) */


    )
  };


  const clone = (e) => {

    do {
      var person = prompt("Please enter new version number:")

      if (person == "" || person == null || !person) {
        console.log("test")
        return
        //break out of the function early
      }
      person = parseInt((person), 10);
    } while (isNaN(person) || Numbers.indexOf(person) >= 0);

    console.log(person)
    const Body = {
      NewProjectType: person,
      Clone: e
    }
    const fetch = async () => {
      try {
        const DBGet = await axios.post(`/api/private/clone/${e}`, Body, config);
        console.log(DBGet.data)
      }
      catch (e) {
    /*     if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = '/logout';
        } */
      }

    }
    fetch().then(
      SetNumbers(oldArray => [...oldArray, person])
      /*   SetNumbers((prevState) => ({
          ...prevState,person
          
        })) */


    )

  };


  const deleteIt = (e) => {
    if (window.confirm("Do you really want to delete it?")) {
      const Body = {
        projectType: e
      }
      console.log(e)
      const fetch = async () => {
        try {
          const DBGet = await axios.post(`/api/private/deletevers/${e}`, Body, config);
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        }

      }
      fetch().then(

        SetNumbers(oldArray => (oldArray.filter(item => item !== e)))
        /*  setIsSaving(prev => (!prev)) */
      )

    } else {
      return
    }


  };






  if (!Loading && Numbers && canSee) {
    return (

      <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100">
        <div className="main-body bg-custom-white pt-0 pd-20">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div id="container" className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <button onClick={() => writeDatabase()}>Update</button> */}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#"><FormattedMessage id="Home" defaultMessage="Home" /></a></li>
                    <li className="breadcrumb-item active"><FormattedMessage id="Versions" defaultMessage="Versions" /></li>
                  </ol>
                </div>
              </div>
            </div>{/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            <div className="">
              <div style={{ paddingRight: "7.5px", paddingLeft: "7.5px", justifyContent: "flex-end" }} className="row mb-2">
                <button style={{ Right: "0", fontSize: "1.3em" }} className='mb-2 btn btn-primary btn-primary-blue no-print'
                  onClick={() => addNew()}><i style={{ paddingRight: "10px" }} className="fas fa-file"></i> <FormattedMessage id="AddNew" defaultMessage="Add New" /></button>
              </div>


              <div className="row">
                <div className="col-12">
                  <div className="mb-5 card">
                    {/* /.card-header */}
                    <div >


                      <table style={{ marginBottom: "0px" }} id="example2" className="table table-bordered table-hover">
                        <thead>

                          <tr>

                            <th style={{verticalAlign: "middle"}}><FormattedMessage id="DefaultVersion" defaultMessage="Default Version" /></th>
                            <th>
                              <Select
                                value={selectVersioning}
                                className={"multiClass"}
                                onChange={setSelectVersioning}
                                options={versionings}
                                filterOption={createFilter({ ignoreAccents: false })}
                                components={{ Option: CustomOption }}
                                classNamePrefix="custom-select"
                              />
                            </th>
                          </tr>

                          <tr>

                            <th style={{verticalAlign: "middle"}}><FormattedMessage id="DefaultProject" defaultMessage="Default Project" /></th>
                            <th>
                              <Select
                                value={selectProject}
                                className={"multiClass"}
                                onChange={setSelectProject}
                                options={projects}
                                filterOption={createFilter({ ignoreAccents: false })}
                                components={{ Option: CustomOption }}
                                classNamePrefix="custom-select"
                              />
                            </th>
                          </tr>



                        </thead>
                        <tbody>






                        </tbody>
                      </table>

                    </div></div></div>
                <div className="col-12">
                  <div className="card">
                    <div >


                      <table style={{ marginBottom: "0px" }} id="example2" className="table table-bordered table-hover">
                        <thead>

                          <tr>

                            <th><FormattedMessage id="All Versions" defaultMessage="All Versions" /></th>

                          </tr>

                          {Numbers.map((arrItem, Index) => {
                            if (arrItem == 10000001) {
                              return (

                                <tr role="row" key={arrItem + Index}>
                                  <td className="TableFlex sorting_1"><Link style={{ color: "inherit", display: "Block" }} to={`/versioning/${arrItem}`}  ><FormattedMessage id="Main" defaultMessage="Main" /></Link>



                                  </td>
                                </tr>

                              )

                            }
                            if (arrItem !== 10000001) {
                              return (

                                <tr role="row" key={arrItem + Index}>
                                  <td className="TableFlex sorting_1"><Link style={{ display: "flex", alignItems: "center", color: "inherit" }} to={`/versioning/${arrItem}`}  >{arrItem}</Link>

                                    <Link style={{ Right: "0", fontSize: "1.3em" }} className='LeftAlign btn-primary-blue mr-2 btn btn-secondary no-print'
                                      to={`/versioning/${arrItem}`}
                                    ><i className="fas fa-clone"></i> <FormattedMessage id="Edit" defaultMessage="Edit" /></Link>

                                    <button style={{ Right: "0", fontSize: "1.3em" }} className='btn-primary-blue mr-2 btn btn-secondary no-print'
                                      onClick={() => clone(arrItem)}><i className="fas fa-clone"></i> <FormattedMessage id="Clone" defaultMessage="Clone" /></button>
                                    <button style={{ Right: "0", fontSize: "1.3em" }} className='btn-primary-blue mr-2 btn btn-secondary no-print'
                                      onClick={() => deleteIt(arrItem)}><i className="fas fa-trash"></i> <FormattedMessage id="Delete" defaultMessage="Delete" /></button>

                                  </td>
                                </tr>

                              )
                            }
                          })}


                        </thead>
                        <tbody>






                        </tbody>
                      </table>
                    </div>
                    {/* /.card-body */}
                  </div>

                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}

          </section>
          <div className="col-md-12 mg-t-10">
            <Footer />
          </div>
        </div>
      </div>

    );
  }
  else {
    return (

      <section style={{ minHeight: "100vh" }} className="content-header">
        <div className="SpinnerCon container-fluid">
          <div className="spinner-border" role="status">
            <span className="sr-only"><FormattedMessage id="Loading" defaultMessage="Loading..." /></span>
          </div>
        </div>
      </section>

    )

  }
}
export default VersioningsOverview;
import React, { Component } from 'react';

const Progressbar = (props) => {
  const getProgressClass = (percentage) => {
    if (percentage < 50) {
      return "progress bg-red"
    } else if (percentage < 85 && percentage > 0) {
      return "progress bg-yellow"
    } else if (percentage < 100 && percentage > 85) {
      return "progress bg-green"
    } else if (percentage === 100) {
      return "progress bg-dark-green"
    } else {
      return "progress bg-red"
    }
  }

  return (
    <>
      <div style={{ width: `${props.width}%` }}  className={getProgressClass(parseFloat(props.width))}></div>
    </>
  )
}


export default Progressbar
import React, { useState } from 'react';
function SpinnerMini() {

    return (
      
            <div style={{height: "10px",
                width: "90px",
                display: "inline-block",
                position: "relative"}} >
                <div style={{
                   }} class="loaderTwo"></div>
            </div>
  
    );
}

export default SpinnerMini
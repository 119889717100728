import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';

import Upload from "./Upload";
import { useUpload } from "../../hooks/useUpload";
import Collapsible from './Tasklist/ProjectTasklistCollapsible';
import CollapsibleWithout from './Tasklist/ProjectTasklistCollapsibleWithout';
import ProjectTasklistSecondScreen from './Tasklist/ProjectTasklistSecondScreen';
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import { format, parse, addDays } from 'date-fns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import { DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Button } from "antd";

<FormattedMessage id="Logout" defaultMessage="Logout" />

const Note = (props) => {
  console.log("Starting Note rendering...")
  const [firstTime, setfirstTime] = useState(true);
  const [Title, setTitle] = useState("");
  const [Creator, setCreator] = useState("");
  const [Edited, setEdited] = useState("");
  const [userNames, setUserNames] = useState("");
  const [selectedUserNames, setSelectedUserNames] = useState([])
  const [selectedPrivateUserNames, setSelectedPrivateUserNames] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [saved, setSaved] = useState(true);
  const [Quill, setQuill] = useState(false)
  
  const intl = useIntl();
  const { handleBeforeUpload, handleAfterUpload, handleRemove, fileList } = useUpload();
  let history = useHistory();
  const { projectId } = useParams();
  const projectPath = `/project/${projectId}`;

  useEffect(() => {
    if (!firstTime) {
      setSaved(false)
    }
  }, [selectedUserNames, selectedPrivateUserNames])

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const formDataConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const handleQuill = (e) => {
    setQuill(e)
    setSaved(false)
  }

  useEffect(() => {
    console.log(props)
    let answer = []

    if (props.NotesComp["Title"]) {
        setTitle(props.NotesComp["Title"])
    }

    if (props.NotesComp["Edited"]) {
        setEdited(props.NotesComp["Edited"])
    }

    if (props.NotesComp["Content"]) {
        setQuill(props.NotesComp["Content"])
    }

    // if (props.NotesComp["Persons"]) {
    //     setSelectedUserNames(props.NotesComp["Persons"])
    // }

    if (props.NotesComp["files"]) {
      console.log("props.NotesComp['files']:", props.NotesComp["files"]);
      setUploadedFiles(props.NotesComp["files"]);
    }

    if (props.NotesComp["_id"]) {
      console.log("props.NotesComp['_id']:", props.NotesComp["_id"]);
      setId(props.NotesComp["_id"]);
    }

    const req = axios.get(`/api/private/userNames`, config)
      .then((response) => {
        let usernamesarr = []
        response.data.forEach(item => {
          const editRights = (item.rights.EditProjects.find(item => item == props.idd) || item.rights.EditProjects == "all") ? true : false;
          const seeRights = (item.rights.SeeProjects.find(item => item == props.idd) || item.rights.SeeProjects == "all") ? true : false;
          const notesRights = item.rights.Notes;
          if (editRights && seeRights && notesRights) {
            let obj = {}
            obj.value = item.username
            obj.label = item.username
            obj.id = item._id
            usernamesarr.push(obj)
          }
        })
        setUserNames(usernamesarr)

        if (props.NotesComp["Creator"]) {
          const userName = usernamesarr.find(user => user.id === props.NotesComp["Creator"])
          setCreator(userName ? userName.value : "")
        }

        if (props.NotesComp["Persons"]) {
          setSelectedUserNames(props.NotesComp["Persons"])
        } else {
          setSelectedUserNames(usernamesarr);
        }

        if((props.NotesComp["Private"])?.length === usernamesarr.length || usernamesarr.length > 5 && usernamesarr.length === (props.NotesComp["Private"]?.length + 1 || props.NotesComp["Private"]?.length)) {
            // let privateUserNamesArr = [];
            // const privateUserNames = {
            //     value: 'Everyone',
            //     label: 'Everyone',
            // }
            // privateUserNamesArr.push(privateUserNames)

            // setSelectedPrivateUserNames(privateUserNamesArr);
            setSelectedPrivateUserNames(usernamesarr);
        } else if (props.NotesComp["Private"]) {
            setSelectedPrivateUserNames(props.NotesComp["Private"])
        } else {
          setSelectedPrivateUserNames(usernamesarr);
        }
      })

    function getDate(e) {
      var year = e.substring(0, 4);
      var month = e.substring(4, 6);
      var day = e.substring(6, 8);

      return new Date(year, month - 1, day)
    }
      /*     setSelectedUserNames() */
    setTimeout(() => {
      setfirstTime(false)
    }, 1000)
  }, [])

  useEffect(() => {
    var r = props.Notes.some(el => {
      console.log(el["Title"])
      return el["Title"] == Title
    })
  }, [Title])

  useEffect(() => {
    
  }, []);

    /*   useEffect(() => {
         if (!firstTime) {
             setQuestions(true)
         }
     }, [props.milestoneTranslation, props.setMilestoneTranslation]) 
  */

  const close = () => {
    function scroll() {
      window.scrollTo({ top: props.ScrollPos, behavior: 'smooth' })
    }
    props.setEditNote(false)
    props.setViewNote(false)
    props.setNotesComp(false)
    history.push(projectPath);    
    //console.log(props.ScrollPos)
    //   setTimeout(scroll,50);
  }

  const edit = () => {
    props.setViewNote(false)
    props.setEditNote(true)
    setSaved(false);
  }

  const onFileDelete = (key) => {
    setFilesToDelete([...filesToDelete, key]);
  }

  const isToDelete = (item) => {
    return filesToDelete.includes(item.key);
  }

  const deleteNote = (a) => {
    if (window.confirm("Do you really want to delete it?")) {
      const uploadedFilesKeys = uploadedFiles.map((file) => {
        return file.key;
      });
      console.log("uploadedFilesKeys:", uploadedFilesKeys);
      let body = {
        Title: a,
        uploadedFilesKeys: uploadedFilesKeys,
      };
      let e = props.idd;
      const fetch = async () => {
        try {
          const DBGet = await axios.post(`/api/private/notes/${e}`, body, config);
          props.setNotes(prevState => {
            return prevState.filter((item, index) => {
              return a !== item["Title"]
            })
          })
        } catch (e) {
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = '/logout';
          }
        }
      }

      fetch()
      close()
    } else {
      return
    }
  }

  const handleTextChange = (e) => {
    setTitle(e.target.value)
    setSaved(false)
  }

  const handleSave = (e) => {
    function scroll() {
      window.scrollTo({ top: props.ScrollPos, behavior: 'smooth' })
    }

    const userName = userNames.find(userName => userName.value === props.username)

    let body = {
      _id: id,
      ProjectID: props.idd,
      ProjectName: props.companyName,
      Title: Title,
      Persons: selectedUserNames,
      Private: selectedPrivateUserNames,
      Content: DOMPurify.sanitize(Quill),
      Edited: new Date(props.edited).toLocaleDateString().replace(/\./g, "/"),
      Creator: userName.id,
      files: uploadedFiles,
    };

    console.log("fileList:", fileList);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("_id", id);
    formData.append("ProjectID", props.idd);
    formData.append("ProjectName", props.companyName);
    formData.append("Title", Title);
    formData.append("Persons", JSON.stringify(selectedUserNames));
    formData.append("Private", JSON.stringify(selectedPrivateUserNames));
    formData.append("Content", DOMPurify.sanitize(Quill));
    formData.append(
      "Edited",
      new Date(props.edited).toLocaleDateString().replace(/\./g, "/")
    );
    formData.append("Creator", userName.id);
    for (var key of formData.entries()) {
      console.log(key[0] + ": " + key[1]);
    }

    if (props.editNote) {
      body.Edited = new Date().toLocaleDateString().replace(/\./g, "/");
      const sendToServer = async () => {
        try {
          formData.append("filesToDelete", JSON.stringify(filesToDelete));
          formData.append("uploadedFiles", JSON.stringify(uploadedFiles));
          const res = await axios.put(
            `/api/private/note/${props.idd}`,
            formData,
            formDataConfig
          );
          console.log("res.data:", res.data);

          setUploadedFiles(res.data.files);
          if (fileList.length || filesToDelete.length) {
            handleAfterUpload(null, res.data);
          }
          body.files = res.data.files;

          setId(res.data._id);
          body._id = res.data._id;

          props.setNotes((prevState) => {
            return prevState.map((item, index) => {
              if (props.NotesComp["_id"] == item["_id"]) {
                return body;
              } else {
                return item;
              }
            });
          });
        } catch (e) {
          console.log(e);
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = "/logout";
          }

          if (fileList.length || filesToDelete.length) {
            handleAfterUpload(e);
          }
        }
      };
      sendToServer()
        .then((item) => {
          setSaved(true);
          setLoading(false);
        });
    } else {
      const sendToServer = async () => {
        try {
          const res = await axios.post(
            `/api/private/notes`,
            formData,
            formDataConfig
          );
          console.log("res.data:", res.data);

          setUploadedFiles(res.data.files);
          if (fileList.length || filesToDelete.length) {
            handleAfterUpload(null, res.data);
          }
          body.files = res.data.files;

          setId(res.data._id);
          body._id = res.data._id;
          
          props.setNotes((prevState) => [...prevState, body]);
        } catch (e) {
          console.log(e);
          if (!props.dev) {
            localStorage.removeItem("authToken");
            window.location.href = "/logout";
          }

          if (fileList.length || filesToDelete.length) {
            handleAfterUpload(e);
          }
        }
      };
      sendToServer()
        .then((item) => {
          setSaved(true);
          setLoading(false);
        });
    }
  };

  let modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  console.log("uploadedFiles:", uploadedFiles);
  console.log("selectedUserNames:", selectedUserNames);
  console.log("selectedPrivateUserNames:", selectedPrivateUserNames);

  return (
    <>
      <div
        style={{ display: "Block", zIndex: "1000", overflow: "hidden" }}
        id="popup-1"
        className="fq-modal"
      >
        <div
          id="milestones"
          className={
            `fq-modal-content` + props.viewNote ? `slide-in` : `slide-out`
          }
        >
          <div className="fq-modal-header" style={{ paddingLeft: "15px" }}>
            {props.editNote ? (
              <h1 className="pl-0">
                <FormattedMessage
                  id="EditNote"
                  defaultMessage="Edit selected note"
                />{" "}
              </h1>
            ) : props.viewNote ? (
              <h1 className="pl-0">
                <FormattedMessage id="ViewNote" defaultMessage="View note" />{" "}
              </h1>
            ) : (
              <h1 className="pl-0">
                <FormattedMessage
                  id="AddNote"
                  defaultMessage="Add a new note"
                />{" "}
              </h1>
            )}
            {!props.viewNote ? (
              <p className="pl-0">
                <FormattedMessage
                  id="NoteInstruction"
                  defaultMessage="Please use the following editor to create a new note"
                />{" "}
              </p>
            ) : (
              ""
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",

              paddingBottom: "30px",
              overflowY: "auto",
            }}
            className="modal-content-wrapper"
          >
            {!props.viewNote ? (
              <div style={{ padding: "15px" }} className="row d-flex">
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginRight: "20px",
                  }}
                >
                  <div style={{ paddingBottom: "15px" }}>
                    <FormattedMessage
                      id="NewTitle"
                      defaultMessage="Please enter a title for this note."
                    />
                  </div>
                  <div //style={{ marginLeft: "auto" }}
                  >
                    <input
                      disabled={props.editNote ? "disabled" : ""}
                      style={{
                        padding: "15px",
                        border: "#6A7284 1px solid",
                        width: "100%",
                        borderRadius: "16px",
                        paddingLeft: "23px",
                        border: "1px #6A7284 solid",
                      }}
                      placeholder="Name of the new note"
                      value={Title}
                      onChange={(e) => handleTextChange(e)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {!props.viewNote ? (
              <div style={{ padding: "15px" }} className="row d-flex">
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginRight: "20px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{ maxWidth: "26rem" }} //style={{ marginLeft: "auto" }}
                  >
                    <ReactQuill
                      modules={modules}
                      formats={formats}
                      placeholder="Add your content here…"
                      theme="snow"
                      value={Quill}
                      onChange={(e) => handleQuill(e)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {props.viewNote ? (
              <div
                style={{ padding: "15px", wordBreak: "break-word" }}
                className="row d-flex"
              >
                <div
                  id="quill-format"
                  dangerouslySetInnerHTML={{ __html: Quill ? Quill : "" }}
                />
              </div>
            ) : (
              ""
            )}

            <div style={{ padding: "15px" }} className="row d-flex">
              {props.viewNote ? (
                <>
                {uploadedFiles.length ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        marginRight: "20px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="d-flex align-items-center mb-3">
                        <PaperClipOutlined
                          style={{
                            fontSize: "1.2rem",
                            marginRight: "5px",
                          }}
                        />

                        <FormattedMessage
                          id="NoteUploadedFiles"
                          defaultMessage="The following files were attached:"
                        />
                      </div>
                      <div
                        id="uploadedFiles"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          maxWidth: "400px",
                        }} //style={{ marginLeft: "auto" }}
                      >
                        {uploadedFiles.map((item, index) => {
                          return (
                            <div
                              key={item.key}
                              className="single-file"
                              style={{marginTop: "5px"}}
                            >
                              <a href={`${item.location}`} target="_blank">{item.originalname}</a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )
                :
                <>
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      marginRight: "20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-flex align-items-center mb-3">
                      <PaperClipOutlined
                        style={{
                          fontSize: "1.2rem",
                          marginRight: "5px",
                        }}
                      />
                      <FormattedMessage
                        id="NoteUploadedFiles"
                        defaultMessage="No files were attached."
                      />
                    </div>
                  </div>
                </>
                }
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginRight: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex align-items-center mb-3">
                    <PaperClipOutlined
                      style={{
                        fontSize: "1.2rem",
                        marginRight: "5px",
                      }}
                    /> 
                    <FormattedMessage
                      id="NoteAddFiles"
                      defaultMessage="Files"
                    />
                  </div>
                  <Upload
                    onRemove={handleRemove}
                    beforeUpload={handleBeforeUpload}
                    fileList={fileList}
                  />
                  {props.editNote && uploadedFiles.map((item, index) => {
                      return (
                        <div
                          key={item.key}
                          className="single-file"
                          style={{ marginTop: "5px" }}
                        >
                          <>
                            {isToDelete(item) ?
                              <FormattedMessage
                                id="ItemOriginalNameDel"
                                defaultMessage={"<del>{filename}</del>"}
                                values={{
                                  del: chunks => <del>{chunks}</del>,
                                  filename: item.originalname,
                                }}
                              /> :
                              <>
                                <a href={`${item.location}`} target="_blank">{item.originalname}</a>
                                <DeleteOutlined style={{ marginLeft: "5px"}} onClick={() => onFileDelete(item.key)} />
                              </>
                            }
                          </>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>

            <div style={{ padding: "15px" }} className="row d-flex">
              {props.viewNote ? (
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginRight: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                    >
                      <path
                        id="Pfad_556"
                        data-name="Pfad 556"
                        d="M22,6a2.006,2.006,0,0,0-2-2H4A2.006,2.006,0,0,0,2,6V18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2ZM20,6l-8,5L4,6Zm0,12H4V8l8,5,8-5Z"
                        transform="translate(-2 -4)"
                        fill="#6a7284"
                      />
                    </svg>

                    <FormattedMessage
                      id="NoteNotifiedView"
                      defaultMessage="The following users were notified about this note."
                    />
                  </div>
                  <div
                    id="usernames"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "400px",
                    }} //style={{ marginLeft: "auto" }}
                  >
                    {selectedUserNames.map((item, index) => {
                      return <div className="single-user">{item.value}</div>;
                    })}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginRight: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                    >
                      <path
                        id="Pfad_556"
                        data-name="Pfad 556"
                        d="M22,6a2.006,2.006,0,0,0-2-2H4A2.006,2.006,0,0,0,2,6V18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2ZM20,6l-8,5L4,6Zm0,12H4V8l8,5,8-5Z"
                        transform="translate(-2 -4)"
                        fill="#6a7284"
                      />
                    </svg>

                    <FormattedMessage
                      id="NoteNotifiedView"
                      defaultMessage="Who should be notified about this note?"
                    />
                  </div>
                  <div
                    id="usernames" //style={{ marginLeft: "auto" }}
                  >
                    <MultiSelect
                      options={userNames}
                      value={selectedUserNames}
                      hasSelectAll={true}
                      className={"MultiSelectClassTwo"}
                      onChange={setSelectedUserNames}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              )}
            </div>

            <div style={{ padding: "15px" }} className="row d-flex">
              {props.viewNote ? (
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginRight: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="21"
                      viewBox="0 0 16 21"
                    >
                      <path
                        id="Pfad_554"
                        data-name="Pfad 554"
                        d="M18,8H17V6A5,5,0,0,0,7,6V8H6a2.006,2.006,0,0,0-2,2V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,18,8ZM9,6a3,3,0,0,1,6,0V8H9Zm9,14H6V10H18Zm-6-3a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,17Z"
                        transform="translate(-4 -1)"
                        fill="#6a7284"
                      />
                    </svg>

                    <FormattedMessage
                      id="SeeNoteView"
                      defaultMessage="The following users are able to see this note."
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "400px",
                    }} //style={{ marginLeft: "auto" }}
                  >
                    {selectedPrivateUserNames.map((item, index) => {
                      return <div className="single-user">{item.value}</div>;
                    })}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginRight: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="21"
                      viewBox="0 0 16 21"
                    >
                      <path
                        id="Pfad_554"
                        data-name="Pfad 554"
                        d="M18,8H17V6A5,5,0,0,0,7,6V8H6a2.006,2.006,0,0,0-2,2V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,18,8ZM9,6a3,3,0,0,1,6,0V8H9Zm9,14H6V10H18Zm-6-3a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,17Z"
                        transform="translate(-4 -1)"
                        fill="#6a7284"
                      />
                    </svg>

                    <FormattedMessage
                      id="SeeNote"
                      defaultMessage="Who should be able to see this note?"
                    />
                  </div>
                  <div //style={{ marginLeft: "auto" }}
                  >
                    <MultiSelect
                      options={userNames}
                      value={selectedPrivateUserNames}
                      hasSelectAll={true}
                      className={"MultiSelectClassTwo"}
                      onChange={setSelectedPrivateUserNames}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              )}
            </div>

            {props.viewNote ? (
              <div style={{ padding: "15px" }} className="row d-flex">
                <div
                  className="d-flex flex-column"
                  style={{ paddingRight: "25px" }}
                >
                  Last edit:
                  <span>{Edited}</span>
                </div>
                <div className="d-flex flex-column">
                  Editor:
                  <span>{Creator}</span>
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              style={{ marginTop: "auto", marginLeft: "auto", width: "100%" }}
              className="row save"
            >
              {props.editNote ? (
                <div
                  className="d-flex flex-column"
                  style={{ padding: "0 25px" }}
                >
                  Last edit:
                  <span>{Edited}</span>
                </div>
              ) : (
                ""
              )}

              {props.editNote ? (
                <div className="d-flex flex-column">
                  Editor:
                  <span>{Creator}</span>
                </div>
              ) : (
                ""
              )}

              {props.viewNote && props.canEdit ? (
                <button
                  style={{ cursor: "pointer", marginLeft: "auto" }}
                  className="btn delete"
                  onClick={() => deleteNote(Title)}
                >
                  <FormattedMessage
                    id="DeleteNote"
                    defaultMessage="Delete note"
                  />
                </button>
              ) : (
                <button
                  style={{
                    cursor: "pointer",
                    marginLeft: "auto",
                    textTransform: "uppercase",
                  }}
                  className="btn cancel"
                  onClick={() => close()}
                >
                  <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                </button>
              )}

              {props.viewNote && props.canEdit ? (
                <button
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  className="btn edit"
                  onClick={() => edit()}
                >
                  <FormattedMessage
                    id="EditNoteBtn"
                    defaultMessage="Edit note"
                  />
                </button>
              ) : (
                ""
              )}

              {!saved && !props.viewNote ? (
                <Button
                  type="primary"
                  style={{
                    marginRight: "30px",
                    textTransform: "uppercase",
                    minWidth: "fit-content",
                  }}
                  className="btn saveButtonTasklist"
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    handleSave();
                  }}
                >
                  <FormattedMessage id="Save" defaultMessage="Save" />
                </Button>
              ) : (
                <button
                  style={{
                    marginRight: "30px",
                    textTransform: "uppercase",
                    minWidth: "fit-content",
                  }}
                  className="btn saveButtonTasklist"
                  onClick={() => close()}
                >
                  <FormattedMessage id="Done" defaultMessage="Done" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Note;
import { Redirect } from "react-router-dom";

const Logout = ({ history }) => {
    localStorage.removeItem("authToken");
    <Redirect to="/login" />

  return (
    <div className="login-screen">
     <Redirect to="/login" />
    </div>
  );
};

export default Logout;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Line } from "react-chartjs-2";

import 'chartjs-adapter-date-fns';
import { format, parse, addDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { de } from 'date-fns/locale';



const HistoryChart = (props) => {
  console.log("HistoryChart")
  console.log(props)
  console.log(props.history["startDate"])
  let newarr = []
  props.history[props.name].map(item => {
    newarr.push({
      x: parse(item["x"], 'yyyy-MM-dd', new Date()), y: item["y"],
    })
  })

  let startDate;
  if (props.history["startDate"] !== "") {
    startDate = parse(props.history["startDate"], 'yyyy-MM-dd', new Date())
  } else {
    const _todayStartday = new Date();
    console.log("Today Start Date")
    console.log(format(_todayStartday, 'yyyy-MM-dd'))
    startDate = parse(format(_todayStartday, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date())
  }
  let endDate = addDays(startDate,365)

  console.log(`startDate: ` + startDate)
  console.log(`endDate: ` + endDate)

  var options = {
    aspectRatio: 0,
    plugins: {
      legend: {
        display: false
      }
    },
    tension: .5,
    pointBorderWidth: 2,
    borderColor: "#194A96",
    responsive: true,
    scales: {
      xAxis: {
        align: 'start',
        includeBounds: true,
         maxTicksLimit: 13.1,
        count: 11, 
        type: 'time',
        time: {
          displayFormats: { 'day': 'MMM yy' },
          tooltipFormat: 'dd/MM/yy',
          unit: 'month',
        },
        grid: {
          display: false,
          drawBorder: false,
        }
        , ticks: {
          source: "auto",
          includeBounds: true,
          padding: 10,
          maxRotation: 20,
          minRotation: 20
        }
      },
      yAxis: {
        grid: {
          display: true,
          drawBorder: false
        }, ticks: {
          stepSize: 25,
          callback: function (value, index, values) {
            return value + '%';
          }
        }, max: 100,
        min: 0,
      },
    },
  }

  const data = {
       labels: 
    [startDate,endDate],



    datasets: [

      {


        data: newarr,
        fill: false,
        borderColor: "#194A96",
        pointRadius: 0,
      },

    ]
  };



  return (
    <div className="chart-container" style={{ position: 'relative', width: "100%", height: "200px" }}>
      <Line
        height={null} width={null}
        options={options} data={data} />
    </div>

  );
  /* }
  else {
  return ""
  }} */
}
export default HistoryChart

import React, { useEffect, useState } from 'react';
import { 
    withRouter, 
    useHistory, 
    useParams, 
    useLocation, 
    matchPath 
} from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import Collapsible from './Tasklist/ProjectTasklistCollapsible';
import CollapsibleWithout from './Tasklist/ProjectTasklistCollapsibleWithout';
import Owldemo5 from './reactcarousel5'
import ProjectTasklistSecondScreen from './Tasklist/ProjectTasklistSecondScreen';
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import { format, parse, addDays } from 'date-fns';
import CustomOption from './Translation/CustomOption';
import { toast } from "react-toastify";

const Milestone = (props) => {
    const intl = useIntl();
    const [firstTime, setfirstTime] = useState(true);
    const [Title, setTitle] = useState("");
    const [Questions, setQuestions] = useState([""]);
    const [userNames, setUserNames] = useState("");
    const [selectedUserNames, setSelectedUserNames] = useState([])
    const [startDate, setStartDate] = useState(0);
    const [dueDate, setDueDate] = useState(0);
    const [SelectOne, setSelectOne] = useState([])
    const [SelectDefault, setSelectDefault] = useState([])
    const [Tasks, setTasks] = useState([])
    const [saved, setSaved] = useState(true);
    const [saveable, setsaveable] = useState(true);

    let history = useHistory();
    const location = useLocation();
    const { projectId } = useParams();
    const projectPath = `/project/${projectId}`;

    useEffect(() => {
        if (!firstTime) {
            setSaved(false)
        }
    }, [selectedUserNames])
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };

    useEffect(() => {
        let answer = []
        const req = axios.get(`/api/private/userNames`, config)
            .then((response) => {
                let usernamesarr = []
                response.data.forEach(item => {
                    const editRights = (item.rights.EditProjects.find(item => item == props.idd) || item.rights.EditProjects == "all") ? true : false;
                    const seeRights = (item.rights.SeeProjects.find(item => item == props.idd) || item.rights.EditProjects == "all") ? true : false;
                    if (editRights && seeRights) {
                        let obj = {}
                        obj.value = item.username
                        obj.label = item.username
                        obj.id = item._id
                        usernamesarr.push(obj)
                    }
                })
                setUserNames(usernamesarr)
            })

        if (props.MilestonesComp["Title"]) {
            setTitle(props.MilestonesComp["Title"])
        }

        if (props.MilestonesComp["Questions"]) {
            setQuestions(props.MilestonesComp["Questions"])
        }

        if (props.MilestonesComp["Persons"]) {
            setSelectedUserNames(props.MilestonesComp["Persons"])
        }

        function getDate(e) {
            var year = e.substring(0, 4);
            var month = e.substring(4, 6);
            var day = e.substring(6, 8);
            return new Date(year, month - 1, day)
        }

        if (props.MilestonesComp["startDate"] && props.MilestonesComp["startDate"] !== "false") {
            setStartDate(getDate(props.MilestonesComp["startDate"]))
        }

        if (props.MilestonesComp["dueDate"] && props.MilestonesComp["dueDate"] !== "false") {
            setDueDate(getDate(props.MilestonesComp["dueDate"]))
        }

        let arr = []

        Object.entries(props.allTasks).map((entry) => {
            const [key, value] = entry;
            if (!value[1] && props.milestoneTasks?.Tasks.includes(key)) {
              arr.push({
                label: intl.locale != "en" ? <FormattedMessage id={value[3].replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '')} defaultMessage={value[3]} /> : value[3],
                value: key,
              });
            }
        });

        arr.unshift(
            { label: <FormattedMessage id="All" defaultMessage="All" />, value: "all" },
            { label: <FormattedMessage id="Business and Capacity Information" defaultMessage="1. Business and Capacity Information" />, value: "1." },
            { label: <FormattedMessage id="Facility + Equipment" defaultMessage="2. Facility + Equipment" />, value: "2." },
            { label: <FormattedMessage id="Business + Workshop Processes" defaultMessage="3. Business + Workshop Processes" />, value: "3." },
            { label: <FormattedMessage id="Repair Quality" defaultMessage="4. Repair Quality" />, value: "4." },
            { label: intl.locale != "en" ? <FormattedMessage id="Training" defaultMessage="5. Training" /> : "5. Training", value: "5." },
        )

        setTasks(arr);

        if (props.MilestonesComp["Tasks"]) {
            const matchingTasks = arr.filter(obj => props.MilestonesComp["Tasks"].includes(obj.value));
            setSelectOne(matchingTasks)
        }

        setTimeout(() => {
            setfirstTime(false)
        }, 1000)
    }, [])

    // useEffect(() => {
    //     const pattern = "/project/:projectId/milestones/:milestoneId";
    //     const match = matchPath(location.pathname, { path: pattern, exact: true });
    //     console.log("match:", match);
    //     if (match.isExact) {
    //         const { milestoneId } = match.params;
    //         console.log("milestoneId:", milestoneId);
    //         const milestone = props.MilestoneResultState
    //             .find((milestone) => milestone["_id"] === milestoneId);
    //         console.log("milestone:", milestone);
            
    //         if (milestone) {
    //             props.setMilestonesComp(milestone);
    //             props.setEditMilestone("true");
    //         }
    //     }
    // }, [location]);

    const isEnabled = Title.length > 0

    const selectStyles = {
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
          const indent = data.value.split('.').length * 15; // calculate indent based on number of periods in value
          return {
            ...styles,
            paddingLeft: indent, // apply padding to options
          };
        },
    }
    
    const handleChange = (selectedOptions) => {
        let updatedValues = new Set(); // Use a Set to store unique values
        if (selectedOptions) {
            selectedOptions.forEach(selectedOption => {
            let pattern = /^\d+\.(?=[^\s\d]|(\s(?=[^\s\d])))\s*\S/;
            
            // If the selected option is a parent value (e.g. "2. Facility + Equipment"), add all of its child objects to the selected values
            if (selectedOption.value.match(pattern)) {
                Tasks.forEach(question => {
                if (question.value.startsWith(selectedOption.value) && !question.value.includes(selectedOption.value + '.')) {
                    updatedValues.add(question.value); // Add each unique value to the Set
                }
                });
            } else {
                // If the selected option is a subquestion (e.g. "2.1 Facility New?, 2.1.1 Facility New?, 2.9.9.3 Facility New?"), check if it has any child subquestions
                const childSubquestions = Tasks.filter(question => {
                return question.value.startsWith(selectedOption.value);
                });

                 // If the selected option has child subquestions, add ONLY all child subquestions objects
                childSubquestions.forEach(question => {
                updatedValues.add(question.value); // Add each unique value to the Set
                });
            }
            });
        }
        
        const uniqueValues = Array.from(updatedValues, value => Tasks.find(task => task.value === value)); // Convert the Set to an array of unique objects
        
        setSelectOne(uniqueValues);
        setSaved(false);
    };

    const close = () => {
        function scroll() {
            window.scrollTo({ top: props.ScrollPos, behavior: 'smooth' })
        }
        props.setOnlyMilestone("false")
        props.setMilestoneTranslation([])
        props.setEditMilestone("false")
        props.setMilestonesComp(false)
        history.push(projectPath);    
    }

    const handleTextChange = (e) => {
        setTitle(e.target.value)
        var r = props.Milestones.some(el => {
            return el["Title"] == e.target.value
        })

        if (props.editMilestone) {
            if (e.target.value.length == 0 || r) {
                setsaveable(false)
            } else {
                setsaveable(true)
            }
        } else {
            if (e.target.value.length == 0) {
                setsaveable(false)
            }
        }
        setSaved(false)
    }

    const handleQuestion = (e, index) => {
        // update the corresponding question in the questions array
        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index] = e.target.value;
            return updatedQuestions;
        });

        if (props.editMilestone) {
            if (e.target.value.length == 0) {
                setsaveable(false)
            } else {
                setsaveable(true)
            }
        } else {
            if (e.target.value.length == 0) {
                setsaveable(false)
            }
        }
        setSaved(false)
    }

    const handleRemoveQuestion = (index) => {
        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions.splice(index, 1);
            return updatedQuestions;
        });
        setSaved(false)
    }

    
    const handleAddQuestion = () => {
        // append the new input to the parent element
        setQuestions(prevQuestions => [...prevQuestions, '']);
    }


    const deleteMilestone = (a, b) => {
        if (window.confirm("Do you really want to delete it?")) {
          let body = {
            Title: a
          }
          let e = props.idd

          const fetch = async () => {
            try {
              const DBGet = await axios.post(`/api/private/milestone/${e}`, body, config);
              props.setMilestones(prevState => {
                return prevState.filter((item, index) => {
                  return a !== item["Title"]
                })
              })
    
            } catch (e) {
              if (!props.dev) {
                localStorage.removeItem("authToken");
                window.location.href = '/logout';
              }
            }
          }
    
          fetch()
          close()
        } else {
          return
        }
      }

    const openModal = (e, b, allowed) => {
        setSaved(false)
        if (props.MilestonesComp["Tasks"]) {
            props.setTranslationModal(props.MilestonesComp["searchTasks"])
        } else {
            props.setTranslationModal(e)
        }
        props.setOnlyMilestone("true")
        if (allowed) {
            props.SetTranslationOnlyAllow(allowed)
        }
    }

    const changeDate = (e, b) => {
        if (b == "start") {
            setStartDate(e)
        } else {
            setDueDate(e)
        }
        setSaved(false)
    }

    const handleSave = (e) => {
        let a
        let b
        if (startDate !== 0 && startDate !== null) {
            a = format(startDate, "yyyyMMdd")
        } else {
            a = false
        }

        if (dueDate !== 0 && dueDate !== null) {
            b = format(dueDate, "yyyyMMdd")
        } else {
            b = false
        }

        let questionsArray = [""]
        if (Questions?.length > 0) {
            questionsArray = Questions.map((question, index) => {
                if (question[0] === 0) {
                    return question
                } else {
                    return [
                        0,
                        false,
                        false,
                        question,
                        false,
                        false,
                        0,
                        false,
                        null,
                        null,
                        null,
                        null,
                        false
                    ]
                }
            });
        }

        let body = {
            _id: props.MilestonesComp["_id"],
            ProjectID: props.idd,
            Title: Title,
            Tasks: SelectOne,
            Questions: questionsArray,
            Persons: selectedUserNames,
            startDate: a && a,
            dueDate: b && b
        }

        let bodyforAdd = {
            ProjectID: parseInt(props.idd),
            Title: Title,
            Tasks: SelectOne,
            Questions: questionsArray,
            Persons: selectedUserNames,
            startDate: a && a,
            dueDate: b && b
        }

        if (props.editMilestone == "true") {
            const sendToServer = async () => {
                // Update the state with the edited milestone immediately
                const originalMilestone = props.MilestonesComp;
                props.setMilestones((prevState) =>
                    prevState.map((item) => (item["_id"] === originalMilestone["_id"] ? body : item))
                );
                props.setMilestoneResultState((prevState) =>
                    prevState.map((item) => (item["_id"] === originalMilestone["_id"] ? body : item))
                );
        
                try {
                    const data = await axios.put(`/api/private/milestones/${props.idd}`, body, config);
                    // No need to update the state again since it's already updated
                    
                } catch (e) {
                    // Revert the state back to its original value if the request fails
                    props.setMilestones((prevState) =>
                        prevState.map((item) => (item["_id"] === originalMilestone["_id"] ? originalMilestone : item))
                    );
                    props.setMilestoneResultState((prevState) =>
                        prevState.map((item) => (item["_id"] === originalMilestone["_id"] ? originalMilestone : item))
                    );

                    toast.error(
                        intl.formatMessage({
                            id: "MilestoneEditFailed",
                            defaultMessage: "Milestone edition failed.",
                        }),
                        {
                            position: toast.POSITION.BOTTOM_CENTER,
                        }
                    );
                    close();
                }
            }
            sendToServer().then(item => {
                setSaved(true)
            })
        } else {
            const sendToServer = async () => {
                // Add the new milestone to the state immediately
                props.setMilestones((prevState) => [...prevState, bodyforAdd]);
                props.setMilestoneResultState((prevState) => [...prevState, bodyforAdd]);
                try {

                    const data = await axios.put(`/api/private/addmilestones/${props.idd}`, bodyforAdd, config);
                    // Update the state with the server response
                    if(data.data.message === "Milestone Duplication Found"){
                        toast.error(
                            intl.formatMessage({
                                id: "MilestoneTitleDuplication",
                                defaultMessage: "Milestone Title Duplication",
                            }),
                            {
                                position: toast.POSITION.BOTTOM_CENTER,
                            }
                        );
                    }
                    props.setMilestones((prevState) => {
                        const updatedMilestones = prevState.map((item) => (item === bodyforAdd ? data.data : item));
                        return updatedMilestones;
                    });

                    props.setMilestoneResultState((prevState) => {
                        const updatedMilestones = prevState.map((item) => (item === bodyforAdd ? data.data : item));
                        return updatedMilestones;
                    });
                }
                catch (e) {
                    // Revert the state back to its original value if the request fails
                    props.setMilestones((prevState) => prevState.filter((item) => item !== bodyforAdd));
                    props.setMilestoneResultState((prevState) => prevState.filter((item) => item !== bodyforAdd));

                    toast.error(
                        intl.formatMessage({
                            id: "MilestoneEditFailed",
                            defaultMessage: "Milestone creation failed.",
                        }),
                        {
                            position: toast.POSITION.BOTTOM_CENTER,
                        }
                    );
                    close();
                    // if (!props.dev) {
                    //     localStorage.removeItem("authToken");
                    //     window.location.href = '/logout';
                    // }
                }
            }
            sendToServer().then(item => {
                setSaved(true)
            })
        }
    }

    return (
        <>
            <div id="popup-1" className="d-block fq-modal">
                <div id="milestones" className={`fq-modal-content` + props.viewNote ? `slide-in` : `slide-out`}>
                    <div className="fq-modal-header">
                        <h1><FormattedMessage id="AddMilestone" defaultMessage="Add a new milestone" />  </h1>
                        <p><FormattedMessage id="MilestoneInstruction" defaultMessage="Please fill out the following form to create a new milestone" />  </p>
                    </div>
                    <div className="modal-content-wrapper" 
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "15px",
                        paddingBottom: "30px", 
                        overflowY: "auto"
                    }}>
                        <div className='row mobilePadding' style={{
                        width: "100%",
                    }}>
                            <div className='w-100'>
                                <div className='row mt-2 d-flex flex-column'>
                                    <div className='mb-3'>
                                        <FormattedMessage id="NewTitleMilestone" defaultMessage="Please enter a name for this milestone." />
                                    </div>
                                    <div className='mb-3'>
                                        <input required className="milestone-form-field form-control" placeholder="Add your title here..." value={Title} onChange={e => handleTextChange(e)} />
                                    </div>
                                </div>
                                <div className='row mt-2 d-flex flex-column'>
                                    <div className='mb-3'>
                                        <FormattedMessage id="SelectQuestions" defaultMessage="Please select a set of questions from the project." />
                                    </div>
                                    <div className='mb-3'>
                                    <Select
                                        value={SelectOne}
                                        className={"multiClass max-w-440"}
                                        isClearable={true}
                                        isMulti
                                        styles={selectStyles}
                                        onChange={(e) => handleChange(e)}
                                        options={Tasks}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        components={{ Option: CustomOption }}
                                        classNamePrefix="custom-select"
                                    >
                                    </Select>
                                    </div>
                                </div>
                                <div className='row mt-2 d-flex flex-column'>
                                    <div className='mb-3'>
                                        <FormattedMessage id="MilestonePerson" defaultMessage="Who is the responsible person for this milestone?" />
                                    </div>
                                    <div className='mb-3'>
                                        <div id="usernames">
                                            <MultiSelect
                                                options={userNames}
                                                value={selectedUserNames}
                                                hasSelectAll={false}
                                                className={"MultiSelectClassTwo"}
                                                onChange={setSelectedUserNames}
                                                labelledBy="Select"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2 d-flex flex-column'>
                                    <div className='mb-3'>
                                        <FormattedMessage id="MilestoneCustomQuestion" defaultMessage="Please add custom subtasks to this milestone." />
                                    </div>
                                    <div className='question'>
                                    {Questions.map((question, index) => (
                                        <div className='mb-3' key={index}>
                                            <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                                <input 
                                                className="milestone-form-field form-control" 
                                                placeholder="Subtask" 
                                                value={question && question[0] === 0 ? question[3] : question} 
                                                onChange={e => handleQuestion(e, index)} 
                                                />
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-danger btn-sm" 
                                                    style={{ border: "none", position: "absolute", right: "10px" }}
                                                    onClick={() => handleRemoveQuestion(index)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                    <div onClick={() => handleAddQuestion(Questions.length)} className="mb-4 pointer d-flex flex-column text-center">
                                        <i className="p-2 fas fa-plus"></i>Add additional custom subtask
                                    </div>
                                </div>
                                <div className='row mt-2 d-flex flex-column'>
                                    <div className='mb-3'>
                                        <FormattedMessage id="MilestoneDate" defaultMessage="Please select a Start and a Due Date." />
                                    </div>
                                    <div className='d-flex flex-row align-items-center'>
                                        <div>
                                            <FormattedMessage id="AssignStartDate" defaultMessage="Start Date" />
                                        </div>
                                        <div className="mx-2">
                                            <DatePicker
                                                placeholderText={intl.formatMessage({id: "SelectDate",defaultMessage: "MM/DD/YYYY"})}
                                                className="form-control"
                                                selected={startDate}
                                                onChange={(date) => changeDate(date, "start")}
                                                isClearable={true}
                                            />
                                        </div>
                                        <div>
                                            <FormattedMessage id="AssignDueDate" defaultMessage="Due Date" />
                                        </div>
                                        <div className="mx-2">
                                            <DatePicker
                                                placeholderText={intl.formatMessage({id: "SelectDate",defaultMessage: "MM/DD/YYYY"})}
                                                className="form-control"
                                                selected={dueDate}
                                                onChange={(date) => changeDate(date, "due")}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row save' style={{ marginTop: "auto", marginLeft: "auto", width: "100%" }}>
                                    {props.MilestonesComp["Title"] ? <button
                                        style={{ cursor: "pointer", marginLeft: "auto" }}
                                        className='btn delete'
                                        onClick={() => deleteMilestone(Title)}
                                        ><FormattedMessage id="DeleteMilestone" defaultMessage="Delete milestone" /></button> : ""}
                                    <button
                                        style={{ cursor: "pointer", marginLeft: "auto", textTransform: "uppercase" }}
                                        className='btn cancel'
                                        onClick={() => close()}
                                    ><FormattedMessage id="Cancel" defaultMessage="Cancel" /></button>
                                    {!saved ?
                                    <button
                                        style={{ marginRight: "30px", textTransform: "uppercase", minWidth: "fit-content" }}
                                        className='btn saveButtonTasklist'
                                        disabled={!isEnabled}
                                        onClick={() => handleSave()}><FormattedMessage id="Save" defaultMessage="Save" /></button>
                                    :
                                    <button
                                        style={{ marginRight: "30px", textTransform: "uppercase", minWidth: "fit-content" }}
                                        className='btn saveButtonTasklist'
                                        disabled={!isEnabled}
                                        onClick={() => close()}><FormattedMessage id="Done" defaultMessage="Done" /></button>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>





    );



}


export default Milestone
import { useState } from "react";
import Step1 from "./partials/step1";
import Step2 from "./partials/step2";
import Step3 from "./partials/step3";
import axios from "axios";
import { message } from "antd";
import { notification } from 'antd';

import { ToastContainer, toast } from "react-toastify";
const Index = (props) => {
  console.log(props);
  const [step, setStep] = useState(1);
  const [saveable, setSaveable] = useState(true);

  const [formData, setFormData] = useState({
    projectName: "",
    projectAddress: "",
    purposeAssessment: "",
    boPosition: "",
    assesstmentType: "",
    assessmentCurrency: "",
    boAware: "no",
    spAssessment: "no",
    businessRecommendation: "no",
    sprayBooths: 0,
    ventilatedPrepbays: 0,
    shareKpiData: "no",
    paintBrand: "",
    otherPaintBrand: "",
    commentCurrentSupplier: "",
    likeOldBrand: "",
    changingBasf: "",
    customerRentention: "",
    commentSupplier: "",
    bodyPaintLocation: "no",
    noTW: "yes",
  });

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    console.log("next: " + step);
    setStep(step + 1);
    console.log("next: " + step);
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleSelectType = (input) => (e) => {
    console.log("select value: " + e.value);
    setFormData((prevState) => ({
      ...prevState,
      [input]: e.value,
    }));
  };

  const handleCurrencyChange = (input, option) => {
    console.log("select value: " + option.value);
    setFormData((prevState) => ({
      ...prevState,
      [input]: option.value,
    }));
  }

  const handleRadioType = (input) => (e) => {
    console.log("select value: " + e.target.value);
    setFormData((prevState) => ({
      ...prevState,
      [input]: e.target.value,
    }));
  };

  const saveFormData = () => {
    const sendToServer = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      let result = 0;
      props.projectData.map((item) => {
        if (item.name == formData.projectName) {
          result++;
        }
      });
      console.log(result);
      if (result !== 0) {
        toast.error("Project Name already taken", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }

      const PostData = {
        formData,
      };
      console.log("formData:", formData);
      try {
        const data = await axios.post(
          `/api/private/copy-project`,
          PostData,
          config
        );
        console.log(data.data);
        console.log(data.data.message.success);
        if (data.data && data.data.message && data.data.message.success) {

          console.log("data.data.message.newProjectID");
          console.log(data.data.message.newProjectID);
          notification.success({
            message: 'Project Creation',
            description: 'Project is being created',
          });
          props.onHide();
          setTimeout(function () {
            window.location.reload(false);
            },6000)




          return;
        } else if (
          data.data &&
          data.data.message &&
          data.data.message.newProjectID &&
          data.data.message.success
        ) {
          let newProject = {
            projectName: formData.projectName,
            projectID: data.data.message.newProjectID,
            address: data.data.message.address,
            picture: "false",
            position: data.data.message.position
              ? data.data.message.position
              : {
                  lat: 0,
                  lng: 0,
                },

            percentage: {
              TotalAuditScore: 0,
              Sustainability: 0,
              FacilityEquipment: 0,
              BusinessWorkshopProcess: 0,
              RepairQuality: 0,
              Training: 0,
              BusinessInformation: 0,
            },
          };
          console.log("newProject");
          console.log(newProject);


          console.log("response:")
          console.log(props.projectData);








          console.log("data.data.message.newProjectID 2");
          console.log(data.data.message.newProjectID);
          axios
            .post(
              "/api/addrights/createeditproject",
              {
                projectID: data.data.message.newProjectID,
                username: localStorage.getItem("username"),
              },
              config
            )
            .then((result) => {
              console.log("rights results");
              console.log(result);
            });


          props.onHide();
          notification.success({
            message: 'Project Creation',
            description: 'Project is being created',
          });
          setTimeout(function () {
            window.location.reload(false);
            },6000)

          return;
        } else if (
          data.data.message.success === false &&
          data.data.message.msg === "DUPLICATE PROJECT NAME"
        ) {
          toast.error("ERROR: Project Name DUPLICATION", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return;
        }
        /* if (data.data) {
          setSuccess(true)
        } */
      } catch (e) {
        if (!props.dev) {
          console.log(e);
          /*   localStorage.removeItem("authToken");
           window.location.href = '/logout';   */
        }
      } finally {

      }
    };
    sendToServer().then(async () => {});

    //Add your code here
  };
  switch (step) {
    case 1:
      return (
        <>
        <ToastContainer toastClassName="top-zIndex" autoClose={5000} />
        <Step1
          saveable={saveable}
          projectData={props.projectData}
          show={props.show}
          onHide={props.onHide}
          nextStep={nextStep}
          handleFormData={handleInputData}
          handleSelect={handleSelectType}
          handleCurrencyChange={handleCurrencyChange}
          handleRadio={handleRadioType}
          values={formData}
          save={saveFormData}
        />
        </>
      );
    case 2:
      return (
        <Step2
          show={props.show}
          onHide={props.onHide}
          step={setStep}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          handleSelect={handleSelectType}
          handleRadio={handleRadioType}
          values={formData}
        />
      );
    case 3:
      return (
        <Step3
          show={props.show}
          onHide={props.onHide}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          handleSelect={handleSelectType}
          handleRadio={handleRadioType}
          values={formData}
          save={saveFormData}
        />
      );
    default:
      return <></>;
  }
};
export default Index;

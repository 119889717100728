import React, { useEffect, useState } from 'react';

import axios from 'axios';
import SpinnerMini from './spinnermini';
import importObject from '../sharedFunctions.js/importObject'
import { FormattedMessage } from 'react-intl';
import { Empty } from "antd"
const BaysNotAvailablePaint = (props) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };
    const handleClick = (name) => {
        if(!props.noTeamwork) {
            props.SetDeleteable(false)
            props.SetWaitingForResponse(true)
        }
        if(props.noTeamwork) {
            props.SetDeleteable(false)
            props.SetWaitingForResponse(true)
        }
        
        console.log(props.FinalArray[props.whichArray])
        let lastBayIndex = (props.tasks[props.FinalArray[props.whichArray][0]][2].length - 1)
        let lastBayName = props.tasks[props.FinalArray[props.whichArray][0]][2][lastBayIndex]
        let NewBayNumber = lastBayName
        NewBayNumber = NewBayNumber.split('.')
        console.log(props)
        console.log(props.tasks[props.FinalArray[props.whichArray][0]])
        let PostData = {
            baytype: props.Name,
            parentTaskID: props.tasks[props.FinalArray[props.whichArray][0]][0],
            currentTaskID: props.tasks[props.tasks[props.FinalArray[props.whichArray][0]][2][0]][0],
            projectID: props.idd,
            tasklistNumber: props.TasklistNumber,
            keyOfTask: props.FinalArray[props.whichArray][1][0],
            parentTaskKey: props.FinalArray[props.whichArray][0],
        }
        console.log(PostData)
        async function send() {
            try {
                const data = await axios.post(`/api/private/queue/baycreate`, PostData, config);
                console.log(data.data)
                props.SetWaitingForResponse(false)
                if (data.data.tasklist) {
                    if(props.noTeamwork) {
                        props.SetDeleteable(true)
                        props.SetWaitingForResponse(false)
                    }
                    console.log(data.data.tasklist)
                    props.setTasks(prevState => (
                        data.data.tasklist
                    ))
                } else {
                    props.setTasks(prevState => ({
                        ...prevState,
                        [props.FinalArray[props.whichArray][0]]: newArr,
                        [NewBayNumber]: [false, false, [NewBayNumber + ".1"], NewBayNumber, false, false, 0, name, null, null, null, null],
                        [NewBayNumber + ".1"]: [false, false, false, NewBayNumber + ".1", false, false, 0, "Loading", null, null, null, null,]
                    }))
                }
                return data
            } catch (e) {
                if (!props.dev) {
                    localStorage.removeItem("authToken");
                    window.location.href = '/logout';
                }
            }
        }
        let NewIndex = NewBayNumber[NewBayNumber.length - 1]
        NewIndex = (parseInt(NewIndex) + 1)
        NewBayNumber[NewBayNumber.length - 1] = NewIndex
        NewBayNumber = NewBayNumber.join(".")
        let newArr = props.tasks[props.FinalArray[props.whichArray][0]]
        newArr[2] = [...props.tasks[props.FinalArray[props.whichArray][0]][2], NewBayNumber]
        props.SetDeleteable(true)

        send().then(item => {
        })
    }


    return (
        <>
            <div className="row heading-block">
                <div className="col-lg-12 pl-0">
                    <h1 className="main-heading d-flex align-items-center">{props.Name}
                    {props.canEdit && !props.WaitingForResponse && props.Addable ?
                            <div>
                                <i onClick={() => handleClick(props.Name)} style={{ fontSize: "30px", marginLeft: "20px", cursor: "pointer" }} class="fas fa-plus-circle"></i>
                            </div>
                            :
                           <SpinnerMini />
                        }
                    </h1>
                </div>
            </div>
            <div className="container-fluid pl-0 empty-container">
            <FormattedMessage id={"No" + props.Name} defaultMessage={"No " + props.Name} />

            </div>
        </>
    );

}


export default BaysNotAvailablePaint;
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "./Tooltip";
import SpinnerMini from "../spinnermini";
import axios from "axios";
import { useIntl, FormattedMessage } from "react-intl";
import CameraBurstShotUpload, {
  detectDeviceType,
} from "../../burstCameraUpload/cameraBurstShotUpload";
import AsyncSelect from "react-select/async";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import ProjectTasklistCheckbox from "./ProjectTasklistCheckbox";

const Collapsible = (props) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  const intl = useIntl();
  const [isOpenCamera, setOpenCamera] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isYesNoAnswer, setisYesNoAnswer] = useState();
  const [isYesNo, setIsYesNo] = useState(false);
  const [TextField, setTextField] = useState("");
  const [FilesField, setFilesField] = useState(false);
  const [ThumbsUp, setThumbsUp] = useState(false);
  const [Thumbs, setThumbs] = useState("none");
  const [Display, setDisplay] = useState(true);
  const [saved, setSaved] = useState(true);
  const [lockedForEditing, setlockedForEditing] = useState(false);
  const [TooltipState, setTooltipState] = useState(false);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const [Results, setResults] = useState([{ value: "", label: "" }]);
  const [checkbox, setCheckbox] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [dropdownTwo, setDropdownTwo] = useState(false);
  const [checked, setChecked] = useState([]);

  const { parentChecked, onCheckboxChange } = props;

  useEffect(() => {
    if (parentChecked) {
      setChecked(parentChecked);
    }
  }, [parentChecked]);

  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (props.allTasks[props.item] !== undefined) {
      if (
        props.allTasks[props.item][1] ||
        props.allTasks[props.item][10] === 100
      ) {
        setThumbs("Up");
        setThumbsUp(true);
        setlockedForEditing(true);
      }

      if (
        props.allTasks[props.item] !== undefined &&
        props.allTasks[props.item][10] === 20
      ) {
        setThumbs("Down");
      }
      if (
        props.allTasks[props.item] !== undefined &&
        props.allTasks[props.item][12] &&
        props.allTasks[props.item][12].includes(58572)
      ) {
        setIsYesNo(true);
        if (props.allTasks[props.item][7] === "yes") {
          setisYesNoAnswer("yes");
        } else if (props.allTasks[props.item][7] === "no") {
          setisYesNoAnswer("no");
        }
      }
      if (
        props.allTasks[props.item] !== undefined &&
        props.allTasks[props.item][12] &&
        props.allTasks[props.item][12].includes(59302)
      ) {
        console.log("test");
        setDropdownTwo(true);
      }
    }
  }, [props.allTasks]);

  let body = {
    ProjectID: props.idd,
    Task: props.item[0],
  };
  const handleChange = (event) => {
    if (props.noTeamwork) {
      console.log("test");
      //console.log(props.allTasks[props.item])
      const fetchData = () => {
        return axios
          .get(
            `/api/private/customFields/${props.allTasks[props.item][0][0]}`,
            config
          )
          .then((result) => {
            let data = result.data.customFields;
            let results = [];
            for (var prop in data) {
              data[prop].options.choices.map((item) => {
                results.push({ value: item.value, label: item.value });
              });
              break;
            }
            setResults(results);
            return results;
          });
      };
      fetchData();
    } else {
      const fetchData = () => {
        return axios
          .get(
            `/api/private/customFields/${props.allTasks[props.item][0]}`,
            config
          )
          .then((result) => {
            let data = result.data.customFields;
            let results = [];
            for (var prop in data) {
              data[prop].options.choices.map((item) => {
                results.push({ value: item.value, label: item.value });
              });
              break;
            }
            setResults(results);
            return results;
          });
      };
      fetchData();
    }
  };

  const saveAsComment = (e) => {
    console.log("saveAsComment ProjectTasklist");
    const NameOfTask = props.allTasks[props.item][0];
    let progress;
    progress = 100;
    const PostData = {
      NameOfTask: NameOfTask,
      Textbody: e.value,
      Progress: progress,
      keyOfTask: props.item,
      activityUrl: location.pathname,
    };
    PostData["ProjectID"] = props.idd;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    const req = axios
      .post(`/api/private/queue/comments/${NameOfTask}`, PostData, config)
      .then((response) => {
        console.log("Comments response");
        console.log(response);
        setSaved(true);
        setloadingSpinner(false);
        let newItem = [...props.allTasks[props.item]];
        newItem[7] = e.value;
        newItem[1] = true;
        newItem[10] = 100;
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.item]: newItem,
        }));
      });
  };

  const hiddenFileInput = useRef(null);

  const handleClickHidden = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOpenCamera = (event) => {
    setOpenCamera(true);
  };

  const handleChangePicture = (event) => {
    setFilesField(event.target.files[0]);
    setSaved(false);
  };

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const handleTextChange = (e) => {
    setSaved(false);
    setTextField(e.target.value);
  };

  const handleUnSafe = (item) => {
    setloadingSpinner(true);
    const NameOfTask = props.allTasks[props.item][0];
    const PostData = {
      NameOfTask: NameOfTask,
      keyOfTask: props.item,
      activityUrl: location.pathname,
    };
    if (props.allTasks[props.item][10]) {
      PostData["progress"] = props.allTasks[props.item][10];
    } else {
      PostData["finish"] = props.allTasks[props.item][1];
    }
    PostData["ProjectID"] = props.idd;
    const sendToServer = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        };
        const data = await axios.put(
          `/api/private/queue/unfinishtask/${NameOfTask}`,
          PostData,
          config
        );
        return data;
      } catch (e) {
        // if (!props.dev) {
        //   localStorage.removeItem("authToken");
        //   window.location.href = '/logout';
        // }
        console.log("error:" + e);
      } finally {
      }
    };
    sendToServer().then((item) => {
      setSaved(true);
      setloadingSpinner(false);
      setlockedForEditing(true);
      setThumbs("none");
      let newItem = [...props.allTasks[props.item]];
      newItem[1] = false;
      if (newItem[10]) {
        newItem[10] = 0;
      }
      props.setAllTasks((prevState) => ({
        ...prevState,
        [props.item]: newItem,
      }));
    });
  };

  const handleSave = (status) => {
    console.log("handle save");
    if (status === "Up") {
      setThumbs("Up");
    } else if (status === "Down") {
      setThumbs("Down");
    }
    setloadingSpinner(true);
    const handleFinishTask = (e) => {
      const NameOfTask = props.allTasks[props.item][0];
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.item,
        activityUrl: location.pathname,
      };
      console.log("props:", props);
      PostData["ProjectID"] = props.idd;
      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(
            `/api/private/queue/finishtask/${NameOfTask}`,
            PostData,
            config
          );
          return data;
        } catch (e) {
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
          console.log("error:" + e);
        } finally {
        }
      };
      sendToServer().then((item) => {
        let newItem = [...props.allTasks[props.item]];
        newItem[1] = true;
        newItem[10] = 100;
        if (TextField && TextField !== "") {
          newItem[7] = TextField;
        }
        if (FilesField) {
          newItem[20] = "reload to see new pictures";
        }
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.item]: newItem,
        }));
        setSaved(true);
        setloadingSpinner(false);
      });
    };

    const handleProgressTask = (e) => {
      console.log("handleProgressTask");
      const NameOfTask = props.allTasks[props.item][0];
      const PostData = {
        NameOfTask: NameOfTask,
        keyOfTask: props.item,
        activityUrl: location.pathname,
      };
      PostData["ProjectID"] = props.idd;
      const sendToServer = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
          const data = await axios.put(
            `/api/private/queue/progressTask/${NameOfTask}`,
            PostData,
            config
          );
          return data;
        } catch (e) {
          // if (!props.dev) {
          //   localStorage.removeItem("authToken");
          //   window.location.href = '/logout';
          // }
          console.log("error:" + e);
        } finally {
        }
      };
      sendToServer().then((item) => {
        let newItem = [...props.allTasks[props.item]];
        newItem[10] = 20;
        props.setAllTasks((prevState) => ({
          ...prevState,
          [props.item]: newItem,
        }));
        setSaved(true);
        setloadingSpinner(false);
      });
    };

    const handleFileSave = (e) => {
      console.log("handleFileSave");
      const data = new FormData();
      if (TextField && TextField !== "") {
        data.append("text", TextField);
      }
      if (status === "Up") {
        data.append("progress", "100");
      } else {
        data.append("progress", "20");
      }
      data.append("keyOfTask", props.item);
      data.append("ProjectID", props.idd);
      data.append("activityUrl", location.pathname);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      data.append("file", FilesField);
      const NameOfTask = props.allTasks[props.item][0];
      axios
        .post(`/api/private/queue/uploadimg/${NameOfTask}`, data, config)
        .then(
          (response) => {
            setFilesField("");

            setSaved(true);
            setloadingSpinner(false);
            if (status === "Down") {
              let newItem = [...props.allTasks[props.item]];
              newItem[10] = 20;

              newItem[20] = URL.createObjectURL(FilesField);
              if (TextField && TextField !== "") {
                newItem[7] = TextField;
              }

              props.setAllTasks((prevState) => ({
                ...prevState,
                [props.item]: newItem,
              }));
            } else {
              let newItem = [...props.allTasks[props.item]];
              newItem[1] = true;
              newItem[10] = 100;
              console.log(URL.createObjectURL(FilesField));
              newItem[20] = URL.createObjectURL(FilesField);
              if (TextField && TextField !== "") {
                newItem[7] = TextField;
              }
              props.setAllTasks((prevState) => ({
                ...prevState,
                [props.item]: newItem,
              }));
            }
            return response.data;
          },
          (error) => {
            console.log("error:" + e);
            // if (!props.dev) {
            //   localStorage.removeItem("authToken");
            //   window.location.href = '/logout';
            // }
          }
        );
    };

    const handleSaveComment = (e) => {
      console.log("handleSaveComment");
      const NameOfTask = props.allTasks[props.item][0];
      console.log("NameofTask" + NameOfTask);
      let progress;
      if (status === "Up") {
        progress = 100;
      } else {
        progress = 20;
      }
      const PostData = {
        NameOfTask: NameOfTask,
        Textbody: TextField,
        Progress: progress,
        keyOfTask: props.item,
        activityUrl: location.pathname,
      };
      console.log("POSTDATA");
      console.log(PostData);
      if (isYesNo) {
        PostData["Textbody"] = status;
        console.log(status);
        if (status === "yes") {
          PostData["progress"] = 100;
        } else {
          PostData["progress"] = 20;
        }
        console.log(PostData);
      }
      PostData["ProjectID"] = props.idd;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };
      const req = axios
        .post(`/api/private/queue/comments/${NameOfTask}`, PostData, config)
        .then(
          (response) => {
            console.log("Comments response");
            console.log(response);
            setTextField("");
            setSaved(true);
            setloadingSpinner(false);
            if (isYesNo) {
              let newItem = [...props.allTasks[props.item]];
              newItem[7] = status;
              if (status == "yes") {
                newItem[1] = true;
                newItem[10] = 100;
              } else {
                progress = 20;
              }
              props.setAllTasks((prevState) => ({
                ...prevState,
                [props.item]: newItem,
              }));
            } else if (status === "Down" && !FilesField) {
              let newItem = [...props.allTasks[props.item]];
              newItem[10] = 20;
              if (isYesNo) {
                newItem[7] = status;
                if (status == "yes") {
                  newItem[1] = true;
                  newItem[10] = 100;
                } else {
                  progress = 20;
                }
              } else {
                newItem[7] = TextField;
              }
              props.setAllTasks((prevState) => ({
                ...prevState,
                [props.item]: newItem,
              }));
            } else if (status === "Up" && !FilesField) {
              let newItem = [...props.allTasks[props.item]];
              newItem[1] = true;
              newItem[10] = 100;
              newItem[7] = TextField;
              props.setAllTasks((prevState) => ({
                ...prevState,
                [props.item]: newItem,
              }));
            }
            return response;
          },
          (error) => {
            // if (!props.dev) {
            //   localStorage.removeItem("authToken");
            //   window.location.href = '/logout';
            // }
            console.log("error:" + error);
          }
        );
    };

    if (isYesNo) {
      setloadingSpinner(true);
      handleSaveComment();
    }

    if (FilesField && FilesField !== "") {
      handleFileSave();
    } else if (TextField && TextField !== "") {
      handleSaveComment();
    } else {
      if (status === "Up") {
        console.log("test");
        handleFinishTask();
      } else if (status === "Down") {
        handleProgressTask();
      }
    }
  };

  const handleCheckboxChange = useCallback((taskId, isChecked) => {
    const updateCheckedItems = (checked, taskId, isChecked) => {
      const checkedItems = new Set(checked);
      const stack = [taskId];
  
      while (stack.length) {
        const currentTaskId = stack.pop();
  
        if (isChecked) {
          checkedItems.add(currentTaskId);
          props.setAddToMilestone((prevSelected) => [...prevSelected, taskId]);
        } else {
          checkedItems.delete(currentTaskId);
          props.setAddToMilestone((prevSelected) =>
            prevSelected.filter((id) => id !== taskId)
          );
        }
  
        const task = props.allTasks[currentTaskId];
  
        if (task && task[2]) {
          for (let childTaskId of task[2]) {
            stack.push(childTaskId);
          }
        }
      }
      return [...checkedItems];
    };
  
    setChecked((prevChecked) => {
      const newCheckedItems = updateCheckedItems(prevChecked, taskId, isChecked);
      if (props.onCheckboxChange) {
        props.onCheckboxChange(taskId, isChecked);
      }
      return newCheckedItems;
    });
  }, [checked, props.allTasks, props.setAddToMilestone]);

  let testIfOpenChildren = false;
  if (props.Filter === "Uncompleted") {
    if (
      props.allTasks[props.item] !== undefined &&
      props.allTasks[props.item][2] !== false
    ) {
      props.allTasks[props.item][2].map((element) => {
        function checkchildren(el) {
          if (
            props.allTasks[el] !== undefined &&
            props.allTasks[el][2] !== false
          ) {
            if (props.allTasks[el][2]) {
              props.allTasks[el][2].map((item) => {
                checkchildren(item);
              });
            }
          } else if (
            !props.TaskListComp.includes(el) ||
            props.allTasks[el][10] == 100 ||
            props.allTasks[el][1] ||
            !props.allTasks[el][12].some((element) =>
              props.FinalTags.includes(element)
            )
          ) {
            return;
          } else {
            testIfOpenChildren = true;
          }
        }
        checkchildren(element);
      });
    }
  }

  if (props.Filter === "Completed") {
    if (
      props.allTasks[props.item] !== undefined &&
      props.allTasks[props.item][2] !== false
    ) {
      props.allTasks[props.item][2].map((element) => {
        function checkchildren(el) {
          if (
            props.allTasks[el] !== undefined &&
            props.allTasks[el][2] !== false
          ) {
            props.allTasks[el][2].map((item) => {
              checkchildren(item);
            });
          } else if (
            !props.TaskListComp.includes(el) ||
            !props.allTasks[el][10] === 100 ||
            !props.allTasks[el][1] ||
            !props.allTasks[el][12].some((element) =>
              props.FinalTags.includes(element)
            )
          ) {
            return;
          } else {
            testIfOpenChildren = true;
          }
        }
        checkchildren(element);
      });
    }
  }

  if (props.Filter === "Audited") {
    if (
      props.allTasks[props.item] !== undefined &&
      props.allTasks[props.item][2] !== false
    ) {
      props.allTasks[props.item][2].map((element) => {
        function checkchildren(el) {
          if (
            props.allTasks[el] !== undefined &&
            props.allTasks[el][2] !== false
          ) {
            props.allTasks[el][2].map((item) => {
              checkchildren(item);
            });
          } else if (
            !props.TaskListComp.includes(el) ||
            !(
              props.allTasks[el][10] == 100 ||
              props.allTasks[el][1] ||
              props.allTasks[el][10] == 20
            ) ||
            !props.allTasks[el][12].some((element) =>
              props.FinalTags.includes(element)
            )
          ) {
            return;
          } else {
            testIfOpenChildren = true;
          }
        }
        checkchildren(element);
      });
    }
  }

  if (props.Filter === "Not-Audited") {
    if (
      props.allTasks[props.item] !== undefined &&
      props.allTasks[props.item][2] !== false
    ) {
      props.allTasks[props.item][2].map((element) => {
        function checkchildren(el) {
          if (
            props.allTasks[el] !== undefined &&
            props.allTasks[el][2] !== false
          ) {
            props.allTasks[el][2].map((item) => {
              checkchildren(item);
            });
          } else if (
            !props.TaskListComp.includes(el) ||
            props.allTasks[el][10] == 100 ||
            props.allTasks[el][1] ||
            props.allTasks[el][10] == 20 ||
            !props.allTasks[el][12].some((element) =>
              props.FinalTags.includes(element)
            )
          ) {
            return;
          } else {
            testIfOpenChildren = true;
          }
        }
        checkchildren(element);
      });
    }
  }

  const handleThumbsUp = (item) => {
    setThumbsUp((prev) => !prev);
    setSaved(false);
  };

  const Rename = (name) => {
    name = name.split(" ");
    name.shift();
    name = name.join(" ");
    return name;
  };
  const GetCodeRename = (name) => {
    name = name.split(" ");
    name = name[0];
    return name;
  };

  const handleYesNo = (answer) => {
    console.log(answer);
    setisYesNoAnswer(answer);
    setSaved(false);
    handleSave(answer);
  };

  const handleCheckbox = (e) => {
    setCheckbox((prev) => !prev);
  };

  if (props.Filter === "Uncompleted") {
    if (props.allTasks[props.item] !== undefined) {
      return (
        <>
          {(isYesNo &&
            props.allTasks[props.item][12].some((element) =>
              props.FinalTags.includes(element)
            ) &&
            ((props.allTasks[props.item][7] == "yes" && testIfOpenChildren) ||
              props.allTasks[props.item][7] == "" ||
              !props.allTasks[props.item][7] ||
              props.allTasks[props.item][7] == "no")) || //&& testIfOpenChildren
          (!isYesNo &&
            props.allTasks[props.item][2] &&
            props.allTasks[props.item][10] !== 100 &&
            !props.allTasks[props.item][1] &&
            testIfOpenChildren) ? (
            <>
              <div className="borderSecure"></div>
              <div
                className="TaskListRow TaskListRowParent TaskListRowParent-top"
                style={{ paddingLeft: props.margin + 30 + "px" }}
              >
              <div className="form-group form-check">
              <ProjectTasklistCheckbox
                key={props.item}
                item={props.item}
                allTasks={props.allTasks}
                checked={checked || []}
                onCheckboxChange={handleCheckboxChange || (() => {})}
              />
              </div>
                <div onClick={handleFilterOpening} className="tasklist-label">
                  {isYesNoAnswer !== "no" ? (
                    <i
                      className={
                        !isOpen
                          ? "fas fa-caret-right task-icon-caret"
                          : "fas fa-caret-down task-icon-caret"
                      }
                      onClick={handleFilterOpening}
                    />
                  ) : (
                    <span className="task-icon-caret-empty">&nbsp;</span>
                  )}

                  <p className="taskListRowParent-name">
                    {GetCodeRename(props.allTasks[props.item][3])}&nbsp;
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                  </p>
                </div>
                {props.canEdit && (
                  <div className="tasklist-cta margin-left-auto">
                    {isYesNo ? (
                      <>
                        {loadingSpinner ? (
                          <SpinnerMini />
                        ) : (
                          <>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklistSave hoverSave"
                                  : "btn saveButtonTasklist"
                              }
                              onClick={() => handleYesNo("yes")}
                            >
                              <FormattedMessage id="Yes" defaultMessage="Yes" />
                            </button>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklist"
                                  : "btn saveButtonTasklistSave hoverSave"
                              }
                              onClick={() => handleYesNo("no")}
                            >
                              <FormattedMessage id="No" defaultMessage="No" />
                            </button>
                          </>
                        )}
                      </>
                    ) : dropdownTwo ? (
                      <div id="tasklistDrop" className="tasklist-dropdown-1">
                        <Select
                          placeholder={
                            props.allTasks[props.item][7]
                              ? props.allTasks[props.item][7]
                              : "please choose"
                          }
                          options={Results}
                          onMenuOpen={() => handleChange()}
                          onChange={(e) => saveAsComment(e)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </>
          ) : props.TaskListComp.includes(props.item) &&
            !props.allTasks[props.item][2] &&
            props.allTasks[props.item][10] !== 100 &&
            !props.allTasks[props.item][1] &&
            Display === true &&
            props.shouldNotShow !== "true" ? (
            <>
              <div className="borderSecure"></div>
              <div
                className="TaskListRow tasklistrow-2 cursor-pointer pos-rel"
                style={{ paddingLeft: props.margin + 10 + "px" }}
              >
                <div className="form-group form-check">
                <ProjectTasklistCheckbox
                  key={props.item}
                  item={props.item}
                  allTasks={props.allTasks}
                  checked={checked || []}
                  onCheckboxChange={handleCheckboxChange || (() => {})}
                />
                </div>
                <div className="tasklist-inner">
                  <div
                    className="tasklist-col-1"
                    onClick={props.canEdit ? () => {
                      // props.setSecondScreen(props.item)
                      history.push(`${location.pathname}/${props.item}`);
                    } 
                    : undefined}
                  >
                    {props.allTasks[props.item][3] !== false &&
                    props.allTasks[props.item][3] !== null ?
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                    : null }
                  </div>
                  <div className="tasklist-col-2">
                    {/*   <div className="counter-wrapper">
                      <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                      <div className="counter">0</div>
                    </div>
                    <div className="counter-wrapper">
                      <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                      <div className="counter">0</div>
                    </div> */}
                    {props.allTasks[props.item][11] && (
                      <>
                        <i
                          onClick={() => setTooltipState(true)}
                          className="fas fa-info-circle tooltipOne tasklist-icon-info"
                        ></i>
                        {TooltipState ? (
                          <Tooltip
                            setTooltipState={setTooltipState}
                            content={props.allTasks[props.item][11]}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {props.canEdit && (
                    <>
                      {props.canEdit && loadingSpinner ? (
                        <div className="loading-tasklist-icon">
                          <SpinnerMini />
                        </div>
                      ) : dropdownTwo ? (
                        <div className="tasklist-dropdown-wrapper d-flex margin-left-auto">
                          <div
                            id="tasklistDrop"
                            className="tasklist-dropdown-inner"
                          >
                            <Select
                              placeholder={
                                props.allTasks[props.item][7]
                                  ? props.allTasks[props.item][7]
                                  : "please choose"
                              }
                              options={Results}
                              onMenuOpen={() => handleChange()}
                              onChange={(e) => saveAsComment(e)}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="d-flex margin-left-auto">
                            <input
                              className="tasklist-input-1"
                              placeholder="Add your comment here..."
                              value={TextField}
                              onChange={(e) => handleTextChange(e)}
                            />
                            {!FilesField ? (
                              <></>
                            ) : (
                              <i
                                onClick={() => setFilesField(false)}
                                className="far fa-trash-alt tasklist-delete-1"
                              />
                            )}

                            <i
                              onClick={
                                Thumbs === "Down"
                                  ? undefined
                                  : () => handleSave("Down")
                              }
                              className={
                                Thumbs === "Down"
                                  ? "far fa-thumbs-down RedIMG task-thumb-down"
                                  : "far fa-thumbs-down task-thumb-down"
                              }
                            />
                            <i
                              onClick={() => handleSave("Up")}
                              className={
                                Thumbs === "Up"
                                  ? "far fa-thumbs-up greenIMG task-thumb-up"
                                  : "far fa-thumbs-up task-thumb-up"
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {isOpen &&
            props.allTasks[props.item][2] &&
            props.allTasks[props.item][2].map((item, index) => (
              <>
                <Collapsible
                  parentChecked={checked}
                  onCheckboxChange={handleCheckboxChange}
                  noTeamwork={props.noTeamwork}
                  saved={saved}
                  shouldNotShow={
                    isYesNoAnswer === "no" ||
                    (props.shouldNotShow && props.shouldNotShow === "true")
                      ? "true"
                      : "false"
                  }
                  idd={props.idd}
                  Tags={props.Tags}
                  dev={props.dev}
                  FinalTags={props.FinalTags}
                  TaskListComp={props.TaskListComp}
                  setModalImage={props.setModalImage}
                  setCurrentImg={props.setCurrentImg}
                  setSecondScreen={props.setSecondScreen}
                  Filter={props.Filter}
                  setEl={props.setEl}
                  margin={props.margin + 20}
                  setAllTasks={props.setAllTasks}
                  addToMilestone={props.addToMilestone} 
                  setAddToMilestone={props.setAddToMilestone}
                  allTasks={props.allTasks}
                  canEdit={props.canEdit}
                  index={index}
                  item={item}
                  section={props.section}
                />
              </>
            ))}

          {isOpenCamera === true && detectDeviceType() === "Mobile" && (
            <CameraBurstShotUpload
              OpenCameraStatus={() => setOpenCamera(false)}
            />
          )}
        </>
      );
    } else {
      return "";
    }
  } else if (props.Filter === "Completed") {
    if (props.allTasks[props.item] !== undefined) {
      return (
        <>
          {(isYesNo &&
            props.allTasks[props.item][12].some((element) =>
              props.FinalTags.includes(element)
            ) &&
            (props.allTasks[props.item][1] ||
              props.allTasks[props.item][10] == "100")) ||
          (props.allTasks[props.item][2] && testIfOpenChildren) ? (
            <>
              <div className="borderSecure"></div>
              <div
                className="TaskListRow TaskListRowParent TaskListRowParent-top"
                style={{ paddingLeft: props.margin + 20 + "px" }}
              >
                {/*   <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" />
              </div> */}
                <div onClick={handleFilterOpening}>
                  {isYesNoAnswer !== "no" ? (
                    <i
                      className={
                        !isOpen
                          ? "fas fa-caret-right task-icon-caret"
                          : "fas fa-caret-down task-icon-caret"
                      }
                      onClick={handleFilterOpening}
                    />
                  ) : (
                    <span className="task-icon-caret-empty">&nbsp;</span>
                  )}

                  <p
                    style={{
                      alignSelf: "center",
                      marginBottom: "0px",
                      fontWeight: "bold",
                      display: "inline-block",
                    }}
                  >
                    {GetCodeRename(props.allTasks[props.item][3])}&nbsp;
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                  </p>
                </div>
                {props.canEdit && (
                  <div className="margin-left-auto">
                    {isYesNo ? (
                      <>
                        {loadingSpinner ? (
                          <SpinnerMini />
                        ) : (
                          <>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklistSave hoverSave"
                                  : "btn saveButtonTasklist"
                              }
                              onClick={() => handleYesNo("yes")}
                            >
                              <FormattedMessage id="Yes" defaultMessage="Yes" />
                            </button>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklist"
                                  : "btn saveButtonTasklistSave hoverSave"
                              }
                              onClick={() => handleYesNo("no")}
                            >
                              <FormattedMessage id="No" defaultMessage="No" />
                            </button>
                          </>
                        )}
                      </>
                    ) : dropdownTwo ? (
                      <div
                        id="tasklistDrop"
                        className="tasklist-dropdown-wrapper d-flex margin-left-auto"
                      >
                        <Select
                          placeholder={
                            props.allTasks[props.item][7]
                              ? props.allTasks[props.item][7]
                              : "please choose"
                          }
                          options={Results}
                          onMenuOpen={() => handleChange()}
                          onChange={(e) => saveAsComment(e)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </>
          ) : props.TaskListComp.includes(props.item) &&
            !props.allTasks[props.item][2] &&
            (props.allTasks[props.item][10] == 100 ||
              props.allTasks[props.item][1]) &&
            Display === true ? (
            <>
              <div className="borderSecure"></div>
              <div
                className="TaskListRow"
                style={{
                  paddingLeft: props.margin + "px",
                  position: "relative",
                }}
              >
                {/*    <div className="form-group form-check">
                  <input type="checkbox" className="form-check-input" />
                </div> */}
                <div className="tasklist-inner">
                  <div
                    className="tasklist-col-1"
                    onClick={props.canEdit ? () => {
                      // props.setSecondScreen(props.item)
                      history.push(`${location.pathname}/${props.item}`);
                    } 
                    : undefined}
                  >
                    {props.allTasks[props.item][3] !== false &&
                    props.allTasks[props.item][3] !== null ?
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                    : ""
                    }
                  </div>
                  <div className="tasklist-col-1">
                    {props.allTasks[props.item][7] !== false &&
                    props.allTasks[props.item][7] !== null ?
                    <FormattedMessage
                      id={props.allTasks[props.item][7].toString()}
                      defaultMessage={props.allTasks[props.item][7]}
                    />
                    : null }
                  </div>
                  <div className="tasklist-col-2">
                    {/*   <div className="counter-wrapper">
                      <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                      <div className="counter">0</div>
                    </div>
                    <div className="counter-wrapper">
                      <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                      <div className="counter">0</div>
                    </div> */}
                    {props.allTasks[props.item][11] && (
                      <>
                        <i
                          onClick={() => setTooltipState(true)}
                          className="fas fa-info-circle tooltipOne tasklist-icon-info"
                        ></i>
                        {TooltipState ? (
                          <Tooltip
                            setTooltipState={setTooltipState}
                            content={props.allTasks[props.item][11]}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {props.canEdit && (
                    <>
                      {props.canEdit && (
                        <>
                          {dropdownTwo ? (
                            <div className="tasklist-dropdown-wrapper">
                              <div
                                id="tasklistDrop"
                                className="tasklist-dropdown-inner"
                              >
                                <Select
                                  placeholder={
                                    props.allTasks[props.item][7]
                                      ? props.allTasks[props.item][7]
                                      : "please choose"
                                  }
                                  options={Results}
                                  onMenuOpen={() => handleChange()}
                                  onChange={(e) => saveAsComment(e)}
                                />
                              </div>
                            </div>
                          ) : lockedForEditing && loadingSpinner ? (
                            <div className="loading-tasklist-icon">
                              <SpinnerMini />
                            </div>
                          ) : (
                            <>
                              <div className="ml-auto">
                                <i
                                  onClick={() => handleUnSafe()}
                                  className={
                                    Thumbs === "Up"
                                      ? "far fa-thumbs-up greenIMG task-thumb-up"
                                      : "far fa-thumbs-up .task-thumb-down"
                                  }
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {isOpen &&
            props.allTasks[props.item][2] &&
            props.allTasks[props.item][2].map((item, index) => {
              return (
                <>
                  <Collapsible
                    noTeamwork={props.noTeamwork}
                    dev={props.dev}
                    Tags={props.Tags}
                    idd={props.idd}
                    FinalTags={props.FinalTags}
                    TaskListComp={props.TaskListComp}
                    setModalImage={props.setModalImage}
                    setCurrentImg={props.setCurrentImg}
                    setSecondScreen={props.setSecondScreen}
                    setAllTasks={props.setAllTasks}
                    Filter={props.Filter}
                    setEl={props.setEl}
                    margin={props.margin + 20}
                    allTasks={props.allTasks}
                    canEdit={props.canEdit}
                    index={index}
                    item={item}
                  />
                </>
              );
            })}
        </>
      );
    } else {
      return "";
    }
  } else if (props.Filter === "Audited") {
    if (props.allTasks[props.item] !== undefined) {
      return (
        <>
          {(isYesNo &&
            props.allTasks[props.item][12].some((element) =>
              props.FinalTags.includes(element)
            ) &&
            props.allTasks[props.item][7] == "yes" &&
            testIfOpenChildren) ||
          props.allTasks[props.item][7] == "no" ||
          (props.allTasks[props.item][2] &&
            props.allTasks[props.item][10] !== 100 &&
            !props.allTasks[props.item][1] &&
            testIfOpenChildren) ? (
            <>
              <div className="borderSecure"></div>
              <div
                className="TaskListRow TaskListRowParent TaskListRowParent-top"
                style={{ paddingLeft: props.margin + 20 + "px" }}
              >
                {/*  <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" />
              </div> */}
                <div onClick={handleFilterOpening}>
                  {isYesNoAnswer !== "no" ? (
                    <i
                      className={
                        !isOpen
                          ? "fas fa-caret-right task-icon-caret"
                          : "fas fa-caret-down task-icon-caret"
                      }
                      onClick={handleFilterOpening}
                    />
                  ) : (
                    <span className="task-icon-caret-empty">&nbsp;</span>
                  )}
                  <p className="taskListRowParent-name">
                    {GetCodeRename(props.allTasks[props.item][3])}&nbsp;
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                  </p>
                </div>
                {props.canEdit && (
                  <div className="ml-auto">
                    {isYesNo ? (
                      <>
                        {loadingSpinner ? (
                          <SpinnerMini />
                        ) : (
                          <>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklistSave hoverSave"
                                  : "btn saveButtonTasklist"
                              }
                              onClick={() => handleYesNo("yes")}
                            >
                              <FormattedMessage id="Yes" defaultMessage="Yes" />
                            </button>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklist"
                                  : "btn saveButtonTasklistSave hoverSave"
                              }
                              onClick={() => handleYesNo("no")}
                            >
                              <FormattedMessage id="No" defaultMessage="No" />
                            </button>
                          </>
                        )}
                      </>
                    ) : dropdownTwo ? (
                      <div id="tasklistDrop" className="tasklist-dropdown-1">
                        <Select
                          placeholder={
                            props.allTasks[props.item][7]
                              ? props.allTasks[props.item][7]
                              : "please choose"
                          }
                          options={Results}
                          onMenuOpen={() => handleChange()}
                          onChange={(e) => saveAsComment(e)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </>
          ) : props.TaskListComp.includes(props.item) &&
            !props.allTasks[props.item][2] &&
            (props.allTasks[props.item][10] == 100 ||
              props.allTasks[props.item][10] == 20 ||
              props.allTasks[props.item][1]) &&
            Display == true ? (
            <>
              <div className="borderSecure"></div>
              <div
                className="TaskListRow tasklistrow-top"
                style={{ paddingLeft: props.margin + "px" }}
              >
                {/* <div className="form-group form-check">
                  <input type="checkbox" className="form-check-input" />
                </div> */}
                <div className="tasklist-inner">
                  <div
                    className="tasklist-col-1"
                    onClick={props.canEdit ? () => {
                      // props.setSecondScreen(props.item)
                      history.push(`${location.pathname}/${props.item}`);
                    } 
                    : undefined}
                  >
                    {props.allTasks[props.item][3] !== false &&
                    props.allTasks[props.item][3] !== null ?
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                    : null }
                  </div>
                  <div className="tasklist-col-2">
                    {/*    <div className="counter-wrapper">
                    <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                    <div className="counter">0</div>
                  </div>
                  <div className="counter-wrapper">
                    <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                    <div className="counter">0</div>
                  </div> */}
                    {props.allTasks[props.item][11] && (
                      <>
                        <i
                          onClick={() => setTooltipState(true)}
                          className="fas fa-info-circle tooltipOne tasklist-icon-info"
                        ></i>
                        {TooltipState ? (
                          <Tooltip
                            setTooltipState={setTooltipState}
                            content={props.allTasks[props.item][11]}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {props.canEdit && (
                    <>
                      {props.canEdit && (
                        <>
                          {dropdownTwo ? (
                            <div className="tasklist-dropdown-wrapper">
                              <div
                                id="tasklistDrop"
                                className="tasklist-dropdown-inner"
                              >
                                <Select
                                  placeholder={
                                    props.allTasks[props.item][7]
                                      ? props.allTasks[props.item][7]
                                      : "please choose"
                                  }
                                  options={Results}
                                  onMenuOpen={() => handleChange()}
                                  onChange={(e) => saveAsComment(e)}
                                />
                              </div>
                            </div>
                          ) : !lockedForEditing ? (
                            loadingSpinner ? (
                              <div className="loading-tasklist-icon">
                                <SpinnerMini />
                              </div>
                            ) : (
                              <>
                                <div className="d-flex ml-auto">
                                  <input
                                    className="tasklist-input-1"
                                    placeholder="Add your comment here..."
                                    value={TextField}
                                    onChange={(e) => handleTextChange(e)}
                                  />
                                  {!FilesField ? (
                                    <>
                                      <input
                                        classname="ZeroWidth w-0"
                                        key="uploadPic"
                                        ref={hiddenFileInput}
                                        accept="image/*"
                                        multiple
                                        capture
                                        type="file"
                                        name="file"
                                        onChange={handleChangePicture}
                                      />
                                      <i
                                        onClick={handleClickHidden}
                                        className="far fa-image fa-icon-img"
                                      />
                                    </>
                                  ) : (
                                    <i
                                      onClick={() => setFilesField(false)}
                                      className="far fa-trash-alt fa-img-delete"
                                    />
                                  )}
                                  <i
                                    onClick={
                                      Thumbs === "Down"
                                        ? undefined
                                        : () => handleSave("Down")
                                    }
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "25px",
                                      verticalAlign: "bottom",
                                      marginLeft: "20px",
                                      marginTop: "3px",
                                    }}
                                    className={
                                      Thumbs === "Down"
                                        ? "far fa-thumbs-down RedIMG task-thumb-down"
                                        : "far task-thumb-down"
                                    }
                                  />
                                  <i
                                    onClick={() => handleSave("Up")}
                                    className={
                                      Thumbs === "Up"
                                        ? "far fa-thumbs-up greenIMG task-thumb-up"
                                        : "far fa-thumbs-up task-thumb-up"
                                    }
                                  />
                                </div>
                              </>
                            )
                          ) : loadingSpinner ? (
                            <div className="loading-tasklist-icon">
                              <SpinnerMini />
                            </div>
                          ) : (
                            <>
                              <div className="ml-auto">
                                <i
                                  onClick={() => handleUnSafe()}
                                  className={
                                    Thumbs === "Up"
                                      ? "far fa-thumbs-up greenIMG task-thumb-up"
                                      : "far fa-thumbs-up task-thumb-up"
                                  }
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {isOpen &&
            props.allTasks[props.item][2] &&
            props.allTasks[props.item][2].map((item, index) => (
              <>
                <Collapsible
                  noTeamwork={props.noTeamwork}
                  dev={props.dev}
                  Tags={props.Tags}
                  idd={props.idd}
                  FinalTags={props.FinalTags}
                  TaskListComp={props.TaskListComp}
                  setModalImage={props.setModalImage}
                  setCurrentImg={props.setCurrentImg}
                  setSecondScreen={props.setSecondScreen}
                  setAllTasks={props.setAllTasks}
                  Filter={props.Filter}
                  setEl={props.setEl}
                  margin={props.margin + 20}
                  allTasks={props.allTasks}
                  canEdit={props.canEdit}
                  index={index}
                  item={item}
                />
              </>
            ))}
        </>
      );
    } else {
      return "";
    }
  } else if (props.Filter === "Not-Audited") {
    if (props.allTasks[props.item] !== undefined) {
      return (
        <>
          {(isYesNo &&
            props.allTasks[props.item][7] === "yes" &&
            testIfOpenChildren &&
            props.allTasks[props.item][12].some((element) =>
              props.FinalTags.includes(element)
            )) ||
          (isYesNo &&
            !props.allTasks[props.item][7] &&
            props.allTasks[props.item][12].some((element) =>
              props.FinalTags.includes(element)
            )) ||
          (!isYesNo &&
            props.allTasks[props.item][2] &&
            props.allTasks[props.item][10] !== 100 &&
            !props.allTasks[props.item][1] &&
            testIfOpenChildren) ? (
            <>
              <div className="borderSecure"></div>
              {/*   {props.allTasks[props.item][7] === "yes" && "yes"}
               */}{" "}
              <div
                className="TaskListRow TaskListRowParent"
                style={{
                  alignItems: "Center",
                  marginRight: "20px",
                  display: "flex",
                  cursor: "pointer",
                  marginLeft: props.margin + "px",
                }}
              >
                <div onClick={handleFilterOpening}>
                  {isYesNoAnswer !== "no" ? (
                    <i
                      className={
                        !isOpen
                          ? "fas fa-caret-right task-icon-caret"
                          : "fas fa-caret-down task-icon-caret"
                      }
                      onClick={handleFilterOpening}
                    />
                  ) : (
                    <span className="task-icon-caret-empty">&nbsp;</span>
                  )}
                  <p className="taskListRowParent-name">
                    {GetCodeRename(props.allTasks[props.item][3])}&nbsp;
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                  </p>
                </div>
                {props.canEdit && (
                  <div className="ml-0">
                    {isYesNo ? (
                      <>
                        {loadingSpinner ? (
                          <SpinnerMini />
                        ) : (
                          <>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklistSave hoverSave"
                                  : "btn saveButtonTasklist"
                              }
                              onClick={() => handleYesNo("yes")}
                            >
                              <FormattedMessage id="Yes" defaultMessage="Yes" />
                            </button>
                            <button
                              className={
                                isYesNoAnswer === "yes"
                                  ? "btn saveButtonTasklist"
                                  : "btn saveButtonTasklistSave hoverSave"
                              }
                              onClick={() => handleYesNo("no")}
                            >
                              <FormattedMessage id="No" defaultMessage="No" />
                            </button>
                          </>
                        )}
                      </>
                    ) : dropdownTwo ? (
                      <div id="tasklistDrop" className="tasklist-dropdown-1">
                        <Select
                          placeholder={
                            props.allTasks[props.item][7]
                              ? props.allTasks[props.item][7]
                              : "please choose"
                          }
                          options={Results}
                          onMenuOpen={() => handleChange()}
                          onChange={(e) => saveAsComment(e)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </>
          ) : props.TaskListComp.includes(props.item) &&
            !props.allTasks[props.item][2] &&
            props.allTasks[props.item][10] !== 100 &&
            props.allTasks[props.item][10] !== 20 &&
            !props.allTasks[props.item][1] &&
            Display === true &&
            props.shouldNotShow !== "true" ? (
            <>
              <div className="borderSecure"></div>

              <div
                className="TaskListRow"
                style={{ cursor: "pointer", marginLeft: props.margin + "px" }}
              >
                <div
                  style={{
                    position: "relative",
                    marginLeft: "37px",
                    marginRight: "20px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "44%",
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                    onClick={props.canEdit ? () => {
                      // props.setSecondScreen(props.item)
                      history.push(`${location.pathname}/${props.item}`);
                    } 
                    : false}
                  >
                    <FormattedMessage
                      id={Rename(props.allTasks[props.item][3])}
                      defaultMessage={Rename(props.allTasks[props.item][3])}
                    />
                  </div>
                  <div className="tasklist-col-2">
                    {/*  <div className="counter-wrapper">
                    <img src='/images/icons/question_answer_black_24dp.svg' className='icon' alt='icon' />
                    <div className="counter">0</div>
                  </div>
                  <div className="counter-wrapper">
                    <img src='/images/icons/collections_black_24dp.svg' className='icon' alt='icon' />
                    <div className="counter">0</div>
                  </div> */}
                    {props.allTasks[props.item][11] && (
                      <>
                        <i
                          onClick={() => setTooltipState(true)}
                          className="fas fa-info-circle tooltipOne tasklist-icon-info"
                        ></i>
                        {TooltipState ? (
                          <Tooltip
                            setTooltipState={setTooltipState}
                            content={props.allTasks[props.item][11]}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {props.canEdit && (
                    <>
                      {dropdownTwo ? (
                        <div style={{ display: "flex", marginLeft: "auto" }}>
                          <div
                            id="tasklistDrop"
                            className="tasklist-dropdown-1"
                          >
                            <Select
                              placeholder={
                                props.allTasks[props.item][7]
                                  ? props.allTasks[props.item][7]
                                  : "please choose"
                              }
                              options={Results}
                              onMenuOpen={() => handleChange()}
                              onChange={(e) => saveAsComment(e)}
                            />
                          </div>
                        </div>
                      ) : props.canEdit && loadingSpinner ? (
                        <div className="loading-tasklist-icon">
                          <SpinnerMini />
                        </div>
                      ) : props.canEdit ? (
                        <>
                          <div className="d-flex margin-left-auto yes-this">
                            <input
                              className="tasklist-input-1"
                              placeholder="Add your comment here..."
                              value={TextField}
                              onChange={(e) => handleTextChange(e)}
                            />
                            {!FilesField ? (
                              <>
                                <input
                                  className="ZeroWidth w-0"
                                  key="uploadPic"
                                  ref={hiddenFileInput}
                                  accept="image/*"
                                  multiple
                                  capture
                                  type="file"
                                  name="file"
                                  onChange={handleChangePicture}
                                />
                                <i
                                  onClick={handleClickHidden}
                                  className="far fa-image fa-icon-img"
                                />
                              </>
                            ) : (
                              <i
                                onClick={() => setFilesField(false)}
                                className="far fa-trash-alt fa-img-delete"
                              />
                            )}
                            <i
                              onClick={
                                Thumbs === "Down"
                                  ? undefined
                                  : () => handleSave("Down")
                              }
                              className={
                                Thumbs === "Down"
                                  ? "far fa-thumbs-down RedIMG task-thumb-down"
                                  : "far fa-thumbs-down task-thumb-down"
                              }
                            />
                            <i
                              onClick={() => handleSave("Up")}
                              className={
                                Thumbs === "Up"
                                  ? "far fa-thumbs-up greenIMG"
                                  : "far fa-thumbs-up task-thumb-up"
                              }
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {isOpen &&
            props.allTasks[props.item][2] &&
            props.allTasks[props.item][2].map((item, index) => {
              return (
                <>
                  <Collapsible
                    noTeamwork={props.noTeamwork}
                    dev={props.dev}
                    Tags={props.Tags}
                    idd={props.idd}
                    shouldNotShow={
                      (isYesNoAnswer === "yes" && !saved) ||
                      isYesNoAnswer === "no" ||
                      (props.shouldNotShow && props.shouldNotShow == "true")
                        ? "true"
                        : "false"
                    }
                    FinalTags={props.FinalTags}
                    TaskListComp={props.TaskListComp}
                    setModalImage={props.setModalImage}
                    setCurrentImg={props.setCurrentImg}
                    setSecondScreen={props.setSecondScreen}
                    Filter={props.Filter}
                    setEl={props.setEl}
                    margin={props.margin + 20}
                    setAllTasks={props.setAllTasks}
                    allTasks={props.allTasks}
                    canEdit={props.canEdit}
                    index={index}
                    item={item}
                    section={props.section}
                  />
                </>
              );
            })}
        </>
      );
    } else {
      return "";
    }
  } else {
    return (
      <>
        <FormattedMessage
          id="Nothingtodisplay"
          defaultMessage="Nothing to display"
        />
      </>
    );
  }
};
export default Collapsible;

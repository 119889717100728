import React from "react";
import ReactDOM from "react-dom";
// import 'antd/dist/antd.css';
import App from "./App";

let func = "Component";
let dev: boolean;
if (React[func].name === func) {
  /*  console.log("dev") */
  dev = true;
  //("dev")
} else {
  //("build")
  dev = false;
  console.log = () => {};
}
/* console.log(status) */

ReactDOM.render(
  <React.StrictMode>
    <App dev={dev} />
  </React.StrictMode>,
  document.getElementById("root")
);

import React, { Component, useState, useEffect,useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import LeftPanel from './LeftPanel';
import Menu from './menu';

const MobileMenu =  ( props ) => {
  
  const ref = useRef()

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if ( props.isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        props.closeHandle()
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [ props.isMenuOpen])
    return (
      <div className={ props.isMenuOpen ? "mobileCanvas open" : "mobileCanvas"} ref={ref}>
        <div className="close-menu-icon" onClick={props.closeHandle}>
          <img src="/images/icons8-close.svg" alt=""/>
        </div>
        <LeftPanel openPage={props.openPage} SetOpenPage={props.SetOpenPage}
          isMobile /* SetOpenPage={()=>{
          console.log("Set Open Page -> is mobile")
        }} */
        />
        <Menu/>
      </div>
    );
  
}
export default MobileMenu
import round from './round';


const MainFunction = (e, tasks, name, FinalArray, Tags, Weight, Translation) => {
  if (typeof (e) == "undefined" || e == " ") {
    return false
  } else {
    if (e[0] == "Comment") {
      /*  console.log(e)
        console.log(name)
        console.log(tasks[e[1]]) */
      if (typeof (tasks[e[1]]) !== "undefined") {
        FinalArray[name + "Result"] = tasks[e[1]][7]
      } else {
        FinalArray[name + "Result"] = ""
        return false
      }
    } else if (e[0] == "File") {

      if (typeof (tasks[e[1]]) !== "undefined") {
        console.log(tasks[e[1]])
        console.log(name)
        function findLastIndex(array, searchKey, searchValue) {
          //    console.log(array)
          var index = array.slice().reverse().findIndex(x => x !== null);
          var count = array.length - 1
          var finalIndex = index >= 0 ? count - index : index;
          //  console.log(finalIndex)
          return finalIndex;
        }
        let index
        if (tasks[e[1]][5] && Array.isArray(tasks[e[1]][5])) {
          index = findLastIndex(tasks[e[1]][5], 'a', 'something')
        }
        let LengthMinusOne

        if (Array.isArray(tasks[e[1]][5])) {
          let LengthMinusOne = Array.isArray(tasks[e[1]][5]) && tasks[e[1]][5].length - 1
          FinalArray[name + "Result"] = [tasks[e[1]][5][index]]
        }

        //console.log(FinalArray[name + "Result"])
      } else {
        FinalArray[name + "Result"] = ""
        return false
      }
    }




    else if (e[0] == "Bay") {

      //  console.log(e)
      //   console.log(e)
      //  console.log(name) 
      if (typeof (tasks[e[1]]) !== "undefined") {
        FinalArray[name + "Result"] = [e[1], tasks[e[1]][2]]
      } else {
        FinalArray[name + "Result"] = 0
        return false
      }
      if (!FinalArray[name]) {
        FinalArray[name] = {}
      }

      if (name == "MechanicalAndBodyRepairWorkBays") {
        FinalArray[name]["MechanicalBay"] = []
        FinalArray[name]["RemoveRefitBay"] = []
        FinalArray[name]["GeneralRepairBay"] = []
        FinalArray[name]["HeavyRepairBay"] = []
        FinalArray[name]["AluminiumBay"] = []
      } else if (name == "PaintShopBaysCount") {
        FinalArray[name]["PreparationBay"] = []
        FinalArray[name]["MaskingBay"] = []
        FinalArray[name]["PolishingBay"] = []
        FinalArray["PreparationBayStandard"] = 0
        FinalArray["PreparationBaySemi"] = 0
        FinalArray["PreparationBayFull"] = 0
      } else if (name == "MixingBay") {
        //  console.log(name)
        FinalArray[name]["MixingRoom"] = []

      } else if (name == "SprayBoothBay") {
        FinalArray[name]["SprayBooth"] = []
        FinalArray["SprayBoothSeparateDrying"] = 0
        FinalArray["CombiSprayBoothStandard"] = 0
        FinalArray["CombiSprayBoothInfrared"] = 0
      }




      if (tasks[e[1]][2]) {
        /*   console.log(tasks[e[1]][2])
   */
        tasks[e[1]][2].map((item, index) => {
          function dependingOnBay(secondName, ParentItem, baytag, baytype) {
            let CommentPrep
            let addons = []
            //  console.log(secondName)
            //   console.log(ParentItem)
            //  console.log(baytag)
            let newStr = item
            newStr = newStr.replace(e[1], "");
            newStr = newStr.replace(".", "");
            newStr = parseInt(newStr)
            let pushThisItem = {}

            pushThisItem["Tags"] = Tags
            pushThisItem["TotalWeight"] = 0
            pushThisItem["WeightComplete"] = 0
            pushThisItem["complete"] = 0
            pushThisItem["total"] = 0
            pushThisItem["Tasks"] = []
            pushThisItem["uncompleted"] = 0
            pushThisItem["pictures"] = []
            pushThisItem["ArrayNumber"] = newStr
            pushThisItem["Name"] = item
            pushThisItem["AddOn"] = {}

            let allChildren = []

            // console.log(ParentItem)
            Object.keys(tasks).forEach(e => {
              if (e.startsWith(ParentItem + ".")) {
                allChildren.push(e)
              }
            })
            allChildren = allChildren.map(a => a.split('.').map(n => +n + 100000).join('.')).sort()
              .map(a => a.split('.').map(n => +n - 100000).join('.'));
            let addonResults = {}
            if (baytype == "Paint") {
              addons = ["PaintShopBaysRadio", "PaintShopBaysTools", "PaintShopBaysAir", "PaintShopBaysSteam", "PaintShopBaysElectricity", "PaintShopBaysWaste", "PaintShopBaysLiftOne", "PaintShopBaysLiftTwo", "PaintShopBaysLamp", "PaintShopBaysLiftFour", "PaintShopBaysLiftThree", "PaintShopBaysLiftFive"]
            } else if (baytype == "Mechanical") {
              addons = ["BodyShopBaysRadio", "BodyShopBaysTools", "BodyShopBaysAir", "BodyShopBaysSteam", "BodyShopBaysElectricity", "BodyShopBaysWaste", "BodyShopBaysLiftOne", "BodyShopBaysLiftTwo", "BodyShopBaysLamp", "BodyShopBaysLiftFour", "BodyShopBaysLiftThree", "BodyShopBaysLiftFive"]
            }
            addons.forEach((item, index) => {
              console.log("Addon Start")
              console.log(item)

              if (Translation[item]) {
                /*  if (!Translation[item][2]) {
                   return
                 } */
                console.log(e[1])
                // console.log(Translation[item][3][0])
                let removeAtBeginning = Translation[item][4]
                if (Translation[item][0] == "BayAverage") {
                  let CountResult = 0
                  let Amount = 0
                  Translation[item][1].map((ITEM, INDEX) => {
                    console.log("Translation item")
                    console.log()
                    Amount++
                    let emp
                    let part = ITEM.value.replace(e[1] + ".1", "")
                    if (tasks[e[1] + "." + newStr + part] && tasks[e[1] + "." + newStr + part][7]) {
                      CountResult = CountResult + parseInt(tasks[e[1] + "." + newStr + part][7])


                    }
                  })

                  let part = Translation[item][2][0].replace(e[1] + ".1", "")
                  let compareagainst = e[1] + "." + newStr + part


                  if (CountResult && tasks[compareagainst][7]) {
                    console.log("Count Result/Amount " + parseFloat(CountResult / Amount) + ">" + "tasks[compareagainst][7]" + parseFloat(tasks[compareagainst][7]))
                    if (parseFloat(CountResult / Amount) >= (parseFloat(tasks[compareagainst][7]))) {
                      console.log("coming addon if")
                      console.log("Addon Item if: ")
                      console.log(item)
                      pushThisItem["AddOn"][item] = { result: ["BayAverage", true] }
                    } else {
                      console.log("Addon Item else 1: ")
                      console.log(item)
                      pushThisItem["AddOn"][item] = { result: ["BayAverage", false] }
                    }
                  } else {
                    console.log("Addon Item else 2: ")
                    console.log(item)
                    pushThisItem["AddOn"][item] = { result: ["BayAverage", false] }
                  }
                  let result = Translation[item][2][0].replace(e[1], "");
                } else if (Translation[item][0] == "BayYesNo") {
                  //    console.log(Translation[item])
                  let part = Translation[item][1].replace(Translation[item][2] + ".1", "")

                  console.log("bays Check")

                  if (tasks[Translation[item][2] + "." + newStr + part] && tasks[Translation[item][2] + "." + newStr + part][7] == "yes") {
                    console.log("Taskno if")
                    console.log(tasks[Translation[item][2] + "." + newStr + part])
                    pushThisItem["AddOn"][item] = { result: ["BayYesNo", true] }
                  } else {
                    console.log("Taskno else")
                    console.log(tasks[Translation[item][2] + "." + newStr + part])
                    pushThisItem["AddOn"][item] = { result: ["BayYesNo", false] }
                  }
                }

                else if (Translation[item][0] == "BayIfOne") {
                  /*    console.log("test")
                     console.log(Translation[item]) */
                  let Str = Translation[item][1].replace(Translation[item][4] + ".1", "");

                  if (!addonResults[item]) {
                    addonResults[item] = { "itemArray": [], "type": "BayIfOne" }
                  }

                  addonResults[item]["itemArray"] = allChildren.filter(el => el.startsWith(ParentItem + Str + "."))
                  Array.isArray(Translation[item][2]) && Translation[item][2].map(itemEL => {
                    let Check = itemEL["value"].replace(Translation[item][4] + ".1", ParentItem)

                    if (allChildren.includes(Check)) {
                      addonResults[item]["itemArray"].push(Check)
                    }
                  })
                  //   console.log(addonResults[item])
                }

                else {
                  console.log("Addon Else -- 2")
                  console.log(item)
                  console.log("Resultaddon")
                  if (item === "PaintShopBaysLiftOne") {
                    console.log("Found");
                    //addonResults[item] = { "itemArray": [], "type": "BayPercentage" }
                  }
                  console.log(addonResults[item])
                  console.log(Translation[item][1])
                  console.log(Translation[item][4])
                  let Str = Translation[item][1] && Translation[item][1].replace(Translation[item][4] + ".1", "");
                  console.log("Str: " + Str)
                  if (!addonResults[item]) { addonResults[item] = { "itemArray": [], "type": "BayPercentage" } }
                  console.log(addonResults[item])
                  let BayPercentageArray = [...allChildren]
                  BayPercentageArray.push(ParentItem)
                  console.log("BayPercentageArray")
                  console.log(BayPercentageArray)
                  console.log("ParentItem: " + ParentItem)
                  console.log("ParentItem + Str:" + ParentItem + Str + ".")
                  console.log(ParentItem + Str)

                  addonResults[item]["itemArray"] = Str && BayPercentageArray.filter(el => el.startsWith(ParentItem + Str + ".") || el == ParentItem + Str)
                  console.log(addonResults[item]["itemArray"])
                  if (addonResults[item]["itemArray"] == null) {
                    addonResults[item]["itemArray"] = []
                  }
                  console.log("Translation[item][2]")
                  console.log(Translation[item][2])
                  Array.isArray(Translation[item][2]) && Translation[item][2].map(itemEL => {
                    let Check = itemEL["value"].replace(Translation[item][4] + ".1", ParentItem)
                    console.log("Check")
                    console.log(Check)

                    if (allChildren.includes(Check)) {

                      addonResults[item]["itemArray"].push(Check)
                    }
                  })
                }


                // addonResults[item].push()
              }
              console.log("Addon End")
            })


            allChildren.map((ChildItem, index) => {

              if (tasks[ChildItem] && tasks[ChildItem][12] && tasks[ChildItem][12].some(el => Tags.includes(el))) {

                function additems() {
                  if (tasks[ChildItem][4]) {

                    tasks[ChildItem][4].forEach((Pic, indexPic) => {
                      pushThisItem["pictures"].push([tasks[ChildItem][4][indexPic], tasks[ChildItem][5][indexPic]])
                    })
                  }



                  /* if (!tasks[ChildItem][2] || tasks[ChildItem][12].includes(58572)) {
                    console.log(ChildItem)
                    pushThisItem["total"]++
                    let additionalWeight = 1
                    if (Weight && Weight.hasOwnProperty([ChildItem])) {

                      additionalWeight = Weight[ChildItem]
                    }
                    pushThisItem["TotalWeight"] += additionalWeight  //+= item[2]
                    if (tasks[ChildItem][1] || tasks[ChildItem][1] == 100) {
                      pushThisItem["complete"]++
                      pushThisItem["WeightComplete"] += additionalWeight
                    } else {
                      //   console.log(ChildItem)
                      pushThisItem["uncompleted"]++
                    } */

                  if (!tasks[ChildItem][2] || tasks[ChildItem][12].includes(58572)) {

                    /*   if (tasks[item][12].includes(58604)) {

                      } */
                    pushThisItem["total"]++
                    let additionalWeight = 1

                    function checkFather(FatherName, ChildBay) {

                      //    console.log(FatherName)
                      let ElementName = FatherName.split('.')
                      if (ChildBay == 0) {
                        ChildBay = ElementName.pop()
                      } else {
                        let newChildBay = ElementName.pop()
                        ChildBay = newChildBay + "." + ChildBay
                      }
                      //  console.log(ChildBay)
                      ElementName = ElementName.join('.')
                      // console.log(FatherName)
                      //console.log(ChildBay)
                      if (tasks[FatherName] && tasks[FatherName][12] && !tasks[FatherName][12].includes(58561)) {
                        //  console.log("didnt")
                        //   console.log(ElementName)
                        return checkFather(ElementName, ChildBay)
                      } else {
                        //  console.log("final")
                        ChildBay = ChildBay.split(".")
                        ChildBay.shift()
                        ChildBay = ChildBay.join(".")
                        //    console.log(ChildBay)
                        let finalName = FatherName.split('.')
                        finalName.pop()
                        finalName = finalName.join('.')
                        finalName = finalName + ".1." + ChildBay
                        return finalName

                        /*  console.log(ElementName)
                         console.log(ChildBay) */
                        // console.log("final")
                      }

                    }

                        //2.6.2.44.15.6
                     /*    item.startsWith("2.6.4") && console.log(item)
                      */   let result = checkFather(ChildItem, 0)


                    if (Weight[result] || Weight[result] == 0) {
                      //   console.log(Weight[result])
                      additionalWeight = Weight[result]
                    } else if (Weight[item] || Weight[item] == 0) {
                      additionalWeight = Weight[item]
                    } else {
                      additionalWeight = 1
                    }
                    pushThisItem["TotalWeight"] += additionalWeight
                    if (tasks[ChildItem][1] || tasks[ChildItem][1] == 100 || tasks[ChildItem][7] == "yes") {
                      pushThisItem["complete"]++
                      pushThisItem["WeightComplete"] += additionalWeight
                    } else {
                      //   console.log(ChildItem)
                      pushThisItem["uncompleted"]++
                    }
                    // console.log(checkFather(item))
                    /*  if (item.startsWith("2.8.2.6.18")) {
                       console.log(item)
                       console.log(result)
                       console.log(additionalWeight)
                       } */



                    //   console.log(ChildItem)



                    addons.forEach(El => {
                      if (addonResults[El] && addonResults[El]["itemArray"].includes(ChildItem)) {
                        console.log("Children Addon Included")
                        console.log(addonResults[El])
                        //  console.log(El)
                        //console.log(addonResults[El])
                        // console.log(ChildItem)
                        if (!pushThisItem["AddOn"][El]) { pushThisItem["AddOn"][El] = { TotalWeight: 0, WeightComplete: 0, uncomplete: [], complete: 0 } }
                        // console.log(additionalWeight)
                        //  console.log(pushThisItem["AddOn"][El])
                        pushThisItem["AddOn"][El]["TotalWeight"] += additionalWeight

                        if (tasks[ChildItem][1] || tasks[ChildItem][1] == 100) {
                          pushThisItem["AddOn"][El]["WeightComplete"] += additionalWeight
                          pushThisItem["AddOn"][El]["complete"]++
                        } else {
                          pushThisItem["AddOn"][El]["uncomplete"].push(ChildItem)
                        }
                        if (!pushThisItem["AddOn"][El]["type"] && addonResults[El]["itemArray"]) {
                          pushThisItem["AddOn"][El]["type"] = addonResults[El]["type"]
                        }

                      }

                    })


                  }
                  pushThisItem["Tasks"].push(ChildItem)


                }
                function getfather(ElementName) {

                  if (!tasks[ElementName][12].includes(58572)) {

                    ElementName = ElementName.split('.')

                    ElementName.pop()

                    ElementName = ElementName.join('.')

                    return getfather(ElementName)
                  } else {
                    if (tasks[ElementName][7] == "yes") {
                      return true
                    } else {
                      return false
                    }
                  }
                }
                if (tasks[ChildItem][12].includes(58583)) {

                  if (tasks[ChildItem][12].includes(baytag)) {

                    additems()
                  } else {
                    return
                  }
                } else if (tasks[ChildItem][12].includes(58557)) {

                  if (getfather(ChildItem)) {

                    additems()
                  } else {
                    pushThisItem["Tasks"].push(ChildItem)
                  }
                } else {
                  additems()
                }
              }


            })
            addons.forEach(El => {
              if (pushThisItem.AddOn[El]) {
                // console.log(pushThisItem.AddOn[El])

                if (!pushThisItem.AddOn[El]["result"]) {
                  if (pushThisItem.AddOn[El]["type"] == "BayPercentage") {
                    if (pushThisItem["AddOn"][El]["WeightComplete"] == pushThisItem["AddOn"][El]["TotalWeight"]) {
                      pushThisItem["AddOn"][El].result = ["BayPercentage", true]
                    } else {
                      pushThisItem["AddOn"][El].result = ["BayPercentage", false]
                    }
                  } else {
                    if (pushThisItem["AddOn"][El]["complete"] > 0) {
                      pushThisItem["AddOn"][El].result = ["BayIfOne", true]
                    } else {
                      pushThisItem["AddOn"][El].result = ["BayIfOne", false]
                    }
                  }

                }


              }


            })

            if (FinalArray[name] && FinalArray[name][secondName]) {
              FinalArray[name][secondName].push(pushThisItem)
            }






          }



          if (tasks[item]) {
            if (tasks[item][7] == "Mechanical Work Bays") {
              dependingOnBay("MechanicalBay", item, 58481, "Mechanical")
            } else if (tasks[item][7] == "Remove + Refit Area") {
              dependingOnBay("RemoveRefitBay", item, 58184, "Mechanical")
            } else if (tasks[item][7] == "Light Body Repair Bays") {
              dependingOnBay("GeneralRepairBay", item, 58480, "Mechanical")
            } else if (tasks[item][7] == "Heavy Body Repair Bays") {
              dependingOnBay("HeavyRepairBay", item, 58479, "Mechanical")
            } else if (tasks[item][7] == "Aluminium Body Repair Bays") {
              dependingOnBay("AluminiumBay", item, 58485, "Mechanical")
            }
            else if (tasks[item][7] == "Preparation Bays") {
              dependingOnBay("PreparationBay", item, 58610, "Paint")
            } else if (tasks[item][7] == "Masking Bays") {
              dependingOnBay("MaskingBay", item, 58609, "Paint")
            } else if (tasks[item][7] == "Polishing Bays") {
              dependingOnBay("PolishingBay", item, 58611, "Paint")
            } else if (tasks[item][7] == "Spray Booth") {
              dependingOnBay("SprayBooth", item, false)
            }
            else if (tasks[item][7] == "Mixing Room") {
              dependingOnBay("MixingRoom", item, false)
            }

          }
          // console.log(FinalArray[name])
        })

        /* if( FinalArray[name] && FinalArray[name][secondName]) {
          FinalArray[name][secondName].push(pushThisItem)
        } */
        //   console.log( FinalArray[name])
        for (const item in FinalArray[name]) {
          FinalArray[name][item].sort((a, b) => (a.ArrayNumber > b.ArrayNumber) ? 1 : -1)
        }
        // FinalArray[name].forEach(item=> {




        //  console.log( FinalArray[name])
      }

      if (name == "PaintShopBaysCount") {
        let CommentPrep
        let Answer
        if (Translation["StandardSemiDown"]) {
          CommentPrep = Translation["StandardSemiDown"][1].split('.')
          Answer = CommentPrep.pop()
          CommentPrep = CommentPrep.pop()
          if (FinalArray["PaintShopBaysCount"] && FinalArray["PaintShopBaysCount"]["PreparationBay"]) {
            let NumberPreparationBays = FinalArray["PaintShopBaysCount"]["PreparationBay"]
            NumberPreparationBays.forEach(item => {
              /*   console.log(Answer)
                console.log(CommentPrep)
                console.log(item["Name"] + "." + CommentPrep ) */
              //    console.log(tasks[item["Name"] + "." + CommentPrep][7] )

              if (tasks[item["Name"] + "." + CommentPrep] && tasks[item["Name"] + "." + CommentPrep][7] && (tasks[item["Name"] + "." + CommentPrep][7]).toString().toLowerCase() == "standard") {
                //  console.log(1)
                console.log(item)
                FinalArray["PreparationBayStandard"]++
              } else if

                /* (tasks[item["Name"] + "." + CommentPrep] && tasks[item["Name"] + "." + CommentPrep][7] && (tasks[item["Name"] + "." + CommentPrep][7]).toString().toLowerCase() == "yes") {

                if */ (tasks[item["Name"] + "." + CommentPrep + "." + Answer] && tasks[item["Name"] + "." + CommentPrep + "." + Answer][7] && ((tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "side" || (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "end" || (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "sidedraft" || (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "semi")) {
                //    console.log(3)
                console.log(item)
                FinalArray["PreparationBaySemi"]++
              }
              else if (tasks[item["Name"] + "." + CommentPrep + "." + Answer] && tasks[item["Name"] + "." + CommentPrep + "." + Answer][7] && ((tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "down" || (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "full-downdraft" || (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "full")) {
                //   console.log(4)
                console.log(item)
                FinalArray["PreparationBayFull"]++
              }

              else {
                console.log(item)
                FinalArray["PreparationBayStandard"]++
              }



            })


          }
        }
      }
      if (name == "SprayBoothBay") {
        let CommentPrep
        let Answer
        if (Translation["CombiSpray"]) {
          CommentPrep = Translation["CombiSpray"][1].split('.')
          console.log(CommentPrep)
          Answer = CommentPrep.pop()
          CommentPrep = CommentPrep.pop()
          console.log(CommentPrep)
          console.log(Answer)

          if (FinalArray["SprayBoothBay"] && FinalArray["SprayBoothBay"]["SprayBooth"]) {
            let NumberPreparationBays = FinalArray["SprayBoothBay"]["SprayBooth"]
            // console.log(NumberPreparationBays)
            NumberPreparationBays.forEach(item => {
              // console.log(item)
              // console.log([item["Name"] + "." + CommentPrep])
              // console.log(tasks[item["Name"] + "." + CommentPrep] && tasks[item["Name"] + "." + CommentPrep][7])
              /*   console.log(Answer)
                console.log(CommentPrep)
                console.log(item["Name"] + "." + CommentPrep ) */
              //   console.log([item["Name"] + "." + CommentPrep + "." + Answer] )
              if (tasks[item["Name"] + "." + CommentPrep + "." + Answer] && tasks[item["Name"] + "." + CommentPrep + "." + Answer][7] && (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "standard") {
                //  console.log(1)
                console.log("1")
                FinalArray["CombiSprayBoothStandard"]++
              } else if

                (tasks[item["Name"] + "." + CommentPrep + "." + Answer] && tasks[item["Name"] + "." + CommentPrep + "." + Answer][7] && (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "infrared") {
                FinalArray["CombiSprayBoothInfrared"]++
                console.log("2")
              }
              else if (tasks[item["Name"] + "." + CommentPrep + "." + Answer] && tasks[item["Name"] + "." + CommentPrep + "." + Answer][7] && (tasks[item["Name"] + "." + CommentPrep + "." + Answer][7]).toString().toLowerCase() == "drying") {

                //   console.log(4)
                FinalArray["SprayBoothSeparateDrying"]++
                console.log("3")
              }

              else {
                console.log("4")
                FinalArray["CombiSprayBoothStandard"]++
              }



            })


          }
        }
      }





    }



    else if (e[0] == "Repair") {
      /*    console.log(e)
         console.log(name) */
      try {
        //  console.log(e)
        if (!FinalArray[name]) {
          FinalArray[name] = []
        }

        let allChildren = []
        //   console.log(e[1])
        let SearchForName = e[1]
        //  console.log(SearchForName)
        if (SearchForName.endsWith(".")) {
          //  console.log(SearchForName)
          Object.keys(tasks).forEach(element => {
            if (element.startsWith(SearchForName)) {

              allChildren.push(element)
            }
          })
        } else {
          SearchForName += "."
          //   console.log(SearchForName)
          Object.keys(tasks).forEach(element => {
            if (element.startsWith(SearchForName)) {

              allChildren.push(element)
            }
          })
        }
        //   console.log(allChildren)

        //     console.log(allChildren)

        let AmountOfRepairs = allChildren.filter(item => {
          return item.match(/\./g).length == 2
        })
        //        console.log(AmountOfRepairs)
        //    console.log(AmountOfRepairs)
        //  console.log(allChildren)
        // console.log(AmountOfRepairs)
        AmountOfRepairs.map((ParentItem, ParentIndex) => {
          let some = ParentItem.split(".")
          let SingleNumber = some[some.length - 1]
          some = ParentItem.split(".")
          FinalArray[name][ParentIndex] = {}
          FinalArray[name][ParentIndex]["Repairs"] = []
          FinalArray[name][ParentIndex]["TotalWeight"] = 0
          FinalArray[name][ParentIndex]["WeightComplete"] = 0
          FinalArray[name][ParentIndex]["complete"] = 0
          FinalArray[name][ParentIndex]["total"] = 0
          FinalArray[name][ParentIndex]["ArrayLastNumber"] = parseInt(SingleNumber)
          FinalArray[name][ParentIndex]["ArrayNumber"] = ParentItem
          FinalArray[name][ParentIndex]["FatherNumber"] = e[1]
          /*  console.log(tasks[ParentItem])
           console.log(tasks[ParentItem][7]) */
          if (tasks[ParentItem][7]) {
            let string = tasks[ParentItem][7].split(",")
            FinalArray[name][ParentIndex]["Date"] = string[0]
          }




          //    console.log(tasks[ParentItem][2])
          tasks[ParentItem][2].map(ChildItem => {
            //console.log(ChildItem)
            let SpecificChildren = allChildren.filter(e => {
              return e.startsWith(ChildItem + ".")
            })

            //  console.log(SpecificChildren)
            let someNew = ChildItem.split(".")
            let SingleNumberNew = someNew[someNew.length - 1]
            someNew = ChildItem.split(".")
            // console.log(SingleNumberNew)
            let pushThisItem = {}
            pushThisItem["TotalWeight"] = 0
            pushThisItem["WeightComplete"] = 0
            pushThisItem["complete"] = 0
            pushThisItem["total"] = 0
            pushThisItem["Tasks"] = []
            pushThisItem["uncompleted"] = 0
            pushThisItem["pictures"] = []
            pushThisItem["ArrayLastNumber"] = parseInt(SingleNumberNew)
            pushThisItem["ArrayNumber"] = ParentItem




            SpecificChildren.map(item => {
              /*  const even = (element) => element % 2 === 0; */
              if (tasks[item] && tasks[item][12] && tasks[item][12].some(el => Tags.includes(el))) {
                // console.log("test")
                /* console.log(tasks[item[0]]) */
                /* console.log(tasks[item[0]]) */


                function addToList() {

                  if (tasks[item][4]) {
                    //  if (tasks[item][4]) {
                    tasks[item][4].forEach((Pic, indexPic) => {
                      pushThisItem["pictures"].push([tasks[item][4][indexPic], tasks[item][5][indexPic]])
                    })
                  }



                  if (!tasks[item][2] || tasks[item][12].includes(58572)) {


                    pushThisItem["total"]++
                    FinalArray[name][ParentIndex]["total"]++
                    let additionalWeight = 1


                    let itemcheck = item.split(".")
                    let CheckThisItem = itemcheck.pop()
                    if (Weight[4 + ".1" + ".1" + ".1." + CheckThisItem] || Weight[4 + ".1" + ".1" + ".1." + CheckThisItem] == 0) {
                      additionalWeight = Weight[4 + ".1" + ".1" + ".1." + CheckThisItem]
                    } else if (Weight[item] || Weight[item] == 0) {
                      additionalWeight = Weight[item]
                    } else {
                      additionalWeight = 1
                    }
                    // console.log(checkFather(item))


                    if (Weight && (Weight.hasOwnProperty(item) || Weight[item] == 0)) {
                      additionalWeight = Weight[item]
                    }


                    pushThisItem["TotalWeight"] += additionalWeight  //+= item[2]
                    FinalArray[name][ParentIndex]["TotalWeight"] += additionalWeight

                    if (tasks[item][1] /* || tasks[item[0]][10] == 20 */ || tasks[item][1] == 100) {
                      pushThisItem["complete"]++
                      FinalArray[name][ParentIndex]["complete"]++
                      pushThisItem["WeightComplete"] += additionalWeight // += item[2]
                      FinalArray[name][ParentIndex]["WeightComplete"] += additionalWeight
                    } else {
                      pushThisItem["uncompleted"]++
                      FinalArray[name][ParentIndex]["uncompleted"]++
                    }


                  }

                  pushThisItem["Tasks"].push(item)


                }



                addToList()



              }



            })
            /*  console.log(FinalArray[name]["WeightComplete"])
             console.log(FinalArray[name]["TotalWeight"]) */


            pushThisItem["Result"] = round(100 * pushThisItem["WeightComplete"] / pushThisItem["TotalWeight"])
            pushThisItem["Result"] = pushThisItem["Result"] && pushThisItem["Result"] !== "NaN" ? pushThisItem["Result"] : 0
            // return round(100 * arr["WeightComplete"] / arr["TotalWeight"]);



            FinalArray[name][ParentIndex]["Repairs"].push(pushThisItem)

          })

          FinalArray[name][ParentIndex]["Result"] = round(100 * FinalArray[name][ParentIndex]["WeightComplete"] / FinalArray[name][ParentIndex]["TotalWeight"])
          FinalArray[name][ParentIndex]["Result"] = FinalArray[name][ParentIndex]["Result"] && FinalArray[name][ParentIndex]["Result"] !== "NaN" ? FinalArray[name][ParentIndex]["Result"] : 0


        })


        FinalArray[name].sort((a, b) => (a.ArrayLastNumber > b.ArrayLastNumber) ? 1 : -1)
        FinalArray[name].forEach(item => {
          item["Repairs"].sort((a, b) => (a.ArrayLastNumber > b.ArrayLastNumber) ? 1 : -1)
        })
      } catch (e) {
        // console.log(e)
      }

    }

    else if (e[0] == "ToolBox") {
      try {
        //  console.log(e)
        if (!FinalArray[name]) {
          FinalArray[name] = []
        }

        let allChildren = []
        //   console.log(e[1])
        let SearchForName = e[1]
        //  console.log(SearchForName)
        if (SearchForName.endsWith(".")) {
          //  console.log(SearchForName)
          Object.keys(tasks).forEach(element => {
            if (element.startsWith(SearchForName)) {

              allChildren.push(element)
            }
          })
        } else {
          SearchForName += "."
          //   console.log(SearchForName)
          Object.keys(tasks).forEach(element => {
            if (element.startsWith(SearchForName)) {

              allChildren.push(element)
            }
          })
        }
        //   console.log(allChildren)

        //     console.log(allChildren)

        let AmountOfRepairs = allChildren.filter(item => {
          return item.match(/\./g).length == 5
        })
        //  console.log(AmountOfRepairs)
        //    console.log(AmountOfRepairs)
        //  console.log(allChildren)
        // console.log(AmountOfRepairs)
        AmountOfRepairs.map((ParentItem, ParentIndex) => {

          let some = ParentItem.split(".")
          let SingleNumber = some[some.length - 1]
          some = ParentItem.split(".")
          FinalArray[name][ParentIndex] = {}
          FinalArray[name][ParentIndex]["Tasks"] = []
          FinalArray[name][ParentIndex]["pictures"] = []
          FinalArray[name][ParentIndex]["TotalWeight"] = 0
          FinalArray[name][ParentIndex]["WeightComplete"] = 0
          FinalArray[name][ParentIndex]["complete"] = 0
          FinalArray[name][ParentIndex]["total"] = 0
          FinalArray[name][ParentIndex]["ArrayNumber"] = ParentItem
          FinalArray[name][ParentIndex]["ArrayLastNumber"] = parseInt(SingleNumber)
          FinalArray[name][ParentIndex]["FatherNumber"] = e[1]





          //    console.log(tasks[ParentItem][2])
          tasks[ParentItem][2].map(item => {
            //console.log(ChildItem)
            //  console.log("itemnumber" + " " + item)


            if (tasks[item] && tasks[item][12] && tasks[item][12].some(el => Tags.includes(el))) {
              // console.log("test")



              function addToList() {


                if (tasks[item][4]) {
                  tasks[item][4].forEach((Pic, indexPic) => {
                    FinalArray[name][ParentIndex]["pictures"].push([tasks[item][4][indexPic], tasks[item][5][indexPic]])
                  })

                }

                if (!tasks[item][2]) {
                  //  pushThisItem["total"]++
                  FinalArray[name][ParentIndex]["total"]++
                  let additionalWeight = 1

                  function checkFather(FatherName, ChildBay) {
                    //    console.log(FatherName)
                    let ElementName = FatherName.split('.')
                    if (ChildBay == 0) {
                      ChildBay = ElementName.pop()
                    } else {
                      let newChildBay = ElementName.pop()
                      ChildBay = newChildBay + "." + ChildBay
                    }
                    //  console.log(ChildBay)
                    ElementName = ElementName.join('.')
                    // console.log(FatherName)
                    //console.log(ChildBay)
                    if (tasks[FatherName] && !tasks[FatherName][12].includes(58885)) {
                      //  console.log("didnt")
                      //   console.log(ElementName)
                      return checkFather(ElementName, ChildBay)
                    } else {
                      //  console.log("final")
                      ChildBay = ChildBay.split(".")
                      ChildBay.shift()
                      ChildBay = ChildBay.join(".")
                      //    console.log(ChildBay)
                      let finalName = FatherName.split('.')
                      finalName.pop()
                      finalName = finalName.join('.')
                      finalName = finalName + ".1." + ChildBay
                      return finalName


                    }

                  }

                  //2.6.2.44.15.6
                  let result = checkFather(item, 0)
                  //    console.log("result" + " " + result)
                  // console.log(result)

                  if (Weight[result] || Weight[result] == 0) {
                    //   console.log(Weight[result])
                    additionalWeight = Weight[result]
                  } else if (Weight[item] || Weight[item] == 0) {
                    additionalWeight = Weight[item]
                  } else {
                    additionalWeight = 1
                  }
                  // console.log(checkFather(item))





                  if (Weight && (Weight.hasOwnProperty(item) || Weight[item] == 0)) {
                    additionalWeight = Weight[item]
                  }


                  //   pushThisItem["TotalWeight"] += additionalWeight  //+= item[2]
                  FinalArray[name][ParentIndex]["TotalWeight"] += additionalWeight

                  if (tasks[item][1] || tasks[item][1] == 100) {
                    //   pushThisItem["complete"]++
                    FinalArray[name][ParentIndex]["complete"]++
                    //   pushThisItem["WeightComplete"] += additionalWeight // += item[2]
                    FinalArray[name][ParentIndex]["WeightComplete"] += additionalWeight
                  } else {
                    //   pushThisItem["uncompleted"]++
                    FinalArray[name][ParentIndex]["uncompleted"]++
                  }


                }

                FinalArray[name][ParentIndex]["Tasks"].push(item)


              }



              addToList()



            }








            //    FinalArray[name][ParentIndex]["ToolBox"].push(pushThisItem)

          })

          FinalArray[name][ParentIndex]["Result"] = round(100 * FinalArray[name][ParentIndex]["WeightComplete"] / FinalArray[name][ParentIndex]["TotalWeight"])
          FinalArray[name][ParentIndex]["Result"] = FinalArray[name][ParentIndex]["Result"] && FinalArray[name][ParentIndex]["Result"] !== "NaN" ? FinalArray[name][ParentIndex]["Result"] : 0


        })
        //  console.log(FinalArray[name])
        FinalArray[name].sort((a, b) => (a.ArrayLastNumber > b.ArrayLastNumber) ? 1 : -1)
        //console.log(FinalArray[name])

      } catch (e) {
        // console.log(e)
      }

    }




    else if (e[0] == "BayComment") {
      //console.log(e)
      FinalArray[name] = []
      // console.log([e[1]])
      //  console.log(e)
      if (typeof (tasks[e[1]]) !== "undefined") {
        /* console.log(e) */
        //    console.log(e)
        let removeAtBeginning = e[2] + ".1"

        let newStr = e[1];


        newStr = newStr.replace(removeAtBeginning, "");

        FinalArray[name] = newStr

      } else {
        FinalArray[name] = false
        FinalArray[name + "Result"] = false
        return false
      }
    }

    else if (e[0] == "BayFile") {

      FinalArray[name + "Result"] = []
      if (typeof (tasks[e[1]]) !== "undefined") {



        let newName = e[2] + "."
        function remove_first_occurrence(str, searchstr) {
          var index = str.indexOf(searchstr);
          if (index === -1) {
            return str;
          }
          return str.slice(0, index) + str.slice(index + searchstr.length);
        }
        function remove_first_occurrenceTwo(str, searchstr) {
          var index = str.indexOf(searchstr);
          if (index === -1) {
            return str;
          }
          return str.slice(index + searchstr.length);
        }
        let newtest = remove_first_occurrence(e[1], newName)
        var newtestTwo = remove_first_occurrenceTwo(newtest, ".")

        if (tasks[e[2]]) {

          let childs = tasks[e[2]][2]

          if (childs) {
            childs.map((item, Index) => {

              let newcounter = Index + 1
              /*  console.log(newName)
               console.log(newcounter)
               console.log(newtestTwo)
               console.log(newName + newcounter + "." + newtestTwo) */
              if (tasks[newName + newcounter + "." + newtestTwo]) {

                FinalArray[name + "Result"].push([tasks[newName + newcounter + "." + newtestTwo][4][0], tasks[newName + newcounter + "." + newtestTwo][5][0]])
              }


            })
          }

        }


      } else {
        FinalArray[name + "Result"] = false
        return false
      }


    }

    else if (e[0] == "Percentage") {

      if (!FinalArray[name]) {
        FinalArray[name] = {}
      }

      FinalArray[name]["TotalWeight"] = 0
      FinalArray[name]["WeightComplete"] = 0
      FinalArray[name]["complete"] = 0
      FinalArray[name]["total"] = 0
      FinalArray[name]["Tasks"] = []
      FinalArray[name]["uncompleted"] = 0
      FinalArray[name]["pictures"] = []

      let allChildren = []

      if (typeof e[1] !== "string") {
        return
      }
      if (e[1] == "all") {
        //    console.log("test2")
        Object.keys(tasks).forEach(element => {
          allChildren.push(element)
        })
      } else {
        let SearchForName = e[1]
        //  console.log(SearchForName)
        if (SearchForName.endsWith(".")) {
          //  console.log(SearchForName)
          Object.keys(tasks).forEach(element => {
            if (element.startsWith(SearchForName)) {

              allChildren.push(element)
            }
          })
        } else {
          SearchForName += "."
          //   console.log(SearchForName)
          Object.keys(tasks).forEach(element => {
            if (element.startsWith(SearchForName)) {

              allChildren.push(element)
            }
          })
        }


        if (e[2]) {
          e[2].map(Additem => {
            //   console.log(Additem["value"])
            if (!allChildren[Additem]) {
              allChildren.push(Additem["value"])
            }
          })
        }
      }
      //  console.log(allChildren.length)

      /* console.log(Tags) */
      let TheseTags = []
      if (e[3]) {
        e[3].map(tagEl => {
          TheseTags.push(parseInt(tagEl["value"]))
        })
      }
      /*  TheseTags = TheseTags.filter(elem => Tags.includes(elem)) */
      /*   console.log(TheseTags)
        console.log(Tags) */
      FinalArray[name]["Tags"] = TheseTags
      try {

        allChildren.map(item => {
          if (tasks[item] && tasks[item][12] &&
            (tasks[item][12].some(el => TheseTags.includes(el)) && tasks[item][12].some(el => Tags.includes(el))
              || (TheseTags.length == 0 && tasks[item][12].some(el => Tags.includes(el)))
            )
          ) {



            function addToList() {
              //   name == "Training" && console.log(item)
              if (tasks[item][4]) {
                tasks[item][4].forEach((Pic, indexPic) => {
                  FinalArray[name]["pictures"].push([tasks[item][4][indexPic], tasks[item][5][indexPic]])
                })

              }


              //yesno
              if (!tasks[item][2] || tasks[item][12].includes(58572)) {

                /*   if (tasks[item][12].includes(58604)) {
  
                  } */
                FinalArray[name]["total"]++
                let additionalWeight = 1
                if (tasks[item][12].includes(58604)) {
                  function checkFather(FatherName, ChildBay) {
                    //    console.log(FatherName)
                    let ElementName = FatherName.split('.')
                    if (ChildBay == 0) {
                      ChildBay = ElementName.pop()
                    } else {
                      let newChildBay = ElementName.pop()
                      ChildBay = newChildBay + "." + ChildBay
                    }
                    //  console.log(ChildBay)
                    ElementName = ElementName.join('.')
                    // console.log(FatherName)
                    //console.log(ChildBay)
                    if (tasks[FatherName] && !tasks[FatherName][12].includes(58561)) {
                      //  console.log("didnt")
                      //   console.log(ElementName)
                      return checkFather(ElementName, ChildBay)
                    } else {
                      //  console.log("final")
                      ChildBay = ChildBay.split(".")
                      ChildBay.shift()
                      ChildBay = ChildBay.join(".")
                      //    console.log(ChildBay)
                      let finalName = FatherName.split('.')
                      finalName.pop()
                      finalName = finalName.join('.')
                      finalName = finalName + ".1." + ChildBay
                      return finalName

                      /*  console.log(ElementName)
                       console.log(ChildBay) */
                      // console.log("final")
                    }

                  }

                  //2.6.2.44.15.6
               /*    item.startsWith("2.6.4") && console.log(item)
                */   let result = checkFather(item, 0)


                  if (Weight[result] || Weight[result] == 0) {
                    //   console.log(Weight[result])
                    additionalWeight = Weight[result]
                  } else if (Weight[item] || Weight[item] == 0) {
                    additionalWeight = Weight[item]
                  } else {
                    additionalWeight = 1
                  }
                  // console.log(checkFather(item))
                  /*  if (item.startsWith("2.8.2.6.18")) {
                     console.log(item)
                     console.log(result)
                     console.log(additionalWeight)
                     } */

                }
                else if (tasks[item][12].includes(58511)) {
                  //  console.log(item)
                  let itemcheck = item.split(".")
                  let CheckThisItem = itemcheck.pop()
                  //  console.log(CheckThisItem)
                  // console.log(4 + ".1" + ".1" + ".1." + CheckThisItem)
                  //  console.log(Weight[4 + ".1" + ".1" + ".1." + CheckThisItem])
                  if (Weight[4 + ".1" + ".1" + ".1." + CheckThisItem] || Weight[4 + ".1" + ".1" + ".1." + CheckThisItem] == 0) {
                    // console.log(Weight[4 + ".1" + ".1" + ".1." + CheckThisItem])
                    additionalWeight = Weight[4 + ".1" + ".1" + ".1." + CheckThisItem]
                  } else if (Weight[item] || Weight[item] == 0) {
                    additionalWeight = Weight[item]
                  } else {
                    additionalWeight = 1
                  }
                  // console.log(checkFather(item))

                }

                else if (Weight && (Weight.hasOwnProperty(item) || Weight[item] == 0)) {
                  /*      console.log(Weight[item[0]]) */
                  additionalWeight = Weight[item]
                }

                FinalArray[name]["TotalWeight"] += additionalWeight  //+= item[2]
                if (tasks[item][1] /* || tasks[item[0]][10] == 20 */ || tasks[item][1] == 100) {
                  FinalArray[name]["complete"]++
                  FinalArray[name]["WeightComplete"] += additionalWeight // += item[2]
                } else {
                  FinalArray[name]["uncompleted"]++
                }
              }
              //     name == "TotalAuditScore" && console.log("included " + item)
              FinalArray[name]["Tasks"].push(item)


            }
            function checkIfFirstChild(FatherName) {
              //  console.log(FatherName)
              let ElementName = FatherName.split('.')
              let ChildBay = ElementName.pop()
              ElementName = ElementName.join('.')
              if (FatherName.length == 1) {
                return true
              }

              if (tasks[FatherName] && (!tasks[FatherName][12].includes(58561))) {

                return checkIfFirstChild(ElementName)
              } else {
                if (ChildBay == "1") {
                  return true
                } else {
                  /* if(tasks[ElementName][7] ==  "Mechanical Work Bays" ) {
                    return false
                  } */
                  if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Mechanical Work Bays" && !tasks[item][12].includes(58481))) {

                    return true
                  }
                  if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Remove + Refit Area" && !tasks[item][12].includes(58184))) {

                    return true
                  }
                  if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Light Body Repair Bays" && !tasks[item][12].includes(58480))) {
                    return true
                  }
                  if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Heavy Body Repair Bays" && !tasks[item][12].includes(58479))) {
                    return true
                  }
                  if (tasks[FatherName] && tasks[item][12].includes(58583) && (tasks[FatherName][7] == "Aluminium Body Repair Bays" && !tasks[item][12].includes(58485))) {
                    return true
                  }
                  if (tasks[FatherName] && tasks[FatherName][7] == "Preparation Bays" && !tasks[item][12].includes(58610)) {
                    //   console.log(item)
                    return true
                  }
                  if (tasks[FatherName] && tasks[FatherName][7] == "Masking Bays" && !tasks[item][12].includes(58609)) {
                    // console.log(item)
                    return true
                  }
                  if (tasks[FatherName] && tasks[FatherName][7] == "Polishing Bays" && !tasks[item][12].includes(58611)) {
                    return true
                  }
                  /*  if (tasks[FatherName] && tasks[FatherName][7] == "Polishing Bays" && !tasks[item][12].includes(58611)) {
                     return true
                   }
                   if (tasks[FatherName] && tasks[FatherName][7] == "Polishing Bays" && !tasks[item][12].includes(58611)) {
                     return true
                   } */
                  /*  if (tasks[FatherName] && tasks[FatherName][12].includes(58885)) {
                     return true
                   } */
                  return false
                }
              }
            }

            // bayquestion
            if (tasks[item][12].includes(58604)) {
              //    console.log(item)
              //  console.log(item)
              if (checkIfFirstChild(item)) {
                //    console.log("test")
                return
              }
            }

            function checkIfFirstChildTwo(FatherName) {
              //  console.log(FatherName)
              let ElementName = FatherName.split('.')
              let ChildBay = ElementName.pop()
              ElementName = ElementName.join('.')
              if (FatherName.length == 1) {
                return true
              }

              if (tasks[FatherName] && (!tasks[FatherName][12].includes(58885))) {
                return checkIfFirstChildTwo(ElementName)
              } else {
                if (ChildBay == "1") {
                  return true
                } else {
                  return false
                }
              }
            }

            if (tasks[item][12].includes(58862)) {
              if (checkIfFirstChildTwo(item)) {
                return
              }
            }
            function getfather(ElementName) {
              if (!tasks[ElementName][12].includes(58572)) {
                ElementName = ElementName.split('.')
                ElementName.pop()
                ElementName = ElementName.join('.')
                return getfather(ElementName)
              } else {
                if (tasks[ElementName][7] == "yes") {
                  return true
                } else {
                  return false
                }
              }
            }

            //repairquality

            if (tasks[item][12].includes(58511)) {

              //   console.log(item)
              /* let name = item.split(".")
             
              name.pop()
              name.pop()
              let parentItem = name.join(".")
              //    console.log(parentItem)
              name.pop()
              let GrandParentItem = name.join(".")
              console.log(item)
                 console.log(GrandParentItem)
                 console.log(tasks[GrandParentItem][2])
                 console.log(parentItem) */
              if (
                /* tasks[GrandParentItem][2][tasks[GrandParentItem][2].length - 1] == parentItem && parentItem !== "4.1.1"
                 */
                !item.startsWith("4.1.1")
              ) {

              } else {

                return
              }

            }
            //58808
            //conditional ?
            if (tasks[item][12].includes(58557)) {
              //      console.log(item)
              if (getfather(item)) {
                addToList()
              } else {
                FinalArray[name]["Tasks"].push(item)
              }
            } else {

              addToList()
            }

          } else if (tasks[item] && tasks[item][2]) {
            /*   name == "Business Information" && console.log(item) */
            FinalArray[name]["Tasks"].push(item)
          }
        })

        FinalArray[name + "Result"] = round(100 * FinalArray[name]["WeightComplete"] / FinalArray[name]["TotalWeight"])
        FinalArray[name + "Result"] = FinalArray[name + "Result"] && FinalArray[name + "Result"] !== "NaN" ? FinalArray[name + "Result"] : 0
        // return round(100 * arr["WeightComplete"] / arr["TotalWeight"]);
      } catch (error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }




    }

    else if (e[0] == "BayPercentage") {

      let removeAtBeginning = e[2] + ".1"

      let newStr = e[1];

      console.log(newStr)
      if (newStr == null) { return }
      newStr = newStr.replace(removeAtBeginning, "");



    }

    else if (e[0] == "Calculation") {

      /*   console.log(name == "ProjectedWeeklyJobsCalcSpecialOne" && e) */
      const checkElement = (Elem) => {
        if (tasks[Elem]) {
          if (!isNaN(parseFloat(tasks[Elem][7]))) {
            return parseFloat(tasks[Elem][7])
          }
        } else if (Elem == "RemoveRefitBay") {
          return FinalArray["MechanicalAndBodyRepairWorkBays"] ? FinalArray["MechanicalAndBodyRepairWorkBays"]["RemoveRefitBay"].length : 0
        } else if (Elem == "GeneralRepairBay") {
          return FinalArray["MechanicalAndBodyRepairWorkBays"] ? FinalArray["MechanicalAndBodyRepairWorkBays"]["GeneralRepairBay"].length : 0
        } else if (Elem == "HeavyRepairBay") {
          return FinalArray["MechanicalAndBodyRepairWorkBays"] ? FinalArray["MechanicalAndBodyRepairWorkBays"]["HeavyRepairBay"].length : 0
        } else if (Elem == "AluminiumBay") {
          return FinalArray["MechanicalAndBodyRepairWorkBays"] ? FinalArray["MechanicalAndBodyRepairWorkBays"]["AluminiumBay"].length : 0
        } else if (Elem == "PreparationBayStandard") {
          return FinalArray["PreparationBayStandard"] ? FinalArray["PreparationBayStandard"] : 0
        } else if (Elem == "PreparationBaySemi") {
          return FinalArray["PreparationBaySemi"] ? FinalArray["PreparationBaySemi"] : 0
        } else if (Elem == "PreparationBayFull") {
          return FinalArray["PreparationBayFull"] ? FinalArray["PreparationBayFull"] : 0
        } else if (Elem == "MaskingBay") {
          return FinalArray["PaintShopBaysCount"] ? FinalArray["PaintShopBaysCount"]["MaskingBay"].length : 0
        } else if (Elem == "SprayBooth") {
          return FinalArray["SprayBoothBay"] ? FinalArray["SprayBoothBay"]["SprayBooth"].length : 0
        } else if (Elem == "PolishingBay") {
          return FinalArray["PaintShopBaysCount"] ? FinalArray["PaintShopBaysCount"]["PolishingBay"].length : 0
        } else if (Elem == "WashBaysResult") {
          return FinalArray["WashBaysResult"] && parseInt(FinalArray["WashBaysResult"]) > 0 ? 1 : 0
        } else if (Elem == "MechanicalBay") {
          return FinalArray["MechanicalAndBodyRepairWorkBays"] ? FinalArray["MechanicalAndBodyRepairWorkBays"]["MechanicalBay"].length : 0
        } else if (Elem == "EstimatingBaysOneResult") {
          return FinalArray["EstimatingBaysOneResult"] ? parseInt(FinalArray["EstimatingBaysOneResult"]) : 0
        }
        else if (Elem == "SprayBoothSeparateDrying") {
          return FinalArray["SprayBoothSeparateDrying"] ? parseInt(FinalArray["SprayBoothSeparateDrying"]) : 0
        } else if (Elem == "CombiSprayBoothStandard") {
          return FinalArray["CombiSprayBoothStandard"] ? parseInt(FinalArray["CombiSprayBoothStandard"]) : 0
        } else if (Elem == "CombiSprayBoothInfrared") {
          return FinalArray["CombiSprayBoothInfrared"] ? parseInt(FinalArray["CombiSprayBoothInfrared"]) : 0
        }
        else if (Elem == "TotalWorkingHoursBy8") {
          return parseInt(FinalArray["TotalWorkingHoursBy8Result"])
        } else if (Elem == "CombiSprayBoothStandard") {
          return parseInt(FinalArray["CombiSprayBoothStandard"])
        }
        else if (Elem == "CombiSprayBoothInfrared") {
          return parseInt(FinalArray["CombiSprayBoothInfrared"])
        }
        else {
          return false
        }

      }



      let sum = 0
      let allChildren = []
      if (e[1]) {
        if (e[1] == "all") {
          //    console.log("test2")
          Object.keys(tasks).forEach(element => {
            allChildren.push(element)
          })
        } else {
          try {
            let SearchForName = e[1]
            //  console.log(SearchForName)
            console.log(SearchForName)
            if (SearchForName.endsWith(".")) {
              //  console.log(SearchForName)
              Object.keys(tasks).forEach(element => {
                if (element.startsWith(SearchForName)) {

                  allChildren.push(element)
                }
              })

            } else {
              SearchForName += "."
              //   console.log(SearchForName)
              Object.keys(tasks).forEach(element => {
                if (element.startsWith(SearchForName)) {

                  allChildren.push(element)
                }
              })
            }
          } catch (e) {
            console.log(e)
          }
        }


      }

      //   console.log(e)
      if (e[4]) {

        e[4].map(Additem => {

          //   console.log(Additem["value"])
          if (!allChildren[Additem]) {
            allChildren.push(Additem["value"])
          }
        })
      }
      //   console.log(allChildren)
      allChildren.map((item) => {
        if (checkElement(item)) {
          sum += checkElement(item)
        }


      })
      /*   console.log(name == "ProjectedWeeklyJobsCalcSpecialOne" && sum) */

      //   console.log(sum)
      // console.log(e)

      if (e[2]) {
        if (checkElement(e[2][0])) {
          sum = sum / checkElement(e[2][0])
        }
      }
      /*   console.log(name == "ProjectedWeeklyJobsCalcSpecialOne" && sum)
        console.log(name == "ProjectedWeeklyJobsCalcSpecialOne" && checkElement(e[7][0])) */
      if (e[7]) {
        if (checkElement(e[7][0]) || checkElement(e[7][0]) == 0) {
          sum = sum * checkElement(e[7][0])
        }
      }
      /*     console.log(name == "ProjectedWeeklyJobsCalcSpecialOne" && sum) */
      if (e[3] || e[3] == 0) {
        sum = sum * parseFloat(e[3])
      }
      if (e[6]) {
        sum = sum / parseFloat(e[6])

      }
      // console.log(sum)
      FinalArray[name + "Result"] = sum


      //  console.log(sum)
      return sum
    }
  }

}

export default MainFunction

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PieChart from "./ProjectElementPie";
import Progressbar from "./progressbar";
import Owldemo4 from "./reactcarousel4";
import openTaskList from "../sharedFunctions.js/openTaskList";
import axios from "axios";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import checkPercentage from '../sharedFunctions.js/checkpercentage'


const ShowRepairQuality = (props) => {
  const [WaitingForResponse, SetWaitingForResponse] = useState(false);
  const [Block, SetBlock] = useState(false);

  const [AddableTest, SetAddableTest] = useState(true);
  const [AddableRepair, SetAddableRepair] = useState(true);
  const [Deleteable, SetDeleteable] = useState(true);
  const [Test, SetTest] = useState(true);

  let history = useHistory();
  const location = useLocation();
  //  console.log(props)

  useEffect(() => {
    if (props.FinalArray.length > 0)
      SetTest(props.FinalArray["GroupedbyDate"][0].total);
  });

  const openModal = (e, b, allowed) => {
    props.setOnlyMilestone("false");
    props.setMilestoneTranslation([]);
    props.setTranslationModal(e);
    props.SetTranslationAddon(b);

    /*  console.log(e) */
  };

  let test;
  if (
    props.FinalArray["GroupedbyDate"] &&
    props.FinalArray["GroupedbyDate"][1] &&
    props.FinalArray["GroupedbyDate"][1].total
  ) {
    test = props.FinalArray["GroupedbyDate"][1].total;
  }

  const handleClick = (name) => {
    if (!props.noTeamwork) {
      SetAddableTest(false);
      SetDeleteable(false);
    }
    if (props.noTeamwork) {
      SetDeleteable(false);
      SetAddableRepair(false);
      SetAddableTest(false);
    }

    //SetBlock(true)
    // SetWaitingForResponse(true)

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    let lastArrayNumber =
      props.FinalArray["GroupedbyDate"][
        props.FinalArray["GroupedbyDate"].length - 1
      ]["ArrayNumber"];
    // console.log(lastArrayNumber)
    lastArrayNumber = lastArrayNumber.split(".");
    lastArrayNumber.shift();
    // console.log(lastArrayNumber[0])
    lastArrayNumber = parseInt(lastArrayNumber) + 1;

    console.log(
      props.FinalArray["GroupedbyDate"][
        props.FinalArray["GroupedbyDate"].length - 1
      ]["FatherNumber"] + ".1"
    );
    //console.log([props.FinalArray["GroupedbyDate"][props.FinalArray["GroupedbyDate"].length - 1]["FatherNumber"] + "1"][0])
    // this here  console.log(props.tasks[props.tasks[props.FinalArray["MechanicalAndBodyRepairWorkBaysResult"][0]][2][0]][0])

    //   console.log(props.tasks[props.FinalArray["GroupedbyDate"][props.FinalArray["GroupedbyDate"].length - 1]["FatherNumber"] + "1"][0])
    let PostData = {
      TaskToCopy:
        props.tasks[props.FinalArray["GroupedbyDate"][0]["ArrayNumber"]][0],
      parentTaskID:
        props.tasks[
          props.FinalArray["GroupedbyDate"][
            props.FinalArray["GroupedbyDate"].length - 1
          ]["FatherNumber"]
        ][0],
      projectID: props.idd,
      tasklistNumber: props.TasklistNumber,
      Date: format(new Date(), "dd.MM.yyyy"),
      keyOfTask: props.FinalArray["GroupedbyDate"][0]["ArrayNumber"],
      parentTaskKey:
        props.FinalArray["GroupedbyDate"][
          props.FinalArray["GroupedbyDate"].length - 1
        ]["FatherNumber"],
    };
    console.log([props.FinalArray["GroupedbyDate"][0]["ArrayNumber"]][0]);
    console.log(
      props.tasks[props.FinalArray["GroupedbyDate"][0]["ArrayNumber"]]
    );
    console.log(
      props.tasks[props.FinalArray["GroupedbyDate"][0]["ArrayNumber"]]
    );
    console.log(PostData);
    const filtered = Object.entries(props.tasks);
    console.log(filtered);
    let getArrayNumber =
      props.tasks[
        props.FinalArray["GroupedbyDate"][
          props.FinalArray["GroupedbyDate"].length - 1
        ]["FatherNumber"]
      ];
    console.log();
    let lastChild;
    if (getArrayNumber[2].length - 1 == -1) {
      console.log(getArrayNumber[2]);
      lastChild = 2;
    } else {
      console.log(getArrayNumber[2][getArrayNumber[2].length - 1]);
      lastChild = getArrayNumber[2][getArrayNumber[2].length - 1];
      console.log(lastChild);
      lastChild = lastChild.split(".");
      lastChild = parseInt(lastChild[lastChild.length - 1]) + 1;
    }

    let ReplaceThis =
      props.FinalArray["GroupedbyDate"][
        props.FinalArray["GroupedbyDate"].length - 1
      ]["FatherNumber"] + ".1";

    /*   console.log(lastChild) */
    let FatherNumber =
      props.FinalArray["GroupedbyDate"][
        props.FinalArray["GroupedbyDate"].length - 1
      ]["FatherNumber"] + ".1";
    console.log(FatherNumber);
    console.log(name);
    let NewName = name + "." + lastChild;
    console.log(NewName);
    console.log(filtered);
    let CheckForThis = FatherNumber + ".";
    let AllChildren = filtered.filter((e) => {
      return e[0].startsWith(CheckForThis) || e[0] == FatherNumber;
    });
    console.log(AllChildren);
    // console.log(AllChildren)
    console.log(ReplaceThis);
    console.log(NewName);
    AllChildren.map((item, index) => {
      if (index == 0) {
        item[1][7] = format(new Date(), "dd.MM.yyyy");
      }
      if (index == 1) {
        item[1][7] = "Loading";
      }
      console.log(item[0]);
      item[0] = item[0].replace(ReplaceThis, NewName);
    });
    console.log(AllChildren);
    const obj = Object.fromEntries(AllChildren);
    //  let result = text.replace(/blue/g, "red");
    console.log(obj);
    async function send() {
      try {
        const data = await axios.post(
          `/api/private/queue/repaircreate`,
          PostData,
          config
        );
        console.log(data.data);
        SetWaitingForResponse(false);

        if (data.data.tasklist) {
          if (props.noTeamwork) {
            SetDeleteable(true);
            SetAddableRepair(true);
            SetAddableTest(true);
          }
          console.log(data.data);
          props.setTasks((prevState) => data.data.tasklist);
        } else {
          let Arr =
            props.tasks[
              props.FinalArray["GroupedbyDate"][
                props.FinalArray["GroupedbyDate"].length - 1
              ]["FatherNumber"]
            ];
          Arr[2].push(NewName);
          console.log(Arr);
          console.log(NewName);
          props.setTasks((prevState) => ({
            ...prevState,
            [props.FinalArray["GroupedbyDate"][0]["FatherNumber"]]: Arr,
            ...obj,
          }));
        }
        return data;
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    }

    send().then((item) => {});
  };

  const createNewRepair = (name, FatherNumber) => {
    if (!props.noTeamwork) {
      SetAddableRepair(false);
      SetDeleteable(false);
    }
    if (props.noTeamwork) {
      SetDeleteable(false);
      SetAddableRepair(false);
      SetAddableTest(false);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    let PostData = {
      parentTasksName: props.tasks[name][3],
      parentTaskID: props.tasks[name][0],
      parentTaskKey: name,
      projectID: props.idd,
      TaskToCopy: props.tasks[FatherNumber + ".1" + ".1"][0],
      keyOfTask: FatherNumber + ".1" + ".1",
      tasklistNumber: props.TasklistNumber,
    };

    //   console.log(FatherNumber)
    //   console.log(name)

    //  console.log(PostData)
    //  console.log(PostData)
    const filtered = Object.entries(props.tasks);
    //  console.log(filtered)
    let getArrayNumber = props.tasks[name];
    let lastChild = (getArrayNumber =
      getArrayNumber[2][getArrayNumber[2].length - 1]);
    let ReplaceThis =
      props.FinalArray["GroupedbyDate"][
        props.FinalArray["GroupedbyDate"].length - 1
      ]["FatherNumber"] + ".1";
    //  console.log(lastChild)
    lastChild = lastChild.split(".");
    lastChild = parseInt(lastChild[lastChild.length - 1]) + 1;
    // console.log(lastChild)
    //  let FatherNumber = props.FinalArray["GroupedbyDate"][props.FinalArray["GroupedbyDate"].length - 1]["FatherNumber"] + ".1"
    // console.log(FatherNumber)
    let searchForThis = FatherNumber + ".1";
    /*   console.log(searchForThis)
          console.log(filtered) */
    let AllChildren = filtered.filter((e) => {
      return e[0].startsWith(searchForThis);
    });
    //   console.log(AllChildren)

    let NewName = name + "." + lastChild;
    //  console.log(NewName)

    // console.log(AllChildren)
    AllChildren.map((item, index) => {
      if (index == 0) {
        item[1][7] = format(new Date(), "dd.MM.yyyy");
      }
      if (index == 1) {
        item[1][7] = "Loading";
      }
      item[0] = item[0].replace(ReplaceThis, NewName);
    });
    //  console.log(AllChildren)
    const obj = Object.fromEntries(AllChildren);
    //  let result = text.replace(/blue/g, "red");
    //   console.log(obj)

    async function send() {
      try {
        const data = await axios.post(
          `/api/private/queue/repairtestcreate`,
          PostData,
          config
        );
        console.log(data.data);
        SetWaitingForResponse(false);
        if (data.data.tasklist) {
          if (props.noTeamwork) {
            SetDeleteable(true);
            SetAddableRepair(true);
            SetAddableTest(true);
          }
          console.log(data.data.tasklist);
          props.setTasks((prevState) => data.data.tasklist);
        } else {
          let Arr = props.tasks[name];
          Arr[2].push(NewName);
          console.log(obj);
          console.log(Arr);
          props.setTasks((prevState) => ({
            ...prevState,
            [name]: Arr,
            ...obj,
          }));
        }
        return data;
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    }

    send().then((item) => {});
  };

  const handleDeleteRepair = (name, ArrayNumber) => {
    if (!props.noTeamwork) {
      SetAddableRepair(false);
      SetAddableTest(false);
    }
    if (props.noTeamwork) {
      SetDeleteable(false);
      SetAddableRepair(false);
      SetAddableTest(false);
    }

    console.log(name);
    /* console.log(ArrayNumber) */
    console.log(name["ArrayNumber"]);
    let isExecuted = window.confirm("Are you sure you want to delete?");
    if (!isExecuted) {
      return;
    }

    /*  let deleteThese = importDeleteObject(ArrayNumber) */

    /*   const newCar = Object.keys(props.tasks).reduce((object, key) => {
              if (!importDeleteObject.includes(key)) {
                object[key] = props.tasks[key]
              }
              return object
            }, {})
            console.log(newCar)
            props.setTasks(newCar) */

    //  SetWaitingForResponse(true)

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    /*
        console.log(["MechanicalAndBodyRepairWorkBaysResult"][0]) */
    //   console.log(name)
    //   console.log(props.tasks[name][0])
    let currentTaskID = props.tasks[name["ArrayNumber"]][0];
    console.log(currentTaskID);
    let PostData = {
      //  currentTaskID: props.tasks[name["ArrayNumber"]][0],
      projectID: props.idd,
      keyOfTask: name,
      // TaskID :
    };
    //    console.log(PostData)
    async function send() {
      try {
        const data = await axios.put(
          `/api/private/queue/repairdelete/${currentTaskID}`,
          PostData,
          config
        );
        //          console.log(data.data)
        SetWaitingForResponse(false);

        if (data.data.tasklist) {
          if (props.noTeamwork) {
            SetDeleteable(true);
            SetAddableRepair(true);
            SetAddableTest(true);
          }
          console.log(data.data);
          props.setTasks((prevState) => data.data.tasklist);
        } else {
          let Arr = props.tasks[name["FatherNumber"]];
          console.log(Arr);
          console.log(name["ArrayNumber"]);
          const index = Arr[2].indexOf(name["ArrayNumber"]);
          if (index > -1) {
            Arr[2].splice(index, 1);
          }
          console.log(Arr);
          const filtered = Object.keys(props.tasks);
          let AllChildren = filtered.filter((e) => {
            return e.startsWith(name["ArrayNumber"]);
          });
          console.log(AllChildren);

          props.setTasks((prevState) => {
            let newData = { ...prevState };
            newData[name["ArrayNumber"]] = Arr;
            AllChildren.map((item) => {
              delete newData[item];
            });
            return newData;
          });
        }
        return data;
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    }

    send().then((newObj) => {});
  };

  const handleDeleteRepairTest = (name, ArrayNumber) => {
    if (!props.noTeamwork) {
      SetAddableRepair(false);
      SetAddableTest(false);
    }

    /*   console.log(props.tasks[name["ArrayNumber"]][2])
          console.log(props.tasks[name["ArrayNumber"]][2][ArrayNumber])
          let parentName = name["Tasks"][0].split('.')
          parentName.pop()
          console.log(ArrayNumber)
          console.log(props.tasks[name["ArrayNumber"]][2][ArrayNumber]) */
    let thisItem = props.tasks[name["ArrayNumber"]][2][ArrayNumber];
    let ThisItemID = props.tasks[thisItem][0];
    let isExecuted = window.confirm("Are you sure you want to delete?");
    if (!isExecuted) {
      return;
    }
    if (props.noTeamwork) {
      SetDeleteable(false);
      SetAddableRepair(false);
      SetAddableTest(false);
    }
    // let deleteThese = importDeleteObject(ArrayNumber)

    /*   const newCar = Object.keys(props.tasks).reduce((object, key) => {
              if (!importDeleteObject.includes(key)) {
                object[key] = props.tasks[key]
              }
              return object
            }, {})
            console.log(newCar)
            props.setTasks(newCar) */

    //  SetWaitingForResponse(true)

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    /*
        console.log(["MechanicalAndBodyRepairWorkBaysResult"][0]) */
    //   console.log(name)
    //   console.log(props.tasks[name][0])
    //        let currentTaskID = props.tasks[name][0]

    let PostData = {
      //  currentTaskID: props.tasks[props.tasks[props.FinalArray["MechanicalAndBodyRepairWorkBaysResult"][0]][2][0]][0],
      projectID: props.idd,
      keyOfTask: name,
      // TaskID :
    };

    //    console.log(PostData)
    async function send() {
      try {
        const data = await axios.put(
          `/api/private/queue/repairtestdelete/${ThisItemID}`,
          PostData,
          config
        );
        //          console.log(data.data)
        SetWaitingForResponse(false);
        if (data.data.tasklist) {
          if (props.noTeamwork) {
            SetDeleteable(true);
            SetAddableRepair(true);
            SetAddableTest(true);
          }
          console.log(data.data);
          props.setTasks((prevState) => data.data.tasklist);
        } else {
          let Arr = props.tasks[name["ArrayNumber"]];
          console.log(Arr);
          console.log(props.tasks[name["ArrayNumber"]][2][ArrayNumber]);
          const index = Arr[2].indexOf(
            props.tasks[name["ArrayNumber"]][2][ArrayNumber]
          );
          if (index > -1) {
            Arr[2].splice(index, 1);
          }
          console.log(Arr);
          props.setTasks((prevState) => {
            let newData = { ...prevState };
            newData[name["ArrayNumber"]] = Arr;
            name["Tasks"].map((item) => {
              delete newData[item];
            });
            return newData;
          });
        }
        return data;
      } catch (e) {
        if (!props.dev) {
          localStorage.removeItem("authToken");
          window.location.href = "/logout";
        }
      }
    }
    const filtered = Object.entries(props.tasks);

    send().then((newObj) => {});
  };

  if (props.FinalArray["GroupedbyDate"]) {
    if (!props.Tags.includes(58511)) {
      return (
        <>
          <div id="Repair Quality" className="row heading-block">
            <div className="col-lg-12 d-flex pl-0">
              <h1 className="main-heading">
                <FormattedMessage
                  id="RepairQuality"
                  defaultMessage="Repair Quality"
                />{" "}

              </h1>
              {props.canEdit && !Block && AddableRepair ? (
                <div
                  className="add-circle-wrapper"
                  onClick={() =>
                    handleClick(
                      props.FinalArray["GroupedbyDate"][0]["FatherNumber"]
                    )
                  }
                >
                  <div className="content">
                    <i class="fas fa-plus"></i>
                    <span className="label-text">
                      <FormattedMessage
                        id="CreateNewQualityCheckDATE"
                        defaultMessage="Create New Quality Check DATE"
                      />
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {props.FinalArray["GroupedbyDate"].slice(1).map((Item, Index) => {
            if (Item.total == 0) {
            } else {
            }

            return (
              <>
                <div className="container-fluid plr-0 repair-quality">
                  <div className="row mobile-scroll-y">
                    {Item ? (
                      <div className="min-h img-text-block d-flex pos-rel">
                        <div
                    style={{
                      backgroundColor: "#808080b5",
                      zIndex: 10,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      position: "absolute",
                    }}
                  >
                    <div class="centered-text-overlay-bay">
                      <FormattedMessage
                        id="NotAvailable"
                        defaultMessage="Not Available"
                      />
                    </div>
                  </div>
                        <div className="content">
                          {Item["Date"] && (
                            <h5>
                              <b>{Item["Date"]}</b>
                            </h5>
                          )}
                          <h5>
                            <b><FormattedMessage
                        id="TotalQualityScore"
                        defaultMessage="Total Quality Score"
                      /></b>
                          </h5>
                          <p className="mg-b-0 small-text">
                            {Item.complete}/{Item.total}{" "}
                            <FormattedMessage
                              id="Taskscompleted"
                              defaultMessage="Tasks completed"
                            />
                          </p>
                          <div
                            style={{
                              width: "80%",
                              margin: "auto",
                              marginTop: "2rem",
                            }}
                          >
                            {" "}
                            <PieChart
                              className="printPie"
                              percentage={0}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    ) : (
                      "\u00A0"
                    )}
                    {Item["Repairs"].map((ChildItem, ChildIndex) => {
                      try {
                        let check = false;
                        // console.log(ChildItem.Tasks)
                        if (
                          ChildItem.Tasks.length == 0 ||
                          (ChildItem.Tasks[
                            ChildItem.Tasks.length - 1
                          ].startsWith(Item["FatherNumber"] + ".1") &&
                            ChildItem.Tasks[
                              ChildItem.Tasks.length - 1
                            ].startsWith(Item["FatherNumber"] + ".1."))
                        ) {
                          check = true;
                        }

                        /* item.Tasks.forEach(element => {
                                                    if (!element.startsWith(item.Name)) { check = true }

                                                }); */
                        if (ChildItem.total == 0) {
                          check = true;
                        }

                        //   console.log([ChildItem["ArrayNumber"] + ".1"])
                        let test = ChildItem["Tasks"][0].split(".");
                        test.pop();
                        test = test.join(".");
                        console.log(check);
                        return (
                          <>
                            {/*   {props.FinalArray["GroupedbyDate"][Index + 1]["ArrayNumber"] + "." + (ChildIndex + 1)}
                             */}{" "}
                            {!props.tasks[ChildItem["Tasks"][0]] ||
                            (props.tasks[ChildItem["Tasks"][0]][7] !==
                              "Loading" &&
                              /*  props.tasks[ChildItem["ArrayNumber"] + ".1"][7] !== "Loading"
                                                             && */
                              props.tasks[test][7] !== "Loading" &&
                              !check) ? (
                              <>
                                {/*  {ChildItem["ArrayNumber"]} */}
                                <div className="min-h img-text-block d-flex pos-rel">
                                  {props.canEdit &&
                                  !WaitingForResponse &&
                                  Item["Repairs"].length > 1 &&
                                  Deleteable ? (
                                    <div
                                      onClick={() =>
                                        handleDeleteRepairTest(
                                          ChildItem,
                                          ChildIndex
                                        )
                                      }
                                      className="delete-circle-wrapper"
                                    >
                                      <div className="content-inner">
                                        <i className=" fas fa-trash"></i>{" "}
                                        <span className="label-text">
                                          DELETE
                                        </span>
                                      </div>
                                    </div>
                                  ) : Item["Repairs"].length == 1 ? (
                                    <>
                                      {" "}
                                      <div style={{ width: "14px" }}>
                                        {" "}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <div style={{ width: "14px" }}></div>{" "}
                                    </>
                                  )}



                                  <div
                    style={{
                      backgroundColor: "#808080b5",
                      zIndex: 10,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      position: "absolute",
                    }}
                  >
                    <div class="centered-text-overlay-bay">
                      <FormattedMessage
                        id="NotAvailable"
                        defaultMessage="Not Available"
                      />
                    </div>
                  </div>

                  <Owldemo4
                    noTeamwork={props.noTeamwork}
                    setCurrentImg={props.setCurrentImg}
                    setModalImage={props.setModalImage}
                    Pictures={[]}
                  />
                                  <Progressbar width={0} />
                                  <div className="content">
                    <h5>Check {ChildIndex + 1}</h5>
                    <p className="mg-b-0 small-text">0/0 <FormattedMessage
                          id="Taskscompleted"
                          defaultMessage="Tasks completed"
                        /></p>
                    <div className="stats">0 %</div>
                    <div className="d-flex mg-t-20">
                      <div style={{ padding: "8px 0px", margin: "0" }}>
                        <FormattedMessage
                          id="ShowTasks"
                          defaultMessage="Show Tasks"
                        />
                      </div>
                    </div>
                  </div>
                                  <div
                                    style={{
                                      marginTop: "auto",
                                      marginLeft: "1rem",
                                    }}
                                    className="d-flex mg-t-20"
                                  ></div>
                                </div>
                                {Item["Repairs"].length - 1 == ChildIndex && (
                                  <>
                                    {props.canEdit &&
                                    !WaitingForResponse &&
                                    AddableTest ? (
                                      <div
                                        className="add-circle-wrapper bg-gayish"
                                        onClick={() =>
                                          createNewRepair(
                                            Item["ArrayNumber"],
                                            Item["FatherNumber"]
                                          )
                                        }
                                      >
                                        <div className="content">
                                          <i class="fas fa-plus"></i>
                                          <span className="label-text">
                                            <FormattedMessage
                                              id="AddCar"
                                              defaultMessage="Add Car"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}

                                {Item["Repairs"].length == 1 && Deleteable ? (
                                  <>
                                    {props.canEdit && !WaitingForResponse ? (
                                      <div
                                        onClick={() => handleDeleteRepair(Item)}
                                        className="add-circle-wrapper bg-red"
                                      >
                                        <div className="content">
                                          <i className=" fas fa-trash"></i>{" "}
                                          <span className="label-text">
                                            <FormattedMessage
                        id="DELETESet"
                        defaultMessage="Delete Set"
                      />
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    marginLeft: "14px",
                                    background: "#8080800d",
                                  }}
                                  className="min-h img-text-block d-flex"
                                ></div>

                                {Item["Repairs"].length - 1 == ChildIndex && (
                                  <>
                                    {
                                      props.canEdit &&
                                      !WaitingForResponse &&
                                      AddableTest ? (
                                        <div
                                          className="add-circle-wrapper bg-gayish"
                                          onClick={() =>
                                            createNewRepair(
                                              Item["ArrayNumber"],
                                              Item["FatherNumber"]
                                            )
                                          }
                                        >
                                          <div className="content">
                                            <i class="fas fa-plus"></i>
                                            <span className="label-text">
                                              <FormattedMessage
                                                id="AddCar"
                                                defaultMessage="Add Car"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    }
                                  </>
                                )}
                              </>
                            )}
                          </>
                        );
                      } catch (e) {
                        console.log(e);
                      }
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </>
      )
    } else {
      return (
        <>
          <div id="Repair Quality" className="row heading-block">
            <div className="col-lg-12 d-flex pl-0">
              <h1 className="main-heading">
                <FormattedMessage
                  id="RepairQuality"
                  defaultMessage="Repair Quality"
                />{" "}

              </h1>
              {props.canEdit && !Block && AddableRepair ? (
                <div
                  className="add-circle-wrapper"
                  onClick={() =>
                    handleClick(
                      props.FinalArray["GroupedbyDate"][0]["FatherNumber"]
                    )
                  }
                >
                  <div className="content">
                    <i class="fas fa-plus"></i>
                    <span className="label-text">
                      <FormattedMessage
                        id="CreateNewQualityCheckDATE"
                        defaultMessage="Create New Quality Check DATE"
                      />
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {props.FinalArray["GroupedbyDate"].slice(1).map((Item, Index) => {
            if (Item.total === 0) {
            } else {
            }

            return (
              <>
                <div className="container-fluid plr-0 repair-quality">
                  <div className="row mobile-scroll-y">
                    {Item ? (
                      <div className="min-h img-text-block d-flex pos-rel">
                        <div className="content">
                          {Item["Date"] && (
                            <h5>
                              <b>{Item["Date"]}</b>
                            </h5>
                          )}
                          <h5>
                            <b><FormattedMessage id="TotalQualityScore" defaultMessage="Total Quality Score" /></b>
                          </h5>
                          <p className="mg-b-0 small-text">
                            {Item.complete}/{Item.total}{" "}
                            <FormattedMessage
                              id="Taskscompleted"
                              defaultMessage="Tasks completed"
                            />
                          </p>
                          <div
                            style={{
                              width: "80%",
                              margin: "auto",
                              marginTop: "2rem",
                            }}
                          >
                            {" "}
                            <PieChart
                              className="printPie"
                              percentage={checkPercentage(Item["Result"])}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    ) : (
                      "\u00A0"
                    )}
                    {Item["Repairs"].map((ChildItem, ChildIndex) => {
                      try {
                        let check = false;
                        // console.log(ChildItem.Tasks)
                        if (
                          ChildItem.Tasks.length === 0 ||
                          (ChildItem.Tasks[
                            ChildItem.Tasks.length - 1
                          ].startsWith(Item["FatherNumber"] + ".1") &&
                            ChildItem.Tasks[
                              ChildItem.Tasks.length - 1
                            ].startsWith(Item["FatherNumber"] + ".1."))
                        ) {
                          check = true;
                        }

                        /* item.Tasks.forEach(element => {
                                                    if (!element.startsWith(item.Name)) { check = true }
    
                                                }); */
                        if (ChildItem.total === 0) {
                          check = true;
                        }

                        //   console.log([ChildItem["ArrayNumber"] + ".1"])
                        let test = ChildItem["Tasks"][0].split(".");
                        test.pop();
                        test = test.join(".");
                        console.log(check);
                        return (
                          <>
                            {/*   {props.FinalArray["GroupedbyDate"][Index + 1]["ArrayNumber"] + "." + (ChildIndex + 1)}
                             */}{" "}
                            {!props.tasks[ChildItem["Tasks"][0]] ||
                            (props.tasks[ChildItem["Tasks"][0]][7] !==
                              "Loading" &&
                              /*  props.tasks[ChildItem["ArrayNumber"] + ".1"][7] !== "Loading"
                                                             && */
                              props.tasks[test][7] !== "Loading" &&
                              !check) ? (
                              <>
                                {/*  {ChildItem["ArrayNumber"]} */}
                                <div className="min-h img-text-block d-flex pos-rel">
                                  {props.canEdit &&
                                  !WaitingForResponse &&
                                  Item["Repairs"].length > 1 &&
                                  Deleteable ? (
                                    <div
                                      onClick={() =>
                                        handleDeleteRepairTest(
                                          ChildItem,
                                          ChildIndex
                                        )
                                      }
                                      className="delete-circle-wrapper"
                                    >
                                      <div className="content-inner">
                                        <i className=" fas fa-trash"></i>{" "}
                                        <span className="label-text">
                                          DELETE
                                        </span>
                                      </div>
                                    </div>
                                  ) : Item["Repairs"].length === 1 ? (
                                    <>
                                      {" "}
                                      <div style={{ width: "14px" }}>
                                        {" "}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <div style={{ width: "14px" }}></div>{" "}
                                    </>
                                  )}

                                  <Owldemo4
                                    noTeamwork={props.noTeamwork}
                                    setCurrentImg={props.setCurrentImg}
                                    setModalImage={props.setModalImage}
                                    Pictures={ChildItem.pictures}
                                  />
                                  <Progressbar width={ChildItem["Result"]} />
                                  <div className="content">
                                    <h5>Check {ChildIndex + 1}</h5>
                                    <p className="mg-b-0 small-text">
                                      {ChildItem.complete}/{ChildItem.total}{" "}
                                      <FormattedMessage id="Taskscompleted" defaultMessage="Tasks completed" />


                                    </p>
                                    <div className="stats">
                                      {checkPercentage(ChildItem["Result"])} %
                                    </div>
                                    <div className="d-flex mg-t-20">
                                      <div
                                        style={{
                                          padding: "8px 0px",
                                          margin: "0",
                                        }}
                                        onClick={() => {
                                          openTaskList(
                                            ChildItem["Tasks"],
                                            props.setScrollPos,
                                            props.passTaskListComp,
                                            props.passResult,
                                            ChildItem["Result"],
                                            ChildItem,
                                            "Repair Check",
                                            "Repairs",
                                          )
                                          history.push(`${location.pathname}/byDate/${Index + 1}/Repairs/${ChildIndex + 1}/tasks`, { from: "collapsible" });
                                        }}
                                        class="cta-link fq-popup"
                                        data-popup-id="popup-1"
                                      >
                                        <FormattedMessage
                                          id="ShowTasks"
                                          defaultMessage="Show Tasks"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: "auto",
                                      marginLeft: "1rem",
                                    }}
                                    className="d-flex mg-t-20"
                                  ></div>
                                </div>
                                {Item["Repairs"].length - 1 === ChildIndex && (
                                  <>
                                    {props.canEdit &&
                                    !WaitingForResponse &&
                                    AddableTest ? (
                                      <div
                                        className="add-circle-wrapper bg-gayish"
                                        onClick={() =>
                                          createNewRepair(
                                            Item["ArrayNumber"],
                                            Item["FatherNumber"]
                                          )
                                        }
                                      >
                                        <div className="content">
                                          <i class="fas fa-plus"></i>
                                          <span className="label-text">
                                            <FormattedMessage
                                              id="AddCar"
                                              defaultMessage="Add Car"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}

                                {Item["Repairs"].length === 1 && Deleteable ? (
                                  <>
                                    {props.canEdit && !WaitingForResponse ? (
                                      <div
                                        onClick={() => handleDeleteRepair(Item)}
                                        className="add-circle-wrapper bg-red"
                                      >
                                        <div className="content">
                                          <i className=" fas fa-trash"></i>{" "}
                                          <span className="label-text">
                                          <FormattedMessage
                        id="DeleteSet"
                        defaultMessage="Delete Set"
                      />
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    marginLeft: "14px",
                                    background: "#8080800d",
                                  }}
                                  className="min-h img-text-block d-flex"
                                ></div>

                                {Item["Repairs"].length - 1 === ChildIndex && (
                                  <>
                                    {
                                      props.canEdit &&
                                      !WaitingForResponse &&
                                      AddableTest ? (
                                        <div
                                          className="add-circle-wrapper bg-gayish"
                                          onClick={() =>
                                            createNewRepair(
                                              Item["ArrayNumber"],
                                              Item["FatherNumber"]
                                            )
                                          }
                                        >
                                          <div className="content">
                                            <i class="fas fa-plus"></i>
                                            <span className="label-text">
                                              <FormattedMessage
                                                id="AddCar"
                                                defaultMessage="Add Car"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    }
                                  </>
                                )}
                              </>
                            )}
                          </>
                        );
                      } catch (e) {
                        console.log(e);
                      }
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default ShowRepairQuality;
